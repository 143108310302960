import { type FunctionComponent, useCallback, useEffect, useState } from "react";
import { FormControlLabel, Switch, } from "@mui/material";
import DeleteConfirmationPopupV from "./DeleteConfirmationPopupV";
import PortalPopup from "./PortalPopup";
import OrderScreenDiscountButtonV from "./OrderScreenDiscountButtonV";
import OrderScreenOrderItemModifi from "./OrderScreenOrderItemModifi";
import { pricingLevelFactory } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import {
  groupPrinters0,
  removeCurrentModifier,
  removeModifierItem,
  selectedItem,
  selectedModifier,
  setSelectedItem
} from "@/react/EditMenuView/EditMenuView.tsx";
import { ModifierType } from "@/data/Modifier.ts";
import uuid from "time-uuid";
import _ from "lodash";
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import Input, { customParseFloat, inputController0 } from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";

export type EditMenuEditGroupBottomSheetPluginType = {
  className?: string;
  onClose?: () => void;
  type: ModifierType;
};

const EditMenuEditGroupBottomSheetPlugin: FunctionComponent<
  EditMenuEditGroupBottomSheetPluginType
> = ({ className = "", onClose, type }) => {
  const [isDeleteConfirmationPopupV1Open, setDeleteConfirmationPopupV1Open] =
    useState(false);

  const openDeleteConfirmationPopupV1 = useCallback(() => {
    setDeleteConfirmationPopupV1Open(true);
  }, []);

  const closeDeleteConfirmationPopupV1 = useCallback(() => {
    setDeleteConfirmationPopupV1Open(false);
  }, []);

  const { computedModifierItemPrice, handleModifierItemPriceChange } = pricingLevelFactory()

  const handleAddItem = (_name?: string, price?: number) => {
    const type = (selectedModifier()?.useType !== ModifierType.VARIANT) ? ModifierType.MODIFIER : ModifierType.VARIANT
    const name = _name || (type === ModifierType.MODIFIER ? 'Selection' : 'Variant')
    selectedModifier()?.items?.push({ name: name, price: price || 0, _id: uuid(), type: type })
    setSelectedItem(_.last(selectedModifier()?.items)!);
  }

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <>
      <div
        className={`!opacity-100 w-full h-[800px] relative rounded-t-3xs rounded-b-none bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start gap-3 [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-bottom] opacity-[0] max-h-[90%] text-left text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
        data-animate-on-scroll
      >
        <div className="self-stretch bg-white-solid-white-100-ffffff border-gray-solid-gray-164-e1e1e1 border-b-[1px] border-solid box-border h-11 flex flex-row items-center justify-end py-2 px-3 gap-2 text-base">
          <b className="flex-1 relative overflow-hidden text-ellipsis whitespace-nowrap">
            Edit Group
          </b>
          <img
            className="w-5 relative h-5 object-cover cursor-pointer"
            alt=""
            src="/iconclose-popup-icon2@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start p-2 gap-4">
          <div className="self-stretch flex flex-col items-start justify-center gap-1">
            <div className="relative font-medium">{LL0().printerSetting.groupPrinterName()}</div>
            <Input
              className="self-stretch font-mulish font-semibold text-sm text-black-solid-black-1000-000000"
              value={selectedModifier()?.name || ""}
              onChange={(e) => {
                _.assign(selectedModifier(), { name: e})
              }}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center pt-0 px-0 pb-2 gap-3">
            <div className="self-stretch relative font-medium">
              Option Detail
            </div>
            <div className="gap-4 self-stretch flex flex-row items-start justify-start flex-wrap content-start">
              {selectedModifier()?.items?.map((item, index) => (
                <OrderScreenDiscountButtonV
                  key={index}
                  label={item?.name}
                  price={LL3().format.currency(item?.price || 0)}
                  onSelect={() => {
                    setSelectedItem(item)
                  }}
                  onDelete={() => removeModifierItem(selectedModifier(), index)}
                  isSelected={selectedItem() == item}
                />
              ))}
              <div
                className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-8 flex flex-row items-center justify-center py-1 px-4 relative gap-1 text-left text-sm text-black-solid-black-880-1d1d26 font-mulish"
                onPointerDown={(e) => {
                  e.stopPropagation();
                  handleAddItem()
                }}
              >
                <b className="relative">+ {type === ModifierType.MODIFIER ? LL0().productViewSetting.modifierEditorDialog.addSelection() : LL0().productViewSetting.modifierEditorDialog.addVariant()}</b>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-1">
            <div className="relative font-medium">{LL0().ui.name()}</div>
            <Input
              className="self-stretch font-mulish font-semibold text-sm text-black-solid-black-1000-000000"
              value={selectedItem()?.name || ''}
              onChange={(e) => {
                if (!selectedItem()) handleAddItem(e)
                _.assign(selectedItem(), { name: e })
              }}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-1">
            <div className="relative font-medium">{LL0().article.price()}</div>
            <Input
              className="self-stretch font-mulish font-semibold text-sm text-black-solid-black-1000-000000"
              value={computedModifierItemPrice()}
              onChange={(e) => {
                if (!selectedItem()) handleAddItem('', customParseFloat(e) as number)
                handleModifierItemPriceChange(e);
              }}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center pt-0 px-0 pb-2 gap-1">
            <div className="relative font-medium">{LL0().productViewSetting.modifierEditorDialog.printer()}</div>
            <div className="gap-2 self-stretch flex flex-row items-start justify-start flex-wrap content-start">
              {groupPrinters0().map((groupPrinter, index) => (
                <OrderScreenOrderItemModifi
                  key={index}
                  showIsSelected={selectedModifier()?.groupPrinter === groupPrinter._id}
                  text={groupPrinter.name}
                  onSelect={() => _.assign(selectedModifier(), { groupPrinter: groupPrinter._id })}
                />
              ))}
            </div>
          </div>
          <div className="self-stretch h-6 shrink-0 flex flex-row items-center justify-start gap-3">
            <div className="relative font-medium">{LL0().productViewSetting.modifierEditorDialog.oneAndOnly()}:</div>
            <div className="w-10 relative h-6 overflow-hidden shrink-0">
              <FormControlLabel
                label=''
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                control={<Switch color="primary" size="medium"
                                 checked={selectedModifier()?.selectOne || false}
                                 onChange={e => _.assign(selectedModifier(), { selectOne: e.target.checked })} />}
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-center text-center text-white-solid-white-100-ffffff">
          <div className="self-stretch flex flex-row items-start justify-start pt-1 px-3 pb-4 gap-3">
            <div
              className="flex-1 rounded-md bg-red-solid-red-240-eb5458 h-9 overflow-hidden flex flex-row items-center justify-center py-1 px-8 box-border cursor-pointer z-[1]"
              onClick={openDeleteConfirmationPopupV1}
            >
              <b className="relative">{LL0().ui.delete()}</b>
            </div>
            <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden flex flex-row items-center justify-center py-1 px-8 box-border z-[0]"
              onClick={onClose}
            >
              <b className="relative">{LL0().ui.save()}</b>
            </div>
          </div>
        </div>
        <TableManualKeyboard inputController={inputController0}/>
      </div>
      {isDeleteConfirmationPopupV1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopupV1}
        >
          <DeleteConfirmationPopupV onClose={closeDeleteConfirmationPopupV1}
                                    onComplete={async () => {
                                      await removeCurrentModifier();
                                      onClose?.();
                                    }}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default EditMenuEditGroupBottomSheetPlugin;
