import React, { type FunctionComponent, useEffect } from 'react';
import { LL0 } from "@/react/core/I18nService.tsx";
import { FormControlLabel, Switch } from "@mui/material";
import _ from "lodash";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type EditMenuMoreMenuType = {
  onClose?: () => void;
};


const KitchenMoreMenu: FunctionComponent<EditMenuMoreMenuType> = ({
  onClose,
}) => {

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <>

    <div
      className={`!opacity-100 z-[100] w-[300px] h-full text-mini relative shadow-[0px_0px_6px_2px_#fff] [background:linear-gradient(90deg,_#f8f8f8,_rgba(235,_236,_238,_0.96))] flex flex-col items-center justify-start pt-10 px-0 pb-2.5 box-border gap-[16px] [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-left] opacity-[0] max-w-[90%] overflow-auto text-center text-black-solid-black-1000-000000 font-mulish`}
      data-animate-on-scroll
    >
      <div className="flex flex-col items-center justify-start gap-[16px] z-[0]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <b className="relative text-[14px]">{LL0().productViewSetting.fontSize()}</b>
          <div
            className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
            <div
              className="w-16 box-border h-8 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
              ref={makeRipple}
              onClick={() => {
                const currentFontSize = deviceSetting0()?.screenConfig.kitchenFontSize || 0;
                if (currentFontSize > 1) {
                  _.assign(deviceSetting0()?.screenConfig, { kitchenFontSize: currentFontSize - 1 });
                }
              }}
            >
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconsteper-minus@2x.png"
              />
            </div>
            <b className="relative">{deviceSetting0()?.screenConfig.kitchenFontSize}px</b>
            <div
              className="w-16 box-border h-8 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
              ref={makeRipple}
              onClick={() => {
                const currentFontSize = deviceSetting0()?.screenConfig.kitchenFontSize || 0;
                _.assign(deviceSetting0()?.screenConfig, { kitchenFontSize: currentFontSize + 1 });

              }}
            >
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconstepper-plus@2x.png"
              />
            </div>
          </div>
        </div>
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border">
          <div className="w-16 relative h-6">
            <FormControlLabel
              label=''
              className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
              control={<Switch color="primary" size="medium"
                               checked={generalSetting0()?.enableKitchenBell ?? false}
                               onChange={(e, checked: boolean) => {
                                 _.assign(generalSetting0(), { enableKitchenBell: checked })

                                 console.log(generalSetting0()?.enableKitchenBell)
                               }}
              />}
            />
          </div>
          <div className="flex-1 relative flex flex-col items-start justify-start">
            {LL0().order.enableKitchenBell()}
          </div>
        </div>
        {generalSetting0()?.enableKitchenBell &&
          <div
            className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border">
            <div className="w-16 relative h-6">
              <FormControlLabel
                label=''
                className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                control={<Switch color="primary" size="medium"
                                 checked={generalSetting0()?.limitBellOnly3Times ?? false}
                                 onChange={(e, checked: boolean) => _.assign(generalSetting0(), { limitBellOnly3Times: checked })}
                />}
              />
            </div>
            <div className="flex-1 relative">
              {LL0().kitchenMonitor.limitBellThreeTimes()}
            </div>
          </div>}
        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border">
          <div className="w-16 relative h-6">
            <FormControlLabel
              label=''
              className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
              control={<Switch color="primary" size="medium"
                               checked={generalSetting0()?.oneColumnPerOrder ?? false}
                               onChange={(e, checked: boolean) => _.assign(generalSetting0(), { oneColumnPerOrder: checked })}
              />}
            />
          </div>
          <div className="flex-1 relative flex flex-col items-start justify-start">
            {LL0().kitchenMonitor.oneColumnPerOrder()}
          </div>
        </div>

        <div
          className="self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[35px] flex flex-row items-center justify-center py-0 px-4 box-border">
          <div className="w-16 relative h-6">
            <FormControlLabel
              label=''
              className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
              control={<Switch color="primary" size="medium"
                               checked={generalSetting0()?.onlySortByDate ?? false}
                               onChange={(e, checked: boolean) => _.assign(generalSetting0(), { onlySortByDate: checked })}
              />}
            />
          </div>
          <div className="flex-1 relative flex flex-col items-start justify-start">
            {LL0().kitchenMonitor.onlySortByDate()}
          </div>
        </div>
      </div>
      <img
        className="w-6 h-6 absolute !m-[0] top-[8px] left-[8px] object-cover cursor-pointer z-[1]"
        alt=""
        src="/icondelete-fillter-icon@2x.png"
        onClick={onClose}
      />
    </div>
    </>
  );
};

export default KitchenMoreMenu;
