import { TseConfig } from '@/data/TseConfig'
import TerminalPlugin from '@terminal/TerminalPlugin.tsx'
import { setTseConfigV, tseConfig0 } from "@/data/TseConfigHub.ts";
import { inputController0 } from "@/react/core/Input.tsx";
import { setEodMode0 } from "@/tse/tse-variable.ts";
import { clearEodCache } from "@/pos/logic/order-utils.ts";
import { setOpenMockTSEPopup } from "@/react/Developer/MockTSEPopup.tsx";
import { toast } from "react-toastify";
import { exportMeta, importMeta } from "@/data/fix/backup-indexeddb.ts";
import { categoryFixFactory } from "@/data/fix/fix3.ts";

import { addDemoCashbookData } from "@/react/CashbookView/cashbook-utils.ts";
import { migrateVariants } from "@/data/fix/fixVariants.ts";
import { addTaxGroupEod } from "@/data/fix/fixTaxGroup.ts";

export async function handleQueryCode(v: string) {
  const password = v.split('setTsePw/')[1]
  if (password) {
    await TseConfig.upsert({
      _id: tseConfig0()._id,
      tsePassword: password
    })
    setTseConfigV(v => v + 1);
  }
}

export function configTse() {
  //todo: lam gi o day ??
  setOpenMockTSEPopup(true);
}

export function notification() {
  toast("enable successful", { type: 'success', autoClose: 600 });
}

export async function onTerminalEnter() {
  const v = inputController0().keys().join("");
  if (v === "configTse") {
    configTse()
    inputController0().setKeys([]);
  } else if (v === "tse/5678") {
    setEodMode0('reduce')
    notification()
  } else if (v.split('/')[0] === 'setTsePw') {
    await handleQueryCode(v)
    notification()
  } else if (v === 'clearEodCache') {
    await clearEodCache()
    notification()
  } else if (v === 'exportMeta') {
    await exportMeta();
    toast('exportMeta successful', { type: 'success', autoClose: 600 });
  } else if (v === 'variants') {
    await migrateVariants();
  } else if (v.split('/')[0] === 'importMeta') {
    await importMeta(v.split('/')[1], v.split('/')[2]);
    toast('importMeta successful', { type: 'success', autoClose: 600 });
  } else if (v === 'fixCategories') {
    const api = categoryFixFactory();
    await api.fixLayoutCategories();
    toast('fix category successful', { type: 'success', autoClose: 600 });
  } else if (v === 'addDemoCashbookData') {
    addDemoCashbookData()
    toast('add demo cashbook data successful', { type: 'success', autoClose: 600 });
  } else if (v === 'addTaxGroup') {
    const api = await addTaxGroupEod();
    await api.solveCompleteOrder();
    toast('add tax group', { type: 'success', autoClose: 600 })
  }
}


const TerminalView = () => {
  return <TerminalPlugin />
}

export default TerminalView;
