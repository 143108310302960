import type { FunctionComponent } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import OrderScreenOrderItemModifi from "./OrderScreenOrderItemModifi";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  createNewModifier,
  setSelectedItem,
  setSelectedModifier
} from "@/react/EditMenuView/EditMenuView.tsx";
import { type Modifier, type ModifierItem, ModifierType } from "@/data/Modifier.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { addModifierFactory, dineInModifiers } from "@/data/ModifierHub.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import uuid from "time-uuid";
import { clone } from "json-fn";
import EditMenuEditGroupBottomSheetPlugin from "@vertical-edit-menu/EditMenuEditGroupBottomSheetPlugin.tsx";
import PortalDrawer from "@vertical-edit-menu/PortalDrawer.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { product0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";

export type EditMenuPopupModifiersType = {
  className?: string;
  onClose?: () => void;
};

const EditMenuPopupModifiers: FunctionComponent<EditMenuPopupModifiersType> = ({
  className = "",
  onClose,
}) => {

  const [isOpenEditGroup, setOpenEditGroup] = useSignal(false);

  return (
    <>
      <div
        className={`w-[448px] relative bg-white-solid-white-100-ffffff h-[400px] overflow-hidden flex flex-col items-start justify-start pt-[18px] px-0 pb-0 box-border gap-4 max-w-full max-h-full text-left text-base text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center p-2 z-[0]">
          <b className="flex-1 relative">{LL0().productViewSetting.modifierDialog.availabilityGroups()}</b>
          <img
            className="w-[21px] relative h-[21px] object-cover cursor-pointer"
            alt=""
            src="/iconclose-popup-icon1@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-[78px] gap-4 z-[1] text-xs">
          {/*map*/}
          {dineInModifiers().map((modifier) =>
            <div className="self-stretch flex flex-col items-start justify-center pt-0 px-2 pb-2 gap-3"
                 key={modifier._id}
            >
              <div className="self-stretch flex flex-row items-center justify-between">
                <div className="flex flex-row items-center justify-start gap-0">
                  <div className="flex-row items-center justify-start">
                    <FormControlLabel
                      className="self-stretch relative"
                      label={modifier?.name}
                      control={
                        <Checkbox color="primary"
                                  checked={!!product0()?.activePopupModifierGroup?.includes(modifier._id!)}
                                  onChange={e => addModifierFactory(() => product0(), modifier)(e.target.checked)}
                        />
                      }
                    />
                  </div>
                  {/*<div className="relative font-medium">{modifier?.name}</div>*/}
                </div>
                <div className="flex-1 flex flex-row items-center justify-end gap-4 text-sm text-blue-solid-blue-390-0687ed">
                  <b className="relative [text-decoration:underline] sm:text-sm"
                     ref={makeRipple}
                     onClick={() => {
                       setSelectedModifier(modifier);
                       setSelectedItem(modifier.items?.[0]!);
                       setOpenEditGroup(true);
                     }}
                  >
                    {LL0().ui.edit()}
                  </b>
                  <img
                    className="w-6 relative h-6 object-cover"
                    alt=""
                    src="/iconedit-menuduplicate-icon-ver1@2x.png"
                    onClick={() => {
                      const items = modifier.items?.map((item) => ({ ...item, _id: uuid() })) as ModifierItem[];
                      createNewModifier(ModifierType.MODIFIER, clone({ ...modifier, _id: uuid(), items } as Modifier))
                    }}
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-2">
                {modifier.items?.map((item, index) => (
                  <OrderScreenOrderItemModifi
                    key={index}
                    showIsSelected={false}
                    text={item?.name}
                    price={LL3().format.currency(item?.price || 0)}
                  />
                ))}
              </div>
            </div>
          )}

        </div>
        <div className="w-full !m-[0] absolute right-[0%] bottom-[0px] left-[0%] bg-white-solid-white-100-ffffff border-gray-solid-gray-400-c9cbcc border-t-[1px] border-solid box-border flex flex-col items-start justify-center pt-2.5 px-2.5 pb-4 z-[2] text-center text-sm text-white-solid-white-100-ffffff">
          <div className="self-stretch flex flex-row items-center justify-start gap-3">
            <div className="flex-1 rounded-md bg-green-solid-green-00c968 overflow-hidden flex flex-row items-center justify-center py-[9px] px-3"
                 onClick={() => createNewModifier(ModifierType.MODIFIER)}
                 ref={makeRipple}
            >
              <b className="flex-1 relative">{LL0().productViewSetting.modifierDialog.addNewGroup()}</b>
            </div>
            <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-3"
                 onClick={onClose}
            >
              <b className="relative">{LL0().ui.save()}</b>
            </div>
          </div>
        </div>
      </div>
      {isOpenEditGroup() && <PortalDrawer
        overlayColor="rgba(113, 113, 113, 0.3)"
        placement="Bottom"
        onOutsideClick={() => setOpenEditGroup(false)}
      >
        <EditMenuEditGroupBottomSheetPlugin onClose={() => setOpenEditGroup(false)} type={ModifierType.MODIFIER} />
      </PortalDrawer>}
    </>
  );
};

export default EditMenuPopupModifiers;