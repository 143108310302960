import dayjs from 'dayjs'
import Debug from 'debug'
import _ from 'lodash'

import { invoiceGroupPrinters0 } from '@/data/GroupPrinterHub.ts'
import type { MonthlyReport } from '@/pos/logic/ReportType.ts'
import { LL2 } from '@/react/core/I18nBackend.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { reportConfigs } from '@/react/MonthlyReportView/MonthlyReportView.tsx'
import { VPrinter } from '@/react/Printer/VPrinter.ts'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from '@/shared/printer/types'
import { countReportTotal } from "@/pos/logic/report-utils.ts";
import { orderConfig } from '@/shared/order/order-config'

const debug = Debug('printer:monthly-report')
export const printMonthlyReport = async (monthlyReport?: MonthlyReport, isEscPos?: boolean): Promise<ScriptedRaster | undefined> => {
  if (!monthlyReport) {
    debug('monthlyReport is not defined')
    return
  }

  const LL = LL2()

  const {
    groupItemsByCategory,
    sumByCategory,
    totalCashSalesExcludingCashback,
    totalCashlessSalesExcludingTip,
    sumByPayment,
    zNumbers,
    total,
    report,
    totalTip,
    voucherReport,
    totalServiceFee,
    totalRefund,
    totalDebitorAmount,
    paymentReport,
    totalShippingFee,
    totalShippingTip,
    totalDonation
  } = monthlyReport

  const { from, to } = report

  const { name: companyName, address: companyAddress, telephone: companyTel, taxNumber: companyTaxNumber, country } = companyInfo0()

  if (!country) return

  const printer = new VPrinter({ ...invoiceGroupPrinters0()[0].printers[0] })

  const {tax, net, gross} = countReportTotal(report)

  const { vTaxSum, vDiscount, vTaxComponents } = report

  await printer.alignCenter()
  await printer.bold(true)
  await printer.println(companyName!)
  await printer.bold(false)
  await printer.setTextNormal()
  await printer.println(companyAddress!)
  if (companyTel) await printer.println(`${LL.printing.tel()}: ${companyTel}`)
  if (companyTaxNumber) await printer.println(`${LL.printing.vatRegNo()}: ${companyTaxNumber}`)

  await printer.newLine()
  if (isEscPos) await printer.setTextDoubleHeight()
  else {
    await printer.setFontSize(40)
  }
  if (monthlyReport.trainingMode) {
    await printer.bold(true)
    await printer.println(`**${LL0().settings.inTrainingMode().toUpperCase()}**`)
  }
  await printer.bold(true)
  await printer.println(LL.printing.monthReport())
  await printer.setFontSize(36)
  await printer.bold(false)
  await printer.println(dayjs.unix(from).format('MM/YYYY'))
  await printer.newLine()

  await printer.alignLeft()
  await printer.setTextNormal()
  await printer.bold(false)

  if (zNumbers) {
    await printer.bold(false)
    await printer.drawLine()
    const sortedDateZNumbers = _.sortBy(Object.keys(zNumbers), key => dayjs(key, 'DD.MM.YYYY').unix());
    for (const date of sortedDateZNumbers) {
      const zReports = zNumbers[date]
      for (const z of Object.keys(zReports)) {
        if (z) {
          await printer.leftRight(`${LL.printing.zNumber()} ${z}: ${LL3().format.currency(zReports[z])}`, `${dayjs(date, 'DD.MM.YYYY').format(LL.dates.dateFormat())}`)
        }
      }
    }
  }

  await printer.bold(true)
  await printer.drawLine()
  await printer.println(LL.printing.totalSale())
  await printer.bold(false)
  // await printer.leftRight(LL.printing.total(), LL3().format.currency(vTaxSum?.gross))
  // await printer.leftRight(LL.printing.subTotal(), LL3().format.currency(vTaxSum?.net))
  await printer.leftRight(LL.printing.total(), LL3().format.currency(gross))
  await printer.leftRight(LL.printing.subTotal(), LL3().format.currency(net))
  if (totalServiceFee) await printer.leftRight(LL.printing.serviceFee(), `${LL3().format.currency(totalServiceFee)}`)
  // await printer.leftRight(LL.printing.tax(), LL3().format.currency(vTaxSum?.tax))
  await printer.leftRight(LL.printing.tax(), LL3().format.currency(tax))
  await printer.bold(true)
  await printer.drawLine()

  await printer.bold(false)

  if (!orderConfig.useTaxComponents) {
    const groupTypes = Object.keys(vTaxSum?.vTaxSum || {})
    for (let i = 0; i < groupTypes.length; i++) {
      const groupType = groupTypes[i]
      await printer.println(`${LL.printing.tax()} (${groupType.includes(',')? groupType.replace(',', '.') : groupType}%)`);
      const { gross = 0, net = 0, tax = 0 } = vTaxSum?.vTaxSum?.[groupType] || {};
      await printer.leftRight(LL.printing.total(), LL3().format.currency(gross))
      await printer.leftRight(LL.printing.subTotal(), LL3().format.currency(net))
      await printer.leftRight(LL.printing.tax(), LL3().format.currency(tax))
      await printer.newLine()
    }
  }

  if (orderConfig.useTaxComponents) {
    for (const key of Object.keys(vTaxComponents)) {
      await printer.leftRight(key, LL.format.currency(vTaxComponents[key]));
    }
    await printer.newLine();
  }

  await printer.bold(false)
  await printer.leftRight(LL.printing.voucherSold(), `${LL3().format.currency(_.get(voucherReport, 'soldTotal', 0))}`)
  for (const voucher of _.get(voucherReport, 'soldVouchers', [])) {
    await printer.leftRight('-   ' + voucher.code, `${LL3().format.currency(voucher.price || 0)}`)
  }
  await printer.leftRight(LL.printing.voucherUsed(), `${LL3().format.currency(_.get(voucherReport, 'redeemedTotal', 0))}`)
  for (const voucher of _.get(voucherReport, 'redeemedVouchers', [])) {
    await printer.leftRight('-   ' + voucher.code, `${LL3().format.currency(Math.abs(voucher.price || 0))}`)
  }

  await printer.leftRight(LL.printing.discount(), `${LL3().format.currency(vDiscount || 0)}`)
  await printer.bold(true)
  await printer.drawLine()

  await printer.bold(false)

  if (country !== 'de') {
    await printer.bold(true)
    await printer.println(LL.report.payments());
    await printer.bold(false)
  }

  for (const paymentMethod of Object.keys(paymentReport)) {
    // @ts-expect-error TODO: Fix this typing
    await printer.leftRight(_.capitalize(LL.payment[paymentMethod]() || paymentMethod), `${LL3().format.currency(paymentReport[paymentMethod]?.excludeCashback || 0)}`)
  }

  await printer.drawLine()
  await printer.leftRight(LL.printing.totalTip(), `${LL3().format.currency(totalTip || 0)}`)
  await printer.drawLine()

  if (country !== 'de') {
    //fee
    await printer.bold(true)
    await printer.println(LL.report.otherFees());
    await printer.bold(false)
    await printer.leftRight(LL.report.shippingFee(), LL3().format.currency(totalShippingFee || 0))
    await printer.leftRight(LL.report.shippingTip(), LL3().format.currency(totalShippingTip || 0))
    await printer.leftRight(LL.report.donation(), LL3().format.currency(totalDonation || 0))
    await printer.drawLine()

    //total
    await printer.leftRight(LL.report.totalCashSalesExcludingCashback(), `${LL.format.currency(totalCashSalesExcludingCashback || 0)}`);
    await printer.leftRight(LL.report.totalCashlessSalesExcludingTip(), `${LL.format.currency(totalCashlessSalesExcludingTip || 0)}`);
    if (totalDebitorAmount) {
      await printer.leftRight(LL.report.debtAmount(), LL.format.currency(totalDebitorAmount || 0))
    }
    await printer.drawLine();
  }

  await printer.leftRight(LL.report.totalRefund(), `${LL3().format.currency(totalRefund || 0)}`)
  await printer.drawLine()

  if (sumByCategory && reportConfigs().showProductSold) {
    await printer.bold(true)
    await printer.println(LL.printing.productSold())
    await printer.drawLine()

    for (const category in sumByCategory) {
      const products = groupItemsByCategory[category]
      if (!products) return
      await printer.newLine()

      await printer.bold(true)
      await printer.println(`${category || LL.printing.noCategory()} (${LL3().format.currency(sumByCategory[category])})`)
      await printer.bold(false)

      for (const product in products) {
        if (products[product].quantity) await printer.println(` ${products[product].quantity} x ${product} (${LL3().format.currency(products[product].vSum)})`)
      }
    }
  }

  // const raster: Raster = (await printer.print())!;
  const raster = await printer.getRaster()
  return raster
}
