import { signal } from "@/react/core/reactive.ts";

export enum AppMode {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
  Kiosk = 'kiosk'
}

export const [appMode, setAppMode] = signal<AppMode>(AppMode.Horizontal);

//@ts-ignore
window.appMode=appMode