import { type FunctionComponent, useCallback, useEffect, useState } from "react";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { setInputControllers } from "@/react/OrderView/OrderView.tsx";
import { useAsyncEffect, useSignal } from "@/react/core/reactive.ts";
import { isAddCustomer, order0, scopeOrder0 } from "@/react/PaymentView/PaymentView.tsx";
import _, { omit } from "lodash";
import { toast } from "react-toastify";
import { setPaymentRedBillInfoPopupOpen } from "@payment/PaymentScreenMoreOption.tsx";
import { printInvoice } from "@/react/Printer/print-invoice.ts";
import { InvoiceTypes } from "@/pos/OrderType.ts";
import {
  customers
} from "@/react/CustomerInfoView/CustomerInfoView.tsx";
import type { Customer } from "@/data/Customer.ts";
import type { DocDeepSignal } from "@/data/data-utils.ts";
import type { RxDocument } from "rxdb";
import type { CustomerMapAddress } from "@new-delivery/NewDeliveryAddEditCustomer.tsx";
import Autocomplete, { type AutocompleteProps } from "@/react/core/Autocomplete.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import clsx from "clsx";
import axios from "axios";
import { getApiUrl } from "@/shared/utils.ts";
import { getLL2 } from "@/react/core/I18nBackend.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { customer0 } from "@/react/NewDeliveryView/NewDeliveryView.tsx";
import {
  addNewCustomerInfo,
  findCustomerByCompanyName, getCityName,
  updateCustomer
} from "@/react/CustomerInfoView/customer-utils.ts";

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { DEBOUNCE_TIME } from "@/react/FloorPlanView/FloorPlanView.tsx";


export interface CustomerInfo {
  name: string;
  street: string,
  phone: string,
  email: string,
  taxNo: string,
  zipCode: string,
  ustId: string,
  cardNo: string,
  company: string,
  note: string,
  city: string,
}

export const defaultCustomer = {
  name: "",
  street: "",
  phone: "",
  email: "",
  taxNo: "",
  zipCode: "",
  ustId: "",
  cardNo: "",
  company: "",
  note: "",
  city: ""
}

export type PaymentRedBillInfoPopupType = {
  className?: string;
  onClose?: () => void;
};

const PaymentRedBillInfoPopup: FunctionComponent<
  PaymentRedBillInfoPopupType
> = ({ className = "", onClose }) => {

  const [customerTelOptions, setCustomerTelOptions] = useState<Array<DocDeepSignal<Customer>>>([]);
  const [customerNameOptions, setCustomerNameOptions] = useState<Array<DocDeepSignal<Customer>>>([]);

  const [customerInfo, setCustomerInfo] = useSignal<CustomerInfo>(defaultCustomer)

  const [customerCompanyOptions, setCustomerCompanyOptions] = useState<Array<DocDeepSignal<Customer>>>([]);

  const [autoCompleteAddressOptions, setAutoCompleteAddressOptions] = useState<any[]>([]);

  const getCustomerCompanyName = useCallback(_.debounce( () => {
    const foundCustomers = customers().filter(c => c?.company?.toLowerCase().includes(customerInfo()?.company.toLowerCase()))
    setCustomerCompanyOptions(foundCustomers || [])
  }, 100), []);

  const getCustomerTel = useCallback(_.debounce(() => {
    const foundCustomers = customers().filter(c => c?.phoneNumber?.includes(customerInfo()?.phone))
    setCustomerTelOptions(foundCustomers || [])
  }, 100), []);

  const getCustomerName = useCallback(_.debounce( () => {
    const foundCustomers = customers().filter(c => c?.name?.toLowerCase().includes(customerInfo()?.name?.toLowerCase()))
    setCustomerNameOptions(foundCustomers || [])
  }, 100), []);

  useEffect(() => {
    if ((customerInfo()?.phone?.length || 0) === 0) return;
    getCustomerTel()
  }, [customerInfo()?.phone]);

  useEffect(() => {
    if ((customerInfo()?.name.length || 0) === 0) return;
    getCustomerName()
  }, [customerInfo()?.name]);

  useEffect(() => {
    if ((customerInfo()?.company.length || 0) === 0) return;
    getCustomerCompanyName()
  }, [customerInfo()?.company]);

  useAsyncEffect(async() => {
    if (!customers || customers.length === 0) {
      await updateCustomer()
    }
  }, [JSON.stringify(customers())]);


  const autoCompletePropsConstructor = (options: RxDocument<Customer>[], key: "name" | "company" | "phone", otherKey: "name" | "company" | "phoneNumber"):
    AutocompleteProps => ({
    sx: { height: "37px" },
    freeSolo: true,
    className: "rounded self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400",
    filterOptions: opts => opts,
    value: customerInfo()?.[key] || "",
    onChange: (_e, newValue) => {
      _.debounce(() => userFLow(`autoCompletePropsConstructor in payment`, {
          username: loginUser()?.name,
          orderId: order0()._id
        }), DEBOUNCE_TIME.v,
        { leading: true, trailing: true })
      if (newValue === null) return
      setCustomerInfo(prev => ({
        ...prev,
        [key]: newValue
      }));
    },
    options: options.map(customer => customer?.[otherKey] || ""),
    inputProps: {
      value: customerInfo()?.[key] || "",
      onChange: value => {
        _.debounce(() => userFLow(`customer info in payment`, {
          username: loginUser()?.name,
          orderId: order0()._id
        }), DEBOUNCE_TIME.v,
          { leading: true, trailing: true })
        setCustomerInfo(prev => ({ ...prev, [key]: value }))
      },
      className: "w-full shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400",
      endAdornment: (onChange, value) => (
        <>
          <img
            className="mr-1"
            src="/searchicon3x.png"
            alt="search-icon"
            width={20}
            height={20}
          />
        </>
      )
    },
    renderOption: ({ style, className, ...restProps }, option, state) => {
      const foundCustomer = options.find(customer => customer[otherKey] === option)

      return (
        <li
          className="self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1"
          key={foundCustomer?._id || state.index}
          {...restProps}
          onClick={(e) => {
            userFLow(`customer info in payment`, {
              username: loginUser()?.name,
              orderId: order0()._id
            })
            if (!foundCustomer) return;
            const found = foundCustomer;
            setCustomerInfo(prev => ({ ...prev, company: found!.company!, name: found!.name || '', phone: found!.phoneNumber || '' }))
            restProps.onClick?.(e);
          }}
          ref={makeRipple}
        >
          {state.index % 2 === 0 &&
            <div
              className="!flex w-full absolute my-0 mx-[!important] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-opacity-blue-80-3c5ac6 hidden z-[0]" />
          }
          <div className={clsx("relative text-blue-solid-blue-600-4b40c9 z-[3] mr-2",
            state.index % 2 === 0 && "text-white")}>
            <span>{foundCustomer?.company}</span>
          </div>
          <div className={clsx("relative text-blue-solid-blue-600-4b40c9 z-[3] mr-2",
            state.index % 2 === 0 && "text-white")}>
            <span>{foundCustomer?.name}</span>
          </div>
          <div className={clsx("text-left flex-1 relative text-black-solid-black-880-1d1d26 z-[1]",
            state.index % 2 === 0 && "text-white")}>
            <span>{foundCustomer?.phoneNumber}</span>
          </div>
        </li>
      )
    }
  })


  const fetchAddress = useCallback(_.debounce(async (searchPlace: string) => {
    const { data: foundAddresses } = await axios.get<
      CustomerMapAddress[]>(getApiUrl() + "/api/map/google-places", {
      params: {
        searchPlace
      }
    })
    setAutoCompleteAddressOptions(foundAddresses || [])
  }, 500), [customerInfo()?.street]);

  useEffect(() => {
    const autoCompleteStreet = customerInfo()?.street;

    if ((autoCompleteStreet?.length || 0) > 3) {
      if (autoCompleteAddressOptions[0]?.index == null ||
        !autoCompleteAddressOptions.find(option => option.name === autoCompleteStreet)) {
        fetchAddress(autoCompleteStreet);
      }
    }

    const foundCustomer = findCustomerByCompanyName(customerInfo().company);
    if (foundCustomer) {
      const index = foundCustomer?.addresses?.findIndex(address => address.street === autoCompleteStreet);
      if (index !== -1 && index !== undefined) {
        setCustomerInfo((prev) => ({
          ...prev,
          zipCode: foundCustomer?.addresses?.[index!]?.zipcode || ''
        }));
      }
    }

  }, [customerInfo()?.street]);




  useEffect(() => {
    if (!customerInfo().company) {
      setCustomerInfo((prev) => ({
        ...prev,
        ...omit(defaultCustomer, ['company']),
      }));
    } else if (customerInfo().company) {
      const fetchedCustomer = findCustomerByCompanyName(customerInfo().company);

      if (!fetchedCustomer) {
        setCustomerInfo((prev) => ({
          ...prev,
        }));
      } else {
        setCustomerInfo((prev) => ({
          ...prev,
          street: fetchedCustomer?.addresses?.[0].street ?? '',
          zipCode: fetchedCustomer?.addresses?.[0].zipcode ?? '',
          email: fetchedCustomer?.email ?? '',
          taxNo: fetchedCustomer?.taxNo ?? '',
          cardNo: fetchedCustomer?.cardNo ?? '',
          note: fetchedCustomer?.note ?? '',
          ustId: fetchedCustomer?.ustId ?? '',
          city: fetchedCustomer?.addresses?.[0].city ?? '',
          name: fetchedCustomer?.name ?? '',
          phone: fetchedCustomer?.phoneNumber ?? '',
        }));
      }
    }
  }, [customerInfo().company]);


  const handleSaveCustomerInfo = async () => {
    if (!customerInfo().company || !customerInfo().street) return toast.error(`${LL0().printing.printRequired()}`);

    if (customerInfo().company && customerInfo().street) {
      const { customerId, rawInfo } = await addNewCustomerInfo(customerInfo())
      _.assign(scopeOrder0(), { customerRaw: _.omit(rawInfo, ['cardNo']), customer: customerId });
      if (order0().seatMode) {
        const seatOrder = order0()?.seatMap?.[scopeOrder0().seat!]
        _.assign(seatOrder, { customerRaw: _.omit(rawInfo, ['cardNo']), customer: customerId });
      } else {
        _.assign(order0(), { customerRaw: _.omit(rawInfo, ['cardNo']), customer: customerId });
      }
      toast.success(`${LL0().customer.addInfo()}`, {autoClose: 600})
      if (!isAddCustomer()) {
        await printInvoice(scopeOrder0(), InvoiceTypes.RED_INVOICE);
        toast.success(`${LL0().printing.printRebBill()}`, { autoClose: 600 });
      }
    }
    setCustomerInfo(defaultCustomer)
    setPaymentRedBillInfoPopupOpen(false);
  };

  return (
    <div
      className={`w-full h-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-3.5 px-3.5 pb-1.5 box-border gap-[8px] min-w-[540px] max-h-[600px] max-w-full text-left text-smi text-black-solid-black-600-424242 font-mulish ${className}`}
    >
      <div className="no-scrollbar pb-2 self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[7px]">
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
            <div className="relative font-extrabold">
              <span>{LL0().printing.customerName()}</span>
            </div>
            <Autocomplete
              {...autoCompletePropsConstructor(customerNameOptions, "name", "name")}
              dontUseNativeInput
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
            <div className="relative font-extrabold">
              <span>{LL0().settings.companyName()}</span>
              <span className="text-crimson">*</span>
            </div>
            <Autocomplete
              {...autoCompletePropsConstructor(customerCompanyOptions, "company", "company")}
              dontUseNativeInput
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative font-extrabold">
              <span>{LL0().delivery.methods.tel()}</span>
            </div>
            <Autocomplete
              {...autoCompletePropsConstructor(customerTelOptions, "phone", "phoneNumber")}
              dontUseNativeInput
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative font-extrabold">{LL0().delivery.customer.email()}</div>
            <Input
              className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400"
              value={customerInfo().email}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              onChange={value => setCustomerInfo(prev => ({ ...prev, email: value }))}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative font-extrabold">
              <span>{LL0().settings.taxNo()}</span>
            </div>
            <Input
              className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400"
              value={customerInfo().taxNo}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              onChange={value => setCustomerInfo(prev => ({ ...prev, taxNo: value }))}
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative font-extrabold">{LL0().delivery.customer.address.zipcode()}</div>
            <Input
              className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400"
              value={customerInfo().zipCode}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              onChange={value => setCustomerInfo(prev => ({ ...prev, zipCode: value }))}
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[2px]">
          <div className="relative font-extrabold">
            <span>{LL0().delivery.customer.address.street()}</span>
            <span className="text-crimson">*</span>
          </div>
          <Autocomplete
            className="w-full rounded z-[5] self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400"
            filterOptions={(options) => {
              return options
            }}
            value={customerInfo().street || ''}
            freeSolo={true}
            onChange={(_e, newValue) => {
              _.debounce(() => userFLow(`customerInfo street in payment`, {
                username: loginUser()?.name,
                orderId: order0()._id
              }), 2000, { leading: true, trailing: true })
              if (newValue === null || newValue === undefined) return;
              if (newValue === '') setCustomerInfo(prev => ({ ...prev, city: '' }));
              const foundAddress = autoCompleteAddressOptions.find(address => address.name === newValue);
              if (foundAddress) {
                setCustomerInfo(prev => ({ ...prev, street: foundAddress.name }))
                const city =  getCityName(foundAddress.name);
                setCustomerInfo(prev => ({ ...prev, city: city }))
              } else {
                setCustomerInfo(prev => ({ ...prev, street: newValue }))
              }
            }}
            options={autoCompleteAddressOptions.map(address => address.name)}
            inputProps={{
              className: "w-full shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400",
              onChange: value => {
                if (!value) return;
                setCustomerInfo(prev => ({ ...prev, street: value }))
              },
              value: customerInfo().street || '',
              endAdornment: (onChange, value) => (
                <>
                  <img
                    className="mr-1 cursor-pointer"
                    src="/searchicon3x.png"
                    alt="search-icon"
                    width={20}
                    height={20}
                    onClick={() => {
                      userFLow(`findCustomerByCompanyName in payment`, {
                        username: loginUser()?.name,
                        orderId: order0()._id
                      })
                      onChange?.({ target: { value: value || "" } } as any)
                      const fetchCustomer = findCustomerByCompanyName(customerInfo().company);
                      if (fetchCustomer) {
                        setAutoCompleteAddressOptions(fetchCustomer?.addresses?.map((address, index) => ({
                          name: address.street
                        })) || []);
                      }
                    }}
                    ref={makeRipple}
                  />
                </>
              )
            }}
            dontUseNativeInput
          />
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[7px]">
        </div>
        {companyInfo0()?.country !== 'de' && (
          <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="relative font-extrabold">{LL0().printing.vatRegNo()}</div>
              <Input
                className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset]"
                value={customerInfo().ustId}
                refInputController={i => {
                  i.setFocus(true)
                  return setInputControllers?.([i]);
                }}
                onChange={value => setCustomerInfo(prev => ({ ...prev, ustId: value }))}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="relative font-extrabold">{LL0().settings.cardNo()}</div>
              <Input
                className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset]"
                value={customerInfo().cardNo}
                refInputController={i => {
                  i.setFocus(true)
                  return setInputControllers?.([i]);
                }}
                onChange={value => setCustomerInfo(prev => ({ ...prev, cardNo: value }))}
              />
            </div>
          </div>
        )}

        <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative font-extrabold">
              <span>{LL0().settings.city()}</span>
            </div>
            <Input
              className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] font-mulish text-sm text-gray-400"
              value={customerInfo().city}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              onChange={value => setCustomerInfo(prev => ({ ...prev, city: value }))}
            />
          </div>
          <div className="flex-1 self-stretch flex flex-col items-start justify-center gap-[2px]">
            <div className="relative font-extrabold">{LL0().settings.moreInfo()}</div>
            <Input
              className="self-stretch shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset]"
              value={customerInfo().note}
              refInputController={i => {
                i.setFocus(true)
                return setInputControllers?.([i]);
              }}
              onChange={value => setCustomerInfo(prev => ({ ...prev, note: value }))}
            />
          </div>
        </div>
      </div>
      <TableManualKeyboard
        inputController={inputController0}
        onEnter={handleSaveCustomerInfo}
      />
    </div>
  );
};

export default PaymentRedBillInfoPopup;
