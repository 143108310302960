import dayjs, { type Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'

import { masterDeviceSetting } from '@/data/DeviceSettingHub.ts'
import type { Order } from '@/data/Order.ts'
import { toISOString } from '@/lib/utils.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { CommitAction, type ItemCommit, type OrderItem, type Tax } from '@/pos/OrderType'
import { orderConfig, roundNumber } from '@/shared/order/order-config.ts'
import { beginHour } from "@/data/PosSettingsSignal.ts";

// NOTE: Already applied in FullApp3.stories.tsx, but still error
dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(utc)
dayjs.extend(timezone)

const config = orderConfig

export enum TaxCalMethod {
  OneStep = 'OneStep',
  TwoStep = 'TwoStep',
}

export const calTax = function (gross: number, tax: number, precision: number = 2) {
  if (tax === 0) return 0
  return roundNumber(gross * (1 - 1 / (1 + tax / 100)), precision)
}

export function calNet(gross: number, tax: number, precision: number = 2) {
  if (tax === 0) return gross
  return roundNumber(gross * (1 / (1 + tax / 100)), precision)
}

export function calGross(net: number, tax: number, precision: number = 2) {
  if (net === 0) return 0
  return roundNumber(net * (1 + tax / 100), precision)
}

//design vPrice -> discount + fee, unitPrice -> vPrice + modifiers

export function calItemTax(item: OrderItem, tax: number, precision: number = 2, originalPrice = false) {
  let unitPrice = (item.vPrice || 0) + _.sumBy(item.modifiers, 'vSum')
  if (originalPrice) {
    unitPrice = (item.price || 0) + _.sumBy(item.modifiers, m => m.price * m.quantity)
  }
  if (!config.isNetPriceBase) {
    return calTax(unitPrice * item.quantity, tax || 0, precision)
  } else {
    return roundNumber(((tax as number) * unitPrice * item.quantity) / 100, precision) || 0
  }
}

export function calItemNet(item: OrderItem, tax: number, precision: number = 2, originalPrice = false) {
  let unitPrice = (item.vPrice || 0) + _.sumBy(item.modifiers, 'vSum')
  if (originalPrice) {
    unitPrice = (item.price || 0) + _.sumBy(item.modifiers, m => m.price * m.quantity)
  }
  if (!config.isNetPriceBase) {
    return roundNumber(calNet(unitPrice * item.quantity, tax || 0), precision)
  } else {
    return roundNumber(unitPrice * item.quantity, precision)
  }
}

export function calItemVSum(item: OrderItem, tax: number, precision: number = 2, originalPrice = false) {
  if (item.taxComponents && item.taxComponents.length > 0) {
    let vSum = calItemNet(item, 0, orderConfig.sumPrecision)
    if (orderConfig.isNetPriceBase) {
      for (const taxComponent of item.taxComponents) {
        vSum += calItemTax(item, taxComponent!.value!, orderConfig.sumPrecision)
      }
    }
    return vSum
  }
  let unitPrice = (item.vPrice || 0) + _.sumBy(item.modifiers, 'vSum')
  if (originalPrice) {
    unitPrice = (item.price || 0) + _.sumBy(item.modifiers, m => m.price * m.quantity)
  }
  if (!config.isNetPriceBase) {
    return roundNumber(unitPrice * item.quantity, precision)
  } else {
    return roundNumber(calGross(unitPrice * item.quantity, tax || 0, precision), precision)
  }
}

export function calItemVSumByPath(item: OrderItem, pricePath: 'price' | 'vPrice' = 'price', precision = 2) {
  const unitPrice = (item[pricePath] || 0) + _.sumBy(item.modifiers, m => (m[pricePath] || 0) * m.quantity)
  if (!config.isNetPriceBase) {
    return roundNumber(unitPrice * item.quantity, precision)
  } else {
    return roundNumber(calGross(unitPrice * item.quantity, item.tax || 0), precision)
  }
}

export function calItemVTotal(item: OrderItem, pricePath: 'price' | 'vPrice' = 'price', precision = 2) {
  return roundNumber((item[pricePath] || 0) + _.sumBy(item.modifiers, m => (m[pricePath] || 0) * m.quantity), precision)
}

export function mergeVTaxGroup(objValue?: Tax, itemValue?: Tax) {
  const precision = 4
  if (!objValue) return itemValue
  if (!itemValue) return objValue
  return {
    tax: roundNumber(objValue.tax + itemValue.tax, precision),
    net: roundNumber(objValue.net + itemValue.net, precision),
    gross: roundNumber(objValue.gross + itemValue.gross, precision),
  }
}

export function getTimezone() {
  if (masterDeviceSetting()?.timezone) {
    if (localStorage.getItem('master-timezone') !== masterDeviceSetting()!.timezone) {
      localStorage.setItem('master-timezone', masterDeviceSetting()!.timezone!)
    }
    return masterDeviceSetting()!.timezone!
  }
  const checkLocalTimezone = localStorage.getItem('master-timezone')
  if (!!checkLocalTimezone) return checkLocalTimezone
  const timezone = dayjs.tz.guess()
  localStorage.setItem('master-timezone', timezone)
  return timezone
}

export function getVDate(date: number) {
  return dayjs.unix(date).tz(getTimezone()).subtract(beginHour(), 'hour').startOf('day').unix()
}

// @ts-ignore
window.getVDate = getVDate

export function getVDateDayjs(date: Dayjs) {
  return date.clone().tz(getTimezone()).subtract(beginHour(), 'hour').startOf('day')
}

export function getTodayVDateISO() {
  return toISOString(getVDateDayjs(dayjs(now())).unix())
}

export function calPriceWDiscount(price: number, discount?: string | number, precision = 4) {
  if (discount === undefined) return price
  if (typeof discount === 'string' && discount.includes('%')) {
    const _discount = parseFloat(discount.replace('%', ''))
    return roundNumber((price * (100 - _discount)) / 100, precision)
  } else if (typeof discount === 'string') {
    return roundNumber(price - parseFloat(discount), precision)
  } else {
    return roundNumber(price - discount, precision)
  }
}

export function itemCompareFactory() {
  return {
    isSameItem,
    isSameItem2
  }

  function compareNonModifier(item1: OrderItem, item2: OrderItem, countSentProp = true) {
    if (item1.note !== item2.note) return false;
    if (item1.course && item2.course && item1.course !== item2.course) return false;
    if (countSentProp && item2.sent) return false;
    if (item1.isVoucher || item2.isVoucher) return false;
    if (item1.seat !== item2.seat) return false;
    return true;
  }

  function compareBasicAttributes(item1: OrderItem, item2: OrderItem) {
    return (
      item1.name === item2.name &&
      item1.price === item2.price &&
      item1.isKitchenDone === item2.isKitchenDone &&
      ((!_.get(item1, 'taxes.0') && !_.get(item2, 'taxes.0')) || _.get(item1, 'taxes.0') === _.get(item2, 'taxes.0')) &&
      ((!_.get(item1, 'taxes.1') && !_.get(item2, 'taxes.1')) || _.get(item1, 'taxes.1') === _.get(item2, 'taxes.1'))
    );
  }

  function isSameItem(item1: OrderItem, item2: OrderItem, countSentProp = true) {
    if (!compareNonModifier(item1, item2, countSentProp) ||
      item1.modifiers.length > 0 ||
      item2.modifiers.length > 0) {
      return false;
    }

    return compareBasicAttributes(item1, item2)
  }


  function isSameItem2(item1: OrderItem, item2: OrderItem, countSentProp = true) {
    if (!compareNonModifier(item1, item2, countSentProp)) return false;
    if (item1.discount !== item2.discount) return false;

    if (item1.modifiers?.length === 0 && item2.modifiers?.length === 0) return compareBasicAttributes(item1, item2)

    if (!checkSameModifier(item1.modifiers, item2.modifiers)) return false

    return compareBasicAttributes(item1, item2)
  }
}

function checkSameModifier(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const removeIdKey = (obj) => {
    const { id, commitRef, ...rest } = obj;
    return rest;
  };

  const sortedArr1 = [...arr1]
    .map(removeIdKey)
    .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

  const sortedArr2 = [...arr2]
    .map(removeIdKey)
    .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

  return sortedArr1.every((value, index) => JSON.stringify(value) === JSON.stringify(sortedArr2[index]));
}

export const getBeginHourAsMinutes = () => {
  const beginHourDayjs = dayjs(orderConfig.beginHour, 'HH:mm')
  return beginHourDayjs.hour() * 60 + beginHourDayjs.minute()
}

export const getBeginDate = (from: number) => {
  return dayjs.unix(from).tz(getTimezone()).subtract(getBeginHourAsMinutes(), 'minute').startOf('day').toDate()
}

export const getEndDate = (to: number) => {
  return dayjs.unix(to).tz(getTimezone()).subtract(getBeginHourAsMinutes(), 'minute').endOf('day').toDate()
}

export const getBeginDateUnix = (from: number) => {
  return dayjs.unix(from).tz(getTimezone()).subtract(getBeginHourAsMinutes(), 'minute').startOf('day').unix()
}

export const getEndDateUnix = (to: number) => {
  return dayjs.unix(to).tz(getTimezone()).subtract(getBeginHourAsMinutes(), 'minute').endOf('day').unix()
}

export const getLastRecentItems = (items: OrderItem[], lastPrintedRound: number = 0, order: Order) => {
  lastPrintedRound = order.lastPrintedRound!;
  const printCommitQuantity = order.commits?.filter(c => c.action === CommitAction.PRINT).length || 0
  if (printCommitQuantity > lastPrintedRound) {
    return []
  }
  return items
    .filter(item => item.printedRound === lastPrintedRound)
    .filter(i => i.quantity > 0)
    .map(i => {
      const item = _.cloneDeep(i)
      item.quantity = item.lastQuantity ? item.lastQuantity : item.quantity
      return item
    })
}

export const getLastOldItems = (items: OrderItem[], lastPrintedRound: number = 0, order: Order) => {
  lastPrintedRound = order.lastPrintedRound;
  return items.filter(item => item.printedRound! < lastPrintedRound).filter(i => i.quantity > 0)
}

export const filterTseCommits = (commits: ItemCommit[]) => {
  return commits.filter(c => c.action !== CommitAction.SET_TSE_METHOD)
}
