import { convertDocuments } from '@/data/data-utils'
import { dataLock } from '@/data/DataUtils.ts'
import { deviceSetting0, isInSrmTrainingMode } from '@/data/DeviceSettingSignal.ts'
import { isQuebecSrmEnabled, mainScreen, mainScreenVertical } from '@/data/PosSettingsSignal.ts'
import { User, UserRole } from '@/data/User'
import { loginUser, setLoginUser, setUsers0, users0 } from '@/data/UserSignal.ts'
import { onMountScreen, PosScreen, router } from '@/pos/PosRouter.ts'
import { effectOn, signal, useAsyncEffect } from '@/react/core/reactive.ts'
import { ModeConnect } from '@/react/Developer/DataMenu.logic'
import { userFLow } from '@/shared/logger.ts'
import MultiAwaitLock from '@/shared/MultiAwaitLock.ts'
import { srmCertLogic } from '@/srm/cert.logic'
import { SrmLogic } from '@/srm/logic'
import { SrmTransactionLogic } from '@/srm/transaction.logic'

import { clearStaffStatusOnAllTables } from './TableStaffStatusHub'
import { isValidSrmUser, loginUsersEditSettingPermission } from './UserSignal.computed'
import { AppMode, appMode } from "@/react/AppMode.ts";


export const [userV, setUserV] = signal<number>(0)
export const [openSRMCertificateWarningPopup, setOpenSRMCertificateWarningPopup] = signal<boolean>(false)
const userLock = new MultiAwaitLock()
userLock.tryAcquire()

effectOn(
  [userV],
  async () => {
    await dataLock.acquireAsync()
    const _users = await User.find().exec()
    const users = convertDocuments<User>(_users, true)
    setUsers0(users)
    if (users.length > 0) {
      userFLow('🔑 Users loaded')
      userLock.release().then()
    }
  },
  { defer: true }
)

export const makeUsersAvailable = () => {
  useAsyncEffect(async () => {
    if (userV() === 0) {
      await dataLock.acquireAsync()
      User.$.subscribe(() => setUserV(v => v + 1))
      setUserV(1)
    }
  }, [userV()])
}

export const login = async (passcode: string) => {
  userFLow('🔑 Logging in using passcode', passcode)
  await userLock.acquireAsync()
  userFLow('🔑 User lock acquired')
  if (users0().length === 0 && passcode === '0000') {
    userFLow('🔑 Logging in case no users')
    if (isQuebecSrmEnabled()) await clearStaffStatusOnAllTables()
    return true
  }
  const user = users0().find(user => user.passcode === passcode)
  if (user) {
    userFLow('🔑 Logging in as', user.name)
    setLoginUser(user)
    localStorage.setItem('loggedPasscode', passcode)
    if (isQuebecSrmEnabled()) {
      await clearStaffStatusOnAllTables()
      // Check certificate validity asynchronously after login
      if (localStorage.getItem('modeConnect') !== ModeConnect.DEVELOPER.toString()) {
        setTimeout(async () => {
          const result = srmCertLogic.checkCertificateValidity(deviceSetting0()?.srm?.cert)
          userFLow('🔑 Certificate validity:', result)
          if (result.status !== 'ok') await srmCertLogic.promptUserToUpdateCert(result)
        }, 500)
      }
    }
    return true
  }
  userFLow('🔑 Logging in failed no user')
  return false
}

export const logOut = async (isVertical: boolean = false) => {
  if (appMode() === AppMode.Kiosk) {
    router.screen = PosScreen.KIOSK_START_SCREEN
  }
  const user = loginUser()?.name
  // We need to impersonate the current user because we will log him out right away
  if (isValidSrmUser()) {
    // Because we does not await here, need to pass the user to impersonate before log out
    // Send offline transactions, if any
    new SrmTransactionLogic({ impersonate: user }).sendOfflineTransactionsUnattended()
    // Turn off training mode when logout
    if (isInSrmTrainingMode()) new SrmLogic({ impersonate: user }).setSrmTrainingMode(false)
  }
  if (isQuebecSrmEnabled()) await clearStaffStatusOnAllTables()

  console.log('🚶‍♂️ Logging out as', user)
  setLoginUser()
  router.screen = isVertical ? PosScreen.VERTICAL_PASSCODE : PosScreen.PASSCODE
  localStorage.removeItem('loggedPasscode')
}

export const autoLogin = async () => {
  const user = users0().find(user => ['admin', 'Admin'].includes(user.name!) || user.role === UserRole.ADMIN)
  if (user) {
    return await login(user.passcode ?? '')
  }
}

onMountScreen(mainScreen(), async () => {
  await userLock.acquireAsync()
  if (import.meta.env.MODE === 'development') {
    console.log('loginUsersEditSettingPermission', loginUsersEditSettingPermission())
    if (!(await autoLogin())) await logOut()
  }
})

onMountScreen(mainScreenVertical(), async () => {
  await userLock.acquireAsync()
  if (import.meta.env.MODE === 'development') {
    console.log('loginUsersEditSettingPermission', loginUsersEditSettingPermission())
    if (!(await autoLogin())) await logOut(true)
  }
})