import type { ThermalPrinter } from "@/data/GroupPrinter";
import type { Order } from "@/data/Order";
import { MarketPlaceProvider, type OrderItem, OrderType } from "@/pos/OrderType";
import takeAwayIcon from "@/react/Printer/take-away-icon";
import Printer from "@/shared/printer/pure-image-printer-parallel";
import dayjs from "dayjs";
import _ from "lodash";
import { clone } from "rxdb";
import { getLL2 } from "@/react/core/I18nBackend.tsx";
import { getMaxCourse } from "@/pos/logic/order-reactive.ts";
import { PrinterMode, type VPrinter } from "@/react/Printer/VPrinter.ts";
import { getTicketNumber } from "@/react/Printer/print-label.ts";
import { loginUser } from "@/data/UserSignal.ts";
import type { ScriptedRaster } from "@/shared/printer/types"
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

function getCouponName(str: string) {
  return str.slice(str.indexOf('[') + 1, str.lastIndexOf(']')) || '';
}

export function getReceiptsFromOrder(items: Array<OrderItem>): { [k: string]: Array<OrderItem> } {
  return _.reduce<OrderItem, { [k: string]: Array<OrderItem> }>(items, function (obj, item) {
    function addItem(i: number) {
      if (!item.groupPrinter && !item.groupPrinter2) return;

      const groupPrinter = i === 1 ? item.groupPrinter : item.groupPrinter2;
      if (!groupPrinter) return;

      const receiptKey = JSON.stringify({
        groupPrinter: groupPrinter,
        course: item.course,
      })

      if (!obj[receiptKey]) obj[receiptKey] = [];

      if (!_.find(item.modifiers, m => m.groupPrinter && m.groupPrinter !== groupPrinter)) {
        obj[receiptKey].push(clone(item));
      } else {
        // case modifiers have another groupPrinter

        // handle if all modifiers have another groupPrinter
        if (!_.find(item.modifiers, m => m.groupPrinter && m.groupPrinter === groupPrinter)) {
          const _item = _.omit(item, ['modifiers']) as OrderItem;
          obj[receiptKey].push(_item);
        }

        const modifierGroup = _.groupBy(item.modifiers, i => i.groupPrinter ? i.groupPrinter : groupPrinter);
        for (let _groupPrinter in modifierGroup) {
          const _item = {
            ...item,
            groupPrinter: _groupPrinter,
            modifiers: modifierGroup[_groupPrinter]
          }

          const _receiptKey = JSON.stringify({
            groupPrinter: _groupPrinter,
            course: item.course,
          })

          if (!obj[_receiptKey]) obj[_receiptKey] = [];

          obj[_receiptKey].push(_.cloneDeep(_item));
        }
      }
    }

    if (item.groupPrinter === item.groupPrinter2) {
      [1].forEach(addItem);
    } else {
      [1, 2].forEach(addItem);
    }
    //if item is separator, make last item of all current receipts be separator too
    if (item.separate) {
      _.values(obj).forEach(receipt => {
        receipt[receipt.length - 1].separate = true
      })
    }
    return obj;
  }, {});
}

export function createPrinter(): Printer {
  return new Printer(560, {
    printFunctions: {
      printRaster: async ({ data, width, height }) => {
        return { data, width, height };
      }
    },
    defaultPrintTarget: 'raster'/* : 'raster'*/
  });
}

export async function printKitchenReceipt(groupPrinterName: string, groupPrinter: ThermalPrinter, course: number, items: Array<OrderItem>, order: Order, cancel: boolean, isEntireReceipt: boolean, printer: VPrinter, isReprint = false): Promise<ScriptedRaster> {
  //fixme: remove this lines
  // course = 0;
  const user = loginUser()?.name;
  const ticketNumber = getTicketNumber(order)
  const LL = getLL2();
  const kitchen = LL().printing._kitchen;
  // const printer = createPrinter();
  const { pickUpNumber, note, table, externalId, provider, customerRaw, date, pickupDate, dropOffDate } = order;
  const takeAway = course === 0 || order.takeAway;
  const pickup = order?.type == OrderType.PickUp;
  const delivery = order?.type == OrderType.Delivery;
  const {
    hidePrinterName,
    showProductPrice,
    hideProductName,
    invertColor,
    marginTop,
    fontSize,
    spaceBetweenItems,
    boldItems,
    fontSizeForReceiptHeader,
    fontSizeFooter,
  } = groupPrinter;
  const printerGeneralSetting = posSetting0()?.printerGeneralSetting
  const itemFontSize = 20 + ((fontSize || 1) - 1) * 6
  const headerFontSize = 35
  const orderInfoFontSize = fontSizeForReceiptHeader ? 20 + ((fontSizeForReceiptHeader || 1) - 1) * 6 : 26
  const footerFontSize = fontSizeFooter ? 20 + ((fontSizeFooter || 1) - 1) * 6 : 26
  await printer.newLine()
  await printer.marginTop(marginTop || 0)

  //add reprint in the title
  if (isReprint) {
    await printer.setFontSize(40)
    await printer.alignCenter()
    await printer.setTextQuadArea()
    await printer.bold(true);
    await printer.println(`${_.upperCase(LL().srm.caption.reprint())}`);
    await printer.newLine();
  }

  if (cancel) {
    await printer.setFontSize(40)
    await printer.alignCenter()
    await printer.setTextQuadArea()
    await printer.bold(true);
    await printer.println(`${kitchen.cancel()}`)
    await printer.newLine();
  }

  await printer.alignLeft();
  await printer.setTextNormal();

  if (pickUpNumber) {
    await printer.println(`${LL().printing.pickUpNumber()}: ${pickUpNumber}`)
  }
  if (takeAway) {
    await printer.alignCenter()
    if (printerGeneralSetting?.takeAwayLabel && printerGeneralSetting?.takeAwayLabel !== 'icon') {
      await printer.setFontSize(headerFontSize + 5)
      await printer.bold(true);
      await printer.println(`${_.upperCase(LL().printing.takeAway())}`);
      await printer.newLine();
      await printer.setTextNormal()
    } else {
      await printer.printImage(takeAwayIcon, 'base64', 1 / 6.3)
    }
    await printer.alignLeft()
  }

  await printer.alignCenter()
  await printer.bold(true);
  await printer.setFontSize(headerFontSize + 5)
  if (table) {
    await printer.println(`${LL().printing.table()}: ${table}`);
  }
  if (!table && !provider) {
    await printer.println(`${_.upperCase(LL().dashboard.fastCheckout())}`);
  }
  if (!table && provider) {
    if (pickup) {
      await printer.println(`${_.upperCase(LL().editOnlineMenu.pickup())}`);
    }
    if (delivery) {
      await printer.println(`${_.upperCase(LL().editOnlineMenu.delivery())}`);
    }
  }
  await printer.alignLeft()
  await printer.bold(false);
  //header
  await printer.setFontSize(orderInfoFontSize)
  if (ticketNumber) {
    await printer.bold(true)
    await printer.println(`${LL().printing.ticketNumber()}: ${ticketNumber}`);
    await printer.bold(false)
  }
  if (externalId) {
    if (provider === MarketPlaceProvider.UBER_EATS) {
      await printer.println(`ID: #${externalId.substring(externalId.length - 6)}`)
    } else if (provider === MarketPlaceProvider.RESTAURANT_PLUS) {
      await printer.println(`ID: #${externalId.substring(externalId.length - 6)}`)
    } else {
      await printer.println(`ID: #${externalId}`)
    }
  }

  if (provider) {
    if (provider !== MarketPlaceProvider.PHONE)
      await printer.println(`P: ${provider}`)
    await printer.println(`H: ${dayjs(pickupDate).format(`${LL().dates.dateFormat()} ${LL().dates.timeFormat()}`)}`)
  }
    customerRaw?.name && await printer.println(`N: ${customerRaw.name}`);
    customerRaw?.phone && await printer.println(`T: ${customerRaw.phone}`);
    customerRaw?.email && await printer.println(`E: ${customerRaw.email}`);
    customerRaw?.address && await printer.println(`A: ${customerRaw?.extraAddressInfo ? customerRaw?.extraAddressInfo + ', ' : ''}${customerRaw.address}`);

  if (note) {
    await printer.println(note)
  }
  if (customerRaw?.note) {
    await printer.println(`${customerRaw?.note}`);
  }
  //header
  {
    await printer.alignRight();
    !provider && await printer.println(dayjs.unix(order.date || 0).format('HH:mm'));
    await printer.drawLine();
    await printer.alignLeft();
  }

  if (externalId && order.discountDetails && order.discountDetails?.length) {
    const discountDetails = order.discountDetails ?? [];
    for (const discount of discountDetails) {
      const name = getCouponName(discount.name);
      const value = Number(discount.value);
      await printer.leftRight(`${_.upperCase(LL().ui.promotion())} ${name ? `(${name})` : ''}`, LL3().format.currency(value || 0))
    }
    await printer.drawLine();
  }

  const maxQuantity = (_.maxBy(items, 'quantity') || { quantity: 0 }).quantity

  const modifierFontSize = _.round(0.65 * itemFontSize)

  const seatExists = items.find(i => i.hasOwnProperty('seat'));

  async function printItemsFn(items: OrderItem[]) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      await printer.bold(false);
      await printer.setTextQuadArea();
      const quantityColumnWidth = (maxQuantity.toString().length + 1) * 0.05;


      await printer.setFontSize(_.round(itemFontSize * 0.75))
      await printer.bold(boldItems ?? true)
      const itemContent = `${item.id ? item.id + '. ' : ''}${hideProductName ? '' : item.name + ' ' + (showProductPrice ? item.price : '')}`
      await printer.println(`${item.quantity} x ${itemContent}`)
      await printer.bold(false)
      //   { text: item.quantity, align: 'LEFT', width: quantityColumnWidth, bold: true },
      //   { text: 'x', align: 'LEFT', width: 0.05, bold: false },
      //   { text: , align: 'LEFT', width: 1 - 0.05 - quantityColumnWidth, bold: false},
      // ]);
      // await printer._reset()
      if (item.modifiers) {
        if (invertColor) await printer.invert(true)
        await printer.setFontSize(modifierFontSize);

        for (let j = 0; j < item.modifiers.length; j++) {
          const mod = item.modifiers[j];
          let modifierText = `* ${mod.quantity > 1 ? mod.quantity + ' x ' : ''} ${mod.name}  ${showProductPrice ? mod.price : ''}`
          //fixme:
          // if (mod.price) modifierText += ` ${convertMoney(mod.price)}`;
          await printer.println(modifierText)
        }
        if (invertColor) printer.invert(false)
      }

      if (item.note) {
        await printer.println(`\t${item.note}`)
      }

      if (i < items.length - 1) {
        await printer.setTextNormal();
        if (item.separate) {
          await printer.newLine()
          await printer.alignCenter()
          await printer.println(kitchen.horizontalLine());
          await printer.newLine()
          await printer.alignLeft()
        } else {
          await printer.newLine();
        }
      }
      spaceBetweenItems && await printer.println('')
    }
  }

  if (!seatExists) {
    await printItemsFn(items)
  } else {
    const seatGroup = _.groupBy(items, i => i.seat);
    for (const seat of Object.keys(seatGroup)) {
      const _items = seatGroup[seat];
      await printer.bold(true);
      await printer.alignCenter();
      await printer.setTextQuadArea();
      await printer.println(`** ${LL().payment.seat().toUpperCase()} ${Number(seat) + 1} **`)
      await printer.alignLeft();
      await printer.bold(false);
      await printer.setFontSize(itemFontSize)
      await printItemsFn(_items)
      await printer.println(' ')
    }
  }


  await printer.setTextNormal();
  await printer.bold(true);
  await printer.drawLine();

  await printer.setTextNormal();
  await printer.bold(true);
  await printer.alignCenter();

  function getCourseText(course: number): string {
    if (getMaxCourse(order.items) <= 1) return ''
    if (course === 0) return kitchen.takeAway();
    return `${kitchen.course()} ${course}`
  }

  // footer
  await printer.setFontSize(footerFontSize)
  if (getCourseText(course)) await printer.println(getCourseText(course));
  await printer.setTextNormal();
  if (!hidePrinterName) {
    if (!isEntireReceipt) {
      const footerText = `${kitchen.printer({ printer: groupPrinterName })}${user ? ` - ${user}` : ''}`
      await printer.println(footerText);
    } else await printer.println(kitchen.entireReceipt());
  }

  if (externalId) {
    const {payments} = order
    if (_.isEmpty(payments)) {
      await printer.println(kitchen.unpaid());
    } else {
      if (payments[0].extraType === "cash") {
        await printer.println(kitchen.unpaid());
      } else {
        await printer.println(kitchen.paid());
      }
    }
  }

  const raster = await printer.getRaster();
  return raster;
}

