import { type FunctionComponent } from "react";
import TextField from "@/react/core/TextField.tsx";
import _ from "lodash";
import { category0, product0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { PRODUCT_COLORS } from "@/react/EditMenuView/EditMenuView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export type EditCategoryFormType = {
  className?: string;
};

const VerticalEditCategoryForm: FunctionComponent<EditCategoryFormType> = ({
  className = "",
}) => {
  return (
    <div
      className={`!flex !w-full w-[480px] hidden flex-col items-start justify-start pt-2 px-3 pb-4 box-border gap-4 text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish lg:box-border mq350small:pl-3 mq350small:pr-[13px] mq350small:box-border ${className}`}
    >
      <div className="self-stretch flex flex-row items-center justify-start gap-3">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          <span>{LL0().article.name()}</span>
          <span className="text-red-solid-red-550-cf2222">*</span>
          <span>:</span>
        </b>
        <TextField
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          sx={{ "& .MuiInputBase-root": { height: "37px" } }}
          value={category0()?.name || ''}
          onChange={(e) => _.assign(category0(), { name: e.target.value })}
        />
      </div>
      <div className="self-stretch flex flex-row items-start justify-start gap-3">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          {LL0().restaurant.color()}:
        </b>
        <div className="flex-1 flex flex-row items-start justify-start flex-wrap content-start gap-3">
          {PRODUCT_COLORS.map((color, index) =>
            <div className="rounded-981xl flex flex-col items-start justify-start relative"
                 key={index}
                 style={{ backgroundColor: color }}
                 onClick={() => {
                   _.assign(category0()?.layout, { color })
                 }}
            >
              <div className="w-10 relative rounded-981xl border-gray-solid-gray-320-d2d2d2 border-[1px] border-solid box-border h-10 z-[0]" />
              {category0()?.layout?.color === color && <>
                <div className="w-10 absolute !m-[0] top-[-0.1px] left-[0px] rounded-981xl border-blue-solid-blue-450-1271ff border-[1px] border-solid box-border h-10 z-[1]" />
                <img
                  className="w-4 absolute !m-[0] top-[0px] right-[0px] h-4 object-cover z-[2]"
                  alt=""
                  src="/iconedit-menuchecked-icon-ver1@2x.png"
                />
              </>}
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default VerticalEditCategoryForm;