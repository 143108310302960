import { type FunctionComponent, useContext } from 'react';
import SplitBill from '@order-view/SplitBill';
import SplitPopupSeatsArrow from '@order-view/SplitPopupSeatsArrow';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';

const PaymentSplitPopup: FunctionComponent<{show?: boolean}> = ({show= false}) => {
  const { splitEnable } = useContext(ItemFactoryContext);
  
  if (splitEnable?.()) show = true
  return show && (
    <div className="z-[99] !bg-[rgba(77,86,99,0.95)] !flex !pl-[5%] my-0 mx-[!important] absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] bg-dimgray-300 hidden flex-row items-start justify-end pt-4 pb-6 pr-2 pl-[228px] box-border gap-[12px] z-[1] text-left text-mini text-gray-400 font-mulish Tablet_600:pl-20 Tablet_600:box-border Mobile_480:pl-10 Mobile_480:box-border">
      <SplitBill />
      <SplitPopupSeatsArrow />
    </div>
  );
};

export default PaymentSplitPopup;
