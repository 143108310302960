import { type FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";
import { onPrint } from "@/react/OrderView/OrderView.tsx";

type PrintBeforeSplitPopup = {
  onClose?: () => void;
};

const PrintBeforeSplitPopup: FunctionComponent<
  PrintBeforeSplitPopup
> = ({ onClose }) => {

  const onConfirmed = async() => {
    onClose?.();
    await onPrint(true)
  }

  return (
    <div className="rounded-2xl bg-basic-color-white shadow-[0px_0px_4px_rgba(0,_0,_0,_0.1)] [backdrop-filter:blur(30px)] w-[450px] overflow-hidden flex flex-col items-center justify-center p-3 box-border gap-[5px] min-w-[300px] max-w-full max-h-full text-center text-xl text-red-solid-red-400-d93b5c font-mulish-button-name-bold">
      <div className="self-stretch flex flex-col items-center justify-start gap-[4px] mb-3">
        <b className="self-stretch relative leading-[28px] mb-2">
          {LL0().dialogs.printKitchen.printOrder()}
        </b>
        <div className="self-stretch relative text-base leading-[20px] font-medium text-blue-solid-blue-900-081a51 mt-2">
          {LL0().dialogs.printKitchen.notification()}
        </div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[350px] text-sm text-blue-solid-blue-200-017efa mt-3">
        <button ref={makeRipple}
                className="flex-1 rounded-md bg-basic-color-white overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-200-017efa"
                onClick={onClose}
        >
          <b className="flex-1 relative">{LL0().ui.cancel()}</b>
        </button>
        <button ref={makeRipple} onClick={onConfirmed} className="flex-1 rounded-md [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 text-basic-color-white">
          <b className="relative">{LL0().ui.print()}</b>
        </button>
      </div>
    </div>
  );
};

export default PrintBeforeSplitPopup;