// import '@/lib/inject-hack-localstorage.ts';
import '@/shared/debug.ts'
import "@preact/signals-react/auto";
import * as ReactDOM from "react-dom/client";
import "./index.css";
import {FullApp3} from "@/react/FullApp3.stories";
import { initSentry } from './lib/sentry'
import { ErrorBoundary } from "react-error-boundary";
import { captureException } from "@sentry/react";
import { switchToSecondDisplayMode } from "./react/Installation/SecondDisplayConfig.logic"
import { isSecondDisplay, shouldAutoSwitch } from './react/Installation/SecondDisplayConfig.state'


if(!import.meta.env.DEV) initSentry()

function Fallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <pre style={{ color: "red" }}>{(error as Error).stack}</pre>
    </div>
  );
}

// Navigate to second display page
if (import.meta.env.PROD && isSecondDisplay() && shouldAutoSwitch()) switchToSecondDisplayMode()

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ErrorBoundary
    FallbackComponent={Fallback}
    onReset={(details) => {
      // Reset the state of your app so the error doesn't happen again
    }}
    onError={e => captureException(e)}
  >
    <FullApp3 />
  </ErrorBoundary>
);
