import { type FunctionComponent, useState, useCallback } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import PrinterTemplatePopup from "@printer-setting/PrinterTemplatePopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import _ from 'lodash';
import {
  currentPrinter,
  getInfoFor, PrinterMode,
  printerSettingMode,
  printerSwitchProps, selectedGroupPrinter, selectedGroupPrinterId
} from '@/react/Printer/PrinterSettingView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import type { PrinterGeneralSetting } from "@/data/PosSetting.ts";
import { kitchenGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";


export type PrinterConfigsFormType = {
  className?: string;
  iconQuestionIcon?: string;
  showAnItem?: boolean;
  anItemVisible?: boolean;
  anItemVisible1?: boolean;
  anItemVisible2?: boolean;
  anItemVisible3?: boolean;
  anItemVisible4?: boolean;
  anItemVisible5?: boolean;

  isRecheckReceipt?: boolean;
  isClientReceipt?: boolean;
};

const PrinterConfigsForm: FunctionComponent<PrinterConfigsFormType> = ({
  className = "",
  iconQuestionIcon,
  showAnItem,
  anItemVisible,
  anItemVisible1,
  anItemVisible2,
  anItemVisible3,
  anItemVisible4,
  anItemVisible5,
  isRecheckReceipt,
  isClientReceipt,
}) => {

  const [open, setOpen] = useState(false)
  const [popupTitle, setPopupTitle] = useState('')
  const [popupContent, setPopupContent] = useState('')

  const closePopup = useCallback(() => {
    userFLow(`close popup`, {
      username: loginUser()?.name
    });
    setOpen(false)
  }, [])
  const openPopupFor = useCallback(
    (field: keyof PrinterGeneralSetting) => () => {
      userFLow(`open popup for ${field}`, {
        username: loginUser()?.name
      });
      const [title, content] = getInfoFor(field)
      setPopupTitle(title)
      setPopupContent(content)
      setOpen(true)
    },
    []
  )

  return (
    <>
      <div
        className={`self-stretch flex flex-row items-start justify-start gap-[24px] Tablet_768:flex-col Tablet_768:gap-[12px] ${className}`}
      >
        <div
          className="flex-1 flex flex-col items-start justify-start gap-[12px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  label=''
                  control={<Switch color="primary" size="medium"
                                   {...printerSwitchProps("sound")}
                  />}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.sound()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src={iconQuestionIcon}
              onClick={openPopupFor("sound")}
            />
          </div>

          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  label=''
                  control={<Switch color="primary" size="medium"
                                   {...printerSwitchProps("tscPOS")}
                  />}
                />
              </div>
              <div className="flex-1 relative">{LL0().printerSetting.tscPOS()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src={iconQuestionIcon}
              onClick={openPopupFor("tscPos")}
            />
          </div>

          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  label=''
                  control={<Switch color="primary" size="medium"
                                   {...printerSwitchProps("keepConnection")}
                  />}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.keepConnection()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src={iconQuestionIcon}
              onClick={openPopupFor("keepConnection")}
            />
          </div>
          {isClientReceipt &&
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("merge")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.mergeNameAndQuantity()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("merge")}
              />
            </div>
          }
          {isClientReceipt &&
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("printCustomerInfo")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.printCustomerInfo()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("printCustomerInfo")}
              />
            </div>
          }
          {showAnItem && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("merge")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.merge()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("merge")}
              />
            </div>
          )}
          {anItemVisible && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("hidePrinterName")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.hidePrinterName()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("hidePrinterName")}
              />
            </div>
          )}
          {anItemVisible1 && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("hideProductName")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.hideProductName()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("hideProductName")}
              />
            </div>
          )}
          {printerSettingMode() === PrinterMode.NORMAL && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch
                      color="primary" size="medium"
                      checked={selectedGroupPrinter()?.isDefault || false}
                      onChange={(e: any) => {
                        if (e.target.checked) {
                          _.assign(selectedGroupPrinter(), { isDefault: true })
                          kitchenGroupPrinters0().filter(k => k._id !== selectedGroupPrinterId()).map(g => g.isDefault = false)
                        }
                      }}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().printing.defaultPrinter()}</div>
              </div>
              {/*<img*/}
              {/*  className="w-6 relative h-6 object-cover cursor-pointer"*/}
              {/*  alt=""*/}
              {/*  src={iconQuestionIcon}*/}
              {/*  onClick={openPopupFor("hideProductName")}*/}
              {/*/>*/}
            </div>
          )}
        </div>
        <div
          className="flex-1 flex flex-col items-start justify-start gap-[12px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  label=''
                  control={<Switch color="primary" size="medium"
                                   {...printerSwitchProps("escPOS")}
                  />}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.escPos()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src={iconQuestionIcon}
              onClick={openPopupFor("escPos")}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
              <div className="w-12 relative h-6">
                <FormControlLabel
                  className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                  label=''
                  control={<Switch color="primary" size="medium"
                                   {...printerSwitchProps("flashed")}
                  />}
                />
              </div>
              <div className="flex-1 relative">{LL0().settings.flashed()}</div>
            </div>
            <img
              className="w-6 relative h-6 object-cover cursor-pointer"
              alt=""
              src={iconQuestionIcon}
              onClick={openPopupFor("flashed")}
            />
          </div>
          {isClientReceipt &&
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("hideUnitPrice")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.hideUnitPrice()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("hideUnitPrice")}
              />
            </div>
          }
          {isClientReceipt &&
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("printBarcode")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.printBarcode()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("printBarcode")}
              />
            </div>
          }
          {isClientReceipt &&
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("printTipLine")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.printTipLine()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("printTipLine")}
              />
            </div>
          }
          {anItemVisible2 && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("oneReceiptForOneArticle")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.splitArticles()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("splitArticles")}
              />
            </div>
          )}
          {anItemVisible3 && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("dateTimeFormat")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.useDatetimeFormat({ datetimeFormat: "HH:mm" })}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("dateTimeFormat")}
              />
            </div>
          )}
          {anItemVisible5 && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("invertColor")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.invertColor()}</div>
              </div>
              <img
                className="w-6 relative h-6 object-cover cursor-pointer"
                alt=""
                src={iconQuestionIcon}
                onClick={openPopupFor("invertColor")}
              />
            </div>
          )}
          {anItemVisible5 && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     {...printerSwitchProps("spaceBetweenItems")}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.spaceBetweenItem()}</div>
              </div>
              {/*<img*/}
              {/*  className="w-6 relative h-6 object-cover cursor-pointer"*/}
              {/*  alt=""*/}
              {/*  src={iconQuestionIcon}*/}
              {/*  onClick={openPopupFor("invertColor")}*/}
              {/*/>*/}
            </div>
          )}
          {anItemVisible5 && (
            <div className="self-stretch flex flex-row items-center justify-between">
              <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                <div className="w-12 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                    label=''
                    control={<Switch color="primary" size="medium"
                                     checked={currentPrinter()?.boldItems ?? true}
                                     onChange={(e: any) => _.assign(currentPrinter(), { boldItems: e.target.checked })}
                    />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().settings.boldItems()}</div>
              </div>
              {/*<img*/}
              {/*  className="w-6 relative h-6 object-cover cursor-pointer"*/}
              {/*  alt=""*/}
              {/*  src={iconQuestionIcon}*/}
              {/*  onClick={openPopupFor("invertColor")}*/}
              {/*/>*/}
            </div>
          )}
        </div>
      </div>
      {open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopup}
        >
          <PrinterTemplatePopup onClose={closePopup} title={popupTitle} content={popupContent} />
        </PortalPopup>
      )}

    </>
  );
};

export default PrinterConfigsForm;