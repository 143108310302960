import MonthlyReportPlugin from '@monthly-report/MonthlyReportPlugin.tsx'
import dayjs, { type Dayjs } from 'dayjs'
import { memo } from 'react'

import { dataLock } from '@/data/DataUtils.ts'
import { getEodReportPeriodAggregate } from '@/pos/logic/eod-calendar-utils.ts'
import { printMonthlyReport } from '@/pos/logic/monthly-report-print.ts'
import type { MonthlyReport } from '@/pos/logic/ReportType.ts'
import { onEnter, PosScreen, router } from '@/pos/PosRouter.ts'
import { queryTrainingMode, showTrainingMode } from '@/pos/trainingMode.ts'
import { deepSignal, effectOn, signal } from '@/react/core/reactive.ts'
import { printInvoiceFromRaster } from '@/react/Printer/print-invoice.ts'
import { mainScreen } from "@/data/PosSettingsSignal.ts";
import { makeCategoriesAvailable } from "@/data/CategoryHub.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export const [currentMonth, setCurrentMonth] = signal<Dayjs>(dayjs().startOf('M'))

// fromDate & toDate must be unix() (number)
export const [fromDate, setFromDate] = signal<number>(0)
export const [toDate, setToDate] = signal<number>(0)

type MonthlyReportConfig = {
  showProductSold?: boolean
  showMoreZNumber?: boolean
  showEmployeeTimeClock?: boolean
}

export const [monthlyReport, setMonthlyReport] = signal<MonthlyReport>()
export const [reportConfigs, setReportConfigs] = signal<MonthlyReportConfig>(
  deepSignal({
    showProductSold: true,
  })
)

// Make monthlyReport available in window for debugging
Object.assign(window, { monthlyReport })

effectOn([currentMonth], () => {
  setFromDate(currentMonth().startOf('M').unix())
  setToDate(currentMonth().endOf('M').unix())
})

export const [monthlyReportDataV, setMonthlyReportDataV] = signal<number>(0)

effectOn(
  [fromDate, toDate, monthlyReportDataV, queryTrainingMode],
  async () => {
    if (monthlyReportDataV() === 0) return
    await dataLock.acquireAsync()
    const _monthlyReport = await getEodReportPeriodAggregate(fromDate(), toDate())
    _monthlyReport.trainingMode = showTrainingMode()
    setMonthlyReport(_monthlyReport)
  },
  { defer: true }
)

export const onChangeMonth = (action: 'NEXT' | 'PREV') =>
  setCurrentMonth(prev =>
    prev
      .month(prev.month() + (action === 'NEXT' ? 1 : -1))
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
  )

export const onPrintMonthlyReport = async () => {
  userFLow(`click print monthly report`, {
    username: loginUser()?.name
  });
  const raster = await printMonthlyReport(monthlyReport())
  if (!raster) return
  await printInvoiceFromRaster(raster, { metadata: { date: dayjs().unix(), type: 'monthly-report' } })
}

export const onBack = () => {
  userFLow(`click back`, {
    username: loginUser()?.name
  });
  router.screen = mainScreen()
}
export const secondsToHoursMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h${minutes}'`;
}
const MonthlyReportView = () => {
  makeCategoriesAvailable()
  onEnter(PosScreen.MONTHLY_REPORT, async () => {
    // await dataLock.acquireAsync();
    // const _monthlyReport = await getEodReportPeriodAggregate(fromDate(), toDate());
    // setMonthlyReport(_monthlyReport);
    setMonthlyReportDataV(v => v + 1)
  })
  return <MonthlyReportPlugin />
}

export default memo(MonthlyReportView)
