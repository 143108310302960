import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const SECTION_COLLECTION_NAME = 'sections'

export interface Section {
  _id: string
  color?: string
  name?: string
  icon?: string
  //use for sorting
  order?: number
  categories?: string[]
}

export const VERSION = 5
const schema: RxJsonSchema<any> = {
  title: 'section schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 64 },
    icon: { type: 'string', maxLength: 64 },
    type: { type: 'enum', enum: ['online', 'local'] },
    order: { type: 'number' },
    color: { type: 'string' },
    categories: { type: 'array', items: { type: 'string' } },
  },
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

export const Section = {} as RxCollection<Section>

export const createSectionCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Section,
    collectionName: SECTION_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}

Object.assign(window, { Section }) // Make available global
