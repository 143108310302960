import { signal } from "@/react/core/reactive.ts";
import debug from "debug";
import { wakeUpEE } from "@/shared/wakeUpEE.ts";
import { logOut } from "@/data/UserHub.ts";

export const [isInternetConnected, setIsInternetConnected] = signal(true);
export const [isWifiConnected, setIsWifiConnected] = signal(true);

const log = debug('view:network')

declare global {
  interface Window {
    onNetworkInfo: (isInternetConnected: boolean, isWifiConnected: boolean) => void
  }
}

window.onNetworkInfo = onNetworkInfo

async function onNetworkInfo(isInternetConnected: boolean, isWifiConnected: boolean) {
  setIsInternetConnected(isInternetConnected);
  log(`⚡️ is internet connected ${isInternetConnected}`);
  setIsWifiConnected(isWifiConnected);
  log(`⚡️ is wifi connected ${isWifiConnected}`);

}