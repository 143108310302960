import { type FunctionComponent, useState, useCallback } from "react";
import OrderScreenFoodPopup from "@order-view/OrderScreenFoodPopup.tsx";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import OrderScreenCigarettePopu from '@order-view/OrderScreenCigarettePopu.tsx';
import { addCustomDish } from '@/react/OrderView/OrderView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";

type OrderSreenCustomDishPoType = {
  onClose?: () => void;
};

const OrderScreenCustomDishPo: FunctionComponent<OrderSreenCustomDishPoType> = ({
  onClose,
}) => {
  const handleBottleClick = (name: string, price: number, defaultName: string) => {
    addCustomDish(name, price, defaultName);
    onClose?.()
  }

  const [isOrderSreenCigarettePopuOpen, setOrderSreenCigarettePopuOpen] =
    useState(false);
  const [isOrderSreenFoodPopupOpen, setOrderSreenFoodPopupOpen] =
    useState(false);
  const [isOrderSreenFoodPopup1Open, setOrderSreenFoodPopup1Open] =
    useState(false);

  // close custom dish popup at the same time as children popups closed
  const openOrderSreenCigarettePopu = useCallback(() => {
    userFLow(`open order screen cigarette popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenCigarettePopuOpen(true);
  }, []);

  const closeOrderSreenCigarettePopu = useCallback(() => {
    userFLow(`close order screen cigarette popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenCigarettePopuOpen(false);
    onClose?.();
  }, []);

  const openOrderSreenFoodPopup = useCallback(() => {
    userFLow(`open order screen food popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenFoodPopupOpen(true);
  }, []);

  const closeOrderSreenFoodPopup = useCallback(() => {
    userFLow(`close order screen food popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenFoodPopupOpen(false);
    onClose?.();
  }, []);

  const openOrderSreenFoodPopup1 = useCallback(() => {
    userFLow(`open order screen food popup1`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenFoodPopup1Open(true);
  }, []);

  const closeOrderSreenFoodPopup1 = useCallback(() => {
    userFLow(`close order screen food popup1`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenFoodPopup1Open(false);
    onClose?.();
  }, []);

  return (
    <>
      <div className="w-full relative rounded bg-gray-solid-gray-300-dbdbdb overflow-hidden flex flex-col items-end justify-start min-w-[260px] h-full max-w-full max-h-full text-left text-sm text-black-solid-black-1000-000000 font-mulish">
        <div className="self-stretch flex flex-col items-end justify-start gap-[1px]">
          {!isQuebecSrmEnabled() &&
            <div className="self-stretch flex gap-[1px]">
              <div className="bg-white h-11 w-[50%] mq432:w-[40%] relative" />
              <div
                className="bg-white flex h-11 w-[50%] mq432:w-[60%] items-center justify-start py-0 px-2.5 box-border cursor-pointer relative"
                onClick={openOrderSreenCigarettePopu}
              >
                <div className="relative font-semibold">{LL0().div.cigarette()}</div>
              </div>
            </div>
          }
          <div className="self-stretch flex flex-row items-start justify-start gap-[1px]">
            <div
              className="self-stretch flex-1 mq432:w-[40%] bg-white-solid-white-100-ffffff flex flex-col items-center justify-center py-3 px-0 gap-[8px] cursor-pointer"
              onPointerDown={openOrderSreenFoodPopup}
            >
              <img
                className="w-10 relative h-10 object-cover"
                alt=""
                src="/orderdish-icon@2x.png"
              />
              <div className="relative font-semibold">{LL0().div.food()}</div>
            </div>
            {!isQuebecSrmEnabled() &&
              <div className="self-stretch flex-1 mq432:w-[60%] flex flex-col items-start justify-start gap-[1px]">
                <div className="self-stretch flex-1 bg-white-solid-white-100-ffffff flex flex-row items-center justify-start py-0 px-2.5"
                     onPointerDown={() => handleBottleClick("Bottle", 0.25, LL0().div.food())}
                >
                  <div className="relative font-semibold">{LL0().div.bottle_025()}</div>
                </div>
                <div className="self-stretch flex-1 bg-white-solid-white-100-ffffff flex flex-row items-center justify-start py-0 px-2.5"
                     onPointerDown={() => handleBottleClick("Collect bottle", -0.25, LL0().div.food())}
                >
                  <div className="relative font-semibold">
                    {LL0().div.collect_bottle_025()}
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="self-stretch flex flex-row items-end justify-start gap-[1px]">
            <div
              className="self-stretch flex-1 mq432:w-[40%] bg-white-solid-white-100-ffffff flex flex-col items-center justify-center py-3 px-0 gap-[8px] cursor-pointer"
              onPointerDown={openOrderSreenFoodPopup1}
            >
              <img
                className="w-10 relative h-10 object-cover"
                alt=""
                src="/orderdrink-icon@2x.png"
              />
              <div className="relative font-semibold">{LL0().div.drink()}</div>
            </div>
            {!isQuebecSrmEnabled() &&
              <div className="self-stretch flex-1 mq432:w-[60%] flex flex-col items-start justify-start gap-[1px]">
                <div className="self-stretch flex-1 bg-white-solid-white-100-ffffff flex flex-row items-center justify-start py-3 px-2.5"
                     onPointerDown={() => handleBottleClick("Bottle", 0.15, LL0().div.drink())}
                >
                  <div className="relative font-semibold">{LL0().div.bottle_015()}</div>
                </div>
                <div className="self-stretch flex-1 bg-white-solid-white-100-ffffff flex flex-row items-center justify-start py-3 px-2.5"
                     onPointerDown={() => handleBottleClick("Collect bottle", -0.15, LL0().div.drink())}
                >
                  <div className="relative font-semibold">
                    {LL0().div.collect_bottle_015()}
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {isOrderSreenCigarettePopuOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOrderSreenCigarettePopu}
        >
          <OrderScreenCigarettePopu onClose={closeOrderSreenCigarettePopu} />
        </PortalPopup>
      )}
      {isOrderSreenFoodPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderSreenFoodPopup}
        >
          <OrderScreenFoodPopup onClose={closeOrderSreenFoodPopup} defaultName={LL0().div.food()} type={"food"} />
        </PortalPopup>
      )}
      {isOrderSreenFoodPopup1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOrderSreenFoodPopup1}
        >
          <OrderScreenFoodPopup onClose={closeOrderSreenFoodPopup1} defaultName={LL0().div.drink()} type={"drink"} />
        </PortalPopup>
      )}
    </>
  );
};

export default OrderScreenCustomDishPo;
