import { type FunctionComponent, useEffect, useState } from 'react';
import { LL0 } from '@/react/core/I18nService.tsx';
import { missedCalls0 } from '@/data/CallHub.ts';
import { onAcceptCall, onAcceptOrderCall, onAcceptReservationCall } from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import { OrderType } from '@/pos/OrderType.ts';
import type { RxDocument } from 'rxdb';
import type { Call } from '@/data/Call.ts';
import type { Customer } from '@/data/Customer.ts';
import dayjs from 'dayjs';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type MissedCallComponentProps = {
  missedCall: RxDocument<Call, {}>
}

const MissedCallComponent = ({ missedCall }: MissedCallComponentProps) => {
  const [customer, setCustomer] = useState<RxDocument<Customer, {}> | undefined>();
  useEffect(() => {
    const fetchCustomer = async () => {
      setCustomer(await  missedCall.populate("customerId"))
    }
    fetchCustomer();
  }, []);

  return (
    <div className="self-stretch rounded bg-white flex flex-col items-center justify-center py-2.5 px-4 relative gap-[4px] border-[0.4px] border-solid border-materialize-grey-grey">
      <div className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded box-border z-[0] border-[2px] border-solid border-tomato-300" />
      <div className="self-stretch flex flex-row items-start justify-between pt-0 px-0 pb-2 z-[1] border-b-[1px] border-solid border-grey-grey-lighten-2">
        <div className="flex-1 flex flex-col items-start justify-start py-0 pr-1 pl-0 gap-[4px]">
          <div className="self-stretch flex flex-row items-start justify-start gap-[2px]">
            <b className="relative leading-[15px]">{customer?.name}</b>
            <b className="relative leading-[15px]">-</b>
            <b className="relative leading-[15px]">{customer?.phoneNumber}</b>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[2px] text-smi text-materialize-grey-darken-1">
            <div className="relative leading-[117.4%]">
              {LL0().delivery.toolbar.missedCalls()}
            </div>
            <div className="flex flex-row items-center justify-start gap-[2px]">
              <div className="relative leading-[117.4%]">-</div>
              <div className="relative leading-[117.4%]">
                {dayjs().diff(dayjs.unix(missedCall.date || 0), "minutes")} {LL0().pendingOrder.minAgo()}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-start gap-[8px]">
          <img
            className="relative w-5 h-5 object-cover"
            alt=""
            src="/iconcancel-call@2x.png"
          />
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center py-1 px-0 gap-[16px] z-[3] text-xs Tablet_768:gap-[8px] Tablet_768:pl-0 Tablet_768:box-border Tablet_768:grid Tablet_768:grid-cols-[repeat(2,_1fr)] Tablet_600:gap-[4px] Mobile_480:gap-[12px] Mobile_480:pl-0 Mobile_480:box-border Mobile_480:grid Mobile_480:grid-cols-[Repeat(2,_1fr)] Mobile_480:[row-gap:8px] Mobile_480:[column-gap:8px]">
        <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
             onClick={() => {
               userFLow(`incremental remove`, {
                 username: loginUser()?.name
               });
               missedCall?.incrementalRemove()
             }}
        >
          <img
            className="relative w-6 h-6 object-cover Tablet_600:w-5 Tablet_600:h-5"
            alt=""
            src="/iconcancel-order-icon@2x.png"
          />
          <div className="relative font-medium Tablet_600:text-2xs">
            {LL0().ui.delete()}
          </div>
        </div>
        <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
             ref={makeRipple}
             onClick={() => onAcceptReservationCall(missedCall, customer)}
        >
          <img
            className="relative w-6 h-6 object-cover Tablet_600:w-5 Tablet_600:h-5"
            alt=""
            src="/iconorder-already-icon@2x.png"
          />
          <div className="relative font-medium Tablet_600:text-2xs">
            {LL0().pendingOrder.reserve()}
          </div>
        </div>
        <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
             onClick={() => onAcceptOrderCall(missedCall, customer, OrderType.PickUp)}
        >
          <img
            className="relative w-6 h-6 object-cover Tablet_600:w-5 Tablet_600:h-5"
            alt=""
            src="/icontakeaway-icon@2x.png"
          />
          <div className="relative font-medium Tablet_600:text-2xs">
            {LL0().report.takeOut()}
          </div>
        </div>
        <div className="flex-1 rounded-sm box-border flex flex-col items-center justify-center py-2.5 px-6 gap-[2px] min-w-[47px] border-[1px] border-solid border-grey-grey-lighten-2"
             onClick={() => onAcceptOrderCall(missedCall, customer, OrderType.Delivery)}
        >
          <img
            className="relative w-6 h-6 object-cover Tablet_600:w-5 Tablet_600:h-5"
            alt=""
            src={"/icondeliver-icon@2x.png"}
          />
          <div className="relative font-medium Tablet_600:text-2xs">
            {LL0().onlineOrder.delivery()}
          </div>
        </div>
      </div>
    </div>
  )
}


type PendingOrdersMissedCallsType = {
  iconCancelCall?: string;
  iconDeliverIcon?: string;
  isExpanded?: boolean;
  isClosed?: boolean;
  showOrderStatus?: boolean;
  showIconCallIcon?: boolean;
  showCustomerNote?: boolean;
  showMissedCalls?: boolean;
  showOrderStatus1?: boolean;
  orderStatusVisible?: boolean;
  showCallTime?: boolean;
  showIconCallIcon1?: boolean;
  showIconCancelCall?: boolean;
  showCustomerNote1?: boolean;
  showOrderActions?: boolean;
  showButtonText?: boolean;
};

const PendingOrdersMissedCalls: FunctionComponent<
  PendingOrdersMissedCallsType
> = ({
  iconCancelCall,
  iconDeliverIcon,
  isExpanded = true,
  isClosed = true,
  showOrderStatus,
  showIconCallIcon,
  showCustomerNote,
  showMissedCalls,
  showOrderStatus1,
  orderStatusVisible,
  showCallTime,
  showIconCallIcon1,
  showIconCancelCall,
  showCustomerNote1,
  showOrderActions,
  showButtonText,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(false);
  isExpanded = isAccordionExpanded;
  isClosed =  !isAccordionExpanded;

  return (
    <div className="!w-full w-[455px] flex flex-col items-start justify-start gap-[4px] text-left text-smi text-white font-mulish">
      {isClosed && (
        <div className="self-stretch flex flex-row items-center justify-start gap-[4px]"
             onClick={() => setIsAccordionExpanded(prev => !prev)}
        >
          <img
            className="relative w-5 h-5 object-cover"
            alt=""
            src="/iconcancel-call@2x.png"
          />
          <div className="relative leading-[117.4%] font-semibold">
            {LL0().delivery.toolbar.missedCalls()} ({missedCalls0().length})
          </div>
          <img
            className="relative w-5 h-5 object-cover"
            alt=""
            src="/iconarrow-down-icon--white@2x.png"
          />
        </div>
      )}
      {isExpanded && (
        <div className="self-stretch flex flex-col items-start justify-center gap-[4px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[4px]"
               onClick={() => setIsAccordionExpanded(prev => !prev)}
          >
            <img
              className="relative w-5 h-5 object-cover"
              alt=""
              src={iconCancelCall}
            />
            <div className="relative leading-[117.4%] font-semibold">
              {LL0().delivery.toolbar.missedCalls()} ({missedCalls0().length})
            </div>
            <img
              className="relative w-5 h-5 object-cover"
              alt=""
              src="/iconarrow-up-icon--white@2x.png"
            />
          </div>
          <div className="gap-2 self-stretch rounded flex flex-col items-center justify-center text-sm text-black Mobile_480:grid-cols-[Repeat_(3,_2fr)]">
            { missedCalls0().map(missedCall => (
                <MissedCallComponent missedCall={missedCall}
                                     key={missedCall._id}
                />
            )) }
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingOrdersMissedCalls;
