import { context } from '@opentelemetry/api'

import { posSync0 } from '@/data/PosSyncState.ts'
import type { MasterCommand } from '@/lib/master-caller/master-caller.types.ts'
import { getNatsClient } from '@/lib/nats-client.ts'
import { createSpan } from '@/lib/open-telemetry.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'

class MasterHandler {
  public command$: Record<string, any>
  private isActive: boolean

  constructor() {
    this.command$ = {}
    this.isActive = false
  }

  async startHandler() {
    if (this.isActive) return
    this.isActive = true
    const natsClient = getNatsClient()
    await natsClient.subscribeToSubject(`${posSync0().id}-master`, async (message: MasterCommand) => {
      const masterHandler = getMasterHandler()
      const result = await masterHandler.handleCommand(message.method, message.params)
      return result
    })
  }

  async stopHandler() {
    if (!this.isActive) return
    this.isActive = false
    const natsClient = getNatsClient()
    natsClient.unsubscribeToSubject(`${posSync0().id}-master`)
  }

  registerCommand(command: string, fn: (...args: any[]) => any) {
    this.command$[command] = fn
  }

  async handleCommand(command: string, args: any[]) {
    const span = createSpan('Handling master command', undefined, context.active())
    span?.setAttribute('command', command)
    span?.setAttribute('args', JSON.stringify(args))
    span?.setAttribute('storeId', `${posSync0()?.id!}`)
    span?.setAttribute('deviceId', getDeviceId())
    try {
      if (!this.command$[command]) {
        return {
          error: 'not found',
        }
      }
      const result = await this.command$[command](...args)
      if (result) {
        span?.setAttribute(result, JSON.stringify(result))
      }
      span?.end()
      return result
    } catch (err: any) {
      span?.recordException(err)
      span?.end()
      throw err
    }
  }
}

let masterHandler: MasterHandler

export function getMasterHandler() {
  if (!masterHandler) {
    masterHandler = new MasterHandler()
  }
  return masterHandler
}
