import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PortalPopup from "./PortalPopup";
import PaymentSelectTerminalPopu from "../../../payment/src/components/PaymentSelectTerminalPopu";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { signal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { posSync0 } from "@/data/PosSyncState.ts";
import { toast } from "react-toastify";
import { canShowQrCode, getLinkInvoice, scopeOrder0 } from "@/react/PaymentView/PaymentView.tsx";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import QRCode from "qrcode.react";
import { getServer } from "@/shared/utils.ts";
import { FormControlLabel, Switch } from "@mui/material";
import { generalSetting0, isQuebecSrmEnabled, printerGeneralSetting0 } from "@/data/PosSettingsSignal.ts";
import _ from "lodash";
import { srmTransactionLogic } from "@/srm/transaction.logic.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { onGroupBill } from "@/react/OrderView/OrderView.tsx";
import { setTogglePaymentMore } from "@vertical-payment2/PaymentMoreButtonV.tsx";

interface ScreenPluginButtonMoreType {
  className?: string;
}

export const [toggleMorePaymentVPlugin, setToggleMorePaymentVPlugin] = signal(false)


const ScreenPluginButtonMore: FunctionComponent<ScreenPluginButtonMoreType> = ({
  className = "",
}) => {

  const { order0, seat, setSeat } = useContext(ItemFactoryContext)
  const [isQRCodePopupOpen, setQRCodePopupOpen] = useState(false);

  const [terminalClicked, setTerminalClicked] = useState<boolean>(false)
  const [showQRClicked, setShowQRClicked] = useState<boolean>(false)
  const [isPaymentSelectTerminalPopuOpen, setPaymentSelectTerminalPopuOpen] =
    useState(false);

  const refClickOutside = useRef<HTMLDivElement>(null);

  const openPaymentSelectTerminalPopu = useCallback(() => {
    setPaymentSelectTerminalPopuOpen(true);
  }, []);

  const closePaymentSelectTerminalPopu = useCallback(() => {
    setPaymentSelectTerminalPopuOpen(false);
  }, []);


  const handleTerminalClick = () => {
    setTerminalClicked(true);
    setToggleMorePaymentVPlugin(false)
    openPaymentSelectTerminalPopu();
    setTerminalClicked(false)
  };


  const handleShowQRClick = useCallback(() => {
    setShowQRClicked(true);
    setToggleMorePaymentVPlugin(false)
    if (!posSync0()?.id) return toast.warning('You need connect to store', { autoClose: 500 })
    if (!order0?.() || !canShowQrCode(order0())) {
      return toast.warning('You need press print', { autoClose: 500 })
    }
    setQRCodePopupOpen(true);
    setShowQRClicked(false);
  }, []);

  const closeQrCodePopup = useCallback(() => {
    setQRCodePopupOpen(false);
  }, []);



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (refClickOutside.current && !refClickOutside.current.contains(event.target as Node)) {
        setToggleMorePaymentVPlugin(false)
      }
    };
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [toggleMorePaymentVPlugin()]);
  return (
    <div
      className={`z-[5] flex flex-col items-center justify-center gap-[8px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish-button-name-bold ${className}`}
      ref={refClickOutside}
    >
      <div className="flex flex-col items-start justify-start relative">
        <div onClick={() => {
          setToggleMorePaymentVPlugin(!toggleMorePaymentVPlugin())
        }}
             className={
               clsx(toggleMorePaymentVPlugin() ? "shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(180deg,_#2196f3,_#0a80de)] h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border z-[0] mq432:h-[34px]" :
                 "shadow-[0px_0px_6px_rgba(0,_0,_0,0.22)] rounded-81xl [background:linear-gradient(180deg,#2196f3,_#0a80de)] h-10 overflow-hidden shrink-0 flex z-[1] flex-row items-center justify-center py-0.5 px-2.5 box-border mq432:h-[34px]"
               )
             }
        >
          <img
            className="w-[30px] relative h-[30px] object-cover mq432:w-[26px] mq432:h-[26px]"
            alt=""
            src={ toggleMorePaymentVPlugin() ? "/icon-payment-menu-explain-icon@2x.png" : "/icon-payment-menu-icon@2x.png"}
          />
        </div>
        { toggleMorePaymentVPlugin() &&
          <div className="!m-[0] absolute top-[40px] right-[0px] flex flex-col items-start justify-start z-[1] mq482:h-60">
            <div className="self-stretch flex flex-col items-end justify-start py-0 pr-[19px] pl-0">
              <img
                className="w-2.5 relative h-[9px] object-cover"
                alt=""
                src="/icontooltip-arrow-up-icon@2x.png"
              />
            </div>
            <div
              className="w-48 shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff overflow-y-auto flex flex-col items-start justify-start py-1 px-2 box-border gap-[6px]">
              <div
                className="self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px]"
                onClick={handleShowQRClick}
                ref={makeRipple}
              >
                <div
                  className={clsx('border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]', showQRClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]')} />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/show-qr.png"
                />
                <b
                  className={clsx('relative leading-[17px] sm:text-smi mq600:text-smi mq432:text-smi', terminalClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]')}>
                  {LL0().payment.showQrCode()}
                </b>
              </div>
              <div
                className="self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px]"
                onClick={handleTerminalClick}
                ref={makeRipple}
              >
                <div
                  className={clsx('border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]', terminalClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]')} />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/terminal-setup.png"
                />
                <b
                  className={clsx('relative leading-[17px] sm:text-smi mq600:text-smi mq432:text-smi', terminalClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]')}>
                  {LL0().dashboard.terminalSetup()}
                </b>
              </div>
              {isQuebecSrmEnabled() && <div
                className="h-[34px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                ref={makeRipple}
                onClick={() => (setTogglePaymentMore(false), srmTransactionLogic.handleFailureToPay(scopeOrder0()))}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                <img
                  className="w-[24px] relative h-[24px] object-cover z-[1]"
                  alt=""
                  src="/fail-to-pay-icon.png"
                />
                <b className="flex-1 relative leading-[17px] z-[2]">
                  {LL0().order.receipt.failToPay()}
                </b>
                <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                  {LL0().order.receipt.failToPay()}
                </b>
              </div>}
              <div
                className="h-[34px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small"
                                     checked={deviceSetting0()?.autoOpenCashDrawer}
                                     onChange={(_e, checked) => _.assign(deviceSetting0(), { autoOpenCashDrawer: checked })}
                    />}
                    label='' />
                </div>
                <b className="flex-1 relative leading-[17px]">
                  {LL0().payment.autoOpenCashDrawer()}
                </b>
              </div>
              <div
                className="hidden h-[34px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff mq432:flex">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small"
                                     checked={!generalSetting0()?.cashTipEnable}
                                     onChange={(e, checked: boolean) => _.assign(generalSetting0(), { cashTipEnable: !checked })}
                    />}
                    label='' />
                </div>
                <b className="flex-1 relative leading-[17px]">
                  {LL0().order.cashTip()}
                </b>
              </div>
              {(isQuebecSrmEnabled() && order0?.()?.seatMode) &&
                <div
                  className="h-[34px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                  <div className="w-9 relative h-[18px]">
                    <FormControlLabel
                      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                      control={<Switch color="primary" size="small"
                                       checked={order0?.()?.isGroupBill || false}
                                       onChange={(e) => {
                                         if (order0)
                                           onGroupBill(order0(), e.target.checked)
                                       }} />}
                      label='' />
                  </div>
                  <b className="flex-1 relative leading-[17px]">
                    {LL0().order.groupBills()}
                  </b>
                </div>}
              <div
                className="h-[34px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small"
                                     checked={!!printerGeneralSetting0()?.autoPrintInvoiceBeforePaying}
                                     onChange={(_e, checked) => _.assign(printerGeneralSetting0(), { autoPrintInvoiceBeforePaying: checked })}
                    />}
                    label={''} />
                </div>
                <b className="flex-1 relative leading-[17px]">{LL0().payment.autoPrint()}</b>
              </div>
              <div
                className="h-[34px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                <div className="w-9 relative h-[18px]">
                  <FormControlLabel
                    className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                    control={<Switch color="primary" size="small" />}
                    label={''} />
                </div>
                <b className="flex-1 relative leading-[17px]">
                  {LL0().payment.autoCloseAfterTerminalProcess()}
                </b>
              </div>
            </div>
          </div>
        }
      </div>
      {isPaymentSelectTerminalPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentSelectTerminalPopu}
        >
          <PaymentSelectTerminalPopu onClose={closePaymentSelectTerminalPopu} />
        </PortalPopup>
      )}
      {isQRCodePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeQrCodePopup}
        >
          <div
            className="w-full h-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-center justify-start p-12 box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-900-1e1e23 font-mulish">
            <img
              className="w-8 absolute !m-[0] top-[12px] right-[12px] h-8 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
              alt=""
              src="/close_qr_popup.png"
              onClick={closeQrCodePopup}
            />
            <QRCode
              value={getLinkInvoice(order0?.()!, seat?.()) || `${getServer().manageServer.url}/invoice/${posSync0()?.id}/${order0?.()?._id}`}
              size={256}
              style={{ marginTop: '1rem' }}
            />
          </div>
        </PortalPopup>
      )}
    </div>
  );
};

export default ScreenPluginButtonMore;
