import { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";

export type DeleteConfirmationPopupVType = {
  className?: string;
  onClose?: () => void;
  onComplete?: () => void;
};

const DeleteConfirmationPopupV: FunctionComponent<
  DeleteConfirmationPopupVType
> = ({ className = "", onClose, onComplete }) => {
  return (
    <div
      className={`w-[380px] relative bg-white-solid-white-100-ffffff flex flex-col items-end justify-start pt-8 px-4 pb-7 box-border gap-8 max-w-full max-h-full overflow-auto text-center text-base text-red-solid-red-550-cf2222 font-mulish ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-2">
        <b className="self-stretch relative">{LL0().ui.confirmation()}</b>
        <div className="self-stretch relative text-sm text-black-solid-black-1000-000000">
          {LL0().editMenu.areYouSureYouWantToDelete()}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-center gap-3 text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff border-blue-solid-blue-420-2979ff border-[1px] border-solid box-border h-[37px] overflow-hidden flex flex-row items-center justify-center py-1 px-4 cursor-pointer"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden flex flex-row items-center justify-center py-1 px-4 box-border text-white-solid-white-100-ffffff"
             onClick={() => {
               onComplete?.();
               onClose?.();
             }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationPopupV;
