import { type FunctionComponent, useMemo, type CSSProperties } from "react";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import clsx from "clsx";

export type ProductItemType = {
  className?: string;
  productNumber?: string;
  productType: string | undefined;
  unPrintQuantity?: number;
  printQuantity?: number;
  showCheckedIcon?: boolean;
  isSelected?: boolean;
  showPopupIcon?: boolean;
  showQuantity?: boolean;
  productName?: string;

  /** Style props */
  backgroundColor?: CSSProperties["backgroundColor"];
};

const ProductItem: FunctionComponent<ProductItemType> = ({
  className = "",
  productNumber,
  productType,
  unPrintQuantity,
  printQuantity,
  showCheckedIcon,
  isSelected,
  showPopupIcon,
  showQuantity,
  backgroundColor,
  productName,
}) => {
  const isDefault1Style: CSSProperties = useMemo(() => {
    return {
      backgroundColor: backgroundColor,
    };
  }, [backgroundColor]);

  return (
    <div
      className={`flex-1 rounded flex flex-col items-start justify-start relative gap-[12px] min-w-[114px] text-left text-smi text-black-solid-black-1000-000000 font-mulish lg:min-w-[142px] mq600:min-w-[114px] mq414:min-w-[96px] ${className}`}
    >
      <div
        className="self-stretch rounded bg-pink-solid-pink-100-fbe4ec flex flex-col items-start justify-between pt-1.5 pb-1 px-1 box-border gap-0 z-[0]"
        style={{
          ...isDefault1Style,
          height: deviceSetting0()?.screenConfig?.itemCardHeight || 50
        }}
      >
        <div className="flex-1 self-stretch flex flex-row items-start justify-start gap-[2px]">
          {productNumber &&
            <div className={clsx("relative tracking-[-0.12px] leading-[16px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] lg:text-sm md:text-sm sm:text-sm mq600:text-sm mq432:text-smi mq414:text-smi mq360:text-xs mq340:text-xs mq320:text-xs", productType === 'Extra' ? 'italic skew-25 text-[#333333] font-medium' : 'font-semibold')}
                 style={{
                   fontSize: deviceSetting0()?.screenConfig?.productFontSize || 15,
                   lineHeight: (`${deviceSetting0()?.screenConfig?.textLineHeight}px` || '14px')
                 }}
            >
              {productNumber}.
            </div>}
          <div className={clsx("h-full flex-1 relative tracking-[-0.12px] leading-[16px] [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] lg:text-sm md:text-sm sm:text-sm mq600:text-sm mq432:text-smi mq360:text-xs mq340:text-xs mq320:text-xs", productType === 'Extra' ? 'italic skew-25 text-[#333333] font-medium' : 'font-semibold')}
               style={{
                 fontSize: deviceSetting0()?.screenConfig?.productFontSize || 15,
                 lineHeight: (`${deviceSetting0()?.screenConfig?.textLineHeight}px` || '14px')
               }}
          >
            {productName}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-end justify-end text-center text-white-solid-white-100-ffffff">
          <div className="flex-1 h-4 flex flex-row items-center justify-start">
            {showPopupIcon && (
              <img
                className="w-3 relative h-3 object-cover"
                alt=""
                src="/icondashboardpopup-icon-ver1@2x.png"
              />
            )}
          </div>
          {!!unPrintQuantity && (
            <div className="w-[22px] rounded-981xl [background:linear-gradient(180deg,_#fb6b6b,_#fd0000)] h-4 flex flex-row items-center justify-center py-0 px-0.5 box-border">
              <b className="relative">{unPrintQuantity}</b>
            </div>
          )}
          {!!printQuantity && (
            <div className="ml-1 w-[22px] rounded-981xl [background:linear-gradient(180deg,_#7aa03b,_#007000)] h-4 flex flex-row items-center justify-center py-0 px-0.5 box-border">
              <b className="relative">{printQuantity}</b>
            </div>
          )}
          {showCheckedIcon && (
            <img
              className="w-3.5 relative h-3.5 object-cover"
              alt=""
              src="/edit-menuchecked-icon@2x.png"
            />
          )}
        </div>
      </div>
      {isSelected && (
        <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-gray-200 z-[1]" />
      )}
    </div>
  );
};

export default ProductItem;
