import type { RxConflictHandler, RxConflictHandlerInput, RxConflictHandlerOutput } from "rxdb";
import { stripAttachmentsDataFromDocument } from "rxdb";

export const paidOrderConflictHandler: RxConflictHandler<any> = function (
  i: RxConflictHandlerInput<any>,
  _context: string
): Promise<RxConflictHandlerOutput<any>> {
  const newDocumentState = stripAttachmentsDataFromDocument(i.newDocumentState);
  const realMasterState = stripAttachmentsDataFromDocument(i.realMasterState);

  if (newDocumentState.updatedAt === realMasterState.updatedAt) {
    return Promise.resolve({
      isEqual: true
    });
  }

  if (_context === 'replication-resolve-conflict' || _context === 'masterWrite-online') {
    const doc = newDocumentState.updatedAt < realMasterState.updatedAt ? newDocumentState :realMasterState;
    return Promise.resolve({
      isEqual: false,
      documentData: doc
    });
  }

  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState
  });
};

//them tinh nang merge real time