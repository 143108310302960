import { PosScreen } from '@/pos/PosRouter'
import { computed } from '@/react/core/reactive'
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort'
import { isSrmCertificateValid } from '@/srm/cert.logic'
import { CONSTANT_VALUES } from '@/srm/lib/constants'

import { deviceSetting0 } from './DeviceSettingSignal'
import { posSetting0 } from './PosSettingsSignal'
import { loginUser } from './UserSignal'

export const loginUsers = computed<string[]>(() => {
  if (loginUser()?.name) {
    return [loginUser()!.name!]
  }
  return []
})

// show button settings
export const loginUsersEditSettingPermission = computed(() => {
  if (loginUser()) {
    return getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) || getAccessibility(PosScreen.HAPPY_HOUR) || getAccessibility(PosScreen.EDIT_MENU) || getAccessibility(PosScreen.EDIT_FLOOR_PLAN)
  }
  return false
})
export const loginUsersReportsPermission = computed(() => {
  if (loginUser()) {
    return getAccessibility(PosScreen.STAFF_REPORT) || getAccessibility(PosScreen.EOD) || getAccessibility(PosScreen.MONTHLY_REPORT) || getAccessibility(PosScreen.CASHBOOK)
  }
  return false
})
export const isValidSrmUser = computed(
  () =>
    // Running testcase
    posSetting0()?.srm?.testcaseNumber !== CONSTANT_VALUES.CASESSAI_EMPTY ||
    // OR user is synced with SRM
    (loginUser()?.srmSynced &&
      // AND have does not have any sync error
      !loginUser()?.srmSyncingError &&
      // AND have valid certificate
      isSrmCertificateValid() &&
      // AND have valid device ID
      deviceSetting0()?.srm?.deviceId)
)
