import { isMaster } from "@/lib/master-signal.ts";
import { offlineSync, onlineSync } from "@/data/ReplicateEffect.ts";
import { masterAvailable, onlineMasterAvailable } from "@/data/data-utils.ts";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import { WorkingMode } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";
import { isInternetConnected, isWifiConnected } from "@/shared/networkInfo.ts";
import { toast } from "react-toastify";

export enum MasterAvailableHandler {
  ShowWarning = 'ShowWarning',
  ShowWarningAndReturn = 'ShowWarningAndReturn',
  Ignore = 'Ignore',
  NoInternet = 'NoInternet',
  NoWifi = 'NoWifi',
  NoInternetAndReturn = 'NoInternetAndReturn',
  NoWifiAndReturn = 'NoWifiAndReturn'
}

export function checkMasterAvailableInternal(): MasterAvailableHandler {
  //fixme: strict mode
  if (posSetting0()?.workingMode === WorkingMode.StrictMode) {
    if (!isInternetConnected()) {
      if (!isWifiConnected()) return MasterAvailableHandler.NoWifiAndReturn
      return MasterAvailableHandler.NoInternetAndReturn
    }
    if (!isMaster() && offlineSync() && !masterAvailable()) return MasterAvailableHandler.ShowWarningAndReturn
    if (!isMaster() && onlineSync() && !onlineMasterAvailable()) return MasterAvailableHandler.ShowWarningAndReturn
    return MasterAvailableHandler.Ignore
  } else if (posSetting0()?.workingMode === WorkingMode.WarningMode || !posSetting0()?.workingMode) {
    if (!isInternetConnected()) {
      if (!isWifiConnected()) return MasterAvailableHandler.NoWifi
      return MasterAvailableHandler.NoInternet
    }
    if (!isMaster() && offlineSync() && !masterAvailable()) return MasterAvailableHandler.ShowWarning
    if (!isMaster() && onlineSync() && !onlineMasterAvailable()) return MasterAvailableHandler.ShowWarning
    return MasterAvailableHandler.Ignore
  } else if (posSetting0()?.workingMode === WorkingMode.NormalMode) {
    if (!isInternetConnected()) {
      if (!isWifiConnected()) return MasterAvailableHandler.Ignore
      return MasterAvailableHandler.Ignore
    }
    if (!isMaster() && offlineSync() && !masterAvailable()) return MasterAvailableHandler.Ignore
    if (!isMaster() && onlineSync() && !onlineMasterAvailable()) return MasterAvailableHandler.Ignore
    return MasterAvailableHandler.Ignore
  }

  return MasterAvailableHandler.Ignore;
}

export function checkMasterAvailable (): boolean {
  switch (checkMasterAvailableInternal()) {
    case MasterAvailableHandler.NoWifi:
      toast.error('No Wifi', { autoClose: 10000 })
      break
    case MasterAvailableHandler.NoInternet:
      toast.error('No Internet', { autoClose: 10000 })
      break
    case MasterAvailableHandler.NoWifiAndReturn:
      toast.error('No Wifi', { autoClose: 10000 })
      return true;
    case MasterAvailableHandler.NoInternetAndReturn:
      toast.error('No Internet', { autoClose: 10000 })
      return true;
    case MasterAvailableHandler.ShowWarningAndReturn:
      toast.error('Master is not available', { autoClose: 10000 })
      return true;
    case MasterAvailableHandler.ShowWarning:
      toast.error('Master is not available', { autoClose: 10000 })
      break
  }
  return false;
}