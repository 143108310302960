import { rnHost } from "@/shared/webview/rnwebview.ts";
import { notificationToast } from "@/react/FloorPlanView/Noti.ts";
import { createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import { convertToBase64Png } from "@/shared/utils"
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { currentPrinter } from "@/react/Printer/PrinterSettingView.tsx";
import { captureException } from "@sentry/react";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { groupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import debug from "debug";
import Queue from "queue";

const log = debug('printer:bluetoothManager');

const queue = new Queue({ autostart: true, concurrency: 1 });

export async function printBluetooth(address: string, base64: string) {
  log(`print to bluetooth ${base64.length} to ${address}`);

  queue.push(async () => {
    let count = 0;
    while (count < 10) {
      try {
        await rnHost.printBluetooth(address, base64, 560);
        break;
      } catch (e: any) {
        captureException(new Error(`print to bluetooth: error ${e.message}`), { tags: { type: 'print' } });
        if (count < 1) {
          notificationToast("Connection failed : " + (e as Error).message, {
            type: "error",
            autoClose: 1000 * 60
          })
        }
        captureException(e);
        const printer = groupPrinters0().find(g => g.printers[0].printerType === 'bluetooth' && g.printers[0].address?.split("/")[0] === address);
        if (!generalSetting0()?.preventShowErrorPopUpForPrinter && count > 3 && generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && printer) {
          await printerErrDialog(LL0().printing.printerError({
            printer: _.upperFirst(printer?.name),
            printerType: _.upperFirst(printer?.printers[0].printerType)
          }), printer?.printers[0].address, testBluetoothPrinter)
        }
      } finally {
        count++;
      }
    }
  })
}

export const testBluetoothPrinter = async () => {
  const address = currentPrinter()?.address?.split("/")[0];
  if (!address) return;
  const printer = createPrinter();
  await printer.println("TEST");
  const raster: Raster = (await printer.print())!;
  const base64 = await convertToBase64Png(raster, false);

  await printBluetooth(address, base64);
}