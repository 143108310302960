import { type FunctionComponent, useState } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import ManuelTableFloorButton from "@dashboard/ManuelTableFloorButton";
import { busyRoomMapper, rooms0 } from "@/react/FloorPlanView/FloorPlanView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { decreaseParts, itemContext } from "@/react/OrderView/OrderView";
import { userFLow } from "@/shared/logger";
import { loginUser } from "@/data/UserSignal";

const MoveItemTableLeft: FunctionComponent = () => {

  const [showFloorFlanAccordion, setShowFloorFlanAccordion] = useState<boolean>(true)


  return (
    <div
      className="self-stretch flex-1 bg-gray-solid-gray-140-f4f3f3 flex flex-col items-center justify-start gap-[11px] min-w-[190px] max-w-[290px] text-left text-sm text-blue-solid-blue-390-0687ed font-mulish">
      <div className="self-stretch flex flex-col items-center justify-center gap-[8px]">
        <div className="self-stretch bg-gray-solid-gray-166-ededee h-[39px] flex flex-row items-center justify-between py-[9px] px-4 box-border"
             onClick={() => {
               userFLow(`set show floor flan accordion ${!showFloorFlanAccordion}`, {
                 username: loginUser()?.name
               });
               setShowFloorFlanAccordion(!showFloorFlanAccordion)
             }}
        >
          <div className="flex flex-row items-center justify-start gap-[16px]">
            <img
              className="w-[21px] relative h-[21px] hidden"
              alt=""
              src="/floor-plan-icon.svg"
            />
            <b className="relative">{LL0().restaurant.floorPlan()}</b>
          </div>
          <img
            className="w-3 relative h-[7.1px]"
            alt=""
            src={showFloorFlanAccordion ? "/order-view-chevron-down-icon.svg" : "/order-view-chevron-right-icon.svg"}
          />
        </div>
        {showFloorFlanAccordion && <div
          className="self-stretch overflow-hidden flex flex-col items-center justify-center py-1.5 px-[7px] gap-[12px] text-center text-royalblue-300">
          <div
            className="self-stretch flex-1 relative grid grid-rows-[repeat(2,1fr)] grid-cols-[repeat(2,1fr)] [grid-row-gap:4px] [grid-column-gap:3px]">
            {rooms0().map((room, index) =>
              <ManuelTableFloorButton
                floorText={room.name}
                showSelected={false}
                showRedDot={!!busyRoomMapper()?.[room._id]}
                manuelTableFloorButtonHeight="40px"
                manuelTableFloorButtonMinWidth="0px"
                key={index}
                _id={room._id}
              />)}
            {!rooms0().length &&
              <div className="font-semibold font-mulish col-span-2 text-center">{LL0().dashboard.noRoomShow()}</div>
            }
          </div>
        </div>}
      </div>
      {/*<div*/}
      {/*  className="self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex flex-col items-center justify-end pt-2 px-0 pb-3 text-center text-white-solid-white-100-ffffff">*/}
      {/*  <div*/}
      {/*    className="rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"*/}
      {/*    onClick={() => router.screen = PosScreen.ORDER}*/}
      {/*    ref={makeRipple}*/}
      {/*  >*/}
      {/*    <img*/}
      {/*      className="w-[7.1px] relative h-3 object-contain"*/}
      {/*      alt=""*/}
      {/*      src="/chevron-right-icon.svg"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex flex-col items-center justify-end pt-2 px-0 pb-3 text-center text-white-solid-white-100-ffffff">
        <div className="rounded-81xl bg-palette-blue-style shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
             onClick={() => {
               userFLow(`go to screen order`, {
                 username: loginUser()?.name
               });
               router.screen = PosScreen.ORDER
               if (itemContext.groupEnable()) {
                 itemContext.onGroupCancel();
               }
             }}
             ref={makeRipple}
        >
          <img
            className="w-[30px] relative h-[30px] object-cover"
            alt=""
            src="/iconback-icon11@2x.png"
          />
          <b className="relative leading-[17px]">{LL0().ui.back()}</b>
        </div>
      </div>
    </div>
  );
};

export default MoveItemTableLeft;
