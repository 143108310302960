import { type FunctionComponent, useCallback, useState } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import TaxAndPaymentDeleteConfi from './TaxAndPaymentDeleteConfi'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import TableManualKeyboard from '@/react/core/TableManualKeyboard.tsx'
import Input, { inputController0 } from '@/react/core/Input.tsx'
import {
  deleteTaxComponent,
  initSubTax,
  taxCategory0,
  taxCategoryTypes
} from '@/react/PaymentSettingView/PaymentSettingView.tsx'
import _ from 'lodash'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { clone } from 'json-fn'
import { LL0 } from '@/react/core/I18nService.tsx'

export type NewEditTaxPopupType = {
  onClose?: () => void;
  onComplete?: () => void;
};

const NewEditTaxPopup: FunctionComponent<NewEditTaxPopupType> = ({
                                                                   onClose,
                                                                   onComplete
                                                                 }) => {
  const [currentDeleteIndex, setCurrentDeleteIndex] = useState<number>(0)

  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false)
  const [isTaxAndPaymentDeleteConfi1Open, setTaxAndPaymentDeleteConfi1Open] =
    useState(false)

  const openTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(true)
  }, [])

  const closeTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(false)
  }, [])

  return (
    <>
      <div
        className="new-tax-popup no-scrollbar w-full h-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-end justify-start pt-3.5 px-0 pb-0 box-border min-w-[560px] min-h-[340px] max-w-full max-h-full overflow-auto text-left text-base text-blue-solid-blue-500-0051c1 font-mulish height_414:h-[400px] height_414:min-h-[300px]">
        <div className="no-scrollbar self-stretch h-[231px] overflow-y-auto shrink-0 flex flex-col items-center justify-start py-0 px-3.5 box-border gap-[8px_0px] z-[0] height_414:h-40">
          <div className="self-stretch flex flex-row items-start justify-center gap-[0px_12px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                {LL0().article.name()}
              </b>
              <Input
                className="h-[36px] self-stretch font-mulish text-sm text-gray"
                value={taxCategory0()?.name || ''}
                onChange={(value) => _.assign(taxCategory0(), { name: value })}
                refInputController={i => {
                  i.setFocus(true)
                  i.moveCaretToEnd()
                }}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                {LL0().article.type()}
              </b>
              <FormControl
                className="self-stretch font-mulish text-sm text-gray"
                variant="outlined"
              >
                <InputLabel color="primary" />
                <Select
                  color="primary"
                  name="type"
                  size="small"
                  value={taxCategory0()?.type || 'food:dineIn'}
                  onChange={e => _.assign(taxCategory0(), { type: e.target.value })}
                >
                  {taxCategoryTypes.map((taxCategoryType, index) => (
                    <MenuItem value={taxCategoryType} key={index}>
                      {_.get(LL0().tax, _.camelCase(taxCategoryType))?.()}
                      {/*{_.camelCase(taxCategoryType)}*/}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
          {taxCategory0()?.type !== 'combo' && <div className="self-stretch flex flex-row items-start justify-center gap-[0px_12px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                {LL0().tax.taxValue()}
              </b>
              <Input
                className="h-[36px] self-stretch font-mulish text-sm text-gray"
                value={taxCategory0()?.value?.toString() ?? ""}
                onChange={(v) => !isNaN(parseFloat(v)) && _.assign(taxCategory0(), {value: parseFloat(v)})}
              />
            </div>
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                {LL0().tax.printedLabel()}
              </b>
              <Input
                className="h-[36px] self-stretch font-mulish text-sm text-gray"
                value={taxCategory0()?.printLabel || ""}
                onChange={(v) => _.assign(taxCategory0(), {printLabel: v})}
              />
            </div>
          </div>}
          {taxCategory0()?.type === 'combo' && <div
            className="self-stretch flex flex-col items-center justify-start gap-[8px] text-sm text-brown-brown-300-993600">
            {taxCategory0()?.components?.map((component, index) => (
              <div className="self-stretch flex flex-row items-end justify-center gap-[12px]"
                   key={index}
              >
                <div className="flex-1 flex flex-col items-start justify-start gap-[3px]">
                  <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                    {LL0().tax.subName()}
                  </b>
                  <Input
                    className="h-[36px] self-stretch"
                    value={component.name || ''}
                    onChange={(value) => _.assign(component, { name: value })}
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[3px]">
                  <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                    {LL0().tax.taxValue()}
                  </b>
                  <Input
                    className="h-[36px] self-stretch font-mulish text-sm text-gray-200"
                    value={component.value?.toString() ?? ''}
                    onChange={(value) => !isNaN(parseFloat(value)) && _.assign(component, { value: parseFloat(value) })}
                  />
                </div>
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[3px]">
                  <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                    {LL0().tax.printedLabel()}
                  </b>
                  <Input
                    className="h-[36px] self-stretch"
                    value={component.printLabel || ''}
                    onChange={(value) => _.assign(component, { printLabel: value })}
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[3px_0px]">
                  <b className="self-stretch relative flex items-center h-[21px] shrink-0">
                    {LL0().delivery.note()}
                  </b>
                  <FormControl
                    className="!w-full self-stretch"
                    variant="outlined"
                    sx={{
                      borderRadius: '0px 0px 0px 0px',
                      width: '152.3px',
                      height: '36px',
                      m: 0,
                      p: 0,
                      '& .MuiInputBase-root': {
                        m: 0,
                        p: '14px',
                        minHeight: '36px',
                        justifyContent: 'center',
                        display: 'inline-flex'
                      },
                      '& .MuiInputLabel-root': {
                        m: 0,
                        p: 0,
                        minHeight: '36px',
                        display: 'inline-flex'
                      },
                      '& .MuiMenuItem-root': {
                        m: 0,
                        p: 0,
                        height: '36px',
                        display: 'inline-flex'
                      },
                      '& .MuiSelect-select': {
                        m: 0,
                        p: 0,
                        height: '36px',
                        alignItems: 'center',
                        display: 'inline-flex'
                      },
                      '& .MuiInput-input': { m: 0, p: 0 },
                      '& .MuiInputBase-input': {
                        textAlign: 'left',
                        p: '0 !important'
                      }
                    }}
                  >
                    <InputLabel color="primary" />
                    <Select
                      color="primary"
                      size="small"
                      displayEmpty
                      value={component.note}
                      onChange={(e) => _.assign(component, {note: e.target.value})}
                    >
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="P">P</MenuItem>
                      <MenuItem value="S">S</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                </div>
                <img
                  className="w-8 relative h-8 object-cover cursor-pointer"
                  alt=""
                  src="/big-red-bin-icon@2x.png"
                  onClick={() => {
                    setCurrentDeleteIndex(index)
                    openTaxAndPaymentDeleteConfi()
                  }} />

              </div>))}
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[6px] text-base text-blue-solid-blue-500-0051c1"
              ref={makeRipple}
              onClick={() => {
                _.assign(taxCategory0(), {
                  components: [
                    ...taxCategory0()?.components || [],
                    clone(initSubTax)
                  ]
                })
              }}
            >
              <div
                className="self-stretch flex flex-row items-center justify-start gap-[0px_6px] text-base text-blue-solid-blue-500-0051c1">
                <img
                  className="w-[34px] relative h-[34px] object-cover"
                  alt=""
                  src="/iconadd-option-value-icon@2x.png"
                />
                <div className="relative font-semibold md:text-smi sm:text-xs">
                  {LL0().tax.addSubTax()}
                </div>
              </div>
            </div>
          </div>}
        </div>
        <TableManualKeyboard
          inputController={inputController0}
          onEnter={onComplete}
        />
        <img
          className="w-[21px] absolute !m-[0] top-[8px] right-[8px] h-[21px] object-cover cursor-pointer z-[2]"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
      {isTaxAndPaymentDeleteConfiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTaxAndPaymentDeleteConfi}
        >
          <TaxAndPaymentDeleteConfi
            onClose={closeTaxAndPaymentDeleteConfi}
            onComplete={() => deleteTaxComponent(currentDeleteIndex)}
          />
        </PortalPopup>
      )}
    </>
  )
}

export default NewEditTaxPopup
