import {type FunctionComponent, useState, useCallback} from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Switch,
  FormControlLabel,
} from "@mui/material";
import PopupDeleteConfirmation from "../../Popup/PopupDeleteConfirmation";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import {getTax, STATES, VIEW__MODIFIER} from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import TextField from "@/react/core/TextField";
import {numberFromString, numberToString} from "@/react/core/utils";
import type {ModifierItem} from "@/data/Modifier";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils";
import {taxCategoriesVM} from "@/data/TaxCategoryHub.ts";

const ModifierTypeSingleFieldset: FunctionComponent = () => {
  const [isEditItemDeleteConfirmPoOpen, setEditItemDeleteConfirmPoOpen] =
    useState(false);
  const [isEditItemDeleteConfirmPo1Open, setEditItemDeleteConfirmPo1Open] =
    useState(false);
  const [isEditItemDeleteConfirmPo2Open, setEditItemDeleteConfirmPo2Open] =
    useState(false);

  const openEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(true);
  }, []);

  const closeEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(false);
  }, []);

  const openEditItemDeleteConfirmPo1 = useCallback(() => {
    setEditItemDeleteConfirmPo1Open(true);
  }, []);

  const closeEditItemDeleteConfirmPo1 = useCallback(() => {
    setEditItemDeleteConfirmPo1Open(false);
  }, []);

  const openEditItemDeleteConfirmPo2 = useCallback(() => {
    setEditItemDeleteConfirmPo2Open(true);
  }, []);

  const closeEditItemDeleteConfirmPo2 = useCallback(() => {
    setEditItemDeleteConfirmPo2Open(false);
  }, []);

  const [stagingItem, setStagingItem] = useState<ModifierItem | undefined>()

  return (
    <>
      <table className="self-stretch">
        <thead>
        <tr>
          <th className="relative pr-0 pb-0">
            <div className="relative bg-gray-opacity-gray-0-d9d9d9-300 w-6 max-w-[24px] md:h-12 sm:h-10"/>
          </th>
          <th className="relative pb-0">
            <div className="rounded-t-2xl rounded-b-none bg-green-solid-green-300-bdebdc box-border shrink-0 flex flex-row items-center justify-start h-auto gap-[0px] w-full border-t-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-r-[1px] border-l-[1px] hover:bg-green-solid-green-300-bdebdc hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start hover:rounded-t-2xl hover:rounded-b-none">
              <div className="flex-1 h-14 flex flex-row items-center justify-start py-0 px-2 box-border md:h-12 sm:h-10">
                <div
                  className="relative text-lg font-semibold font-mulish text-[#2e2e48] text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                  {LL0().editOnlineMenu.optionValue()}
                </div>
              </div>
              <div className="w-40 h-14 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] md:h-12 sm:w-[110px] sm:h-10">
                <div
                  className="relative text-lg font-semibold font-mulish text-[#2e2e48] text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                  {LL0().printing.price()} ({LL0().printing.currency()})
                </div>
              </div>
              <div className="w-40 h-14 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] md:h-12 sm:w-[110px] sm:h-10">
                <div
                  className="relative text-lg font-semibold font-mulish text-[#2e2e48] text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                  {LL0().article.tax()}
                </div>
              </div>
              <div className="w-[88px] h-14 flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] md:h-12 sm:w-16 sm:h-10 mq768:w-14">
                  <div
                    className="relative text-lg font-semibold font-mulish text-[#2e2e48] text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                    {LL0().onlineOrder.status()}
                  </div>
                </div>
              <div className="w-[88px] h-14 flex flex-row items-center justify-center py-0 px-2 box-border md:w-20 md:h-12 sm:w-16 sm:h-10 mq768:w-12">
                        <div
                          className="relative text-lg font-semibold font-mulish text-[#2e2e48] text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                          {LL0().ui.delete()}
                        </div>
                      </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        {STATES.editingModifier()?.items?.map(item => (
          <tr key={item._id}>
            <td className="relative pr-0 pb-0">
              <div
                className="w-full flex flex-row items-center justify-center py-0 px-2 box-border max-w-[24px] h-full">
                <div className="relative w-6 h-6 overflow-hidden shrink-0">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/iconreplace-position-icon1@2x.png"
                  />
                </div>
              </div>
            </td>
            <td className="relative pb-0">
              <div
                className="bg-white-solid-white-100-ffffff box-border shrink-0 flex flex-row items-center justify-start py-2 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-l-[1px] hover:bg-white-solid-white-100-ffffff hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start hover:py-2 hover:px-0 hover:box-border">
                <div className="self-stretch flex-1 flex flex-row items-center justify-start py-0 px-2">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    label=""
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    value={item.name}
                    onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'name', e.target.value)}
                  />
                </div>
                <div
                  className="self-stretch w-40 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    size="small"
                    placeholder="0"
                    fullWidth={true}
                    variant="outlined"
                    type="number"
                    value={numberToString(item.price)}
                    onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'price', numberFromString(e.target.value))}
                  />
                </div>
                <div
                  className="self-stretch w-40 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <FormControl
                    className="self-stretch flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-260-304eee"
                    variant="outlined"
                  >
                    <InputLabel color="primary"/>
                    <Select
                      color="primary" size="small"
                      value={numberToString(item.tax)}
                      onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'tax', numberFromString(e.target.value))}
                    >
                      {
                        taxCategoriesVM().map((taxCategory, index) =>
                          <MenuItem key={index} value={getTax(taxCategory)}>
                            {taxCategory.name} {getTax(taxCategory)}%
                          </MenuItem>)
                      }
                    </Select>
                    <FormHelperText/>
                  </FormControl>
                </div>
                <div
                  className="self-stretch w-[88px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] sm:w-16 mq768:w-14">
                  <div className="relative w-10 h-5">
                    <FormControlLabel
                      label=""
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                      control={<Switch
                        color="primary"
                        checked={!!item.isEnabled}
                        onChange={(_, checked) => VIEW__MODIFIER.onItemChanged(item._id, 'isEnabled', checked)}
                      />}
                    />
                  </div>
                </div>
                <div
                  className="self-stretch flex flex-row items-center justify-center py-0 px-6 md:w-20 sm:w-16 mq768:w-12">
                  <img
                    className="relative w-10 h-10 object-cover cursor-pointer"
                    alt=""
                    src="/iconcell-bin-icon@2x.png"
                    onClick={() => (openEditItemDeleteConfirmPo(), setStagingItem(item))}
                  />
                </div>
              </div>
            </td>
          </tr>
        ))}
        {/*
          <tr>
            <td className="relative pr-0 pb-0">
              <div className="w-full flex flex-row items-center justify-center py-0 px-2 box-border max-w-[24px] h-full">
                <div className="relative w-6 h-6 overflow-hidden shrink-0">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/iconreplace-position-icon1@2x.png"
                  />
                </div>
              </div>
            </td>
            <td className="relative pb-0">
              <div className="bg-blue-solid-blue-100-eff1f9 box-border shrink-0 flex flex-row items-center justify-start py-2 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-l-[1px] hover:bg-blue-solid-blue-100-eff1f9 hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start hover:py-2 hover:px-0 hover:box-border">
                <div className="self-stretch flex-1 flex flex-row items-center justify-start py-0 px-2">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    label="Name"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
                <div className="self-stretch w-40 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    size="small"
                    placeholder="0"
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
                <div className="self-stretch w-40 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <FormControl
                    className="self-stretch flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-260-304eee"
                    variant="outlined"
                  >
                    <InputLabel color="primary" />
                    <Select color="primary" size="small">
                      <MenuItem value="Exempt">{LL0().editOnlineMenu.exempt()}</MenuItem>
                      <MenuItem value="5%">5%</MenuItem>
                      <MenuItem value="7%">7%</MenuItem>
                      <MenuItem value="8%">8%</MenuItem>
                      <MenuItem value="10%">10%</MenuItem>
                      <MenuItem value="19%">19%</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                </div>
                <div className="self-stretch w-[88px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] sm:w-16 mq768:w-14">
                  <div className="relative w-10 h-5">
                    <FormControlLabel
                      label=""
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                      control={<Switch color="primary" />}
                    />
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-center justify-center py-0 px-6 md:w-20 sm:w-16 mq768:w-12">
                  <img
                    className="relative w-10 h-10 object-cover cursor-pointer"
                    alt=""
                    src="/iconcell-bin-icon@2x.png"
                    onClick={openEditItemDeleteConfirmPo1}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="relative pr-0 pb-0">
              <div className="w-full flex flex-row items-center justify-center py-0 px-2 box-border max-w-[24px] h-full">
                <div className="relative w-6 h-6 overflow-hidden shrink-0">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/iconreplace-position-icon1@2x.png"
                  />
                </div>
              </div>
            </td>
            <td className="relative pb-0">
              <div className="bg-white-solid-white-100-ffffff box-border shrink-0 flex flex-row items-center justify-start py-2 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-l-[1px] hover:bg-white-solid-white-100-ffffff hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start hover:py-2 hover:px-0 hover:box-border">
                <div className="self-stretch flex-1 flex flex-row items-center justify-start py-0 px-2">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    label="Name"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
                <div className="self-stretch w-40 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    size="small"
                    placeholder="0"
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
                <div className="self-stretch w-40 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <FormControl
                    className="self-stretch flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-260-304eee"
                    variant="outlined"
                  >
                    <InputLabel color="primary" />
                    <Select color="primary" size="small">
                      <MenuItem value="Exempt">{LL0().editOnlineMenu.exempt()}</MenuItem>
                      <MenuItem value="5%">5%</MenuItem>
                      <MenuItem value="7%">7%</MenuItem>
                      <MenuItem value="8%">8%</MenuItem>
                      <MenuItem value="10%">10%</MenuItem>
                      <MenuItem value="19%">19%</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                </div>
                <div className="self-stretch w-[88px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] sm:w-16 mq768:w-14">
                  <div className="relative w-10 h-5">
                    <FormControlLabel
                      label=""
                      className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%]"
                      control={<Switch color="primary" />}
                    />
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-center justify-center py-0 px-6 md:w-20 sm:w-16 mq768:w-12">
                  <img
                    className="relative w-10 h-10 object-cover cursor-pointer"
                    alt=""
                    src="/iconcell-bin-icon@2x.png"
                    onClick={openEditItemDeleteConfirmPo2}
                  />
                </div>
              </div>
            </td>
          </tr>
          */}
        <tr>
          <td className="relative pr-0">
            <div className="relative bg-gray-opacity-gray-0-d9d9d9-300 w-6 max-w-[24px]"/>
          </td>
          <td className="relative">
            <div
              className="rounded-t-none rounded-b-2xl bg-gray-solid-gray-80-fafafa box-border shrink-0 flex flex-row items-start justify-start py-3 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-b-[1px] border-l-[1px] hover:bg-gray-solid-gray-80-fafafa hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-start hover:justify-start hover:rounded-t-none hover:rounded-b-2xl hover:py-3 hover:px-0 hover:box-border sm:pt-2.5 sm:pb-2.5 sm:box-border mq768:pt-2 mq768:pb-2 mq768:box-border mq600:pt-1 mq600:pb-1 mq600:box-border">
              <button ref={makeRipple} onClick={VIEW__MODIFIER.onCreateItem}
                      className="self-stretch flex-1 flex flex-row items-center justify-start py-0 pr-2 pl-3 gap-[10px]">
                <img
                  className="relative w-11 h-11 object-cover sm:w-10 sm:h-10 mq768:w-9 mq768:h-9 mq600:w-8 mq600:h-8"
                  alt=""
                  src="/iconadd-option-value-icon@2x.png"
                />
                <div
                  className="flex-1 relative text-base font-semibold font-mulish-button-name-bold text-gray-opacity-gray-60-2e2e48-300 text-left md:text-smi sm:text-xs">
                  {LL0().editOnlineMenu.addValue()}
                </div>
              </button>
              <div className="self-stretch w-40 md:w-[130px] sm:w-[110px]"/>
              <div className="self-stretch w-40 md:w-[130px] sm:w-[110px]"/>
              <div className="self-stretch w-[88px] md:w-[72px] sm:w-16 mq768:w-14"/>
              <div className="self-stretch w-[88px] md:w-20 sm:w-16 mq768:w-12"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      {isEditItemDeleteConfirmPoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo}
        >
          <PopupDeleteConfirmation
            onClose={closeEditItemDeleteConfirmPo}
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${stagingItem?.name}"?`}
            onConfirmed={() => (closeEditItemDeleteConfirmPo(), VIEW__MODIFIER.onItemDelete(stagingItem?._id))}/>
        </PortalPopup>
      )}
      {isEditItemDeleteConfirmPo1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo1}
        >
          <PopupDeleteConfirmation onClose={closeEditItemDeleteConfirmPo1}/>
        </PortalPopup>
      )}
      {isEditItemDeleteConfirmPo2Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo2}
        >
          <PopupDeleteConfirmation onClose={closeEditItemDeleteConfirmPo2}/>
        </PortalPopup>
      )}
    </>
  );
};

export default ModifierTypeSingleFieldset;
