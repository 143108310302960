import { type FunctionComponent, useState, useCallback } from "react";
import {
  Switch,
  FormControlLabel,
} from "@mui/material";
import PrinterTemplatePopup from "@printer-setting/PrinterTemplatePopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import type { PrinterGeneralSetting } from '@/data/PosSetting.ts';
import _ from "lodash";
import { additionalSetting0, getInfoFor } from '@/react/Printer/PrinterSettingView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

const additionalSettingSwitchProps = (type: keyof PrinterGeneralSetting) => ({

  checked: !!additionalSetting0()?.[type] || false,

  onChange: (e: any) => {
    userFLow(`additional setting switch ${type} ${e.target.checked}`, {
      username: loginUser()?.name
    });
    _.assign(additionalSetting0(), { [type]: e.target.checked })
  }

})

const AdditionalPrinterSettings: FunctionComponent = () => {
  const [isAdvancedAccordionOpen, setIsAdvancedAccordtionOpen] = useState<boolean>(true);

  const [open, setOpen] = useState(false)
  const [popupTitle, setPopupTitle] = useState('')
  const [popupContent, setPopupContent] = useState('')

  const closePopup = useCallback(() => setOpen(false), [])
  const openPopupFor = useCallback(
    (field: keyof PrinterGeneralSetting) => () => {
      userFLow(`open popup for ${field}`, {
        username: loginUser()?.name
      });
      const [title, content] = getInfoFor(field)
      setPopupTitle(title)
      setPopupContent(content)
      setOpen(true)
    },
    []
  )

  return (
    <>
      <div
        className="!flex no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start gap-[8px] z-[2] text-left text-mini text-gray-300 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          {!isAdvancedAccordionOpen ?
            <div className="!flex self-stretch hidden flex-row items-center justify-start pt-2 px-0 pb-0 gap-[4px]"
                 onPointerDown={() => setIsAdvancedAccordtionOpen(prev => !prev)}
            >
              <b className="relative [text-decoration:underline] Tablet_768:text-sm uppercase">
                {LL0().settings.advanced()}
              </b>
              <img
                className="w-6 relative h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"
                alt=""
                src="/iconarrow-up-icon--grey1@2x.png"
              />
            </div>
            :
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">
              <div className="self-stretch flex flex-row items-center justify-start pt-2 px-0 pb-0 gap-[0px_4px]"
                   onPointerDown={() => setIsAdvancedAccordtionOpen(prev => !prev)}
              >
                <b className="relative [text-decoration:underline] Tablet_768:text-sm uppercase">
                  {LL0().settings.advanced()}
                </b>
                <img
                  className="w-6 relative h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"
                  alt=""
                  src="/iconarrow-down-icon--grey@2x.png"
                />
              </div>
              <div
                className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-2 gap-[12px] text-sm text-black-solid-black-880-1d1d26 border-b-[0.4px] border-solid border-gray-solid-gray-480-b1b1b1">
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                    <div className="w-12 relative h-6">
                      <FormControlLabel
                        label=''
                        className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                        control={<Switch color="primary"
                                         size="medium" {...additionalSettingSwitchProps("displayLumpSum")} />}
                      />
                    </div>
                    <div className="flex-1 relative">
                      {LL0().settings.displayLumpSum()}
                    </div>
                  </div>
                  <img
                    className="w-6 relative h-6 object-cover cursor-pointer"
                    alt=""
                    src="/iconquestion-icon1@2x.png"
                    onClick={openPopupFor("displayLumpSum")}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                    <div className="w-12 relative h-6">
                      <FormControlLabel
                        label=''
                        className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                        control={<Switch color="primary"
                                         size="medium" {...additionalSettingSwitchProps("printPickUpNumber")} />}
                      />
                    </div>
                    <div className="flex-1 relative">
                      {LL0().settings.generalSetting.printPickUpNumber()}
                    </div>
                  </div>
                  <img
                    className="w-6 relative h-6 object-cover cursor-pointer"
                    alt=""
                    src="/iconquestion-icon1@2x.png"
                    onClick={openPopupFor("printPickUpNumber")}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                    <div className="w-12 relative h-6">
                      <FormControlLabel
                        label=''
                        className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                        control={<Switch color="primary"
                                         size="medium" {...additionalSettingSwitchProps("printPickUpTicket")} />}
                      />
                    </div>
                    <div className="flex-1 relative">
                      {LL0().settings.generalSetting.printPickUpTicket()}
                    </div>
                  </div>
                  <img
                    className="w-6 relative h-6 object-cover cursor-pointer"
                    alt=""
                    src="/iconquestion-icon1@2x.png"
                    onClick={openPopupFor("printPickUpTicket")}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                    <div className="w-12 relative h-6">
                      <FormControlLabel
                        label=''
                        className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                        control={<Switch color="primary"
                                         size="medium" {...additionalSettingSwitchProps("autoPrintInvoiceBeforePaying")} />}
                      />
                    </div>
                    <div className="flex-1 relative">
                      {LL0().settings.generalSetting.autoPrintInvoiceBeforePaying()}
                    </div>
                  </div>
                  <img
                    className="w-6 relative h-6 object-cover cursor-pointer"
                    alt=""
                    src="/iconquestion-icon1@2x.png"
                    onClick={openPopupFor("autoPrintInvoiceBeforePaying")}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                    <div className="w-12 relative h-6">
                      <FormControlLabel
                        label=''
                        className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                        control={<Switch color="primary"
                                         size="medium" {...additionalSettingSwitchProps("printOverMasterDevice")} />}
                      />
                    </div>
                    <div className="flex-1 relative">
                      {LL0().settings.generalSetting.printOverMasterDevice()}
                    </div>
                  </div>
                  <img
                    className="w-6 relative h-6 object-cover cursor-pointer"
                    alt=""
                    src="/iconquestion-icon@2x.png"
                    onClick={openPopupFor("printOverMasterDevice")}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex-1 flex flex-row items-center justify-start gap-[4px]">
                    <div className="w-12 relative h-6">
                      <FormControlLabel
                        label=''
                        className="absolute h-full w-[83.33%] top-[0%] right-[8.33%] bottom-[0%] left-[8.33%]"
                        control={<Switch color="primary"
                                         size="medium"
                                         checked={generalSetting0()?.preventShowErrorPopUpForPrinter || false}
                                         onChange={(event, checked) => {
                                           userFLow(`change switch preventShowErrorPopUpForPrinter to ${generalSetting0()?.preventShowErrorPopUpForPrinter}`, {
                                             username: loginUser()?.name
                                           })
                                           _.assign(generalSetting0(), { preventShowErrorPopUpForPrinter: checked })
                                         }}
                        />}
                      />
                    </div>
                    <div className="flex-1 relative">
                      {LL0().printerSetting.preventPrinterErrorPopup()}
                    </div>
                  </div>
                  {/*<img*/}
                  {/*  className="w-6 relative h-6 object-cover cursor-pointer"*/}
                  {/*  alt=""*/}
                  {/*  src="/iconquestion-icon@2x.png"*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
          }
        </div>
        {/*TODO: check this*/}
        {/*  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">*/}
        {/*    <div className="self-stretch hidden flex-row items-center justify-start pt-2 px-0 pb-0 gap-[4px]">*/}
        {/*      <b className="relative [text-decoration:underline] Tablet_768:text-sm">*/}
        {/*        GENERAL PRINTER*/}
        {/*      </b>*/}
        {/*      <img*/}
        {/*        className="relative w-6 h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"*/}
        {/*        alt=""*/}
        {/*        src="/iconarrow-up-icon--grey@2x.png"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">*/}
        {/*      <div className="self-stretch flex flex-row items-center justify-start pt-2 px-0 pb-0 gap-[0px_4px]">*/}
        {/*        <b className="relative [text-decoration:underline] Tablet_768:text-sm">*/}
        {/*          GENERAL PRINTER*/}
        {/*        </b>*/}
        {/*        <img*/}
        {/*          className="w-6 relative h-6 object-cover"*/}
        {/*          alt=""*/}
        {/*          src="/iconarrow-down-icon--grey@2x.png"*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <div className="flex flex-row items-center justify-start gap-[8px]">*/}
        {/*        <div className="flex flex-row flex-wrap items-start justify-start gap-[8px]">*/}
        {/*          <PrinterSelectButton*/}
        {/*            buttonNumer="0"*/}
        {/*            isSelected*/}
        {/*            printerSelectButtonWidth="40px"*/}
        {/*            printerSelectButtonPadding="0px 16px"*/}
        {/*            selectedWidth="100%"*/}
        {/*            originalPriceFontStyle="italic"*/}
        {/*          />*/}
        {/*          <PrinterSelectButton*/}
        {/*            buttonNumer="1"*/}
        {/*            isSelected={false}*/}
        {/*            printerSelectButtonWidth="40px"*/}
        {/*            printerSelectButtonPadding="0px 16px"*/}
        {/*            selectedWidth="100%"*/}
        {/*            originalPriceFontStyle="italic"*/}
        {/*          />*/}
        {/*          <PrinterSelectButton*/}
        {/*            buttonNumer="2"*/}
        {/*            isSelected={false}*/}
        {/*            printerSelectButtonWidth="40px"*/}
        {/*            printerSelectButtonPadding="0px 16px"*/}
        {/*            selectedWidth="100%"*/}
        {/*            originalPriceFontStyle="italic"*/}
        {/*          />*/}
        {/*          <PrinterSelectButton*/}
        {/*            buttonNumer="3"*/}
        {/*            isSelected={false}*/}
        {/*            printerSelectButtonWidth="40px"*/}
        {/*            printerSelectButtonPadding="0px 16px"*/}
        {/*            selectedWidth="100%"*/}
        {/*            originalPriceFontStyle="italic"*/}
        {/*          />*/}
        {/*          <PrinterSelectButton*/}
        {/*            buttonNumer="4"*/}
        {/*            isSelected={false}*/}
        {/*            printerSelectButtonWidth="40px"*/}
        {/*            printerSelectButtonPadding="0px 16px"*/}
        {/*            selectedWidth="100%"*/}
        {/*            originalPriceFontStyle="italic"*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*        <img*/}
        {/*          className="relative w-6 h-6 object-cover cursor-pointer"*/}
        {/*          alt=""*/}
        {/*          src="/iconquestion-icon4@2x.png"*/}
        {/*          onClick={openPrinterTemplatePopup8}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*  </div>*/}
        {/*  /!*<div className="self-stretch flex flex-col items-start justify-start gap-[8px]">*!/*/}
        {/*  /!*  <div className="self-stretch hidden flex-row items-center justify-start pt-2 px-0 pb-0 gap-[4px]">*!/*/}
        {/*  /!*    <b className="relative [text-decoration:underline] uppercase">*!/*/}
        {/*  /!*{LL0().settings.courseSetting()}*!/*/}
        {/*  /!*    </b>*!/*/}
        {/*  /!*    <img*!/*/}
        {/*  /!*      className="relative w-6 h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"*!/*/}
        {/*  /!*      alt=""*!/*/}
        {/*  /!*      src="/iconarrow-up-icon--grey@2x.png"*!/*/}
        {/*  /!*    />*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*  <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">*!/*/}
        {/*  /!*    <div className="self-stretch flex flex-row items-center justify-start pt-2 px-0 pb-0 gap-[4px]">*!/*/}
        {/*  /!*      <b className="relative [text-decoration:underline] uppercase">*!/*/}
        {/*  /!*{LL0().settings.courseSetting()}*!/*/}
        {/*  /!*      </b>*!/*/}
        {/*  /!*      <img*!/*/}
        {/*  /!*        className="relative w-6 h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"*!/*/}
        {/*  /!*        alt=""*!/*/}
        {/*  /!*        src="/iconarrow-down-icon--grey@2x.png"*!/*/}
        {/*  /!*      />*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*    <div className="self-stretch flex flex-col items-start justify-start gap-[4px] text-sm text-gray-400">*!/*/}
        {/*  /!*      <div className="relative">*!/*/}
        {/*  /!*{LL0().settings.courseSettingInterval()}:*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*      <div className="self-stretch flex flex-row items-start justify-start gap-[8px] Tablet_768:flex-col Tablet_768:gap-[12px]">*!/*/}
        {/*  /!*        <TextField*!/*/}
        {/*  /!*          className="[border:none] bg-[transparent] self-stretch flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26 Tablet_768:flex-[unset] Tablet_768:self-stretch"*!/*/}
        {/*  /!*          color="primary"*!/*/}
        {/*  /!*          size="small"*!/*/}
        {/*  /!*          fullWidth={true}*!/*/}
        {/*  /!*          variant="outlined"*!/*/}
        {/*  /!*        />*!/*/}
        {/*  /!*        <div className="rounded-10xs bg-whitesmoke-100 shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] box-border h-[37px] flex flex-row items-center justify-start py-0 px-2 gap-[8px] border-[0.5px] border-solid border-darkgray">*!/*/}
        {/*  /!*          <div className="relative">{LL0().settings.changeCoursesName()}</div>*!/*/}
        {/*  /!*          <img*!/*/}
        {/*  /!*            className="relative w-6 h-6 object-cover"*!/*/}
        {/*  /!*            alt=""*!/*/}
        {/*  /!*            src="/iconpencil-icon@2x.png"*!/*/}
        {/*  /!*          />*!/*/}
        {/*  /!*        </div>*!/*/}
        {/*  /!*      </div>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*  </div>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
      </div>
      {open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePopup}
        >
          <PrinterTemplatePopup onClose={closePopup} title={popupTitle} content={popupContent}/>
        </PortalPopup>
      )
      }
    </>
  )
    ;
};

export default AdditionalPrinterSettings;