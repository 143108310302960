import type { FunctionComponent } from "react";
import { itemContext } from "@/react/OrderView/OrderView.tsx";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { signal } from "@/react/core/reactive";
import { LL0 } from "@/react/core/I18nService";
import { router } from "@/pos/PosRouter";
import { mainScreen } from "@/data/PosSettingsSignal.ts";
import { toast } from "react-toastify";

export type ConfirmMergeTableType = {
  className?: string;
  onClose?: () => void;
};
export const [isOpenMergeSeatPopUp, setIsOpenMergeSeatPopup] = signal<boolean>(false)
const ConfirmMergeTablePopUp: FunctionComponent<ConfirmMergeTableType> = ({
  className = "",
  onClose,
}) => {

  return (
    <div
      className={`w-full py-[24px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center px-8 Mobile_480:px-2  box-border gap-2 min-w-[300px] max-w-[548px] h-full max-h-full text-center text-base text-black-solid-black-880-1d1d26 font-mulish ${className}`}
    >
      <div className="gap-y-[16px] Mobile_480:pb-2 pb-[24px] px-5">
        <div className="text-[16px] self-stretch relative leading-[28px] font-normal">
          {LL0().dialogs.moveItem.notice()}
        </div>
        <div className="text-[16px] self-stretch relative leading-[28px] font-bold">
          {LL0().dialogs.moveItem.chooseButton()}
        </div>
      </div>
      <div
        className="gap-3 px-4 self-stretch flex flex-col items-center justify-center text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="w-full rounded-md bg-white-solid-white-100-ffffff border-[#F0603E] border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-[44px] cursor-pointer"
          onClick={() => {
            onClose?.();
            itemContext.onGroupToggle();
          }}
          ref={makeRipple}
        >
          <b className="text-[16px] text-[#F0603E] relative">{LL0().dialogs.moveItem.mergeAll()}</b>
        </div>
        <div
          className="w-full rounded-md bg-white-solid-white-100-ffffff border-blue-solid-blue-420-2979ff border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-[44px] cursor-pointer"
          onClick={() => {
            if (order0()?.seatMode) {
              setIsOpenMergeSeatPopup(true)
              onClose?.();
            } else {
              onClose?.();
              if (!order0()?.hasOwnProperty('isMoveOrder')) {
                itemContext.onMoveToggle();
              } else {
                toast.warning('Leave the table or print order before moving again!!!', {autoClose: 600})
              }
            }
          }}
          ref={makeRipple}
        >
          <b className="text-[16px] relative">{LL0().dialogs.moveItem.mergeEachItem()}</b>
        </div>
      </div>
      <div
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 cursor-pointer z-[1]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icong-close-popup-icon--grey12@2x.png"
        />
      </div>
    </div>
  );
};

export default ConfirmMergeTablePopUp;
