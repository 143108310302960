import { type FunctionComponent, useCallback } from 'react';
import type { DocDeepSignal } from '@/data/data-utils.ts';
import type { HappyHour } from '@/data/HappyHour.ts';
import { useSelector } from '@/react/core/reactive.ts';
import {
  discountTypeLabels,
  happyHourId0,
  setHappyHourId0,
  weekDayPeriodConverter
} from '@/react/HappyHourView/HappyHourView.tsx';
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type HappyHourHappyHourItemType = {
  weekdayPeriod?: string;
  hourTime?: string;
  discount?: string;
  isSelected?: boolean;

  happyHourData: DocDeepSignal<HappyHour>;
};

const HappyHourHappyHourItem: FunctionComponent<HappyHourHappyHourItemType> = ({
  weekdayPeriod = "Mon - Tue, Thu - Fri, Sun",
  hourTime = "9:00 - 12:30",
  discount = "- €2",
  isSelected = true,
  happyHourData
}) => {
  const isSelectedHappyHour = useSelector<string | undefined, HappyHour>(happyHourId0, (p1, p2) => p1 === p2?._id);
  const valueLabel = useCallback(() => {
    if (!happyHourData.discountType) return '';
    if (discountTypeLabels?.[happyHourData.discountType as keyof typeof discountTypeLabels]?.valueLabel === '- %') {
      return discountTypeLabels?.[happyHourData.discountType as keyof typeof discountTypeLabels]?.valueLabel
    } else {
      return `${discountTypeLabels?.[happyHourData.discountType as keyof typeof discountTypeLabels]?.valueLabel.split(" ")[0]} ${LL3().common.currency()}`;
    }
  }, [happyHourData.discountType]);
  weekdayPeriod = weekDayPeriodConverter(happyHourData.days ?? []);
  hourTime = `${happyHourData.from} - ${happyHourData.to}`;
  discount = `${valueLabel()}${happyHourData.discountValue}`;
  isSelected = isSelectedHappyHour(happyHourData)

  const onClick = useCallback(() => {
    userFLow(`select hour time in sidebar left in voucher ${happyHourData?._id}`, {
      username: loginUser()?.name
    })
    setHappyHourId0(happyHourData._id)
  }, [])

  return (
    <div className="bg-basic-color-white-solid-white-100-ffffff box-border overflow-hidden flex flex-col items-start justify-center py-[11px] px-[21px] relative gap-[6px] text-left text-smi text-gray-solid-gray-910-4d4d4e font-mulish self-stretch border-t-[1px] border-solid border-gray-solid-gray-360-d6d5d5 border-b-[1px]"
         onPointerDown={onClick}
    >
      {isSelected && (
        <div className="absolute my-0 mx-[!important] h-[98.44%] w-full top-[0%] right-[0%] bottom-[1.56%] left-[0%] bg-gray-opacity-gray-50-f2f2f2 z-[0]" />
      )}
      <div className="relative z-[1] Tablet_768:text-2xs">{weekdayPeriod}</div>
      <div className="self-stretch flex flex-row items-end justify-between z-[2] text-mini">
        <b className="relative Tablet_768:text-smi mr-2">{hourTime}</b>
        <b className="relative text-blue-solid-blue-420-2979ff Tablet_768:text-smi">
          {discount}
        </b>
      </div>
      {isSelected && (
        <div className="absolute my-0 mx-[!important] top-[calc(50%_-_33px)] right-[0px] bg-blue-solid-blue-460-1471ff shadow-[0px_0px_4px_rgba(20,_113,_255,_0.6)] w-[5px] h-[90px] z-[3]" />
      )}
    </div>
  );
};

export default HappyHourHappyHourItem;
