import type { JsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'

import { createCollection } from './utils/migration'
import type { PosFeatures } from "@/srm/features.logic.ts";
import type { WorkingMode } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";

export const POS_SETTING_COLLECTION_NAME = 'pos_settings'

export const VERSION = 62

export interface Cashbook {
  incomingTypes?: any[]
  outgoingTypes?: any[]
}

export type KeyboardConfig = {
  active?: boolean
  onlyShowInFirstPage?: boolean
  x?: boolean
  top?: number
  left?: number
  width?: number
  height?: number
  //additional columns from right to left
  rows: Array<Array<string>>
}

export interface GeneralSetting {
  beginHour?: string
  secondDisplayLanguage?: string
  quickBtn?: boolean
  quickFnRows?: number
  deliveryOrderMode?: string
  useVirtualPrinter?: boolean
  useVirtualKeyboard?: boolean
  automaticCashDrawer?: boolean
  googleMapApiKey?: string
  onlyCheckoutPrintedItems?: boolean
  showTutorial?: boolean
  frontendLanguage?: string
  isProductPriceIncludingTax?: boolean
  taxType?: string
  useTimeClock?: boolean
  courseDuration?: number
  logUserActions?: boolean
  tseEnable?: boolean
  /** Enable communication with Quebec's WEB-SRM */
  quebecSrmEnable?: boolean
  defaultTips?: number[]
  serviceCharges?: number[]
  hasTablePlan?: boolean
  runInventory?: boolean
  isDisplayPasscodeTutorial?: boolean
  resetMaxIdPerDay: boolean
  autoLogOutWhenCloseApp?: boolean
  autoLogOutAfterInactivity?: boolean
  autoLogOutPeriod?: number
  serviceFeeAsItem?: boolean
  decimalPointExpressed?: number
  cashTipEnable?: boolean
  virtualPrinter?: boolean
  cashbookEnable?: boolean
  multipleDineInMenusEnable?: boolean
  multipleDineInMenus?: Array<{
    _id?: string
    name?: string
    active?: boolean
    isTakeAway?: boolean
    isPrintKitchen?: boolean
  }>
  mergeSimilarItem?: boolean
  monitoring?: boolean
  useMenuV2?: boolean
  allowMultiplePayments?: boolean
  hideFastCheckout?: boolean
  hideManualTable?: boolean
  addCustomerAfterOrder?: boolean
  showPopupPrintError?: boolean,
  preventShowErrorPopUpForPrinter?: boolean
  displayKitchenEnable?: boolean
  enableKitchenBell?: boolean
  limitBellOnly3Times?: boolean
  onlySortByDate?: boolean
  oneColumnPerOrder?: boolean
}

export interface CompanyInfo {
  name?: string
  address?: string
  address2?: string
  zipCode?: string
  city?: string
  ustId?: string
  country?: string
  backendLanguage?: string
  telephone?: string
  taxNumber?: string
  taxLabel?: string
  logo?: string
  logoSize?: number
  footerText?: string
  redInvoiceContent?: string
  province?: string
  owner?: string
  email?: string
}

export interface PosFeature {
  showPendingOrder?: boolean
  showNewDelivery?: boolean
  showOnlineMenu?: boolean
  showReservation?: boolean
  showCustomerInfo?: boolean
}

export interface PrinterGeneralSetting {
  displayLumpSum?: boolean
  printPickUpNumber?: boolean
  printPickUpTicket?: boolean
  autoPrintInvoiceBeforePaying?: boolean
  printOverMasterDevice?: boolean
  sound?: boolean
  tscPos?: boolean
  escPos?: boolean
  keepConnection?: boolean
  merge?: boolean
  hidePrinterName?: boolean
  hideProductName?: boolean
  flashed?: boolean
  invertColor?: boolean
  splitArticles?: boolean
  takeAwayLabel?: 'icon' | 'delivery' | 'pickup' | 'fastCheckout' | 'takeAway'
}

export interface PrinterCache {
  /** base64 encoded Raster */
  header0?: string
  header0_md5?: string
  /** base64 encoded Raster */
  header1?: string
  header1_md5?: string
  /** base64 encoded Raster */
  payment: string
  payment_md5: string
  /** base64 encoded Raster */
  qrCode: string
  qrCode_md5: string
  /** base64 encoded Raster */
  footerText: string
  footerText_md5: string
  /** base64 encoded Raster */
  redInvoiceContent: string
  redInvoiceContent_md5: string

  /** base64 encoded Raster */
  tseSerialNumber: string
  tseSerialNumber_md5: string
  /** base64 encoded Raster */
  tseTimeFormat: string
  tseTimeFormat_md5: string
  /** base64 encoded Raster */
  tseSignatureAlgorithm: string
  tseSignatureAlgorithm_md5: string
  /** base64 encoded Raster */
  tsePublicKey: string
  tsePublicKey_md5: string
  /** base64 encoded Raster */
  tseUserName: string
  tseUserName_md5: string
}

export interface PosSrmSetting {
  /**
   * [IDSEV] "Product identifier" in the Quebec Dashboard
   *
   * Unique SRS identifier used to send requests to the WEB-SRM. We send you this number when
   * you register your SRS using My Account for partners on our website.
   *
   * Holder: SRS developer
   */
  productId?: string
  /**
   * [IDVERSI] "Version identifier" of the registered product in the Quebec Dashboard
   *
   * Unique SRS version identifier used to send requests to the WEB-SRM. We send you this
   * number when you register your SRS using My Account for partners on our website.
   *
   * Holder: SRS developer
   */
  productVersionId?: string
  /**
   * [IDPARTN] "Partner identifier" in the Quebec Dashboard
   *
   * Unique partner identifier (your SRS developer identifier). We send you this number when you
   * register your SRS using My Account for partners on our website.
   *
   * Holder: SRS developer
   */
  partnerId?: string
  /**
   * [codCertif] "Certificate Code"
   *
   * Code issued by us proving that the SRS is certified and can communicate with the WEB-SRM.
   *
   * Holder: SRS developer
   */
  certificateCode?: string
  /**
   * [CODCERTIF] "Authenticate Code"
   *
   * Code provided by Revenu Québec that is used to identify requestors to the WEB-SRM and
   * obtain a digital certificate. Mandataries must keep their authorization codes confidential.
   *
   * Holder: Mandatary
   */
  authCode?: string
  /**
   * [noDossFO] "Mandatory billing file number". Format: AA9999
   *
   * Number provided by Revenu Québec to identify a sector of activity and, if applicable, the location
   * of the mandatary’s establishment based on its QST registration number.
   *
   * Holder: Mandatary
   */
  billingNumber?: string
  /**
   * [CN] "Mandatory's identification number". Format: 1234567890
   *
   * Number assigned by Revenu Québec and used each time a person communicates with
   * Revenu Québec. Under the mandatory billing measures, this number is primarily used to identify
   * the owner of the digital certificate.
   *
   * Holder: Mandatary
   */
  identificationNumber?: string
  /**
   * @deprecated Use device timezone instead
   * [utc] Coordinated Universal Time used by the SRS when recording the date transaction (datTrans). Format: +/-00:00
   */
  timezone?: string
  /**
   * @deprecated Use device timezone instead
   * [utc]  indicator of Standard and Daylight Savings Time. Format: 'A' | 'N'
   */
  timezoneZ?: string
  /** [noTVQ] Mandatary's QST number */
  qstNumber?: string
  /** [noTPS] Mandatary's GST number */
  gstNumber?: string
  /**
   * SRS version corresponding to the update of the parent version (versiParn). This number is assigned by the developer (see section 4.4.1.1.2).
   *
   * Length: 1 to 20 characters
   * It must only contain ASCII characters according to section 4.1.2.
   */
  version?: string
  /**
   * Parent version of the SRS. This number is assigned by the developer (see section 4.4.1.1.2).
   *
   * Length: 1 to 20 characters
   * It must only contain ASCII characters according to section 4.1.2.
   */
  previousVersion?: string
  /**
   * Test case number and step.
   *
   * Format: 999.999
   */
  testcaseNumber?: string

  /**
   * [ENVIRN] Environment used to make the request
   *
   * Possible values are:
   *  - **DEV**: Développement (development)
   *  - **ESSAI**: Cas d’essais (test cases) (certification)
   *  - **PROD**: Production (production)
   */
  env?: 'DEV' | 'ESSAI' | 'PROD',
  features?: Record<PosFeatures, boolean | undefined>
}

export interface PosSetting {
  _id: string
  generalSetting?: GeneralSetting
  posDatabase?: {
    _id?: string
    id: number
    name: string
  } | null
  hardwares?: Array<{
    name?: string
    id?: string
  }>
  keyboardConfig?: KeyboardConfig
  // fixme
  // call?: {
  //   ipAddresses: {}
  // },
  companyInfo?: CompanyInfo
  // fixme
  // keyboardDeliveryConfig?: Array<{}>,
  // onlineDevice: {
  //   services?: {}
  //   store?: {}
  // }
  // onlineOrderSoundSetting?: {}
  printerGeneralSetting?: PrinterGeneralSetting
  // fixme
  // reservation?: {
  //   openHours: Array<{}>
  //   seatLimit: Array<{}>
  // }
  // signInRequest?: {}
  posFeature?: PosFeature

  customerLedDisplay?: {
    interface?: string
    device?: string
  }
  hardwareID?: string
  divArticleSettings?: {
    drink?: {
      tax?: number
      tax2?: number
      groupPrinter?: string
      groupPrinter2?: string
    }
    food?: {
      groupPrinter?: string
      groupPrinter2?: string
      tax?: number
      tax2?: number
    }
  }
  reportSettings?: {
    showProductSold?: boolean
    showMoreInfo?: boolean
  }
  reportSetting?: {
    receiversEmail?: string
    biweekly?: boolean
    daily?: boolean
    weekly?: boolean
    monthly?: boolean
  }
  masterDevice?: string
  masterIp?: string
  defaultTakeaway?: boolean
  server?: string
  srm?: PosSrmSetting
  printerCache?: PrinterCache
  replicateServer?: {
    username: string
    password: string
  }
  /** If true, will use only one OnlineMenu for all OnlineProviders */
  useSingleOnlineMenu: boolean
  menuScrollbar: boolean

  cashbook?: Cashbook
  workingMode?: WorkingMode
}

const PosSettingSchema: RxJsonSchema<PosSetting> = {
  title: 'posSetting schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    generalSetting: <JsonSchema<GeneralSetting>>{
      type: 'object',
      properties: {
        beginHour: { type: 'string' },
        quickBtn: { type: 'boolean' },
        quickFnRows: { type: 'integer' },
        deliveryOrderMode: { type: 'string' },
        useVirtualPrinter: { type: 'string' },
        useVirtualKeyboard: { type: 'boolean' },
        googleMapApiKey: { type: 'string' },
        onlyCheckoutPrintedItems: { type: 'boolean' },
        showTutorial: { type: 'boolean' },
        frontendLanguage: { type: 'string' },
        isProductPriceIncludingTax: { type: 'string' },
        taxType: { type: 'string' },
        useTimeClock: { type: 'boolean' },
        courseDuration: { type: 'integer' },
        logUserActions: { type: 'string' },
        automaticCashDrawer: { type: 'boolean' },
        tseEnable: { type: 'boolean' },
        quebecSrmEnable: { type: 'boolean' },
        defaultTips: { type: 'array', items: { type: 'number' } },
        serviceCharges: { type: 'array', items: { type: 'number' } },
        hasTablePlan: { type: 'boolean' },
        runInventory: { type: 'boolean' },
        isDisplayPasscodeTutorial: { type: 'boolean' },
        autoLogOutWhenCloseApp: { type: 'boolean' },
        autoLogOutAfterInactivity: { type: 'boolean' },
        autoLogOutPeriod: { type: 'number' },
        resetMaxIdPerDay: { type: 'boolean' },
        serviceFeeAsItem: { type: 'boolean' },
        decimalPointExpressed: { type: 'number' },
        cashbookEnable: { type: 'boolean' },
        multipleDineInMenusEnable: { type: 'boolean' },
        multipleDineInMenus: <JsonSchema<GeneralSetting['multipleDineInMenus']>>{
          type: 'array',
          items: {
            type: 'object',
            properties: {
              _id: { type: 'string', maxLength: 24 },
              name: { type: 'string' },
              active: { type: 'boolean' },
              isTakeAway: { type: 'boolean' },
              isPrintKitchen: { type: 'boolean' },
            },
          },
        },
        mergeSimilarItem: { type: 'boolean' },
        monitoring: { type: 'boolean' },
        useMenuV2: { type: 'boolean' },
        allowMultiplePayments: { type: 'boolean' },
        addCustomerAfterOrder: { type: 'boolean' },
        showPopupPrintError: { type: 'boolean' }
      },
    },
    posDatabase: <JsonSchema<PosSetting['posDatabase']>>{
      type: 'object',
      properties: {
        _id: { type: 'string', maxLength: 24 },
        id: { type: 'number' },
        name: { type: 'string' },
      },
    },
    hardwares: <JsonSchema<PosSetting['hardwares']>>{
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          id: { type: 'string', maxLength: 24 },
        },
      },
    },
    keyboardConfig: <JsonSchema<KeyboardConfig>>{
      type: 'object',
      properties: {
        active: { type: 'boolean' },
        onlyShowInFirstPage: { type: 'boolean' },
        x: { type: 'boolean' },
        top: { type: 'integer' },
        left: { type: 'integer' },
        width: { type: 'integer' },
        height: { type: 'integer' },
        // fixme
        rows: {
          type: 'array',
          items: {
            type: 'array',
            items: { type: 'string' },
          },
        },
      },
    },
    // fixme
    // call: {
    //   type: "object",
    //   properties: {
    //     ipAddresses: { type: "object", properties: { } }
    //   }
    // },
    companyInfo: <JsonSchema<CompanyInfo>>{
      type: 'object',
      properties: {
        name: { type: 'string' },
        address: { type: 'string' },
        address2: { type: 'string' },
        zipCode: { type: 'string' },
        city: { type: 'string' },
        ustId: { type: 'string' },
        country: { type: 'string' },
        backendLanguage: { type: 'string' },
        telephone: { type: 'string' },
        taxNumber: { type: 'string' },
        taxLabel: { type: 'string' },
        logo: { type: 'string' },
        logoSize: { type: 'number' },
        footerText: { type: 'string' },
        redInvoiceContent: { type: 'string' },
        province: { type: 'string' },
        owner: { type: 'string' },
        email: { type: 'string' },
      },
    },
    posFeature: <JsonSchema<PosFeature>>{
      type: 'object',
      properties: {
        showPendingOrder: { type: 'boolean' },
        showNewDelivery: { type: 'boolean' },
        showOnlineMenu: { type: 'boolean' },
        showReservation: { type: 'boolean' },
        showCustomerInfo: { type: 'boolean' },
      }
    },
    // fixme
    // keyboardDeliveryConfig: {
    //   type: "array",
    //   items: { }
    // },
    // onlineDevice: {
    //   type: 'object',
    //   properties: {
    //     services: {type: "object", properties: { }},
    //     store: {type: "object", properties: { }},
    //   }
    // },
    // onlineOrderSoundSetting: { type: "object", properties: { } },
    printerGeneralSetting: <JsonSchema<PrinterGeneralSetting>>{
      type: 'object',
      properties: {
        displayLumpSum: { type: 'boolean' },
        printPickUpNumber: { type: 'boolean' },
        printPickUpTicket: { type: 'boolean' },
        autoPrintInvoiceBeforePaying: { type: 'boolean' },
        printOverMasterDevice: { type: 'boolean' },
        takeAwayLabel: { type: 'string' },
      },
    },
    // fixme
    // reservation: {
    //   type: "object",
    //   properties: {
    //     openHours: { type: "array", items: { }},
    //     seatLimit: { type: "array", items: { }},
    //   }
    // },
    // signInRequest: { type: "object", properties: { }},
    customerLedDisplay: <JsonSchema<PosSetting['customerLedDisplay']>>{
      type: 'object',
      properties: {
        interface: { type: 'string' },
        device: { type: 'string' },
      },
    },
    hardwareID: { type: 'string' },
    divArticleSettings: <JsonSchema<PosSetting['divArticleSettings']>>{
      type: 'object',
      properties: {
        drink: {
          type: 'object',
          properties: {
            tax: { type: 'integer' },
            tax2: { type: 'integer' },
            groupPrinter: { type: 'string' },
            groupPrinter2: { type: 'string' },
          },
        },
        food: {
          type: 'object',
          properties: {
            groupPrinter: { type: 'string' },
            groupPrinter2: { type: 'string' },
            tax: { type: 'integer' },
            tax2: { type: 'integer' },
          },
        },
      },
    },
    reportSettings: <JsonSchema<PosSetting['reportSettings']>>{
      type: 'object',
      properties: {
        showProductSold: { type: 'boolean' },
        showMoreInfo: { type: 'boolean' },
      },
    },
    reportSetting: <JsonSchema<PosSetting['reportSetting']>>{
      type: 'object',
      properties: {
        receiversEmail: { type: 'string' },
        biweekly: { type: 'string' },
        daily: { type: 'string' },
        weekly: { type: 'string' },
        monthly: { type: 'string' },
      },
    },
    masterDevice: { type: 'string' },
    masterIp: { type: 'string' },
    defaultTakeaway: { type: 'boolean' },
    server: { type: 'string' },
    srm: <JsonSchema<PosSrmSetting>>{
      type: 'object',
      properties: {
        productId: { type: 'string' },
        productVersionId: { type: 'string' },
        partnerId: { type: 'string' },
        certificateCode: { type: 'string' },
        authCode: { type: 'string' },
        billingNumber: { type: 'string' },
        timezone: { type: 'string' },
        timezoneZ: { type: 'string' },
        gstNumber: { type: 'string' },
        qstNumber: { type: 'string' },
        version: { type: 'string' },
        previousVersion: { type: 'string' },
        testcaseNumber: { type: 'string' },
        env: { type: 'string' },
      },
    },
    printerCache: <JsonSchema<PrinterCache>>{
      type: 'object',
      properties: {
        header0: { type: 'string' },
        header0_md5: { type: 'string' },
        header1: { type: 'string' },
        header1_md5: { type: 'string' },
        payment: { type: 'string' },
        payment_md5: { type: 'string' },
        qrCode: { type: 'string' },
        qrCode_md5: { type: 'string' },
        footerText: { type: 'string' },
        footerText_md5: { type: 'string' },
        tseSerialNumber: { type: 'string' },
        tseSerialNumber_md5: { type: 'string' },
        tseTimeFormat: { type: 'string' },
        tseTimeFormat_md5: { type: 'string' },
        tseSignatureAlgorithm: { type: 'string' },
        tseSignatureAlgorithm_md5: { type: 'string' },
        tsePublicKey: { type: 'string' },
        tsePublicKey_md5: { type: 'string' },
        tseUserName: { type: 'string' },
        tseUserName_md5: { type: 'string' },
      },
    },
    replicateServer: <JsonSchema<PosSetting['replicateServer']>>{
      type: 'object',
      properties: {
        username: { type: 'string' },
        password: { type: 'string' },
      },
    },
    useSingleOnlineMenu: { type: 'boolean' },
    menuScrollbar: { type: 'boolean' },
    cashbook: {
      type: 'object',
      properties: {
        incomingTypes: { type: 'array' },
        outgoingTypes: { type: 'array' },
      },
    },
  },
}

export const PosSetting = {} as RxCollection<PosSetting>

window.PosSetting = PosSetting

export const createPosSettingCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: PosSetting,
    collectionName: POS_SETTING_COLLECTION_NAME,
    version: VERSION,
    schema: PosSettingSchema,
    extraStrategy: {
      55: doc => {
        if (doc.printerGeneralSetting) {
          doc.printerGeneralSetting.takeAwayLabel = 'icon'
        }
        return doc
      },
      56: doc => {
        if (doc.generalSetting) {
          doc.generalSetting.hideManualTable = false
          doc.generalSetting.hideFastCheckout = false
        }
        return doc
      },
      58: doc => {
        if (doc.generalSetting?.multipleDineInMenus) {
          doc.generalSetting?.multipleDineInMenus?.forEach(item => {
            item.isPrintKitchen = true
          })
        }
        return doc
      },
      59: doc => {
        if (doc.generalSetting) {
          doc.generalSetting.showPopupPrintError = true
        }
        return doc
      },
    }
  })
}
