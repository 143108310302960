import debug from 'debug'
import _ from 'lodash'
import type { RxCollection, RxDocumentData } from 'rxdb'
import { debounceTime, filter, groupBy, mergeMap, tap } from 'rxjs'
import uuid from 'time-uuid'

import { getDeviceId } from '@/shared/getDeviceId'

import type { Product } from './Product'
import { ProductSync } from './ProductSync'

const log = debug('data:product-sync-hook')

export function registerSyncHook(collection: RxCollection<Product>) {
  collection.$.pipe(
    filter(e => e.operation === 'UPDATE'),
    groupBy(e => e.documentId),
    mergeMap(g =>
      g.pipe(
        debounceTime(500), // Debounce for each documentId
        tap(e => checkAndSync(e.documentId, e.documentData, collection))
      )
    )
  ).subscribe()
}

const SYNCING_BLACKLISTED_FIELDS: Array<keyof RxDocumentData<Product>> = [
  '_id',
  '_deleted',
  '_attachments',
  '_rev',
  '_meta',
  'syncingHash', // This is for checking sync session
  'menus',
  'categories',
  'position',
]

type SyncingSession = Map<string, boolean> // targetId -> isSynced

const sessions = new Map<string, SyncingSession>()
const scheduleForDelete = (ms: number, key: string) => setTimeout(() => sessions.delete(key), ms)

async function checkAndSync(id: string, data: RxDocumentData<Product>, collection: RxCollection<Product>) {
  // log('⚡️ Checking sync config for', id)
  const syncConfigs = await ProductSync.find({ selector: { sync_source: id, deviceId: getDeviceId() } }).exec()
  if (!syncConfigs.length) return // log('ℹ️ No sync config found!')

  log(`⚡️ Syncing "${id}" to ${syncConfigs.length} targets...`)

  const [key, session] = getSession(data.syncingHash)
  session.set(id, true)

  const deviceId = getDeviceId()
  for (const s of syncConfigs) {
    if (s.deviceId !== deviceId) {
      // log('ℹ️ Sync config not for this device, skipping...', s)
      continue
    }
    if (session.has(s.sync_target)) {
      log(`ℹ️ Target "${s.sync_target}" already in this syncing session, skipping...`)
      continue
    }
    session.set(s.sync_target, false)

    const target = await collection.findOne({ selector: { _id: s.sync_target } }).exec()
    if (!target) {
      log('ℹ️ Sync target not found! Removing sync config...')
      await s.remove()
      continue
    }

    const dataToSync = { ..._.omit(data, SYNCING_BLACKLISTED_FIELDS), syncingHash: key }
    log(`⚡️ Syncing ${id} » ${s.sync_target}...`)
    await target.incrementalUpdate({ $set: dataToSync })
  }

  if ([...session.values()].every(a => a === true)) {
    log('⚡️ All items synced, terminating session...', key)
    // We can't remove session right away since some data will come a bit late due to the "debounce"
    scheduleForDelete(3000, key)
  } else {
    // log(`⚡️ Saving session...`, session)
    sessions.set(key, session)
  }
}

function getSession(previousKey: string | undefined) {
  let key: string
  let session: SyncingSession

  if (previousKey && sessions.has(previousKey)) {
    key = previousKey!
    session = sessions.get(key)!
    // log('⚡️ Resuming session', key, session)
  } else {
    key = uuid()
    session = new Map<string, boolean>()
    // log('⚡️ Starting new session', key)
  }
  return [key, session] as const
}
