import { toast } from 'react-toastify'

import { posSetting0 } from '@/data/PosSettingsSignal'
import { computed, type Accessor } from '@/react/core/reactive'
import { REGISTERED_VALUES } from '@/srm/lib/constants'

import { testRunner001 } from './001'
import { testRunner002 } from './002'
import { testRunner003 } from './003'
import { testRunner004 } from './004'
import { testRunner005 } from './005'
import { testRunner006 } from './006'
import { testRunner007 } from './007'
import { testRunner008 } from './008'
import { testRunner009 } from './009'
import { testRunner010 } from './010'
import { testRunner011 } from './011'
import { testRunner019 } from './019'
import { testRunner020 } from './020'
import { testRunner021 } from './021'
import { testRunner022 } from './022'
import { testRunner024 } from './024'
import { testRunner026 } from './026'
import { testRunner029 } from './029'
import { testRunner030 } from './030'
import { testRunner031 } from './031'
import { testRunner036 } from './036'
import { testRunner037 } from './037'
import { testRunner103 } from './103'
import { REQUIRED_TESTCASES } from './constants'
import { nextTestcase, setLastSucceededTestcase, setNextTestcase } from './state'

const activeVersion: Accessor<string | undefined> = computed(() => Object.entries(REGISTERED_VALUES).find(([_, v]) => v.IDVERSI === posSetting0()?.srm?.productVersionId)?.[0])
const requiredTestcases: Accessor<Record<string, string[]> | undefined> = computed(() => REQUIRED_TESTCASES[activeVersion() as keyof typeof REGISTERED_VALUES])
const flattenedRequiredTestcases = computed(() =>
  Object.entries(requiredTestcases() ?? {})
    .sort()
    .flatMap(([num, steps]) => steps.map(step => [num, step].map(i => i.toString().padStart(3, '0')).join('.')))
)
export const runProgress = computed(() => {
  const cases = flattenedRequiredTestcases()
  const passed = cases.indexOf(nextTestcase()) + 1
  const total = cases.length
  return `${passed}/${total}`
})

const runnerMap: Record<string, Record<string, () => Promise<boolean>>> = {
  '001': testRunner001,
  '002': testRunner002,
  '003': testRunner003,
  '004': testRunner004,
  '005': testRunner005,
  '006': testRunner006,
  '007': testRunner007,
  '008': testRunner008,
  '009': testRunner009,
  '010': testRunner010,
  '011': testRunner011,
  '019': testRunner019,
  '020': testRunner020,
  '021': testRunner021,
  '022': testRunner022,
  '024': testRunner024,
  '026': testRunner026,
  '029': testRunner029,
  '030': testRunner030,
  '031': testRunner031,
  '036': testRunner036,
  '037': testRunner037,
  '103': testRunner103,
}

export async function execAllTestcase() {
  const cases = Object.entries(requiredTestcases() ?? {}).sort()
  for (const [num, steps] of cases)
    for (const step of steps) {
      const testcase = [num, step].map(i => i.toString().padStart(3, '0')).join('.')
      if (testcase !== nextTestcase()) continue
      const fn = runnerMap[num]?.[testcase]
      if (!fn) return toast.warn(`Testcase ${testcase} not implemented!`)
      if ((await fn()) === false) return toast.error(`Testcase ${testcase} failed!`)
    }

  toast.success('All testcases passed!', { autoClose: false })
}

export async function resetExecTestcase() {
  setLastSucceededTestcase('')
  setNextTestcase('001.001')

  const cases = Object.entries(requiredTestcases() ?? {}).sort()
  for (const [num] of cases) await runnerMap[num]?.cleanup?.()
}
