import { useRef } from 'react'
import type { Subscription } from 'rxjs'

import { dataLock } from '@/data/DataUtils'
import { masterActionLogic } from '@/pos/logic/master-actions'
import { useSignalEffect } from '@/react/core/reactive'
import { isMaster } from "@/lib/master-signal.ts";

export function useMasterActionProcessQueue() {
  const sub = useRef<Subscription>()
  useSignalEffect(() => {
    if (!isMaster()) return
    dataLock.acquireAsync().then(() => {
      sub.current?.unsubscribe()
      sub.current = masterActionLogic.registerQueue()
    })
    return () => sub.current?.unsubscribe()
  })
}
