import {signal} from "@/react/core/reactive.ts";
import type {Order} from "@/data/Order.ts";
import {createOrder} from "@/pos/logic/order-reactive.ts";

export const [order0, setOrder0] = signal<Order>(createOrder());
// @ts-ignore
window.order0 = order0;

export enum OrderMode {
  TABLE,
  FAST_CHECKOUT
}

export enum OrderScreenCartMode {
  LIST_ITEM_ONLY = 0,
  ADD_SEAT_CART = 1,
  SPLIT_BILL = 2,
  EMPTY_CART = 3
}

export enum DiscountPopupMode {
  EDIT_ITEM = 0,
  MODIFIER = 1,
  DISCOUNT = 2,
  MOVE_SEAT = 3,
  SPLIT_ITEM = 4
}

export const [isOrderViewCart, setIsOrderViewCart] = signal<boolean>(false);
export const [orderScreenCartMode, setOrderScreenCartMode] = signal<OrderScreenCartMode>(OrderScreenCartMode.LIST_ITEM_ONLY);
export const [discountPopupMode, setDiscountPopupMode] = signal<DiscountPopupMode>(DiscountPopupMode.EDIT_ITEM)
