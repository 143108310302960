import React, { type CSSProperties, type FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { FormControlLabel, Switch } from '@mui/material'
import {
  handleAddSeat, isShowPrice, isShowToolbar,
  onGroupBill,
  onMergeSeats,
  onToggleTakeOut, setIsShowPrice, setIsShowToolbar,
  setVoucherStatus
} from '@/react/OrderView/OrderView.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import PortalPopup from '@order-view/PortalPopup.tsx'
import DiscountPopupItem from '@order-view/DiscountPopup/DiscountPopupItem.tsx'
import OrderScreenCustomDishPo from '@order-view/OrderScreenCustomDishPo.tsx'
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { printInvoice } from '@/react/Printer/print-invoice.ts'
import { InvoiceTypes } from '@/pos/OrderType.ts'
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx'
import OrderScreenFunctionsPopup from '@order-view/OrderScreenFunctionsPopup.tsx'
import { shouldWarnBecauseOffline } from "@/data/ReplicateEffect.ts";
import { toast } from "react-toastify";
import { openCashDrawer } from "@/pos/cashdrawer.ts";
import { isFeatureEnabled, PosFeatures } from '@/srm/features.logic'
import { clsx } from "clsx";
import _ from "lodash";
import SplitBillEquallyPopup from "@order-view/SplitBillEquallyPopup.tsx";
import { signal } from "@/react/core/reactive.ts";
import { checkItemBeforeSplit, removeDiscountBeforeSplit } from "@/react/OrderView/order-splitbill-utils.ts";
import { generalSetting0, isQuebecSrmEnabled, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { dialogConfirmMergeSeat } from "@order-view/Popups/dialogConfirmMergeSeat.tsx";
import ConfirmMergeTablePopUp, {
  isOpenMergeSeatPopUp,
  setIsOpenMergeSeatPopup
} from "@order-view/Popups/ConfirmMergeTablePopUp.tsx";
import ConfirmMergeSeatBeforeMove from "@order-view/Popups/ConfirmMergeSeatBeforeMove.tsx";
import PrintBillForSeats from "@order-view/PrintBillForSeats.tsx";
import PortalDrawer from "@edit-menu/Popups/PortalDrawer.tsx";
import EditMenuEditScreen from "@vertical-edit-menu/EditMenuEditScreen.tsx";
import {
  DiscountPopupMode,
  OrderScreenCartMode,
  setDiscountPopupMode,
  setIsOrderViewCart,
  setOrderScreenCartMode
} from "@/react/OrderView/OrderViewShare.ts";
import { setDiscountPopupVertical } from "@/react/VerticalOrderView/VerticalOrderView.tsx";
import { setIsOpenVoucherPopup } from './OrderScreenPlugin2.tsx'
import PrintBeforeSplitPopup from "@vertical-order-view/Popups/PrintBeforeSplitPopup.tsx";
import { checkMasterAvailable } from "@/data/utils/master-available.ts";


type OrderScreenBurgerMenuType = {
  isExpanded?: boolean;
  isSelected?: boolean;
  showButtonText?: boolean;

  /** Style props */
  iconTooltipArrowUpIconWidth?: CSSProperties['width'];
  iconTooltipArrowUpIconHeight?: CSSProperties['height'];
  buttonCursor?: CSSProperties['cursor'];
  buttonCursor1?: CSSProperties['cursor'];
  buttonCursor2?: CSSProperties['cursor'];
};

export const [isPrintKitchenPopUpOpen, setPrintKitchenPopUpOpen] = signal<boolean>(false);

export const [isSplitBillEquallyPopupOpen, setSplitBillEquallyPopupOpen] =
  signal<boolean>(false);

export const [isConfirmMergeTablePopupOpen, setIsConfirmMergeTablePopupOpen] = signal<boolean>(false);


const OrderScreenBurgerMenu: FunctionComponent<OrderScreenBurgerMenuType> = (
  {
    iconTooltipArrowUpIconWidth,
    iconTooltipArrowUpIconHeight
  }) => {
  const { order0, splitEnable, onSeatToggle, onMoveToggle, onSplitCancel } = useContext(ItemFactoryContext)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const closeMenu = () => setIsExpanded(false)
  const [isPrintBillForSeatsOpen, setPrintBillForSeatsOpen] = useState(false);
  const [isOrderScreenFunctionsPopupOpen, setOrderScreenFunctionsPopupOpen] =
    useState(false)
  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false)
  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false)
  const [isOrderScreenCustomDishPoOpen, setOrderScreenCustomDishPoOpen] =
    useState(false)
  const [isEditScreenOpen, setEditScreenOpen] =
    useState(false);
  const openEditScreenPopup = useCallback(() => {
    closeMenu()
    setEditScreenOpen(true);
  }, []);

  const closeEditScreenPopup = useCallback(() => {
    setEditScreenOpen(false);
  }, []);
  const openOrderScreenFunctionsPopup = useCallback(() => {
    setOrderScreenFunctionsPopupOpen(true);
  }, []);

  const closeOrderScreenFunctionsPopup = useCallback(() => {
    setOrderScreenFunctionsPopupOpen(false);
  }, []);
  const openDiscountPopup = useCallback(() => {
    setDiscountPopupVertical(0)
    setDiscountPopupMode(DiscountPopupMode.DISCOUNT)
    closeMenu()
  }, [])
  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(false)
  }, [])

  const openVoucherPopup = useCallback(() => {
    console.log('open')
    setVoucherPopupOpen(true)
    closeMenu()
  }, [])

  const closeVoucherPopup = useCallback(() => {
    setVoucherStatus("")
    setVoucherPopupOpen(false)
  }, [])

  const openOrderScreenCustomDishPo = useCallback(() => {
    setOrderScreenCustomDishPoOpen(true)
    closeMenu()
  }, [])

  const closeOrderScreenCustomDishPo = useCallback(() => {
    setOrderScreenCustomDishPoOpen(false)
  }, [])

  const openPrintBillForSeats = useCallback(() => {
    setPrintBillForSeatsOpen(true);
  }, []);

  const closePrintBillForSeats = useCallback(() => {
    setPrintBillForSeatsOpen(false);
  }, []);
  const openSplitBillEquallyPopup = useCallback(() => {
    setSplitBillEquallyPopupOpen(true);
  }, []);
  const closeSplitBillEquallyPopup = useCallback(() => {
    setSplitBillEquallyPopupOpen(false);
  }, []);
  const iconTooltipArrowUpIconStyle: CSSProperties = useMemo(() => {
    return {
      width: iconTooltipArrowUpIconWidth,
      height: iconTooltipArrowUpIconHeight
    }
  }, [iconTooltipArrowUpIconWidth, iconTooltipArrowUpIconHeight])

  return (
    <>
      {isExpanded &&
        <div className="z-[99] w-full h-full fixed top-0 left-0 opacity-0"
             onClick={() => {
               closeMenu()
             }}
        />
      }
      <div
        className="shrink-0 order-button-more flex flex-col items-center justify-start gap-[8px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
        {!isExpanded &&
          <img
            className="w-10 relative h-10 object-cover Tablet_768:w-[34px] Tablet_768:h-[34px] Tablet_600:w-[34px] Tablet_600:h-[34px] Mobile_480:w-[34px] Mobile_480:h-[34px] height_414:w-[34px] height_414:h-[34px]"
            alt=""
            src={(!order0?.().takeAway) ? '/iconburger-menu-icon@2x.png' : '/iconorderscreentakeaway@2x.png'}
            onClick={() => {
              setIsExpanded(prev => !prev)
            }}
          />
        }
        {isExpanded && (
          <div className="flex flex-col items-start justify-start relative z-[99]">
            <img
              onClick={closeMenu}
              className="w-10 relative h-10 object-cover opacity-[0.5] Tablet_768:w-[34px] Tablet_768:h-[34px] Tablet_600:w-[34px] Tablet_600:h-[34px] Mobile_480:w-[34px] Mobile_480:h-[34px] height_414:w-[34px] height_414:h-[34px]"
              alt=""
              src="/iconorderburger-menu-expanded-icon-ver1@2x.png"
            />
            <div
              className={clsx("!m-[0] absolute top-full right-[10px] flex flex-col items-center justify-start z-[60] height_414:h-60",
              )}
            >
              <div className={clsx("self-stretch items-end flex flex-col justify-start",
              )}>
                <img
                  className="w-2.5 relative h-[9px] object-cover"
                  alt=""
                  src="/icontooltip-arrow-up-icon@2x.png"
                  style={iconTooltipArrowUpIconStyle}
                />
              </div>
              <div
                className="Mobile_480:w-[200px] max-h-[400px] no-scrollbar w-[404px] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-aliceblue-100 overflow-y-auto flex flex-row flex-wrap items-start justify-start py-2.5 px-2 box-border gap-[10px]">
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  ref={makeRipple}
                  onClick={async () => {
                    closeMenu()
                    if (checkMasterAvailable()) return;
                    // if (shouldWarnBecauseOffline()) return toast.error('Master is not available', { autoClose: 2000 })
                    const _order = order0?.()
                    if (!_order) return
                    if (!_order.isGroupBill && _order.seatMap?.length) {
                      openPrintBillForSeats()
                    } else {
                      await printInvoice(_order, InvoiceTypes.GUEST_CHECK)
                    }
                  }}
                >
                  <div
                    className="!hidden w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/iconorderunpaid-bill-icon@2x.png"
                  />
                  <b className="!flex flex-1 relative leading-[17px] hidden z-[2]">
                    {LL0().order.invoiceTypes.guestCheck()}
                  </b>
                  <b className="!hidden flex-1 relative leading-[17px] text-white z-[3]">
                    {LL0().order.invoiceTypes.guestCheck()}
                  </b>
                </div>
                {getAccessibility(AccessPort.OPEN_CASH_DRAWER_ANY_TIME) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    ref={makeRipple}
                    onClick={() => {
                      openCashDrawer()
                      closeMenu()
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/orderdrawer-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.openCashDrawer()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.openCashDrawer()}
                    </b>
                  </div>}
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    onToggleTakeOut()
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  {order0?.().takeAway &&
                    <div
                      className="!flex w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />}
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/ordertake-out-icon@2x.png"
                  />
                  {order0?.().takeAway ?
                    <b className="flex-1 relative leading-[17px] text-white z-[3]">
                      {LL0().order.takeAway()}
                    </b>
                    :
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.takeAway()}
                    </b>
                  }
                </div>
                {/*<div*/}
                {/*  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-300-3faaff"*/}
                {/*  ref={makeRipple}*/}
                {/*  onClick={openEditMenuChangeMenuPopup}*/}
                {/*>*/}
                {/*  <div*/}
                {/*    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />*/}
                {/*  <img*/}
                {/*    className="w-[30px] relative h-[30px] object-cover z-[1]"*/}
                {/*    alt=""*/}
                {/*    src="/iconsearch-blue@2x.png"*/}
                {/*  />*/}
                {/*  <b className="flex-1 relative leading-[17px] z-[2]">*/}
                {/*    {LL0().order.search()}*/}
                {/*  </b>*/}
                {/*  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">*/}
                {/*    {LL0().order.search()}*/}
                {/*  </b>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-300-3faaff"*/}
                {/*  ref={makeRipple}*/}
                {/*  onClick={openEditMenuChangeMenuPopup}*/}
                {/*>*/}
                {/*  <div*/}
                {/*    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />*/}
                {/*  <img*/}
                {/*    className="w-[30px] relative h-[30px] object-cover z-[1]"*/}
                {/*    alt=""*/}
                {/*    src="/order-change-menu-price-icon2@2x.png"*/}
                {/*  />*/}
                {/*  <b className="flex-1 relative leading-[17px] z-[2]">*/}
                {/*    {LL0().order.changeMenuPrice()}*/}
                {/*  </b>*/}
                {/*  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">*/}
                {/*    {LL0().order.changeMenuPrice()}*/}
                {/*  </b>*/}
                {/*</div>*/}
                {isFeatureEnabled(PosFeatures.SplitBill) && (order0?.().items.length === 0 || (!splitEnable?.() && order0?.().seatMode) ?
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    ref={makeRipple}
                    onClick={() => {
                      handleAddSeat()
                      closeMenu()
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/iconaddseat-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.addSeat()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.addSeat()}
                    </b>
                  </div>
                  :
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      closeMenu()
                      if (checkItemBeforeSplit(order0?.()!)) {
                        removeDiscountBeforeSplit(order0?.()!)
                        onSeatToggle?.()
                        setIsOrderViewCart(true)
                      } else {
                        setPrintKitchenPopUpOpen(true)
                      }
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/ordersplit-bill-icon1@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.splitOrder()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.splitOrder()}
                    </b>
                  </div>)}
                {/*{isFeatureEnabled(PosFeatures.SplitBillVertical) && order0?.().seatMode &&*/}
                {/*  <div*/}
                {/*    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"*/}
                {/*    onClick={() => {*/}
                {/*      closeMenu()*/}
                {/*      if (checkItemBeforeSplit(order0?.())) {*/}
                {/*        removeDiscountBeforeSplit(order0?.())*/}
                {/*        onSeatToggle?.()*/}
                {/*      } else {*/}
                {/*        setPrintKitchenPopUpOpen(true)*/}
                {/*      }*/}
                {/*    }}*/}
                {/*    ref={makeRipple}*/}
                {/*  >*/}
                {/*    <div*/}
                {/*      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />*/}
                {/*    <img*/}
                {/*      className="w-[30px] relative h-[30px] object-cover z-[1]"*/}
                {/*      alt=""*/}
                {/*      src="/ordersplit-bill-icon1@2x.png"*/}
                {/*    />*/}
                {/*    <b className="flex-1 relative leading-[17px] z-[2]">*/}
                {/*      {LL0().order.reDivision()}*/}
                {/*    </b>*/}
                {/*    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">*/}
                {/*      {LL0().order.reDivision()}*/}
                {/*    </b>*/}
                {/*  </div>*/}
                {/*}*/}
                {isFeatureEnabled(PosFeatures.SplitBill) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 border-blue-solid-blue-300-3faaff border-[1px] border-solid box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-1 cursor-pointer"
                    onClick={() => {
                      openSplitBillEquallyPopup()
                      closeMenu()
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/order-equally-split-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.equalSplit()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white-solid-white-100-ffffff z-[3]">
                      {LL0().order.equalSplit()}
                    </b>
                  </div>
                }
                {isFeatureEnabled(PosFeatures.MoveTable) /*&& !order0?.().seatMode*/ && order0?.().payable && generalSetting0()?.hasTablePlan &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    onClick={() => {
                      setIsConfirmMergeTablePopupOpen(true)
                      // onMoveToggle?.()
                      closeMenu()
                    }}
                    ref={makeRipple}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/ordermove-table-icon@2x.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.moveTable()}
                    </b>
                    <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                      {LL0().order.moveTable()}
                    </b>
                  </div>
                }
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    openDiscountPopup()
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/orderdiscount-icon@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.discount()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.discount()}
                  </b>
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    setIsOpenVoucherPopup(true)
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/ordervoucher-icon2@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.voucher()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.voucher()}
                  </b>
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  onClick={() => {
                    openOrderScreenCustomDishPo()
                    closeMenu()
                  }}
                  ref={makeRipple}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/ordercustom-dish-icon@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.customDish()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.customDish()}
                  </b>
                </div>
                <div
                  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                  ref={makeRipple}
                  onClick={() => {
                    openEditScreenPopup()
                  }}
                >
                  <div
                    className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                  <img
                    className="w-[30px] relative h-[30px] object-cover z-[1]"
                    alt=""
                    src="/orderedit-screen-icon1@2x.png"
                  />
                  <b className="flex-1 relative leading-[17px] z-[2]">
                    {LL0().order.editScreen()}
                  </b>
                  <b className="w-[69px] relative leading-[17px] hidden text-white z-[3]">
                    {LL0().order.editScreen()}
                  </b>
                </div>
                {(isQuebecSrmEnabled() && order0?.()?.seatMode) &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                    <div className="w-9 relative h-[18px]">
                      <FormControlLabel
                        label={''}
                        className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                        control={<Switch color="primary" size="small"
                                         checked={order0?.()?.isGroupBill || false}
                                         onChange={(e) => {
                                           if (order0)
                                             onGroupBill(order0(), e.target.checked)
                                         }} />}
                      />
                    </div>
                    <b className="flex-1 relative leading-[17px]">
                      {LL0().order.groupBills()}
                    </b>
                  </div>}
                {order0?.()?.seatMode && !splitEnable?.() &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                    ref={makeRipple}
                    onClick={async () => {
                      if (splitEnable?.()) {
                        toast.warning('Need complete split bill first!')
                      }
                      await dialogConfirmMergeSeat(() => {
                        if (onSplitCancel && order0) {
                          onSplitCancel(false)
                          onMergeSeats(order0())
                          setOrderScreenCartMode(OrderScreenCartMode.LIST_ITEM_ONLY)
                          closeMenu()
                        }
                      })
                    }}
                  >
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]" />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/icon-merge.png"
                    />
                    <b className="flex-1 relative leading-[17px] z-[2]">
                      {LL0().order.mergeSeats()}
                    </b>
                  </div>}
                {!order0?.().table &&
                  <div
                    className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">
                    <div className="w-9 relative h-[18px]">
                      <FormControlLabel
                        label={''}
                        className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                        control={<Switch color="primary" size="small"
                                         checked={posSetting0()?.defaultTakeaway ?? false}
                                         onChange={(event) => {
                                           _.assign(posSetting0(), { defaultTakeaway: event.target.checked })
                                           if (order0) _.assign(order0(), { takeAway: event.target.checked })
                                         }}
                        />}
                      />
                    </div>
                    <b className="flex-1 relative leading-[17px]">
                      {LL0().order.defaultTakeaway()}
                    </b>
                  </div>}
                {/*<div*/}
                {/*  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">*/}
                {/*  <div className="w-9 relative h-[18px]">*/}
                {/*    <FormControlLabel*/}
                {/*      label={''}*/}
                {/*      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"*/}
                {/*      control={<Switch color="primary" size="small"*/}
                {/*                       checked={isShowToolbar() || false}*/}
                {/*                       onChange={e => setIsShowToolbar(e.target.checked)}*/}
                {/*      />}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <b className="flex-1 relative leading-[17px]">*/}
                {/*    {LL0().order.showToolbar()}*/}
                {/*  </b>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  className="h-[34px] w-[188px] rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">*/}
                {/*  <div className="w-9 relative h-[18px]">*/}
                {/*    <FormControlLabel*/}
                {/*      label={''}*/}
                {/*      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"*/}
                {/*      control={<Switch color="primary" size="small"*/}
                {/*                       checked={isShowPrice() || false}*/}
                {/*                       onChange={e => setIsShowPrice(e.target.checked)}*/}
                {/*      />}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <b className="flex-1 relative leading-[17px]">*/}
                {/*    {LL0().order.showTotal()}*/}
                {/*  </b>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        )}
      </div>

      {isDiscountPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopupItem onClose={closeDiscountPopup} />
        </PortalPopup>
      )}

      {isOrderScreenCustomDishPoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOrderScreenCustomDishPo}
        >
          <OrderScreenCustomDishPo onClose={closeOrderScreenCustomDishPo} />
        </PortalPopup>
      )}
      {isOrderScreenFunctionsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderScreenFunctionsPopup}
        >
          <OrderScreenFunctionsPopup onClose={closeOrderScreenFunctionsPopup} />
        </PortalPopup>
      )}
      {isSplitBillEquallyPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeSplitBillEquallyPopup}
        >
          <SplitBillEquallyPopup onClose={closeSplitBillEquallyPopup} />
        </PortalPopup>
      )}
      {isPrintBillForSeatsOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePrintBillForSeats}
        >
          <PrintBillForSeats onClose={closePrintBillForSeats} />
        </PortalPopup>
      )}
      {isPrintKitchenPopUpOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setPrintKitchenPopUpOpen(false)}
        >
          <PrintBeforeSplitPopup onClose={() => setPrintKitchenPopUpOpen(false)} />
        </PortalPopup>
      )}
      {isConfirmMergeTablePopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsConfirmMergeTablePopupOpen(false)}
        >
          <ConfirmMergeTablePopUp onClose={() => setIsConfirmMergeTablePopupOpen(false)} />
        </PortalPopup>
      )}
      {isOpenMergeSeatPopUp() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsOpenMergeSeatPopup(false)}
        >
          <ConfirmMergeSeatBeforeMove onClose={() => setIsOpenMergeSeatPopup(false)} />
        </PortalPopup>
      )}
      {isEditScreenOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeEditScreenPopup}
        >
          <EditMenuEditScreen onClose={closeEditScreenPopup} />
        </PortalDrawer>
      )}
    </>
  )
}

export default OrderScreenBurgerMenu