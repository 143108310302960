import type {OrderItem} from "@/pos/OrderType.ts";
import {getReceiptsFromOrder, printKitchenReceipt} from "@/react/Printer/print-kitchen-utils.ts";
import _ from "lodash";
import {groupPrinters0} from "@/data/GroupPrinterHub.ts";
import uuid from "time-uuid";
import type {Order} from "@/data/Order.ts";
import { getMappedPrinter } from '@/react/Printer/print-utils.ts';
import dayjs from "dayjs";
import debug from "debug";
import type { ThermalPrinter } from "@/data/GroupPrinter.ts";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { PrintScripts } from "@/data/PrintScripts"
import { multipleMenus0 } from "@/react/EditMenuView/EditPricingLevel.logic.ts";

const log = debug('printer:print-kitchen');

let result: string;
const queues = {};
//todo: one instance -> reuse

const isPrintKitchenFollowMenu = (order: Order) => {
  if (!generalSetting0().multipleDineInMenusEnable) return true;
  if (!!order?.provider) return true;
  let isPrintKitchen;
  if (!order?.multiplePriceMenu) {
     isPrintKitchen = multipleMenus0()?.[0]?.isPrintKitchen;
  } else {
     isPrintKitchen = multipleMenus0()?.find(m => m._id === order?.multiplePriceMenu)?.isPrintKitchen;
  }
  return isPrintKitchen
}

const printedData: string[] = [];
//todo define Printer in ts
export const printKitchen = async (order: Order, printed = true, isReprint = false): Promise<void> => {
  log(`printKitchen `, { orderId: order._id })
  if (!isPrintKitchenFollowMenu(order)) return;
  if (printedData.indexOf(order._id) !== -1) return;
  printedData.push(order._id);
  setTimeout(() => printedData.splice(printedData.indexOf(order._id), 1), 3000)

  if (isReprint) {
    const items = order?.seatMode ? order?.seatMap?.flatMap(o => o.items)?.filter(i => i.quantity) : order.items?.filter(i => i.quantity);
    if (items && items?.length) {
      await printItems(items, false);
    }
  } else {
    const items = order.getRecent!(printed);
    await printItems(items, false);
    const cancellationItems = order.getCancellationRecent!(printed);
    await printItems(cancellationItems, true);
  }


  async function printItems(items: OrderItem[], cancel: boolean = false) {
    const groupReceipts = getReceiptsFromOrder(items);


//["{\"groupPrinter\":\"5e7864b186e3ec0997b5e48e\",\"course\":1}"]
    for (let [key, _items] of _.entries(groupReceipts)) {
      const {groupPrinter: _id, course} = JSON.parse(key);
      const groupPrinter = groupPrinters0().find(g => g._id === _id);
      if (!groupPrinter) return;
      const kitchenPrinter = getMappedPrinter(groupPrinter);
      if (!kitchenPrinter) return;
      if (kitchenPrinter.oneReceiptForOneArticle) {
        for (const item of _items) {
          if (item.course === course) {
            await processPrint(groupPrinter.name, kitchenPrinter, course, [item]);
          }
        }
      } else {
        await processPrint(groupPrinter.name, kitchenPrinter, course, _items);
      }
    }

    async function processPrint(groupPrinterName: string, kitchenPrinter: ThermalPrinter, course: number, _items: OrderItem[]) {
      const vPrinter = new VPrinter(kitchenPrinter)
      await printKitchenReceipt(groupPrinterName, kitchenPrinter, course, _items, order, cancel, false, vPrinter, isReprint);
      setTimeout(async () => {
        if (generalSetting0()?.useVirtualPrinter) {
          await PrintScripts.insert({
            _id: uuid(),
            date: dayjs().unix(),
            scripts: _.cloneDeep(vPrinter.scripts),
          })
        }
      }, 500);
      //todo: emit error
      // const printer = new EscPrinter(kitchenPrinter);
      // await printer.printRaster(raster);
      await vPrinter.print({cut: true, viaMaster: true});
    }
  }

  // return result;
}
