import { type FunctionComponent, useState } from "react";
import dayjs from "dayjs";
import { MarketPlaceProvider, OrderType, type TOrder } from "../../../../src/pos/OrderType";
import { useComputed, useSignal } from "@/react/core/reactive";
import InputKeyboardPopUp from '@/react/core/InputKeyboardPopUp.tsx';
import { isProviderSupportModifyOrderDate, predefinedTimes, allowCustomTimeDialog, type OnAcceptOption } from "@/react/PendingOrder/PendingOrderLogic.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { smartFormatShortDateTime } from "@/shared/dateTimeUtils.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import msgBox from "@/react/SystemService/msgBox";
import dialogService from "@/react/SystemService/dialogService.tsx";
import { TimeSelection } from "./TimeSelection";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import _ from "lodash";

export type OnAcceptOrder = (opt: OnAcceptOption) => void

type Props = {
  order: TOrder,
  onAccept: OnAcceptOrder,
  onDecline: Function
}

enum ActionButtonMode {
  Default,
  Decline,
  Accept
}


const ActionButtons: FunctionComponent<Props> = ({
  order,
  onAccept,
  onDecline
}) => {
  const LL = LL0()
  const cancelReasons = useComputed(() => [
    LL.onlineOrder.cancelReasons.itemIssue(),
    LL.onlineOrder.cancelReasons.kitchenClosed(),
    LL.onlineOrder.cancelReasons.customerCalledToCancel(),
    LL.onlineOrder.cancelReasons.restaurantTooBusy(),
    LL.onlineOrder.cancelReasons.storeClosed(),
    LL.onlineOrder.cancelReasons.capacity(),
    LL.onlineOrder.cancelReasons.address(),
    LL.onlineOrder.cancelReasons.specialInstructionsIssue(),
    LL.onlineOrder.cancelReasons.pricingIssue(),
  ])

  const [actionButtonMode, setActionButtonMode] = useState(ActionButtonMode.Default)
  const [declineReason, setDeclineReason] = useState('')

  const isPickUpOrder = order.type === OrderType.PickUp;
  const isInHouse = order.type === OrderType.InHouse;
  // TODO: correct the logic
  // - customer expect date time:
  //   - the moment they receive the order (delivery order)
  //   - the moment they visit store to pickup (pickup order)
  // - time prepare: time to cook
  // - store expect to ready the order (a.k.a ready for (customer/courier) to pickup): the time begin to cook (might not be the time they accept the order) + time to cook
  // - store expect time to complete the order, the time:
  //   - the customer to pickup (pickup order)
  //   - the courier to pickup then delivery it to customer (delivery order)
  const customerExpectedTime = (
      isPickUpOrder
          ? order.pickupDate === 'asap' ? 'asap' : order.pickupDate
          : order.dropOffDate === 'asap' ? 'asap' : order.dropOffDate
  )
  const ESTIMATE_TIME_FOR_PREPARE = 30
  const ESTIMATE_TIME_FOR_DELIVERY = 10
  const [storeExpectPickUpDt, setStoreExpectPickUpDt] = useSignal(
    customerExpectedTime === 'asap'
      ? dayjs().add(ESTIMATE_TIME_FOR_PREPARE, 'm')
      : isPickUpOrder
        ? dayjs(customerExpectedTime)
        : dayjs(customerExpectedTime).subtract(ESTIMATE_TIME_FOR_DELIVERY, 'm')
  )
  const [storeExpectDeliveryDt, setStoreExpectDeliveryDt] = useSignal(
    customerExpectedTime === 'asap'
      ? dayjs().add(ESTIMATE_TIME_FOR_PREPARE + ESTIMATE_TIME_FOR_DELIVERY, 'm')
      : isPickUpOrder
        ? dayjs(customerExpectedTime).add(ESTIMATE_TIME_FOR_DELIVERY, 'm')
        : dayjs(customerExpectedTime)
  )

  const orderPredefinedTimes = predefinedTimes(order)
  const [predefinedTimeIdx, setPredefinedTimeIdx] = useSignal(-1)

  async function showTimeSelectionDialog() {
    userFLow(`show time selection dia log`, {
      username: loginUser()?.name
    });
    const {action, value} = await dialogService.show<{action: string, value: any}>({
      // @ts-ignore
      component: TimeSelection,
      bind: {
        date: storeExpectPickUpDt()
      }
    })

    if (action === 'cancel')
      return;

    let idx: number;
    if (action === 'adjust') {
      if (orderPredefinedTimes.includes(value)) {
        idx = orderPredefinedTimes.indexOf(value)
      } else {
        idx = orderPredefinedTimes.length
      }
    } else {
      idx = orderPredefinedTimes.length
    }

    selectTime({action, value}, idx)
  }

  function selectTime({action, value}: {action: string, value: any}, idx?: number) {
    console.log('selectTime', {action, value})
    if (!_.isNil(idx)) {
      setPredefinedTimeIdx(idx)
    }
    switch (action) {
      case 'adjust':
        const minutes = Number(value)
        if (order.pickupDate === 'asap') {
          const newDate = dayjs().add(minutes, 'm');
          setStoreExpectPickUpDt(newDate)
          setStoreExpectDeliveryDt(newDate)
        } else {
          const newDate = dayjs(customerExpectedTime).add(minutes, 'm')
          setStoreExpectPickUpDt(newDate)
          setStoreExpectDeliveryDt(newDate)
        }
        break;
      case 'set': {
        const newDate = dayjs(value)
        setStoreExpectPickUpDt(newDate)
        setStoreExpectDeliveryDt(newDate)
        break;
      }
    }
  }

  const acceptOrderWithNewDate = () => {
    userFLow(`accept oder with new date`, {
      username: loginUser()?.name
    });
    onAccept({
      pickupDate: storeExpectPickUpDt().toISOString(),
      dropOffDate: storeExpectDeliveryDt().toISOString()
    })
  }

  const unMatchProducts = () => {
    if (order.provider !== MarketPlaceProvider.RESTABLO)
      return []
    return order.items.filter(item => !item._id)
  }

  const hasUnMatchProduct = () => unMatchProducts().length > 0

  const onCashButtonClick = () => {
    userFLow(`cash button click`, {
      username: loginUser()?.name
    });
    if (hasUnMatchProduct()) {
      msgBox.show(
        LL.pendingOrder.warning(),
        LL.pendingOrder.missingProductInfoWarning(),
        msgBox.Buttons.OK,
        msgBox.Icons.Information)
      return
    }

    if (isInHouse) {
      acceptOrderWithNewDate()
    } else if (isProviderSupportModifyOrderDate(order)) {
      setActionButtonMode(ActionButtonMode.Accept)
    } else {
      acceptOrderWithNewDate()
    }
  }

  function getTimeBtnStyle(idx: number) {
    return {
      borderRadius: '3px',
      border: '2px solid #B1B1B1',
      background: '#F4F4F4',
      boxShadow: "0.8px 1px 2px 0px rgba(0, 0, 0, 0.10)",
      width: 85,
      height: 34,
      backgroundColor: predefinedTimeIdx() === idx ? '#80c6ff' : "#fff",
    }
  }

  return (
     <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-smi z-[1]">
       {actionButtonMode === ActionButtonMode.Default &&
         <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
           <div className="cursor-pointer rounded-sm flex flex-col items-center justify-center py-[15px] px-5 gap-[2px] border-[1px] border-solid border-grey-grey-lighten-2 sm:py-3 sm:px-4 sm:box-border"
                onClick={() => {
                  userFLow(`set action button mode decline`, {
                    username: loginUser()?.name
                  });
                  setActionButtonMode(ActionButtonMode.Decline)
                }}>
             <img
                className="relative w-6 h-6 object-cover"
                alt=""
                src="/iconcancel-order-icon@2x.png"/>
           </div>
           <div className="cursor-pointer self-stretch flex-1 bg-grey-grey-lighten-2 flex flex-row items-center justify-center gap-[6px] text-sm"
                onClick={onCashButtonClick}>
             <div className="relative w-[30px] h-[30px]">
               <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/iconcash@2x.png"
               />
             </div>
             <div className="relative font-semibold">
               {LL3().format.currency(order.vTotal!)}
             </div>
           </div>
       </div>}

       {actionButtonMode === ActionButtonMode.Accept &&
         <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-base">
           <div className="self-stretch flex flex-col items-center justify-start gap-[8px] text-gray">
             <div className="self-stretch flex flex-row items-center justify-between gap-[8px] mb-[6px]">
               <div className="relative sm:text-sm">
                 { isPickUpOrder ? LL0().pendingOrder.requestedTimeOfPickUp() : LL0().pendingOrder.requestedTimeOfDelivery() }:
               </div>
               <b className="relative sm:text-sm">{
                 customerExpectedTime === 'asap'
                   ? LL0().pendingOrder.asap()
                   : smartFormatShortDateTime(customerExpectedTime)
               }</b>
             </div>

             <div className="flex flex-row flex-wrap items-center gap-[10px]">
               {orderPredefinedTimes.map((item, idx) =>
                 <div
                   className="flex items-center justify-center cursor-pointer"
                   key={item}
                   style={getTimeBtnStyle(idx)}
                   onClick={() => {
                     userFLow(`select time ${item}`, {
                       username: loginUser()?.name
                     });
                     selectTime({ action: 'adjust', value: item }, idx)
                   }}>
                 {item} min
               </div>)}
               {
                 allowCustomTimeDialog(order) &&
                 <div className="flex items-center justify-center cursor-pointer"
                      style={getTimeBtnStyle(orderPredefinedTimes.length)}
                      onClick={showTimeSelectionDialog}>...</div>
               }
             </div>
         </div>

         <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[16px] text-smi">
           <div className="cursor-pointer rounded-sm flex flex-col items-center justify-center py-[15px] px-5 gap-[2px] border-[1px] border-solid border-grey-grey-lighten-2 sm:py-3 sm:px-4 sm:box-border"
                onClick={() => {
                  userFLow(`set action button mode default`, {
                    username: loginUser()?.name
                  });
                  setActionButtonMode(ActionButtonMode.Default)
                }}>
             <img
                className="relative w-6 h-6 object-cover"
                alt=""
                src="/iconback-icon@2x.png"
             />
           </div>
           <div className="cursor-pointer self-stretch flex-1 bg-grey-grey-lighten-2 flex flex-row items-center justify-center gap-[6px] text-sm"
                onClick={() => acceptOrderWithNewDate()}>
             <img
                className="relative w-[30px] h-[30px] object-cover"
                alt=""
                src="/iconprinter-icon-3@2x.png"
             />
             <div className="relative font-semibold">{LL0().pendingOrder.accept()}</div>
             <span className="mx-1">-</span>
             <span>{storeExpectPickUpDt().format(LL2().dates.timeFormat())}</span>
           </div>
         </div>
       </div>}

       {actionButtonMode === ActionButtonMode.Decline &&
         <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-center text-sm">
           <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
             <div className="relative leading-[17px] text-crimson">
               {LL0().onlineOrder.reasonDecline()}
             </div>
             <div className="self-stretch flex flex-row gap-2 flex-wrap">
               {cancelReasons().map(v => <div
                   key={v}
                   className="bg-sky-200 rounded-md cursor-pointer px-2 py-2"
                   onClick={() => {
                     userFLow(`set decline button reason ${v}`, {
                       username: loginUser()?.name
                     });
                     setDeclineReason(v)
                   }}>
                 {v}
               </div>)}
             </div>
             <InputKeyboardPopUp
                 className="w-full"
                 value={declineReason}
                 onChange={setDeclineReason}
             />
           </div>

           <div className="self-stretch rounded-sm flex flex-row items-start justify-start gap-[16px] text-left text-smi text-black">
             <div className="cursor-pointer rounded-sm flex flex-col items-center justify-center py-[15px] px-5 gap-[2px] border-[1px] border-solid border-grey-grey-lighten-2 sm:py-3 sm:px-4 sm:box-border"
                  onClick={() => setActionButtonMode(ActionButtonMode.Default)}>
               <img className="relative w-6 h-6 object-cover" alt="" src="/iconback-icon@2x.png"/>
             </div>

             <div className="cursor-pointer self-stretch flex-1 bg-grey-grey-lighten-2 flex flex-row items-center justify-center text-center text-sm text-crimson"
                  onClick={() => onDecline(declineReason)}>
               <b className="relative leading-[17px]">
                 {LL0().pendingOrder.decline()}
               </b>
             </div>
           </div>
       </div>}
     </div>
  );
};

export default ActionButtons;
