import { convertDocuments, type DocDeepSignal } from '@/data/data-utils'
import { Partition } from '@/data/Partition'
import { computed, effectOn, signal, useAsyncEffect } from '@/react/core/reactive'
import { dataLock } from '@/data/DataUtils.ts'
import { RoomObject } from "@/data/Room.ts";
import { setActivePartitionId } from "@/react/VerticalDashboardView/partition-logic.ts";

export const [partitions0, setPartitions0] = signal<Array<DocDeepSignal<Partition>>>([])

export const [partitionV, setPartitionV] = signal(0)
effectOn(
  [partitionV],
  async () => {
    await dataLock.acquireAsync()
    const partitionsRaw = await Partition.find().exec()
    const partitions = convertDocuments<Partition>(partitionsRaw, true, ['roomObjects']);
    const roomObjectsRaw = await RoomObject.find({
      selector: {
        partition: {
          $exists: true
        }
      }
    }).exec()
    const roomObjects = (convertDocuments<RoomObject>(roomObjectsRaw, true)).sort((a, b) => (a.location?.order || 0) - (b.location?.order || 0));
    partitions.sort((r1, r2) => r1.order - r2.order)
      .forEach(r => {
        r.roomObjects = [];
        r.roomObjects?.push(...roomObjects.filter(o => o.partition === r._id).filter((o: {
          type: string;
        }) => o.type === "table" || o.name != null));
      });
    setPartitions0(partitions)
    setActivePartitionId(partitions?.[0]?._id)
  },
  { defer: true }
)

//@ts-ignore
window.partitions0 = partitions0

export const makePartitionsAvailable = () => {
  useAsyncEffect(async () => {
    if (partitionV() === 0) {
      await dataLock.acquireAsync()
      Partition.$.subscribe(change => {
        setPartitionV(v => v + 1)
      })
      setPartitionV(1)
    }
  }, [partitionV()])
}
