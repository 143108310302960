import { type FunctionComponent, useCallback } from 'react';
import { useSelector } from '@/react/core/reactive.ts';
import type { HappyHour } from '@/data/HappyHour.ts';
import { happyHour0 } from '@/react/HappyHourView/HappyHourView.tsx';
import _ from 'lodash';
import {LL0} from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type HappyHourDiscountTypeItemType = {
  typeLabel?: string;
  isSelected?: boolean;

  value?: string;
};

const HappyHourDiscountTypeItem: FunctionComponent<
  HappyHourDiscountTypeItemType
> = ({ typeLabel = "Percentage", isSelected = true, value }) => {
  const isSelectedDiscountType = useSelector<HappyHour | undefined, string>(happyHour0, (p1, p2) => p1?.discountType === p2);
  isSelected = isSelectedDiscountType(value);

  let label;
  switch (typeLabel) {
    case "Percentage":
      label = LL0().settings.happyHour.percent();
      break;
    case "Amount":
      label = LL0().settings.happyHour.amount();
      break;
    case "Fixed price":
      label = LL0().settings.happyHour.fixed();
      break;
    default:
      label = LL0().settings.happyHour.percent();
  }

  const onClick = useCallback(() => {
    userFLow(`select discount type happy hours ${value}`, {
      username: loginUser()?.name
    })
    _.assign(happyHour0(), { discountType: value })
  }, []);

  return (
    <div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border w-32 h-[30px] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] text-left text-mini text-black-solid-black-880-1d1d26 font-mulish border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
      )}
        <div className="relative z-[1] Tablet_768:text-smi">{label}</div>
    </div>
  );
};

export default HappyHourDiscountTypeItem;
