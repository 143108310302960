import { type FunctionComponent, useCallback, useState } from 'react';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import DiscountPopup from './DiscountPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { order0, paymentContext0 } from '@/react/PaymentView/PaymentView.tsx';
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts';
import { openCashDrawer } from '@/pos/cashdrawer.ts'
import VoucherPopup from "@/react/VoucherView/VoucherPopup.tsx";

export type PaymentVerticalMoreButtonType = {
  onClose?: () => void;
  isExpanded?: boolean;
};

const PaymentVerticalMoreButton: FunctionComponent<
  PaymentVerticalMoreButtonType
> = ({ onClose }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false);
  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false);

  const openDiscountPopup = useCallback(() => {
    setIsExpanded(false);
    setDiscountPopupOpen(true);
  }, []);

  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupOpen(false);
  }, []);

  const openVoucherPopup = useCallback(() => {
    setIsExpanded(false);
    setVoucherPopupOpen(true);
  }, []);

  const closeVoucherPopup = useCallback(() => {
    setVoucherPopupOpen(false);
  }, []);

  return (
    <>
      {
        isExpanded &&
        <div className="opacity-0 fixed top-0 left-0 w-full h-full z-[2]" onClick={() => setIsExpanded(false)} />
      }
      <div className="flex flex-col items-start justify-start gap-[12px_0px] text-center text-base text-black-solid-black-900-1e1e23 font-mulish-button-name-bold">
        <div className="w-[46px] rounded-981xl bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(23px)] h-[46px] hidden flex-col items-center justify-center"
             ref={makeRipple}
             onClick={() => setIsExpanded(prev => !prev)}
        >
          <img
            className="w-10 relative h-10 object-cover Mobile_480:w-9 Mobile_480:h-9 mq320:w-8 mq320:h-8"
            alt=""
            src="/icon-vertical-menu-more-icon@2x.png"
          />
        </div>
        <div className="w-[46px] h-[46px] flex flex-col items-start justify-start p-0.5 box-border relative gap-[8px_0px] max-h-[none]">
          <div className="w-[42px] rounded-981xl bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(23px)] h-[42px] flex flex-col items-center justify-center z-[0]"
               ref={makeRipple}
               onClick={() => setIsExpanded(prev => !prev)}
          >
            <img
              className="w-10 relative h-10 object-cover"
              alt=""
              src="/icon-vertical-menu-more-icon@2x.png"
            />
          </div>
          {isExpanded &&
          <div className="!z-[3] !flex !max-w-none !max-h-none !-left-1 !w-[194px] my-0 mx-[!important] absolute top-[49px] left-[2px] flex flex-col items-center justify-start py-1 px-0.5 box-border max-w-[none] max-h-[none] overflow-auto z-[1]">
            <div className="self-stretch flex flex-row items-center justify-start py-0 pr-0 pl-[15px] Mobile_480:pl-[11px] Mobile_480:box-border mq320:pl-[9px] mq320:box-border">
              <img
                className="w-[13px] relative h-[11.3px]"
                alt=""
                src="/icon-vertical-polygon1.svg"
              />
            </div>
            <div className="rounded-sm bg-black-opacity-black-30-000000-200 shadow-[1px_0px_4px_#525f71,_10px_-10px_14px_rgba(149,_149,_149,_0.1)_inset,_-10px_10px_14px_rgba(255,_255,_255,_0.1)_inset] [backdrop-filter:blur(40px)] overflow-hidden flex flex-col items-center justify-start p-3 gap-[16px_0px]">
              <div className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] Mobile_480:h-9 mq320:h-8"
                   ref={makeRipple}
                   onClick={() => paymentContext0.onSeatToggle()}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                  alt=""
                  src="/icon-vertical-splitbill-icon@2x.png"
                />
                <b className="flex-1 relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().order.splitOrder()}
                </b>
              </div>
              { getAccessibility(AccessPort.DISCOUNT) &&
              <div
                className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] cursor-pointer Mobile_480:h-9 mq320:h-8"
                onClick={openDiscountPopup}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                  alt=""
                  src="/payment-vertical--discount-icon@2x.png"
                />
                <b className="flex-1 relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().order.discount()}
                </b>
              </div>
              }
              <div
                className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] cursor-pointer Mobile_480:h-9 mq320:h-8"
                onClick={openVoucherPopup}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                  alt=""
                  src="/payment-vertical--voucher-icon@2x.png"
                />
                <b className="flex-1 relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().printing.voucher()}
                </b>
              </div>
            <div className="rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-[0px_8px] Mobile_480:h-9 mq320:h-8"
                 ref={makeRipple}
                 onClick={() => openCashDrawer()}
            >
                <img
                  className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                  alt=""
                  src="/payment-vertical--cashdrawer-icon@2x.png"
                />
                <b className="relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().payment.openCashDrawer()}
                </b>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
      {isDiscountPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopup onClose={closeDiscountPopup} />
        </PortalPopup>
      )}
      {isVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeVoucherPopup}
        >
          <VoucherPopup onClose={closeVoucherPopup} order={order0()} />
        </PortalPopup>
      )}
    </>
  );
};

export default PaymentVerticalMoreButton;
