import { FunctionComponent, useState } from "react";
import { TextField, Icon } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
export type DataExportPopupType = {
  className?: string;
  onClose?: () => void;
};

const DataExportPopup: FunctionComponent<DataExportPopupType> = ({
  className = "",
  onClose,
}) => {
  const [inputDateTimePickerValue, setInputDateTimePickerValue] =
    useState(null);
  const [inputDateTimePicker1Value, setInputDateTimePicker1Value] =
    useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`w-[448px] h-full bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-[18px] px-0 pb-0 box-border max-w-full max-h-full overflow-auto text-left text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center pt-0 px-2 pb-[18px] text-[16px]">
          <b className="flex-1 relative mq432:text-[15px]">
            GDPdU/GoBD Data Exporteren
          </b>
          <img
            className="w-5 relative h-5 object-cover cursor-pointer"
            alt=""
            src="/iconclose-popup-icon@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-2 pb-4 text-gray">
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
              <b className="relative sm:text-smi mq600:text-smi">Type</b>
              <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-white-solid-white-100-ffffff">
                <div className="flex-1 rounded-10xs bg-blue-solid-blue-400-0091ff overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px]">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-450-1271ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-450-1271ff" />
                  <div className="relative font-semibold z-[1]">GoDB</div>
                </div>
                <div className="flex-1 rounded-10xs bg-gray-solid-gray-135-efefef overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px] text-black-solid-black-900-1e1e23">
                  <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-450-1271ff box-border hidden z-[0] border-[1px] border-solid border-blue-solid-blue-450-1271ff" />
                  <div className="relative font-semibold z-[1]">DSFINV</div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-end gap-[16px] mq360:flex-col mq360:gap-[8px]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[8px] mq360:flex-[unset] mq360:self-stretch">
                <b className="relative sm:text-smi mq600:text-smi">Von</b>
                <div className="self-stretch shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)]">
                  <DatePicker
                    value={inputDateTimePickerValue}
                    onChange={(newValue: any) => {
                      setInputDateTimePickerValue(newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <Icon>calendar_today_sharp</Icon>,
                    }}
                    sx={{}}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        required: false,
                        autoFocus: false,
                        error: false,
                        color: "primary",
                      },
                      openPickerIcon: {
                        component: () => <></>,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[8px] mq360:flex-[unset] mq360:self-stretch">
                <b className="relative sm:text-smi mq600:text-smi">Bis</b>
                <div className="self-stretch shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)]">
                  <DatePicker
                    value={inputDateTimePicker1Value}
                    onChange={(newValue: any) => {
                      setInputDateTimePicker1Value(newValue);
                    }}
                    components={{
                      OpenPickerIcon: () => <Icon>calendar_today_sharp</Icon>,
                    }}
                    sx={{}}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        required: false,
                        autoFocus: false,
                        error: false,
                        color: "primary",
                      },
                      openPickerIcon: {
                        component: () => <></>,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <b className="relative sm:text-smi mq600:text-smi">
                Exportieren zu:
              </b>
              <div className="relative sm:text-smi mq600:text-smi">
                Keine USB gefunden. Bitte ein USB anstecken!
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch overflow-hidden flex flex-col items-end justify-start pt-3 px-2 pb-4 text-center text-white-solid-white-100-ffffff mq320:gap-[8px]">
          <div className="self-stretch h-9 flex flex-row items-start justify-start gap-[8px]">
            <div className="flex-1 rounded-md bg-purple-solid-purple-500-586cf5 overflow-hidden flex flex-row items-center justify-center py-[9px] px-1">
              <b className="relative">Refresh</b>
            </div>
            <div className="flex-1 rounded-md bg-green-solid-green-415-7ab76f overflow-hidden flex flex-row items-center justify-center py-[9px] px-1">
              <b className="relative">Verlassen</b>
            </div>
            <div className="flex-1 rounded-md bg-green-solid-green-415-7ab76f overflow-hidden flex flex-row items-center justify-center py-[9px] px-1">
              <b className="relative">Tse Info</b>
            </div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default DataExportPopup;
