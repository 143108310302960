import { type FunctionComponent, useCallback, useState } from "react";
import EditMenuAddIngredientP from "./EditMenuAddIngredientP";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { initInventoryItem, setInventory0 } from "@/react/InventoryView/InventoryView.tsx";
import { deepSignal } from "@/react/core/reactive.ts";
import { clone } from "json-fn";
import { EditInventoryMode, setEditInventoryMode } from "@inventory/Popups/EditItemPopup/InventoryAddEditItemPopu.tsx";
import { setOpenEditMenuForm } from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";
import { setEditMenuEditFormOpen } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import PortalDrawer from "@vertical-edit-menu/PortalDrawer.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { ITEM_MODE, setEditorMode0 } from "@/react/EditMenuView/EditMenuView.tsx";

export type EditIngredientToolbarType = {
  className?: string;
};

const EditIngredientToolbar: FunctionComponent<EditIngredientToolbarType> = ({
  className = "",
}) => {
  const [isEditMenuAddIngredientPOpen, setEditMenuAddIngredientPOpen] =
    useState(false);

  const openEditMenuAddIngredientP = useCallback(() => {
    setEditInventoryMode(EditInventoryMode.ADD);
    setInventory0(deepSignal(clone(initInventoryItem())));
    setEditMenuAddIngredientPOpen(true);
  }, []);

  const closeEditMenuAddIngredientP = useCallback(() => {
    setEditMenuAddIngredientPOpen(false);
  }, []);

  return (
    <>
      <div
        className={`self-stretch bg-gray-solid-gray-170-e3e4e5 flex flex-row items-center justify-between py-3 px-2.5 z-[1] text-left text-sm text-black-solid-black-1000-000000 font-mulish sm:h-[42px] sm:pl-2 sm:pr-2 sm:box-border ${className}`}
      >
        <div className="flex flex-row items-center justify-start gap-2 sm:gap-1">
          <div
            className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-3 box-border relative gap-2 cursor-pointer"
            onClick={openEditMenuAddIngredientP}
            ref={makeRipple}
          >
            <div className="flex-1 !m-[0] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
            <b className="relative z-[1] sm:text-smi">{LL0().product.newIngredient()}</b>
          </div>
          <div className="flex-1 opacity-50 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-3 box-border relative gap-2">
            <div className="flex-1 !m-[0] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
            <b className="relative z-[1] sm:text-smi">{LL0().editMenu.checkList()}</b>
          </div>
        </div>
        <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-blue-solid-blue-450-1271ff h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border text-white-solid-white-100-ffffff"
             ref={makeRipple}
             onClick={() => {
               if(generalSetting0()?.useMenuV2) {
                 setEditorMode0(ITEM_MODE.ITEM)
               }
               setOpenEditMenuForm(false)
               setEditMenuEditFormOpen(false)
             }}
        >
          <b className="relative sm:text-smi">{LL0().ui.close()}</b>
        </div>
      </div>
      {isEditMenuAddIngredientPOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Bottom"
          onOutsideClick={closeEditMenuAddIngredientP}
        >
          <EditMenuAddIngredientP onClose={closeEditMenuAddIngredientP} />
        </PortalDrawer>
      )}
    </>
  );
};

export default EditIngredientToolbar;