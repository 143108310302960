import { useEffect, useMemo } from 'react'

import type { OrderStrip, OrderType } from '@/pos/OrderType'
import { computed, effect, mutable, signal } from '@/react/core/reactive'
import type { OrderMode } from '@/react/OrderView/OrderViewShare.ts'

export const enum PosScreen {
  BLANK = 'Blank',
  ORDER = 'OrderView',
  FLOOR_PLAN = 'FloorPlanView',
  MAIN_VIEW = 'MainView',
  VIRTUAL_PRINTER_VIEW = 'VirtualPrinterView',
  VIRTUAL_PRINTER = 'VirtualPrinter',
  EDIT_MENU = 'EditMenu',
  EDIT_FLOOR_PLAN = 'EditFloorPlan',
  EDIT_USER = 'EditUser',
  TIME_MANAGEMENT = 'TimeManagement',
  PAYMENT = 'Payment',
  EOD = 'EOD',
  ORDER_HISTORY = 'OrderHistory',
  CUSTOMER_INFO = 'CustomerInfo',
  PRINTER_SETTING = 'PrinterSetting',
  PENDING_ORDERS = 'PendingOrders',
  PAYMENT_SETTING = 'PaymentSetting',
  COMPANY_INFORMATION = 'CompanyInformation',
  MONTHLY_REPORT = 'MonthlyReport',
  LOGIN = 'Login',
  HAPPY_HOUR = 'HappyHour',
  RESERVATION = 'Reservation',
  GENERAL_SETTINGS = 'GeneralSettings',
  PASSCODE = 'Passcode',
  GENERAL_SETTING = 'GeneralSetting',
  STAFF_REPORT = 'StaffReport',
  BILL = 'Bill',
  DEVELOPER = 'Developer',
  EDIT_ONLINE_MENU = 'EditOnlineMenu',
  PAIRING = 'Pairing',
  WEB_MENU = 'WebMenu',
  MASTER_MACHINE = 'MasterMachine',
  SHIPPING_STATUS = 'ShippingStatus',
  INVENTORY = 'Inventory',
  CASHBOOK = 'Cashbook',
  SYNC_MENU = 'SyncMenu',
  KITCHEN_MONITOR = 'KitchenMonitor',
  EMAIL_REPORT = 'EmailReport',
  ONLINE_ORDER_SETTING = 'OnlineOrderSetting',
  TUTORIAL = 'Tutorial',
  MOVE_ITEM_TABLE = 'MoveTableItem',
  MANUAL_TABLE = 'ManualTable',
  SIGNIN = 'SignIn',
  NEW_DELIVERY = 'NewDelivery',
  TERMINAL = 'Terminal',
  SRM = 'Srm',
  INSTALLATION = 'Installation',
  VOUCHER = 'Voucher',
  VERTICAL_CUSTOMER_INFO = "VerticalCustomerInfo",
  VERTICAL_EDIT_MENU = "VerticalEditMenu",
  VERTICAL_VIRTUAL_PRINTER = "VerticalVirtualPrinter",
  VERTICAL_HAPPY_HOUR = "VerticalHappyHour",
  VERTICAL_RESERVATION = "VerticalReservation",
  VERTICAL_ORDER = "VerticalOrder",
  VERTICAL_TIME_MANAGEMENT = "VerticalTimeManagement",
  VERTICAL_USER_SETTING = "VerticalUserSetting",
  VERTICAL_PENDING_ORDERS = "VerticalPendingOrders",
  VERTICAL_INVENTORY = "VerticalInventory",
  VERTICAL_PAYMENT = "VerticalPayment",
  VERTICAL_GENERAL_SETTINGS = "VerticalGeneralSettings",
  VERTICAL_COMPANY_INFO = "VerticalCompanyInfo",
  VERTICAL_DASHBOARD= "VerticalDashboard",
  VERTICAL_PASSCODE= "VerticalPasscode",
  VERTICAL_SHIPPING_STATUS= "VerticalShippingStatus",
  VERTICAL_STAFF_REPORT= "VerticalStaffReport",
  VERTICAL_EOD= "VerticalEod",
  VERTICAL_ORDER_HISTORY= "VerticalOrderHistory",
  VERTICAL_SYNC_MENU= "VerticalSyncMenu",
  VERTICAL_MONTHLY_REPORT= "VerticalMonthlyReport",
  VERTICAL_NEW_DELIVERY= "VerticalNewDelivery",
  VERTICAL_ONLINE_PROVIDER_SETTINGS= "VerticalOnlineProviderSettings",
  VERTICAL_DATA_EXPORT= "VerticalDataExport",
  VERTICAL_EMAIL_REPORT= "VerticalEmailReport",
  VERTICAL_SET_MASTER_MACHINE= "VerticalSetMasterMachine",
  VERTICAL_DEVELOPER_ONLY= "VerticalDeveloperOnly",
  VERTICAL_PRINTER_SETTING= "VerticalPrinterSetting",
  VERTICAL_CASHBOOK= "VerticalCashbook",
  VERTICAL_TAX_PAYMENT= "VerticalTaxPayment",
  VERTICAL_ONLINE_MENU= "VerticalOnlineMenu",
  VERTICAL_MANUAL_TABLE = "VerticalManualTable",
  DETAIL_STAFF_REPORT = "DetailStaffReport",
  EDIT_MENU_VERTICAL='EditMenuVertical',
  VERTICAL_VOUCHER = "VerticalVoucher",
  VERTICAL_EDIT_ROOM = "VerticalEditRoom",
  VERTICAL_TERMINAL = "VerticalTerminal",
  VERTICAL_INSTALLATION = "VerticalInstallation",
  KIOSK_START_SCREEN = 'KioskStartScreen',
  KIOSK_ORDER_VIEW = 'KioskOrderView',
  KIOSK_PAYMENT = 'KioskPayment',
}

/** **React Signal** to store the current screen */
export const router = mutable<{ screen: PosScreen }>({ screen: PosScreen.BLANK })
const routerHistory: string[] = []

//@ts-ignore
window.router=router

// Rcord route history
effect(() => {
  routerHistory.push(router.screen)
})

/** Extra navigation params */
interface ParamType {
  table?: string
  order?: OrderStrip
  orderMode?: OrderMode
  isMoveTable?: boolean
  takeAway?: boolean
  // new delivery
  orderType?: OrderType
}

export const [params, setParams] = signal<ParamType>()

/** **React Hook** to run a function when a screen is exited */
export const onExit = (screen: string, fn: () => any) => {
  const isScreen = useMemo(() => computed(() => router.screen === screen), [])
  useEffect(() => {
    // TODO: check this
    // if (!isScreen() && _.last(routerHistory) === screen) fn();
    if (!isScreen()) fn()
  }, [isScreen()])
}

/** **React Signal's Effect** to run when a screen is exited */
export const onExitScreens = (screens: PosScreen[], fn: () => any) => {
  const isScreen = computed(() => screens.includes(router.screen))
  effect(() => {
    // TODO: check this
    // if (!isScreen() && _.last(routerHistory) === screen) fn();
    if (!isScreen()) fn()
  })
}

/** **React Hook** to run a function when a screen is entered */
export const onEnter = (screen: string, fn: () => any) => {
  const isScreen = useMemo(() => computed(() => router.screen === screen), [])
  useEffect(() => {
    if (isScreen()) fn()
  }, [isScreen()])
}

/** **React Signal's Effect** to run when a screen is entered */
export const onEnterScreen = (screen: string, fn: () => any) => {
  const isScreen = computed(() => router.screen === screen)
  effect(() => {
    if (isScreen()) fn()
  })
}

/** **React Signal's Effect** to run when a screen is entered */
export const onEnterScreens = (screens: string[], fn: () => any) => {
  const isScreen = computed(() => screens.includes(router.screen))
  effect(() => {
    if (isScreen()) fn()
  })
}

/** **React Signal's Effect** to run when a screen is entered for the first time */
export const onMountScreen = (screen: string, fn: () => any) => {
  const isScreen = computed(() => router.screen === screen)
  let isFirstTime = true

  effect(() => {
    if (isScreen() && isFirstTime) {
      isFirstTime = false
      fn()
    }
  })
}
