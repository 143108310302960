import dayjs from 'dayjs'
import { now, type RxCollection, type RxPlugin } from 'rxdb'

import type { BaseDocumentType } from '@/data/types'
import { getDeviceId } from '@/shared/getDeviceId'

function assignUpdatedAt(collection: RxCollection) {
  function onSave(doc: BaseDocumentType) {
    doc.updatedAt = dayjs(now()).valueOf()
    doc.updatedOn = getDeviceId()
  }

  collection.preInsert(onSave, true)
  collection.preSave(onSave, true)
  collection.preRemove(onSave, true)
}

export const UpdatedAtPlugin: RxPlugin = {
  rxdb: true, // this must be true so rxdb knows that this is a rxdb-plugin
  name: 'updatedAt',
  hooks: {
    createRxCollection: {
      after({ collection }) {
        assignUpdatedAt(collection)
      },
    },
    preCreateRxSchema: {
      after(schema) {
        schema.properties.updatedAt = { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 }
      },
    },
  },
}
