import { memo } from 'react'
import VerticalEditMenuPlugin from "@vertical-edit-menu/VerticalEditMenuPlugin.tsx";
import { signal, useAsyncEffect } from "@/react/core/reactive.ts";
import { makeHappyHoursAvailable } from "@/data/HappyHourHub.ts";
import { makeCategoriesAvailable } from "@/data/CategoryHub.ts";
import { makeInventoriesAvailable } from "@/data/InventoryHub.ts";
import { makeProductsAvailable } from "@/data/ProductHub.ts";
import { makeModifiersAvailable } from "@/data/ModifierHub.ts";
import { multipleMenus0 } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import {
  categories0,
  products0,
  sections0,
  setCategories0,
  setCategory0,
  setMenu0,
  setProducts0,
  setSections0
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { dataLock } from "@/data/DataUtils.ts";
import { MakeObservableDocument, MakeObservableList } from "@/data/utils/data-utils-2.ts";
import { Category } from "@/data/Category.ts";
import { Section } from "@/data/Section.ts";
import { Product } from "@/data/Product.ts";
import { Menu, MenuTypes } from "@/data/Menu.ts";
import { convertDocument } from "@/data/data-utils.ts";
import { setCategories, setV } from "@/react/OrderView/OrderView.tsx";
import { makeGroupPrintersAvailable } from "@/data/GroupPrinterHub.ts";
import { makeTaxCategoriesAvailable } from "@/data/TaxCategoryHub.ts";
import { router } from "@/pos/PosRouter.ts";
import { generalSetting0, mainScreen, mainScreenVertical } from "@/data/PosSettingsSignal.ts";

export const [isOpenEditMenuForm, setOpenEditMenuForm] = signal(false)

export const onBackEditMenu = () => {
  if (generalSetting0()?.useMenuV2) {
    router.screen = mainScreen()
  } else {
    router.screen = mainScreenVertical()
  }
}

const VerticalEditMenuView = () => {
  makeHappyHoursAvailable();
  makeCategoriesAvailable();
  makeInventoriesAvailable();
  makeProductsAvailable();
  makeModifiersAvailable();
  multipleMenus0();
  makeGroupPrintersAvailable()
  makeTaxCategoriesAvailable()

  useAsyncEffect(async () => {
    await dataLock.acquireAsync();
    setV(v => v + 1)
    console.log('---------Edit Menu Vertical----------')
    const _menu = await Menu.findOne({ selector: { type: MenuTypes.LOCAL } }).exec()
    const _categories = await Category.find({
      selector: {
        menus: { $exists: true },
        type: { $exists: true }
      }
    }).exec()
    if (_categories) {
      setCategories(_categories.map(s => s?.toMutableJSON()))
      setCategory0(_categories[0]?.toMutableJSON())
    }
    if (_menu) {
      setMenu0(convertDocument(_menu, true))
    }

    await MakeObservableList(
      Category.find({ selector: { menus: { $exists: true }, type: { $exists: true } } }),
      (c) => (c.menus?.length || 0) > 0,
      doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
        .makeAutoSave().makeDebounce(100).exec()
    )
      .addListSignal(categories0, setCategories0)
      .makeAutoPull()
      .pull();

    await MakeObservableList(
      Section.find(),
      (c) => true,
      doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
        .makeAutoSave().makeDebounce(100).exec()
    )
      .addListSignal(sections0, setSections0)
      .makeAutoPull()
      .pull();

    await MakeObservableList(
      Product.find(),
      (c) => true,
      doc => MakeObservableDocument(doc).createSignal().makeAutoObserve()
        .makeAutoSave().makeDebounce(100).exec()
    )
      .addListSignal(products0, setProducts0)
      .makeAutoPull()
      .pull();
  })


  return <VerticalEditMenuPlugin />
}

export default memo(VerticalEditMenuView)