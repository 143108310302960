import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { MENU_COLLECTION_NAME } from './Menu'
import { createCollection } from "@/data/utils/migration.ts";

export const CATEGORY_COLLECTION_NAME = 'categories'

enum ProductType {
  Note,
  Modifier,
}

export const VERSION = 8

const categorySchema = {
  title: 'category schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 64 },
    parentCategory: { type: 'string' },
    icon: { type: 'string' },
    iconColor: { type: 'string' },
    groupPrinter: { type: 'string', maxLength: 24 },
    type: { type: 'enum', enum: ['material', 'vertical'] },
    layout: {
      type: 'object',
      properties: {
        color: { type: 'string' },
        top: { type: 'number' },
        left: { type: 'number' },
      },
    },
    groupPrinter2: { type: 'string', maxLength: 24 },
    position: { type: 'number' },
    groupPrinters: { type: 'array', items: { type: 'string' } },
    menus: { type: 'array', ref: MENU_COLLECTION_NAME, items: { type: 'string' } },
  },
  indexes: ['id', 'name', 'groupPrinter', 'groupPrinter2'],
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

export interface Category extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof categorySchema> {
  /** @deprecated Use `groupPrinters` instead! */
  groupPrinter?: string
  /** @deprecated Use `groupPrinters` instead! */
  groupPrinter2?: string
}
export const Category = {} as RxCollection<Category>

export const createCategoryCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Category,
    collectionName: CATEGORY_COLLECTION_NAME,
    version: VERSION,
    schema: categorySchema,
  });
}
//@ts-ignore
window.Category = Category
