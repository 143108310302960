import type { Inventory } from '@/data/Inventory.ts'
import { getLL } from '@/react/core/I18nService.tsx'
import { categories0 } from '@/data/CategoryHub.ts'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import InventoryAddEditItemPopu, {
  EditInventoryMode,
  setEditInventoryMode
} from '@inventory/Popups/EditItemPopup/InventoryAddEditItemPopu.tsx'
import { useCallback, useState } from 'react'
import { setInventory0 } from '@/react/InventoryView/InventoryView.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import dayjs from 'dayjs'
import { clsx } from 'clsx'
import { setInventoryActionsId } from '@/data/InventoryActionHub.ts'
import { LL3 } from "@/react/core/I18nCurrency.tsx";

type InventoryTableRowProps = {
  inventoryData: Inventory;
  isEven?: boolean;
}

const InventoryTableRow = ({inventoryData, isEven}: InventoryTableRowProps) => {
  const LL = getLL();
  const {product, id, stock, unit, threshold} = inventoryData
  const [isInventoryAddEditItemPopuOpen, setInventoryAddEditItemPopuOpen] =
    useState(false);

  const openInventoryAddEditItemPopu = useCallback(() => {
    setEditInventoryMode(EditInventoryMode.EDIT);
    setInventory0(inventoryData);
    setInventoryActionsId(inventoryData._id);
    setInventoryAddEditItemPopuOpen(true);
  }, [JSON.stringify(inventoryData)])

  const closeInventoryAddEditItemPopu = useCallback(() => {
    setInventoryAddEditItemPopuOpen(false);
  }, []);

  return <>
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[1/1] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[8px] hover:relative hover:col-[1/1] hover:row-[3/3]">*/}
    {/*  <div*/}
    {/*    className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*    {id}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[2/2] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[8px] hover:relative hover:col-[2/2] hover:row-[3/3]">*/}
    {/*  <div*/}
    {/*    className="flex-1 relative font-semibold [display:-webkit-inline-box] items-center justify-center overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*    {product?.name}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[3/3] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[8px] hover:relative hover:col-[3/3] hover:row-[3/3] Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden height_414:hidden">*/}
    {/*  <div*/}
    {/*    className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*    {product?.categories?.map((categoryId) =>*/}
    {/*      categories0().find(category => category._id === categoryId)?.name).join(", ")}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[4/4] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[8px] hover:relative hover:col-[4/4] hover:row-[3/3] Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden height_414:hidden">*/}
    {/*  <div*/}
    {/*    className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*    {LL().format.currency(product?.price || 0)}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[2px] relative col-[5/5] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[2px] hover:relative hover:col-[5/5] hover:row-[3/3]">*/}
    {/*  <div className="flex-1 h-[42px] flex flex-row items-center justify-center gap-[2px]">*/}
    {/*    <div*/}
    {/*      className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*      {stock}*/}
    {/*    </div>*/}
    {/*    <div*/}
    {/*      className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*      {unit}*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[6/6] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[8px] hover:relative hover:col-[6/6] hover:row-[3/3]">*/}
    {/*  <div*/}
    {/*    className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">*/}
    {/*    {threshold}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[7/7] row-[3/3] border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] hover:gap-[8px] hover:relative hover:col-[7/7] hover:row-[3/3]">*/}
    {/*  <div*/}
    {/*    className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-2xs">*/}
    {/*    {dayjs.unix(inventoryData.lastUpdated || 0).format("DD/MM/YYYY")}*/}
    {/*  </div>*/}
    {/*</div>*/}
    {/*<div*/}
    {/*  className="!row-auto !col-auto bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative col-[8/8] row-[3/3] border-b-[1px] border-solid border-black-solid-black-700-364150 hover:gap-[8px] hover:relative hover:col-[8/8] hover:row-[3/3]"*/}
    {/*  ref={makeRipple}*/}
    {/*  onClick={openInventoryAddEditItemPopu}*/}
    {/*>*/}
    {/*  <img*/}
    {/*    className="relative w-[26px] h-[26px] object-cover cursor-pointer mq1440:w-7 mq1440:h-7"*/}
    {/*    alt=""*/}
    {/*    src="/iconedit-icon-2@2x.png"*/}
    {/*  />*/}
    {/*</div>*/}
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] height_414:col-[1/1]', isEven && "bg-white")}>
      <div
        className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
        {id}
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_768:col-[2/4] Tablet_600:col-[2/4] height_414:col-[2/4]', isEven && "bg-white")}>
      <div
        className="flex-1 relative font-semibold [display:-webkit-inline-box] items-center justify-center overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
        {product?.name}
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden height_414:hidden', isEven && "bg-white")}>
      <div
        className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
        {product?.categories?.map((categoryId) =>
          categories0().find(category => category._id === categoryId)?.name).join(", ")}
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden height_414:hidden', isEven && "bg-white")}>
      <div
        className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
        {LL3().format.currency(product?.price || 0)}
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[2px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_768:col-[5/5] Tablet_600:col-[5/5] height_414:col-[5/5]', isEven && "bg-white")}>
      <div className="flex-1 h-[42px] flex flex-row items-center justify-center gap-[2px]">
        <div
          className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
          {stock}
        </div>
        <div
          className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
          {unit}
        </div>
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_768:col-[6/6] Tablet_600:col-[6/6]', isEven && "bg-white")}>
      <div
        className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-3xs">
        {threshold}
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-r-[1px] border-solid border-black-solid-black-700-364150 border-b-[1px] Tablet_768:col-[7/7] Tablet_600:col-[7/7]', isEven && "bg-white")}>
      <div
        className="flex-1 relative font-semibold flex items-center justify-center h-[42px] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mq1440:text-base mq320:text-2xs">
        {dayjs.unix(inventoryData.lastUpdated || 0).format(LL().dates.dateFormat())}
      </div>
    </div>
    <div
      className={clsx('bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-center py-0 px-1 gap-[8px] relative border-b-[1px] border-solid border-black-solid-black-700-364150 Tablet_768:col-[8/8] Tablet_600:col-[8/8]', isEven && "bg-white")}
      onClick={openInventoryAddEditItemPopu}
      ref={makeRipple}
    >
      <img className="relative w-[26px] h-[26px] object-cover cursor-pointer mq1440:w-7 mq1440:h-7" alt=""
           src="/iconedit-icon-2@2x.png"/>
    </div>

    {isInventoryAddEditItemPopuOpen && (
      <PortalPopup
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={closeInventoryAddEditItemPopu}
      >
        <InventoryAddEditItemPopu onClose={closeInventoryAddEditItemPopu}/>
      </PortalPopup>
    )}
  </>
}

export default InventoryTableRow;