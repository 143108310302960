import { type FunctionComponent, useCallback, useState } from "react";
import PopupProductVariantEdit from "./PopupProductVariantEdit";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { STATES, VIEW__PRODUCT } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const VariantSelectFieldset: FunctionComponent = () => {
  const [isEditAddVariantPopupOpen, setEditAddVariantPopupOpen] =
    useState(false);

  const openEditAddVariantPopup = useCallback(() => {
    setEditAddVariantPopupOpen(true);
  }, []);

  const closeEditAddVariantPopup = useCallback(() => {
    setEditAddVariantPopupOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold height_414:gap-[2px]">
        <div className="self-stretch flex flex-row items-center justify-between py-0 pr-1.5 pl-0">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.variants()}:
          </div>
          <div
            className="relative [text-decoration:underline] font-medium text-green-solid-green-500-1f8b24 text-right cursor-pointer lg:text-base md:text-mini sm:text-sm mq768:text-smi height_414:text-smi"
            onClick={openEditAddVariantPopup}
            ref={makeRipple}
          >
            + {LL0().editOnlineMenu.addNew()}
          </div>
        </div>
        <div className="self-stretch rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] flex flex-row items-start justify-between py-[5px] pr-2 pl-3 text-blue-solid-blue-200-017efa border-[1px] border-solid border-blue-solid-blue-100-e6f2fe">
          <div className="flex-1 shrink-0 flex flex-row flex-wrap items-center justify-start gap-[8px] min-h-[36px]">
            {STATES.editingProduct()?.variants?.filter(a => a.isEnabled).map(v => (
            <div key={v._id} className="rounded-xl bg-blue-solid-blue-100-e6f2fe h-9 flex flex-row items-center justify-start py-1.5 px-2 box-border gap-[4px]">
              <div className="relative font-semibold md:text-base sm:text-sm mq768:text-smi">
                {v.name}
              </div>
              {v.price &&
              <div className="relative font-semibold text-orange-solid-orange-200-ef9400 md:text-base sm:text-sm mq768:text-smi">
                +{LL3().format.currency(v.price)}
              </div>
              }
              <img
                className="cursor-pointer relative w-5 h-5 object-cover mq768:w-[18px] mq768:h-[18px]"
                alt=""
                src="/iconmenudelete-icon@2x.png"
                onClick={() => {
                  userFLow(`disabled variants (id: ${v._id}) in Online Menu`, {
                    username: loginUser()?.name
                  });
                  VIEW__PRODUCT.onDisableVariant(v._id)
                }}
              />
            </div>
            ))}
            {/*
            <div className="rounded-xl bg-blue-solid-blue-100-e6f2fe h-9 flex flex-row items-center justify-start py-1.5 px-2 box-border gap-[4px]">
              <div className="relative font-semibold md:text-base sm:text-sm mq768:text-smi">
                Medium
              </div>
              <div className="relative font-semibold text-orange-solid-orange-200-ef9400 md:text-base sm:text-sm mq768:text-smi">
                +$10
              </div>
              <img
                className="relative w-5 h-5 object-cover mq768:w-[18px] mq768:h-[18px]"
                alt=""
                src="/iconmenudelete-icon@2x.png"
              />
            </div>
            <div className="rounded-xl bg-blue-solid-blue-100-e6f2fe h-9 flex flex-row items-center justify-start py-1.5 px-2 box-border gap-[4px]">
              <div className="relative font-semibold md:text-base sm:text-sm mq768:text-smi">
                Family
              </div>
              <div className="relative font-semibold text-orange-solid-orange-200-ef9400 md:text-base sm:text-sm mq768:text-smi">
                +$10
              </div>
              <img
                className="relative w-5 h-5 object-cover mq768:w-[18px] mq768:h-[18px]"
                alt=""
                src="/iconmenudelete-icon@2x.png"
              />
            </div>
            */}
          </div>
        </div>
      </div>
      {isEditAddVariantPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditAddVariantPopup}
        >
          <PopupProductVariantEdit onClose={closeEditAddVariantPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default VariantSelectFieldset;
