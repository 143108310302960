import { type FunctionComponent, useState, useCallback } from "react";
import NewEditTerminalPopup from "./Popups/NewEditTerminalPopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import TaxPaymentTerminalRow from "./TaxPaymentTerminalRow";
import { LL0 } from '@/react/core/I18nService.tsx';
import { terminals0 } from '@/data/TerminalHub.ts';
import {
  createTerminal,
  initTerminal,
  setTerminal0,
  terminal0
} from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import { deepSignal } from "@/react/core/reactive";
import { clone } from 'json-fn';

const TerminalSection: FunctionComponent = () => {
  const onAddTerminal = async () => {
    const _terminal = terminal0();
    if (!_terminal) return;
    await createTerminal(_terminal);
    setNewEditTerminalPopup1Open(false);
  }

  const [isNewEditTerminalPopup1Open, setNewEditTerminalPopup1Open] =
    useState(false);

  const openNewEditTerminalPopup = useCallback(() => {
    setTerminal0(deepSignal(clone(initTerminal)))
    setNewEditTerminalPopup1Open(true);
  }, []);

  const closeNewEditTerminalPopup1 = useCallback(() => {
    setNewEditTerminalPopup1Open(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-end justify-start gap-[14px_0px] text-left text-sm text-gray font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">
          <b className="self-stretch relative sm:text-smi Tablet_768:text-smi">
            {LL0().payment.terminal()}:
          </b>
          <div className="self-stretch rounded overflow-hidden flex flex-col items-start justify-start text-mini text-white-solid-white-100-ffffff border-[2px] border-solid border-gray-solid-gray-164-e1e1e1">
            <div className="self-stretch bg-gray-solid-gray-500-888f97 flex flex-row items-center justify-between py-[13px] px-[11px] sm:pt-2 sm:pb-2 sm:box-border Tablet_768:pt-2 Tablet_768:pb-2 Tablet_768:box-border">
              <div className="flex-1 relative font-semibold Tablet_768:text-smi">
                {LL0().article.name()}
              </div>
              <div className="flex-1 relative Tablet_768:text-smi">{LL0().article.type()}</div>
              <div className="w-[104px] relative h-px overflow-hidden shrink-0" />
            </div>
            {terminals0().map((terminal) =>
              <TaxPaymentTerminalRow
                terminal={terminal}
                key={terminal._id}
              />) }
          </div>
        </div>
        <div
          className="rounded-xl bg-green-solid-green-9fcc3b shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center py-1 px-2 gap-[0px_5px] cursor-pointer text-smi text-black-solid-black-1000-000000"
          onClick={openNewEditTerminalPopup}
        >
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconplus-icon1@2x.png"
          />
          <div className="relative font-semibold">{LL0().taxPayment.newTerminal()}</div>
        </div>
      </div>
      {isNewEditTerminalPopup1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewEditTerminalPopup1}
        >
          <NewEditTerminalPopup onClose={closeNewEditTerminalPopup1} onComplete={onAddTerminal}/>
        </PortalPopup>
      )}
    </>
  );
};

export default TerminalSection;
