import { memo, useEffect } from 'react';
import { ITEM_MODE, setEditorMode0 } from '@/react/EditMenuView/EditMenuView.tsx';
import { batch, signal, useComputed, useSignal } from '@/react/core/reactive.ts';
import GridElement from '@/react/core/GridElement.tsx';
import type { Setter } from "solid-js";
import {
  categories0,
  isSelected, menu0,
  setCategory0,
  setCurrentCategory,
  setTempSwitchItem,
  tempSwitchItem,
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { Category } from "@/data/Category.ts";
import { peek } from "deepsignal/react";
import delay from "delay";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { setOpenEditMenuForm } from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";

let categoryHub: any = {};


const RenderCategoryVertical = memo(({ left, top, setVer }: { top: number, left: number, setVer: Setter<number> }) => {
  const category = useComputed(() => {
    let found = categories0()?.find((c) => c.get()?.layout?.top === top && c.get()?.layout?.left === left);
    return found?.get();
  });
  const index = useComputed(() => categories0()?.findIndex(_category => _category?.get()?._id === category()?._id) as number);
  const color = () => category()?.layout?.color || '#FFFFFF'
  const active = isSelected(index());
  const border = () => {
    if (!active) return "solid 1px #FFFFFF";
    return active ? "solid 2px #FF0022" : "1px solid transparent";
  }

  if (!category()) return <></>;

  categoryHub[category()!._id] = {
    setVer,
    setActive() {
      batch(() => {
        setCurrentCategory(index());
        setCategory0(category() as Category);
        setEditorMode0(ITEM_MODE.CATEGORY);
      });
    }
  }

  return (
    <GridElement
      x={category()?.layout?.left!} y={category()?.layout?.top!} cols={1} rows={1}
      style={{
        background: color(),
        display: "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "borderRadius": "5px",
        "border": border(),
        minWidth: '145px',
        minHeight: ((menu0()?.rows || 1) * 38 + 5) - 45,

      }}
      onClick={async (e) => {
        const switchItem = tempSwitchItem();
        if (switchItem?.active && switchItem.type === "category") {
          const switchCategory = (categories0().find(
            (p) => p.get()._id === switchItem._id
          )!).get();
          const currentCategory = (categories0().find(
            (p) => p.get()._id === category()?._id
          )!).get();
          if (!category() || !switchCategory?.layout) {
            setTempSwitchItem();
            return;
          }

          if (switchItem.mode === "swap") {
            batch(() => {
              switchCategory.layout!.left = peek(category()!.layout!, 'left');
              switchCategory.layout!.top = peek(category()!.layout!, 'top')
              currentCategory.layout!.left = peek(switchItem, 'left')!;
              currentCategory.layout!.top = peek(switchItem, 'top')!;
              console.log('swap success')
            })
          }

          setTempSwitchItem();

          await delay(50);
          setVer(ver => ver + 1);
          categoryHub[switchCategory._id]?.setVer((v: number) => v + 1);
          await delay(50);
          categoryHub[switchCategory._id]?.setActive();
          return;
        }
        batch(() => {
          setCurrentCategory(index());
          setCategory0(category() as Category);
          setEditorMode0(ITEM_MODE.CATEGORY);
        });
      }}
    >
      <span className="text-sm font-bold line-clamp-2"
            style={{
              fontSize: deviceSetting0()?.screenConfig?.categoryFontSize,
              lineHeight: `${deviceSetting0()?.screenConfig?.categoryLineHeight}px`
            }}>
        {category()?.name}
      </span>
    </GridElement>
  );
})

const RenderCategoryWrapper = ({ left, top }: { top: number, left: number }) => {
  const [ver, setVer] = useSignal<number>(0);
  useEffect(() => {
    if (ver() % 2 === 1) setVer(ver() + 1);
  }, [ver()]);
  return ver() % 2 === 0 && <RenderCategoryVertical top={top} left={left}
                                                    setVer={setVer} />;
}

export default RenderCategoryWrapper;