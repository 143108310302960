import PrinterSettingPlugin from '@printer-setting/PrinterSettingPlugin.tsx';
import { onEnter, PosScreen, router } from '@/pos/PosRouter.ts';
import { computed, selector, signal, useSignalEffect } from '@/react/core/reactive.ts';
import {
  groupPrinters0,
  kitchenGroupPrinters0, labelPrinters0,
  makeGroupPrintersAvailable,
  recheckGroupPrinters0,
  setGroupPrinterV
} from '@/data/GroupPrinterHub.ts';
import { GroupPrinter, PrinterDefault, type ThermalPrinter } from '@/data/GroupPrinter.ts';
import { dataLock } from '@/data/DataUtils.ts';
import uuid from 'time-uuid';
import _ from 'lodash';
import { LL0 } from '@/react/core/I18nService.tsx';
import type { PrinterGeneralSetting } from '@/data/PosSetting.ts';
import { memo } from 'react';
import { makeTaxCategoriesAvailable, taxCategories0 } from '@/data/TaxCategoryHub.ts';
import { rnHost } from "@/shared/webview/rnwebview.ts";
import { generalSetting0, mainScreen, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export enum PrinterMode {
  NORMAL,
  LABEL_PRINTER,
  RECHECK_PRINTER,
  CLIENT_RECEIPT,
  ADDITIONAL_SETTING,
}

export const FONT_SIZES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
export const TOP_MARGINS = [0, 1, 2, 3, 4];

export const PRINTER_TYPES = [
  {
    label: "Network Printer",
    value: "ip"
  },
  {
    label: "USB",
    value: "usb"
  },
  {
    label: "Bluetooth",
    value: "bluetooth"
  },
  {
    label: "Integrate",
    value: "integrate"
  },
  {
    label: "Serial Port",
    value: "serialPort"
  },
  {
    label: "Star Micronics",
    value: "star"
  },
]

export const INTEGRATE_SDKS = [{
  label: "Sunmi v2",
  value: "v2"
}, {
  label: "Sunmi v3",
  value: "v3"
}]

export const additionalSetting0 = computed(() => posSetting0()?.printerGeneralSetting)
export const [printerSettingMode, setPrinterSettingMode] = signal<PrinterMode>(PrinterMode.NORMAL)

export const [selectedGroupPrinterId, setSelectedGroupPrinterId] = signal<string | undefined>();
export const selectedGroupPrinter = computed(() => groupPrinters0().find(groupPrinter => groupPrinter._id === selectedGroupPrinterId()))
export const isSelectedGroupPrinter = selector(selectedGroupPrinter, (p1: string, p2: GroupPrinter) => p1 === p2?._id);
export const [currentPrinterIndex, setCurrentPrinterIndex] = signal<number>(0);
export const currentPrinter = computed(() => {
  if (selectedGroupPrinter()) return selectedGroupPrinter()!.printers[currentPrinterIndex()]
})
export const isLabelPrinterEnable = computed<boolean>(() => {
  return !!labelPrinters0()?.printers[0]?.printerType;
})

//@ts-ignore
window.currentPrinter = currentPrinter;
export const getInfoFor = (field: keyof PrinterGeneralSetting) => {
  const s = LL0().settings
  const s1 = LL0().settings.generalSetting
  let title = _.get(s, field)?.() || _.get(s1, field)?.()
  let content = _.get(s, `${field}Explain`)?.() || title

  if (field === "dateTimeFormat") {
    title = s.useDatetimeFormat({datetimeFormat: "HH:mm"})
    content = s.useDatetimeFormat({datetimeFormat: "HH:mm"})
  }

  if (!content || !title) throw new Error(`Missing translation for "${field}"`)
  return [title, content] as const
}

export const createNewGroupPrinter = async (type: "kitchen" | "recheck", opts?: Partial<GroupPrinter>) => {
  const initName = type === 'recheck' ? `Recheck ${recheckGroupPrinters0()?.length + 1}`
    : `New Printer ${kitchenGroupPrinters0()?.length + 1}`
  await GroupPrinter.insert({
    _id: uuid(),
    type,
    name: initName,
    printers: [PrinterDefault],
    ...opts,
  });
  setGroupPrinterV(v => v + 1)
}

export const deleteCurrentGroupPrinter = async () => {
  userFLow(`delete current groupPrinter ${selectedGroupPrinter()?.name}`, {
    username: loginUser()?.name
  });
  await selectedGroupPrinter()?.doc?.remove();
  setGroupPrinterV(v => v + 1)
  setTimeout(() => {
    setSelectedGroupPrinterId(kitchenGroupPrinters0()[0]._id);
  }, 100);
}


export const printerSwitchProps = (type: keyof ThermalPrinter) => ({
  checked: !!currentPrinter()?.[type] || false,
  onChange: (e: any) => _.assign(currentPrinter(), {[type]: e.target.checked})
})

export const onBack = () => {
  userFLow(`go to main screen`, {
    username: loginUser()?.name
  });
  router.screen = mainScreen()
}

//DEFAULT TAXES

export const defaultPrinterTaxes = computed(() => {
  return taxCategories0().reduce((acc: Record<string, {dineIn?: number, takeAway?: number}>, curr, index) => {
    // split to get name of the product type: for example "food"
    const productName = curr.name?.split(":")[0];
    if (!productName) return acc;
    if (generalSetting0()?.taxType === "one") {
      //TODO: Make this multiple taxes
      if (index !== 0) return acc;
      _.merge(acc, {[productName]: { dineIn: curr.value, takeAway: curr.value }});
    } else {
      const type = curr.type?.split(":")[1];
      _.merge(acc, {[productName]: { [type]: curr.value }});
      // _.merge(acc, {[productName]: { [curr.type]: curr.value }});
    }
    return acc;
  }, {})
})

export const handleProductTypeChange = async(productType: string) => {
  const foundPrinterTaxes = defaultPrinterTaxes()[productType];
  _.assign(selectedGroupPrinter(), {productType, defaultDineInTax: foundPrinterTaxes?.dineIn,
    defaultTakeAwayTax: foundPrinterTaxes?.takeAway});

  //update printer immediately after the printer change
  //currentPrinter
  // const id = selectedGroupPrinter()?._id;
  // const printer = kitchenGroupPrinters0().find((p) => p._id === id);
  // const _products = await Product.find().exec();
  // const products = convertDocuments<Product>(_products, true, [], {debounce: 2000})
  // for (const product of products) {
  //   if (product.groupPrinter === id) {
  //     _.assign(product, {
  //       // groupPrinter: id,
  //       taxCategory: printer?.productType ? printer.productType + ":dineIn" : '',
  //       taxCategory2: printer?.productType ? printer.productType + ":takeAway" : '',
  //       tax: printer?.defaultDineInTax,
  //       tax2: printer?.defaultTakeAwayTax,
  //     })
  //   }
  // }
}

export const handlePrinterIncludeToggle = (groupPrinterId: string | undefined, currentPrinter: any)=> {
  const currentIncludes = currentPrinter()?.includes || [];
  const printerId = groupPrinterId;
  if (currentIncludes && _.includes(currentIncludes, printerId)) {
    _.assign(currentPrinter(), {
      includes: _.without(currentIncludes, printerId)
    });
  } else {
    _.assign(currentPrinter(), {
      includes: [...currentIncludes, printerId]
    });
  }
}

export const [usbPrinterDevices, setUsbPrinterDevices] = signal<string[]>([]);

const PrinterSettingView = () => {
  makeGroupPrintersAvailable();
  makeTaxCategoriesAvailable();


  useSignalEffect(async () => {
    await dataLock.acquireAsync();
    selectedGroupPrinter();
    setCurrentPrinterIndex(0);
    setSelectedGroupPrinterId(kitchenGroupPrinters0()?.[0]._id);
    setPrinterSettingMode(PrinterMode.NORMAL)
  })

  onEnter(PosScreen.PRINTER_SETTING, async () => {
    const devices = await rnHost.listUsbDevices();
    setUsbPrinterDevices(devices);
  })

  return <PrinterSettingPlugin />
}

export default memo(PrinterSettingView)
