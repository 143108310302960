import type { FunctionComponent } from 'react';
import OrderBurgerMenuButton from "@order-view/OrderBurgerMenuButton.tsx";
import PayPrintButtons from "@order-view/PayPrintButtons";
import { canGoBack, onBack, ScreenMode, screenMode, setScreenMode } from '@/react/OrderView/OrderView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { useContext } from 'react';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import clsx from "clsx";
import { isSmallSidebar } from "@/data/DeviceSettingHub.ts";

const OrderScreenTopNav: FunctionComponent = () => {
  const { onSplitCancel, onMoveCancel, splitEnable, moveEnable } = useContext(ItemFactoryContext);

  return (
    <div className="!z-50 self-stretch flex flex-col items-center justify-center gap-[8px] z-[1] text-left text-smi text-black-solid-black-880-1d1d26 font-mulish">
      {!splitEnable?.() && !moveEnable?.() && screenMode() === ScreenMode.ORDER ?
        <div className="self-stretch flex flex-row items-center pt-3 px-1 pb-0">
          <div className={clsx("flex-1 flex flex-row items-center justify-start", isSmallSidebar() ? "gap-1" : "gap-3")}>
            <div className="w-[40px] h-[40px]">
              <OrderBurgerMenuButton
                isExpanded={false}
                iconTooltipArrowUpIconWidth="10px"
                iconTooltipArrowUpIconHeight="9px"
                isSelected={false}
                showButtonText={false}
              />
            </div>
            <PayPrintButtons />
          </div>
          <div className="flex flex-row items-start justify-start gap-[8px] text-center text-sm text-white-solid-white-100-ffffff Tablet_768:gap-[4px] Tablet_768:pl-0 Tablet_768:box-border Tablet_600:gap-[4px] Mobile_480:gap-[4px] height_414:gap-[4px]">
            <div className="data-[disabled=true]:opacity-50 data-[disabled=true]:pointer-events-none rounded-81xl bg-palette-blue-style shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-9 overflow-hidden flex flex-row items-center justify-center py-0.5 px-[15px] box-border gap-[3px]"
                 onClick={onBack}
                 ref={makeRipple}
                 data-disabled={!canGoBack()}
            >
              <img
                className="relative w-[30px] h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
              <b className="relative leading-[17px] hidden">Button</b>
            </div>
          </div>
        </div>
        :
        <div className="!flex self-stretch hidden flex-row items-center justify-center pt-2 px-1 pb-0 text-center text-sm text-white-solid-white-100-ffffff">
          <div className="w-[80px] rounded-81xl [background:linear-gradient(180deg,_#ffda68,_#e3ac00)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
               onClick={() => {
                 if (screenMode() === ScreenMode.EDIT) {
                   setScreenMode(ScreenMode.ORDER)
                   return
                 }
                 if (splitEnable?.()) onSplitCancel?.()
                 else onMoveCancel?.()
               }}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover hidden"
              alt=""
              src="/ordervoucher-icon1@2x.png"
            />
            <b className="relative leading-[17px]">{screenMode() === ScreenMode.EDIT ? LL0().ui.ok().toUpperCase() : LL0().ui.cancel()}</b>
          </div>
        </div>
      }
    </div>
  );
};

export default OrderScreenTopNav;
