import { Fragment, type FunctionComponent } from 'react';
import { modifiers, onConfirmModifiers, resultModifiers, setResultModifiers } from '@/react/OrderView/OrderView.tsx';
import _ from 'lodash';
import { useSignal } from '@/react/core/reactive.ts';
import type { ModifierItem } from '@/data/Modifier.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { orderPricingLevelFactory } from "@/react/OrderView/OrderPricingLevel.logic.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type OrderGroupPopupType = {
  onClose?: () => void;
  onClearResult?: () => void;
};


const OrderGroupPopup: FunctionComponent<OrderGroupPopupType> = ({
  onClose,
  onClearResult,
}) => {

  const {getPricingModifierItem} = orderPricingLevelFactory();

  return (
    <div className="min-w-[400px] relative bg-white w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] mq600:p-2 mq600:gap-1 max-w-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div className="overflow-y-auto no-scrollbar self-stretch flex-1 flex flex-col items-start justify-start gap-[16px] z-[0]">
        {/*<b className="self-stretch relative">{LL0().article.drink()}</b>*/}
        {/*<div className="self-stretch relative text-smi text-black-solid-black-1000-000000">*/}
        {/*  {LL0().order.chooseOneOfTheFollowingOptions()}:*/}
        {/*</div>*/}
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-center text-sm text-black-solid-black-880-1d1d26">
          {_.compact(modifiers()).map((modifier, index) => (
            <Fragment key={index}>
              <span className={`text-[#0051C1]`}>{modifier?.name}</span>
              <div className={`flex flex-wrap w-full gap-[12px]`}>
                {modifier?.items?.map((item, i) => (
                  <div className="text-black-solid-black-1000-000000 rounded-10xs bg-gray-solid-gray-130-f4f4f4-200 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-[34px] flex flex-row items-center justify-center py-0 px-2.5 relative gap-[2px] min-w-[40px] min-h-[34px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1" key={i}
                       onClick={() => {
                         userFLow(`add modifier ${item.name}`, {
                           username: loginUser()?.name
                         });
                         if (modifier.selectOne) {
                           if (!modifier.mandatory && resultModifiers()[item._id!]) {
                             delete resultModifiers()[item._id!];
                             setResultModifiers({ ...resultModifiers() });
                             return;
                           }
                           const _id = _.keys(resultModifiers()).find(i => resultModifiers()[i].gid === modifier._id);
                           if (_id) {
                             delete resultModifiers()[_id];
                             setResultModifiers({ ...resultModifiers() });
                           }
                           setResultModifiers({
                             ...resultModifiers(), ...{
                               [item._id!]: {
                                 _id: item._id!,
                                 name: item.name!,
                                 gid: modifier._id!,
                                 price: getPricingModifierItem(item!),
                               }
                             }
                           });
                         } else {
                           if (!modifier.mandatory && resultModifiers()[item._id!]) {
                             delete resultModifiers()[item._id!];
                             setResultModifiers({ ...resultModifiers() });
                             return;
                           }
                           setResultModifiers({
                             ...resultModifiers(), ...{
                               [item._id!]: {
                                 _id: item._id!,
                                 type: item?.type,
                                 name: item.name!,
                                 gid: modifier._id!,
                                 price: getPricingModifierItem(item!)
                               }
                             }
                           });
                         }
                       }}>
                    {!!resultModifiers()[item?._id!] &&
                    <div className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                    }
                    <div className="z-[1] relative">{item?.name} - {LL3().format.currency(getPricingModifierItem(item) || 0)}</div>
                  </div>
                ))}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      <div className="self-stretch flex flex-col items-end justify-start pt-3 px-0 pb-0 z-[1] text-center text-sm text-white">
        <div className="rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
              onClick={() => {
                userFLow(`save add modifiers`, {
                  username: loginUser()?.name
                });
                onClose?.();
                const modifiers = _.values(resultModifiers()) as ModifierItem[];
                setResultModifiers({});
                onConfirmModifiers(modifiers);
              }}>
          <b className="relative">{LL0().ui.save()}</b>
        </div>
      </div>
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={()=>{
          userFLow(`close order group popup`, {
            username: loginUser()?.name
          });
          onClose?.()
          onClearResult?.()
        }}
      />
    </div>
  );
};

export default OrderGroupPopup;