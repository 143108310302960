import type { FunctionComponent } from "react";
import { ColorDarken } from '@/react/core/ColorDarken.tsx';

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { menu0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { categories1, setActiveCategory } from "@/react/OrderView/OrderView.tsx";

interface RenderCategoryProps {
  row: number;
}

const RenderCategory: FunctionComponent<RenderCategoryProps> = ({ row }) => {
  const categories = categories1() || []

  const itemsPerRow = Math.ceil(categories.length / row);
  const renderRow = (items: typeof categories, rowIndex: number) => {
    return (
      <div className="flex flex-row gap-[4px]" key={rowIndex}
      >
        {items.map((category, index) => (
          <ColorDarken color={category?.layout?.color || '#FFFFFF'} key={category._id}>
            {(color, setClicked) => (
              <div
                className="flex items-center rounded-[4px] justify-center"
                onClick={() => {
                  setActiveCategory(category._id)
                  setClicked(true)
                }}
                style={{
                  height: ((deviceSetting0()?.screenConfig?.categoryHeight || 0)),
                  minHeight: ((menu0()?.rows || 1) * 38 + 5) - 45,
                  background: color,
                  minWidth: 120
                }}
              >
                <div
                  style={{
                    fontSize: deviceSetting0()?.screenConfig?.categoryFontSize,
                    lineHeight: `${deviceSetting0()?.screenConfig?.categoryLineHeight}px`,
                  }}
                  className="flex-1 relative font-semibold [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
                  {category.name}
                </div>
              </div>
            )}
          </ColorDarken>
        ))}
      </div>
    );
  };

  return (
    <div
      className="overflow-x-scroll no-scrollbar flex flex-col w-screen gap-[4px]"
    >
      {Array.from({ length: row }, (_, index) => {
        const start = index * itemsPerRow;
        const end = start + itemsPerRow;
        const slicedCategories = categories.slice(start, end);
        return slicedCategories.length > 0 ? renderRow(slicedCategories, index) : null;
      })}
    </div>
  );
};


export default RenderCategory;
