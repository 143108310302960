import React, { Fragment, type FunctionComponent, memo } from "react";
import GridLayout from "@/react/core/GridLayout.tsx";
import { columnsCategoryArr, rowsCategoryArr } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import RenderCategory from "@/react/EditMenuView/Vertical/RenderCategoryVertical.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";


export type CategoryWrapperType = {
  className?: string;
};

const CategoryWrapper: FunctionComponent<CategoryWrapperType> = memo(
  ({ className = "" }) => {
    return (
      // <div
      //   className={`overflow-y-auto flex flex-col items-start justify-center text-center text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      // >
      //   <div className="w-[1074px] h-[88px] relative shrink-0 grid grid-rows-[repeat(2,40px)] grid-cols-[repeat(auto-fit,146px)] [grid-row-gap:4px] [grid-column-gap:4px] sm:grid sm:grid-rows-[repeat(2,40px)] sm:grid-cols-[repeat(auto-fit,120px)] sm:[grid-row-gap:4px] sm:[grid-column-gap:4px] mq600:grid mq600:grid-rows-[repeat(2,40px)] mq600:grid-cols-[repeat(auto-fit,120px)] mq600:[grid-row-gap:4px] mq600:[grid-column-gap:4px] mq432:grid mq432:grid-rows-[repeat(2,40px)] mq432:grid-cols-[repeat(auto-fit,120px)] mq432:[grid-row-gap:4px] mq432:[grid-column-gap:4px]">
      //     <OrderScreenCategoryItem showIsSelected label="Favorite" />
      //
      //   </div>
      // </div>
      <>
        <GridLayout rows={2} cols={'auto-fill'} colGap={5} rowGap={5}
                    className="h-full flex-grow self-center overflow-x-scroll no-scrollbar"
                    style={{
                      // height: (2) * 38 - 5,
                      gridTemplateColumns: 'repeat(auto-fill, minmax(145px, 145px))',
                      height: (deviceSetting0()?.screenConfig?.categoryHeight || 1) * 2 + 5,
                    }}
        >
          {rowsCategoryArr().map((item: number, top: number) => (<Fragment key={top}>
            {columnsCategoryArr().map((_item: number, left: number) => (
              <RenderCategory top={top} left={left} key={`${top}/${left}`} />
            ))}
          </Fragment>))}
        </GridLayout>

      </>
    );
  }
);

export default CategoryWrapper;