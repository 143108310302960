import { FunctionComponent } from "react";
import VerticalEditMenuToolbar from "./VerticalEditMenuToolbar.tsx";
import VerticalEditNormalItemForm from "./VerticalEditNormalItemForm.tsx";
import VerticalEditModifierForm from "./VerticalEditModifierForm.tsx";
import VerticalEditNonFixedItemForm from "./VerticalEditNonFixedItemForm.tsx";
import VerticalEditCategoryForm from "./VerticalEditCategoryForm.tsx";
import { editorMode0, ITEM_MODE } from "@/react/EditMenuView/EditMenuView.tsx";
import { product0, setEditMenuEditFormOpen } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import VerticalEditSectionTitleItemForm from "@vertical-edit-menu/VerticalEditSectionTitleItemForm.tsx";
import { setOpenEditMenuForm } from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";


export type EditMenuFormType = {
  className?: string;
};

const VerticalEditMenuForm: FunctionComponent<EditMenuFormType> = ({
  className = "",
}) => {
  return (
    <div
      className={`no-scrollbar self-stretch overflow-y-auto flex flex-col items-center justify-start gap-3 text-left text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
    >
      {( !generalSetting0()?.useMenuV2) &&
        <VerticalEditMenuToolbar onClose={() => {
          setEditMenuEditFormOpen(false)
          setOpenEditMenuForm(false)
        }} />
      }
      <div className="no-scrollbar self-stretch overflow-y-auto shrink-0 flex flex-col items-start justify-start text-blue-solid-blue-500-0051c1">
        {editorMode0() === ITEM_MODE.CATEGORY &&
          (<VerticalEditCategoryForm />)
        }
        {
          editorMode0() === ITEM_MODE.ITEM &&
          <>
            {
              product0()?.layout?.type === "Extra" ?
                <VerticalEditModifierForm /> :
                product0()?.layout?.type === "Article" ?
                  <VerticalEditNormalItemForm /> : <VerticalEditNonFixedItemForm />
            }
          </>
        }
        {editorMode0() === ITEM_MODE.SECTION && <VerticalEditSectionTitleItemForm />}

      </div>
    </div>
  );
};

export default VerticalEditMenuForm;
