import _ from 'lodash'

import { computed } from '@/react/core/reactive'
import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";

export const orderConfig = {
  /**
   * isNetPricesBase = true -> price exclude tax, false -> price include tax
   * case exclude tax -> serviceFee exclude tax
   * case include tax -> serviceFee include tax
   */
  isNetPriceBase: false,
  force: false,
  beginHour: '06:00',
  sumPrecision: 2,
  discountPrecision: 4,
  trackCancelBeforePrint: false,
  discountBeforeTax: true,
  distributeDiscountToItems: true,
  useTaxComponents: false
}

export const useServiceFeeBeforeTax = computed(() => isQuebecSrmEnabled())

export const roundNumber = (value: number, precision?: number) => {
  const pre = value < 0 ? -1 : 1;
  const val =  _.round(Math.abs(value), precision || orderConfig.sumPrecision || 2)
  return pre * val;
}
