import React, { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from "react";
import { signal, useSignal } from "@/react/core/reactive.ts";
import { clsx } from "clsx";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import { checkItemBeforeSplit, removeDiscountBeforeSplit } from "@/react/OrderView/order-splitbill-utils.ts";
import {
  setIsPayment, setIsTip,
  setIsViewBill,
  setOpenSplitBillPaymentV
} from "@/react/VerticalPayment2View/VerticalPayment2View.tsx";
import { setPrintKitchenPopUpOpen } from "@vertical-order-view/OrderScreenBurgerMenu.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  handleClearDiscountOrder,
  handleOrderDiscount,
  orderDiscount,
  paymentContext0, scopeOrder0, setIsAddCustomer,
  setMultiPayment0,
  setOrderDiscount
} from "@/react/PaymentView/PaymentView.tsx";
import { setIsOpenVoucherPopup } from "@vertical-order-view/OrderScreenPlugin2.tsx";
import ChooseSeatPrintPopUp from "@vertical-payment2/ChooseSeatPrintPopUp.tsx";
import PortalPopup from "@payment/PortalPopup.tsx";
import PaymentRedbillInfoPlugin from "@vertical-payment2/PaymentRedbillInfoPlugin.tsx";
import DiscountVertical from "@/react/core/DiscountVertical.tsx";
import { isEditButton } from "@order-view/DiscountPopup/DiscountPopupItem.tsx";
import PortalDrawer from "@edit-menu/Popups/PortalDrawer.tsx";
import { OrderStatus } from "@/pos/OrderType.ts";
import { toast } from "react-toastify";
import PaymentSelectTerminalPopu from "@payment/PaymentSelectTerminalPopu.tsx";
import { generalSetting0, isQuebecSrmEnabled, printerGeneralSetting0 } from "@/data/PosSettingsSignal.ts";
import { srmTransactionLogic } from "@/srm/transaction.logic.ts";
import { FormControlLabel, Switch } from "@mui/material";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import _ from "lodash";
import { onGroupBill } from "@/react/OrderView/OrderView.tsx";

export type PaymentMoreButtonVType = {
  className?: string;
};

export const [togglePaymentMore, setTogglePaymentMore] = signal(false)


const PaymentMoreButtonV: FunctionComponent<PaymentMoreButtonVType> = ({
  className = "",
}) => {
  const [splitBillClicked, setSplitBillClicked] = useState(false);
  const [viewBillClicked, setViewBillClicked] = useState(false);
  const { order0, splitEnable, onSeatToggle, onMoveToggle, onSplitCancel } = useContext(ItemFactoryContext)
  const [isDiscountPopupVertical, setDiscountPopupVertical] = useSignal<boolean | number>(false)
  const [discountClicked, setDiscountClicked] = useState(false);
  const [voucherClicked, setVoucherClicked] = useState(false);
  const [addTipClicked, setAddTipClicked] = useState(false);
  const [addCustomerClicked, setAddCustomerClicked] = useState(false);
  const [isAddCustomerPopUpOpen, setIsAddCustomerPopUpOpen] = useState<boolean>(false)
  const [terminalClicked, setTerminalClicked] = useState<boolean>(false)
  const [isPaymentSelectTerminalPopuOpen, setPaymentSelectTerminalPopuOpen] =
    useState(false);

  const handleSplitBillClick = () => {
    if (order0?.()?.status === OrderStatus.PAID) {
      toast(`${LL0().msgBox.printInvoice()}`, { type: 'info', autoClose: 500 })
      return
    }
    setSplitBillClicked(true);
    if (checkItemBeforeSplit(order0?.()!)) {
      removeDiscountBeforeSplit(order0?.()!)
      onSeatToggle?.()
      setOpenSplitBillPaymentV(true)
    } else {
      setPrintKitchenPopUpOpen(true)
    }
    setTogglePaymentMore(false)
    setSplitBillClicked(false)
  };

  const handleViewBillClick = () => {
    setViewBillClicked(true);
    setIsViewBill(true);
    setTogglePaymentMore(false)
    setViewBillClicked(false)
  };


  const handleAddCustomerClick = () => {
    setAddCustomerClicked(true);
    setTogglePaymentMore(false)
    setIsAddCustomerPopUpOpen(true)
    setIsAddCustomer(true)
    setAddCustomerClicked(false)
  }

  const openDiscountPopup = useCallback(() => {
    setDiscountPopupVertical(0)
    setTogglePaymentMore(false)
  }, []);
  const closeDiscountPopup = useCallback(() => {
    setDiscountPopupVertical(false);
  }, []);

  const handleDiscountClicked = () => {
    if (order0?.()?.status === OrderStatus.PAID) {
      toast(`${LL0().msgBox.printInvoice()}`, { type: 'info', autoClose: 500 })
      return
    }
    setDiscountClicked(true);
    openDiscountPopup()
    setDiscountClicked(false)
  };

  const handleVoucherClicked = () => {
    if (order0?.()?.status === OrderStatus.PAID) {
      toast(`${LL0().msgBox.printInvoice()}`, { type: 'info', autoClose: 500 })
      return
    }
    setVoucherClicked(true);
    setIsOpenVoucherPopup(true);
    setTogglePaymentMore(false)
    setVoucherClicked(false)
  };

  const handleAddTipClicked = () => {
    if (order0?.()?.status === OrderStatus.PAID) {
      toast(`${LL0().msgBox.printInvoice()}`, { type: 'info', autoClose: 500 })
      return
    }
    setAddTipClicked(true);
    setMultiPayment0(false);
    setIsPayment(false);
    setIsTip(true);
    setAddTipClicked(false)
  };

  const refClickOutside = useRef<HTMLDivElement>(null);

  const openPaymentSelectTerminalPopu = useCallback(() => {
    setPaymentSelectTerminalPopuOpen(true);
  }, []);

  const closePaymentSelectTerminalPopu = useCallback(() => {
    setPaymentSelectTerminalPopuOpen(false);
  }, []);


  const handleTerminalClick = () => {
    setTerminalClicked(true);
    setTogglePaymentMore(false)
    openPaymentSelectTerminalPopu();
    setTerminalClicked(false)
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (refClickOutside.current && !refClickOutside.current.contains(event.target as Node)) {
        setTogglePaymentMore(false)
      }
    };
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [togglePaymentMore()]);
  return (
    <div
      className={`flex flex-col items-start justify-start gap-[8px] text-left text-sm text-black-solid-black-900-1e1e23 font-mulish-button-name-bold ${className}`}
      ref={refClickOutside}
    >
      <div className="flex flex-col items-start justify-start relative">
        <div onClick={() => {
          setTogglePaymentMore(!togglePaymentMore())
        }}
             className={
               clsx(togglePaymentMore() ? "shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(180deg,_#2196f3,_#0a80de)] h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border z-[0] mq432:h-[34px]" :
                 "shadow-[0px_0px_6px_rgba(0,_0,_0,0.22)] rounded-81xl [background:linear-gradient(180deg,#2196f3,_#0a80de)] h-10 overflow-hidden shrink-0 flex z-[1] flex-row items-center justify-center py-0.5 px-2.5 box-border mq432:h-[34px]"
               )
             }
        >
          <img
            className="w-[30px] relative h-[30px] object-cover mq432:w-[26px] mq432:h-[26px]"
            alt=""
            src={ togglePaymentMore() ? "/icon-payment-menu-explain-icon@2x.png" : "/icon-payment-menu-icon@2x.png"}
          />
        </div>
        { togglePaymentMore() &&
          <div className="!m-[0] absolute top-[40px] right-[0px] flex flex-col items-start justify-start z-[1] mq482:h-60">
            <div className="self-stretch flex flex-col items-end justify-start py-0 pr-[19px] pl-0">
              <img
                className="w-2.5 relative h-[9px] object-cover"
                alt=""
                src="/icontooltip-arrow-up-icon@2x.png"
              />
            </div>
            <div
              className="w-48 shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff overflow-y-auto flex flex-col items-start justify-start py-1 px-2 box-border gap-[6px]">
              {!order0?.()?.seatMode && (
                <>
                  {/*<div*/}
                  {/*  className="w-[148px] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff overflow-y-auto flex flex-col items-start justify-start py-1 px-2 box-border gap-[6px]">*/}
                  <div
                    className={`self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px] ${order0?.().status === OrderStatus.PAID ? 'opacity-70' : ''}`}
                    onClick={handleDiscountClicked}
                  >
                    <div
                      className={`border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0] ${discountClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]'}`} />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/iconpayment-verticaldiscount-icon-ver1@2x.png"
                    />
                    <b
                      className={`relative leading-[17px] sm:text-smi mq600:text-smi mq432:text-smi ${discountClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]'}`}>
                      {LL0().settings.happyHour.discount()}
                    </b>
                  </div>
                  <div
                    className={`self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px] ${order0?.().status === OrderStatus.PAID ? 'opacity-70' : ''}`}
                    onClick={handleVoucherClicked}
                  >
                    <div
                      className={`border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0] ${voucherClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]'}`}
                    />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/iconpayment-verticalvoucher-icon-ver1@2x.png"
                    />
                    <b
                      className={`relative leading-[17px] sm:text-smi mq600:text-smi mq432:text-smi ${voucherClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]'}`}>
                      {LL0().printing.voucher()}
                    </b>
                  </div>
                  <div
                    className={`self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px] ${order0?.()?.status === OrderStatus.PAID ? 'opacity-70' : ''}`}
                    onClick={handleAddTipClicked}
                    ref={makeRipple}
                  >
                    <div
                      className={`border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0] ${addTipClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]'}`} />
                    <img
                      className="w-[30px] relative h-[30px] object-cover z-[1]"
                      alt=""
                      src="/iconpayment-verticaltip-icon-icon-ver1@2x.png"
                    />
                    <b
                      className={`relative leading-[17px] sm:text-smi mq600:text-smi mq432:text-smi ${addTipClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]'}`}>
                      {LL0().settings.addTip()}
                    </b>
                  </div>
                </>
              )}
              {!order0?.()?.seatMode && (<div
                className={`self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px] ${order0?.()?.status === OrderStatus.PAID ? 'opacity-70' : ''}`}
                onClick={handleSplitBillClick}
                ref={makeRipple}
              >
                <div
                  className={clsx('border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]', splitBillClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]')} />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/iconnew-versionsplit-bill-icon-ver1@2x.png"
                />
                <b
                  className={clsx('relative leading-[17px] z-[2] sm:text-smi mq600:text-smi mq432:text-smi',  splitBillClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]')}>
                  {LL0().order.splitOrder()}
                </b>
              </div>)}
              <div
                className="self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px]"
                onClick={handleViewBillClick}
                ref={makeRipple}
              >
                <div
                  className={clsx('border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]', viewBillClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]')} />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/iconordereye-icon-ver1@2x.png"
                />
                <b
                  className={clsx('relative leading-[17px] z-[2] sm:text-smi mq600:text-smi mq432:text-smi',  viewBillClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]')}>
                  {LL0().order.viewBill()}
                </b>
              </div>
              {/*<>fixme: add customer</>*/}
              {!order0?.()?.seatMode && (<div
                className="self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px]"
                onClick={handleAddCustomerClick}
                ref={makeRipple}
              >
                <div
                  className={clsx('border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]', addCustomerClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]')} />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/icon-add-customer.png"
                />
                <b
                  className={clsx('relative leading-[17px] z-[2] sm:text-smi mq600:text-smi mq432:text-smi',  addCustomerClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]')}>
                  {LL0().onlineOrder.addCustomer()}
                </b>
              </div>)}
              <div
                className="self-stretch rounded flex flex-row items-center justify-start py-0.5 pr-1 pl-0.5 relative gap-[6px]"
                onClick={handleTerminalClick}
                ref={makeRipple}
              >
                <div
                  className={clsx('border-solid border-[1px] border-blue-solid-blue-350-2196f3 w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]', terminalClicked ? 'bg-blue-solid-blue-350-2196f3' : 'bg-[#D0E5FF]')} />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/terminal-setup.png"
                />
                <b
                  className={clsx('relative leading-[17px] z-[2] sm:text-smi mq600:text-smi mq432:text-smi',  viewBillClicked ? 'text-white-solid-white-100-ffffff z-[3]' : 'z-[2]')}>
                  {LL0().dashboard.terminalSetup()}
                </b>
              </div>
            </div>
          </div>
        }
      </div>
      {isAddCustomerPopUpOpen && (
        <PortalDrawer
          overlayColor="rgba(0, 0, 0, 0.5)"
          placement="Bottom"
          onOutsideClick={() => setIsAddCustomerPopUpOpen(false)}
        >
          <PaymentRedbillInfoPlugin onClose={() => setIsAddCustomerPopUpOpen(false)} />
        </PortalDrawer>
      )}
      {isDiscountPopupVertical() === 0 &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setDiscountPopupVertical(false)}
        >
          <DiscountVertical
            showClose={true}
            showDiscountForm={true}
            type={"order"}
            isEdit={isEditButton()}
            order={order0?.()}
            discount={orderDiscount()}
            setDiscount={setOrderDiscount}
            onApply={(d) => handleOrderDiscount(d, order0?.())}
            onClear={handleClearDiscountOrder}
            onClose={() => setDiscountPopupVertical(false)}
          />
        </PortalPopup>
      }
      {isPaymentSelectTerminalPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePaymentSelectTerminalPopu}
        >
          <PaymentSelectTerminalPopu onClose={closePaymentSelectTerminalPopu} />
        </PortalPopup>
      )}
    </div>
  );
};

export default PaymentMoreButtonV;
