import type { FunctionComponent } from 'react'
import { FormControlLabel, Switch } from "@mui/material";
import { LL0 } from "@/react/core/I18nService.tsx";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion-seats.tsx';
import { useSignal } from "@/react/core/reactive.ts";
import { isSmallSidebar, isVerticalMenu } from "@/data/DeviceSettingHub.ts";
import _ from 'lodash'
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  decreasePropertyValuesScreenConfig,
  increasePropertyValuesScreenConfig
} from "@/react/OrderView/OrderView.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { MenuDirection } from "@/data/MenuDirection.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type EditMenuFormType = {
  isEditScreen?: boolean;
};

const EditMenuForm: FunctionComponent<EditMenuFormType> = ({
  isEditScreen,
}) => {
  const [editScreenTab, setEditScreenTab] = useSignal<string>('1')

  return (
    isEditScreen && (
      <div className="!flex !w-full gap-2 w-[294px] hidden flex-col items-center justify-start gap-[16px] text-left text-sm text-text-color-black-1e1e23 font-mulish">
        <Accordion type="single" className={'w-full flex flex-col gap-2'}
                   collapsible
                   value={editScreenTab()}
                   onValueChange={val => {
                     setEditScreenTab(val)
                   }}
        >
          <AccordionItem key={'1'} value={'1'} onClick={() => {
            userFLow(`set edit screen tab category `, {
              username: loginUser()?.name
            });
            setEditScreenTab('1')
          }}>
            <div className="self-stretch overflow-y-auto flex flex-col items-center justify-start gap-[4px]">
              <AccordionTrigger text={LL0().article.category()} isPaid={false}>

              </AccordionTrigger>
              <AccordionContent className={'w-full'}>
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-1">
                  <div className="!border-0 self-stretch rounded-md bg-white overflow-y-auto flex flex-col items-center justify-start p-2.5 gap-[12px] border-[1px] border-solid border-lightgray-200">
                    <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
                      <b className="relative md:text-xs">{LL0().article.category()}:</b>
                      <div className="flex flex-row items-start justify-start gap-[8px]">
                        <div className="rounded-10xs h-[30px] flex flex-row items-center justify-center py-0 px-2.5 box-border relative gap-[8px]"
                             ref={makeRipple}
                             onClick={() => {
                               userFLow(`set horizontal`, {
                                 username: loginUser()?.name
                               });
                               _.assign(deviceSetting0()?.screenConfig, { menuDirection: MenuDirection.HORIZONTAL })
                             }}
                        >
                          {!isVerticalMenu() &&
                            <div className="w-[calc(100%_+_1px)] absolute my-0 mx-[!important] h-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] rounded-10xs bg-gainsboro box-border z-[0] border-[1px] border-solid border-gainsboro" />
                          }
                          <img
                            className="w-6 relative h-6 object-cover z-[1]"
                            alt=""
                            src="/iconmode-horizontal-icon@2x.png"
                          />
                        </div>
                        <div className="rounded-10xs h-[30px] flex flex-row items-center justify-center py-0 px-2.5 box-border relative gap-[8px]"
                             ref={makeRipple}
                             onClick={() => {
                               userFLow(`set vertical`, {
                                 username: loginUser()?.name
                               });
                               _.assign(deviceSetting0()?.screenConfig, { menuDirection: MenuDirection.VERTICAL })
                             }}
                        >
                          {isVerticalMenu() &&
                            <div className="!flex w-[calc(100%_+_1px)] absolute my-0 mx-[!important] h-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] rounded-10xs bg-gainsboro box-border hidden z-[0] border-[1px] border-solid border-gainsboro" />
                          }
                          <img
                            className="w-6 relative h-6 object-cover z-[1]"
                            alt=""
                            src="/iconmode-vertical-icon@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    {/*<FormControlLabel*/}
                    {/*  className="self-stretch"*/}
                    {/*  label={LL0().order.oneLine()}*/}
                    {/*  control={<Switch color="primary"*/}
                    {/*                   checked={deviceSetting0()?.screenConfig?.oneLine || false}*/}
                    {/*                   onChange={(event) => _.assign(deviceSetting0()?.screenConfig, { oneLine: event.target.checked })}*/}
                    {/*  />}*/}
                    {/*/>*/}
                    {/*<FormControlLabel*/}
                    {/*  className="self-stretch"*/}
                    {/*  label={LL0().order.oneLineAndShorten()}*/}
                    {/*  control={<Switch color="primary"*/}
                    {/*                   checked={deviceSetting0()?.screenConfig?.oneLineShorten || false}*/}
                    {/*                   onChange={(event) => _.assign(deviceSetting0()?.screenConfig, { oneLineShorten: event.target.checked })}*/}
                    {/*  />}*/}
                    {/*/>*/}
                    {isVerticalMenu() ?
                      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                        <b className="relative md:text-xs">{LL0().productViewSetting.height()}</b>
                        <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
                          <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
                               ref={makeRipple}
                               onClick={() => {
                                 decreasePropertyValuesScreenConfig("categoryHeight", 4, 42)
                               }}
                          >
                            <img
                              className="w-6 relative h-6 object-cover"
                              alt=""
                              src="/iconsteper-minus@2x.png"
                            />
                          </div>
                          <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.categoryHeight}px</b>
                          <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
                               ref={makeRipple}
                               onClick={() => increasePropertyValuesScreenConfig("categoryHeight", 4)}
                          >
                            <img
                              className="w-6 relative h-6 object-cover"
                              alt=""
                              src="/iconstepper-plus@2x.png"
                            />
                          </div>
                        </div>
                      </div>
                      :
                      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                        <b className="relative md:text-xs">{LL0().productViewSetting.width()}</b>
                        <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
                          <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
                               ref={makeRipple}
                               onClick={() => decreasePropertyValuesScreenConfig("categoryWidth", 2, 80)}
                          >
                            <img
                              className="w-6 relative h-6 object-cover"
                              alt=""
                              src="/iconsteper-minus@2x.png"
                            />
                          </div>
                          <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.categoryWidth}px</b>
                          <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
                               ref={makeRipple}
                               onClick={() => increasePropertyValuesScreenConfig("categoryWidth", 2)}
                          >
                            <img
                              className="w-6 relative h-6 object-cover"
                              alt=""
                              src="/iconstepper-plus@2x.png"
                            />
                          </div>
                        </div>
                      </div>
                    }
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                      <b className="relative md:text-xs">{LL0().productViewSetting.fontSize()}</b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("categoryFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.categoryFontSize}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("categoryFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                      <b className="relative md:text-xs">{LL0().productViewSetting.lineHeight()}</b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("categoryLineHeight", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.categoryLineHeight}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("categoryLineHeight", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </div>
          </AccordionItem>
          <AccordionItem key={'2'} value={'2'} onClick={() => {
            userFLow(`set edit screen tab product`, {
              username: loginUser()?.name
            });
            setEditScreenTab('2')
          }}>
            <div className="self-stretch overflow-y-auto flex flex-col items-center justify-start gap-[4px]">
              <AccordionTrigger text={LL0().article.product()} isPaid={false}>
              </AccordionTrigger>
              <AccordionContent className={'w-full'}>
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-1">
                  <div className="!border-0 self-stretch rounded-md bg-white overflow-y-auto flex flex-col items-center justify-start p-2.5 gap-[12px] border-[1px] border-solid border-lightgray-200">
                    {/*<div className="self-stretch flex flex-col items-start justify-start gap-[8px]">*/}
                    {/*  <b className="relative md:text-xs">{LL0().order.itemCardHeight()}</b>*/}
                    {/*  <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">*/}
                    {/*    <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"*/}
                    {/*         ref={makeRipple}*/}
                    {/*         onClick={() => decreasePropertyValuesScreenConfig("itemCardHeight", 2)}*/}
                    {/*    >*/}
                    {/*      <img*/}
                    {/*        className="w-6 relative h-6 object-cover"*/}
                    {/*        alt=""*/}
                    {/*        src="/iconsteper-minus@2x.png"*/}
                    {/*      />*/}
                    {/*    </div>*/}
                    {/*    <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.itemCardHeight}px</b>*/}
                    {/*    <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"*/}
                    {/*         ref={makeRipple}*/}
                    {/*         onClick={() => increasePropertyValuesScreenConfig("itemCardHeight", 2)}*/}
                    {/*    >*/}
                    {/*      <img*/}
                    {/*        className="w-6 relative h-6 object-cover"*/}
                    {/*        alt=""*/}
                    {/*        src="/iconstepper-plus@2x.png"*/}
                    {/*      />*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                      <b className="relative md:text-xs">{LL0().productViewSetting.fontSize()}</b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("productFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.productFontSize}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("productFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                      <b className="relative md:text-xs">{LL0().productViewSetting.lineHeight()}</b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-gray-divider-2">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("textLineHeight", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative md:text-xs">{deviceSetting0()?.screenConfig?.textLineHeight}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-divider-1"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("textLineHeight", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    {/*<FormControlLabel*/}
                    {/*  className="self-stretch"*/}
                    {/*  label={LL0().order.hideTitleName()}*/}
                    {/*  control={<Switch color="primary" checked={deviceSetting0()?.screenConfig?.hideTitleName || false}*/}
                    {/*                   onChange={(event) => _.assign(deviceSetting0()?.screenConfig, { hideTitleName: event.target.checked })}*/}
                    {/*  />}*/}
                    {/*/>*/}
                    {/*<FormControlLabel*/}
                    {/*  className="self-stretch"*/}
                    {/*  label={LL0().order.scrollableLayout()}*/}
                    {/*  control={*/}
                    {/*    <Switch color="primary" checked={deviceSetting0()?.screenConfig?.scrollableLayout || false}*/}
                    {/*            onChange={(event) => _.assign(deviceSetting0()?.screenConfig, { scrollableLayout: event.target.checked })}*/}
                    {/*    />}*/}
                    {/*/>*/}
                  </div>
                  <img
                    className="!hidden w-6 relative h-6 object-contain"
                    alt=""
                    src="/iconblue-colapse-arrow-up@2x.png"
                  />
                  <img
                    className="w-6 relative h-6 object-contain hidden"
                    alt=""
                    src="/iconblue-colapse-arrow-down@2x.png"
                  />
                </div>
              </AccordionContent>
            </div>
          </AccordionItem>
          <AccordionItem key={'3'} value={'3'} onClick={() => {
            userFLow(`set edit screen tab side bar`, {
              username: loginUser()?.name
            });
            setEditScreenTab('3')
          }}>
            <div className="self-stretch overflow-y-auto flex flex-col items-center justify-start gap-[4px]">
              <AccordionTrigger text={LL0().productViewSetting.sidebar()} isPaid={false}>
              </AccordionTrigger>
              <AccordionContent className={'w-full'}>
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-1">
                  <div className="!border-0 self-stretch rounded-md bg-white overflow-y-auto flex flex-col items-center justify-start p-2.5 border-[1px] border-solid border-gray-solid-gray-400-c9cbcc">
                    <FormControlLabel
                      className="self-stretch"
                      label={LL0().order.smallSidebar()}
                      control={<Switch color="primary"
                                       checked={isSmallSidebar() || false}
                                       onChange={(event) => _.assign(deviceSetting0()?.screenConfig, { smallSidebar: event.target.checked })}
                      />}
                    />
                  </div>
                </div>
              </AccordionContent>
            </div>
          </AccordionItem>
        </Accordion>

      </div>
    )
  );
};

export default EditMenuForm;
