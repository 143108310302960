import { FunctionComponent, useState, useCallback } from "react";
import { Switch, FormControlLabel } from "@mui/material";
import EditMenuCustomDishPopup from "./EditMenuCustomDishPopup";
import PortalPopup from "./PortalPopup";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import PortalDrawer from "@edit-menu/Popups/PortalDrawer.tsx";
import EditMenuEditScreen from "@vertical-edit-menu/EditMenuEditScreen.tsx";
import { onGroupBill } from "@/react/OrderView/OrderView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export type EditMenuBurgerButtonVType = {
  className?: string;
};

const EditMenuBurgerButtonV: FunctionComponent<EditMenuBurgerButtonVType> = ({
  className = "",
}) => {
  const [showIsExpanded, setShowIsExpanded] = useState(false)
  const [isEditMenuCustomDishPopupOpen, setEditMenuCustomDishPopupOpen] =
    useState(false);
  const [isEditScreenOpen, setEditScreenOpen] =
    useState(false);

  const openEditMenuCustomDishPopup = useCallback(() => {
    setEditMenuCustomDishPopupOpen(true);
    setShowIsExpanded(false)
  }, []);

  const closeEditMenuCustomDishPopup = useCallback(() => {
    setEditMenuCustomDishPopupOpen(false);
  }, []);

  const openEditScreenPopup = useCallback(() => {
    setShowIsExpanded(false);
    setEditScreenOpen(true);
  }, []);

  const closeEditScreenPopup = useCallback(() => {
    setEditScreenOpen(false);
  }, []);

  return (
    <>
      {showIsExpanded &&
        <div className='absolute inset-0 w-full h-full z-1' onClick={() => setShowIsExpanded(false)} />
      }
      <div
        className={`flex shrink-0 flex-col items-start justify-start gap-2 text-left text-sm text-black-solid-black-900-1e1e23 font-mulish ${className}`}>

        {!showIsExpanded && <img
          className="w-10 relative h-10 object-cover"
          alt=""
          src="/iconorderburger-menu-icon-ver1@2x.png"
          onClick={() => setShowIsExpanded(prevState => !prevState)}
        />}
        {showIsExpanded && (
          <div className="flex flex-col items-start justify-start relative">
            <img
              className="w-10 relative h-10 object-cover z-[0]"
              alt=""
              src={showIsExpanded ? "/iconorderburger-menu-expanded-icon-ver1@2x.png" : "/iconorderburger-menu-icon-ver1@2x.png"}
              onClick={() => setShowIsExpanded(prevState => !prevState)}
            />
            <div className="!m-[0] absolute top-[40px] right-[0px] flex flex-col items-start justify-start z-[1]">
              <div className="self-stretch flex flex-col items-end justify-start py-0 pl-0 pr-[15px] sm:pr-3 sm:box-border mq350small:pr-3 mq350small:box-border">
                <img
                  className="w-2.5 relative h-[9px] object-cover"
                  alt=""
                  src="/icontooltip-arrow-up-icon@2x.png"
                />
              </div>
              <div className="z-50 w-48 shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff overflow-y-auto flex flex-col items-start justify-start p-2 box-border gap-1.5">
                <div className="h-[46px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                     onClick={openEditMenuCustomDishPopup}
                     ref={makeRipple}
                >
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]"></div>
                  <img className="w-[30px] relative h-[30px] object-cover z-[1]" alt="" src="/iconordercustom-dish-icon-ver1@2x.png" />
                  <b className="flex-1 relative leading-[17px] font-bold z-[2]">Custom Dish</b>
                  <b className="w-[69px] relative leading-[17px] font-bold hidden text-white z-[3]">Custom Dish</b>
                </div>
                <div className="h-[46px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 px-1.5 relative gap-[4px] border-[1px] border-solid border-blue-solid-blue-300-3faaff"
                     onClick={openEditScreenPopup}
                     ref={makeRipple}
                >
                  <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-310-00aaff hidden z-[0]"></div>
                  <img className="w-[30px] relative h-[30px] object-cover z-[1]" alt="" src="/orderedit-screen-icon1@2x.png" />
                  <b className="flex-1 relative leading-[17px] font-bold z-[2]">Edit Screen</b>
                  <b className="w-[69px] relative leading-[17px] font-bold hidden text-white z-[3]">Edit Screen</b>
                </div>
                {/*<div*/}
                {/*  className="h-[46px] w-full rounded bg-blue-opacity-blue-2196f3 box-border flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 border-[1px] border-solid border-blue-solid-blue-300-3faaff">*/}
                {/*  <div className="w-9 relative h-[18px]">*/}
                {/*    <FormControlLabel*/}
                {/*      label={''}*/}
                {/*      className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"*/}
                {/*      control={<Switch color="primary" size="small" />}*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*  <b className="flex-1 relative leading-[17px]">*/}
                {/*    {LL0().div.multipleMenu()}*/}
                {/*  </b>*/}
                {/*</div>*/}

              </div>
            </div>
          </div>
        )}
      </div>
      {isEditMenuCustomDishPopupOpen && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered">
          <EditMenuCustomDishPopup onClose={closeEditMenuCustomDishPopup} />
        </PortalPopup>
      )}
      {isEditScreenOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeEditScreenPopup}
        >
          <EditMenuEditScreen onClose={closeEditScreenPopup} />
        </PortalDrawer>
      )}
    </>
  );
};

export default EditMenuBurgerButtonV;
