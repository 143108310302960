import type { FunctionComponent } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type OrderScreenOrderItemModifiType = {
  className?: string;
  showIsSelected?: boolean;
  text?: string;
  price?: string;
  onSelect?: () => void;
};

const OrderScreenOrderItemModifi: FunctionComponent<
  OrderScreenOrderItemModifiType
> = ({ className = "", showIsSelected, text, price, onSelect }) => {
  return (
    <div
      className={`rounded-10xs bg-gray-solid-gray-130-f4f4f4 border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-8 overflow-hidden flex flex-row items-center justify-center py-1 px-2 relative gap-1 text-left text-sm text-black-solid-black-880-1d1d26 font-mulish ${className}`}
      ref={makeRipple}
      onClick={onSelect}
    >
      {showIsSelected && (
        <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[1px] border-solid box-border z-[0]" />
      )}
      <div className="relative z-[1] mq350small:text-smi">{text}</div>
      {price && <div className="relative z-[2] mq350small:text-smi">|</div>}
      <div className="relative z-[3] mq350small:text-smi">{price}</div>
    </div>
  );
};

export default OrderScreenOrderItemModifi;