import { type FunctionComponent, useCallback, useState } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { clsx } from "clsx";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { exportDSFINV } from "@/tse/dsfinv/dsfinv-convert.ts";
import { useAsyncEffect, useSignal } from "@/react/core/reactive";
import { PaidOrder } from "@/data/Order";
import { tse } from "@/tse/tse-communicate.ts";
import dialogService from "@/react/SystemService/dialogService.tsx";
import { getUrlParam } from "@/shared/utils2.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export function checkValid(from: number, to: number) {
  if (!from || !to) {
    toast.error('Please fill in both FROM box and TO box!')
    return false
  }
  if (from > to) {
    toast.error('FROM day cannot be after TO day!')
    return false
  }

  if (dayjs().endOf('day').valueOf() < from) {
    toast.error('Cannot export data from the future!')
    return false
  }
  return true
}

type DataExportPopupType = {
  onClose?: () => void;
};

enum TabType {
  goDB = 0,
  dsfinv = 1,
}

const DataExportPopup: FunctionComponent<DataExportPopupType> = ({
  onClose,
}) => {

  const [dateStart, setDateStart] = useState(0);
  const [dateEnd, setDateEnd] = useState(0);

  const [dateInputFrom, setDateInputFrom] = useState<dayjs.Dayjs | null>(null);
  const [dateInputTo, setDateInputTo] = useState<dayjs.Dayjs | null>(null);
  const [isOpenEmailPopup, setIsOpenEmailPopup] = useState<boolean>(false)
  const [tab, setTab] = useState<TabType>(TabType.dsfinv)
  const [email, setEmail] = useSignal('');

  const openEmailPopup = useCallback(() => {
    userFLow(`open email popup`, {
      username: loginUser()?.name
    });
    setIsOpenEmailPopup(true)
  }, [])
  const closeEmailPopup = useCallback(() => {
    userFLow(`close email popup`, {
      username: loginUser()?.name
    });
    setIsOpenEmailPopup(false)
  }, [])

  async function handleExportData(from: number, to: number, s3 = false, email?: string) {
    userFLow(`export data`, {
      username: loginUser()?.name
    });
    const isValidDate = checkValid(from, to);
    if (!isValidDate) return;
    onClose?.()
    await exportDSFINV(from, to, s3, email)
  }

  useAsyncEffect(async ()=>{
    const firstOrder = await PaidOrder.findOne().sort({ vDate: 'asc' }).exec();
    if (firstOrder?.vDate) {
      const initialFrom = dayjs(firstOrder.vDate * 1000);
      setDateInputFrom(initialFrom);
      setDateStart(firstOrder.vDate);
    }
    const lastOrder = await PaidOrder.findOne().sort({ vDate: 'desc' }).exec();
    if (lastOrder?.vDate) {
      const initialTo = dayjs(lastOrder.vDate * 1000);
      setDateInputTo(initialTo);
      setDateEnd(lastOrder.vDate);
    }
  },[])

  async function handleExportTar(from: number, to: number) {
    userFLow(`export tar`, {
      username: loginUser()?.name
    });
    const isValidDate = checkValid(from, to);
    if (!isValidDate) return;
    onClose?.()
    const closeRegisterDialog = dialogService.progress({ title: 'Exporting...' })
    try {
      const url = await tse.exportTar({ fromTime: from, toTime: to })
      if (import.meta.env.MODE === 'production' && getUrlParam('os')) {
        await rnHost.openUrl(url)
      } else {
        window.open(url, '_blank')
      }
      toast.success('Export success!')
    } catch (err: any) {
      toast.error(`Export failed: ${err.message}`)
    } finally {
      closeRegisterDialog()
    }
  }

  function validateEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  const LL = LL0()
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="w-full relative rounded bg-white overflow-hidden flex flex-col items-center justify-start pt-8 px-4 pb-6 box-border gap-[16px_0px] min-w-[560px] h-full max-w-full max-h-full text-left text-sm text-black-solid-black-900-1e1e23 font-mulish">
        <b className="self-stretch relative text-base z-[0]">
          {LL.dashboard.dataExportersGDOdU()}
        </b>
        <img
          className="w-[21px] absolute !m-[0] top-[12px] right-[12px] h-[21px] object-cover cursor-pointer z-[1]"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[2] text-black-solid-black-880-1d1d26">
          <div className="self-stretch flex flex-col items-start justify-center gap-[8px]">
            <b className="relative sm:text-smi Tablet_768:text-smi">{LL.onlineOrder.type()}</b>
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[16px] text-white-solid-white-100-ffffff">

              <div
                className={clsx(
                  'flex-1 rounded-10xs bg-gray-solid-gray-135-efefef overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px]',
                  tab === TabType.goDB && 'w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-450-1271ff'
                )}
                onClick={() => {
                  userFLow(`set tab`, {
                    username: loginUser()?.name
                  });
                  setTab?.(TabType.goDB)
                }}
              >
                <div
                  className="self-stretch flex-1 rounded-10xs flex flex-row items-center justify-center py-[0px] px-[4px] gap-[4px] z-[1]">
                  {tab === TabType.goDB && (
                    <div
                      className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-450-1271ff" />
                  )}
                  <div
                    className={clsx('relative font-semibold z-[1] text-black-solid-black-900-1e1e23', tab === TabType.goDB && 'text-white')}>
                    GoDB
                  </div>
                </div>
              </div>

              <div
                className={clsx(
                  'flex-1 rounded-10xs bg-gray-solid-gray-135-efefef overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px]',
                  tab === TabType.dsfinv && 'w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-450-1271ff'
                )}
                onClick={() => {
                  userFLow(`set tab`, {
                    username: loginUser()?.name
                  });
                  setTab?.(TabType.dsfinv)
                }}
              >
                <div
                  className="self-stretch flex-1 rounded-10xs flex flex-row items-center justify-center py-[0px] px-[4px] gap-[4px] z-[1]">
                  {tab === TabType.dsfinv && (
                    <div
                      className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-450-1271ff" />
                  )}
                  <div
                    className={clsx('relative font-semibold z-[1] text-black-solid-black-900-1e1e23', tab === TabType.dsfinv && 'text-white')}>
                    DSFINV
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-end gap-[16px] mt-2 mb-2">
            <div className="flex-1 flex flex-row items-start justify-start gap-[8px]">
              {/*<b className="relative sm:text-smi Tablet_768:text-smi">{LL.onlineOrder.from()}</b>*/}
              {/*<div*/}
              {/*  className="flex-1 flex flex-row items-end justify-center gap-[6px] mq600:flex-col mq600:gap-[8px] mq600:flex-[unset] mq600:self-stretch mq414:gap-[16px]">*/}
              <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  label={LL.onlineOrder.from()}
                  value={dateInputFrom}
                  onChange={(newValue) => {
                    setDateInputFrom(newValue);
                    if (newValue) setDateStart(newValue.startOf('day').unix());
                  }}
                  sx={{}}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      required: false,
                      autoFocus: true,
                      error: false,
                      color: "primary",
                      helperText: "",
                    },
                  }}
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                <DatePicker
                  format={LL2().dates.dateFormat()}
                  label={LL.onlineOrder.to()}
                  value={dateInputTo}
                  onChange={(newValue) => {
                    setDateInputTo(newValue);
                    if (newValue) setDateEnd(newValue.startOf('day').unix());
                  }}
                  sx={{}}
                  slotProps={{
                    textField: {
                      name: "",
                      size: "small",
                      fullWidth: true,
                      required: false,
                      autoFocus: true,
                      error: false,
                      color: "primary",
                      helperText: "",
                    },
                  }}
                />
              </div>
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className="self-stretch h-9 flex flex-row items-start justify-between z-[3] text-center text-white">
          <div className="flex flex-row items-start justify-start gap-[8px]">
            <div
              className="rounded-md bg-purple-solid-purple-500-586cf5 overflow-hidden flex flex-row items-center justify-center py-[9px] px-4"
              onClick={async () => {
                await handleExportData(dateStart, dateEnd)
              }}>
              <b className="relative">{LL0().editOnlineMenu.export()}</b>
            </div>
            <div
              className="rounded-md bg-purple-solid-purple-500-586cf5 overflow-hidden flex flex-row items-center justify-center py-[9px] px-4"
              onClick={openEmailPopup}>
              <b className="relative">{LL0().editOnlineMenu.export()} s3</b>
            </div>
            <div
              className="rounded-md bg-purple-solid-purple-500-586cf5 overflow-hidden flex flex-row items-center justify-center py-[9px] px-4"
              onClick={async () => {
                await handleExportTar(dateStart, dateEnd)
              }}>

              {/*fixme: add i18n for export Tse Tar*/}
              <b className="relative">{'Export Tse Tar'}</b>
            </div>
            <div
              className="rounded-md bg-green-solid-green-415-7ab76f overflow-hidden flex flex-row items-center justify-center py-[9px] px-4">
              <b className="relative">{LL.dashboard.leave()}</b>
            </div>
          </div>
          <div
            className="rounded-md bg-green-solid-green-415-7ab76f overflow-hidden flex flex-row items-center justify-center py-[9px] px-4">
            <b className="relative">{LL.dashboard.thisInfo()}</b>
          </div>
        </div>
        {isOpenEmailPopup &&
          <PortalPopup
            overlayColor="rgba(0, 0, 0, 0.2)"
            placement="Centered"
            onOutsideClick={closeEmailPopup}
          >
            <div
              className={`w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-4 px-4 pb-3 box-border gap-[24px] min-w-[320px] h-full max-w-full max-h-full text-center text-sm text-white-solid-white-100-ffffff font-mulish`}
            >
              <Input
                className="min-h-[40px] self-stretch font-mulish font-medium text-base text-dimgray z-[0]"
                value={email() || ''}
                label={'Email'}
                onChange={value => setEmail(value)}
              />
              <TableManualKeyboard
                inputController={inputController0}
                onEnter={async () => {
                  if (!validateEmail(email())) {
                    toast.error('Please enter valid email address');
                    return;
                  }
                  onClose?.()
                  await handleExportData(dateStart, dateEnd, true, email())
                }}
              />
              <img
                className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[3]"
                alt=""
                src="/close-popup-icon--grey@2x.png"
                onClick={onClose}
              />
            </div>
          </PortalPopup>}
      </div>
    </LocalizationProvider>
  );
};

export default DataExportPopup;
