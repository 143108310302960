import React, { type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import {
  isDisabled,
  isOpenRefundItemsPopup,
  isOpenRefundReasonPopup,
  isPrinted,
  order0,
  orderHistoryLogic
} from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { voidTx } from '@/react/Terminal'
import { TxLogCollection } from '@/data/TxLog.ts'
import msgBox from '@/react/SystemService/msgBox.tsx'
import { TxVoidLogCollection } from '@/data/TxVoidLog.ts'
import uuid from 'time-uuid'
import DialogService from '@/react/SystemService/dialogService.tsx'
import clsx from 'clsx'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import OrderHistoryRestaurantPOS from './Popup/POSRefundItemsPopup'
import OrderHistoryRestaurantPOS1 from '@order-history/Popup/POSRefundReasonPopup.tsx'
import { OrderStatus } from '@/pos/OrderType.ts'
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";
import { isFeatureEnabled, PosFeatures } from '@/srm/features.logic'
import type { Order } from '@/data/Order'
import { createOrder } from "@/pos/logic/order-reactive.ts";
import { setOrder0 } from "@/react/PaymentView/PaymentView.tsx";
import { PosScreen, router } from '@/pos/PosRouter'
import { userFLow } from '@/shared/logger'
import { loginUser } from '@/data/UserSignal'

type OrderHistoryButtonMoreType = {
  onClose?: () => void;
  showDefault?: boolean;
  showTap?: boolean;
};

const OrderHistoryButtonMore: FunctionComponent<OrderHistoryButtonMoreType> = () => {
  const [showTap, setShowTap] = useState<boolean>(false);

  const onVoid = async () => {
    userFLow("Void Order History", {
      orderId: order0()?._id,
      username: loginUser()?.name
    })
    const orderId = order0()._id!;
    console.log('[onVoid] order', orderId)

    console.log('[onVoid] searching void result...')
    const txRefund = await TxVoidLogCollection.findOne({ selector: { orderId } }).exec()
    if (txRefund) {
      console.log('[onVoid] transaction already voided', txRefund._data)
      await msgBox.show(LL0().onlineOrder.void(), LL0().onlineOrder.transactionAlreadyRefunded(), msgBox.Buttons.OK, msgBox.Icons.Information)
      return
    }

    console.log('[onVoid] order is not void yet, searching transaction...')
    const tx = await TxLogCollection.findOne({ selector: { orderId } }).exec()
    if (!tx) {
      console.log('[onVoid] no transaction found for this order!')
      await msgBox.show(LL0().onlineOrder.void(), LL0().onlineOrder.noTransactionFound(), msgBox.Buttons.OK, msgBox.Icons.Information)
      return
    }

    console.log('[onVoid] tx', tx)

    console.log('[onVoid] transaction found, void')
    const closeProgress = DialogService.progress({ title: 'Void' })
    const { error, response } = await voidTx(tx.terminalId, { refId: (tx.metadata as any)?.payment.id })
    closeProgress()
    if (error) {
      const errorMsg = `Transaction void failed with error ${error}`
      console.log('[onVoid]', errorMsg)
      await msgBox.show(LL0().onlineOrder.void(), errorMsg, msgBox.Buttons.OK, msgBox.Icons.Error)
    } else {
      console.log('[onVoid] transaction voided', response)
      await TxVoidLogCollection.upsert({
        _id: uuid(),
        type: tx.type,
        orderId: tx.orderId,
        metadata: response
      })
      await msgBox.show(LL0().onlineOrder.void(), LL0().onlineOrder.transactionHasBeenVoided(), msgBox.Buttons.OK, msgBox.Icons.Information)
    }
  }

  // const onShowMore = async () => {
  //   await DialogService.show({
  //     component: function ({onClose}) {
  //       return <DialogService.BaseDialog title="More" onClose={onClose}>
  //         <div className="flex flex-row gap-[20px] pt-2">
  //           <div
  //             className="cursor-pointer rounded-81xl [background:linear-gradient(175.65deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#10e095] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
  //             ref={makeRipple}
  //             onClick={() => {
  //               onVoid()
  //               onClose(null)
  //             }}>
  //             <img className="relative w-[30px] h-[30px] object-cover" alt="" src="/iconrefund-icon@2x.png"/>
  //             <b className="relative leading-[17px]">Void</b>
  //           </div>
  //
  //           <div
  //             className="cursor-pointer rounded-81xl [background:linear-gradient(175.65deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#10e095] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
  //             ref={makeRipple}
  //             onClick={() => {
  //               onRefund()
  //               onClose(null)
  //             }}>
  //             <img className="relative w-[30px] h-[30px] object-cover" alt="" src="/iconrefund-icon@2x.png"/>
  //             <b className="relative leading-[17px]">{LL0().onlineOrder.refund()}</b>
  //           </div>
  //         </div>
  //       </DialogService.BaseDialog>
  //     }
  //   })
  // }
  const clickOutsideRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target as Node)) {
      setShowTap(false);
    }
  }, []);

  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    if (showTap) {
      window.addEventListener('mousedown', handleGlobalClick);
    }

    return () => {
      if (showTap) {
        window.removeEventListener('mousedown', handleGlobalClick);
      }
    };
  }, [handleClickOutside, showTap]);

  const [isOrderHistoryRestaurantPOSOpen, setOrderHistoryRestaurantPOSOpen] =
    useState(false);
  const [isOrderHistoryRestaurantPOS1Open, setOrderHistoryRestaurantPOS1Open] =
    useState(false);

  const openOrderHistoryRestaurantPOS = useCallback(() => {
    setShowTap(false)
    setOrderHistoryRestaurantPOSOpen(true);
  }, []);

  const closeOrderHistoryRestaurantPOS = useCallback(() => {
    setOrderHistoryRestaurantPOSOpen(false);
  }, []);

  const openOrderHistoryRestaurantPOS1 = useCallback(() => {
    if (order0().status === OrderStatus.CANCELLATION_REFERENCE) return;
    setShowTap(false)
    orderHistoryLogic.openRefundDialog()
  }, []);

  const closeOrderHistoryRestaurantPOS1 = useCallback(() => {
    setOrderHistoryRestaurantPOS1Open(false);
  }, []);

  const onOpenPaymentView = async(order: Order) => {
    userFLow("Debitor Order History", {
      orderId: order0()?._id,
      username: loginUser()?.name
    })
    const debitorOrder = createOrder(order);
    debitorOrder.status = OrderStatus.IN_PROGRESS;
    debitorOrder.payments = [];
    router.screen = PosScreen.PAYMENT
    setOrder0(debitorOrder)
  }

  return (
    <>
      <div
        className={clsx('self-stretch shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] flex flex-col items-center justify-end relative gap-[4px] text-left text-base text-black-solid-black-900-1e1e23 font-mulish-button-name-bold',
          isDisabled() && 'opacity-[0.5]',
        )}>
        {showTap && <div
          className="self-stretch rounded-11xl [background:linear-gradient(180deg,_#2196f3,_#0a80de)] h-[34px] flex flex-row items-center justify-center py-0.5 px-2.5 box-border z-[0] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border">
          <img
            className="w-[30px] relative h-[30px] opacity-[0.5] Tablet_600:w-7 Tablet_600:h-7 Mobile_480:w-[26px] Mobile_480:h-[26px]"
            alt=""
            src="/order-history-icon-more.svg"
          />
        </div>}
        {!showTap && <div
          className="!flex w-[50px] rounded-11xl [background:linear-gradient(180deg,_#2196f3,_#0a80de)] h-[34px] hidden flex-row items-center justify-center py-0.5 px-2.5 box-border z-[1] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border"
          onClick={() => { if(!isDisabled()) setShowTap(v => !v) }}
        >
          <img
            className="w-[30px] relative h-[30px] Tablet_600:w-7 Tablet_600:h-7 Mobile_480:w-[26px] Mobile_480:h-[26px]"
            alt=""
            src="/order-history-icon-more-white1.svg"
          />
        </div>}
        {showTap && <div
          className="!m-[0] absolute right-[4px] bottom-[35.7px] flex flex-col items-center justify-end py-0 px-0.5 box-border max-w-[none] max-h-[none] overflow-auto z-[2]"
          ref={clickOutsideRef}
        >
          <div
            className="w-[180px] rounded-sm bg-black-opacity-black-30-000000 shadow-[1px_0px_4px_#525f71,_10px_-10px_14px_rgba(149,_149,_149,_0.1)_inset,_-10px_10px_14px_rgba(255,_255,_255,_0.1)_inset] [backdrop-filter:blur(40px)] overflow-hidden flex flex-col items-center justify-end py-3 px-1.5 box-border gap-[16px]">
            {order0().status === OrderStatus.CANCELLATION_REFERENCE && <div
              className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center cursor-pointer text-center Mobile_480:h-9 mq320:h-8"
              onClick={openOrderHistoryRestaurantPOS}
              ref={makeRipple}
            >
              <b className="relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().orderHistory.cancelledItems()}
              </b>
            </div>}
            <ButtonWrapper
              className={clsx('self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 pr-3.5 pl-0 box-border gap-[16px] cursor-pointer Mobile_480:h-9 mq320:h-8',
                order0().status === OrderStatus.CANCELLATION_REFERENCE && 'opacity-50'
              )}
              onClick={openOrderHistoryRestaurantPOS1}
              isDisabled={order0().status === OrderStatus.CANCELLATION_REFERENCE || isPrinted() || order0().status === OrderStatus.DEBITOR}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/redbill-icon-1@2x.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().onlineOrder.refund()}
              </b>
            </ButtonWrapper>
            {isFeatureEnabled(PosFeatures.VoidOrder) &&
            <ButtonWrapper
              className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 pr-7 pl-0 box-border gap-[20px] Mobile_480:h-9 mq320:h-8"
              onClick={onVoid}
              isDisabled={isPrinted() || order0().status === OrderStatus.DEBITOR}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/iconpaidbill-icon@2x.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().orderHistory.void()}
              </b>
            </ButtonWrapper>
            }
            {order0().status === OrderStatus.DEBITOR && <ButtonWrapper
              className='self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 pr-3.5 pl-0 box-border gap-[16px] cursor-pointer Mobile_480:h-9 mq320:h-8'
              onClick={() => onOpenPaymentView(order0())}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                alt=""
                src="/icon-debitor.png"
              />
              <b className="relative leading-[17px] Mobile_480:text-mini mq320:text-sm">
                {LL0().payment.payDebitor()}
              </b>
            </ButtonWrapper>
            }
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-end py-0 pr-3 pl-0 Mobile_480:pl-[42px] Mobile_480:box-border mq320:pl-10 mq320:box-border">
            <img
              className="w-[13px] relative h-[11.3px]"
              alt=""
              src="/polygon1.svg"
            />
          </div>
        </div>}
      </div>
      {/*{isOrderHistoryRestaurantPOSOpen && (*/}
      {/*  <PortalPopup*/}
      {/*    overlayColor="rgba(0, 0, 0, 0.2)"*/}
      {/*    placement="Centered"*/}
      {/*    onOutsideClick={closeOrderHistoryRestaurantPOS}*/}
      {/*  >*/}
      {/*    <OrderHistoryRestaurantPOS2*/}
      {/*      onClose={closeOrderHistoryRestaurantPOS}*/}
      {/*    />*/}
      {/*  </PortalPopup>*/}
      {/*)}*/}
      {isOpenRefundItemsPopup() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={orderHistoryLogic.handleCancelRefund}
        >
          <OrderHistoryRestaurantPOS
            onClose={orderHistoryLogic.handleCancelRefund}
          />
        </PortalPopup>
      )}
      {isOpenRefundReasonPopup() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={orderHistoryLogic.handleBackToRefundItemPopup}
        >
          <OrderHistoryRestaurantPOS1 onClose={orderHistoryLogic.handleBackToRefundItemPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default OrderHistoryButtonMore;