import React, { type FunctionComponent, useCallback } from "react";
import { MarketPlaceProvider, OrderType, type TOrder } from "../../../../src/pos/OrderType";
import DeliveryIcon from "@pending-order/DeliveryIcon.tsx";
import PickupIcon from "@pending-order/PickupIcon.tsx";
import InHouse from '@pending-order/InHouseIcon.tsx';
import _ from "lodash"
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const OrdersInfo: FunctionComponent<{showOrderTypeIcon?: boolean, order: TOrder}> = ({order, showOrderTypeIcon}) => {
  const showDoordashIcon = order.provider === MarketPlaceProvider.DOOR_DASH
  const showRPlusIcon = order.provider === MarketPlaceProvider.RESTAURANT_PLUS
  const showPikaIcon = order.provider === MarketPlaceProvider.PIKAPOINT
  const showUberEatsIcon = order.provider === MarketPlaceProvider.UBER_EATS
  const showSelfDeliveryIcon = order.provider === MarketPlaceProvider.RESTAURANT_PLUS
  const showDeliverectIcon = order.provider === MarketPlaceProvider.DELIVERECT
  const showIconSkipTheDishes = order.provider === MarketPlaceProvider.SKIP_THE_DISH
  const showLieferandoIcon = order.provider === MarketPlaceProvider.LIEFERANDO
  const showRestabloIcon = order.provider === MarketPlaceProvider.RESTABLO
  const orderID = (
    (showDeliverectIcon && order.metadata?.channelOrderDisplayId) ||
    (showUberEatsIcon && order.externalId && order.externalId.substring(order.externalId.length - 6)) ||
    order.externalId ||
    order.id
  )
  const customerName = order.customerRaw?.name
  const customerPhone = order.customerRaw?.phone
  const email = order.customerRaw?.email
  const isPickUp = order.type === OrderType.PickUp;
  const isDeliveryOrder = order.type === OrderType.Delivery;
  const isInHouse = order.type === OrderType.InHouse;

  const inspectOrder = useCallback(() => {
    userFLow(`inspect order`, {
      username: loginUser()?.name
    });
    console.log(_.cloneDeep(order));
  }, [])

  return (
    <>
      <div className="self-stretch flex flex-row items-center justify-start pt-0 pl-0 gap-[4px] z-[5] text-left text-sm text-mediumslateblue font-mulish"
           onClick={inspectOrder}>
        {
          showOrderTypeIcon ? (
            isDeliveryOrder
              ? <DeliveryIcon/>
              : isPickUp
                ? <PickupIcon/>
                : <InHouse/>
            ) : null
        }
        {(showLieferandoIcon || showIconSkipTheDishes) && (
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/iconskip-the-dishes1@2x.png"
          />
        )}
        {showRestabloIcon && (
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/restablo.png"
          />
        )}
        {showDeliverectIcon && (
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/deliverect.png"
          />
        )}
        {showDeliverectIcon && order?.metadata?.channelName !== "Deliverect" && `[${order?.metadata?.channelName || ''}]`}
        {showDoordashIcon && (
          <img
            className="relative w-[23px] h-6 object-cover"
            alt=""
            src="/doordash-icon1@2x.png"
          />
        )}
        {showRPlusIcon && (
          <img
            className="relative w-[23px] h-6 object-cover"
            alt=""
            src="/rplus-icon1@2x.png"
          />
        )}
        {showPikaIcon && (
          <img
            className="relative w-[30px] h-6 object-cover"
            alt=""
            src="/pika-icon1@2x.png"
          />
        )}
        {showUberEatsIcon && (
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/iconuber-eats-icon1@2x.png"
          />
        )}
        {showSelfDeliveryIcon && (
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/icondeliver-icon-3@2x.png"
          />
        )}
        <b className="relative leading-[24px] mq1440:text-base">#{orderID}</b>
      </div>
      {
        isInHouse
          ? <div className="flex-1 flex flex-row items-center justify-start gap-[2px] text-xs text-black">
            <div className="relative leading-[15px] mq1440:text-sm">
              Table: {order.table}
            </div>
            </div>
          : <div className="flex-1 flex flex-row items-center justify-start gap-[2px] text-xs text-black">
              <div className="relative leading-[15px] mq1440:text-sm">
                {customerName}
              </div>
              <div className="relative leading-[15px] mq1440:text-sm px-[5px]"> | </div>
              <div className="relative leading-[15px] mq1440:text-sm">{customerPhone}</div>
            <div className="relative leading-[15px] mq1440:text-sm px-[5px]"> | </div>
              <div className="relative leading-[15px] mq1440:text-sm">{email}</div>
            </div>
      }
    </>
  );
};

export default OrdersInfo;
