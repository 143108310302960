import { type FunctionComponent, useMemo, useState } from "react";
import { Autocomplete, FormControl, FormHelperText, InputLabel, ListSubheader, MenuItem, Select, TextField, } from "@mui/material";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { inputController0 } from "@/react/core/Input.tsx";
import {
  convertCityNameToCountryCity,
  convertCountryCityToCityName,
  onAutofillTaxByRegion
} from "@/react/PaymentSettingView/PaymentSettingView.tsx";
import defaultTaxesByCity from "@/react/PaymentSettingView/defaultTaxesByCity.json"
import _ from "lodash";
import { LL0 } from "@/react/core/I18nService.tsx";

export type AutofillTaxPopupType = {
  onClose?: () => void;
};


const AutofillTaxPopup: FunctionComponent<AutofillTaxPopupType> = ({
  onClose,
}) => {
  const [cityOption, setCityOption] = useState<{
    country: string;
    city: string;
  } | null>(null)

  const options = useMemo(() => _.keys(defaultTaxesByCity).map((option) => convertCityNameToCountryCity(option)), [])
  const groupedOptions = useMemo(() => _.groupBy(options, (option) => option.country), [])

  return (
    <div
      className="w-full height_414:min-h-screen min-h-[400px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-between pt-9 px-3 pb-3 box-border gap-[24px_0px] min-w-[480px] h-full max-w-full max-h-full text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish height_414:h-auto">
      <div className="flex-1 self-stretch flex flex-col gap-4 items-start justify-start pt-0 px-0 pb-3 z-[0]">
        <b className="relative">{LL0().tax.selectArea()}:</b>
        <FormControl
          className="flex-1 w-full"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Select defaultValue={""} onChange={(e) => setCityOption(convertCityNameToCountryCity(e.target.value))}
                  value={cityOption?.city ? convertCountryCityToCityName(cityOption) : ''}
                  size={'small'}
                  className={'w-full'}
          >
            {_.entries(groupedOptions).map(([g, items]) => (
              [
                <ListSubheader key={g}>{g}</ListSubheader>,
                ...items.map((item) => (
                  <MenuItem key={item.city} value={convertCountryCityToCityName(item)}>
                    {`${item.city}, ${item.country}`}
                  </MenuItem>
                ))
              ]
            ))}
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <div className='w-full justify-end'>
        <div
          className='text-white min-w-[72px] h-[36px] flex-1 self-stretch flex items-center justify-center bg-[#2979ff] rounded-[6px]'
          onClick={() => {
            if (!cityOption) return;
            onAutofillTaxByRegion(convertCountryCityToCityName(cityOption) as keyof typeof defaultTaxesByCity).then();
            onClose?.();
          }}
        >
          {LL0().ui.add()}
        </div>
      </div>
      <img
        className="w-[21px] absolute !m-[0] top-[10px] right-[10px] h-[21px] object-cover cursor-pointer z-[2]"
        alt=""
        src="/iconclose-popup-icon@2x.png"
        onClick={onClose}
      />
    </div>
  );
};

export default AutofillTaxPopup;
