import React, { type FunctionComponent, useCallback, useEffect, useState } from "react";
import Input, { inputController0 } from "@/react/core/Input"
import { LL0 } from "@/react/core/I18nService";
import TableManualKeyboard from "@/react/core/TableManualKeyboard";
import type { RxDocument } from "rxdb";
import { Customer } from "@/data/Customer.ts";
import type { CustomerMapAddress } from "@new-delivery/NewDeliveryAddEditCustomer.tsx";
import _ from "lodash";
import axios from "axios";
import { getApiUrl } from "@/shared/utils.ts";
import Autocomplete, { type AutocompleteProps } from "@/react/core/Autocomplete.tsx";
import { findCustomer, initCustomer } from "@/react/CustomerInfoView/CustomerInfoView.tsx";
import { deepSignal, useAsyncEffect, useSignal } from "@/react/core/reactive.ts";
import { clone } from "json-fn";
import { convertDocument, type DocDeepSignal } from "@/data/data-utils.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import clsx from "clsx";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import msgBox from "@/react/SystemService/msgBox.tsx";

type CustomerInfoPopup = {
  onClose?: () => void
}

const CustomerInfoPopup: FunctionComponent<CustomerInfoPopup> = ({ onClose }) => {
  const [customer0, setCustomer0] = useSignal<DocDeepSignal<Customer> | undefined>();

  const currentCustomerAddress = customer0()?.addresses?.[customer0()?.defaultAddressIndex || 0];

  const [customerTelOptions, setCustomerTelOptions] = useState<RxDocument<Customer>[]>([]);
  const [customerNameOptions, setCustomerNameOptions] = useState<RxDocument<Customer>[]>([]);
  const [autoCompleteAddressOptions, setAutoCompleteAddressOptions] = useState<CustomerMapAddress[]>([]);

  const fetchCustomerTel = useCallback(_.debounce(async () => {
    const foundCustomers = await findCustomer("", customer0()?.phoneNumber)
    setCustomerTelOptions(foundCustomers || [])
  }, 500), []);

  const fetchCustomerName = useCallback(_.debounce(async () => {
    const foundCustomers = await findCustomer(customer0()?.name)
    setCustomerNameOptions(foundCustomers || [])
  }, 500), []);

  useEffect(() => {
    if ((customer0()?.phoneNumber?.length || 0) <= 3) return;
    fetchCustomerTel()
  }, [customer0()?.phoneNumber]);

  useEffect(() => {
    if ((customer0()?.name?.length || 0) <= 3) return;
    fetchCustomerName()
  }, [customer0()?.name]);

  const autoCompletePropsConstructor = (options: RxDocument<Customer>[], key: "phoneNumber" | "name"):
    AutocompleteProps => ({
    sx: { height: "37px" },
    freeSolo: true,
    className: "flex-1",
    // not let mui do filter
    filterOptions: opts => opts,
    value: customer0()?.[key] || "",
    onChange: (_e, newValue) => {
      console.log({ newValue })
      if (newValue === null) {
        setCustomer0(deepSignal(clone(initCustomer())))
        return;
      }
      _.assign(customer0(), { [key]: newValue })
    },
    options: options.map(customer => customer?.[key] || ""),
    inputProps: {
      label: key === "phoneNumber" ? `${LL0().customerInfo.telephoneRequired()}` : `${LL0().customerInfo.customersName()} (${_.startCase(LL0().companyInfo.required())})`,
      value: customer0()?.[key] || "",
      onChange: value => {
        _.assign(customer0(), { [key]: value })
      },
      className: "w-full font-mulish text-sm text-gray-100"
    },
    renderOption: ({ style, className, ...restProps }, option, state) => {
      const foundCustomer = options.find(customer => customer[key] === option)

      return (
        <li
          className="self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1"
          key={foundCustomer?._id || state.index}
          {...restProps}
          onClick={(e) => {
            if (!foundCustomer) return;
            setCustomer0(convertDocument<Customer>(foundCustomer, false));
            restProps.onClick?.(e);
          }}
          ref={makeRipple}
        >
          {state.index % 2 === 0 &&
            <div
              className="!flex w-full absolute my-0 mx-[!important] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-opacity-blue-80-3c5ac6 hidden z-[0]" />
          }
          <div className={clsx("text-left flex-1 relative text-black-solid-black-880-1d1d26 z-[1]",
            state.index % 2 === 0 && "text-white")}>
            <span>{foundCustomer?.phoneNumber}</span>
          </div>
          <div className={clsx("relative text-blue-solid-blue-600-4b40c9 z-[3]",
            state.index % 2 === 0 && "text-white")}>
            {foundCustomer?.name}
          </div>
        </li>
      )
    }
  })

  const fetchAddress = useCallback(_.debounce(async (searchPlace: string) => {
    //TODO: Integrate w google api key later?
    const { data: foundAddresses } = await axios.get<
      CustomerMapAddress[]>(getApiUrl() + "/api/map/google-places", {
      params: {
        searchPlace
      }
    })
    setAutoCompleteAddressOptions(foundAddresses || [])
  }, 500), []);

  useEffect(() => {
    if ((currentCustomerAddress?.street?.length || 0) <= 3 ||
      (autoCompleteAddressOptions?.[0]?.index != null &&
        !!autoCompleteAddressOptions.find(option => option.name === currentCustomerAddress?.street))
    ) return;
    fetchAddress(currentCustomerAddress?.street || "")
  }, [currentCustomerAddress?.street]);

  const onClickSave = async () => {
    const _customer = customer0();
    if (!_customer) return;
    //todo: add i18n for msgBox
    if (!_customer?.phoneNumber || !_customer?.name) {
      await msgBox.show('Missing required information', `Missing customer's phone number or name`, msgBox.Buttons.OK)
      return
    }
    await Customer.upsert(clone(_customer))
    const address = _customer.addresses?.[_customer.defaultAddressIndex || 0]
    _.assign(order0(), {
      customerRaw: {
        name: _customer.name,
        address: address?.street,
        phone: _customer.phoneNumber,
        extraAddressInfo: address?.home,
        note: _customer.note,
      },
      customer: _customer._id,
    })

    onClose?.()
  }

  useAsyncEffect(async () => {
    if (order0().customerRaw) {
      const foundCustomer = await findCustomer(order0().customerRaw?.name, order0().customerRaw?.phone)
      if (foundCustomer) {
        setCustomer0(deepSignal(clone(foundCustomer[0])))
      }
    } else {
      setCustomer0(deepSignal(clone(initCustomer())))
    }
  }, []);

  return (
    <div
      className="relative bg-white w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] height_414:gap-[16px] mq600:p-2 mq768:h-screen mq600:gap-1 min-w-[400px] height_414:pb-2 height_414:pt-3 max-w-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div
        className="overflow-scroll no-scrollbar self-stretch flex-1 flex flex-col items-start justify-start gap-[16px] z-[0]">
        <div
          className=" pt-4 !flex-1 self-stretch bg-white-solid-white-100-ffffff flex flex-col items-start justify-start gap-[10px] z-[1]">
          <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[2]">
            <Autocomplete
              dontUseNativeInput
              {...autoCompletePropsConstructor(customerTelOptions, "phoneNumber")}
            />
            <Autocomplete
              dontUseNativeInput
              {...autoCompletePropsConstructor(customerNameOptions, "name")}
            />
          </div>
          <Autocomplete
            dontUseNativeInput
            sx={{ height: "37px" }}
            freeSolo
            fullWidth
            // not let mui do filter
            filterOptions={(options) => {
              return options
            }}
            value={{
              name: currentCustomerAddress?.street,
              index: customer0()?.defaultAddressIndex
            }}
            onChange={(_e, newValue) => {
              const _customer = customer0();
              if (!_customer) return;
              if (newValue?.index != null && newValue != null) {
                _customer.defaultAddressIndex = newValue?.index
                return;
              }
              _.merge(currentCustomerAddress,
                {
                  street: newValue?.name
                  , placeId: newValue?.placeId
                })
            }}
            getOptionLabel={option => option.name || ""}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            options={autoCompleteAddressOptions}
            inputProps={{
              className: "w-full font-mulish text-sm text-gray-100",
              label: LL0().delivery.customer.address.street(),
              value: currentCustomerAddress?.street || '',
              onChange: value => _.merge(currentCustomerAddress, { street: value }),
              endAdornment: (onChange, value) => (
                <>
                  {
                    !!customer0()?.doc &&
                    <img
                      className="mr-2"
                      src="/new-delivery-blue-arrow.png"
                      alt="new-delivery-blue-arrow"
                      width={20}
                      height={20}
                      onClick={() => {
                        if (!customer0()?.addresses?.length || customer0()?.addresses?.length === 1 && customer0()?.addresses?.[0]?.street === '') return;
                        onChange?.({ target: { value: value || "" } } as any)
                        setAutoCompleteAddressOptions(customer0()?.addresses?.map((address, index) => ({
                          name: address.street || '', index
                        })) || []);
                      }}
                    />
                  }
                </>
              )
            }}
          />

          <div className="self-stretch flex flex-col items-start justify-center gap-[16px] z-[0]">
            <Input
              className="w-full font-mulish text-sm text-gray-100"
              label={LL0().customerInfo.houseNumber()}
              value={currentCustomerAddress?.home || ''}
              onChange={value => _.merge(currentCustomerAddress, { home: value })}
            />
            <Input
              className="w-full font-mulish text-sm text-gray-100"
              label={LL0().article.note()}
              value={customer0()?.note || ''}
              onChange={value => _.assign(customer0(), { note: value })}
            />
          </div>
        </div>
      </div>
        <TableManualKeyboard
          inputController={inputController0}
          onEnter={onClickSave}
        />
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={() => {
          onClose?.()
        }}
      />
    </div>
  )
}

export default CustomerInfoPopup;