import { Button } from '@mui/material'

import { execAllTestcase, resetExecTestcase, runProgress } from '@/srm/testcase/runner'
import { lastSucceededTestcase, nextTestcase } from '@/srm/testcase/state'

export default function SrmTestcase() {
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <div>✅ Last succeeded</div>
        <code>{lastSucceededTestcase()}</code>
        <div>🧪 Next case</div>
        <code>{`${nextTestcase()} (${runProgress()})`}</code>
        <Button onClick={execAllTestcase}>Run</Button>
        <Button onClick={resetExecTestcase}>Reset</Button>
      </div>
    </div>
  )
}
