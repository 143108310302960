import { router } from "@/pos/PosRouter.ts";
import {
  CashbookTransactionsAction,
  CashbookViewList,
  currentBalance,
  firstTransactionTime,
  lastTransactionTime,
  setSelectedCashbookView,
  todayIncoming,
  todayOutgoing,
  todayStartBalance
} from '@/react/CashbookView/CashbookView.tsx';
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { type FunctionComponent, useCallback, useState } from "react";
import CashbookBalanceChange from "./CashbookBalanceChange";
import CashbookCloseDayPopup from "./CashbookCloseDayPopup";
import CashbookPriceBox from "./CashbookPriceBox";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { LL0 } from '@/react/core/I18nService.tsx'
import { FormControlLabel, Switch } from "@mui/material";
import { mainScreen } from "@/data/PosSettingsSignal.ts";


const CashbookPlugin: FunctionComponent = () => {
  const [isCashbookCloseDayPopupOpen, setCashbookCloseDayPopupOpen] =
    useState(false);

  const [showEnableTransactions, setShowEnableTransactions] = useState(false);

  const openCashbookCloseDayPopup = useCallback(() => {
    setCashbookCloseDayPopupOpen(true);
  }, []);

  const closeCashbookCloseDayPopup = useCallback(() => {
    setCashbookCloseDayPopupOpen(false);
  }, []);

  return (
    <>
      {showEnableTransactions && (
        <div className="absolute top-0 right-0 left-0 bottom-0" onClick={() => setShowEnableTransactions(false)} />
      )}
      <div
        className="cashbook-screen w-full h-full flex flex-col items-center justify-start text-left text-smi text-black-solid-black-1000-000000 font-mulish lg:w-full lg:h-full mq1440:w-full mq1440:h-full">
        <div
          className="self-stretch bg-black-solid-black-500-59636f h-[50px] shrink-0 flex flex-row items-center justify-between py-[5px] px-2.5 box-border w-auto gap-[10px] sm:h-[42px] Tablet_768:h-[42px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-2.5 z-[1]">
            <div className="flex flex-col items-start justify-start relative gap-0.5 z-[1]">
              <div
                className="rounded-[10000px] bg-cyan-solid-cyan-300-7cf3de h-10 flex flex-row items-center justify-start z-[1]"
                ref={makeRipple}
                onClick={() => setShowEnableTransactions(v => !v)}
              >
                <img
                  className="w-10 relative h-10 object-cover Tablet_768:w-9 Tablet_768:h-9 Tablet_600:w-8 Tablet_600:h-8"
                  alt=""
                  src="/iconcashbook-setting@2x.png"
                />
              </div>
              {showEnableTransactions && <div
                className="w-[368px] !m-[0] absolute bottom-[-77px] left-[0px] flex flex-col items-center justify-start z-[0] height_414:h-60">
                <div className="self-stretch flex flex-col items-start justify-start py-0 px-[15px]">
                  <img
                    className="w-2.5 relative h-[9px] object-cover"
                    alt=""
                    src="/icontooltip-arrow-up-icon@2x.png"
                  />
                </div>
                <div
                  className="w-[368px] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] rounded bg-blue-solid-blue-20-f3f9ff overflow-y-auto flex flex-row flex-wrap items-start justify-start p-4 box-border">
                  <div
                    className="h-[34px] flex-1 rounded bg-blue-opacity-blue-2196f3 border-blue-solid-blue-300-3faaff border-[1px] border-solid box-border flex flex-row items-center justify-start py-0.5 px-2.5 gap-1.5 hover:bg-blue-opacity-blue-2196f3 hover:flex hover:flex-1 hover:h-[34px] hover:flex-row hover:gap-1.5 hover:items-center hover:justify-start hover:rounded hover:py-0.5 hover:px-2.5 hover:box-border hover:border-blue-solid-blue-300-3faaff hover:border-[1px] hover:border-solid">
                    <div className="w-9 relative h-[18px]">
                      <FormControlLabel
                        label={''}
                        className="absolute h-full w-[83.33%] top-[0%] right-[11.11%] bottom-[0%] left-[5.56%]"
                        control={<Switch color="primary" size="small"/>}
                      />
                    </div>
                    <b
                      className="flex-1 relative leading-[17px] flex font-bold h-auto">
                      {LL0().cashbook.showTransactionDetailsForPrinting()}
                    </b>
                  </div>
                </div>
              </div>}
            </div>
            <div
              className="h-10 rounded-11xl bg-orange-solid-orange-300-ffc163 flex flex-row items-center justify-start py-[9px] px-2.5 box-border gap-[7px] w-auto [align-self:unset] z-[0] text-smi text-black-solid-black-1000-000000 hover:bg-orange-solid-orange-300-ffc163 hover:flex hover:w-auto hover:[align-self:unset] hover:h-10 hover:flex-row hover:gap-[7px] hover:items-center hover:justify-start hover:rounded-11xl hover:py-[9px] hover:px-2.5 hover:box-border sm:h-[34px] Tablet_768:h-[34px]"
              onClick={() => setSelectedCashbookView(CashbookViewList.CashbookHistory)}
              ref={makeRipple}
            >
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconhistory-white@2x.png"
              />
              <div className="relative leading-[13px] font-semibold">
                {LL0().cashbook.cashBookHistory()}
              </div>
            </div>
          </div>

          <div
            className="w-[76px] rounded-11xl bg-blue-solid-blue-450-1271ff h-10 flex flex-row items-center justify-center p-2.5 box-border z-[0] sm:h-[34px] Tablet_768:h-[34px]"
            onClick={() => router.screen = mainScreen()}
            ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
          </div>
        </div>
        <div
          className="self-stretch flex-1 bg-black-solid-black-700-364150 overflow-y-auto flex flex-col items-center justify-start py-[30px] px-0 text-center text-lg text-white-solid-white-100-ffffff lg:items-center lg:justify-start lg:pt-12 lg:pb-12 lg:box-border sm:pt-7 sm:pb-7 sm:box-border Tablet_768:pt-5 Tablet_768:pb-4 Tablet_768:box-border Tablet_600:pt-4 Tablet_600:pb-4 Tablet_600:box-border mq1440:items-center mq1440:justify-start mq1440:pt-20 mq1440:pb-20 mq1440:box-border height_414:pt-2 height_414:pb-2 height_414:box-border">
          <div
            className="w-[488px] flex flex-col items-start justify-center gap-[24px] h-auto lg:w-[600px] sm:w-[580px] Tablet_768:gap-[20px] Tablet_600:w-[460px] Tablet_600:gap-[20px] mq1440:w-[800px] mq1440:gap-[48px] height_414:gap-[10px]">
            <div
              className="self-stretch flex flex-row items-center justify-between lg:h-auto Tablet_600:h-auto Tablet_600:items-center Tablet_600:justify-center height_414:h-[140px] height_414:pt-0 height_414:pb-0 height_414:box-border">
              <CashbookPriceBox
                icon="/cashbookshuttle@2x.png"
                title={LL0().cashbook.start()}
                cashNumber={todayStartBalance()}
                dateCash={firstTransactionTime()}
                isShowIcon
                isDefault
                showDateCash
              />
              <div className="flex-1 h-px flex flex-row items-center justify-start relative">
                <div
                  className="flex-1 relative border-blue-opacity-blue-80-e3f0ff border-t-[1px] border-dashed box-border h-0.5 z-[0]"/>
                <img

                  className="w-6 absolute !m-[0] top-[calc(50%_-_12.5px)] left-[calc(50%_-_12.5px)] h-6 overflow-hidden shrink-0 object-cover z-[1]"
                  alt=""
                  src="/icontriangle-right@2x.png"
                />
              </div>
              <CashbookPriceBox
                icon="/cashbookwallet-icon@2x.png"
                title={LL0().cashbook.currentWallet()}
                cashNumber={currentBalance()}
                dateCash={lastTransactionTime()}
                isShowIcon
                isDefault
                showDateCash
              />
            </div>
            <div
              className="self-stretch flex flex-col items-center justify-start gap-[12px] Tablet_600:gap-[10px] mq1440:gap-[20px] height_414:gap-[10px]">
              <CashbookBalanceChange
                icon="/iconincoming-cash@2x.png"
                title={LL0().cashbook.incomingCash()}
                change={todayIncoming()}
                color="linear-gradient(180deg,_#ffda68,_#e3ac00)"
                type={CashbookTransactionsAction.INCOMING}
              />
              <CashbookBalanceChange
                icon="/iconoutgoing-cash@2x.png"
                title={LL0().cashbook.outgoingCash()}
                change={todayOutgoing()}
                color="linear-gradient(180deg,_rgba(255,_158,_137,_0.9),_rgba(216,_39,_0,_0.9))"
                type={CashbookTransactionsAction.OUTGOING}
              />
            </div>
            <div
              className="self-stretch flex flex-col items-center justify-center gap-[12px] text-xl Tablet_600:gap-[10px] mq1440:gap-[20px] height_414:gap-[10px]">
              <div
                className="self-stretch rounded-md [background:linear-gradient(180deg,_#c6f38e,_#6a9a33)] h-[53px] flex flex-row items-center justify-center py-2.5 px-0 box-border w-auto gap-[10px] sm:h-12 Tablet_768:h-10 Tablet_600:h-9 height_414:h-[42px]"
                onClick={() => setSelectedCashbookView(CashbookViewList.CashbookCounting)}
                ref={makeRipple}
              >
                <div
                  className="relative font-medium lg:text-3xl lg:leading-[22px] sm:text-xl Tablet_768:text-base Tablet_600:text-sm mq1440:text-3xl mq1440:leading-[22px] height_414:text-mid">
                  {LL0().cashbook.cashBalancing()}
                </div>
              </div>
              <div
                className="self-stretch rounded-md [background:linear-gradient(180deg,_#c6f38e,_#6a9a33)] h-[53px] flex flex-row items-center justify-center py-2.5 px-0 box-border cursor-pointer sm:h-12 Tablet_768:h-10 Tablet_600:h-9 height_414:h-[42px] height_414:items-center height_414:justify-center"
                onClick={openCashbookCloseDayPopup}
              >
                <div
                  className="relative font-medium lg:text-3xl lg:leading-[22px] sm:text-xl Tablet_768:text-base Tablet_600:text-sm mq1440:text-3xl mq1440:leading-[22px] height_414:text-mid">
                  {LL0().cashbook.closeDay()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isCashbookCloseDayPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeCashbookCloseDayPopup}
        >
          <CashbookCloseDayPopup
            onClose={closeCashbookCloseDayPopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default CashbookPlugin;