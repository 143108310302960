import { type FunctionComponent, useCallback, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import SelectStaffPopup from "./SelectStaffPopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import SearchServicesPopup from "./SearchServicesPopup";
import ReservationServiceFrom from "./ReservationServiceFrom";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  addService,
  getServiceDisplayText,
  isFromWeb,
  isMultipleStaffs,
  onConfirmModifiers,
  removeServiceAt,
  rsvServices,
  setIsMultipleStaffs,
  setStaff,
  staff,
} from "@/data/ReservationHub.ts";
import SelectModifiersPopup from "@reservation2/SelectModifiersPopup.tsx";
import { useComputed, useSignal } from "@/react/core/reactive.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

export type ServiceSectionType = {
  className?: string;
};

const ServiceSection: FunctionComponent<ServiceSectionType> = ({
  className = "",
}) => {
  const [isSelectStaffPopupOpen, setSelectStaffPopupOpen] = useState(false);
  const [isSearchServicesPopupOpen, setSearchServicesPopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useSignal()
  const selectedProductDisplayText = useComputed(() => getServiceDisplayText(selectedProduct()))

  const [isSelectModifiersPopupOpen, setSelectModifiersPopupOpen] =
    useState(false);

  const openSelectModifiersPopup = useCallback(() => {
    setSelectModifiersPopupOpen(true);
  }, []);

  const closeSelectModifiersPopup = useCallback(() => {
    setSelectModifiersPopupOpen(false);
  }, []);

  const openSelectStaffPopup = useCallback(() => {
    setSelectStaffPopupOpen(true);
  }, []);

  const closeSelectStaffPopup = useCallback(() => {
    setSelectStaffPopupOpen(false);
  }, []);

  const openSearchServicesPopup = useCallback(() => {
    setSearchServicesPopupOpen(true);
  }, []);

  const closeSearchServicesPopup = useCallback(() => {
    setSearchServicesPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className={`self-stretch rounded-lg bg-white-solid-white-100-ffffff flex flex-col items-start justify-start p-3 gap-[12px] z-[0] text-left text-base text-black-solid-black-1000-000000 font-mulish border-[1px] border-solid border-gray-solid-gray-355-c6c6c6 h.414:pl-2 h.414:pr-2 h.414:box-border mq320:pl-1 mq320:pr-1 mq320:box-border ${className}`}
      >
        <div className="self-stretch flex flex-row items-center justify-start gap-[12px] h.414:gap-[4px]">
          <div className="flex flex-col items-start justify-center gap-[4px]">
            <b className="relative leading-[20px] sm:text-mini mq768:text-smi mq480:text-sm h.414:text-smi mq320:text-xs">
              {LL0().reservation.pleaseSelectServices()}
            </b>
            {/*<div*/}
            {/*  className="flex flex-row items-center justify-start gap-[4px] text-black-solid-black-500-59636f">*/}
            {/*  <div className="relative sm:text-mini mq768:text-smi mq480:text-sm h.414:text-smi mq320:text-xs">*/}
            {/*    {LL0().reservation.totalPrice()}:*/}
            {/*  </div>*/}
            {/*  <b className="relative text-green-solid-green-00c968 sm:text-mini mq768:text-smi h.414:text-smi mq320:text-xs">*/}
            {/*    {LL3().format.currency(60)}*/}
            {/*  </b>*/}
            {/*</div>*/}
          </div>
          <div className="flex-1 flex flex-row items-center justify-end gap-[10px] text-sm text-yellow-solid-yellow-700-da9001">
            {isFromWeb() && <div className="relative font-semibold sm:text-smi mq768:text-xs">
            {LL0().reservation.youCantChangeTheService()}
            </div>}
            {!isFromWeb() && <div className="flex flex-row items-center justify-start gap-[10px] text-blue-solid-blue-420-2979ff">
              <div className="flex flex-row items-center justify-start gap-[4px] text-black-solid-black-1000-000000">
                <div className="relative font-medium sm:text-smi mq768:text-xs h.414:text-xs">
                  {LL0().reservation.multipleStaffs()}
                </div>
                <FormControlLabel
                  className="relative"
                  sx={{marginRight: 0}}
                  label=""
                  control={<Switch
                    color="primary"
                    size="medium"
                    checked={isMultipleStaffs()}
                    onChange={(e, checked) => setIsMultipleStaffs(checked)}/>}
                />
              </div>
              {!isMultipleStaffs() && !staff() && <div
                className="rounded bg-blue-solid-blue-20-f3f9ff box-border h-9 flex flex-row items-center justify-start py-1 px-2.5 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff md:py-2 md:pr-3.5 md:pl-2.5 md:box-border mq768:h-[30px] mq768:pl-2 mq768:pr-2 mq768:box-border h.414:h-[30px]"
                onClick={openSelectStaffPopup}
              >
                <b className="relative sm:text-smi mq768:text-xs mq480:text-xs h.414:text-xs mq320:text-xs">
                  + {LL0().reservation.addStaff()}
                </b>
              </div>}
              <div
                className="rounded bg-blue-solid-blue-20-f3f9ff box-border h-9 flex flex-row items-center justify-start py-1 px-2.5 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff md:py-2 md:pr-3.5 md:pl-2.5 md:box-border mq768:h-[30px] mq768:pl-2 mq768:pr-2 mq768:box-border h.414:h-[30px] h.414:pl-2 h.414:pr-2 h.414:box-border"
                onClick={openSearchServicesPopup}
              >
                <b className="relative sm:text-smi mq768:text-xs mq480:text-xs h.414:text-xs mq320:text-xs">
                  + {LL0().reservation.addService()}
                </b>
              </div>
            </div>}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-sm text-black-solid-black-500-59636f">
          <div className="self-stretch relative box-border h-px z-[2] border-t-[1px] border-solid border-gray-solid-gray-490-bdbdbd" />
          {!isMultipleStaffs() && <div className="flex flex-row items-start justify-start gap-[4px] cursor-pointer z-[1]"
            onClick={openSelectStaffPopup}
          >
            <b className="relative leading-[20px]">{LL0().monthlyReport.staff()}:</b>
            <b className="relative [text-decoration:underline] leading-[20px] text-purple-solid-purple-9e5cfb">
              {staff()}
            </b>
          </div>}
          <div className="self-stretch grid flex-row flex-wrap items-start justify-start gap-[12px] grid-cols-[repeat(auto-fill,_minmax(320px,_1fr))] z-[0] text-black-solid-black-900-1e1e23">
            {rsvServices().map((service, index) => (
              <ReservationServiceFrom
                key={index}
                service={`${LL0().reservation.service()} ${index + 1}:`}
                isAddStaff={!service.staff}
                isMultipleStaffs={isMultipleStaffs()}
                staffName={service.staff}
                displayText={getServiceDisplayText(service)}
                modifiers={service.modifiers}
                servicePrice={LL3().format.currency(60)}
                note={service.note}
                onRemove={() => removeServiceAt(index)}/>
            ))}
          </div>
        </div>
      </div>
      {isSelectStaffPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSelectStaffPopup}
        >
          <SelectStaffPopup onClose={closeSelectStaffPopup} onConfirm={setStaff}/>
        </PortalPopup>
      )}
      {isSelectModifiersPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSelectModifiersPopup}
        >
          <SelectModifiersPopup
            displayText={selectedProductDisplayText()}
            onClose={closeSelectModifiersPopup}
            onConfirm={(modifiers) => onConfirmModifiers(selectedProduct(), modifiers)}/>
        </PortalPopup>
      )}
      {isSearchServicesPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSearchServicesPopup}
        >
          <SearchServicesPopup
            onClose={closeSearchServicesPopup}
            onConfirm={(product) => {
              if (product.activePopupModifierGroup) {
                setSelectedProduct(product)
                openSelectModifiersPopup()
              } else {
                addService(product)
              }
            }}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default ServiceSection;
