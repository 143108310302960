import type { RxCollection, RxJsonSchema } from 'rxdb'

import { GROUP_PRINTERS_COLLECTION_NAME } from './GroupPrinter'
import { MENU_COLLECTION_NAME } from './Menu'

export const PRODUCT_COLLECTION_NAME = 'products'

export const ORDER_RESTRICTIONS = {
  dineIn: 'Dine In',
  pickup: 'Pickup',
  delivery: 'Delivery',
  waiterService: 'Waiter-Service',
} as const
export type ORDER_RESTRICTIONS = keyof typeof ORDER_RESTRICTIONS

export const ITEM_VALIDITIES = {
  forever: 'Forever',
  recurring: 'Recurring',
  period: 'Period',
}
export type ITEM_VALIDITIES = keyof typeof ITEM_VALIDITIES

export const RECURRING_WEEKDAYS = {
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
} as const
export type RECURRING_WEEKDAYS = keyof typeof RECURRING_WEEKDAYS

export interface ProductVariant {
  _id: string
  name: string
  price: number
  tax: number
  isEnabled: boolean
}
export interface ProductTaxComponent {
  name: string
  value: number
  printLabel: string
}
export interface ProductMenuPrice {
  menu: string
  value: number
}
export interface ProductMenuTax {
  menu: string
  ref: string
  value: number
}
interface ProductLayout {
  color: string
  order: number
  device: string
}
interface ProductIngredient {
  amount: number
  inventoryId: string
}
export enum ProductStatusKiosk {
  Recommend = 'recommend',
  BestSeller = 'bestSeller',
  New = 'new',
}
export interface Product {
  _id: string
  id?: string
  note?: string
  appType?: string
  categories?: string[]
  groupPrinter?: string
  groupPrinter2?: string
  labelPrinter?: string
  ingredients?: ProductIngredient[]
  layoutCategory?: string
  layout?: ProductLayout
  name?: string
  status?: ProductStatusKiosk
  price?: number
  oldPrice?: number
  costPrice?: number
  tax?: number
  createdAt?: string
  updatedAt?: string
  isItemNote?: boolean
  tax2?: number
  /** Dine-in tax */
  taxCategory?: string
  /** Take-away tax */
  taxCategory2?: string
  isDivArticle?: boolean
  isModifier?: boolean
  activePopupModifierGroup?: string[]
  priority?: number
  description?: string
  saleNote?: string
  orderRestrictions?: Array<ORDER_RESTRICTIONS>
  orderRestriction_pickupTimeFrom?: number
  orderRestriction_pickupTimeTo?: number
  validityMode?: ITEM_VALIDITIES
  validity_recurringWeekDays?: Array<RECURRING_WEEKDAYS>
  validity_periodFrom?: number
  validity_periodTo?: number
  isDiscountable?: boolean
  isEnabled?: boolean
  locations?: string[]
  stockQuantity?: number
  /**
   * @deprecated Use `imageId` instead.
   */
  image?: string
  /**
   * @deprecated Use `imageId` instead.
   */
  imageUrl?: string
  section?: string
  imageId?: string
  modifiers?: string[]
  variants?: ProductVariant[]
  happyHours?: string[]
  syncingHash?: string
  position?: number
  menus?: string[]
  groupPrinters?: string[]
  /** Dine-in tax */
  taxComponents?: ProductTaxComponent[]
  /** Take-away tax */
  taxComponents2?: ProductTaxComponent[]
  menuPrices?: ProductMenuPrice[]
  menuTaxes?: ProductMenuTax[]
  menuTaxes2?: ProductMenuTax[]
}

export const productSchema: RxJsonSchema<Product> = {
  title: 'product schema',
  version: 31, // Increment this number if the schema changes
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    id: { type: 'string', maxLength: 24 },
    appType: { type: 'string' },
    // attributes: {type: "array", items: {}},
    categories: { type: 'array', items: { type: 'string' } },
    groupPrinter: { type: 'string', maxLength: 50 },
    groupPrinter2: { type: 'string', maxLength: 50 },
    labelPrinter: { type: 'string', maxLength: 50 },
    ingredients: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          amount: { type: 'number' },
          inventoryId: { type: 'string' },
        },
      },
    },
    layoutCategory: { type: 'string' },
    layout: {
      type: 'object',
      properties: {
        color: { type: 'string' },
        order: { type: 'integer' },
        device: { type: 'string' },
        type: {type: 'string'},
      },
    },
    name: { type: 'string', maxLength: 50 },
    price: { type: 'number' },
    costPrice: { type: 'number' },
    tax: { type: 'integer' },
    createdAt: { type: 'string' },
    updatedAt: { type: 'string' },
    isItemNote: { type: 'boolean' },
    tax2: { type: 'integer' },
    taxCategory: { type: 'string' },
    taxCategory2: { type: 'string' },
    isDivArticle: { type: 'boolean' },
    isModifier: { type: 'boolean' },
    activePopupModifierGroup: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    priority: { type: 'number' },
    description: { type: 'string' },
    saleNote: { type: 'string' },
    orderRestrictions: { type: 'array', items: { type: 'string' } },
    orderRestriction_pickupTimeFrom: { type: 'number' },
    orderRestriction_pickupTimeTo: { type: 'number' },
    validityMode: { type: 'string' },
    validity_recurringWeekDays: { type: 'array', items: { type: 'string' } },
    validity_periodFrom: { type: 'number' },
    validity_periodTo: { type: 'number' },
    isDiscountable: { type: 'boolean' },
    isEnabled: { type: 'boolean' },
    locations: { type: 'array', items: { type: 'string' } },
    stockQuantity: { type: 'number' },
    image: { type: 'string' },
    imageUrl: { type: 'string' },
    section: { type: 'string' },
    modifiers: { type: 'array', items: { type: 'string' } },
    variants: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string' },
          name: { type: 'string' },
          price: { type: 'number' },
          tax: { type: 'number' },
          isEnabled: { type: 'boolean' },
        },
        required: ['_id'],
      },
    },
    happyHours: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    syncingHash: { type: 'string' },
    position: { type: 'number' },
    menus: { type: 'array', ref: MENU_COLLECTION_NAME, items: { type: 'string' } },
    groupPrinters: { type: 'array', ref: GROUP_PRINTERS_COLLECTION_NAME, items: { type: 'string' } },
    taxComponents: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          value: { type: 'integer' },
          printLabel: { type: 'string' },
        },
      },
    },
    taxComponents2: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          value: { type: 'integer' },
          printLabel: { type: 'string' },
        },
      },
    },
    menuPrices: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          menu: { type: 'string' },
          value: { type: 'integer' },
        },
      },
    },
    menuTaxes: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          menu: { type: 'string' },
          ref: { type: 'string' },
          value: { type: 'integer' },
        },
      },
    },
    menuTaxes2: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          menu: { type: 'string' },
          ref: { type: 'string' },
          value: { type: 'integer' },
        },
      },
    },
  },
  indexes: ['id', 'name', 'groupPrinter', 'groupPrinter2', 'labelPrinter'],
  required: ['_id'],
}

export const Product = {} as RxCollection<Product>

Object.assign(window, { Product }) // Make available globally
