import { type FunctionComponent, useState, useCallback } from "react";
import {
  Switch,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@mui/material";
import DineInTaxAndTakeawayTaxI from "./Popups/DineInTaxAndTakeawayTaxI";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import AutofillTaxPopup from "./AutofillTaxPopup";
import NewEditTaxPopup from "./Popups/NewEditTaxPopup.tsx";
import TaxInfoCard from "./TaxInfoCard";
import _ from 'lodash';
import { LL0 } from '@/react/core/I18nService.tsx';
import {
  createTaxCategory,
  initTaxCategory,
  setTaxCategory0, taxCategory0
} from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import { deepSignal } from '@/react/core/reactive.ts';
import { clone } from 'json-fn';
import { taxCategories0 } from '@/data/TaxCategoryHub.ts';
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

const TaxPaymentLeftPath: FunctionComponent = () => {
  const onAddTaxCategory = async () => {
    const _taxCategory = taxCategory0();
    if (!_taxCategory) return
    await createTaxCategory(_taxCategory);
    closeNewEditTaxPopup1();
  }

  const [isDineInTaxAndTakeawayTaxIOpen, setDineInTaxAndTakeawayTaxIOpen] =
    useState(false);
  const [isAutofillTaxPopupOpen, setAutofillTaxPopupOpen] = useState(false);

  const [isNewEditTaxPopup1Open, setNewEditTaxPopup1Open] = useState(false);

  const openDineInTaxAndTakeawayTaxI = useCallback(() => {
    setDineInTaxAndTakeawayTaxIOpen(true);
  }, []);

  const closeDineInTaxAndTakeawayTaxI = useCallback(() => {
    setDineInTaxAndTakeawayTaxIOpen(false);
  }, []);

  const openAutofillTaxPopup = useCallback(() => {
    setAutofillTaxPopupOpen(true);
  }, []);

  const closeAutofillTaxPopup = useCallback(() => {
    setAutofillTaxPopupOpen(false);
  }, []);

  const openNewEditTaxPopup1 = useCallback(() => {
    // set taxCategory0 to empty object so that we can create it in closeAddEditTaxPopup4
    setTaxCategory0(deepSignal(clone(initTaxCategory)))
    setNewEditTaxPopup1Open(true);
  }, []);

  const closeNewEditTaxPopup1 = useCallback(() => {
    setNewEditTaxPopup1Open(false);
  }, []);

  return (
    <>
      <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start text-left text-mini text-gray-solid-gray-850-767676 font-mulish">
        <div className="self-stretch bg-gray-solid-gray-130-f4f4f4 [backdrop-filter:blur(8px)] h-[50px] shrink-0 flex flex-row items-center justify-start py-[15px] pr-0 pl-6 box-border sm:h-[42px] Tablet_768:pl-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border height_414:h-[42px]">
          <b className="relative sm:text-sm height_414:text-sm">{LL0().article.tax().toUpperCase()}:</b>
        </div>
        <div className="no-scrollbar self-stretch flex-1 overflow-y-auto shrink-0 flex flex-col items-start justify-start pt-0 px-0 pb-4 text-sm text-gray">
          <div className="self-stretch flex flex-row items-center justify-start py-[9px] px-5 Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
            <FormControlLabel
              className="flex-1"
              label={LL0().settings.productPriceIncludeTax()}
              control={<Switch color="primary" size="small"
                               checked={generalSetting0()?.isProductPriceIncludingTax || false}
                               onChange={e => _.assign(generalSetting0(),
                                 { isProductPriceIncludingTax: e.target.checked })}
              />}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start py-0.5 px-5 Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
              <div className="self-stretch h-6 flex flex-row items-center justify-center">
                <b className="flex-1 relative sm:text-smi Tablet_768:text-smi">
                  {LL0().taxPayment.theAmountIsExpressed()}
                </b>
              </div>
              <FormControl
                className="!w-full self-stretch font-mulish text-sm text-gray"
                variant="outlined"
                sx={{
                  borderRadius: "0px 0px 0px 0px",
                  width: "471.5px",
                  height: "37px",
                  m: 0,
                  p: 0,
                  "& .MuiInputBase-root": {
                    m: 0,
                    p: '14px',
                    minHeight: "37px",
                    justifyContent: "center",
                    display: "inline-flex",
                  },
                  "& .MuiInputLabel-root": {
                    m: 0,
                    p: 0,
                    minHeight: "37px",
                    display: "inline-flex",
                  },
                  "& .MuiMenuItem-root": {
                    m: 0,
                    p: 0,
                    height: "37px",
                    display: "inline-flex",
                  },
                  "& .MuiSelect-select": {
                    m: 0,
                    p: 0,
                    height: "37px",
                    alignItems: "center",
                    display: "inline-flex",
                  },
                  "& .MuiInput-input": { m: 0, p: 0 },
                  "& .MuiInputBase-input": {
                    textAlign: "left",
                    p: "0 !important",
                  },
                }}
              >
                <InputLabel color="primary" />
                <Select
                  color="primary"
                  defaultValue="2 numbers"
                  size="small"
                  displayEmpty
                  value={generalSetting0()?.decimalPointExpressed?.toString() || ""}
                  onChange={(e) => _.assign(generalSetting0(), {decimalPointExpressed: parseInt(e.target.value)})}
                >
                  <MenuItem value="2">2 {LL0().printing.numbers()}</MenuItem>
                  <MenuItem value="3">3 {LL0().printing.numbers()}</MenuItem>
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-[9px] px-5 pb-0 gap-[4px_0px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
              <div className="self-stretch flex flex-row items-center justify-between">
                <b className="flex-1 relative sm:text-smi Tablet_768:text-smi">
                  {LL0().taxPayment.selectTypeBeforeSettingUpTaxes()}:
                </b>
                <img
                  className="w-6 relative h-6 object-cover cursor-pointer"
                  alt=""
                  src="/iconquestion-icon@2x.png"
                  onClick={openDineInTaxAndTakeawayTaxI}
                />
              </div>
              <FormControl
                className="self-stretch font-mulish text-sm"
                variant="outlined"
              >
                <InputLabel color="primary" />
                <Select color="primary" size="small"
                        value={generalSetting0()?.taxType || "none"}
                        onChange={e => _.assign(generalSetting0(), { taxType: e.target.value })}
                >
                  <MenuItem value="one">
                    {LL0().settings.taxTypeOne()}
                  </MenuItem>
                  <MenuItem value="two">
                    {LL0().settings.taxTypeTwo()}
                  </MenuItem>
                  <MenuItem value="none">{LL0().settings.taxTypeNone()}</MenuItem>
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
            <div
              className="flex flex-row items-center justify-start gap-[0px_16px] cursor-pointer"
              onClick={openAutofillTaxPopup}
            >
              <img
                className="w-8 relative h-8 object-cover sm:w-[26px] sm:h-[26px]"
                alt=""
                src="/triple-chevron-right-icon@2x.png"
              />
              <i className="relative [text-decoration:underline] font-semibold Tablet_768:text-xs">
                {LL0().settings.autoFillTaxByRegion()}
              </i>
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-end justify-start pt-2.5 px-5 pb-0 gap-[12px_0px] Tablet_768:pl-4 Tablet_768:pr-4 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
            <div className="self-stretch flex flex-col items-end justify-center gap-[8px_0px]">
              <b className="self-stretch relative sm:text-smi Tablet_768:text-smi">
                {LL0().settings.taxInformation()}:
              </b>
              <div className="gap-3 self-stretch flex flex-col items-end justify-center">
                {
                  taxCategories0().map(taxCategory => (
                    <TaxInfoCard
                      taxData={taxCategory}
                      key={taxCategory._id}
                    />
                  ))
                }
              </div>
            </div>
            <div
              className="rounded-xl bg-green-solid-green-9fcc3b shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] flex flex-row items-center justify-center py-1 px-2 gap-[0px_5px] cursor-pointer text-smi text-black-solid-black-1000-000000"
              onClick={openNewEditTaxPopup1}
            >
              <img
                className="w-6 relative h-6 object-cover Tablet_768:w-[22px] Tablet_768:h-[22px]"
                alt=""
                src="/iconplus-icon@2x.png"
              />
              <div className="relative font-semibold Tablet_768:text-xs">
                {LL0().settings.createTax()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDineInTaxAndTakeawayTaxIOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDineInTaxAndTakeawayTaxI}
        >
          <DineInTaxAndTakeawayTaxI onClose={closeDineInTaxAndTakeawayTaxI} />
        </PortalPopup>
      )}
      {isAutofillTaxPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeAutofillTaxPopup}
        >
          <AutofillTaxPopup onClose={closeAutofillTaxPopup} />
        </PortalPopup>
      )}
      {isNewEditTaxPopup1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeNewEditTaxPopup1}
        >
          <NewEditTaxPopup onClose={closeNewEditTaxPopup1} onComplete={onAddTaxCategory}/>
        </PortalPopup>
      )}
    </>
  );
};

export default TaxPaymentLeftPath;
