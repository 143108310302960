import React, { type FunctionComponent, useCallback, useState } from "react";
import DiscountKeyboard from "./Discounts/DiscountKeyboard";
import TableManualKeyboardV from "./TableManualKeyboardV";
import OrderScreenFloatBtnWrapper from "./OrderScreenFloatBtnWrapper";
import OrderScreenBurgerMenu from "./OrderScreenBurgerMenu";
import CategoryTabs from "./CategoryTabs";
import RenderSectionsOrderWrapper from "@/react/OrderView/Vertical/RenderSectionsOrder.tsx";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import {
  handleClearDiscountOrder,
  handleOrderDiscount,
  onProductClickHandle,
  orderDiscount,
  sections,
  setOrderDiscount,
  setResultModifiers,
  setShowPopupModifier,
  setVoucherStatus,
  showPopupModifier
} from "@/react/OrderView/OrderView.tsx";
import {
  isDiscountPopupVertical,
  isEditDiscountLabel,
  onBack,
  setDiscountPopupVertical,
  setEditDiscountLabel
} from "@/react/VerticalOrderView/VerticalOrderView.tsx";
import PortalPopup from "@vertical-order-view/PortalPopup.tsx";
import { isEditButton } from "@order-view/DiscountPopup/DiscountPopupItem.tsx";
import DiscountVertical from "@/react/core/DiscountVertical.tsx";
import PortalDrawer from "@vertical-edit-menu/PortalDrawer.tsx";
import EditDiscountLabelOther from "@vertical-order-view/Popups/EditDiscountLabelVertical.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { signal, useSignal } from "@/react/core/reactive.ts";
import VoucherVerticalPopup from "@vertical-order-view/Popups/VoucherVerticalPopup.tsx";
import _ from "lodash";
import SearchItemVertical from "@vertical-order-view/SearchItemVertical.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import PortalPopup3 from "@order-view/PortalPopup3.tsx";
import OrderGroupPopup from "@order-view/OrderGroupPopup.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import OrderScreenFunctionsPopup from "@vertical-order-view/Popups/OrderScreenFunctionsPopup.tsx";
import PaymentSplitPopup from "@order-view/PaymentSplitPopup.tsx";

export type OrderScreenPluginType = {
  className?: string;
};

export const [isOpenVoucherPopup, setIsOpenVoucherPopup] = signal(false)

const OrderScreenPlugin2: FunctionComponent<OrderScreenPluginType> = ({
  className = "",
}) => {
  const [isExpandedMoreButton, setIsExpandedMoreButton] = useState(false)
  const [searchText, setSearchText] = useSignal('')
  const [isOrderScreenSearchItemPopupOpen, setOrderScreenSearchItemPopupOpen] =
    useState(false);
  const [isOrderScreenFunctionsPopupOpen, setOrderScreenFunctionsPopupOpen] =
    useState(false);
  const openMoreMenu = useCallback(() => {
    setIsExpandedMoreButton(true);
  }, [])
  const closeMoreMenu = useCallback(() => {
    setIsExpandedMoreButton(false);
  }, [])
  const openOrderScreenSearchItemPopup = useCallback(() => {
    setOrderScreenSearchItemPopupOpen(true);
  }, []);

  const closeOrderScreenSearchItemPopup = useCallback(() => {
    setOrderScreenSearchItemPopupOpen(false);
  }, []);

  const openOrderScreenFunctionsPopup = useCallback(() => {
    setOrderScreenFunctionsPopupOpen(true);
  }, []);

  const closeOrderScreenFunctionsPopup = useCallback(() => {
    setOrderScreenFunctionsPopupOpen(false);
  }, []);

  return (
    <div
      className={`flex-1 h-full max-w-full overflow-hidden flex flex-col items-start justify-start relative bg-[url('/public/order-screen--plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-left text-sm text-yellow-solid-yellow-295-ffc82b font-mulish ${className}`}
    >
      <PaymentSplitPopup />
      <DiscountKeyboard />
      <TableManualKeyboardV
        showTooltips
        isSelected={false}
        showNumber={false}
        isDefault
        isEnter={false}
        istext
      />
      {!generalSetting0()?.useMenuV2 &&
        <div className="self-stretch bg-black-solid-black-500-59636f flex flex-col items-start justify-center pt-4 px-2.5 pb-2.5 z-[2]">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="self-stretch flex-1 flex flex-row items-center justify-start gap-[8px]">
              <div className="self-stretch w-16 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-blue-solid-blue-450-1271ff overflow-hidden shrink-0 flex flex-col items-center justify-center mq432:w-[52px] mq432:h-[34px] mq414:w-[52px] mq414:h-[34px] mq360:w-[52px] mq360:h-[34px] mq340:w-[52px] mq340:h-[34px] mq320:w-[52px] mq320:h-[34px]"
                   ref={makeRipple}
                   onClick={onBack}
              >
                <img
                  className="w-8 relative h-8 object-cover mq432:w-[26px] mq432:h-[26px] mq414:w-[26px] mq414:h-[26px] mq360:w-[26px] mq360:h-8 mq340:w-[26px] mq340:h-[26px] mq320:w-[26px] mq320:h-[26px]"
                  alt=""
                  src="/vertical--back-icon@2x.png"
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start py-0 pr-0 pl-2 border-l-[1px] border-solid border-gray-solid-gray-750-696969">
                <b className="self-stretch relative leading-[20px] overflow-hidden text-ellipsis whitespace-nowrap sm:text-smi mq600:text-smi mq432:text-smi">
                  {_.startCase(order0().users?.[0])}
                </b>
                {order0()?.table &&
                  <div className="self-stretch flex flex-row items-start justify-start gap-[2px] text-base text-white-solid-white-100-ffffff">
                    <div className="relative leading-[16px] font-semibold sm:text-xs mq600:text-xs mq432:text-xs">
                      {LL0().orderHistory.tableNo()}:
                    </div>
                    <div className="relative leading-[16px] font-semibold sm:text-xs mq600:text-xs mq432:text-xs">
                      {order0().table}
                    </div>
                  </div>
                }
              </div>
              <img
                className="w-10 relative h-10 object-cover hidden"
                alt=""
                src="/ordertake-out-icon-ver1@2x.png"
              />
            </div>
            <div className="flex flex-row gap-2">
              <div className="rounded-81xl bg-blue-solid-blue-260-1f8be0 hidden flex-row items-center justify-start">
                <img
                  className="w-10 relative h-10 object-cover"
                  alt=""
                  src="/ordertake-out-icon-ver1@2x.png"
                />
              </div>
              {!generalSetting0()?.useMenuV2 &&
                <OrderScreenBurgerMenu
                  onOpen={openMoreMenu}
                  onClose={closeMoreMenu}
                  isExpanded={isExpandedMoreButton}
                  isSelected={false}
                  defaultText
                  selectedText={false}
                />}
              {!generalSetting0()?.hasTablePlan &&
                <div
                  className="border-[1.8px] border-solid border-[#1271FF] w-10 relative h-10 object-cover Tablet_768:w-[34px] Tablet_768:h-[34px] Tablet_600:w-[34px] Tablet_600:h-[34px] Mobile_480:w-[34px] Mobile_480:h-[34px] height_414:w-[34px] height_414:h-[34px] rounded-full bg-[#E6F2FC] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center box-border gap-[0px_3px] cursor-pointer"
                  onClick={openOrderScreenFunctionsPopup}
                  ref={makeRipple}
                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover"
                    alt=""
                    src="/order-function-icon.png"
                  />
                </div>
              }
            </div>
          </div>
        </div>}
      <CategoryTabs />
      <div
        className="self-stretch flex-1 overflow-y-auto no-scrollbar flex flex-col items-start justify-start pt-2 px-2.5 pb-0 gap-[12px] z-[0] text-white-solid-white-100-ffffff">
        <div
          className={'flex items-center justify-start px-1.5 rounded-[4px] w-full h-10 bg-white self-stretch font-mulish font-semibold text-sm text-black-solid-black-500-59636f'}
          onClick={openOrderScreenSearchItemPopup}>
          Search ID
        </div>
        <div
          className="self-stretch pb-[76px] overflow-y-auto no-scrollbar flex-1 flex flex-col items-start justify-start gap-[12px] text-mini">
          {sections()?.sort((a, b) => (a?.order || 0) - (b?.order || 0))?.map((section, index) => (
            <RenderSectionsOrderWrapper key={index} sectionId={section?._id} />
          ))}
        </div>
      </div>
      {!generalSetting0()?.useMenuV2 &&
        <OrderScreenFloatBtnWrapper
          isSplitSeat={false}
          isPayment={false}
          isCartDisable={false}
          isCart
          isPrint={false}
          isSselect
          showQuantityCart={false}
        />
      }
      {isDiscountPopupVertical() === 0 &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
        >
          <DiscountVertical
            showClose={true}
            showDiscountForm={true}
            type={"order"}
            isEdit={isEditButton()}
            order={order0?.()}
            discount={orderDiscount()}
            setDiscount={setOrderDiscount}
            onApply={(d) => handleOrderDiscount(d)}
            onClear={handleClearDiscountOrder}
            onClose={() => setDiscountPopupVertical(false)}
          />
        </PortalPopup>}
      {isEditDiscountLabel() &&
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={() => setEditDiscountLabel(false)}
        >
          <EditDiscountLabelOther onClose={() => setEditDiscountLabel(false)} />
        </PortalDrawer>}
      {isOpenVoucherPopup() && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={() => {
            setIsOpenVoucherPopup(false)
            setVoucherStatus("")
          }}
        >
          <VoucherVerticalPopup
            onClose={() => {
              setIsOpenVoucherPopup(false)
              setVoucherStatus("")
            }}
            order={order0?.()} />
        </PortalDrawer>
      )}
      <PortalPopup3
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={() => {
          setShowPopupModifier(false)
          setResultModifiers({})
        }}
        show={showPopupModifier()}
      >
        <OrderGroupPopup onClose={() => setShowPopupModifier(false)} onClearResult={() => setResultModifiers({})} />
      </PortalPopup3>
      {isOrderScreenSearchItemPopupOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={closeOrderScreenSearchItemPopup}
        >
          <SearchItemVertical onClose={closeOrderScreenSearchItemPopup} onConfirm={onProductClickHandle} />
        </PortalDrawer>
      )}
      {isOrderScreenFunctionsPopupOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Right"
          onOutsideClick={closeOrderScreenFunctionsPopup}
        >
          <OrderScreenFunctionsPopup onClose={closeOrderScreenFunctionsPopup} />
        </PortalDrawer>
      )}
    </div>
  );
};

export default OrderScreenPlugin2;
