import { type FunctionComponent, memo, useRef } from "react";
import MultipleKitchenButton from "./MultipleKitchenButton";
import KitchenMonitorDisplayCard from "./KitchenMonitorDisplayCard";
import { router } from "@/pos/PosRouter.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  currentFilter,
  filterPrinter,
  kitchenOrders1,
  setFilterOrders, startAllOrder,
  stopKitchenBell,
  totalDoneOrders,
  totalNewOrders,
  totalPrepareOrders,
} from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";

import { generalSetting0, mainScreen } from "@/data/PosSettingsSignal.ts";
import clsx from "clsx";
import type { OrderItem } from "@/pos/OrderType.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import RoundedButton from "@pending-order/RoundedButton.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

const KitchenMonitorPlugin: FunctionComponent = () => {

  const refScrollContainer = useRef<HTMLDivElement>(null)
  const refAnimationFrame = useRef<number | null>(0)
  let speed = 0

  const canScroll = (direction: "left" | "right"): boolean => {
    if (!refScrollContainer.current) return false;
    const { scrollLeft, scrollWidth, clientWidth } = refScrollContainer.current;

    if (direction === "left") {
      return scrollLeft > 0;
    } else {
      return scrollLeft < scrollWidth - clientWidth;
    }
  };

  const scrollStep = (direction: "left" | "right") => {
    if (refScrollContainer && canScroll(direction)) {
      const v = Math.min(speed++, 50)
      const step = direction === "left" ? -v : v
      refScrollContainer.current?.scrollBy({
        left: step,
      })
      refAnimationFrame.current = requestAnimationFrame(() => scrollStep(direction))
    }
  }

  const handleStartScrolling = (direction: "left" | "right") => {
    if (!refAnimationFrame.current) {
      scrollStep(direction)
    }
  }

  const handleStopScrolling = () => {
    if (refAnimationFrame.current) {
      cancelAnimationFrame(refAnimationFrame.current);
      refAnimationFrame.current = null;
    }
    speed = 0
  }

  const refContainerCard = useRef<HTMLDivElement>(null)

  const checkItemPrinter = (items: OrderItem[])=> {
    const printers = filterPrinter()
    if (printers === 'all') return true
    else {
      return items.some(item => item.groupPrinter?.includes(printers) || item.groupPrinter2?.includes(printers) || item.labelPrinter?.includes(printers))
    }
  }

  const filterOrders = () => {
    const orders = kitchenOrders1()
    if (filterPrinter() === 'all') return orders
    else {
      return orders.filter(order => checkItemPrinter(order.items))
    }
  }

  return (
    <>
      <div className="text-smi kitchen-monitor-screen overflow-hidden w-full h-full flex flex-col items-start justify-start relative !bg-[url('/order-screen-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-black-solid-black-855-212121 font-mulish">
        <div
          className="self-stretch bg-gray-solid-gray-550-6b7178 h-[50px] flex flex-row items-center justify-between py-0 px-4 box-border z-[3] sm:h-[42px] Tablet_768:h-[42px] Tablet_768:pl-3 Tablet_768:pr-3 Tablet_768:box-border Tablet_600:gap-[12px] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
          <div className="flex flex-row items-start justify-start gap-[6px] sm:gap-[4px]">
            <MultipleKitchenButton />
            <div
              onClick={() => {
                setFilterOrders('all')
              }}
              ref={makeRipple}
              className={clsx(
                "text-black rounded-31xl shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-20 h-[34px] flex flex-row items-center justify-start py-0 px-2 box-border sm:pl-1 sm:pr-1 sm:box-border Tablet_600:w-[68px]",
                {
                  'bg-white-solid-white-100-ffffff': currentFilter() !== 'all',
                  'bg-blue-solid-blue-350-2196f3 text-white-solid-white-100-ffffff': currentFilter() === 'all',
                }
              )}>
              <div className="flex-1 relative font-semibold">{LL0().kitchenMonitor.allActive()}</div>
            </div>
            <div
              onClick={() => {
                setFilterOrders('new')
              }}
              ref={makeRipple}
              className={clsx(
                "text-black rounded-31xl shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-20 h-[34px] flex flex-row items-center justify-start py-0 px-2 box-border sm:pl-1 sm:pr-1 sm:box-border Tablet_600:w-[68px]",
                {
                  'bg-white-solid-white-100-ffffff': currentFilter() !== 'new',
                  'bg-blue-solid-blue-350-2196f3 text-white-solid-white-100-ffffff': currentFilter() === 'new',
                }
              )}>
              <div className="flex-1 relative font-semibold ">{LL0().kitchenMonitor.new()}</div>
              {!!totalNewOrders() &&
                <div
                  className="w-6 h-6 flex flex-row items-center justify-center relative gap-[8px] text-gray-solid-gray-145-f9fafb">
                  <div
                    className="absolute my-0 mx-[!important] top-[calc(50%_-_12px)] left-[calc(50%_-_12px)] rounded-[50%] bg-red-solid-red-150-ff5e71 w-6 h-6 z-[0]" />
                  <b className="flex-1 relative tracking-[-0.28px] z-[1]">
                    {totalNewOrders()}
                  </b>
                </div>
              }
            </div>
            <div
              onClick={() => {
                setFilterOrders('prepare')
              }}
              ref={makeRipple}
              className={clsx(
                "text-black rounded-31xl shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-24 h-[34px] flex flex-row items-center justify-start py-0 px-2 box-border sm:pl-1 sm:pr-1 sm:box-border Tablet_600:w-[78px]",
                {
                  'bg-white-solid-white-100-ffffff': currentFilter() !== 'prepare',
                  'bg-blue-solid-blue-350-2196f3 text-white-solid-white-100-ffffff': currentFilter() === 'prepare',
                }
              )}>
              <div className="flex-1 relative font-semibold ">{LL0().kitchenMonitor.prepare()}</div>
              {!!totalPrepareOrders() &&
                <div
                  className="w-6 h-6 flex flex-row items-center justify-center relative gap-[8px] text-gray-solid-gray-145-f9fafb">
                  <div
                    className="absolute my-0 mx-[!important] top-[calc(50%_-_12px)] left-[calc(50%_-_12px)] rounded-[50%] bg-red-solid-red-150-ff5e71 w-6 h-6 z-[0]" />
                  <b className="flex-1 relative tracking-[-0.28px] z-[1]">
                    {totalPrepareOrders()}
                  </b>
                </div>
              }
            </div>
            <div
              onClick={() => {
                setFilterOrders('done')
              }}
              ref={makeRipple}
              className={clsx(
                "text-black rounded-31xl shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] w-20 h-[34px] flex flex-row items-center justify-start py-0 px-2 box-border sm:pl-1 sm:pr-1 sm:box-border Tablet_600:w-[68px]",
                {
                  'bg-white-solid-white-100-ffffff': currentFilter() !== 'done',
                  'bg-blue-solid-blue-350-2196f3 text-white-solid-white-100-ffffff': currentFilter() === 'done',
                }
              )}>
              <div className="flex-1 relative font-semibold">{LL0().kitchenMonitor.done()}</div>
              {!!totalDoneOrders() &&
                <div
                  className="w-6 h-6 flex flex-row items-center justify-center relative gap-[8px] text-gray-solid-gray-145-f9fafb">
                  <div
                    className="absolute my-0 mx-[!important] top-[calc(50%_-_12px)] left-[calc(50%_-_12px)] rounded-[50%] bg-red-solid-red-150-ff5e71 w-6 h-6 z-[0]" />
                  <b className="flex-1 relative tracking-[-0.28px] z-[1]">
                    {totalDoneOrders()}
                  </b>
                </div>
              }
            </div>
            <div
              className="hidden rounded-31xl bg-white-solid-white-100-ffffff shadow-[1px_1px_6px_rgba(0,_0,_0,_0.08)] h-[34px] flex flex-row items-center justify-start py-0 px-3 box-border gap-[4px] sm:pl-2 sm:pr-2 sm:box-border">
              <img
                className="relative w-6 h-6 object-cover"
                alt=""
                src="/iconzoom-in-icon@2x.png"
              />
              <div className="relative font-semibold">50%</div>
            </div>
            <RoundedButton
              buttonIcon={"/iconmute-icon@2x.png"}
              text="Button"
              showButtonIcon
              showButtonText={false}
              roundedButtonBackgroundColor="#fff"
              onClick={async () => {
                stopKitchenBell()
                await startAllOrder()
              }}
            />
          </div>
          <div
            className="rounded-[100px] bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-white-solid-white-100-ffffff Tablet_600:w-16 Tablet_600:min-w-[64px]"
            onClick={() => router.screen = mainScreen()}
            ref={makeRipple}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="relative leading-[17px] hidden">Button</b>
          </div>
        </div>
        <div
          className="my-0 mx-[!important] absolute right-[16px] bottom-[12px] rounded-26xl bg-gray-opacity-gray-50-d6d7d7 shadow-[0px_1px_4px_rgba(0,_0,_0,_0.25)] w-[45px] h-[45px] overflow-hidden shrink-0 flex flex-row items-center justify-center z-[9999]"
          ref={makeRipple}
          onPointerDown={() => handleStartScrolling("right")}
          onPointerUp={handleStopScrolling}
        >
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/icongeneralwhite-double-chevron-icon@2x.png"
          />
        </div>
        <div
          className="my-0 mx-[!important] absolute bottom-[12px] left-[16px] rounded-26xl bg-gray-opacity-gray-50-d6d7d7 shadow-[0px_1px_4px_rgba(0,_0,_0,_0.25)] w-[45px] h-[45px] overflow-hidden shrink-0 flex flex-row items-center justify-center z-[99]"
          ref={makeRipple}
          onPointerDown={() => handleStartScrolling("left")}
          onPointerUp={handleStopScrolling}
        >
          <img
            className="relative w-6 h-6 object-cover"
            alt=""
            src="/icongeneralwhite-double-chevron-icon1@2x.png"
          />
        </div>
        <div
          style={{fontSize: (deviceSetting0()?.screenConfig?.kitchenFontSize || 14) + 1}}
          className="self-stretch h-full flex-1 overflow-x-auto no-scrollbar flex flex-row items-start justify-start p-2 gap-[10px] z-[0]  text-black-solid-black-1000-000000"
          ref={refScrollContainer}
        >
          <div ref={refContainerCard}
               className="self-stretch flex items-start justify-start gap-[8px]"
              style={{flexWrap: generalSetting0()?.oneColumnPerOrder ? "nowrap" : "wrap", flexDirection: generalSetting0()?.oneColumnPerOrder ? "row" : "column"}}
          >
            {filterOrders().map((order) => {
              return (
                <KitchenMonitorDisplayCard
                  heightContainer={refContainerCard.current?.clientHeight}
                  key={order._id}
                  order={order}
                />
              )
            })}
          <div className="w-0.5 h-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(KitchenMonitorPlugin);
