import { FunctionComponent } from "react";
import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";

export type DiscountKeyboardType = {
  className?: string;
};

const DiscountKeyboard: FunctionComponent<DiscountKeyboardType> = ({
  className = "",
}) => {
  return (
    <div
      className={`w-[480px] !m-[0] absolute top-[532px] left-[0px] hidden flex-col items-center justify-start z-[5] text-center text-lg text-black-solid-black-900-1e1e23 font-mulish ${className}`}
    >
      <div className="self-stretch bg-white-solid-white-100-ffffff flex flex-col items-start justify-start lg:hidden mq600:flex">
        <div className="self-stretch bg-gray-solid-gray-180-ebecee flex flex-col items-start justify-start p-1 gap-[4px] lg:flex-row mq600:flex-col">
          <div className="self-stretch relative h-32 grid grid-rows-[repeat(3,40px)] grid-cols-[repeat(5,minmax(32px,auto))] [grid-row-gap:4px] [grid-column-gap:4px]">
            <div className="relative col-[1/4] row-[1/1]">
              <TextField
                className="[border:none] bg-[transparent] absolute w-full top-[0px] right-[0px] left-[0px] font-mulish font-semibold text-base text-black-solid-black-900-1e1e23"
                color="primary"
                variant="outlined"
                sx={{
                  "& .MuiInputBase-root": { height: "40px" },
                  width: "282px",
                }}
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] overflow-hidden flex flex-col items-center justify-center py-0 px-2.5 relative col-[4/4] row-[1/1] text-sm text-black-solid-black-1000-000000 sm:relative sm:col-[4/4] sm:row-[1/1] mq600:relative mq600:col-[4/4] mq600:row-[1/1] mq432:relative mq432:col-[4/4] mq432:row-[1/1]">
              <div className="self-stretch relative leading-[28px] mq600:text-sm">
                ,
              </div>
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] overflow-hidden flex flex-col items-center justify-center py-0 px-2.5 relative col-[5/5] row-[1/1] lg:relative lg:col-[5/5] lg:row-[1/1] md:relative md:col-[5/5] md:row-[1/1] sm:relative sm:col-[5/5] sm:row-[1/1] mq600:relative mq600:col-[5/5] mq600:row-[1/1] mq432:relative mq432:col-[5/5] mq432:row-[1/1]">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button.svg"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[1/1] row-[2/2] sm:relative sm:col-[1/1] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                1
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[2/2] row-[2/2] sm:relative sm:col-[2/2] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                2
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[3/3] row-[2/2] sm:relative sm:col-[3/3] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                3
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[4/4] row-[2/2] sm:relative sm:col-[4/4] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                4
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[5/5] row-[2/2] sm:relative sm:col-[5/5] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                5
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[1/1] row-[3/3] sm:relative sm:col-[1/1] sm:row-[3/3]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                6
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[2/2] row-[3/3] sm:relative sm:col-[2/2] sm:row-[3/3]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                7
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[3/3] row-[3/3] sm:relative sm:col-[3/3] sm:row-[3/3]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                8
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[4/4] row-[3/3] sm:relative sm:col-[4/4] sm:row-[3/3]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                9
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[5/5] row-[3/3] sm:relative sm:col-[5/5] sm:row-[3/3]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                0
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch h-9 flex flex-row items-start justify-start gap-[4px] text-sm text-white-solid-white-100-ffffff lg:h-auto lg:flex-col mq600:self-stretch mq600:w-auto mq600:flex-row">
            <div className="self-stretch flex-1 rounded-10xs bg-orange-solid-orange-450-ffaa06 flex flex-row items-center justify-center py-2 px-1 lg:h-10 lg:min-w-[175px] lg:max-w-[175px] lg:flex-[unset] lg:self-stretch mq600:flex-1 mq600:h-9 mq600:min-w-0 mq600:max-w-[1000px]">
              <div className="relative leading-[17px] font-semibold">
                Apply Now
              </div>
            </div>
            <div className="self-stretch flex-1 rounded-10xs bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center py-2 px-1 lg:h-10 lg:min-w-[175px] lg:max-w-[175px] lg:flex-[unset] lg:self-stretch mq600:flex-1 mq600:h-9 mq600:min-w-0 mq600:max-w-[1000px]">
              <div className="relative leading-[17px] font-semibold">
                Add Button
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[480px] bg-white-solid-white-100-ffffff hidden flex-row items-start justify-start lg:flex mq600:hidden">
        <div className="flex-1 bg-gray-solid-gray-180-ebecee flex flex-row items-start justify-start p-1 lg:flex-row mq600:flex-col">
          <div className="flex-1 relative h-[84px] grid grid-rows-[repeat(2,40px)] grid-cols-[repeat(11,minmax(32px,auto))] [grid-row-gap:4px] [grid-column-gap:1px] lg:grid lg:grid-rows-[repeat(2,40px)] lg:grid-cols-[repeat(11,minmax(32px,auto))] lg:[grid-row-gap:4px] lg:[grid-column-gap:4px] mq600:flex-[unset] mq600:self-stretch">
            <div className="relative col-[1/9] row-[1/1]">
              <TextField
                className="[border:none] bg-[transparent] absolute w-full top-[0px] right-[0px] left-[0px] font-mulish font-semibold text-base text-black-solid-black-900-1e1e23"
                color="primary"
                variant="outlined"
                sx={{
                  "& .MuiInputBase-root": { height: "40px" },
                  width: "316px",
                }}
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] overflow-hidden flex flex-col items-center justify-center py-0 px-2.5 relative col-[9/9] row-[1/1] text-sm text-black-solid-black-1000-000000 sm:relative sm:col-[9/9] sm:row-[1/1] mq600:relative mq600:col-[9/9] mq600:row-[1/1] mq432:relative mq432:col-[9/9] mq432:row-[1/1]">
              <div className="self-stretch relative leading-[28px] mq600:text-sm">
                ,
              </div>
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] overflow-hidden flex flex-col items-center justify-center py-0 px-2.5 relative col-[10/10] row-[1/1] lg:relative lg:col-[10/10] lg:row-[1/1] md:relative md:col-[10/10] md:row-[1/1] sm:relative sm:col-[10/10] sm:row-[1/1] mq600:relative mq600:col-[10/10] mq600:row-[1/1] mq432:relative mq432:col-[10/10] mq432:row-[1/1]">
              <img
                className="w-[22.7px] relative h-[17px]"
                alt=""
                src="/delete-button.svg"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[1/1] row-[2/2] sm:relative sm:col-[1/1] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                1
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[2/2] row-[2/2] sm:relative sm:col-[2/2] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                2
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[3/3] row-[2/2] sm:relative sm:col-[3/3] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                3
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[4/4] row-[2/2] sm:relative sm:col-[4/4] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                4
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[5/5] row-[2/2] sm:relative sm:col-[5/5] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                5
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[6/6] row-[2/2] sm:relative sm:col-[6/6] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                6
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[7/7] row-[2/2] sm:relative sm:col-[7/7] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                7
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative col-[8/8] row-[2/2] sm:relative sm:col-[8/8] sm:row-[2/2]">
              <div className="flex-1 relative leading-[17px] font-semibold">
                8
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative sm:relative">
              <div className="flex-1 relative leading-[17px] font-semibold">
                9
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="shadow-[0px_1px_0px_#898a8d] rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center min-h-[32px] relative sm:relative">
              <div className="flex-1 relative leading-[17px] font-semibold">
                0
              </div>
              <img
                className="w-6 relative h-6 object-cover hidden sm:w-5 sm:h-5"
                alt=""
                src="/iconkeyboardrepeat-icon-ver1@2x.png"
              />
            </div>
            <div className="rounded-10xs bg-orange-solid-orange-450-ffaa06 flex flex-row items-center justify-center py-2 px-1 relative col-[11/11] row-[1/1] text-sm text-white-solid-white-100-ffffff lg:relative lg:col-[11/11] lg:row-[1/1] lg:min-w-0 lg:max-w-[1750px] mq600:h-9 mq600:relative mq600:col-[11/11] mq600:row-[1/1] mq600:min-w-0 mq600:max-w-[1000px]">
              <div className="relative leading-[17px] font-semibold">
                Apply Now
              </div>
            </div>
            <div className="rounded-10xs bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center py-2 px-1 relative text-sm text-white-solid-white-100-ffffff lg:relative lg:min-w-0 lg:max-w-[1750px] mq600:h-9 mq600:relative mq600:min-w-0 mq600:max-w-[1000px]">
              <div className="relative leading-[17px] font-semibold">
                Add Button
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountKeyboard;
