import { context } from '@opentelemetry/api'
import { StringCodec } from 'nats.ws'

import { masterSocketLock, setMasterAvailable } from '@/data/data-utils.ts'
import { posSync0 } from '@/data/PosSyncState.ts'
import { getNatsClient } from '@/lib/nats-client.ts'
import { createSpan, runWithCurrentContext } from '@/lib/open-telemetry.ts'
import { getRxdbClient } from '@/lib/rxdb-client.ts'
import { getDeviceId } from '@/shared/getDeviceId.ts'
import debug from 'debug'
import { syncMode0 } from "@/data/DeviceSettingHub.ts";
import { SyncMode } from "@/data/data-enum.ts";

const log = debug('app:client-caller');

export interface CallOptions {
  onlyOffline?: boolean
  onlyOnline?: boolean
  retry?: number
  retryTime?: number
}

enum IntervalTime {
  Online = import.meta.env.MODE === 'production' ? 30000 : 10000,
  Offline = 5000
}

export default class ClientCaller {
  static clientPingInterval: ReturnType<typeof setInterval> | undefined

  static async callMasterCommand(method: string, args: any[], options: CallOptions = { retry: 0 }) {
    if (!posSync0()?.id!) return
    if (method === 'print') log('calling print');
    const { onlyOnline = false, onlyOffline = false } = options
    const span = createSpan('Calling master command', undefined, context.active())
    span?.setAttribute('storeId', `${posSync0()?.id!}`)
    span?.setAttribute('deviceId', getDeviceId())
    span?.setAttribute('onlyOnline', onlyOnline)
    const handleRetry = () => {
      if (options.retry && options.retry > 0) {
        setTimeout(() => {
          if (method === 'print') log('retry calling print');
          ClientCaller.callMasterCommand(method, args, { ...options, retry: (options.retry || 1) - 1 }).then();
        }, options.retryTime || 30000);
      } else {
        throw new Error('Can not connect to master')
      }
    }
    return runWithCurrentContext(span, async () => {
      if (!posSync0()?.id!) {
        const err = new Error('No post id')
        span?.recordException(err)
        span?.end()
        return
      }
      if (!onlyOnline) {
        const rxdbClient = await getRxdbClient(null, '')
        if (!!rxdbClient) {
          try {
            const res = await rxdbClient.callMasterCommand({
              method: method,
              params: args,
              isMasterCall: true,
            })
            if (!!res?.error) {
              throw new Error(res.error)
            }
            return res
          } catch (err: any) {
            span?.recordException(err)
            if (onlyOffline) handleRetry();
          }
        }
      }
      if (!onlyOffline) {
        try {
          const subject = `${posSync0()?.id}-master`
          const natsClient = getNatsClient()
          const response = await natsClient.getClient()?.request(
            subject,
            StringCodec().encode(
              JSON.stringify({
                method,
                params: args,
              })
            ),
            { timeout: 4000 }
          )
          if (response) {
            if (StringCodec().decode(response!.data) === '') return undefined;
            const res = JSON.parse(StringCodec().decode(response!.data))
            if (!!res?.error) {
              throw new Error(res.error)
            }
            return res
          }
          throw new Error('No response')
        } catch (err: any) {
          span?.recordException(err)
          span?.end()
          handleRetry();
        }
      }

      const err = new Error('No response')
      span?.recordException(err)
      span?.end()
      throw err
    })
  }

  static currentIntervalTime = IntervalTime.Offline

  static startClientPingInterval() {
    if (ClientCaller.clientPingInterval) {
      ClientCaller.stopClientPingInterval()
    }
    log(`startClientPingInterval ${ClientCaller.currentIntervalTime}`)
    const pingOffline = async () => {
      if (syncMode0() === SyncMode.online) return;
      try {
        log('ping master offline');
        await ClientCaller.callMasterCommand('pingMaster', [], { onlyOffline: true })
        setMasterAvailable(true)
        if (ClientCaller.currentIntervalTime !== IntervalTime.Online) {
          log('change interval to 30s')
          ClientCaller.currentIntervalTime = IntervalTime.Online
          ClientCaller.startClientPingInterval()
        }
      } catch (e) {
        setMasterAvailable(false)
        if (ClientCaller.currentIntervalTime !== IntervalTime.Offline) {
          log('change interval to 5s')
          ClientCaller.currentIntervalTime = IntervalTime.Offline
          ClientCaller.startClientPingInterval()
        }
      }
    };
    if (masterSocketLock._acquired) masterSocketLock.acquireAsync().then(() => pingOffline().then());
    ClientCaller.clientPingInterval = setInterval(pingOffline, ClientCaller.currentIntervalTime)
  }

  static stopClientPingInterval() {
    console.log('stopClientPingInterval')
    if (!ClientCaller.clientPingInterval) return
    clearInterval(ClientCaller.clientPingInterval)
    ClientCaller.clientPingInterval = undefined;
  }
}
