import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type AnnouncePopupType = {
  onClose?: () => void;
};

const AnnouncePopup: FunctionComponent<AnnouncePopupType> = ({ onClose }) => {
  const handleClickGo = () => {
    userFLow(`go to screen floor_plan`, {
      username: loginUser()?.name
    });
    router.screen = PosScreen.FLOOR_PLAN;
    onClose?.();
  }

  return (
    <div className="relative bg-basic-color-white w-full overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[24px] h-full max-w-full max-h-full text-center text-base text-gray font-mulish">
      <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
        <div className="self-stretch relative">
          {LL0().report.notCompletedPaymentWarning()}
        </div>
        <b className="self-stretch relative text-lg text-firebrick-100">
          {LL0().report.notCompletedPaymentWarning2()}
        </b>
      </div>
      <div className="rounded-md bg-basic-color-white box-border flex flex-row items-center justify-center py-2 px-4 min-w-[128px] text-mini text-royalblue-300 border-[1px] border-solid border-royalblue-400 uppercase"
           onClick={handleClickGo}
      >
        <div className="flex-1 relative font-semibold">{LL0().report.go()}</div>
      </div>
    </div>
  );
};

export default AnnouncePopup;
