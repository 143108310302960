import { type FunctionComponent, useState } from 'react';
import StatePending from './StatePending';
import PendingOrdersOrderDetail from './PendingOrdersOrderDetail';
import { acceptOrder, declineOrder, pendingOrders0, totalPendingOrder } from '@/react/PendingOrder/PendingOrderLogic';
import { hasProductMatches, mapProductAndCorrectOrderItem } from '@/react/PendingOrder/ProductMatchingLogic.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import PendingOrdersMissedCalls from '@pending-order/PendingOrdersMissedCalls.tsx';
import PendingOrdersOrder from '@pending-order/PendingOrdersPhoneCall.tsx';
import { calls0 } from '@/data/CallHub.ts';
import { onEnterNewDeliveryWithCustomer } from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { products0 } from "@/data/ProductHub.ts";
import PopupMatchProduct from "./ProductMatching/PopupMatchProduct.tsx";
import PopupMatchProducts from "./ProductMatching/PopupMatchProducts.tsx";
import Link from './LinkIcon.svg';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const PendingOrderLeftPath: FunctionComponent = () => {
  const [isProductsMatchingDialogOpen, setIsProductsMatchingDialogOpen] = useState(false);
  const [isProductMatchingDialogOpen, setIsProductMatchingDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();
  const [selectedItemIdx, setSelectedItemIdx] = useState<number>(-1);

  const openProductMatchDialog = function (order: any, itemIdx: number) {
    userFLow(`open product match dialog`, {
      username: loginUser()?.name
    });
    setSelectedOrder(order)
    setSelectedItemIdx(itemIdx)
    setIsProductMatchingDialogOpen(true)
  }

  const closeProductMatchDialog = function () {
    userFLow(`close product match dialog`, {
      username: loginUser()?.name
    });
    setIsProductMatchingDialogOpen(false)
  }

  const saveProductMatchResult = function({productId}: {productId: string}) {
    const product = products0().find(item => item._id === productId);
    if (!product) {
      console.error('product not found')
      return
    }
    mapProductAndCorrectOrderItem(selectedOrder, selectedItemIdx, product!);
  }

  return (
    <div className="overflow-y-auto no-scrollbar self-stretch flex-1 flex flex-col items-start justify-start px-0 pb-0 gap-[8px] text-left text-mini text-basic-color-white font-mulish Mobile_480:grid">
      <div className="h-[34px] min-h-[34px] line-height-[34px] self-stretch flex flex-col items-start justify-start gap-[4px] Tablet_768:flex">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-[8px] Tablet_600:flex-row">
            <b className="relative leading-[16px]">{LL0().onlineOrder.pendingOrders()}</b>
            <StatePending
              chipNumber={totalPendingOrder()}
              statePendingFlexShrink="0"
              statePendingBackgroundColor="#4caf50"
            />
          </div>

          <div className="flex flex-row gap-[8px]">
            {
              hasProductMatches() && <div
                className="rounded-md bg-basic-color-white overflow-hidden flex flex-row items-center justify-start py-1 px-2 gap-[5px] text-sm text-color-mau-dark border-[1px] border-solid border-grey-grey-lighten-2"
                onClick={() => {
                  userFLow(`open product match dialog`, {
                    username: loginUser()?.name
                  });
                  setIsProductsMatchingDialogOpen(true)
                }}>
                <img src={Link} alt="Link" className="w-[20px]"/>
                {LL0().onlineOrder.productMapping()}
              </div>
            }

            <div
              className="rounded-md bg-basic-color-white overflow-hidden flex flex-row items-center justify-start py-1 px-2 gap-[5px] text-sm text-color-mau-dark border-[1px] border-solid border-grey-grey-lighten-2"
              onClick={() => {
                userFLow(`new delivery with customer`, {
                  username: loginUser()?.name
                });
                onEnterNewDeliveryWithCustomer()
              }}
            >
              <div className="relative w-5 h-5 overflow-hidden shrink-0">
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/iconnew-delivery-icon@2x.png"
                />
              </div>
              <b className="relative">{LL0().onlineOrder.newDelivery()}</b>
            </div>
          </div>
        </div>
        {/*<div className="relative text-sm leading-[17px] font-medium text-tomato-100">*/}
        {/*  Modem not connected*/}
        {/*</div>*/}
      </div>
      { calls0()?.length > 0 && (<>
        <PendingOrdersMissedCalls
          iconCancelCall="/iconcancel-call@2x.png"
          iconDeliverIcon="/icondeliver-icon@2x.png"
          isExpanded
          isClosed={false}
        />
        {
          calls0().map(incomingCall => (
            <PendingOrdersOrder
              incomingCall={incomingCall}
              key={incomingCall._id}
            />
          ))
        }
      </>) }
      <div className="w-full flex flex-col gap-[8px]">
        {pendingOrders0().map(order => <PendingOrdersOrderDetail
            key={order._id}
            order={order}
            onAccept={(storeExpectedDate) => acceptOrder(order, storeExpectedDate)}
            onDecline={(reason) => declineOrder(order, reason)}
            onOpenProductMatchDialog={(itemIdx: number) => openProductMatchDialog(order, itemIdx)}
        />)}
        {totalPendingOrder() === 0 && <div className="min-h-[200px] h-[50%] flex bg-white w-full items-center justify-center rounded-[4px]">
          <p className="text-black">{LL0().onlineOrder.noPending()}</p>
        </div>}
      </div>
      {isProductMatchingDialogOpen &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeProductMatchDialog}
        >
          <PopupMatchProduct
            onClose={closeProductMatchDialog}
            onSave={saveProductMatchResult}
            product={selectedItemIdx >= 0 ? selectedOrder.items[selectedItemIdx] : {}}
          />
        </PortalPopup>
      }

      {
        isProductsMatchingDialogOpen &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeProductMatchDialog}>
          <PopupMatchProducts
            onClose={() => setIsProductsMatchingDialogOpen(false)}
          />
        </PortalPopup>
      }
    </div>
  );
};

export default PendingOrderLeftPath;
