import dialogService from "@/react/SystemService/dialogService.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export const dialogConfirmMergeSeat = async (onConfirm: () => void) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className={`w-full relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center p-3 box-border gap-3 min-w-[150px] mq432:p-0 max-w-[480px] h-full max-h-full text-center text-base text-black-solid-black-880-1d1d26 font-mulish`}
      >
        <div className="self-stretch relative leading-[28px] font-medium">
          {LL0().order.confirmMergeSeatsExplain()}
        </div>
        <div className="self-stretch flex flex-row items-center justify-center text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="w-[129px] rounded-md bg-white-solid-white-100-ffffff border-blue-solid-blue-420-2979ff border-[1px] border-solid box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 max-w-[128px] cursor-pointer"
            onClick={() => {
              userFLow(`confirm merge seat`, {
                username: loginUser()?.name
              });
              onConfirm()
              onClose?.()
            }}
          >
            <b className="relative">{LL0().ui.confirm()}</b>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
