import { type FunctionComponent, useEffect } from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion-seats.tsx';
import { useSignal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import clsx from "clsx";
import { menu0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import _ from "lodash";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  decreasePropertyValuesScreenConfig,
  increasePropertyValuesScreenConfig
} from "@/react/OrderView/OrderView.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

export type EditMenuEditScreenType = {
  className?: string;
  onClose?: () => void;
};

const EditMenuEditScreen: FunctionComponent<EditMenuEditScreenType> = ({
  className = "",
  onClose,
}) => {

  const [editScreenTab, setEditScreenTab] = useSignal<string>('1')

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className={`!opacity-100 w-full h-[780px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start pt-1 px-0 pb-4 box-border gap-4 [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-bottom] opacity-[0] max-h-[90%] text-left text-mini text-white-solid-white-100-ffffff font-mulish ${className}`}
      data-animate-on-scroll
    >
      <Accordion type="single" className={'w-full h-full flex flex-col gap-2'}
                 collapsible
                 value={editScreenTab()}
                 onValueChange={val => {
                   setEditScreenTab(val)
                 }}
      >
        <AccordionItem key={'1'} value={'1'} onClick={() => setEditScreenTab('1')}>
          <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-center justify-start py-0 px-2 gap-1.5">
              <AccordionTrigger text={LL0().article.category()} isPaid={false} />
              <AccordionContent className={'w-full'}>
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-1 text-sm text-black-solid-black-900-1e1e23">
                  <div className="gap-4 self-stretch rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start p-3">
                    <div className="self-stretch flex flex-col items-start justify-center gap-2">
                      <b className="relative">
                        {LL0().productViewSetting.height()}
                      </b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-[#DBDBDB]">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("categoryHeight", 4, 42)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative text-[#1e1e23]">{deviceSetting0()?.screenConfig?.categoryHeight}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("categoryHeight", 4)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-center gap-2">
                      <b className="relative ">
                        {LL0().productViewSetting.fontSize()}
                      </b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-[#DBDBDB]">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("categoryFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative text-[#1e1e23]">{deviceSetting0()?.screenConfig?.categoryFontSize}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("categoryFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </div>
          </div>
        </AccordionItem>
        {!generalSetting0()?.useMenuV2 &&
          <AccordionItem key={'2'} value={'2'} onClick={() => setEditScreenTab('2')}>
            <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start">
              <div className="self-stretch flex flex-col items-center justify-start py-0 px-2 gap-1.5">
                <AccordionTrigger text={LL0().order.section()} isPaid={false} />
                <AccordionContent className={'w-full'}>
                  <div className="self-stretch flex flex-col items-center justify-start py-0 px-1 text-sm text-black-solid-black-900-1e1e23">
                    <div className="self-stretch rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start p-3">
                      <div className="self-stretch flex flex-col items-start justify-center gap-2">
                        <b className="relative">
                          {LL0().order.setItemColumnNumberPerSection()}:
                        </b>
                        <div className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-2 text-mini text-black-solid-black-880-1d1d26">
                          {[2, 3, 4].map((item) => (
                            <div
                              onClick={() => {
                                _.assign(menu0(), { maxColumn: item })
                              }}
                              key={item}
                              className="flex-1 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-2 relative gap-2 min-w-[89px]">
                              <div className={clsx("w-[calc(100%_+_0.3px)] absolute !m-[0] h-full top-[0px] right-[-0.3px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border z-[0]"
                                , { "hidden": menu0()?.maxColumn !== item })} />
                              <div className="w-[calc(100%_+_0.3px)] hidden absolute !m-[0] h-full top-[0px] right-[-0.3px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee border-gray-solid-gray-150-eeeeee border-[2px] border-solid box-border z-[1]" />
                              <div className="relative z-[2]">{item} {LL0().restaurant.menuEdit.columns()}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionContent>
              </div>
            </div>
          </AccordionItem>
        }
        <AccordionItem key={'3'} value={'3'} onClick={() => setEditScreenTab('3')}>
          <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-center justify-start py-0 px-2 gap-1.5">
              <AccordionTrigger text={LL0().article.product()} isPaid={false} />
              <AccordionContent className={'w-full'}>
                <div className="self-stretch flex flex-col items-center justify-start py-0 px-1 text-sm text-black-solid-black-900-1e1e23">
                  <div className="gap-4 self-stretch rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start p-3">
                    <div className="self-stretch flex flex-col items-start justify-center gap-2">
                      <b className="relative">
                        {LL0().order.itemCardHeight()}
                      </b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-[#DBDBDB]">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("itemCardHeight", 2)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative text-[#1e1e23]">{deviceSetting0()?.screenConfig?.itemCardHeight}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("itemCardHeight", 2)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-center gap-2">
                      <b className="relative">
                        {LL0().productViewSetting.fontSize()}
                      </b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-[#DBDBDB]">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("productFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative text-[#1e1e23]">{deviceSetting0()?.screenConfig?.productFontSize}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("productFontSize", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-center gap-2">
                      <b className="relative ">
                        {LL0().order.textLineHeight()}
                      </b>
                      <div className="self-stretch rounded-sm flex flex-row items-center justify-between text-base text-materialize-grey-darken-3 border-[1px] border-solid border-[#DBDBDB]">
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => decreasePropertyValuesScreenConfig("textLineHeight", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconsteper-minus@2x.png"
                          />
                        </div>
                        <b className="relative text-[#1e1e23]">{deviceSetting0()?.screenConfig?.textLineHeight}px</b>
                        <div className="w-12 box-border h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-[#DBDBDB]"
                             ref={makeRipple}
                             onClick={() => increasePropertyValuesScreenConfig("textLineHeight", 0.5)}
                        >
                          <img
                            className="w-6 relative h-6 object-cover"
                            alt=""
                            src="/iconstepper-plus@2x.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionContent>
            </div>
          </div>
        </AccordionItem>
      </Accordion>
      <div className="self-stretch flex flex-row items-center justify-center py-0 px-2 box-border w-full text-center text-base">
        <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center py-[9px] px-3 relative gap-[3px]"
             ref={makeRipple}
             onClick={onClose}
        >
          <img
            className="w-6 relative h-6 object-cover hidden z-[0]"
            alt=""
            src="/iconcash-registerplus-icon-ver12@2x.png"
          />
          <b className="relative z-[1]">{LL0().productViewSetting.save()}</b>
          <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-981xl bg-darkslategray hidden z-[2]" />
        </div>
      </div>
    </div>
  );
};

export default EditMenuEditScreen;
