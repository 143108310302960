import { type FunctionComponent } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import { product0, pushDataVertical } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { handleInputPopup, ITEM_MODE, PRODUCT_COLORS, setEditorMode0 } from "@/react/EditMenuView/EditMenuView.tsx";
import { AccessPort, getAccessibility } from "@/react/DecentralizationPort.ts";
import { PosScreen } from "@/pos/PosRouter.ts";
import { mappedInventories } from "@/data/InventoryHub.ts";
import TextField from "@/react/core/TextField.tsx";
import { dineIn, menuSelected, pricingLevelFactory } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import Input3KeyboardPopUp from "@/react/core/Input3KeyboardPopUp2.tsx";

export type EditModifierFormType = {
  className?: string;
};

const VerticalEditModifierForm: FunctionComponent<EditModifierFormType> = ({
  className = "",
}) => {

  const {
    computedPrice,
    changeProductPrice, getInfo
  } = pricingLevelFactory(product0())

  const { multipleEnable } = getInfo()

  return (
    <div
      className={`!flex !w-full w-[480px] hidden flex-col items-start justify-start pt-2 px-3 pb-4 box-border gap-[15px] text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish lg:box-border mq350small:pl-3 mq350small:pr-3 mq350small:box-border ${className}`}
    >
      <div className="self-stretch flex flex-row items-center justify-start gap-3">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          {LL0().article.type()}:
        </b>
        <FormControl
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Select color="primary" size="small"
                  value={product0()?.layout?.type || 'Article'}
                  onChange={(e) => {
                    const _productLayout = product0()?.layout;
                    if (_productLayout) _productLayout.type = e.target.value as any
                  }}>
            <MenuItem value="Article">{LL0().article.types.normalItem()}</MenuItem>
            <MenuItem value="NonFixed">{LL0().article.types.nonFixedItem()}</MenuItem>
            <MenuItem value="Extra">{LL0().article.types.extra()}</MenuItem>
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <Input3KeyboardPopUp
        value={''}
        onSave={(name, id, price) => {
          if (!product0()) return;
          product0()!.name = name
          product0()!.id = id
          if (multipleEnable && !(menuSelected() === dineIn())) {
            product0()!.menuPrices!.find(m => m.menu === menuSelected())!.value = parseFloat(price || '0')
          } else {
            product0()!.price = parseFloat(price || '0')
          }
        }}>
        {(show, setShow, inputControllers) => (
          <>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.name()}</span>
                <span className="text-firebrick">*</span>
                <span>:</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={product0()?.name || ''}
                onChange={(e) => {
                  const _product = product0()
                  if (_product) _product.name = e.target.value
                }}
                onKeyboardIconClick={() => {
                  handleInputPopup(setShow, inputControllers, pushDataVertical, 0)
                }}
              />
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.id()}</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={product0()?.id || ''}
                onChange={(e) => {
                  const _product = product0()
                  if (_product) _product.id = e.target.value
                }}
                onKeyboardIconClick={() => {
                  handleInputPopup(setShow, inputControllers, pushDataVertical, 1)
                }}
              />
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.price()}</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={computedPrice() || ''}
                onChange={(e) => {
                  changeProductPrice(e.target.value)
                }}
                onKeyboardIconClick={() => {
                  if (!getAccessibility(AccessPort.EDIT_ITEM_PRICE)) return;
                  handleInputPopup(setShow, inputControllers, pushDataVertical, 2)
                }}
              />
            </div>
          </>
        )}
      </Input3KeyboardPopUp>
      {/*<>fixme: set color</>*/}
      <div className="self-stretch flex flex-row items-start justify-start gap-3 text-sm">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          {LL0().ui.color()}:
        </b>
        <div className="flex-1 flex flex-row items-start justify-start flex-wrap content-start gap-3">
          {PRODUCT_COLORS.map((color, index) =>
            <div
              className="rounded-981xl bg-white-solid-white-100-ffffff flex flex-col items-start justify-start relative"
              key={index}
              style={{ backgroundColor: color }}
              onClick={() => {
                _.assign(product0()?.layout, { color })
              }}
            >
              <div
                className="w-10 relative rounded-981xl border-gray-solid-gray-320-d2d2d2 border-[1px] border-solid box-border h-10 z-[0]" />
              {product0()?.layout?.color === color &&
                <>
                  <div
                    className="w-10 absolute !m-[0] top-[-0.1px] left-[0px] rounded-981xl border-blue-solid-blue-450-1271ff border-[1px] border-solid box-border h-10 z-[1]" />
                  <img
                    className="w-4 absolute !m-[0] top-[0px] right-[0px] h-4 object-cover z-[2]"
                    alt=""
                    src="/iconedit-menuchecked-icon-ver1@2x.png"
                  />
                </>
              }
            </div>
          )}
        </div>
      </div>

      {/*<>fixme: ingredient</>*/}
      {/*{getAccessibility(PosScreen.INVENTORY) &&*/}
      {/*  <div className="self-stretch flex flex-col items-start justify-start gap-2.5">*/}
      {/*    <div*/}
      {/*      className="cursor-pointer flex flex-row items-center justify-start gap-1"*/}
      {/*      onClick={() => setEditorMode0(ITEM_MODE.INGREDIENT)}*/}
      {/*    >*/}
      {/*      <img*/}
      {/*        className="w-6 relative h-6 object-cover"*/}
      {/*        alt=""*/}
      {/*        src="/iconicon-screensedit-icon-ver11@2x.png"*/}
      {/*      />*/}
      {/*      <b*/}
      {/*        className="relative [text-decoration:underline] sm:text-sm">{LL0().productViewSetting.ingredients()}:</b>*/}
      {/*    </div>*/}
      {/*    {product0().ingredients?.map((ingredient, index) => {*/}
      {/*      const foundInventory = mappedInventories().find(inventory => inventory._id === ingredient.inventoryId)*/}
      {/*      return (*/}
      {/*        <div*/}
      {/*          className="self-stretch flex flex-row items-start justify-start flex-wrap content-start text-black-solid-black-880-1d1d26"*/}
      {/*          key={index}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2">*/}
      {/*            <div*/}
      {/*              className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border hidden z-[0]" />*/}
      {/*            <div*/}
      {/*              className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee border-gray-solid-gray-150-eeeeee border-[2px] border-solid box-border z-[1]" />*/}
      {/*            <div*/}
      {/*              className="relative z-[2]">{foundInventory?.product?.name}: {ingredient.amount}{foundInventory?.unit}</div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      )*/}
      {/*    })}*/}
      {/*  </div>}*/}

    </div>
  );
};

export default VerticalEditModifierForm;