import OrderScreenCart from '@vertical-order-view/OrderScreenCart.tsx'
import OrderScreenPlugin2 from '@vertical-order-view/OrderScreenPlugin2.tsx'
import _ from 'lodash'
import { memo } from 'react'

import { makeDiscountsAvailable } from '@/data/DiscountHub.ts'
import { groupPrinterV, makeGroupPrintersAvailable, setGroupPrinterV } from '@/data/GroupPrinterHub.ts'
import { makeHappyHoursAvailable } from '@/data/HappyHourHub.ts'
import { makeMaxIdsAvailable } from '@/data/MaxIdHub.ts'
import { makeModifiersAvailable } from '@/data/ModifierHub.ts'
import { Order } from '@/data/Order.ts'
import { makeOrderCommitsAvailable } from '@/data/OrderCommitsHub.ts'
import { removeOrder, updateOrder } from '@/data/OrderHub.ts'
import { makePaymentsAvailable } from '@/data/PaymentHub.ts'
import { generalSetting0, isQuebecSrmEnabled, mainScreen, mainScreenVertical, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { makeTaxCategoriesAvailable } from '@/data/TaxCategoryHub.ts'
import { logOut } from '@/data/UserHub.ts'
import { makeVouchersAvailable } from '@/data/VoucherHub.ts'
import { createOrder, resetOrder } from '@/pos/logic/order-reactive.ts'
import { CommitAction, OrderStatus, type TOrder } from '@/pos/OrderType.ts'
import { onEnter, onExit, params, PosScreen, router } from '@/pos/PosRouter.ts'
import { signal, useSignalEffect } from '@/react/core/reactive.ts'
import { isManual } from '@/react/FloorPlanView/FloorPlanView.tsx'
import { useOrderEditLocking } from '@/react/hooks/useOrderEditLocking.ts'
import { itemContext, onMovedTablePrint, setOrderMode0 } from '@/react/OrderView/OrderView.tsx'
import { isOrderViewCart, order0, OrderMode, setOrder0 } from '@/react/OrderView/OrderViewShare.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic.ts'
import { ItemFactoryContext } from '../PaymentView/OrderItemsFactory2'
import { loginUsers, isValidSrmUser } from "@/data/UserSignal.computed.ts";


export const [isDiscountPopupVertical, setDiscountPopupVertical] = signal<boolean | number>(false)
export const [isEditDiscountLabel, setEditDiscountLabel] = signal(false);

export const checkItemsRemaining = (order?: TOrder) => {
  if (!order) return
  const remaining = _.sumBy(order.items, i => i.quantity - (i.movedQuantity || 0));
  if (remaining > 0) return
  return true
}

export const calculateItemLength = (order: Order) => {
  let total = 0;
  if (order?.items) {
    order?.items?.forEach((item) => {
      total += item.quantity
    });
  }
  return total;
}

//todo: fix duplicated
export const onBack = async () => {
  const currentOrder = order0()
  const isPrinted = currentOrder.commits?.some(commit => commit.action === CommitAction.PRINT)
  const isFastCheckout = !currentOrder.table
  if (!isPrinted && isFastCheckout) {
    await removeOrder(currentOrder)
  }

  if (!generalSetting0()?.hasTablePlan) return await logOut(true)

  if (generalSetting0()?.useMenuV2) {
    router.screen = mainScreen()
  } else {
    router.screen = mainScreenVertical()
  }
}

const VerticalOrderView = () => {
  makeTaxCategoriesAvailable()
  makeGroupPrintersAvailable()
  makeHappyHoursAvailable()
  makeMaxIdsAvailable().then()
  useOrderEditLocking(order0()._id, PosScreen.VERTICAL_ORDER)

  onEnter(PosScreen.VERTICAL_ORDER, () => {
    if (params()?.orderMode === OrderMode.FAST_CHECKOUT || !posSetting0()?.generalSetting?.hasTablePlan) {
      setOrderMode0(OrderMode.FAST_CHECKOUT)
      const order = createOrder({ users: loginUsers() })
      if (posSetting0()?.defaultTakeaway) _.assign(order, { takeAway: true })
      setOrder0(order)
    } else if (params()?.table) {
      setOrderMode0(OrderMode.TABLE)
      const order = createOrder({ table: params().table, users: loginUsers(), manual: isManual() })
      if (params()?.takeAway) _.assign(order, { takeAway: true })
      setOrder0(order)
    } else if (params()?.order) {
      setOrderMode0(OrderMode.TABLE)
      const _order = params()?.order
      const order = createOrder(resetOrder(_order as Order))
      setOrder0(order)
      if (params()?.isMoveTable) {
        onMovedTablePrint().then()
      }
    }
    if (order0().seatMode) {
      itemContext.setSeat(order0().seatMap!.findIndex(o => o.status !== OrderStatus.PAID))
    }

    console.log('onEnter...', params()?.table)
  })

  makeGroupPrintersAvailable()
  makeDiscountsAvailable()
  makePaymentsAvailable()
  makeModifiersAvailable()
  makeVouchersAvailable()
  makeOrderCommitsAvailable()

  useSignalEffect(() => {
    if (groupPrinterV() === 0) setGroupPrinterV(1)
  })

  onExit(PosScreen.ORDER, () => {
    itemContext.setSplitEnable(false)
  })
  return (
    <ItemFactoryContext.Provider value={itemContext}>
      <>
        {isOrderViewCart() ? <OrderScreenCart /> : <OrderScreenPlugin2 />}
      </>
    </ItemFactoryContext.Provider>
  )
}

export default memo(VerticalOrderView);