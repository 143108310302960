import type {FunctionComponent} from "react";
import SeatSelectedButton from "@payment/SeatSelectedButton";
import { order0, paymentContext0 } from "@/react/PaymentView/PaymentView.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const SplitPopupSeatsArrow: FunctionComponent = () => {
  const {order0, isCurrentSeat, setCurrentSeat} = paymentContext0;
  return (
    <div className="no-scrollbar h-full overflow-y-auto w-[123.46px] flex flex-col items-end justify-start text-left text-sm text-gray-400 font-mulish">
      <div className="flex flex-col items-end justify-start gap-[8px]">
        <div
          className="rounded bg-basic-color-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.25)] shrink-0 hidden flex-row items-center justify-center py-[15px] px-3">
          <b className="relative">{LL0().payment.seat().toUpperCase()} 1</b>
        </div>
        {order0()?.seatMap.map((sOrder, index) => (
          <SeatSelectedButton key={index} selected={isCurrentSeat(index)} seatNumber={`${index + 1}`}
                              onClick={() => {
                                userFLow("seat number in Payment", {
                                  username: loginUser()?.name,
                                  orderId: order0?.()._id
                                })
                                setCurrentSeat(index)
                              }}/>
        ))}
      </div>
    </div>
  );
};

export default SplitPopupSeatsArrow;