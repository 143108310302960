import dialogService from "@/react/SystemService/dialogService.tsx";
import { clsx } from "clsx";
import _ from "lodash";

import { getUrlParam } from "@/shared/utils2.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { ModeConnect } from "@/react/Developer/DataMenu.logic.ts";

export const dialogConnect = async (onConnectMaster: () => void, onConnectClient: () => void, onConnectSupport: () => void) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className='w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center box-border gap-[24px] min-w-[280px] pb-4 h-full max-w-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish'
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px]">
            Connect to the store as
          </b>
        </div>
        <div
          className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-3 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={() => {
              _.assign(deviceSetting0(), { isSupportDevice: false, show: true });
              localStorage.setItem('modeConnect', ModeConnect.DEFAULT.toString())
              onConnectMaster()
              onClose()
            }}
          >
            <b className="relative">Master</b>
          </div>
          <div
            className={clsx(`${getUrlParam('os') ? "bg-blue-solid-blue-420-2979ff" : "bg-white-solid-white-100-ffffff"} flex-1 rounded-md box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff `)}
            onClick={() => {
              _.assign(deviceSetting0(), { isSupportDevice: false, show: true })
              localStorage.setItem('modeConnect', ModeConnect.DEFAULT.toString())
              onConnectClient()
              onClose()
            }}
          >
            <b className={clsx(`relative ${getUrlParam('os') ? 'text-white' : 'text-blue'}`)}>Client</b>
          </div>
          <div
            className={clsx(`${!getUrlParam('os') ? "bg-blue-solid-blue-420-2979ff" : "bg-white-solid-white-100-ffffff"} flex-1 rounded-md box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff `)}
            onClick={() => {
              _.assign(deviceSetting0(), { isSupportDevice: true, show: false })
              localStorage.setItem('modeConnect', ModeConnect.DEFAULT.toString())
              onConnectSupport()
              onClose()
            }}
          >
            <b className={clsx(`relative ${!getUrlParam('os') ? 'text-white' : 'text-blue'}`)}>Support</b>
          </div>
        </div>

      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
