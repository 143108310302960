import type { FunctionComponent } from "react";
import { layoutCategories0, orderLayout0, setCategoryIndex } from '@/react/OrderView/OrderView.tsx';
import GridElement from '@/react/core/GridElement.tsx';
import { ColorDarken } from '@/react/core/ColorDarken.tsx';
import GridLayout from '@/react/core/GridLayout.tsx';

import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const CategoryTop: FunctionComponent = () => {
  return (
    <GridLayout rows={orderLayout0()?.rows || 0} cols={orderLayout0()?.columns || 0} colGap={5}
                style={{ padding: "5px", minHeight: (orderLayout0()?.rows || 1) * 38 + 5 , height:(deviceSetting0()?.screenConfig?.categoryHeight)}} rowGap={5} className="w-full">
      {layoutCategories0().map((category, index) => (
          <ColorDarken color={category.color} key={index}>
            {(color, setClicked) => (
              <GridElement x={category.left} y={category.top} cols={1} rows={1}
                           style={{ background: color }}
                           className={"rounded-[5px] flex center"}
                           onClick={e => {
                             userFLow(`choose category ${category.name}`, {
                               username: loginUser()?.name
                             });
                             setCategoryIndex(index)
                             setClicked(true)
                           }}>
                <b className="text-ellipsis whitespace-nowrap overflow-hidden text-text-color-black-1e1e23 font-mulish"
                   style={{fontSize: deviceSetting0()?.screenConfig?.categoryFontSize, lineHeight: `${deviceSetting0()?.screenConfig?.categoryLineHeight}px`}}
                >
                  {category.name}
                </b>
              </GridElement>
            )}
          </ColorDarken>
      ))}
    </GridLayout>
  );
};

export default CategoryTop;
