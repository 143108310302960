import type { RxCollection, RxJsonSchema } from 'rxdb'

import { MENU_COLLECTION_NAME } from './Menu'

export const MODIFIER_COLLECTION_NAME = 'modifiers'

export enum OPTION_DISPLAY_TYPES {
  onlyOne = 'Only One',
  multiple = 'Multiple',
  quantity = 'Quantity',
  select = 'Select',
}

export enum ModifierType {
  VARIANT = 'variant',
  MODIFIER = 'modifier',
}

export interface ModifierItem {
  _id: string
  name?: string
  tax?: number
  price?: number
  type?: string
  position?: number
  isEnabled?: boolean
  typeQuantity_itemCount?: number
  typeQuantity_selectLimit?: number
  typeSelect_group?: string
  menuPrices: { menu: string; value: number }[]
  menuTaxes: { menu: string; value: number }[]
}
export interface ModifierItemGroup {
  _id: string
  name?: string
}

export interface Modifier {
  _id: string
  name?: string
  products?: string[]
  selectOne?: boolean
  mandatory?: string
  groupPrinter?: string
  items?: ModifierItem[]
  itemGroups?: ModifierItemGroup[]
  position?: number
  type?: OPTION_DISPLAY_TYPES
  useType?: ModifierType
  minSelected?: number
  freeSelected?: number
  menus?: string[]
}

const VERSION = 18

export const modifierSchema: RxJsonSchema<Modifier> = {
  title: 'modifier schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    products: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    selectOne: { type: 'boolean' },
    mandatory: { type: 'boolean' },
    groupPrinter: { type: 'string' },
    items: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string', maxLength: 24 },
          name: { type: 'string', maxLength: 50 },
          tax: { type: 'number' },
          price: { type: 'number' },
          type: { type: 'string' },
          position: { type: 'number' },
          isEnabled: { type: 'boolean' },
          typeQuantity_itemCount: { type: 'number' },
          typeQuantity_selectLimit: { type: 'number' },
          typeSelect_group: { type: 'string' },
          menuPrices: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                menu: { type: 'string' },
                value: { type: 'integer' },
              },
            },
          },
          menuTaxes: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                menu: { type: 'string' },
                value: { type: 'integer' },
              },
            },
          },
        },
        required: ['_id'],
      },
    },
    itemGroups: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string', maxLength: 24 },
          name: { type: 'string', maxLength: 24 },
        },
        required: ['_id'],
      },
    },
    position: { type: 'number' },
    type: { type: 'string' },
    useType: { type: 'string' },
    minSelected: { type: 'number' },
    freeSelected: { type: 'number' },
    menus: { type: 'array', ref: MENU_COLLECTION_NAME, items: { type: 'string' } },
  },
  indexes: [],
  required: ['_id'],
}

export const Modifier = {} as RxCollection<Modifier>

Object.assign(window, { Modifier })
