import DeveloperOnlyVerifyPopu from '@dashboard/DeveloperOnlyVerifyPopu.tsx'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import { clsx } from 'clsx'
import dayjs from 'dayjs'
import delay from 'delay'
import Dexie from 'dexie'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { toast, type Id } from 'react-toastify'
import { RecordId } from 'surrealdb.js'

import { Button } from '@/components/ui/button.tsx'
import { SyncMode } from '@/data/data-enum'
import { dataLock } from '@/data/DataUtils.ts'
import { DeviceSetting } from '@/data/DeviceSetting.ts'
import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { GroupPrinter } from '@/data/GroupPrinter.ts'
import { PosSetting } from '@/data/PosSetting.ts'
import { setServer } from '@/data/PosSettingHub.ts'
import { posSetting0, posSettingLock, posSettings0, setTempServer } from '@/data/PosSettingsSignal.ts'
import { posSync0, posSyncLock, posSyncLockReady } from '@/data/PosSyncState.ts'
import { Product } from '@/data/Product.ts'
import { ProductLayout } from '@/data/ProductLayout.ts'
import { setForceOnlineSync } from '@/data/ReplicateEffect.ts'
import { firstTimeConnect, setNeedWaitedForMaster } from '@/data/ReplicateState.ts'
import { Room, RoomObject } from '@/data/Room.ts'
import { server } from '@/data/Server.ts'
import { User } from '@/data/User.ts'
import fetchAdapter from '@/lib/fetch-adapter.js'
import { reloadIfMasterChanged, upsertMaster } from '@/lib/fetch-master.ts'
import { setIsMaster } from '@/lib/master-signal.ts'
import { now } from '@/pos/logic/time-provider.ts'
import { sendDeviceName } from '@/pos/SendDeviceInfo.ts'
import { availableServers } from '@/react/CompanyInformationView/companyInfomation.logic.ts'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { signal, useSignal } from '@/react/core/reactive.ts'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import TextField from '@/react/core/TextField.tsx'
import { ModeConnect, setProcessing, type PairingCode, type PosUser } from '@/react/Developer/DataMenu.logic.ts'
import { currentMode, onConnect, onDisconnect, replicateFactory, setCurrentMode } from '@/react/Developer/developer.logic.ts'
import { setReplicateServerUserNamePassword } from '@/react/Developer/Developer.tsx'
import { dialogConnect } from '@/react/Developer/dialogConnect.tsx'
import { clearPrintCache } from '@/react/Printer/print-invoice-api.ts'
import dialogService from '@/react/SystemService/dialogService.tsx'
import msgBox from '@/react/SystemService/msgBox.tsx'
import { useDeviceId } from '@/shared/getDeviceId'
import SurrealClient from '@/shared/SurrealClient.ts'
import { getApiUrl } from '@/shared/utils.ts'
import { getOsFromParam, getUrlParam } from '@/shared/utils2.ts'


export const DISCONNECT_PASSWORD = "999"

export const [isChangeBg,setIsChangeBg] = signal<boolean>(!!localStorage.getItem('isDisconnectStore'))

export async function clearIndexedDb() {
  for (const dbName of ['pos', 'pos2']) {
    const db = new Dexie(dbName)
    await db.open()
    await db.delete();
    db.close();
  }
  localStorage.removeItem('rx-storage-localstorage-pos--_rxdb_internal--0');
  localStorage.removeItem('rx-storage-localstorage-changestream-pos--_rxdb_internal--0');
}

async function checkDataExists() {
  const products = await Product.find().exec();
  if (products.length > 0) return true;

  const productLayouts = await ProductLayout.find().exec();
  if (productLayouts.length > 0) return true;

  const rooms = await Room.find().exec();
  if (rooms.length > 0) return true;

  const roomObjects = await RoomObject.find().exec();
  if (roomObjects.length > 0) return true;

  const posSettings = await PosSetting.find().exec();
  if (posSettings.length > 0) return true;

  const users = await User.find().exec();
  if (users.length > 0) return true;

  const printers = await GroupPrinter.find().exec();
  if (printers.length > 0) return true;
}

async function shouldInitStore() {
  const needInitStoreStep = parseInt(localStorage.getItem("needInitStoreStep") || "0");
  console.log("shouldInitStore", needInitStoreStep);
  if (needInitStoreStep === 2) {
    firstTimeConnect.v = true;
    setIsMaster(false);
    await dataLock.acquireAsync();
    //check if data exists
    if (await checkDataExists()) {
      await clearIndexedDb();
      await delay(500);
      location.reload();
      return;
    }
    const storeId = localStorage.getItem("posDatabase");
    const tempServer = localStorage.getItem("tempServer");
    const password = localStorage.getItem("password")
    const master = localStorage.getItem("connectAsMaster") === 'true';
    const modeConnect = localStorage.getItem("modeConnect");
    setTempServer(tempServer!);
    setForceOnlineSync(true);
    await delay(500);
    await posSyncLockReady.acquireAsync();
    setReplicateServerUserNamePassword(parseInt(storeId!), password!)
    posSync0().replicationIdentifierPrefix = posSync0().replicationIdentifierPrefix ? posSync0().replicationIdentifierPrefix! + 1 : 1;
    posSync0()!.id = parseInt(storeId!);
    localStorage.removeItem("password");
    localStorage.removeItem("needInitStoreStep");
    localStorage.removeItem("posDatabase");
    const api = replicateFactory()
    if (modeConnect === ModeConnect.DEVELOPER.toString()) {
      localStorage.setItem('isDisconnectStore', 'true')
      setIsChangeBg(true)
      await api.connectAsDevelopver()
      return
    }
    setIsChangeBg(false)
    localStorage.removeItem('isDisconnectStore')
    if (master) {
      await api.connectAsMaster()
    } else {
      await api.connectAsClient()
    }
    await posSettingLock.acquireAsync()

    if (!getOsFromParam()) {
      deviceSetting0()!.syncMode = SyncMode.online;
    }

    if (!deviceSetting0()?.devicePos && !deviceSetting0()?.isSupportDevice) {
      const devices = await DeviceSetting.find({ sort: [{ devicePos: 'desc' }] }).exec()
      _.assign(deviceSetting0(), {
        devicePos: devices[0]?.devicePos ? devices[0].devicePos + 1 : devices.length,
        createdAt: dayjs(now()).unix()
      })
    }
    setNeedWaitedForMaster(true)
    setTimeout(() => setNeedWaitedForMaster(false), 5000);
    setForceOnlineSync(false);
    sendDeviceName().then()
  } else {
    localStorage.removeItem("tempServer");
  }
}

shouldInitStore().then();

interface RegisterStoreProps {
  type: 'Developer' | 'Installation';
}

export const [isDisabledConnect, setIsDisabledConnect] = signal<boolean>(false)

const RegisterStore = ({ type }: RegisterStoreProps) => {
  const [name, setName] = useSignal<string>('')
  const [posDatabase, setPosDatabase] = useSignal<string>("");
  const [isDisconnectVerifyPopupOpen, setIsDisconnectVerifyPopupOpen] = useSignal(false)
  const deviceId = useDeviceId();

  const initDatabase = () => {
    const connectDbCloud = async () => {
      await posSyncLock.acquireAsync();
      const db = await SurrealClient.getSurrealClient('cloud')
      const storeId = posSync0()?.id
      if (!storeId) return undefined
      return await db.select<PosUser>(new RecordId('PosUser', storeId))
    }
    return {
      connectDbCloud
    }
  }

  async function onRegister() {
    if (!deviceSetting0()) {
      toast.error("Something went wrong")
      return
    }

    const closeRegisterDialog = dialogService.progress({ title: "Register" });

    try {
      const response = await axios.post(
        `${getApiUrl()}/api/registerUserWithoutAuthV1`,
        { name: name() },
        {
          responseType: "stream",
          adapter: fetchAdapter
        }
      );

      const stream = response.data;
      let i = 0;
      let current;
      let content = "";
      let posDatabase;
      for await (const chunk of stream) {
        content += chunk + "\n";
        if (i === 0) {
          console.log('first')
          current = toast(content, {
            autoClose: false
          })
          posDatabase = JSON.parse(chunk.split('}')[0] + '}')
        } else {
          toast.update(current as Id, {
            // data: chunk,
            render: content
            // autoClose: 1000
          })
        }
        i++
      }
      //todo: stream result
      //fixme: create new collection
      const storeId = parseInt(posDatabase.id.split(":")[1])
      const deviceRes = await axios.post(
        `${getApiUrl()}/api/registerDevice`,
        { store: storeId, deviceId: deviceId, isMaster: true, name: deviceSetting0()?.name, show: true }
      )
      if (deviceRes.data.id) {
        deviceSetting0()!.cloudRegister = true
      }

      const db = await SurrealClient.getSurrealClient('cloud')
      if (!db) return
      const [existedUser] = await db.select<PosUser>(`PosUser:${storeId}`)
      if (existedUser) {
        setReplicateServerUserNamePassword(storeId, existedUser.password)
      }
      _.assign(posSync0(), _.omit({ ...posDatabase, id: storeId }, ['_id']))
      //save to device settings
      //share -> ko stable lam
      // show posUser name
      reloadIfMasterChanged.v = false;
      await upsertMaster();
      _.assign(deviceSetting0(), { show: true, isSupportDevice: false, devicePos: 1 })
      setTimeout(() => {
        reloadIfMasterChanged.v = true;
      }, 10000);
      closeRegisterDialog()
      sendDeviceName().then()
      localStorage.removeItem('isDisconnectStore')
      setIsChangeBg(false)
    } catch (e: any) {
      closeRegisterDialog()
      await msgBox.show('Error', 'Failed to register POS store: ' + e.message, msgBox.Buttons.OK, msgBox.Icons.Error)
    }
  }

  const onRename = async () => {
    try {
      if (!posSync0()?.id!) {
        toast.error("You need connect store!")
        return
      }
      if (!deviceSetting0()) {
        toast.error("Something went wrong")
        return
      }
      if (name().length < 1) {
        return toast.error("Please type your store name!")
      }
      const db = await SurrealClient.getSurrealClient('cloud')
      if (!db) {
        throw new Error("Server cannot connect, please try again!")
      }
      const closeRegisterDialog = dialogService.progress({ title: 'Rename' })
      setIsDisabledConnect(false)
      const pos = await db.select(new RecordId('PosUser', posSync0()?.id!))
      const hasChangeName = await db.merge<PosUser>((pos.id!), {
        name: name()
      })
      if (hasChangeName?.name === name()) toast.success("Rename successful")
      closeRegisterDialog()
    } catch (error) {
      console.error(error)
      return toast.error("Something wrong with server")
    }
  }

  const onVerifyCode = async (mode: string, code: string) => {
    if (!code || isNaN(parseInt(code)) || code.length != 6) {
      return toast("Please enter a valid pairing code!", { type: 'warning', autoClose: 600 });
    }
    try {
      setProcessing(true)
      const db = await SurrealClient.getSurrealClient('cloud')
      if (!db) return
      const [resultsQuery] = await db.query<[PairingCode[]]>(
        "SELECT * FROM PairingCode WHERE code=($code);", { code: code }
      )
      setProcessing(false)

      if (resultsQuery[0]) {
        if (parseInt(resultsQuery[0]?.code) !== parseInt(code)) {
          return toast("Code doesn't match!", { type: 'warning', autoClose: 600 });
        }

        const checkExpireTime = dayjs(dayjs().toISOString()).diff(dayjs(resultsQuery[0]?.until), 'day');
        if (checkExpireTime > 0) {
          return toast("Code expired!", { type: 'warning', autoClose: 600 });
        }

        if (resultsQuery[0]?.used) {
          return toast("Code has been used!", { type: 'warning', autoClose: 600 });
        }

        if (resultsQuery[0]?.type === 'pos') {
          return toast("Code not allowed to connect store!", { type: 'warning', autoClose: 600 });
        }
        await db.merge(resultsQuery[0]?.id, { used: true, usedDate: dayjs().toISOString() });
        toast("Successful!", { type: 'success', autoClose: 600 });
        setPosDatabase(resultsQuery[0]?.storeId.toString())
        await onConnect(mode, posDatabase());
        clearPrintCache();
        return;
      }
      return toast("Code doesn't exists!", { type: 'warning', autoClose: 600 });
    } catch (e) {
      setProcessing(false)
      return toast("Error: " + e, { type: 'error' });
    }
  }

  const syncRemoteStoreName = (nameStore: string) => {
    const posDatabase = posSetting0()?.posDatabase
    if (posDatabase && name() && posSync0()?.id) {
      posDatabase.name = nameStore
      return setName(nameStore)
    }
    setName(nameStore)
  }

  useEffect(() => {
    if (posSetting0()?.posDatabase) {
      posSync0()?.id! && setName(posSetting0()?.posDatabase?.name!)
      posSyncLock.acquireAsync().finally(() => {
        posSync0().name = name();
      })
    }
  }, [posSetting0()]);

  useEffect(() => {
    initDatabase()
      .connectDbCloud()
      .then(res => {
        if (res && res.name) {
          // posSync0()?.name && posSync0().name = res?.name;
          setName(res.name)
          const { id, _id } = posSync0()
          if (!id) throw new Error(`Connected to unknown store ${_id}!`) // Sentry will pick this up
          posSettings0()[0].posDatabase = { name: res?.name, _id, id }
          posSync0().name = res.name
          // return posSync0()?.id! && setName(res.name!)
        }
      })
    setName("demo store")
  }, []);

  useEffect(() => {
    if (type === 'Installation') setCurrentMode(ModeConnect.PAIRING_CODE)
  }, [type])

  return (
    <>
    <div className="space-y-3">
      <h6 className="text-lg font-bold">First device register:</h6>
      <p>Store Name</p>
      <div className="flex items-center mt-1">
        <div className='flex flex-row mq480:flex-col gap-2'>
          <TextField
            value={name()}
            onChange={(e) => syncRemoteStoreName(e.target.value)}
            className="w-[220px] mr-2"
            // disabled={!posSync0()?.id}
          />
          <Button
            variant={"default"}
            size={"sm"}
            className='w-[130px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
            ref={makeRipple}
            onClick={() => onRegister()}
            disabled={!!posSync0()?.id}
          >
            Register
          </Button>
        </div>
        {posSync0()?.name &&
          <Button
            variant={"default"}
            size={"sm"}
            className="h-[30px] ml-1"
            onClick={() => onRename()}
          >
            Rename
          </Button>
        }
      </div>

      <p className="mt-3">
        Device Id: {deviceId}, pos's database id: {posSync0()?.id}
      </p>

      <h6 className="text-lg font-bold">Second device connect:</h6>
      <div className="items-center mt-1 gap-[100px] flex-col rounded-2xl inline-block py-1 bg-gray-solid-gray-80-fafafa">
        <div className="flex items-center mt-1 gap-2 flex-row">
          <p className="font-bold">Select mode:</p>
          <FormControl
            className="font-mulish text-sm text-black-solid-black-600-424242"
            variant="outlined"
          >
            <InputLabel color="primary" />
            <Select
              disabled={type === 'Installation'}
              color="primary"
              size="small"
              value={currentMode() === ModeConnect.DEFAULT ? "Default" : currentMode() === ModeConnect.PAIRING_CODE ? "Pairing Code" : "Developer"}
              onChange={(e) => {
                if (e.target.value === "Default") {
                  setCurrentMode(ModeConnect.DEFAULT);
                } else if (e.target.value === "Pairing Code") {
                  setCurrentMode(ModeConnect.PAIRING_CODE);
                } else {
                  // if()
                  setCurrentMode(ModeConnect.DEVELOPER);
                }
              }}
            >
              <MenuItem value="Default">Default</MenuItem>
              <MenuItem value="Developer">Developer</MenuItem>
              <MenuItem value="Pairing Code">Pairing Code</MenuItem>
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="items-center flex-col mt-2 gap-2">
          <div className={clsx(currentMode() !== ModeConnect.DEVELOPER && 'hidden')}>
            <p>Database ID</p>
            <div className="flex items-center mt-1">
              <div className='flex flex-row mq480:flex-col gap-2'>
                <TextField
                  value={posDatabase()}
                  onChange={(e) => setPosDatabase(e.target.value)}
                  className="w-[220px] mr-2"
                />
                <Button
                  variant={"default"}
                  size={"sm"}
                  className='w-[130px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                  ref={makeRipple}
                  onClick={async () => {
                    localStorage.setItem('modeConnect', ModeConnect.DEVELOPER.toString())
                    _.assign(deviceSetting0(), { isSupportDevice: true, show: false });
                    await onConnect('support', posDatabase())
                  }}
                  disabled={isDisabledConnect()}>
                  Connect (dev)
                </Button>
              </div>
            </div>
          </div>
          <div className={clsx(currentMode() !== ModeConnect.DEFAULT && 'hidden')}>
            <p>Database ID</p>
            <div className="flex items-center mt-1">
              <div className='flex flex-row mq480:flex-col gap-2'>
                <TextField
                  value={posDatabase()}
                  onChange={(e) => setPosDatabase(e.target.value)}
                  className="w-[220px] mr-2"
                />
                <Button
                  variant={"default"}
                  size={"sm"}
                  className='w-[130px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                  ref={makeRipple}
                  onClick={async () => {
                    if (posDatabase()) {
                      if (!getUrlParam('os') && !posSync0()?.id) {
                        _.assign(deviceSetting0(), { show: false, isSupportDevice: true })
                      }
                      await dialogConnect(() => onConnect('master', posDatabase()), () => onConnect('client', posDatabase()), () => onConnect('support', posDatabase()))
                    }
                  }}
                >
                  Connect
                </Button>
              </div>
            </div>
          </div>
          <div className={clsx(currentMode() !== ModeConnect.PAIRING_CODE && 'hidden')}>
            <p>Pairing Code</p>
            <div className="flex items-center mt-1">
              <div className='flex flex-row mq480:flex-col gap-2'>
                <TextField
                  value={posDatabase()}
                  onChange={(e) => setPosDatabase(e.target.value)}
                  className="w-[220px] mr-2"
                />
                <Button
                  variant={"default"}
                  size={"sm"}
                  className='w-[130px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
                  ref={makeRipple}
                  onClick={async () =>
                    await dialogConnect(
                      () => onVerifyCode('master', posDatabase()),
                      () => onVerifyCode('client', posDatabase()),
                      () => onVerifyCode('support', posDatabase()))
                  }
                  disabled={isDisabledConnect()}>
                  Connect
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="max-w-[220px] flex flex-col gap-1">
        <p>Server:</p>
        <FormControl
          className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Select color="primary" size="small" value={server() || ""}
                  onChange={async e => {
                    await setServer(e.target.value);
                    await clearPrintCache()
                    toast(`Change to server ${e.target.value.toUpperCase()}`, { type: "info" });
                    await delay(1000);
                    // location.reload();
                  }}
          >
            {availableServers().map(key =>
              <MenuItem key={key} value={key}>
                {key.toUpperCase()}
              </MenuItem>
            )}
          </Select>
          <FormHelperText />
        </FormControl>
      </div>

      <div className="flex flex-col gap-2">

        <Button
          variant={"default"}
          size={"sm"}
          className='w-[200px] text-white flex p-2 justify-center items-center bg-[#1271ff] rounded-md max-w-[300px]'
          ref={makeRipple}
          onClick={() => {
            if (import.meta.env.MODE === 'production') {
              setIsDisconnectVerifyPopupOpen(true)
            } else {
              onDisconnect()
            }
          }}
        >
          Disconnect to the store
        </Button>
      </div>
      <div className="max-w-[220px] flex flex-col gap-1">
        <p>Sync Protocol:</p>
        <Select
          className='w-full'
          size="small"
          value={posSync0()?.syncProtocol || 'v1'}
          onChange={(e) => {
            _.assign(posSync0(), { syncProtocol: e.target.value })
          }}
        >
          <MenuItem value={'v1'}>Sync v1</MenuItem>
          <MenuItem value={'v2'}>Sync v2</MenuItem>
          <MenuItem value={'v2-http'}>Sync v2 Http</MenuItem>
        </Select>
      </div>
    </div>
      {isDisconnectVerifyPopupOpen() &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setIsDisconnectVerifyPopupOpen(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setIsDisconnectVerifyPopupOpen(false)}
            password={DISCONNECT_PASSWORD}
            onComplete={async () => {
              await onDisconnect()
              setIsDisconnectVerifyPopupOpen(false)
            }}
          />
        </PortalPopup>
      }
    </>
  )
}

export default RegisterStore