import { type CSSProperties, type FunctionComponent, useCallback, useMemo, useState } from 'react';
import NewEditTaxPopup from './Popups/NewEditTaxPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import TaxAndPaymentDeleteConfi from './Popups/TaxAndPaymentDeleteConfi';
import type { TaxCategory } from '@/data/TaxCategory.ts';
import { deleteTaxCategory, setTaxCategory0 } from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { applyingTaxToAllProduct, paymentSettingViewLogic } from '@/react/PaymentSettingView/PaymentSettingView.logic'
import { CircularProgress } from '@mui/material'
import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal'

export type TaxInfoCardType = {
  nameTax?: string;
  typeTax?: string;
  subName1?: string;
  subName2?: string;
  label1?: string;
  label2?: string;
  value1?: string;
  value2?: string;
  showSubName?: boolean;
  showName?: boolean;
  nameVisible?: boolean;

  /** Style props */
  nameFlex?: CSSProperties["flex"];
  nameWidth?: CSSProperties["width"];
  nameAlignSelf?: CSSProperties["alignSelf"];
  nameWidth1?: CSSProperties["width"];
  nameAlignSelf1?: CSSProperties["alignSelf"];
  nameWidth2?: CSSProperties["width"];

  taxData: TaxCategory
};

const TaxInfoCard: FunctionComponent<TaxInfoCardType> = ({
  nameTax = "Tax for Quebec",
  typeTax = "Combo",
  subName1 = "TPS",
  subName2 = "TVQ",
  label1 = "TPS",
  label2 = "TVQ",
  value1 = "9.975%",
  value2 = "5%",
  showSubName = true,
  showName,
  nameVisible,
  nameFlex,
  nameWidth,
  nameAlignSelf,
  nameWidth1,
  nameAlignSelf1,
  nameWidth2,
  taxData,
}) => {
  nameTax = taxData.name || "";
  typeTax = taxData.type
  showSubName = taxData.type === "combo"

  const [isNewEditTaxPopupOpen, setNewEditTaxPopupOpen] = useState(false);
  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false);
  const nameStyle: CSSProperties = useMemo(() => {
    return {
      flex: nameFlex,
      width: nameWidth,
    };
  }, [nameFlex, nameWidth]);

  const name1Style: CSSProperties = useMemo(() => {
    return {
      alignSelf: nameAlignSelf,
      width: nameWidth1,
    };
  }, [nameAlignSelf, nameWidth1]);

  const name2Style: CSSProperties = useMemo(() => {
    return {
      alignSelf: nameAlignSelf1,
      width: nameWidth2,
    };
  }, [nameAlignSelf1, nameWidth2]);

  const openNewEditTaxPopup = useCallback(() => {
    setTaxCategory0(taxData);
    setNewEditTaxPopupOpen(true);
  }, []);

  const closeNewEditTaxPopup = useCallback(() => {
    setNewEditTaxPopupOpen(false);
  }, []);

  const openTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(true);
  }, []);

  const closeTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(false);
  }, []);

  return (
    <>
      <div className="rounded shadow-[0px_0px_4px_rgba(0,_0,_0,_0.25)] box-border flex flex-col items-start justify-start text-left text-xs text-blue-solid-blue-0057ff font-mulish self-stretch border-[1px] border-solid border-white-solid-white-100-ffffff">
        <div className="self-stretch rounded-t rounded-b-none bg-gray-solid-gray-180-ebecee overflow-hidden flex flex-col items-start justify-start pt-2.5 px-2.5 pb-2 gap-[10px_0px] border-b-[1px] border-solid border-white-solid-white-100-ffffff">
          <div className="self-stretch flex flex-row items-center justify-start gap-[0px_10px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <div className="relative Tablet_768:text-smi">{LL0().article.name().toUpperCase()}</div>
              <div className="self-stretch relative text-sm font-semibold text-black-solid-black-750-313843 Tablet_768:text-smi">
                {nameTax}
              </div>
            </div>
            {isQuebecSrmEnabled() &&
            <button 
              className='rounded-md bg-blue-solid-blue-420-2979ff box-border h-8 flex flex-row items-center justify-start py-0 px-4 border-[1px] border-solid border-blue-solid-blue-420-2979ff Tablet_600:h-6 Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:box-border font-semibold text-white disabled:opacity-50'
              onClick={() => paymentSettingViewLogic.applyTaxToAllProduct(taxData)}
              disabled={applyingTaxToAllProduct()}
            >
              {applyingTaxToAllProduct() ? <CircularProgress size='16px' color='inherit' /> : LL0().inventory.apply()}
            </button>
            }
            <img
              className="w-8 relative h-8 object-cover cursor-pointer sm:w-[26px] sm:h-[26px] sm:[transform:rotate(0deg)] Tablet_600:w-[22px] Tablet_600:h-[22px]"
              alt=""
              src="/yellow-edit-icon@2x.png"
              onClick={openNewEditTaxPopup}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[0px_10px]">
            <div className="flex-1 flex flex-col items-start justify-start gap-[4px_0px]">
              <div className="relative Tablet_768:text-smi">{LL0().article.type().toUpperCase()}</div>
              <div className="self-stretch relative text-sm font-semibold text-black-solid-black-750-313843 Tablet_768:text-smi">
                {typeTax}
              </div>
            </div>
            <img
              className="w-8 relative h-8 object-cover cursor-pointer"
              alt=""
              src="/big-red-bin-icon@2x.png"
              onClick={openTaxAndPaymentDeleteConfi}
            />
          </div>
        </div>
        <div className="self-stretch rounded-t-none rounded-b bg-gray-solid-gray-180-ebecee overflow-hidden flex flex-col items-start justify-start pt-2 px-2.5 pb-2.5 text-sm text-black-solid-black-750-313843">
          <div className="self-stretch flex flex-row items-start justify-start gap-[0px_2px]">
            {showSubName && (
              <div
                className="flex-1 flex flex-col items-start justify-start py-0 pr-0.5 pl-0 gap-[5px_0px]"
                style={nameStyle}
              >
                <div className="relative text-xs text-blue-solid-blue-0057ff Tablet_768:text-2xs Tablet_600:text-2xs Mobile_480:text-2xs height_414:text-2xs mq320:text-2xs">
                  {LL0().tax.subName().toUpperCase()}
                </div>
                {
                  taxData.components?.map((component, index) => (
                    <div className="self-stretch relative font-semibold Tablet_768:text-smi"
                         key={index}
                    >
                      {component.name}
                    </div>
                  ))
                }
              </div>
            )}
            <div className="flex-1 flex flex-col items-start justify-start py-0 px-0.5 gap-[5px_0px]">
              <div className="relative text-xs text-blue-solid-blue-0057ff Tablet_768:text-2xs Mobile_480:text-2xs height_414:text-2xs mq320:text-2xs">
                {LL0().tax.label().toUpperCase()}
              </div>
              { !showSubName &&
                <div className="self-stretch relative font-semibold Tablet_768:text-smi">
                  {taxData.printLabel}
                </div>
              }
              {
                showSubName && taxData.components?.map((component, index) => (
                  <div className="self-stretch relative font-semibold Tablet_768:text-smi"
                       key={index}
                  >
                    {component.printLabel}
                  </div>
                ))
              }
            </div>
            <div className="flex-1 flex flex-col items-end justify-center py-0 pr-0 pl-0.5 box-border gap-[5px_0px] max-w-[80px] text-right">
              <div className="relative text-xs text-blue-solid-blue-0057ff text-left Tablet_768:text-2xs Tablet_600:text-2xs Mobile_480:text-2xs height_414:text-2xs mq320:text-2xs">
                {LL0().fnBtn.value().toUpperCase()}
              </div>
              { !showSubName &&
                  <div className="self-stretch relative font-semibold Tablet_768:text-smi">
                    {taxData.value}
                  </div>
              }
              {
                showSubName && taxData.components?.map((component, index) => (
                  <div className="self-stretch relative font-semibold Tablet_768:text-smi"
                    key={index}
                  >
                    {component.value}
                  </div>
                ))
              }
            </div>
            { showSubName &&
            <div className="flex-1 flex flex-col items-end justify-start py-0 px-0.5 box-border gap-[5px_0px] max-w-[80px] text-right">
              <div className="relative text-xs text-blue-solid-blue-0057ff text-left Tablet_768:text-2xs Tablet_600:text-2xs Mobile_480:text-2xs mq320:text-2xs">
                {LL0().article.note().toUpperCase()}
              </div>
              {
                taxData.components?.map((component, index) => (
                  <div className="self-stretch relative font-semibold Tablet_768:text-smi"
                       key={index}
                  >
                    {component.note}
                  </div>
                ))
              }
            </div>
            }
          </div>
        </div>
      </div>
      {isNewEditTaxPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewEditTaxPopup}
        >
          <NewEditTaxPopup onClose={closeNewEditTaxPopup}
                           onComplete={closeNewEditTaxPopup}/>
        </PortalPopup>
      )}
      {isTaxAndPaymentDeleteConfiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTaxAndPaymentDeleteConfi}
        >
          <TaxAndPaymentDeleteConfi onClose={closeTaxAndPaymentDeleteConfi} onComplete={() => deleteTaxCategory(taxData)} />
        </PortalPopup>
      )}
    </>
  );
};

export default TaxInfoCard;
