import {convertDocuments, type DocDeepSignal} from "@/data/data-utils";
import {Terminal} from "@/data/Terminal";
import { effectOn, signal, useAsyncEffect} from "@/react/core/reactive";
import {dataLock} from "@/data/DataUtils.ts";
import _ from 'lodash';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export const [terminals0, setTerminals0] = signal<Array<DocDeepSignal<Terminal>>>([]);
export const [terminalV, setTerminalV] = signal(0);

effectOn([terminalV], async () => {
  await dataLock.acquireAsync();
  const _terminals = await Terminal.find().exec();
  const terminals = convertDocuments<Terminal>(_terminals, true, [], {debounce: 200});
  setTerminals0(terminals);
}, { defer: true })

export async function toggleUseTerminalAsDefaultForThisDevice(terminalId: string, deviceId: string) {
  userFLow(`toggle UseTerminal As Default For This Device`, {
    username: loginUser()?.name
  });
  console.log('toggleUseTerminalAsDefaultForThisDevice', terminalId, deviceId)
  const terminal = await Terminal.findOne({selector: {_id: terminalId}}).exec()
  if (!terminal)
    throw new Error("Terminal not found");

  const useAsDefaultForPosDevices = _.clone(terminal._data.useAsDefaultForPosDevices) || []
  console.log('useAsDefaultForPosDevices', useAsDefaultForPosDevices)
  const idx = useAsDefaultForPosDevices.indexOf(deviceId)
  console.log('idx', idx)
  if (idx >= 0) {
    console.log('remove', deviceId)
    useAsDefaultForPosDevices.splice(idx, 1)
  } else {
    console.log('add', deviceId)
    useAsDefaultForPosDevices.push(deviceId)
  }

  await terminal?.incrementalPatch({useAsDefaultForPosDevices})
}

export const makeTerminalsAvailable = () => {
  useAsyncEffect(async () => {
    if (terminalV() === 0) {
      await dataLock.acquireAsync();
      Terminal.$.subscribe((_) => {
        console.log('smt changed')
        setTerminalV(v => v + 1);
      })
      setTerminalV(1);
    }
  }, [terminalV()]);
};