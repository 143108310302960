import Input, {type InputController, inputController0, type InputProps} from "@/react/core/Input";
import {type Accessor, type Setter, useSignal} from "@/react/core/reactive";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import TableManualKeyboard from "./TableManualKeyboard";
import {LL0} from "@/react/core/I18nService.tsx";

interface InputKeyboardPopUpProps extends Omit<InputProps, "children"> {
  children: (show: Accessor<boolean>, setShow: Setter<boolean>, inputControllers: Accessor<Array<InputController>>) => JSX.Element;
  onSave: (name: string, id: string, price: string) => void;
}

//todo: mutate value, don't lose focus
const Input3KeyboardPopUp = (props: InputKeyboardPopUpProps) => {
  const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);
  const [show, setShow] = useSignal(false);
  return <>
    {props.children(show, setShow, inputControllers)}
    {show() && <PortalPopup
      overlayColor="rgba(113, 113, 113, 0.3)"
      placement="Centered"
      onOutsideClick={() => setShow(false)}
    >
      <div
        className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-end justify-start py-4 px-4 box-border gap-[6px] min-w-[200px] h-full max-w-full max-h-full text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish">
        <img
          className="relative w-6 h-6 object-cover cursor-pointer"
          alt=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP8SURBVHgB7ZlLa1NBFMfPTfrQgvShLsSNH8B9H0kqtXXrogq+FTfd2SamLyqYNkI0bYmpIFK16Eq0CoILraAFF23Qgh9B3EiXgiQhLU3j/5RUQpw7j5vbgpIflExmzp05/3mcezohqlKlyj+NJWsMhUItNTU1vSjW4y81NTX1lXYBjHsM4/ZYlrW6vr6+kEwmv9nZ2goIh8O96GAOf01/jC0rMTk5GaYdAo7v9Xq9DzHOxZLqHL5fwrivRM94RJXBYPBIufNMoVC4PjQ0dJt2BgvO3y9zntmDcR/xbhA9JBRQW1vbWu58CaMQcY9cZnBwcB5jXrVpboK4U6IGoQB0dJDkXBseHp4hF+jr62tAX08x5mmZHdqF210oIJ/Pp7BsBZKA5n4MfIcqw2psbJxFX1dUhhsbG4uieq+oMpVKrXZ0dNRDdIDk+AOBQPPS0tICGQKnrVwu9xKfZzVsxxOJxGtRmzSMYl/ehYggKfB4PDPxeFxptw1HG4TJORTPqWwx/gQi0Lhdu0f28PT0dAjqk6Rgc3NzAAc7ThrwzONAzpKG87C9KXOe8ZKC5eXl99hOezETfoWpz+/3tyi2k5XJZJ6jr/OkJoIJvKUyUgpgIOKDz+drRrFNYdoKu/2wf1fewNGmq6trTtP5KN76Exp2egIYOLWgKwIrUY+V+Fha2d3dzdtGGW2wbSYw8+OkibYApiiiDkVVdAqUrISF88FpgHLmTZ1nLHIAotMMtkK/yo6jEw74ARQvkBreNhEyxJEARjfEahKB81FygNEWKsUgOknhUKkTbexwLIDh6AQRTRChOthCinve0cxvU5EAprgSLRDRavIcpwdwXitUyqhYAGMQnbYoznzFzjMecgF+SWEFDuvaIzo1k0u4IUA7Jd7GpVR8i4q2kElKLMBxKl6K4/eASUoswzQV/+t5coBJSqzCJBUX4WQLGaXEEPvFpVRciJEAJymxG6m4DCMBTlPiSlNxGboCKk6JHabiSpQCONog3D1B8QypicryeTi1qJl2tOqGWGkU4miDUPmY9PL5iE4+D4EDbl4U2K4AOz8yMjJPGjNvmhKbXBSoVsJWQDqdfoABLpMCpymxbiqO/tvYjkWL2oVvYlytd+IN+YkUuJES6/57ykIw1ufyerszcILUHbqSEvOZwEdMw/S4qNLudvonyYma3h7IwOG/gQ/pbTd2REZYL6rMZrPPMMO/RG08805uD1Sgz6AkOuUg4I2oQXiIV1ZW0u3t7T/wUCe+NhSr8xhgFDOvs9yO4IOKl1gNip0l1Vn4MYCMVXi9Lk2nx8bGDq2trfUg89yHH9ve4se277QL4GAfxWSdxFbO1tXVvYjFYqtUpUqV/5Pfnf7xvP2qqhsAAAAASUVORK5CYII="
          onClick={() => setShow(false)}
        />
        <div className="flex flex-col gap-2 w-full height_414:flex-row">
          <div className="self-stretch flex flex-1 flex-col justify-start gap-[12px] text-sm">
            <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
              <span>{LL0().article.name()}</span>
              <span>:</span>
            </b>
            <Input
              className="w-full"
              refInputController={i => setInputControllers(() => [i])}/>
          </div>
          <div className="flex gap-y-4 gap-x-2 flex-1 Mobile_480:flex-col">
            <div className="self-stretch flex flex-1 flex-col justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.id()}</span>
                <span className="text-firebrick">*</span>
                <span>:</span>
              </b>
              <Input
                className="w-full"
                refInputController={i => setInputControllers(arr => [...arr, i])}/>
            </div>
            <div className="self-stretch flex flex-1 flex-col justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                {LL0().article.price()}:
              </b>
            <Input
              className="w-full"
              refInputController={i => setInputControllers(arr => [...arr, i])}/>
            </div>
          </div>
        </div>
          <TableManualKeyboard
            value={''}
            inputController={inputController0}
            onEnter={() => {
              props.onSave(
                inputControllers()[0].keys().join(""),
                inputControllers()[1].keys().join(""),
                inputControllers()[2].keys().join("")
              );
              setShow(false);
            }}
          />
      </div>
    </PortalPopup>}
  </>;
};

export default Input3KeyboardPopUp;
