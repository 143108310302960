import type { RxCollection,  RxDatabase,  RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const ORDER_HANDLER_COLLECTION_NAME = 'order_handlers'

export const VERSION = 1

const orderHandlerSchema = {
  title: 'orderHandler schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    processedIndex: { type: 'number' },
    status: { type: 'string' },
  },
  indexes: [],
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

export type OrderHandler = {
  _id: string
  processedIndex: number
  processedUuid: string
  status: string
}

export const OrderHandler = {} as RxCollection<OrderHandler>

export const createOrderHandlerCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: OrderHandler,
    collectionName: ORDER_HANDLER_COLLECTION_NAME,
    version: VERSION,
    schema: orderHandlerSchema,
  });
}
//@ts-ignore
window.OrderHandler = OrderHandler
