import { type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { handleRePrintOrder, isDisabled, order0, shouldAllowRePrint } from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import { InvoiceTypes, OrderStatus } from "@/pos/OrderType.ts";
import clsx from "clsx";
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";

import { isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { userFLow } from '@/shared/logger';
import { loginUser } from '@/data/UserSignal';

type OrderHistoryButtonPrintType = {
  showTap?: boolean;
  showDefault?: boolean;
};

const OrderHistoryButtonPrint: FunctionComponent<
  OrderHistoryButtonPrintType
> = ({showDefault = true}) => {
  const [showTap, setShowTap] = useState<boolean>(false);

  const clickOutsideRef = useRef<HTMLDivElement>()

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (clickOutsideRef.current && !clickOutsideRef.current.contains(event.target as Node)) {
      setShowTap(false);
    }
  }, []);

  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      handleClickOutside(event);
    };

    if (showTap) {
      window.addEventListener('mousedown', handleGlobalClick);
    }

    return () => {
      if (showTap) {
        window.removeEventListener('mousedown', handleGlobalClick);
      }
    };
  }, [handleClickOutside, showTap]);

  return (
    <>
      {(showTap && !(order0()?.status === OrderStatus.CANCELLED)) && (
        <div
          className="relative flex flex-col flex-1 items-center justify-center gap-[4px] text-center text-sm text-black-solid-black-900-1e1e23 font-mulish-button-name-bold Mobile_480:pl-0 Mobile_480:box-border">
          <div
            className="self-stretch rounded-11xl [background:linear-gradient(180deg,_#ff8366,_#e44e2c)] h-[34px] flex flex-row items-center justify-center py-0.5 px-2.5 box-border relative gap-[3px] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border"
            ref={makeRipple}
            onClick={() => {
              setShowTap(prev => !prev)
            }}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover opacity-[0.5] z-[0] Tablet_768:hidden"
              alt=""
              src="/order-history-iconprint-icon2@2x.png"
            />
            <b className="relative leading-[17px] opacity-[0.5] z-[1] Tablet_600:text-smi Mobile_480:text-xs">
              {LL0().ui.print()}
            </b>
          </div>
          <div ref={clickOutsideRef}
            className="no-scrollbar my-0 mx-[!important] absolute bottom-full w-fit min-w-[200px] Tablet_768:min-w-[180px] flex flex-col items-center justify-start box-border max-w-[none] max-h-[none] overflow-hidden z-[2] text-base">
            <div
              className="rounded-sm bg-black-opacity-black-30-000000 shadow-[1px_0px_4px_#525f71,_10px_-10px_14px_rgba(149,_149,_149,_0.1)_inset,_-10px_10px_14px_rgba(255,_255,_255,_0.1)_inset] [backdrop-filter:blur(40px)] overflow-hidden flex flex-col items-center justify-start p-3 gap-[16px]">
              <ButtonWrapper
                onClick={() => {
                setShowTap(false);
                handleRePrintOrder(InvoiceTypes.RED_INVOICE);
              }}
                isDisabled={order0().status === OrderStatus.DEBITOR}>
                <div
                  className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-6 box-border gap-[8px] Mobile_480:h-9 mq320:h-8"

                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                    alt=""
                    src="/order-history-redbill-icon-111@2x.png"
                  />
                  <b
                    className="relative leading-[17px] flex items-end justify-center shrink-0 Mobile_480:text-mini mq320:text-sm">
                    {LL0().fnBtn.paymentFunctions.redBill()}
                  </b>
                </div>
              </ButtonWrapper>
              <ButtonWrapper
                onClick={() => {
                setShowTap(false);
                handleRePrintOrder(InvoiceTypes.INVOICE);
              }}
                isDisabled={order0().status === OrderStatus.DEBITOR}>
                <div
                  className="self-stretch rounded-81xl [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.35))] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[42px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-6 box-border gap-[8px] Mobile_480:h-9 mq320:h-8"
                >
                  <img
                    className="w-[30px] relative h-[30px] object-cover Mobile_480:w-[26px] Mobile_480:h-[26px] mq320:w-6 mq320:h-6"
                    alt=""
                    src="/order-history-iconpaidbill-icon11@2x.png"
                  />
                  <b
                    className="relative leading-[17px] flex items-end justify-center shrink-0 Mobile_480:text-mini mq320:text-sm">
                    {LL0().order.invoiceTypes.bill()}
                  </b>
                </div>
              </ButtonWrapper>
            </div>
            <div
              className="self-stretch shrink-0 flex flex-row items-center justify-center Mobile_480:pl-[42px] Mobile_480:box-border mq320:pl-10 mq320:box-border">
              <img
                className="w-[13px] relative h-[11.3px]"
                alt=""
                src="/order-history-polygon.svg"
              />
            </div>

          </div>
        </div>

      )}
      {(!showTap) && (
        <button
          className="disabled:opacity-50 disabled:pointer-events-none flex-1 self-stretch rounded-11xl [background:linear-gradient(180deg,_#ff8366,_#e44e2c)] h-[34px] flex flex-row items-center justify-center py-0.5 box-border gap-[3px] Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border Mobile_480:pl-2 Mobile_480:pr-2 Mobile_480:box-border"
          ref={makeRipple}
          disabled={!shouldAllowRePrint()}
          onClick={async () => {
            if (order0()?.status === OrderStatus.CANCELLED) return handleRePrintOrder(InvoiceTypes.INVOICE);
            if (isQuebecSrmEnabled()) return handleRePrintOrder(InvoiceTypes.INVOICE)
            setShowTap(prev => !prev)
          }}
        >
          <img
            className="w-[30px] relative h-[30px] object-cover sm:w-[26px] sm:h-[26px] Tablet_768:w-[26px] Tablet_768:h-[26px] Tablet_600:w-[26px] Tablet_600:h-[26px] Mobile_480:w-[26px] Mobile_480:h-[26px] height_414:w-[26px] height_414:h-[26px]"
            alt=""
            src="/order-history-iconprint-icon11@2x.png"
          />
          <b className="relative leading-[17px] sm:text-xs Tablet_768:text-xs Tablet_600:text-xs Mobile_480:text-xs height_414:text-xs">
            {LL0().ui.print()}
          </b>
        </button>
      )}
    </>
  );
};

export default OrderHistoryButtonPrint;
