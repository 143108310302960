import React, { FunctionComponent, useCallback, useState } from "react";
import EditMenuCustomDishPopup from "./EditMenuCustomDishPopup";
import PortalPopup from "./PortalPopup";
import OrderScreenAllCategoryPo from "./OrderScreenAllCategoryPo";
import EditMenuBurgerButtonV from "./EditMenuBurgerButtonV";
import CategoryWrapper from "./CategoryWrapper";
import CategorySectionWrapper from "./CategorySectionWrapper";
import {
  addCategory,
  addSection,
  category0,
  isOpenPopupImport,
  sections0, setOpenPopupImport
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  isOpenEditMenuForm,
  onBackEditMenu,
  setOpenEditMenuForm,
} from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";
import PortalDrawer from "@vertical-edit-menu/PortalDrawer.tsx";
import VerticalEditMenuFormDrawer from "@vertical-edit-menu/VerticalEditMenuFormDrawer.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import ProductGridImport from "@vertical-order-view/ProductGridImport.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { clsx } from "clsx";
import {
  editorMode0,
  ITEM_MODE,
  setShowKeyboardIngredients,
  showKeyboardIngredients
} from "@/react/EditMenuView/EditMenuView.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { inputController0 } from "@/react/core/Input.tsx";

export type VerticalEditMenuPluginType = {
  className?: string;
};

const VerticalEditMenuPlugin: FunctionComponent<VerticalEditMenuPluginType> = ({
  className = "",
}) => {
  const [isEditMenuCustomDishPopupOpen, setEditMenuCustomDishPopupOpen] =
    useState(false);
  const [isOrderScreenAllCategoryPoOpen, setOrderScreenAllCategoryPoOpen] =
    useState(false);

  const openEditMenuCustomDishPopup = useCallback(() => {
    setEditMenuCustomDishPopupOpen(true);
  }, []);

  const closeEditMenuCustomDishPopup = useCallback(() => {
    setEditMenuCustomDishPopupOpen(false);
  }, []);

  const openOrderScreenAllCategoryPo = useCallback(() => {
    setOrderScreenAllCategoryPoOpen(true);
  }, []);

  const closeOrderScreenAllCategoryPo = useCallback(() => {
    setOrderScreenAllCategoryPoOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-full h-full max-w-full overflow-hidden flex flex-col items-center justify-start relative bg-[url('/public/edit-menu-bg-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div className={clsx("self-stretch bg-black-solid-black-500-59636f flex flex-row items-center justify-between px-2.5 pb-2.5 z-[2]",
          generalSetting0()?.useMenuV2 ? 'pt-2.5' : 'pt-8'
        )}>
          {!generalSetting0()?.useMenuV2 &&
            <div className="self-stretch w-16 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-blue-solid-blue-450-1271ff overflow-hidden shrink-0 flex flex-col items-center justify-center"
                 ref={makeRipple}
                 onClick={onBackEditMenu}
            >
              <img
                className="w-8 relative h-8 object-cover mq432:w-[26px] mq432:h-[26px] mq414:w-[26px] mq414:h-[26px] mq360:w-[26px] mq360:h-8 mq340:w-[26px] mq340:h-[26px] mq320:w-[26px] mq320:h-[26px]"
                alt=""
                src="/vertical--back-icon@2x.png"
              />
            </div>
          }
          <div className="h-10 flex flex-row items-start justify-end flex-1 gap-2 pl-2">
            {!generalSetting0()?.useMenuV2 &&
              <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(180deg,_#C5F1FF,_#6FC3F2)] h-10 flex flex-row items-center justify-center py-[7px] px-2 box-border relative gap-1"
                   ref={makeRipple}
                   onClick={() => setOpenEditMenuForm(true)}
              >
                <img
                  className="w-6 relative h-6 object-cover hidden z-[0]"
                  alt=""
                  src="/iconcash-registerplus-icon-ver1@2x.png"
                />
                <img
                  className="w-[30px] relative h-[30px] object-cover z-[1]"
                  alt=""
                  src="/iconcash-registerplus-icon-ver11@2x.png"
                />
                <b className="relative leading-[17px] z-[2]">{LL0().productViewSetting.categoryEditPopup.title()}</b>
                <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-981xl bg-darkslategray hidden z-[3]" />
              </div>
            }
            <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl [background:linear-gradient(180deg,_#ffe79b,_#e3a400)] h-10 flex flex-row items-center justify-center py-[7px] px-2 box-border relative gap-1"
                 ref={makeRipple}
                 onClick={async () => {
                   await addSection(category0())
                 }}
            >
              <img
                className="w-6 relative h-6 object-cover hidden z-[0]"
                alt=""
                src="/iconcash-registerplus-icon-ver1@2x.png"
              />
              <img
                className="w-[30px] relative h-[30px] object-cover z-[1]"
                alt=""
                src="/iconcash-registerplus-icon-ver11@2x.png"
              />
              <b className="relative leading-[17px] z-[2]">{LL0().order.section()}</b>
              <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-981xl bg-darkslategray hidden z-[3]" />
            </div>
            <EditMenuBurgerButtonV />
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start py-1.5 px-2.5 gap-1 z-[1]">
          <div
            className="shrink-0 self-stretch w-[30px] rounded [background:linear-gradient(180deg,_#E3F0FF,_#AEB7C1)] flex flex-col items-center justify-center py-2 px-1 box-border cursor-pointer"
            ref={makeRipple}
            onClick={addCategory}
          >
            <img
              className="w-7 relative h-7 object-cover"
              alt=""
              src="/icongeneralplus-icon-ver1@2x.png"
            />
          </div>
          <CategoryWrapper />
        </div>
        <div className="gap-2 no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start pt-2 px-2.5 pb-10 z-[0]">
          {sections0()?.filter(s => s.get()?.categories?.includes(category0()?._id))?.sort((a, b) => (a.get()?.order || 0) - (b.get()?.order || 0))?.map((section, index) => (
            <CategorySectionWrapper key={index} section={section} />
          ))}
        </div>
        {(editorMode0() === ITEM_MODE.INGREDIENT && showKeyboardIngredients() && generalSetting0()?.useMenuV2) &&
          <div className='absolute inset-0 bg-[#6b6f82] bg-opacity-70 w-full h-full flex flex-col z-[1]'>
            <div className='bg-[#6b6f82] bg-opacity-70 flex-1' onClick={(event)=> {
              setShowKeyboardIngredients(false)
              event.stopPropagation();
            }}></div>
            <div className={'z-50 absolute inset-x-0 bottom-0'}>
              <TableManualKeyboard inputController={inputController0}/>
            </div>
          </div>
        }
      </div>
      {isEditMenuCustomDishPopupOpen && (
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered">
          <EditMenuCustomDishPopup onClose={closeEditMenuCustomDishPopup} />
        </PortalPopup>
      )}
      {isOrderScreenAllCategoryPoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeOrderScreenAllCategoryPo}
        >
          <OrderScreenAllCategoryPo onClose={closeOrderScreenAllCategoryPo} />
        </PortalPopup>
      )}
      {(isOpenEditMenuForm() && !generalSetting0()?.useMenuV2) && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={() => setOpenEditMenuForm(false)}
        >
          <VerticalEditMenuFormDrawer onClose={() => setOpenEditMenuForm(false)} />
        </PortalDrawer>
      )}
      {isOpenPopupImport() && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Bottom"
          onOutsideClick={() => setOpenPopupImport(false)}
        >
          <ProductGridImport onClose={() => setOpenPopupImport(false)} />
        </PortalDrawer>
      )}
    </>
  );
};

export default VerticalEditMenuPlugin;