import type { RxCollection, RxJsonSchema } from 'rxdb'

export const TAX_CATEGORY_COLLECTION_NAME = 'tax_categories'

const VERSION = 6

export const taxCategorySchema: RxJsonSchema<TaxCategory> = {
  title: 'taxCategory schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    type: { type: 'string' },
    value: { type: 'number' },
    printLabel: { type: 'string' },
    components: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          name: { type: 'string' },
          value: { type: 'number' },
          printLabel: { type: 'string' },
          note: { type: 'string' },
        },
      },
    },
  },
} as const

export type SubTax = {
  name?: string
  value?: number
  printLabel?: string
  note?: 'F' | 'P' | 'S'
}

export enum TaxType {
  FOOD_DINE_IN = 'food:dineIn',
  FOOD_TAKE_AWAY = 'food:takeAway',
  DRINK_DINE_IN = 'drink:dineIn',
  DRINK_TAKE_AWAY = 'drink:takeAway',
  HST = 'HST',
  COMBO = 'combo',
}

export interface TaxCategory {
  _id?: string
  name?: string
  value?: number
  printLabel?: string
  type: TaxType
  components?: SubTax[]
}

export const TaxCategory = {} as RxCollection<TaxCategory>
