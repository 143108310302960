import PasscodePlugin from '@passcode/PasscodePlugin'
import dayjs from 'dayjs'
import debug from 'debug'
import _ from 'lodash'
import type { RxDocument } from 'rxdb'
import uuid from 'time-uuid'

import { dataLock } from '@/data/DataUtils.ts'
import { ShiftStatus, TimeClock } from '@/data/TimeClock.ts'
import { onEnter, PosScreen } from '@/pos/PosRouter.ts'
import { effectOn, signal } from '@/react/core/reactive.ts'
import { handleClickUpdate } from '@/react/Developer/UpdateNowPopup.logic.ts'
import { beginHour } from "@/data/PosSettingsSignal.ts";
import { loginUser, users0 } from "@/data/UserSignal.ts";
import axios from "axios";
import { getApiUrl, getServer } from "@/shared/utils.ts";
import semver from "semver";
import { getOsFromParam, getUrlParam } from "@/shared/utils2.ts";
import { dialogModeUpdate } from "@/react/PasscodeView/dialogModeUpdate.tsx";
import { isHanseatic } from "@/react/CompanyInformationView/companyInfomation.logic.ts";
import { rnHost } from '@/shared/webview/rnwebview'
import { updateFlow } from "@/shared/logger.ts";

const log = debug('data:passcode')

const blockRunClockIn: any[] = [];

/*******************[ States ]*********************/
const [updateAvailable, setUpdateAvailable] = signal(false)
export const [isCheckingUpdate, setIsCheckingUpdate] = signal(false)
const [lastCheck, setLastCheck] = signal<Date | undefined>(undefined)

/*******************[ Exported States ]*********************/
export {
  // States
  updateAvailable,
  lastCheck,
}

/*******************[ Handlers ]*********************/
export const HANDLERS = {
  getModeUpdate: () => {
    const os = getOsFromParam();
    return os === "ios"
      ? "s3"
      : os === "android"
        ? "store"
        : os === "win32"
          ? "electron"
          : "s3"
  },
  checkUpdate: async () => {
    if (import.meta.env.MODE === 'development' || import.meta.env.VITE_APP_VERSION === 'local') {
      setUpdateAvailable(true)
      return
    }
    try {
      setIsCheckingUpdate(true)
      const l = lastCheck()
      const now = new Date()
      // Only check again after 5 min
      if (!l || dayjs(now).diff(dayjs(l), 'minute') > 5) {
        // sendPosMessage({ type: 'check-update' })
        setLastCheck(now)
        const _version = import.meta.env.VITE_APP_VERSION
        const version = _version.includes('-') ? _version.split('-')[0] : _version;
        const mode = HANDLERS.getModeUpdate()
        updateFlow(`start checkUpdate... ${mode} current version is ${_version} flavor is: ${getUrlParam('flavor')}`)

        const response = await axios.post(
          `${getApiUrl()}/api/checkUpdate`,
          {
            mode,
            flavor: (isHanseatic && mode === 'store') ? getUrlParam('flavor') : ''
          }, {
            timeout: 10000
          }
        )
        updateFlow(`checkUpdate response data is: ${JSON.stringify(response.data)}`)
        console.log(response.data)
        const latestVersion = response.data?.version
        if (!latestVersion) {
          updateFlow(`check version failed, no latest version found`)
        }
        updateFlow(`check version: ${version} and ${latestVersion} -> ${semver.gt(latestVersion, version)}`)
        if (latestVersion && semver.gt(latestVersion, version)) {
          updateFlow('💡 New version available!', response.data?.result?.version)
          setUpdateAvailable(true)
        } else {
          updateFlow(`current version is latest, latestVersion is ${latestVersion} but current is ${version}`)
        }
      }
    } catch (e) {
      updateFlow('🛑 Failed to check for update!', e)
    } finally {
      setIsCheckingUpdate(false)
    }
  },
  updateNow: async () => {
    const os = getOsFromParam();
    let mode
    if (os === 'win32') {
      mode = 'electron'
    } else {
      await dialogModeUpdate(os, async (v) => {
        updateFlow(`user click mode update ${v}`)
        mode = v
      })
    }
    updateFlow(`update with mode ${mode}`)
    if (!mode) return
    if (mode === 'app_store') {
      updateFlow(`openUrl: itms-apps://itunes.apple.com/app/${getServer().appStore.appId}`)
      await rnHost.openUrl(`itms-apps://itunes.apple.com/app/${getServer().appStore.appId}`)
    } else {
      await handleClickUpdate(mode)
    }
  },
}

/*******************[ Debug ]*********************/
// effect(() => log('🪲 updateAvailable', updateAvailable()))

// timecClock handlers
export const [clockedInMap, setClockedInMap] = signal<Record<string, boolean>>({})
export const [lastAction, setLastAction] = signal<RxDocument<TimeClock, {}> | null>()

async function getLastAction(username: string, time = new Date()) {
  const lastClockInAction = await TimeClock.findOne({
    selector: {
      username,
      status: ShiftStatus.CLOCK_IN,
      $or: [{ clockOutTime: { $exists: false } }, { clockOutTime: { $exists: true, $gte: time } }],
    },
  }).exec()
  if (lastClockInAction) return lastClockInAction
  else {
    return await TimeClock.findOne({ selector: { username } }).sort({ clockOutTime: 'desc' }).exec()
  }
}

export const onClockOut = async (username: string, time = dayjs().unix()) => {
  const currentShift = await TimeClock.findOne({
    selector: {
      username,
      status: ShiftStatus.CLOCK_IN,
      $or: [{ clockOutTime: { $exists: false } }, { clockOutTime: { $exists: true, $gte: time } }],
    },
  }).exec()
  if (!currentShift) return
  await TimeClock.upsert({
    ..._.pick(currentShift, ['_id', 'clockInTime', 'username', 'vDate']),
    status: ShiftStatus.CLOCK_OUT,
    clockOutTime: time,
  })
  log(`[user:clockOut] clockOutTime: ${time}, clockInTime: ${currentShift._data.clockInTime}, _id: ${currentShift._data._id}`, { userName: username })
  await fetchClockedInState([username])
}

export const onClockIn = async (username: string, autoClockOutTime?: number, clockInTime = dayjs().unix()) => {
  if (blockRunClockIn?.[username!]) {
    return;
  }

  blockRunClockIn[username!] = true;

  const vDate = dayjs.unix(clockInTime).clone().subtract(beginHour(), 'hour').startOf('day').unix();
  const lastClockInAction = await TimeClock.findOne({
    selector: {
      username,
      status: ShiftStatus.CLOCK_IN,
    },
  }).exec();
  //check if any clock-in shift exists
  if (lastClockInAction) {
    log(`[user:reClockIn] autoClockOutTime:${autoClockOutTime}, clockInTime: ${clockInTime}`, {
      alert: true,
      userName: username
    })
    return
  }
  await TimeClock.insert({
    _id: uuid(),
    username,
    status: ShiftStatus.CLOCK_IN,
    clockInTime,
    vDate,
    clockOutTime: autoClockOutTime || undefined
  })
  log(`[user:clockIn] autoClockOutTime:${autoClockOutTime}, clockInTime: ${clockInTime}`, { userName: username })

  setLastAction(await getLastAction(username))
  await fetchClockedInState([username])

  //allow run clockIn after 10s
  setTimeout(() => {
    blockRunClockIn[username!] = false;
  }, 10000);
}

export const fetchClockedInState = async (fetchedUsernames?: string[], time = 0) => {
  await dataLock.acquireAsync()
  for (const username of fetchedUsernames || users0().map(user => user.name)) {
    clockedInMap()[username || ''] = !!(await TimeClock.findOne({
      selector: {
        username,
        status: ShiftStatus.CLOCK_IN,
        $or: [{ clockOutTime: { $exists: false } }, { clockOutTime: { $exists: true, $gt: time } }],
      },
    }).exec())
  }
  setClockedInMap(prev => ({ ...prev }))
}

effectOn([loginUser], async () => {
  const loginUserName = loginUser()?.name
  if (!loginUserName) return
  await fetchClockedInState([loginUser()?.name || ''])
  if (clockedInMap()[loginUser()?.name || '']) {
    setLastAction(await getLastAction(loginUserName))
  }
})

const PasscodeView = () => {
  onEnter(PosScreen.PASSCODE, fetchClockedInState)

  return <PasscodePlugin />
}

export default PasscodeView
