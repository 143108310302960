import type { RxCollection, RxJsonSchema } from 'rxdb'

export const USER_COLLECTION_NAME = 'users'

export interface User {
  _id: string
  avatar?: string
  name?: string
  passcode?: string
  role?: UserRole
  moveItem?: boolean
  viewOrderDetail?: boolean
  viewOrderHistory?: boolean
  editMenuLayout?: boolean
  accessZReport?: boolean
  viewOtherStaffReport?: boolean
  discount?: boolean
  viewCustomerInfo?: boolean
  allowTableTakeOver?: boolean
  editTablePlan?: boolean
  viewOwnReport?: boolean
  viewMonthlyReport?: boolean
  manageInventory?: boolean
  deletePrintedItem?: boolean
  viewOnlineOrderDashboard?: boolean
  viewOnlineOrderMenu?: boolean
  viewOrder?: boolean
  viewReservation?: boolean
  cancelOrder?: boolean
  editThePriceOfAMenuItem?: boolean
  editHappyHours?: boolean
  editHighSecuritySetting?: boolean
  /** Synced with WEB-SRM */
  srmSynced?: boolean
  srmSyncedName?: string
  srmSyncingError?: string
  /** [noTVQ] Mandatary's QST number */
  noTVQ?: string
  /** [noTPS] Mandatary's GST number */
  noTPS?: string
  openCashDrawer?: boolean
}

export enum UserRole {
  STAFF = 'user',
  MANAGER = 'manager',
  ADMIN = 'admin',
}

export const userSchema: RxJsonSchema<User> = {
  title: 'user schema',
  version: 11, // increment this if schema changes
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    avatar: { type: 'string' },
    name: { type: 'string' },
    passcode: { type: 'string' },
    role: { type: 'string' },
    moveItem: { type: 'boolean' },
    viewOrderDetail: { type: 'boolean' },
    viewOrderHistory: { type: 'boolean' },
    editMenuLayout: { type: 'boolean' },
    accessZReport: { type: 'boolean' },
    viewOtherStaffReport: { type: 'boolean' },
    discount: { type: 'boolean' },
    viewCustomerInfo: { type: 'boolean' },
    allowTableTakeOver: { type: 'boolean' },
    editTablePlan: { type: 'boolean' },
    viewOwnReport: { type: 'boolean' },
    viewMonthlyReport: { type: 'boolean' },
    manageInventory: { type: 'boolean' },
    deletePrintedItem: { type: 'boolean' },
    viewOnlineOrderDashboard: { type: 'boolean' },
    viewOnlineOrderMenu: { type: 'boolean' },
    viewOrder: { type: 'boolean' },
    viewReservation: { type: 'boolean' },
    cancelOrder: { type: 'boolean' },
    editThePriceOfAMenuItem: { type: 'boolean' },
    editHappyHours: { type: 'boolean' },
    editHighSecuritySetting: { type: 'boolean' },
    srmSynced: { type: 'boolean' },
    noTVQ: { type: 'string' },
    noTPS: { type: 'string' },
    srmSyncingError: { type: 'string' },
    openCashDrawer: { type: 'boolean' },
  },
  indexes: [],
}

export const User = {} as RxCollection<User>

Object.assign(window, { User }) // Make available globally
