import { type FunctionComponent, useCallback, useState } from 'react';
import ReportsCashbookPopup from '@dashboard/ReportsCashbookPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import EnableVirtualKeyboardPopu from '@dashboard/EnableVirtualKeyboardPopu';
import ManuelTableFloorPlanButton from '@dashboard/ManuelTableFloorPlanButton';
import { PosScreen, setParams } from '@/pos/PosRouter.ts';
import { OrderMode } from '@/react/OrderView/OrderViewShare.ts';
import { totalOrder } from '@/react/PendingOrder/PendingOrderLogic.tsx';
import DashboardSettingPopup from '@dashboard/DashboardSettingPopup.tsx';
import OnlineMenuPopup from '@dashboard/OnlineMenuPopup.tsx';
import { LL0 } from '@/react/core/I18nService';
import { getAccessibility } from '@/react/DecentralizationPort.ts';
import { todayPendingReservations } from '@/data/ReservationHub.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { isManual, manualOrders0, setIsManual } from '@/react/FloorPlanView/FloorPlanView.tsx';
import { missedCalls0 } from '@/data/CallHub.ts';
import { onEnterNewDeliveryWithCustomer } from '@/react/NewDeliveryView/NewDeliveryView.tsx';
import { loginUsersEditSettingPermission, loginUsersReportsPermission } from "@/data/UserSignal.computed";
import { PosFeatures, isFeatureEnabled } from '@/srm/features.logic';
import { generalSetting0, isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import {
  isShowCustomerInfo,
  isShowNewDeliveryOrder,
  isShowOnlineMenu,
  isShowPendingOrder, isShowReservation
} from "@/shared/posFeatures.ts";

import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const DashboardButtons: FunctionComponent = () => {
  const LL = LL0()
  const [isOnlineMenuPopupOpen, setOnlineMenuPopupOpen] = useState(false);
  const [isReportsCashbookPopupOpen, setReportsCashbookPopupOpen] =
    useState(false);
  const [isDashboardSettingPopupOpen, setDashboardSettingPopupOpen] =
    useState(false);
  const [isEnableVirtualKeyboardPopuOpen, setEnableVirtualKeyboardPopuOpen] =
    useState(false);

  const openOnlineMenuPopup = useCallback(() => {
    setOnlineMenuPopupOpen(true);
  }, []);

  const closeOnlineMenuPopup = useCallback(() => {
    setOnlineMenuPopupOpen(false);
  }, []);

  const openReportsCashbookPopup = useCallback(() => {
    setReportsCashbookPopupOpen(true);
  }, []);

  const closeReportsCashbookPopup = useCallback(() => {
    setReportsCashbookPopupOpen(false);
  }, []);

  const openDashboardSettingPopup = useCallback(() => {
    setDashboardSettingPopupOpen(true);
  }, []);

  const closeDashboardSettingPopup = useCallback(() => {
    setDashboardSettingPopupOpen(false);
  }, []);

  const openEnableVirtualKeyboardPopu = useCallback(() => {
    setEnableVirtualKeyboardPopuOpen(true);
  }, []);

  const closeEnableVirtualKeyboardPopu = useCallback(() => {
    setEnableVirtualKeyboardPopuOpen(false);
  }, []);


  return (
    <>
      <div
        className="py-2 px-1 flex-1 relative h-fit overflow-y-auto grid grid-cols-[repeat(2,1fr)] [grid-row-gap:4px] [grid-column-gap:4px] text-center text-sm text-materialize-grey-darken-3 font-mulish">
        {!generalSetting0()?.hideFastCheckout &&
          <ManuelTableFloorPlanButton
            imageId="/dashboard-fast-checkout-icon18@2x.png"
            buttonText={LL.dashboard.fastCheckout()}
            noticeNumber="20"
            showSelected={false}
            showTable={false}
            showChip={false}
            screen={PosScreen.ORDER}
            onClick={() => {
              setParams({ orderMode: OrderMode.FAST_CHECKOUT })
            }}
          />
        }
        {!generalSetting0()?.hideManualTable &&
          <ManuelTableFloorPlanButton
            imageId="/iconmanual-table-icon21@2x.png"
            buttonText={LL.dashboard.manualTable()}
            noticeNumber={manualOrders0().length.toString()}
            showSelected={isManual()}
            showTable={false}
            showChip={true}
            screen={PosScreen.MANUAL_TABLE}
            onClick={() => {
              userFLow(`go to screen manual table`, {
                username: loginUser()?.name
              });
              setIsManual(true)
            }}
          />
        }
        {isShowPendingOrder() && isFeatureEnabled(PosFeatures.PendingOrder) &&
          <ManuelTableFloorPlanButton
            imageId="/iconpending-order-icon31@2x.png"
            buttonText={LL.onlineOrder.pendingOrders()}
            noticeNumber={totalOrder().toString()}
            showSelected={false}
            showTable={false}
            showChip
            screen={PosScreen.PENDING_ORDERS}
          />
        }
        {getAccessibility(PosScreen.ORDER_HISTORY) &&
          <ManuelTableFloorPlanButton
            imageId="/iconorder-history-icon41@2x.png"
            buttonText={LL.dashboard.orderHistory()}
            noticeNumber="20"
            showSelected={false}
            showTable={false}
            showChip={false}
            screen={PosScreen.ORDER_HISTORY}
          />
        }
        {isShowNewDeliveryOrder() && isFeatureEnabled(PosFeatures.DeliveryOrder) &&
          <ManuelTableFloorPlanButton
            imageId={"iconnew-delivery-icon51@2x.png"}
            buttonText={LL.dashboard.newDeliveryPickup()}
            noticeNumber={missedCalls0().length.toString()}
            showSelected={false}
            showTable={false}
            onClick={onEnterNewDeliveryWithCustomer}
            screen={PosScreen.NEW_DELIVERY}
          />
        }
        {/*<ManuelTableFloorPlanButton*/}
        {/*  imageId="/iconshipping-status-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.shippingStatus()}*/}
        {/*  noticeNumber="20"*/}
        {/*  showSelected={false}*/}
        {/*  showTable={false}*/}
        {/*  showChip={false}*/}
        {/*  screen={PosScreen.SHIPPING_STATUS}*/}
        {/*/>*/}
        {isShowOnlineMenu() &&
          <div
            className="rounded [background:linear-gradient(180deg,_#fff,_#f8f7f7)] shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] h-[108px] flex flex-col items-center justify-center py-3 px-1 box-border relative gap-[8px] cursor-pointer"
            onClick={openOnlineMenuPopup}
            ref={makeRipple}
          >
            <img
              className="relative w-10 h-10 object-cover z-[0] Tablet_600:w-8 Tablet_600:h-8 Mobile_480:w-[26px] Mobile_480:h-[26px]"
              alt=""
              src="/icononline-menu-icon71@2x.png"
            />
            <b className="self-stretch relative z-[1] Tablet_600:text-xs Mobile_480:text-2xs">
              {LL.dashboard.onlineMenu()}
            </b>
            <img
              className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-3 h-3 object-cover z-[2]"
              alt=""
              src="/dashboardpopup-icon@2x.png"
            />
            <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-6 h-6 hidden z-[3] text-whitesmoke">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />
              <b
                className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
                20
              </b>
            </div>
            <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded box-border hidden z-[4] border-[3px] border-solid border-orange" />
          </div>
        }
        {loginUsersReportsPermission() &&
          <div
            className="shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] rounded [background:linear-gradient(180deg,_#fff,_#f8f7f7)] h-[108px] flex flex-col items-center justify-center py-3 px-1 box-border relative gap-[8px] cursor-pointer"
            onClick={openReportsCashbookPopup}
            ref={makeRipple}
          >
            <img
              className="relative w-10 h-10 object-cover z-[0] Tablet_600:w-8 Tablet_600:h-8 Mobile_480:w-[26px] Mobile_480:h-[26px]"
              alt=""
              src="/iconreports-icon81@2x.png"
            />
            <b className="self-stretch relative z-[1] Tablet_600:text-xs Mobile_480:text-2xs">
              {LL0().dashboard.reports()}
            </b>
            <img
              className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-3 h-3 object-cover z-[2]"
              alt=""
              src="/dashboardpopup-icon@2x.png"
            />
            <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-6 h-6 hidden z-[3] text-whitesmoke">
              <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />
              <b
                className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
                20
              </b>
            </div>
            <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded box-border hidden z-[4] border-[3px] border-solid border-orange" />
          </div>
        }
        {isShowCustomerInfo() && getAccessibility(PosScreen.CUSTOMER_INFO) &&
          <ManuelTableFloorPlanButton
            imageId="/iconfind-customer-icon91@2x.png"
            buttonText={LL.dashboard.customerInfo()}
            noticeNumber="20"
            showSelected={false}
            showTable={false}
            showChip={false}
            screen={PosScreen.CUSTOMER_INFO}
          />
        }
        {isShowReservation() &&
          <ManuelTableFloorPlanButton
            imageId="/iconreservation-icon101@2x.png"
            buttonText={LL.dashboard.reservation()}
            noticeNumber={todayPendingReservations().toString()}
            showSelected={false}
            showTable={false}
            showChip
            screen={PosScreen.RESERVATION}
          />}
        {getAccessibility(PosScreen.VIRTUAL_PRINTER) &&
          <ManuelTableFloorPlanButton
            imageId="/iconvirtual-printer-icon111@2x.png"
            buttonText={LL.dashboard.virtualPrinter()}
            noticeNumber="20"
            showSelected={false}
            showTable={false}
            showChip={false}
            screen={PosScreen.VIRTUAL_PRINTER}
          />
        }
        {getAccessibility(PosScreen.INVENTORY) &&
          <ManuelTableFloorPlanButton
            imageId="/iconinventory-icon121@2x.png"
            buttonText={LL.dashboard.inventory()}
            noticeNumber="20"
            showSelected={false}
            showTable={false}
            showChip={false}
            screen={PosScreen.INVENTORY}
          />
        }
        {loginUsersEditSettingPermission() && <div
          className="rounded [background:linear-gradient(180deg,_#fff,_#f8f7f7)] shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] h-[108px] flex flex-col items-center justify-center py-3 px-1 box-border relative gap-[8px] cursor-pointer"
          onClick={openDashboardSettingPopup}
          ref={makeRipple}
          data-testid='dashboardButtons.settings'
        >
          <img
            className="relative w-10 h-10 object-cover z-[0] Tablet_600:w-8 Tablet_600:h-8 Mobile_480:w-[26px] Mobile_480:h-[26px]"
            alt=""
            src="/iconsetting-icon131@2x.png"
          />
          <b className="self-stretch relative z-[1] Tablet_600:text-xs Mobile_480:text-2xs">
            {LL.dashboard.settings()}
          </b>
          <div className="!flex absolute my-0 mx-[!important] top-[4px] right-[4px] w-[13px] h-[13px] z-[2]">
            <div className="absolute top-[7px] right-[0px] rounded-12xs bg-lightseagreen w-1.5 h-1.5 opacity-[0.6]" />
            <div
              className="absolute top-[7px] right-[7px] rounded-12xs bg-yellow-solid-yellow-250-ffdb5a w-1.5 h-1.5 opacity-[0.6]" />
            <div
              className="absolute top-[0px] right-[7px] rounded-12xs bg-orange-solid-orange-800-f25936 w-1.5 h-1.5 opacity-[0.6]" />
            <div className="absolute top-[0px] right-[0px] rounded-12xs bg-darkorchid w-1.5 h-1.5 opacity-[0.6]" />
          </div>
          <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-6 h-6 hidden z-[3] text-whitesmoke">
            <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />
            <b
              className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
              20
            </b>
          </div>
          <div
            className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded box-border hidden z-[4] border-[3px] border-solid border-orange" />
        </div>}
        {/*<ManuelTableFloorPlanButton*/}
        {/*  imageId="/icontutorial-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.tutorial()}*/}
        {/*  noticeNumber="20"*/}
        {/*  showSelected={false}*/}
        {/*  showTable={false}*/}
        {/*  showChip={false}*/}
        {/*  screen={PosScreen.TUTORIAL}*/}
        {/*/>*/}
        {generalSetting0()?.displayKitchenEnable && <ManuelTableFloorPlanButton
          imageId="/iconkichen-monitor-icon151@2x.png"
          buttonText={LL.dashboard.kitchenMonitor()}
          noticeNumber="20"
          showSelected={false}
          showTable={false}
          showChip={false}
          screen={PosScreen.KITCHEN_MONITOR}
        />}
        {/*<ManuelTableFloorPlanButton*/}
        {/*  imageId="/iconworking-staffs-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.workingStaffs()}*/}
        {/*  noticeNumber="20"*/}
        {/*  showSelected={false}*/}
        {/*  showTable={false}*/}
        {/*  showChip={false}*/}
        {/*/>*/}
        {/*<div*/}

        {/*  className="rounded [background:linear-gradient(180deg,_#fff,_#f8f7f7)] shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] h-[108px] flex flex-col items-center justify-center py-3 px-1 box-border relative gap-[8px] cursor-pointer"*/}
        {/*  onClick={openEnableVirtualKeyboardPopu}*/}
        {/*>*/}
        {/*  <img*/}
        {/*    className="relative w-10 h-10 object-cover z-[0] Tablet_600:w-8 Tablet_600:h-8 Mobile_480:w-[26px] Mobile_480:h-[26px]"*/}
        {/*    alt=""*/}
        {/*    src="/iconkeyboard-setting-icon@2x.png"*/}
        {/*  />*/}
        {/*  <b className="self-stretch relative z-[1] Tablet_600:text-xs Mobile_480:text-2xs">*/}
        {/*    {LL.dashboard.enableVirtualKeyboard()}*/}
        {/*  </b>*/}
        {/*  <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-[13px] h-[13px] hidden z-[2]">*/}
        {/*    <div className="absolute top-[7px] right-[0px] rounded-12xs bg-lightseagreen w-1.5 h-1.5 opacity-[0.6]" />*/}
        {/*    <div className="absolute top-[7px] right-[7px] rounded-12xs bg-gold w-1.5 h-1.5 opacity-[0.6]" />*/}
        {/*    <div className="absolute top-[0px] right-[7px] rounded-12xs bg-orange-solid-orange-800-f25936 w-1.5 h-1.5 opacity-[0.6]" />*/}
        {/*    <div className="absolute top-[0px] right-[0px] rounded-12xs bg-darkorchid w-1.5 h-1.5 opacity-[0.6]" />*/}
        {/*  </div>*/}
        {/*  <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-6 h-6 hidden z-[3] text-whitesmoke">*/}
        {/*    <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />*/}
        {/*    <b className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">*/}
        {/*      20*/}
        {/*    </b>*/}
        {/*  </div>*/}
        {/*  <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded box-border hidden z-[4] border-[3px] border-solid border-orange" />*/}
        {/*</div>*/}

        {/*{ import.meta.env.MODE === 'development' &&*/}
        {/*<>*/}
        {/*<ManuelTableFloorPlanButton*/}
        {/*  imageId="/icon-setting-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.initNewData()}*/}
        {/*  showSelected={false}*/}
        {/*  showTable={false}*/}
        {/*  showChip={false}*/}
        {/*  onClick={async () => {*/}
        {/*    await initNewDatabase();*/}
        {/*    location.reload();*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<ManuelTableFloorPlanButton*/}
        {/*  imageId="/icon-setting-icon@2x.png"*/}
        {/*  buttonText={LL.dashboard.createLargeData()}*/}
        {/*  showSelected={false}*/}
        {/*  showTable={false}*/}
        {/*  showChip={false}*/}
        {/*  onClick={async () => {*/}
        {/*    await importDemoOrders();*/}
        {/*  }}*/}
        {/*/>*/}
        {/*</>*/}
        {/*}*/}
        {isQuebecSrmEnabled() &&
          <ManuelTableFloorPlanButton
            imageId="/icon-SRM@2x.png"
            buttonText={"SRM"}
            showSelected={false}
            showTable={false}
            showChip={false}
            screen={PosScreen.SRM}
          />
        }
      </div>
      {isOnlineMenuPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOnlineMenuPopup}
        >
          <OnlineMenuPopup onClose={closeOnlineMenuPopup} />
        </PortalPopup>
      )}
      {isReportsCashbookPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeReportsCashbookPopup}
        >
          <ReportsCashbookPopup onClose={closeReportsCashbookPopup} />
        </PortalPopup>
      )}
      {isDashboardSettingPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDashboardSettingPopup}
        >
          <DashboardSettingPopup onClose={closeDashboardSettingPopup} />
        </PortalPopup>
      )}
      {isEnableVirtualKeyboardPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEnableVirtualKeyboardPopu}
        >
          <EnableVirtualKeyboardPopu onClose={closeEnableVirtualKeyboardPopu} />
        </PortalPopup>
      )}
    </>
  );
};

export default DashboardButtons;
