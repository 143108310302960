import axios from 'axios'
import debug from 'debug'
import _ from 'lodash'
import type { ReplicationPullOptions, ReplicationPushOptions, RxCollection } from 'rxdb'
import { RxReplicationState } from 'rxdb/plugins/replication'
import { filter, Subject } from 'rxjs'
import Surreal from 'surrealdb.js'

import { posSync0 } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { allConfig } from '@/extensions/firebase/useFirebase.ts'
import { getNatsClient } from '@/lib/nats-client.ts'
import { updateConnectionStatus } from '@/lib/open-telemetry.ts'
import { getDeviceId } from '@/shared/getDeviceId'
import SurrealClient from '@/shared/SurrealClient.ts'
import { getApiUrl } from '@/shared/utils.ts'

const log = debug('data:sync')

export type SurrealDBCheckPointType = {
  lwt: number
  lwtMaster: number
}

const SYNC_VERSION = 7
let DEFINED_DB = localStorage.getItem('syncVersion')

export class RxSurrealDBReplicationState<RxDocType> extends RxReplicationState<RxDocType, SurrealDBCheckPointType> {
  constructor(
    public readonly replicationIdentifierHash: string,
    public readonly collection: RxCollection<RxDocType>,
    public readonly pull?: ReplicationPullOptions<RxDocType, SurrealDBCheckPointType>,
    public readonly push?: ReplicationPushOptions<RxDocType>,
    public readonly live: boolean = true,
    public retryTime: number = 1000 * 5,
    public autoStart: boolean = true
  ) {
    super(replicationIdentifierHash, collection, '_deleted', pull, push, live, retryTime, autoStart)
  }
}

export abstract class RxdbSyncSurreal {
  protected done: boolean = false
  public dbName: string = ''
  protected initQuery = ''
  protected migrateQuery = ''
  protected message$: Subject<string> = new Subject()
  protected rejectFn: any
  protected listening = false
  protected inited = false
  protected isPulling = false
  protected pullEventCheckpoint = 0
  protected masterPush: string[] = []
  protected isPingingLiveQuery = false
  protected pingTimeout: any = null
  protected pingResolver: any = null
  protected failedCount: Record<string, number> = {}

  static convertToSurrealId(collectionName: string, localId: string) {
    return `${collectionName}:${localId}`.replaceAll('-', '_')
  }

  getEventNotificationsDb() {
    return `${this.dbName}_event_notifications`
  }

  async initialize() {
    if (this.inited) return
    this.inited = true
    await this.listenLive()
    this.listenConnectionMode()
    this.listenConnected()
    await this.startPullInterval()
  }

  listenConnected() {
    const observable = SurrealClient.message$.pipe(filter(message => !!message.state))
    observable.subscribe({
      next: connectionState => {
        if (connectionState.state === 'CONNECTED' && connectionState.dbName?.startsWith('n')) {
          this.message$.next('resync')
        }
      },
    })
  }

  listenConnectionMode() {
    const observable = SurrealClient.message$.pipe(filter(message => !!message.mode))
    observable.subscribe({
      next: connectionState => {
        this.rejectFn && this.rejectFn('Surreal mode changed')
        this.message$.next('resync')
        if (connectionState.mode === 'ws') {
          this.listenLive()
        } else {
          this.startPullInterval()
        }
      },
    })
  }

  async startPingingLiveQuery() {
    if (this.isPingingLiveQuery || !getDeviceId()) return
    this.isPingingLiveQuery = true
    this.pingTimeout = setTimeout(() => {
      this.isPingingLiveQuery = false
      if (this.pingResolver) {
        this.pingResolver('timeout')
      }
    }, 60000)
    const db = await SurrealClient.getSurrealClient(this.dbName)
    await db.query(`CREATE ${this.getEventNotificationsDb()} SET table = "test_${getDeviceId()}"`)
    const res = await new Promise(resolve => {
      this.pingResolver = resolve
    })

    if (res === 'timeout') {
      updateConnectionStatus('surreal-live-query', false)
      this.startPingingLiveQuery()
    } else {
      clearTimeout(this.pingTimeout)
      updateConnectionStatus('surreal-live-query', true)
      setTimeout(() => {
        this.isPingingLiveQuery = false
        this.startPingingLiveQuery()
      }, 60000)
    }
  }

  async listenLive() {
    if (this.listening) return
    this.startPingingLiveQuery()
    this.listening = true
    console.log('init live query')
    const db = await SurrealClient.getSurrealClient(this.dbName)
    if (db instanceof Surreal) {
      try {
        const id = await (db as Surreal).live(this.getEventNotificationsDb(), (liveQueryResult, content) => {
          if (liveQueryResult === 'CREATE') {
            const table = content.table
            if (table === `test_${getDeviceId()}`) {
              log('Received ping')
              this.pingResolver && this.pingResolver()
              return
            }
            if ((table as string).includes('online_order')) {
              log('Received online order')
            }
            this.pullEventCheckpoint = Math.max(this.pullEventCheckpoint, content.created_at as number)
            this.message$.next(table as string)
          }
        })
        console.log('live query id', id)
      } catch (e) {
        if (SurrealClient.mode === 'ws') {
          setTimeout(() => {
            console.log('Retrying creating new live query')
            this.listening = false
            this.listenLive()
          }, 3000)
        }
      }
    } else {
      this.listening = false
    }
    const natsClient = getNatsClient()
    await natsClient.subscribeToSubject(`${posSync0().id}-events`, async (table: string) => {
      this.message$.next(table as string)
    })
  }

  async startPullInterval() {
    if (this.isPulling) return
    if (SurrealClient.mode === 'ws' && allConfig[`lq_pull_force_${server()}`]?.asBoolean() !== true) return
    const intervalTime = SurrealClient.mode === 'ws' ? 3000 : 1500
    try {
      const db = await SurrealClient.getSurrealClient(this.dbName)
      this.isPulling = true
      if (!this.pullEventCheckpoint) {
        const lastNotification = await db.query(`SELECT * FROM ${this.getEventNotificationsDb()} ORDER BY created_at DESC LIMIT 1;`)
        this.pullEventCheckpoint = (lastNotification?.[0] as Array<Record<string, any>>)?.[0]?.created_at ? (lastNotification?.[0] as Array<Record<string, any>>)?.[0]?.created_at : 1
      }
      const notificationsResponse = await db.query(`
        SELECT * FROM ${this.getEventNotificationsDb()} WHERE created_at > ${this.pullEventCheckpoint} AND created_at IS NOT NONE ORDER BY created_at DESC;
      `)
      const notifications = notificationsResponse?.[0] as Array<Record<string, any>>
      const tables: string[] = []
      notifications.forEach(notification => {
        tables.push(notification.table)
        this.pullEventCheckpoint = Math.max(this.pullEventCheckpoint, notification.created_at)
      })
      _.uniq(tables).forEach(table => {
        this.message$.next(table)
      })
    } finally {
      setTimeout(() => {
        this.isPulling = false
        this.startPullInterval()
      }, intervalTime)
    }
  }

  async initSurrealSync(_dbName: string, collectionNames: string[], masterPush: string[]) {
    if (this.done) return false
    this.done = true
    this.dbName = _dbName
    this.masterPush = masterPush
    if (DEFINED_DB === `${SYNC_VERSION}`) return true
    const db = await SurrealClient.getSurrealClient(this.dbName)
    const syncVersionData: any[] = await db.query(`SELECT * FROM ${this.dbName}_sync_version:${SYNC_VERSION}`)
    if (!syncVersionData[0].length) {
      await axios.post(`${getApiUrl()}/api/registerStoreSurreal`, {
        storeId: _dbName,
        collectionNames,
        version: SYNC_VERSION,
      })
      DEFINED_DB = `${SYNC_VERSION}`
      localStorage.setItem('syncVersion', `${SYNC_VERSION}`)
    }

    return true
  }

  abstract replicateSurrealDB<RxDocType>(options: any): Promise<RxSurrealDBReplicationState<RxDocType>>
}
