import type { FunctionComponent } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import {
  date0,
  dateIso0,
  dayReport0, dayReport0Gross,
  getZContent,
  group0,
  selectedZ0,
  setShowMoreInfo,
  setShowProductSold
} from '@/react/EndOfDayView/EndOfDayView.tsx';
import dayjs from 'dayjs';
import { LL0 } from '@/react/core/I18nService.tsx';
import { setShowTrainingMode, showTrainingMode } from '@/pos/trainingMode.ts'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";


type EndOfDayReportContainerType = {
  isEmpty?: boolean;
};

const EndOfDayReportContainer: FunctionComponent<
  EndOfDayReportContainerType
> = ({ isEmpty }) => {
  isEmpty = !group0()?.[date0()]
  return (
    <div className="flex flex-col items-start justify-start gap-[24px] text-left text-xs text-black font-mulish self-stretch">
      {!isEmpty && (
        <div className="self-stretch flex flex-col items-center justify-start py-0 px-4 gap-[24px] height_414:gap-3">
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
            <div className="self-stretch flex flex-row items-center justify-between">
              <b className="flex-1 relative">{LL0().common.date()}:</b>
              <div className="relative text-right">{dayjs(dateIso0().split('T')[0], 'YYYY-MM-DD').format(LL2().dates.dateFormat())}</div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <b className="flex-1 relative">{LL0().report.ZNumber()}:</b>
              <div className="relative text-right">{getZContent(selectedZ0())}</div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <b className="flex-1 relative">{LL0().report.firstOrder()}:</b>
              <div className="relative text-right">{dayReport0()?.from ? dayjs.unix(dayReport0()?.from).format(`${LL2().dates.shortDate()} HH:mm`) : ""}</div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <b className="flex-1 relative">{LL0().report.lastOrder()}:</b>
              <div className="relative text-right">{dayReport0()?.to ? dayjs.unix(dayReport0()?.to).format(`${LL2().dates.shortDate()} HH:mm`) : ""}</div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between">
              <b className="flex-1 relative">{LL0().report.totalSales()}:</b>
              <b className="relative text-base text-royalblue-100 text-right">
                {LL3().format.currency(dayReport0Gross() || 0)}
              </b>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px] height_414:gap-[4px]">
            <FormControlLabel
              className="self-stretch"
              label={LL0().report.showProductSold()}
              control={<Switch color="primary" defaultChecked={false} onChange={val => {
                userFLow(`show product sold ${val.target.checked}`, {
                  username: loginUser()?.name
                });
                setShowProductSold(val.target.checked)
              }}/>}
            />
            <FormControlLabel
              className="self-stretch"
              label={LL0().report.showMoreInformation()}
              control={<Switch color="primary" defaultChecked={false} onChange={val => {
                userFLow(`show more information ${val.target.checked}`, {
                  username: loginUser()?.name
                });
                setShowMoreInfo(val.target.checked)
              }}/>}
            />
            <FormControlLabel
              className="self-stretch"
              label={LL0().report.showTrainingMode()}
              control={<Switch color="primary" value={showTrainingMode() || false} onChange={val => {
                userFLow(`show training mode ${val.target.checked}`, {
                  username: loginUser()?.name
                });
                setShowTrainingMode(val.target.checked)
              }}/>}
            />
          </div>
        </div>
      )}
      {/*Neu trong se hien mang null*/}
      {isEmpty && (
        <div className="self-stretch flex flex-col items-center justify-center py-0 px-4 gap-[40px] text-center text-smi text-darkslategray-200">
          <div className="relative w-[72px] h-[72px] overflow-hidden shrink-0 opacity-[0.5] sm:w-16 sm:h-16 Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-12 Tablet_600:h-12">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/iconmagnifying-glass-icon@2x.png"
            />
          </div>
          <b className="self-stretch relative opacity-[0.6]">{LL0().report.noOrderToShow()}</b>
        </div>
      )}
    </div>
  );
};

export default EndOfDayReportContainer;
