import ClientCaller from "@/lib/master-caller/client-caller.ts";
import type TseClient from "@/tse/tse-client";
import type { TransactionRequest } from "@/tse/tse-client";
import debug from "debug";

export const debugTse = debug('Tse:ClientUse');

export class TseClientUse implements TseClient {
  constructor() {
  }

  async selfTest(clientId: string) {
    //doesn't support
  }

  async init() {
    //doesn't support
    return true
  }

  async setup() {
    //doesn't support
  }

  async loginAdminUser() {
    //doesn't support
  }

  async updateTime() {
    //doesn't support
  }

  async factoryReset() {
    //doesn't support
  }

  //@ts-ignore
  async startTransaction({clientId, transactionData = '', processType = ''}: {clientId: string, transactionData: string, processType: string}) {
    //doesn't support
  }

  //@ts-ignore
  async updateTransaction({clientId, transactionNumber, transactionData = '', processType = ''}: {
    clientId: string,
    transactionNumber: string,
    transactionData: string,
    processType: string
  }) {
    //doesn't support
  }

  //@ts-ignore
  async finishTransaction({clientId, transactionNumber, transactionData = '', processType = ''}) {
    //doesn't support
  }

  async bulkStartAndFinishTransaction(arr: TransactionRequest[]) {
    return await ClientCaller.callMasterCommand('tse_bulkStartAndFinishTransaction', [arr], { })
  }

  //@ts-ignore
  async startAndFinishTransaction({clientId, transactionData, processType = ''}: TransactionRequest) {
    const result = await ClientCaller.callMasterCommand('tse_startAndFinishTransaction', [{
      clientId, transactionData, processType
    }], { })
    debugTse('startAndFinishTransaction', result);
    return result;
  }

  //@ts-ignore
  async getTsePublicKey() {
    //doesn't support
  }

  //@ts-ignore
  async getTseSerialNumber() {
    //doesn't support
  }

  //@ts-ignore
  async getTseSignatureAlgorithm() {
    //doesn't support
  }

  //@ts-ignore
  async getTseCertificateExpDate() {
    //doesn't support

  }

  //@ts-ignore
  async getLogMessageCertificate() {
    //doesn't support
  }

  //@ts-ignore
  async fastInit({ clientId, credentialSeed, adminPin, adminPuk, timeAdminPin, }) {
    //doesn't support
  }
}