import { rnHost } from "@/shared/webview/rnwebview.ts";
import { captureException } from "@sentry/react";
import type { PrintLabelOptions } from 'tp-react-native-bluetooth-printer';
import type { Order } from "@/data/Order.ts";
import { toast } from "react-toastify";
import { createPrinter } from "@/react/Printer/print-kitchen-utils.ts";
import { convertToBase64Png } from "@/shared/utils"
import type { Raster } from "@/shared/printer/pure-image-printer-parallel";
import { printImageToConsole } from "@/shared/printImageToConsole.ts";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import { CommitAction, type OrderItem } from "@/pos/OrderType.ts";
import delay from "delay";
import { labelPrinters0 } from "@/data/GroupPrinterHub.ts";
import { additionalSetting0, currentPrinter, isLabelPrinterEnable } from "@/react/Printer/PrinterSettingView.tsx";
import { maxId0 } from "@/data/MaxIdHub.ts";
import _ from "lodash";
import { getLL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0, generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { Buffer } from 'buffer';
import { printLan } from "@/shared/printer/lanManager.ts";
import { printUsb } from "@/shared/printer/usbManager.ts";
import debug from 'debug'
import type { ThermalPrinter } from "@/data/GroupPrinter.ts";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { PrintScripts } from "@/data/PrintScripts.ts";
import uuid from "time-uuid";
import dayjs from "dayjs";
import Queue from "queue";
import { printLabelFlow } from "@/shared/logger.ts";
import { isAlwaysHidePopup, printerErrDialog } from "@/react/Printer/printer-dialog.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { isMaster } from "@/lib/master-signal.ts";
import { getMasterHandler } from "@/lib/master-caller/master-handler.ts";
import ClientCaller from "@/lib/master-caller/client-caller.ts";
import { createOrder } from "@/pos/logic/order-reactive.ts";

const log = debug('printer:print-label')
const queue = new Queue({ autostart: true, concurrency: 1 });

export function getTicketNumber(order: Order) {
  if (!additionalSetting0()?.printPickUpNumber && !isLabelPrinterEnable()) return
  let ticketNumber = order?.ticketNumber || 1
  if (!maxId0()?.ticketNumber || maxId0()?.ticketNumber! > 200) {
    order.commits?.push({ action: CommitAction.ADD_TICKET_NUMBER, ticketNumber: 1 });
    _.assign(maxId0(), { ticketNumber: 2 })
  } else if (!order?.ticketNumber) {
    ticketNumber = maxId0()?.ticketNumber || 1
    order.commits?.push({ action: CommitAction.ADD_TICKET_NUMBER, ticketNumber: ticketNumber });
    _.assign(maxId0(), { ticketNumber: ticketNumber + 1 })
  }
  return ticketNumber
}

export async function printLabelRaster(labelPrinter: ThermalPrinter, item: OrderItem, ticketNumber: number, table?: string) {
  const printer = new VPrinter(labelPrinter);
  const LL = getLL2()
  await printer.bold(true)
  await printer.setTextQuadArea();
  await printer.setFontSize(33);
  await printer.println(`${companyInfo0()?.name ? companyInfo0()?.name : 'TAKEOUT'}`);
  await printer.bold(false)
  await printer.setFontSize(30);
  await printer.println(`Ticket number: ${ticketNumber}`);
  if (table) await printer.println(`${LL().printing.table()}: ${table}`);
  await printer.bold(true)
  await printer.drawLine();
  await printer.setFontSize(30);
  await printer.newLine();
  await printer.println(`1 x ${item.name}`);
  await printer.newLine();
  if (item.modifiers.length > 0) {
    await printer.bold(false);
    let modifiers = "* "
    for (const modifier of item.modifiers) {
      modifiers += `${modifier.name}, `
    }
    await printer.setFontSize(24);
    const _printModifiers = modifiers.replace(/,\s*$/, "");
    console.log(_printModifiers);
    log(`print modifiers for item ${JSON.stringify(_printModifiers)}`, { itemId: item._id })
    await printer.println(_printModifiers);
    await printer.setFontSize(28);
    await printer.bold(true);
    await printer.newLine();
  }
  await printer.drawLine();
  const raster = await printer.getRaster();
  await printImageToConsole('demo', raster)
  return raster
}


function LabelPrinter() {
  const _printer = labelPrinters0()
  const currentPrinter = _printer?.printers[0] as ThermalPrinter

  const x = currentPrinter?.leftPadding || 0
  const y = currentPrinter?.marginTop || 0
  const width = currentPrinter?.canvasWidth || 76
  const height = currentPrinter?.canvasHeight || 51
  const address = currentPrinter?.address?.split("/")[0];
  const ip = currentPrinter?.ip;
  const path = currentPrinter?.usb;
  const type = currentPrinter?.printerType! as 'bluetooth' | 'usb' | 'ip'
  const gap = currentPrinter?.gap || 0
  const id = _printer?._id || ''
  return {
    x,
    y,
    width,
    height,
    address,
    ip,
    gap,
    id,
    path,
    type,
    currentPrinter
  }
}

export async function printLabel(order: Order = order0(), ticketNumber: number, printed: boolean) {
  const labelPrinter = (LabelPrinter())!

  if (!labelPrinter || !isLabelPrinterEnable()) {
    return
  }
  const type = labelPrinter.type
  const items = order.getRecent!(printed);
  for (const item of items) {
    if (item.quantity < 1 || item.labelPrinter !== labelPrinter.id) continue
    log(`print item label with type ${type} with ${item.name}`, { itemId: item._id })
    for (let i = 0; i < item.quantity; i++) {
      const raster = await printLabelRaster(labelPrinter.currentPrinter, item, ticketNumber, order?.table)
      if (type === 'bluetooth') {
        await printBluetooth(labelPrinter, raster)
      } else if (type === 'ip') {
        const buffer = bitmap(labelPrinter.x || 0, labelPrinter.y || 0, raster);
        if (!labelPrinter.ip) return
        printLan({ data: buffer, keepConnection: false, printerIp: labelPrinter.ip })
      } else if (type === 'usb') {
        const buffer = bitmap(labelPrinter.x || 0, labelPrinter.y || 0, raster);
        printUsb({ data: buffer, path: labelPrinter.path })
      }
      setTimeout(async () => {
        if (generalSetting0()?.useVirtualPrinter && raster.scripts?.length) {
          log(`insert to print script ${raster.scripts?.length}`)
          await PrintScripts.insert({
            _id: uuid(),
            date: dayjs().unix(),
            scripts: _.cloneDeep(raster.scripts),
          })
        }
      }, 500);
    }
  }
}

async function printBluetooth(labelPrinter: any, raster: Raster) {
  log(`print bluetooth to address ${labelPrinter?.address}`)
  const base64Image = await convertToBase64Png(raster, false);
  const options: PrintLabelOptions = {
    width: labelPrinter.width,
    height: labelPrinter.height,
    gap: labelPrinter.gap,
    tear: 'ON',
    image: [
      {
        x: labelPrinter.x,
        y: labelPrinter.y,
        mode: 0,
        width: raster.width || 280,
        image: base64Image,
      },
    ],
  }
  queue.push(async () => {
    let count = 0;
    while (count < 10) {
      try {
        await rnHost.printTsc(labelPrinter.address!, options)
        await delay(500)
        break;
      } catch (e: any) {
        captureException(new Error(`print label bluetooth: error ${e.message}`), { tags: { type: 'print' } });
        log(`print label bluetooth: error ${e.message}`);
        console.error(`print label bluetooth: error`, e);
        if (!generalSetting0()?.preventShowErrorPopUpForPrinter && count > 3 && generalSetting0().showPopupPrintError && !isAlwaysHidePopup() && labelPrinter) {
          await printerErrDialog(LL0().printing.printerError({
            printer: _.upperFirst('Bluetooth Label Printer'),
            printerType: _.upperFirst('Bluetooth Label Printer')
          }), '', demoPrintBase64, 'BluetoothPrinter')
        }
      } finally {
        count++;
      }
    }
  })
}

function bitmap(x: number, y: number, raster: Raster) {
  const width = (raster.width) / 8;
  const heigth = raster.height;
  const str = "BITMAP " + x + "," + y + "," + width + "," + heigth + "," + 0 + ",";
  const ended = strToBytes("\n");
  const head = strToBytes(str);
  const data = reverseBits(raster.data);
  return Buffer.concat([strToBytes("CLS\n")!, head!, data, ended!, strToBytes("PRINT " + 1 + "\n")!]);
}

function reverseBits(buffer: Buffer) {
  // Duyệt qua từng byte trong buffer
  for (let i = 0; i < buffer.length; i++) {
    // Đảo ngược bit bằng toán tử NOT (~)
    buffer[i] = ~buffer[i] & 0xFF; // AND với 0xFF để giữ lại 8 bit thấp
  }
  return buffer;
}

let charsetName = "utf8";
const strToBytes = (str: string) => {
  try {
    let b = Buffer.from(str, 'utf8');
    if (charsetName == null || charsetName === "") {
      charsetName = "utf8";
    }
    //@ts-ignore
    return Buffer.from(b.toString('utf8'), charsetName);
  } catch (var4) {
    //@ts-ignore
    console.log(var4.message)
  }
  return null;
}

export async function registerMasterPrintLabel() {
  if (!isMaster()) return;
  const masterHandler = getMasterHandler();
  log('Registering master Print Label');
  masterHandler.registerCommand('printLabel', async ({order, ticketNumber, printed}: {order: Order, ticketNumber: number, printed: boolean}) => {
    log('Master Print Label receiving...', { orderId: order._id });
    const _order = createOrder(order);
    await printLabel(_order, ticketNumber!, printed);
  });
}

export async function handlePrintLabel(_order: Order, printed: boolean = false) {
  if (isLabelPrinterEnable()) {
    const checkItem = !_order.items.every(item => !item.labelPrinter)
    log(`call print label, check item is: ${checkItem}`, { orderId: _order._id })
    printLabelFlow('print label', { orderId: _order._id, error_stack: new Error('get trace').stack })
    if (checkItem) {
      const ticketNumber = getTicketNumber(_order)
      if (!isMaster()) {
        await ClientCaller.callMasterCommand('printLabel', [{
          order: _order,
          ticketNumber: ticketNumber!,
          printed
        }]);
      } else {
        await printLabel(_order, ticketNumber!, printed)
      }
    }
  }
}

async function printDemoRaster() {
  const printer = createPrinter();
  await printer.bold(true)
  await printer.setTextQuadArea();
  await printer.setFontSize(33);
  await printer.println(`DEMO TEXT`);
  await printer.drawLine();
  await printer.setFontSize(28);
  await printer.newLine();
  await printer.println(`@@@@ second text demo @@@@@@@@@`);
  const raster: Raster = (await printer.print())!;
  await printImageToConsole('demo', raster)
  return raster
}

export const testPrintLabelIP = async () => {
  const raster = await printDemoRaster()
  const buffer = bitmap(0, 0, raster);
  const labelPrinter = (LabelPrinter())!
  printLan({ data: buffer, keepConnection: false, printerIp: labelPrinter.ip! })
}

export const testPrintLabelUSB = async () => {
  const raster = await printDemoRaster()
  const buffer = bitmap(0, 0, raster);
  const labelPrinter = (LabelPrinter())!
  printUsb({ data: buffer, path: labelPrinter.path })
}

export async function demoCutPaper(order: Order = order0(), tear: 'ON' | 'OFF' = 'OFF') {
  const labelPrinter = (LabelPrinter())!
  const printer = createPrinter();
  await printer.bold(true)
  await printer.setTextQuadArea();
  await printer.setFontSize(33);
  await printer.println(`DEMO TEXT`);
  await printer.drawLine();
  await printer.setFontSize(28);
  await printer.newLine();
  await printer.println(`@@@@ second text demo @@@@@@@@@`);
  await printer.newLine();
  const raster: Raster = (await printer.print())!;
  const base64Image = await convertToBase64Png(raster, false);
  const options: PrintLabelOptions = {
    width: labelPrinter.width,
    height: labelPrinter.height,
    gap: labelPrinter.gap,
    tear: 'ON',
    image: [
      {
        x: labelPrinter.x,
        y: labelPrinter.y,
        mode: 0,
        width: raster.width || 280,
        height: labelPrinter.height,
        image: base64Image,
      },
    ],
  }
  await printImageToConsole('demo', raster)
  await rnHost.printTsc(labelPrinter.address!, options)
}

export async function demoPrintBase64() {
  const address = currentPrinter()?.address?.split("/")[0];
  const printer = createPrinter();
  await printer.println("TEST");
  await printer.println("Second testing text");
  const raster: Raster = (await printer.print())!;
  const base64 = await convertToBase64Png(raster, false);
  const options: PrintLabelOptions = {
    width: 560,
    height: 560,
    image: [
      {
        x: 2,
        y: 2,
        mode: 0,
        width: raster?.width || 560,
        image: base64,
      },
    ],
  }
  if (!address) {
    toast.warning('Please setup printer first')
    captureException('demo printTsc error: address is undefinded')
    return
  }
  await rnHost.printTsc(address!, options)

}