import React, { type FunctionComponent, useCallback, useEffect, useState } from "react";
import KitchenMonitorCardDetailP from "./KitchenMonitorCardDetailP";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import KitchenMonitorItem from "./KitchenMonitorItem";
import { OrderKitchenStatus, type TOrder } from "@/pos/OrderType.ts";
import dayjs from "dayjs";
import _ from "lodash";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import ArchivedConfirmPopup from "@kitchen-monitor/ArchivedConfirmPopup.tsx";
import { getNameKitchenPrinter, groupItemsPrinter } from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type KitchenMonitorDisplayCardType = {
  timeline?: string;
  orderType?: string;
  orderTime?: string;
  orderID?: string;
  staff?: string;
  orderStatus?: "new" | "prepare" | "done";
  order: TOrder;
  heightContainer?: number;
};

const KitchenMonitorDisplayCard: FunctionComponent<KitchenMonitorDisplayCardType> = (
  {
    timeline = "1 mins",
    orderType = "Table no. 3",
    orderTime = "3:00 PM",
    orderID = "#123454",
    staff = "Jennifer",
    orderStatus = "new",
    order,
    heightContainer
  }) => {

  const [isKitchenMonitorCardDetailPOpen, setKitchenMonitorCardDetailPOpen] =
    useState(false);

  const [isOpenArchivedConfirmPopup, setOpenArchivedConfirmPopup] = useState(false);

  const openKitchenMonitorCardDetailP = useCallback(() => {
    if (order?.kitchenStatus === OrderKitchenStatus.NEW) {
      setKitchenMonitorCardDetailPOpen(true)
    } else if (order?.kitchenStatus === OrderKitchenStatus.PREPARE) {
      setKitchenMonitorCardDetailPOpen(true)
    } else if (order?.kitchenStatus === OrderKitchenStatus.DONE) {
      setOpenArchivedConfirmPopup(true);
    }
  }, [order.kitchenStatus, order._id]);

  const closeKitchenMonitorCardDetailP = useCallback(() => {
    setKitchenMonitorCardDetailPOpen(false);
  }, []);

  // const [heightCard, setHeightCard] = useState(0)

  const diffTime = () => {
    const now = dayjs()
    const time = dayjs.unix(order.startAt!)
    const diffInMinutes = now.diff(time, "minute")
    const diffInHours = now.diff(time, "hour");
    const diffDays = now.diff(time, "day");
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${LL0().kitchenMonitor.mins()}`;
    } else if (diffInHours < 24) {
      const remainingMinutes = diffInMinutes % 60;
      return `${diffInHours} ${LL0().kitchenMonitor.hours()}, ${remainingMinutes} ${LL0().kitchenMonitor.mins()}`;
    } else {
      return `${diffDays} ${LL0().kitchenMonitor.days()}`;
    }
  }

  orderStatus = order?.kitchenStatus as any
  timeline = diffTime()
  staff = _.upperFirst(order?.users?.[0] || '')
  orderTime = dayjs.unix(order?.startAt || 0).format(LL2().dates.timeFormat())
  orderID = order?.id ? `#${order?.id}` : order?.ticketNumber ? `#${order?.ticketNumber}` : ''

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // useEffect(() => {
  //   const fixedHeightHeader = 80;
  //   const fixedHeightFooter = 40;
  //   const itemHeight = 30;
  //   const numItems = order?.items?.length || 0;
  //   const dynamicHeightItems = numItems * itemHeight;
  //   const totalHeight = fixedHeightHeader + fixedHeightFooter + dynamicHeightItems;
  //   setHeightCard(totalHeight);
  // }, [order?.items?.length]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const showViewAll = heightCard ? heightCard > windowSize.height - 70 : true;

  const filterOrders = groupItemsPrinter(order)
  const size = (deviceSetting0()?.screenConfig?.kitchenFontSize || 14)
  const tableSize = size > 18 ? 18 : size
  const width = size > 40 ? 'w-[400px]' : size > 25 ? 'w-[300px]' : 'w-[275px]';

  return (
    <>
      <div
        className={`"h-fit rounded bg-white-solid-white-100-ffffff sm:w-[245px] ${width} overflow-hidden flex flex-col items-start justify-start relative gap-[8px] text-center text-mini text-black-solid-black-1000-000000 font-mulish"`}
      >
        <div
          className="absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] box-border z-[2] border-[1px] border-solid"
          style={{
            fontSize: tableSize,
            backgroundColor: orderStatus === "new" ? "#FFF4ED" : orderStatus === "prepare" ? "#F5FBEC" : "#F7F7FF",
            borderColor: orderStatus === "new" ? "#FFF4ED" : orderStatus === "prepare" ? "#F5FBEC" : "#F7F7FF",
          }}
        />
        <div
          style={{ fontSize: tableSize }}
          className="self-stretch flex flex-col items-start justify-start py-2 px-0 gap-[5px] z-[3] text-left text-smi border-b-[1px] border-solid border-gray-solid-gray-300-dbdbdb">
          <div className="self-stretch flex flex-row items-start justify-between py-0 px-2 text-mini">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="relative leading-[19px] font-semibold">
                {_.get(LL0().kitchenMonitor, order?.kitchenStatus || '')?.()}
              </div>
            </div>
            <div className="relative leading-[19px] font-semibold text-right">
              {timeline}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between py-0 px-2">
            <div className="relative leading-[16px] flex-1">
              {order?.table &&
                <div className="relative leading-[120%]">{LL0().orderHistory.tableNo()} {order?.table}</div>}
              {!order.table && !order.type &&
                <div className="relative leading-[120%]">{LL0().dashboard.fastCheckout()}</div>}
              {order?.type && <div
                className="relative leading-[120%]">{_.get(LL0().onlineOrder, order?.type)?.() ? _.get(LL0().onlineOrder, order?.type)?.() : order?.type}</div>}
              {order?.customerRaw &&
                <div className="relative leading-[120%]">{order?.customerRaw.name} - {order?.customerRaw.phone}</div>}
            </div>
            <div className="flex flex-row items-center justify-start gap-[3px] text-right">
              <img
                className="relative w-[18px] h-[18px] object-cover"
                alt=""
                src="/iconclock-icon@2x.png"
              />
              <div className="relative leading-[16px]">{orderTime}</div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between py-0 px-2">
            <i className="relative leading-[16px]">{orderID}</i>
            <i className="relative leading-[16px] text-right">{staff}</i>
          </div>
        </div>
        <div
          className="no-scrollbar overflow-x-hidden max-h-fit self-stretch flex flex-col items-start justify-start relative z-[4] text-left text-sm text-red-solid-red-660-8f181d pb-[40px]"
          style={{
            overflowY: generalSetting0()?.oneColumnPerOrder ? "auto" : "hidden",
            height: generalSetting0()?.oneColumnPerOrder ? windowSize.height - 160 : "auto"
          }}
        >
          {Object.entries(filterOrders as Record<string, any[]>).map(([key, items], index) => {
            return (
              <div className="w-full" key={index}>
                <p
                  style={{ fontSize: tableSize }}
                  className="ml-2">{key.split(",").filter(id => id !== "undefined").length ? key.split(",").filter(id => id !== "undefined").map(id => getNameKitchenPrinter(id)).join(', ') : LL0().kitchenMonitor.cannotFindPrinter()}:</p>
                {items.map((item, idx) => (
                  <KitchenMonitorItem
                    orderId={order._id}
                    key={idx}
                    item={item}
                    orderStatus={orderStatus}
                  />
                ))}
              </div>
            )
          })}
        </div>
        <div
          className="absolute bottom-0 left-0 right-0 self-stretch h-9 flex flex-row items-center py-0 px-2 box-border min-h-[36px] z-[7]"
          style={{
            backgroundColor: orderStatus === "new" ? "#ffa06a" : orderStatus === "prepare" ? "#a9e15d" : "#c0c0ff",
            fontSize: tableSize
          }}
          ref={makeRipple}
          onClick={openKitchenMonitorCardDetailP}
        >
          {/*{(showViewAll && !generalSetting0()?.oneColumnPerOrder) && <div*/}
          {/*  className="my-0 mx-[!important] absolute right-[0px] bottom-[36px] left-[0px] flex flex-row items-center justify-center pt-8 px-0 pb-1.5 box-border gap-[8px] cursor-pointer z-[21] text-smi text-blue-solid-blue-370-0094ff"*/}
          {/*  onClick={(e) => {*/}
          {/*    e.stopPropagation();*/}
          {/*    setKitchenMonitorCardDetailPOpen(true)*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    className={clsx("absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] z-[2]",*/}
          {/*      orderStatus === "new" && "[background:linear-gradient(180deg,_rgba(255,_244,_237,_0.16),_#fff4ed_55.73%)]",*/}
          {/*      orderStatus === "prepare" && "[background:linear-gradient(180deg,_rgba(245,_251,_236,_0.16),_#f5fbec_55.73%)]",*/}
          {/*      orderStatus === "done" && "[background:linear-gradient(180deg,_rgba(247,_247,_255,_0.16),_#f7f7ff_55.73%)]"*/}
          {/*    )} />*/}

          {/*  <b className="relative [text-decoration:underline] leading-[16px] z-[3]">*/}
          {/*    {LL0().kitchenMonitor.viewAll()}*/}
          {/*  </b>*/}
          {/*</div>}*/}

          {orderStatus === "done" && <img
            className="relative w-6 h-6 object-cover z-[0]"
            alt=""
            src="/iconarchived-icon@2x.png"
          />}
          <div
            className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_31px)] font-semibold z-[1]">
            {orderStatus === "new" ? LL0().kitchenMonitor.start() : orderStatus === "prepare" ? LL0().kitchenMonitor.done() : LL0().kitchenMonitor.archived()}
          </div>
        </div>
      </div>
      {isKitchenMonitorCardDetailPOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeKitchenMonitorCardDetailP}
        >
          <KitchenMonitorCardDetailP onClose={closeKitchenMonitorCardDetailP} order={order} />
        </PortalPopup>
      )}
      {isOpenArchivedConfirmPopup &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setOpenArchivedConfirmPopup(false)}
        >
          <ArchivedConfirmPopup onClose={() => setOpenArchivedConfirmPopup(false)} order={order} />
        </PortalPopup>
      }
    </>
  );
};

export default KitchenMonitorDisplayCard;
