import { type FunctionComponent, useState } from "react";
import _ from "lodash";
import { section0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import TextField from "@/react/core/TextField.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { clsx } from "clsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

export type EditSectionTitleItemFormType = {
  className?: string;
};

const drinksListImage = [
  "/icon-cocktailscocktails-1-icon1@2x.png",
  "/icon-cocktailscocktails-1-icon2@2x.png",
  "/icon-cocktailscocktails-1-icon3@2x.png",
  "/icon-cocktailscocktails-1-icon4@2x.png",
  "/icon-cocktailscocktails-1-icon5@2x.png",
  "/icon-cocktailscocktails-1-icon6@2x.png",
  "/icon-cocktailscocktails-1-icon7@2x.png",
  "/icon-cocktailscocktails-1-icon8@2x.png",
  "/icon-cocktailscocktails-1-icon9@2x.png",
  "/icon-cocktailscocktails-1-icon10@2x.png",
  "/icon-cocktailscocktails-1-icon11@2x.png",
  "/icon-cocktailscocktails-1-icon12@2x.png",
  "/icon-cocktailscocktails-1-icon13@2x.png",
  "/icon-cocktailscocktails-1-icon14@2x.png",
  "/icon-cocktailscocktails-1-icon15@2x.png",
  "/icon-cocktailscocktails-1-icon16@2x.png",
  "/icon-cocktailscocktails-1-icon17@2x.png",
  "/icon-cocktailscocktails-1-icon18@2x.png",
  "/icon-cocktailscocktails-1-icon19@2x.png",
  "/icon-cocktailscocktails-1-icon20@2x.png",
  "/icon-cocktailscocktails-1-icon21@2x.png",
  "/icon-cocktailscocktails-1-icon22@2x.png",
  "/icon-cocktailscocktails-1-icon23@2x.png",
  "/icon-cocktailscocktails-1-icon24@2x.png",
  "/icon-cocktailscocktails-1-icon25@2x.png",
];
const dessertListImage = [
  "/icon-cocktailscocktails-1-icon26@2x.png",
  "/icon-cocktailscocktails-1-icon27@2x.png",
  "/icon-cocktailscocktails-1-icon28@2x.png",
  "/icon-cocktailscocktails-1-icon29@2x.png",
  "/icon-cocktailscocktails-1-icon30@2x.png",
  "/icon-cocktailscocktails-1-icon31@2x.png",
  "/icon-cocktailscocktails-1-icon32@2x.png",
  "/icon-cocktailscocktails-1-icon33@2x.png",
  "/icon-cocktailscocktails-1-icon34@2x.png",
  "/icon-cocktailscocktails-1-icon35@2x.png",
  "/icon-cocktailscocktails-1-icon36@2x.png",
  "/icon-cocktailscocktails-1-icon37@2x.png",
  "/icon-cocktailscocktails-1-icon38@2x.png",
  "/icon-cocktailscocktails-1-icon39@2x.png",
  "/icon-cocktailscocktails-1-icon40@2x.png",
  "/icon-cocktailscocktails-1-icon41@2x.png",
  "/icon-cocktailscocktails-1-icon42@2x.png",
  "/icon-cocktailscocktails-1-icon43@2x.png",
  "/icon-cocktailscocktails-1-icon44@2x.png",
  "/icon-cocktailscocktails-1-icon45@2x.png",
  "/icon-cocktailscocktails-1-icon46@2x.png",
  "/icon-cocktailscocktails-1-icon47@2x.png",
  "/icon-cocktailscocktails-1-icon48@2x.png",
  "/icon-cocktailscocktails-1-icon49@2x.png",
  "/icon-cocktailscocktails-1-icon50@2x.png"
];

const bowlListImage = [
  "/icon-cocktailscocktails-1-icon51@2x.png",
  "/icon-cocktailscocktails-1-icon52@2x.png",
  "/icon-cocktailscocktails-1-icon53@2x.png",
  "/icon-cocktailscocktails-1-icon54@2x.png",
  "/icon-cocktailscocktails-1-icon55@2x.png",
  "/icon-cocktailscocktails-1-icon56@2x.png",
  "/icon-cocktailscocktails-1-icon57@2x.png",
  "/icon-cocktailscocktails-1-icon58@2x.png",
  "/icon-cocktailscocktails-1-icon59@2x.png",
  "/icon-cocktailscocktails-1-icon60@2x.png",
  "/icon-cocktailscocktails-1-icon61@2x.png",
  "/icon-cocktailscocktails-1-icon62@2x.png",
  "/icon-cocktailscocktails-1-icon63@2x.png",
  "/icon-cocktailscocktails-1-icon64@2x.png",
  "/icon-cocktailscocktails-1-icon65@2x.png",
  "/icon-cocktailscocktails-1-icon66@2x.png",
  "/icon-cocktailscocktails-1-icon67@2x.png",
  "/icon-cocktailscocktails-1-icon68@2x.png",
  "/icon-cocktailscocktails-1-icon69@2x.png",
  "/icon-cocktailscocktails-1-icon70@2x.png",
  "/icon-cocktailscocktails-1-icon71@2x.png",
  "/icon-cocktailscocktails-1-icon72@2x.png",
  "/icon-cocktailscocktails-1-icon73@2x.png",
  "/icon-cocktailscocktails-1-icon74@2x.png",
  "/icon-cocktailscocktails-1-icon75@2x.png",
];

enum ImgTab {
  Drink,
  Dessert,
  Bowl
}

const VerticalEditSectionTitleItemForm: FunctionComponent<
  EditSectionTitleItemFormType
> = ({ className = "" }) => {

  const [sectionImgTab, setSectionImgTab] = useState<ImgTab>(ImgTab.Drink);

  const renderImages = () => {
    switch (sectionImgTab) {
      case ImgTab.Drink:
        return drinksListImage.map((img, index) => (
          <div key={index} className="border-gray-solid-gray-300-dbdbdb border-[0.6px] border-solid box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-2"
               ref={makeRipple}
               onClick={() => _.assign(section0(), { icon: img })}
          >
            {section0()?.icon === img &&
              <div className="w-[102.3%] absolute !m-[0] h-[103.7%] top-[-1.85%] right-[-0.95%] bottom-[-1.85%] left-[-1.35%] bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-170-ceffff border-[1px] border-solid box-border z-[0]" />}
            <img
              className="w-10 relative h-10 object-cover z-[1] sm:w-9 sm:h-9 mq350small:w-8 mq350small:h-8"
              alt={`Drink ${index}`}
              src={img}
            />
          </div>
        ));
      case ImgTab.Dessert:
        return dessertListImage.map((img, index) => (
          <div key={index} className="border-gray-solid-gray-300-dbdbdb border-[0.6px] border-solid box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-2"
               ref={makeRipple}
               onClick={() => _.assign(section0(), { icon: img })}
          >
            {section0()?.icon === img &&
              <div className="w-[102.3%] absolute !m-[0] h-[103.7%] top-[-1.85%] right-[-0.95%] bottom-[-1.85%] left-[-1.35%] bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-170-ceffff border-[1px] border-solid box-border z-[0]" />}
            <img
              className="w-10 relative h-10 object-cover z-[1] sm:w-9 sm:h-9 mq350small:w-8 mq350small:h-8"
              alt={`Dessert ${index}`}
              src={img}
            />
          </div>
        ));
      case ImgTab.Bowl:
        return bowlListImage.map((img, index) => (
          <div key={index} className="border-gray-solid-gray-300-dbdbdb border-[0.6px] border-solid box-border h-[54.6px] overflow-hidden flex flex-row items-center justify-center relative gap-2"
               ref={makeRipple}
               onClick={() => _.assign(section0(), { icon: img })}
          >
            {section0()?.icon === img &&
              <div className="w-[102.3%] absolute !m-[0] h-[103.7%] top-[-1.85%] right-[-0.95%] bottom-[-1.85%] left-[-1.35%] bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-170-ceffff border-[1px] border-solid box-border z-[0]" />}
            <img
              className="w-10 relative h-10 object-cover z-[1] sm:w-9 sm:h-9 mq350small:w-8 mq350small:h-8"
              alt={`Bowl ${index}`}
              src={img}
            />
          </div>
        ));
      default:
        return null;
    }
  };
  return (
    <div
      className={`!flex !w-full w-[480px] hidden flex-col items-start justify-start pt-2 px-3 pb-4 box-border gap-4 text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish lg:box-border mq350small:pl-3 mq350small:pr-3 mq350small:box-border ${className}`}
    >
      <div className="self-stretch flex flex-row items-center justify-start gap-3">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          <span>Name</span>
          <span className="text-red-solid-red-550-cf2222">*</span>
          <span>:</span>
        </b>
        <TextField
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242 md:pt-[9px] md:pb-[9px] md:box-border"
          value={section0()?.name || ''}
          onChange={(e) => _.assign(section0(), { name: e.target.value })}
        />
      </div>
      <div className="flex-1 self-stretch flex flex-col items-start justify-start gap-2.5">
        <div className={clsx("self-stretch flex flex-row justify-start gap-5",
          generalSetting0()?.useMenuV2 && 'flex-col'
        )}>
          <b className="relative md:text-xs md:w-[43px]">IconTitle:</b>
          <div className="flex-1 flex flex-row items-start justify-start flex-wrap content-start gap-2 text-mini text-black-solid-black-880-1d1d26">
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2"
                 ref={makeRipple}
                 onClick={() => setSectionImgTab(ImgTab.Drink)}
            >
              {sectionImgTab === ImgTab.Drink &&
                <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border z-[0]" />
              }
              <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee border-gray-solid-gray-150-eeeeee border-[2px] border-solid box-border hidden z-[1]" />
              <div className="relative z-[2]">Drink</div>
            </div>
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2"
                 ref={makeRipple}
                 onClick={() => setSectionImgTab(ImgTab.Dessert)}
            >
              {sectionImgTab === ImgTab.Dessert &&
                <div className="!flex w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border hidden z-[0]" />
              }
              <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee border-gray-solid-gray-150-eeeeee border-[2px] border-solid box-border hidden z-[1]" />
              <div className="relative z-[2]">Dessert</div>
            </div>
            <div className="shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2"
                 ref={makeRipple}
                 onClick={() => setSectionImgTab(ImgTab.Bowl)}
            >
              {sectionImgTab === ImgTab.Bowl &&
                <div className="!flex w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border hidden z-[0]" />
              }
              <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee border-gray-solid-gray-150-eeeeee border-[2px] border-solid box-border hidden z-[1]" />
              <div className="relative z-[2]">Bowl</div>
            </div>
          </div>
        </div>
        <div className="self-stretch rounded-sm gap-0 grid grid-cols-[repeat(auto-fit,minmax(48px,auto))] [grid-row-gap:0px] [grid-column-gap:0px]">
          {renderImages()}
        </div>

      </div>
    </div>
  );
};

export default VerticalEditSectionTitleItemForm;
