import type { ExtractDocumentTypeFromTypedRxJsonSchema, RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";

export const PRODUCT_SYNC_COLLECTION_NAME = 'product_sync'

export const VERSION = 1
const schema = {
  title: 'product sync schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    sync_source: { type: 'string' },
    sync_target: { type: 'string' },
    deviceId: { type: 'string' },
  },
  required: ['_id', 'sync_source', 'sync_target', 'deviceId'],
} as const satisfies RxJsonSchema<any>

export interface ProductSync extends ExtractDocumentTypeFromTypedRxJsonSchema<typeof schema> {}
export const ProductSync = {} as RxCollection<ProductSync>

export const createProductSyncCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: ProductSync,
    collectionName: PRODUCT_SYNC_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}

Object.assign(window, { ProductSync })