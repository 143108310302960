import { printUsb } from "@/shared/printer/usbManager";

import { printLan } from "@/shared/printer/lanManager";

import epsonConfig from "node-thermal-printer/configs/epsonConfig";

import { Buffer } from "buffer/";

import { png, pngCompress } from "./png";

import { DEFAULT_CANVAS_WIDTH } from "./print-cons";

import normalizeString from "./normalize-string";

import _ from "lodash";

import { PNG } from "pngjs";

import { Readable, Writable } from "stream";

import PureImage from "@gigasource/pureimage";

import { printIntegrate } from "@/shared/printer/intergrateManager";
import { convertToBase64Png } from "../utils"
import { printBluetooth } from "@/react/Printer/print-bluetooth";
import { printSerialPort } from "@/shared/printer/serialPortManager";
import { allConfig } from "@/extensions/firebase/useFirebase";
import { printStar } from "./starManager";

let nr = 0;

let beepReady = true;

let warmupTimeout;

export default class EscPrinter {
  /**
   * Creates an instance of the printer with the given address and configuration.
   * 
   * @constructor
   * @param {PrinterAddress} address - The address configuration for the printer.
   * @param {Object} [initConfig] - The initial configuration for the printer.
   */
  constructor(address, initConfig) {
    if (!initConfig) {
      this.config = epsonConfig;
      initConfig = this.printerConfig = {type: 'epson'};
    } else {
      this.config = epsonConfig;
      this.printerConfig = initConfig;
    }
    this.config.replaceSpecialCharacters = true
    this.config.extraSpecialCharacters = {'€': 164}

    if (!initConfig.width) initConfig.width = address.numberOfCharactersPerLine || 48;
    if (!initConfig.characterSet) initConfig.characterSet = 'SLOVENIA';
    if (typeof (initConfig.removeSpecialCharacters) == 'undefined') initConfig.removeSpecialCharacters = false;
    if (typeof (initConfig.replaceSpecialCharacters) == 'undefined') initConfig.replaceSpecialCharacters = true;

    this.compress = false;
    if (address.printerType === 'com') this.compress = true;
    this.address = address;

    this.alignLeft();
  }

  setCompress(c) {
    this.compress = c;
  }

  async printIp(ip, options = {keepConnection : false}) {
    //todo: printIp
    if (this.printerConfig.needWarmup) {
      const warmupTask = new EscPrinter(this.address, {needWarmup: false})
      warmupTask.addBlankDataToQuitRasterMode(2000)
      await warmupTask.print(false)
    }
    const task = {printerIp: ip, data: this.buffer, id: this.printerConfig.id, ...options};
    // propagation.inject(context.active(), task);
    // const span = trace.getSpan(context.active())
    // lanManager.addPrintTask(task)
    printLan(task);
    this.clear()
  }

  async printStar(ip, options = {}) {
    const task = {printerIp: ip, data: this.base64, id: this.printerConfig.id, ...options, width: this.address.canvasWidth};
    printStar(task);
    this.clear()
  }

  printBluetooth(mac, channel) {
  }

  appendFeedback() {
    if (!this.alreadyAppendFeedback) {
      this.append(new Buffer([0x1b, 0x40, 0x10, 0x04, 1]));
    }
    this.alreadyAppendFeedback = true;
  }

  async printUsb(path) {
    const task = {data: this.buffer, path}
    printUsb(task);
    this.clear()
  }

  printIntegrate() {
  }

  printWithDriver(printerName) {
  }

  async print(cut = true) {
    if (cut) this.cut();
    if (beepReady && this.address.sound) {
      this.beep();
      beepReady = false;
      setTimeout(() => beepReady = true, 5000)
    }
    this.reset();
    if (this.address.printerType === 'ip') {
      await this.printIp(this.address.ip, {keepConnection: this.address.keepConnection});
    } else if (this.address.printerType === 'star') {
      await this.printStar(this.address.ip, {});
    } else if (this.address.printerType === 'bluetooth') {
      if (!this.address.address) return;
      await printBluetooth(this.address.address?.split("/")[0], this.base64);
    } else if (this.address.printerType === 'usb') {
      printUsb({
        data: this.buffer,
        path: this.address.usb
      })

      nr++;
    } else if (this.address.printerType === 'integrate') { // must be integrate
      printIntegrate({
        data: this.buffer
      })
    } else if (this.address.printerType === 'serialPort') { // must be integrate
      printSerialPort({
        data: this.buffer,
        path: this.address.serialPort,
        baudrate: this.address.baudrate
      })
    }
  }

  addBlankDataToQuitRasterMode(nr) {
    this.buffer = Buffer.concat([new Buffer(_.fill(Array(nr), 0)), new Buffer([0x0c, 0x1b, 0x5c, 0, 0]), this.buffer]);
  }

  cut() {
    this.partialCut();
  }

  linefeed(length) {
    for (let i = 0; i < (length ? length : 6); i++) {
      this.append(this.config.CTL_LF);
    }
  }

  partialCut() {
    this.linefeed();
    this.append(this.config.PAPER_PART_CUT);
  }

  beep() {
    this.append(new Buffer([0x1B, 0x42, 0x5, 0x01]));
  }

  getWidth() {
    return parseInt(this.printerConfig.width);
  }

  clear() {
    this.buffer = null;
  }

  add(buffer) {
    this.append(buffer);
  }

  text(text) {
    this.append(text.toString());
  }

  textLn(text) {
    this.append(text.toString());
    this.append('\n');
  }

  printVerticalTab() {
    this.append(this.config.CTL_VT);
  }

  setFontSize(fontSize) {
    if (fontSize <= 30) {
      this.setTextNormal()
    } else {
      this.setTextQuadArea()
    }
    //set font size is not supported for esc printer
  }

  bold(enabled) {
    if (enabled) this.append(this.config.TXT_BOLD_ON);
    else this.append(this.config.TXT_BOLD_OFF);
  }

  underline(enabled) {
    if (enabled) this.append(this.config.TXT_UNDERL_ON);
    else this.append(this.config.TXT_UNDERL_OFF);
  }

  underlineThick(enabled) {
    if (enabled) this.append(this.config.TXT_UNDERL2_ON);
    else this.append(this.config.TXT_UNDERL_OFF);
  }

  upsideDown(enabled) {
    if (enabled) this.append(this.config.UPSIDE_DOWN_ON);
    else this.append(this.config.UPSIDE_DOWN_OFF);
  }

  invert(enabled) {
    if (enabled) this.append(this.config.TXT_INVERT_ON);
    else this.append(this.config.TXT_INVERT_OFF);
  }

  openCashDrawer() {
    this.append(new Buffer([0x1B, 0x70, 0x00, 50, 50]));
    //this.append(new Buffer([0x1B, 0x70, 0x01, 50, 50]));
    this.append(new Buffer([0x10, 0x714, 0x00, 0, 0]));
  }

  alignCenter() {
    this.align = 'center'
    this.append(this.config.TXT_ALIGN_CT);
  }

  alignLeft() {
    this.align = 'left'
    this.append(this.config.TXT_ALIGN_LT);
  }

  alignRight() {
    this.align = 'right'
    this.append(this.config.TXT_ALIGN_RT);
  }

  newLine() {
    this.append(this.config.CTL_LF);
  }

  marginTop(x) {
    for (let i = 0; i < x * 2; i++) {
      this.newLine()
    }
  }

  drawLine() {
    for (let i = 0; i < this.printerConfig.width; i++) {
      this.append('-');
    }
    this.newLine();
  }

  leftRight(left, right) {
    const _left = normalizeString(left.toString())
    const _right = normalizeString(right.toString())
    this.append(_left);
    const width = this.printerConfig.width - _left.length - _right.length;
    for (let i = 0; i < width; i++) {
      this.append(new Buffer(' '));
    }
    this.append(_right);
    this.newLine();
  }

  printPng(img) {
    if (this.address.compress && this.address.printerType === 'bluetooth') {
      if (this.address.printerType === 'usb') {
        pngCompress(img, this.append.bind(this), 2000);
      } else {
        pngCompress(img, this.append.bind(this));
      }
    } else {
      png(img, this);
    }
  }

  async printRaster(img) {
    if (this.address.printerType === 'bluetooth' || this.address.printerType === 'star') {
      return await this.printRasterToBase64(img);
    }
    const width = img.width < 560 ? img.width : 560;
    let height = img.height;
    let step = 1;
    const maxHeight = allConfig['raster_max_height']?.asNumber() || 25;
    while (true) {
      let begin = (step - 1) * maxHeight
      let _height = Math.min(step * maxHeight, height)
      if ((_height - begin) % 96 === 0) _height = _height - 1;
      //[0x1b, 0x40]: clear the data in the print buffer
      //[0x1d, 0x76, 0x30]: print raster image [doc: https://www.stoltronic.pl/wgrane-pliki/commands-manual-vkp80ii-sx.pdf]
      const arr = [0x1b, 0x40, 0x1d, 0x76, 0x30, 48, (width >> 3) & 0xff, 0x00, ((_height - begin) % 256) & 0xff, ((_height - begin) / 256) & 0xff];
      let imageBuffer = img.data.slice(Math.floor(width * begin /8), Math.floor(width * _height / 8));
      // append data
      this.append(Buffer.from(arr));
      this.append(imageBuffer);

      if (_height === height) break;
      step++;
    }
  }

  async printRasterToBase64(raster) {
    this.base64 = await convertToBase64Png(raster, false);
  }

  printPixels(img) {
    png(img, png);
  }

  append(buff) {
    if (typeof buff == 'string') {
      buff = normalizeString(buff);
    }
    if (!buff) return

    // Append new buffer
    if (this.buffer) {
      this.buffer = Buffer.concat([this.buffer, Buffer.from(buff)]);
    } else {
      this.buffer = buff;
    }
  }

  setInternationalCharacterSet(charSet) {
    if (charSet == 'GERMANY') return this.config.CHARCODE_GERMANY;
    return null;
  }

  table(data) {
    const cellWidth = this.printerConfig.width / data.length;
    for (let i = 0; i < data.length; i++) {
      this.append(data[i].toString());
      const spaces = cellWidth - data[i].toString().length;
      for (let j = 0; j < spaces; j++) {
        this.append(new Buffer(' '));
      }
    }
    this.newLine();
  }

  _estimateLine(text, bold, width) {
    let remainText = text
    let res = 1
    while (remainText.length > width) {
      const lastRemainTextLength = remainText.length;
      remainText = remainText.slice(width, remainText.length)
      remainText = remainText.trim()
      if (remainText.length === lastRemainTextLength) {
        break;
      }
      res ++
    }
    return res
  }
  _measureText(text) {
    return {width: text.length}
  }


  advancedTableCustom(tableData, autoAdjustWidth) {
    tableData = _.cloneDeep(tableData);
    const {metaData = {colMetaData: [], rowMetaData: []}, data} = tableData
    const {colMetaData, rowMetaData} = metaData
    let w = data[0].length
    let h = data.length
    if (autoAdjustWidth) {
      for (let i = 0; i < h; i++) {
        for (let j = 0; j < w; j++) {
          data[i][j].text = normalizeString(data[i][j].text.trim())
        }
      }
      let totalPadding = colMetaData.reduce((a,b) => a + (b.padding || 0), 0)
      let totalFixed = 0
      let cntFixed = 0
      const lowPriorityEstimates = []
      let highPriorityMaxWidths = []
      for (let colIdx = 0; colIdx < w; colIdx++) {
        const {priority} = colMetaData[colIdx]
        if (priority === 'HIGH') {
          const maxLen = Math.max.apply(Math, data.map(row => this._measureText(row[colIdx].text, row[colIdx].bold).width))
          highPriorityMaxWidths.push(maxLen / this.getWidth() + 0.02)
          totalFixed += maxLen / this.getWidth() + 0.02
          // cntFixed ++
        } else {
          let row = []
          for (let i = 0; i < 20; i++) {
            let tmp = []
            for (let rowIdx = 0; rowIdx < h; rowIdx++) {
              tmp.push(this._estimateLine(data[rowIdx][colIdx].text, data[rowIdx][colIdx].bold, this.getWidth() * (i + 1) * 0.05))
            }
            // let v = tmp.reduce((a, b) => a + b, 0)
            let v = Math.max.apply(Math, tmp)
            for (let rowIdx = 0; rowIdx < h; rowIdx++) {
              for (let _rowIdx = 0; _rowIdx < h; _rowIdx++) {
                v += Math.abs(tmp[rowIdx] - tmp[_rowIdx]) / 2
              }
            }
            row.push(v)
          }
          lowPriorityEstimates.push(row)
        }
      }

      let optimizedValue = 10000000
      let optimizedWidths

      function getValue(data, widths) {
        let res = 0
        for (let i = 0; i < data.length; i++) {
          res += data[i][widths[i]]
        }
        return res
      }

      function getOptimizedWidths(data, idx, remain, widths) {
        if (idx === data.length) {
          const value = getValue(data, widths)
          // console.log(widths, value)
          if (value < optimizedValue) {
            optimizedValue = value
            optimizedWidths = [...widths]
          }
        } else {
          if (idx === data.length - 1) {
            if (remain < 0.05) return
            widths.push(Math.floor((remain - 0.05) / 0.05))
            getOptimizedWidths(data, idx + 1, 0, widths)
            widths.pop()
          } else {
            for (let i = 0; i < remain / 0.05; i++) {
              widths.push(i)
              getOptimizedWidths(data, idx + 1, remain - (i + 1) * 0.05, widths)
              widths.pop()
            }
          }
        }
      }

      getOptimizedWidths(lowPriorityEstimates, 0, 1 - totalFixed - totalPadding, [])
      let cur = 0
      let cur1 = 0
      let total = 0
      for (let j = 0; j < w; j++) {
        const {priority} = colMetaData[j]
        if (priority !== 'HIGH') {
          colMetaData[j].width = Math.round(((optimizedWidths[cur++] + 1) * 0.05) * 100) / 100
        } else {
          colMetaData[j].width = highPriorityMaxWidths[cur1++]
        }
        total += colMetaData[j].width
      }
      colMetaData[0].width += 1 - totalPadding - total //make sure total widths = 1
      // console.log(optimizedValue, optimizedWidths, lowPriorityEstimates)
    }

    for (let i = 0; i < h; i++) {
      const rowData = []
      for(let j = 0 ; j < w; j++) {
        rowData.push({...colMetaData[j], ...data[i][j]})
        if (colMetaData[j].padding) {
          rowData.push({text: '', width: colMetaData[j].padding})
        }
      }
      this.tableCustom(rowData)
      const {borderBottom} = rowMetaData[i] || {}
      if (borderBottom) {
        this.drawLine()
      }
    }
  }

  // Options: text, align, width, bold
  tableCustom(data, options = {}) {
    data = _.cloneDeep(data);
    for(let i = 0 ; i < data.length; i++) {
      data[i].text = normalizeString(data[i].text.toString())
    }
    function adjustWidthToFitALine(data) {
      let subTotal = 0
      for(let i = 1 ; i < data.length; i++) {
        subTotal += Math.floor(data[i].width * this.getWidth())
      }
      data[0].width = (this.getWidth() - subTotal) / this.getWidth() + 0.00001
      // for(let i = 0 ; i < data.length; i++) {
      // }
    }
    adjustWidthToFitALine.bind(this)(data)
    let j;
    let spaces;
    let cellWidth = this.printerConfig.width / data.length;
    const secondLine = [];
    let secondLineEnabled = false;

    for (let i = 0; i < data.length; i++) {
      let tooLong = false;
      const obj = data[i];
      obj.text = obj.text.toString();
      while (obj.text.length && obj.text.charAt(0) === '\n') obj.text = obj.text.substring(1)
      if (obj.width) cellWidth = this.printerConfig.width * obj.width;
      if (options.textDoubleWith) cellWidth /= 2;
      if (obj.bold) this.bold(true);

      // If text is too wide go to next line
      const newLineIdx = obj.text.indexOf('\n') === -1 ? 10000000 : obj.text.indexOf('\n');
      if (cellWidth < obj.text.length) {
        tooLong = true;
      }
      obj.originalText = obj.text;
      obj.text = obj.text.substring(0, Math.min(cellWidth, newLineIdx));

      if (obj.align === 'CENTER') {
        spaces = (cellWidth - obj.text.toString().length) / 2;
        spaces = Math.floor(spaces)
        for (j = 0; j < spaces; j++) {
          this.append(new Buffer(' '));
        }
        if (obj.text !== '') this.append(obj.text);
        for (j = 0; j < spaces - 1; j++) {
          this.append(new Buffer(' '));
        }

      } else if (obj.align === 'RIGHT') {
        spaces = cellWidth - obj.text.toString().length;
        spaces = Math.floor(spaces)
        for (j = 0; j < spaces; j++) {
          this.append(new Buffer(' '));
        }
        if (obj.text !== '') this.append(obj.text);

      } else {
        if (obj.text !== '') this.append(obj.text);
        spaces = cellWidth - obj.text.toString().length;
        spaces = Math.floor(spaces);
        for (j = 0; j < spaces; j++) {
          this.append(new Buffer(' '));
        }
      }

      if (obj.bold) this.bold(false);

      if (tooLong) {
        secondLineEnabled = true;
        obj.text = obj.originalText.substring(obj.text.length);
        secondLine.push(obj);
      } else {
        obj.text = '';
        secondLine.push(obj);
      }
    }

    if (!options.textDoubleWith) this.newLine();

    // Print the second line
    if (secondLineEnabled) {
      this.tableCustom(secondLine, options);
    }
  }

  printBarcode(data) {
    this.append(new Buffer([0x1d, 0x6B, 0x49]));
    this.append(new Buffer([data.length + 1]));
    this.append(new Buffer(data));
    this.append(new Buffer([0x10]));
  }

  setTextNormal() {
    this.append(this.config.TXT_NORMAL);
  }

  setTextDoubleHeight() {
    this.append(this.config.TXT_2HEIGHT);
  }

  setTextDoubleWidth() {
    this.append(this.config.TXT_2WIDTH);
  }

  setTextQuadArea() {
    this.append(this.config.TXT_4SQUARE);
  }

  println(text) {
    text = normalizeString(text);
    this.append(text.toString());
    this.append('\n');
  }

  printText(text) {
    text = normalizeString(text);
    const lines = text.split('\n');
    for (const line of lines) {
      if (line) this.println(line);
    }
  }

  printQrCode(str, ratio) {
    const settings = {
      model: 2,
      cellSize: 5,
      correction: 'M'
    };

    // [Name] Select the QR code model
    // [Code] 1D 28 6B 04 00 31 41 n1 n2
    // n1
    // [49 x31, model 1]
    // [50 x32, model 2]
    // [51 x33, micro qr code]
    // n2 = 0
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=140
    if (settings.model === 1) this.append(this.config.QRCODE_MODEL1);
    else if (settings.model === 3) this.append(this.config.QRCODE_MODEL3);
    else this.append(this.config.QRCODE_MODEL2);

    // [Name]: Set the size of module
    // 1D 28 6B 03 00 31 43 n
    // n depends on the printer
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=141
    const size = 'QRCODE_CELLSIZE_'.concat(settings.cellSize.toString());
    this.append(this.config[size]);

    // [Name] Select the error correction level
    // 1D 28 6B 03 00 31 45 n
    // n
    // [48 x30 -> 7%]
    // [49 x31-> 15%]
    // [50 x32 -> 25%]
    // [51 x33 -> 30%]
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=142
    const correction = 'QRCODE_CORRECTION_'.concat(settings.correction.toUpperCase());
    this.append(this.config[correction]);

    // [Name] Store the data in the symbol storage area
    // 1D 28  6B pL pH 31 50 30 d1...dk
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=143
    const s = str.length + 3;
    const lsb = parseInt(s % 256);
    const msb = parseInt(s / 256);
    this.append(Buffer.from([0x1d, 0x28, 0x6b, lsb, msb, 0x31, 0x50, 0x30]));
    this.append(Buffer.from(str));

    // [Name] Print the symbol data in the symbol storage area
    // 1D 28 6B 03 00 31 51 m
    // https://reference.epson-biz.com/modules/ref_escpos/index.php?content_id=144
    this.append(this.config.QRCODE_PRINT);

    return this.buffer;
  }

  // async printQrCode(text, ratio) {
  //   // if (!QRCode) QRCode = (import('./qrcode.js')).default;
  //
  //   if (typeof text !== 'string') text = text.toString();
  //
  //   return new Promise(resolve => {
  //     let qrBinData = Buffer.from([]);
  //
  //     const writeStream = new Writable();
  //     writeStream._write = function (chunk, encoding, cb) {
  //       qrBinData = Buffer.concat([qrBinData, chunk]);
  //       cb();
  //     }
  //     writeStream.on('finish', () => {
  //       this.printImage(qrBinData, 'buffer', ratio).then(resolve);
  //     });
  //
  //     QRCode.toFileStream(writeStream, text);
  //   });
  // }

  async printImage(imageInput, inputType, ratio = 1) {
    const {canvasWidth} = this.printerConfig
    const originalCanvasWidth = canvasWidth || DEFAULT_CANVAS_WIDTH
    let imageData;
    if (inputType === 'base64') {
      imageInput = Buffer.from(imageInput, 'base64')
      inputType = 'buffer'
    }
    // if (inputType === 'path') {
    //   const fStream = fs.createReadStream(imageInput)
    //   imageData = await PureImage.decodePNGFromStream(fStream)
    // }
    if (inputType === 'buffer') {
      const imageReadStream = new Readable();
      imageReadStream.push(imageInput);
      imageReadStream.push(null);
      imageData = await PureImage.decodePNGFromStream(imageReadStream)
    }
    const {width: imgWidth, height: imgHeight} = imageData
    const scaledImgHeight = imgHeight / (imgWidth / originalCanvasWidth) * ratio
    const scaledImgWidth = imgWidth / (imgWidth / originalCanvasWidth) * ratio

    const align = this.align || 'left'
    let x
    switch (align) {
      case 'left':
        x = 0
        break
      case 'right':
        x = originalCanvasWidth - scaledImgWidth
        break
      case 'center':
        x = Math.round((originalCanvasWidth - scaledImgWidth) / 2)
        break
    }
    const canvas = PureImage.make(originalCanvasWidth, scaledImgHeight, {});
    const canvasContext = canvas.getContext();
    canvasContext.translate(0.5, 0.5)
    canvas.data.fill(0)
    canvasContext.drawImage(imageData, 0, 0, imgWidth, imgHeight, x, 0, scaledImgWidth, scaledImgHeight)
    function _canvasToPngBuffer(canvas) {
      return new Promise(async resolve => {
        let canvasImageBuffer = Buffer.from([]);
        const writeStream = new Writable();
        writeStream._write = function (chunk, encoding, cb) {
          canvasImageBuffer = Buffer.concat([canvasImageBuffer, chunk]);
          cb();
        }
        writeStream.on('finish', async () => {
          resolve(canvasImageBuffer);
        });
        await PureImage.encodePNGToStream(canvas, writeStream);
      });
    }

    const pngBuffer = await _canvasToPngBuffer(canvas)
    const png = PNG.sync.read(pngBuffer);
    this.printPng(png)
    if (align === 'left') this.alignLeft()
    else if (align === 'right') this.alignRight()
    else this.alignCenter()
  }

  reset() {
    this.append(this.config.HW_INIT)
  }
  _reset() {
    // do nothing
  }
}
