import { type FunctionComponent, memo, useCallback, useState } from "react";
import StaffReportLeft from "./StaffReportLeft";
import {LL0} from "@/react/core/I18nService.tsx";
import { router} from "@/pos/PosRouter.ts";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import InventoryDateChoosingPo from "@time-management/InventoryDateChoosingPo.tsx";
import { WeeklySchedule } from "@/react/TimeManagementView/TimeManagementView.logic.ts"
import TimeManagementRow from "@time-management/TimeManagementRow.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";


const TimeManagementPlugin: FunctionComponent = () => {
  const [isInventoryDateChoosingPoOpen, setInventoryDateChoosingPoOpen] =
    useState(false);

  const openInventoryDateChoosingPo = useCallback(() => {
    setInventoryDateChoosingPoOpen(true);
  }, []);

  const closeInventoryDateChoosingPo = useCallback(() => {
    setInventoryDateChoosingPoOpen(false);
  }, []);

  return (
    <>
      <div className="bg-black-solid-black-700-364150 w-full h-full flex flex-col items-start justify-between text-center text-sm text-white-solid-white-100-ffffff font-mulish Tablet_600:items-start Tablet_600:justify-start">
      <div className="self-stretch flex-1 overflow-y-auto flex flex-row items-start justify-start">
        <StaffReportLeft />
        <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-4 px-[23px] gap-[14px] sm:pl-4 sm:pr-4 sm:box-border Tablet_768:py-3 Tablet_768:px-2 Tablet_768:box-border">
          <div className="flex flex-row items-center justify-start gap-[6px]" onClick={()=>setInventoryDateChoosingPoOpen(true)}>
            <div className="relative rounded-981xl w-9 h-9">
              <img
                className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                alt=""
                src="/iconcalendar-icon@2x.png"
              />
            </div>
            <div className="relative [text-decoration:underline]">
              {LL0().onlineOrder.thisWeek()}
            </div>
          </div>
          <div className="self-stretch relative rounded-md [background:linear-gradient(180deg,_rgba(247,_247,_247,_0.42),_rgba(170,_169,_169,_0.42))] h-[220px] overflow-hidden shrink-0 grid grid-rows-[repeat(5,44px)] grid-cols-[repeat(2,2fr)_repeat(2,4fr)_1fr_4fr] [grid-row-gap:0px] [grid-column-gap:0px] text-base">
            <div className="[background:linear-gradient(180deg,_#85a8e0,_#446dc8)] flex flex-row items-center justify-center p-2.5 relative">
              <b className="relative sm:text-smi">{LL0().settings.happyHour.days()}</b>
            </div>
            <div className="[background:linear-gradient(180deg,_#85a8e0,_#446dc8)] flex flex-row items-center justify-center p-2.5 gap-[10px] relative">
              <b className="relative sm:text-smi">{LL0().monthlyReport.shift()}</b>
              <img
                className="relative w-[7.4px] h-[9.5px]"
                alt=""
                src="/arrow-67.svg"
              />
            </div>
            <div className="[background:linear-gradient(180deg,_#85a8e0,_#446dc8)] flex flex-row items-center justify-center p-2.5 relative">
              <b className="relative sm:text-smi">{LL0().timeClock.clockOutSuccessDialog.totalHours()}</b>
            </div>
            <div className="[background:linear-gradient(180deg,_#85a8e0,_#446dc8)] flex flex-row items-center justify-center p-2.5 relative">
              <b className="relative sm:text-smi">{LL0().monthlyReport.out()}</b>
            </div>
            <div className="[background:linear-gradient(180deg,_#85a8e0,_#446dc8)] relative"/>
            <div className="[background:linear-gradient(180deg,_#85a8e0,_#446dc8)] flex flex-row items-center justify-center p-2.5 relative">
              <b className="relative sm:text-smi">{LL0().monthlyReport.in()}</b>
            </div>
            {WeeklySchedule.map((dateSchedule)=>(
              dateSchedule.shifts.map((shiftData, index)=>(
                <TimeManagementRow key={index} {...shiftData} date={dateSchedule.dayName} isShowDate={index === 0}/>
              ))
            ))}
            {/*<TimeManagementRow />*/}
            {/*<TimeManagementRow />*/}
            {/*<TimeManagementRow />*/}

          </div>
        </div>
      </div>
      <div className="self-stretch bg-black-solid-black-500-59636f h-[42px] overflow-hidden shrink-0 flex flex-col items-start justify-center py-1 px-2 box-border text-left sm:h-[42px]">
        <div className="rounded-[30px] bg-blue-solid-blue-450-1271ff h-[35px] flex flex-row items-center justify-center py-2 px-[17px] box-border gap-[8px]"
          onClick={()=>router.screen = mainScreen()}
          ref={makeRipple}
        >
          <div className="relative w-[30px] h-[30px]">
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
          </div>
          <b className="relative">{LL0().ui.back()}</b>
        </div>
      </div>
    </div>
      {isInventoryDateChoosingPoOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeInventoryDateChoosingPo}
        >
          <InventoryDateChoosingPo onClose={closeInventoryDateChoosingPo} />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(TimeManagementPlugin);
