import React, { type FunctionComponent, useState, useCallback, memo } from "react";
import GeneralSettingsTrainingMode1 from "./GeneralSettingsTrainingModeOn";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import GeneralSettingsTrainingMode from "./GeneralSettingsTrainingModeOff";
import { router } from "@/pos/PosRouter.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import GeneralSettingsLeftForm from "@general-settings/GeneralSettingsLeftForm.tsx";
import DevicesListRightForm from "@general-settings/DevicesListRightForm.tsx";
import { onToggleTrainingMode, timezoneOptions } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";
import { Order } from "@/data/Order.ts";
import { checkOngoingOrder } from "@general-settings/CheckOngoingOrder.tsx";
import CertificateInfoCell from "@/srm/components/CertificateInfoCell.tsx";
import { srmDeviceSetting } from "@/srm/cert.logic.ts";
import { isInSrmTrainingMode } from "@/data/DeviceSettingSignal";
import { companyInfo0, isQuebecSrmEnabled, mainScreen } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import _ from "lodash";
import { ListSubheader, MenuItem, Select } from "@mui/material";
import { masterDeviceSetting } from "@/data/DeviceSettingHub.ts";

const GeneralSettingsPlugin: FunctionComponent = () => {
  const [
    isGeneralSettingsTrainingModeOpen,
    setGeneralSettingsTrainingModeOpen,
  ] = useState(false);
  const [
    isGeneralSettingsTrainingMode1Open,
    setGeneralSettingsTrainingMode1Open,
  ] = useState(false);

  const openGeneralSettingsTrainingMode = useCallback(async () => {
    userFLow(`openGeneralSettingsTrainingMode general settings`, {
      username: loginUser()?.name
    })
    const remainOrders = await Order.find().exec();
    if(remainOrders.filter(o => o.table).length > 0){
      await checkOngoingOrder()
      return;
    }
    setGeneralSettingsTrainingModeOpen(true);
  }, []);

  const closeGeneralSettingsTrainingMode = useCallback(() => {
    setGeneralSettingsTrainingModeOpen(false);
  }, []);

  const openGeneralSettingsTrainingMode1 = useCallback(() => {
    setGeneralSettingsTrainingMode1Open(true);
  }, []);

  const closeGeneralSettingsTrainingMode1 = useCallback(() => {
    setGeneralSettingsTrainingMode1Open(false);
  }, []);

  return (
    <>
      <div
        className='general-setting-screen w-full h-full bg-white-solid-white-100-ffffff max-w-full overflow-hidden flex flex-row items-start justify-start text-left text-mini text-gray-solid-gray-850-767676 font-mulish'
      >
        <div className="max-w-[50%] self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch h-[50px] [backdrop-filter:blur(8px)] bg-gray-solid-gray-130-f4f4f4 flex flex-row items-center justify-start py-[15px] pr-0 pl-6 box-border Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
            <b className="relative uppercase">{LL0().sidebar.generalSettings()}:</b>
          </div>
          <GeneralSettingsLeftForm />
        </div>
        <div className="self-stretch w-[0.5px] relative box-border opacity-[0.8] border-r-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1" />
        <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch [backdrop-filter:blur(8px)] bg-gray-solid-gray-130-f4f4f4 h-[50px] flex flex-row items-center justify-between py-0 pr-2 pl-6 box-border Tablet_600:pl-4 Tablet_600:box-border">
            <b className="relative uppercase">{LL0().settings.deviceManager.devices()}:</b>
            <div onClick={() => {
              userFLow(`back from general settings to ${mainScreen()}`, {
                username: loginUser()?.name
              })
              router.screen = mainScreen()
            }}
                 ref={makeRipple}
                 className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-blue-solid-blue-450-1271ff h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] text-center text-sm text-white-solid-white-100-ffffff">
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
              <b className="w-[45px] relative leading-[17px] hidden">Button</b>
            </div>
          </div>
          <DevicesListRightForm />
          {companyInfo0()?.country && companyInfo0()?.country !== 'de' &&
            <div className="self-stretch flex flex-col items-start justify-start py-2 px-6 gap-[10px_0px] Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
              <b className="relative [text-decoration:underline] uppercase">
                {LL0().settings.trainingMode()}
              </b>
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px] text-smi text-orange-solid-orange-e47b00">
                {!isInSrmTrainingMode() ?
                  <div className="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
                    <i className="flex-1 relative font-medium">
                      {LL0().settings.turnOnTrainingMode()}
                    </i>
                    <div
                      className="flex-1 rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] box-border h-[37px] flex flex-row items-center justify-center py-0 px-2 max-w-[88px] cursor-pointer text-center text-sm text-black-solid-black-880-1d1d26 border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                      onClick={openGeneralSettingsTrainingMode}
                    >
                      <div className="relative">{LL0().settings.turnOn()}</div>
                    </div>
                  </div>
                  :
                  <div className="self-stretch flex flex-row items-center justify-start gap-[0px_8px] text-green-solid-green-680-00b35d">
                    <i className="flex-1 relative font-medium">
                      {LL0().settings.turnOffTrainingMode()}
                    </i>
                    <div
                      className="flex-1 rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] box-border h-[37px] flex flex-row items-center justify-center py-0 px-2 max-w-[88px] cursor-pointer text-center text-sm text-black-solid-black-880-1d1d26 border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
                      onClick={openGeneralSettingsTrainingMode1}
                    >
                      <div className="relative">{LL0().settings.turnOff()}</div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
          <div className='w-full px-6 flex flex-col gap-2'>
            <div className='text-[#1d1d26] text-[14px]'>{LL0().settings.timezone()}</div>
            <Select
              className='w-full'
              value={masterDeviceSetting()?.timezone}
              onChange={(e) => {
                userFLow(`Timezone in master machine ${masterDeviceSetting()?.timezone} to ${e.target.value}`, {
                  username: loginUser()?.name
                });
                _.assign(masterDeviceSetting(), {forceTimezone: true, timezone: e.target.value});

              }}
              size={'small'}
            >
              {timezoneOptions.map((region) => [
                <ListSubheader key={region.country}>{region.country}</ListSubheader>,
                ...region.timezones.map((timezone) => (
                  <MenuItem key={timezone} value={timezone}>
                    {timezone}
                  </MenuItem>
                )),
              ])}
            </Select>
          </div>
          {isQuebecSrmEnabled() &&
            <div className='self-stretch flex flex-col items-start justify-start py-2 px-6 gap-[10px_0px] Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border'>
              <p>{LL0().settings.userSettings.certificate()}</p>
              <CertificateInfoCell
                cert={srmDeviceSetting()?.cert}
                download="cert.pem"
              />
            </div>}
        </div>
      </div>
      {isGeneralSettingsTrainingModeOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeGeneralSettingsTrainingMode}
        >
          <GeneralSettingsTrainingMode1
            onClose={closeGeneralSettingsTrainingMode}
            onComplete={onToggleTrainingMode}
          />
        </PortalPopup>
      )}
      {isGeneralSettingsTrainingMode1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeGeneralSettingsTrainingMode1}
        >
          <GeneralSettingsTrainingMode
            onClose={closeGeneralSettingsTrainingMode1}
            onComplete={onToggleTrainingMode}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(GeneralSettingsPlugin);