import PortalPopup from "@/react/core/PortalPopup.tsx";
import React from 'react'

import { Button } from '@/components/ui/button.tsx'
import { handleDeleteAll, openDeleteAllPopup, setOpenDeleteAllPopup } from '@/react/Developer/DeleteAllPopup.logic.ts'

const DeleteAllPopup = () => {
  return (
    <>
      {openDeleteAllPopup() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setOpenDeleteAllPopup(false)}
        >
          <div className="relative rounded-10xs bg-white flex flex-col justify-center items-center pt-[19px] px-[32px] pb-[26px] box-border gap-[16px] w-full max-w-full min-w-[200px] max-h-full">
            <Button
              variant={'default'}
              size={'sm'}
              className={'h-[30px] w-fit float-right'}
              onClick={handleDeleteAll}
            >
              Delete
            </Button>
            <Button
              variant={'default'}
              size={'sm'}
              className={'h-[30px] w-fit float-right'}
              onClick={() => setOpenDeleteAllPopup(false)}
            >
              Cancel
            </Button>
          </div>
        </PortalPopup>
      )}
    </>
  )
}

export default DeleteAllPopup
