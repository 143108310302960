import { type FunctionComponent, useState, useCallback } from "react";
import DeleteConfirmationPopupV from "./DeleteConfirmationPopupV";
import PortalPopup from "./PortalPopup";
import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import { materialCategories } from "@/data/CategoryHub.ts";
import _ from "lodash";
import { createNewCategory, deleteCategory, existCategoryMap } from "@/react/InventoryView/InventoryView.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type InventoryAddEditCategoryType = {
  className?: string;
  onClose?: () => void;
};

const InventoryAddEditCategory: FunctionComponent<
  InventoryAddEditCategoryType
> = ({ className = "", onClose }) => {
  const [isDeleteConfirmationPopupV1Open, setDeleteConfirmationPopupV1Open] =
    useState(false);

  const openDeleteConfirmationPopupV1 = useCallback(() => {
    setDeleteConfirmationPopupV1Open(true);
  }, []);

  const closeDeleteConfirmationPopupV1 = useCallback(() => {
    setDeleteConfirmationPopupV1Open(false);
  }, []);

  return (
    <>
      <div
        className={`w-[448px] relative bg-white-solid-white-100-ffffff h-[400px] flex flex-col items-start justify-start pt-[18px] px-0 pb-0 box-border gap-4 max-w-full max-h-full overflow-auto text-left text-base text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-center py-0 px-2 z-[0]">
          <b className="flex-1 relative">{LL0().productViewSetting.categoryEditPopup.title()}</b>
          <img
            className="w-[21px] relative h-[21px] object-cover cursor-pointer"
            alt=""
            src="/iconclose-popup-icon1@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-2.5 pb-[98px] gap-4 z-[1] md:items-start md:justify-start">
          {materialCategories().map(category =>
            <div className="self-stretch flex flex-row items-center justify-start gap-1"
                 key={category._id}
            >
              <TextField
                className="flex-1 font-mulish text-sm text-black-solid-black-1000-000000"
                color="primary"
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "37px" } }}
                value={category.name}
                onChange={e => _.assign(category, { name: e.target.value })}
              />
              <button className="w-[37px] bg-red-solid-red-240-eb5458 rounded-10xs disabled:bg-gray-solid-gray-490-bdbdbd h-[37px] flex flex-row items-center justify-center"
                      disabled={existCategoryMap()[category._id]}
                      onClick={async () => {
                        await deleteCategory(category)
                      }}
                      ref={makeRipple}
              >
                <img
                  className="w-[37px] relative h-[37px] object-cover"
                  alt=""
                  src="/iconicon-screensbin-white-icon-ver1@2x.png"
                />
              </button>
            </div>
          )}

        </div>
        <div className="w-full !m-[0] absolute right-[0%] bottom-[0px] left-[0%] bg-white-solid-white-100-ffffff border-gray-solid-gray-400-c9cbcc border-t-[1px] border-solid box-border flex flex-col items-start justify-center pt-2.5 px-2.5 pb-4 gap-2 z-[2] text-xs">
          <i className="self-stretch relative">
            *{LL0().inventory.onlyEmpty()}
          </i>
          <div className="self-stretch flex flex-row items-center justify-start gap-3 text-center text-sm text-white-solid-white-100-ffffff">
            <div className="flex-1 rounded-md bg-green-solid-green-00c968 overflow-hidden flex flex-row items-center justify-center py-[9px] px-3"
                 onClick={createNewCategory}
                 ref={makeRipple}
            >
              <b className="flex-1 relative">+ {LL0().inventory.filter.category()}</b>
            </div>
            <div className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-3"
                 onClick={onClose}
                 ref={makeRipple}
            >
              <b className="relative">{LL0().ui.save()}</b>
            </div>
          </div>
        </div>
      </div>
      {isDeleteConfirmationPopupV1Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
        >
          <DeleteConfirmationPopupV onClose={closeDeleteConfirmationPopupV1} />
        </PortalPopup>
      )}
    </>
  );
};

export default InventoryAddEditCategory;