import React, { Fragment, type FunctionComponent, useEffect } from 'react'
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch } from '@mui/material'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import {
  extractMapItems,
  fullRefund,
  handleRefund,
  makeSelectedOrderReactive,
  mapRefundItems,
  order1,
  orderHistoryLogic,
  refundAmount,
  refundMode,
  RefundMode,
  refundReason,
  setFullRefund, setIsOpenRefundItemsPopup,
  setRefundAmount,
  setRefundMode,
  setRefundReason,
} from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { useSignal } from '@/react/core/reactive.ts'
import { toast } from 'react-toastify'
import { CommitAction } from '@/pos/OrderType.ts'
import Input, { customParseFloat } from '@/react/core/Input.tsx'
import TextField from '@/react/core/TextField.tsx'
import KeyboardNumber from "@order-history/keyboard/KeyboardNumber.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { userFLow } from '@/shared/logger'
import { loginUser } from '@/data/UserSignal'

export type OrderHistoryRestaurantPOSType = {
  onClose?: () => void;
};

const OrderHistoryRestaurantPOS: FunctionComponent<
  OrderHistoryRestaurantPOSType
> = ({ onClose }) => {
  const [focusIndex, setFocusIndex] = useSignal<number>(-1)
  const [inputValue, setInputValue] = useSignal<string>('')
  const [idRefund, setIdRefund] = useSignal<string>('')

  const setCustomRefund = (amount: string) => {
    if (idRefund() === order1().items?.[focusIndex()]._id!) {
      mapRefundItems[order1().items?.[focusIndex()]._id!] = Number(amount)
    }
    // order1().commits!.push({
    //   action: CommitAction.REFUND,
    //   commitId: item.commitRefs![0] || item._id,
    //   quantityDelta: -1
    // })
  }

  const customRefund = () => {
    if (focusIndex() < 0) return (0).toString();
    const result = mapRefundItems[order1().items[focusIndex()]._id!]
    return result.toString();
  }

  const openOrderHistoryRefundReason = async () => {
    userFLow("Next step reason in refund Order History", {
      username: loginUser()?.name
     })
    if (refundMode() === RefundMode.ITEMS ) {
      await orderHistoryLogic.proceedToSelectRefundReason()
    }

    if (refundMode() === RefundMode.AMOUNT) {
      if (order1()?.vSum && !!refundAmount() && refundAmount() !== 0 && refundAmount() > (order1()?.vSum as number)){
        toast.error("The amount must be less than the original total")
        return
      }

      await handleRefund()
    }
    setIsOpenRefundItemsPopup(false)
  }

  useEffect(() => {
    if (refundMode() === RefundMode.AMOUNT) {
      setRefundAmount(Number(inputValue()))
    } else {
      if (focusIndex() < 0) return;
      setCustomRefund(inputValue())
    }
  }, [inputValue()])

  useEffect(() => {
    if (focusIndex() < 0) return;
    // setInputValue(refundAmount().toString())
  }, [focusIndex()])

  useEffect(() => {
    makeSelectedOrderReactive()
    extractMapItems()
  }, [])

  return (
    <>
      <div
        className="refund-popup pt-4 w-[800px] h-full relative rounded-lg bg-white-solid-white-100-ffffff flex flex-col items-start justify-start gap-[6px] max-w-full max-h-full text-left text-base text-black-solid-black-1000-000000 font-mulish-button-name-bold">
        {/*<div*/}
        {/*  className="height_414:hidden self-stretch flex flex-row items-center justify-start py-2 px-3 gap-[8px] text-black-solid-black-880-1d1d26 border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1">*/}
        {/*  <b className="flex-1 relative leading-[24px] Mobile_480:text-sm">*/}
        {/*    {LL0().onlineOrder.refund()}*/}
        {/*  </b>*/}
        {/*  <img*/}
        {/*    className="w-[22px] relative h-[22px] object-cover cursor-pointer"*/}
        {/*    alt=""*/}
        {/*    src="/icongeneralclose-popup-icon--grey@2x.png"*/}
        {/*    onClick={onClose}*/}
        {/*  />*/}
        {/*</div>*/}
        <div
          className="self-stretch flex-1 flex flex-col items-start justify-start py-0 px-3 gap-[10px] text-black-solid-black-1000-000000">
          <div
            className="self-stretch rounded-10xs flex flex-row items-start justify-start p-0.5 gap-[4px] border-[1px] border-solid border-gray-solid-gray-170-e3e4e5">
            <div
              className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[11px] px-3 relative gap-[8px] Mobile_480:pt-[7px] Mobile_480:pb-[7px] Mobile_480:box-border"
              onClick={() => {
                setRefundMode(RefundMode.AMOUNT)
              }}
              ref={makeRipple}
            >
              {refundMode() === RefundMode.AMOUNT && <>
                <div
                  className="!flex w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-400-0091ff hidden z-[0]" />
                <div
                  className="!flex w-[61px] relative font-semibold text-white-solid-white-100-ffffff hidden z-[2] Mobile_480:text-sm">
                  {LL0().orderHistory.amount()}
                </div>

              </>}

              {refundMode() !== RefundMode.AMOUNT && <div className="relative font-semibold z-[1] Mobile_480:text-sm">
                {LL0().orderHistory.amount()}
              </div>}
            </div>
            <div
              className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[11px] px-3 relative gap-[8px] Mobile_480:pt-[7px] Mobile_480:pb-[7px] Mobile_480:box-border"
              onClick={() => setRefundMode(RefundMode.ITEMS)}
              ref={makeRipple}
            >
              {refundMode() === RefundMode.ITEMS && <>
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-400-0091ff z-[0]" />
                <div className="relative font-semibold text-white-solid-white-100-ffffff z-[2] Mobile_480:text-sm">
                  {LL0().onlineOrder.refundDialog.items()}
                </div>

              </>}
              {refundMode() !== RefundMode.ITEMS &&
                <div className="!flex w-[41px] relative font-semibold hidden z-[1] Mobile_480:text-sm">
                  {LL0().onlineOrder.refundDialog.items()}
                </div>}
            </div>
          </div>
          {refundMode() === RefundMode.ITEMS && <div
            className="self-stretch flex-1 flex flex-col items-start justify-start gap-[10px] text-black-solid-black-880-1d1d26">
            <div className="self-stretch flex flex-row items-center justify-center">
              <div className="flex-1 flex flex-row items-center justify-start py-0 px-2.5 gap-[12px]">
                <div className="w-10 relative h-6">
                  <FormControlLabel
                    label={''}
                    className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px]"
                    control={<Switch color="primary" checked={fullRefund()}
                                     onChange={e => setFullRefund(e.target.checked)} />}
                  />
                </div>
                <div className="flex-1 relative">{LL0().orderHistory.fullRefund()}</div>
              </div>
            </div>
            {!fullRefund() && <div
              className="height_414:h-[124px] Tablet_768:h-[150px] no-scrollbar self-stretch relative rounded box-border h-[184px] overflow-y-auto shrink-0 grid grid-rows-[40px_repeat(3,1fr)] grid-cols-[1fr_3fr_repeat(3,2fr)] [grid-row-gap:0px] [grid-column-gap:0px] border-[1px] border-solid border-gray-solid-gray-320-d2d2d2">
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-start p-2 relative col-[1/1] row-[1/1] text-white-solid-white-100-ffffff">
                <b className="flex-1 relative leading-[18px] Mobile_480:text-sm">
                  No
                </b>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-start p-2 relative col-[2/2] row-[1/1] text-white-solid-white-100-ffffff">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <b
                    className="self-stretch relative leading-[18px] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {LL0().article.product()}
                  </b>
                </div>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-center p-2 relative col-[3/3] row-[1/1] text-center text-white-solid-white-100-ffffff">
                <div className="flex-1 flex flex-col items-center justify-center">
                  <b
                    className="self-stretch relative leading-[18px] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {LL0().order.quantity()}
                  </b>
                </div>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-center p-2 relative col-[4/4] row-[1/1] text-right text-white-solid-white-100-ffffff">
                <b className="flex-1 relative leading-[18px] Mobile_480:text-sm">
                  {LL0().article.price()}
                </b>
              </div>
              <div
                className="bg-gray-solid-gray-730-737373 flex flex-row items-center justify-center p-2 relative col-[5/5] row-[1/1] text-right text-white-solid-white-100-ffffff">
                <b className="flex-1 relative leading-[18px] Mobile_480:text-sm">
                  {LL0().orderHistory.customRefund()}
                </b>
              </div>
              {order1()?.items.map((item, index) => <Fragment key={index}>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-start p-2 relative col-auto row-auto border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <b
                    className="flex-1 relative leading-[150%] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {item.id}
                  </b>
                </div>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-start p-2 relative col-auto row-auto border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <div
                    className="flex-1 relative leading-[150%] font-semibold overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {item.name}
                  </div>
                </div>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-col items-center justify-center py-0 px-2 relative col-auto row-auto text-gray-solid-gray-460-9ca3af border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <div className="self-stretch flex flex-row items-center justify-center">
                    <img
                      className="w-[22px] relative h-[22px] object-cover"
                      alt=""
                      src="/iconretailsminus@2x.png"
                      onClick={() => {
                        userFLow("Minus item Order History", {
                          orderId: order1()?._id,
                          username: loginUser()?.name
                        })
                        if (item.quantity <= 0 ) {
                          toast('Cannot descrease quantity more than 0')
                          return
                        }
                        order1().commits!.push({
                          action: CommitAction.CHANGE_QUANTITY,
                          commitId: item.commitRefs![0] || item._id || '',
                          quantityDelta: !Number.isInteger(item?.quantity) ? -item.quantity :-1
                        })
                      }}
                    />
                    <div
                      className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff box-border h-9 flex flex-row items-center justify-start py-0 px-1 gap-[3px] min-w-[55px] border-[1px] border-solid border-gray-solid-gray-320-d2d2d2">
                      <div className="flex-1 relative leading-[18px] text-black-solid-black-880-1d1d26 text-right">
                        {item.quantity}
                      </div>
                      <div className="relative text-mini leading-[30px] Mobile_480:text-sm">
                        /
                      </div>
                      <div className="relative leading-[30px] Mobile_480:text-sm">
                        {item.lastQuantity}
                      </div>
                    </div>
                    <img
                      className="w-[22px] relative h-[22px] object-cover"
                      alt=""
                      src="/iconretailsplus@2x.png"
                      onClick={() => {
                        userFLow("Plus item Order History", {
                          orderId: order1()?._id,
                          username: loginUser()?.name
                        })
                        const add = item?.lastQuantity && !Number.isInteger(item?.lastQuantity) ? item?.lastQuantity : 1;
                        if (item.quantity + add > (item.lastQuantity || 0)) {
                          toast('Cannot increase quantity more than original quantity')
                          return
                        }
                        order1().commits!.push({
                          action: CommitAction.CHANGE_QUANTITY,
                          commitId: item.commitRefs![0] || item._id || '',
                          quantityDelta: item?.lastQuantity && !Number.isInteger(item?.lastQuantity) ? item?.lastQuantity : 1
                        })
                      }}
                    />
                  </div>
                  {/*<div*/}
                  {/*  className="self-stretch relative text-mini leading-[30px] text-black-solid-black-880-1d1d26 Mobile_480:text-sm">*/}
                  {/*  {item.quantity}*/}
                  {/*</div>*/}
                </div>
                <div
                  className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-end p-2 relative col-auto row-auto border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <div
                    className="text-right flex-1 relative leading-[150%] overflow-hidden text-ellipsis whitespace-nowrap Mobile_480:text-sm">
                    {LL3().format.currency(item.vTotal ?? 0)}
                  </div>
                </div>
                <div onClick={() => {
                  userFLow("item.vTotal Order History", {
                    orderId: order1()?._id,
                    username: loginUser()?.name
                  })
                  setFocusIndex(index);
                  setIdRefund(item._id!)
                }}
                  className="bg-gray-solid-gray-180-ebecee self-stretch flex flex-row items-center justify-center p-2 relative col-auto row-auto border-b-[0.5px] border-solid border-gray-solid-gray-750-696969">
                  <TextField
                    showKeyboardIcon={false}
                    className="flex-1 rounded-10xs bg-white-solid-white-100-ffffff box-border h-9 flex flex-row items-center justify-start gap-[3px] min-w-[55px]"
                    value={
                      (mapRefundItems[item._id!] || 0).toString()
                    }
                    onChange={e =>
                      mapRefundItems[item._id!] = customParseFloat(e.target.value)
                    }>
                  </TextField>
                  {/*<div onClick={() => setFocusIndex(index)} onBlur={() => setFocusIndex(-1)}*/}
                  {/*  className={clsx('flex-1 rounded-10xs bg-white-solid-white-100-ffffff box-border h-9 flex flex-row items-center justify-start py-0 px-1 gap-[3px] min-w-[55px] border-[1px] border-solid',*/}
                  {/*    focusIndex() === index ? 'border-[#1976D2]' : 'border-gray-solid-gray-320-d2d2d2'*/}
                  {/*  )}>*/}
                  {/*  <div className="flex-1 relative leading-[18px] text-black-solid-black-880-1d1d26 text-right">*/}
                  {/*    {LL3().format.currency(mapRefundItems[item._id!] || 0)}*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </Fragment>)}
            </div>}
          </div>}
        </div>
        {refundMode() === RefundMode.AMOUNT && <div
          className="!flex no-scrollbar w-full overflow-y-auto hidden flex-col items-start justify-start py-0 px-3 box-border gap-[12px] text-sm">
          <div
            className="self-stretch flex flex-row items-start justify-start gap-[8px] text-sm text-black-solid-black-880-1d1d26">
            <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[10px]">
              <div className="self-stretch flex flex-col items-start justify-center gap-[1px]">
                <div className="relative leading-[18px] mix-blend-normal">
                  {LL0().orderHistory.customizeAmountForRefund()}:
                </div>
                <Input
                  className="self-stretch font-mulish-button-name-bold text-base text-black-solid-black-880-1d1d26"
                  value={refundAmount().toString()}
                  onChange={(value) => setRefundAmount(customParseFloat(value))}
                />
              </div>
              <div
                className="self-stretch relative h-[59px] shrink-0 Tablet_600:self-stretch Tablet_600:w-auto Tablet_600:gap-[6px]">
                <div className="absolute top-[0px] left-[0px] leading-[18px] mix-blend-normal">
                  {LL0().inventory.reason()}:
                </div>
                <FormControl
                  className="absolute w-full top-[19px] right-[0%] left-[0%] font-mulish-button-name-bold text-sm text-black-solid-black-880-1d1d26"
                  variant="outlined"
                  sx={{
                    borderRadius: '0px 0px 0px 0px',
                    width: '100%',
                    height: '40px',
                    m: 0,
                    p: 0,
                    '& .MuiInputBase-root': {
                      m: 0,
                      p: '14px',
                      minHeight: '40px',
                      justifyContent: 'center',
                      display: 'inline-flex'
                    },
                    '& .MuiInputLabel-root': {
                      m: 0,
                      p: 0,
                      minHeight: '40px',
                      display: 'inline-flex'
                    },
                    '& .MuiMenuItem-root': {
                      m: 0,
                      p: 0,
                      height: '40px',
                      display: 'inline-flex'
                    },
                    '& .MuiSelect-select': {
                      m: 0,
                      p: 0,
                      height: '40px',
                      alignItems: 'center',
                      display: 'inline-flex'
                    },
                    '& .MuiInput-input': { m: 0, p: 0 },
                    '& .MuiInputBase-input': {
                      textAlign: 'left',
                      p: '0 !important'
                    }
                  }}
                >
                  <InputLabel color="primary" />
                  <Select
                    color="primary"
                    size="small"
                    displayEmpty
                    value={refundReason()}
                    onChange={e => {
                      userFLow("Reason in Order History", {
                        username: loginUser()?.name
                       })
                      setRefundReason(e.target.value)
                    }}
                  >
                    <MenuItem value="Fradulent charge">{LL0().orderHistory.fradulentCharge()}</MenuItem>
                    <MenuItem value="Cancelled order">{LL0().orderHistory.cancelledOrder()}</MenuItem>
                    <MenuItem value="Store's return policy">
                      {LL0().orderHistory.storesReturnPolicy()}
                    </MenuItem>
                    <MenuItem value="Returned goods">{LL0().orderHistory.returnedGoods()}</MenuItem>
                    <MenuItem value="Product doesn't meet requirements">
                      {LL0().orderHistory.productDoesntMeetRequirements()}
                    </MenuItem>
                    <MenuItem value="Not satisfied with the product">
                      {LL0().orderHistory.notSatisfiedWithTheProduct()}
                    </MenuItem>
                  </Select>
                  <FormHelperText />
                </FormControl>
              </div>
              <div className="self-stretch flex flex-col items-start justify-center gap-[1px]">
                <div className="relative leading-[18px] mix-blend-normal">
                  {LL0().orderHistory.otherReason()}:
                </div>
                {/*<TextField*/}
                {/*  className="[border:none] bg-[transparent] self-stretch font-mulish-button-name-bold text-sm text-gray-solid-gray-725-707070-100"*/}
                {/*  color="primary"*/}
                {/*  variant="outlined"*/}
                {/*  sx={{ "& .MuiInputBase-root": { height: "40px" } }}*/}
                {/*/>*/}
                <TextField
                  className="self-stretch font-mulish-button-name-bold text-sm text-gray-solid-gray-725-707070-100"
                  value={refundReason() || ''}
                  onChange={(e) => setRefundReason(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>}
        {!fullRefund() &&
          refundMode() === RefundMode.AMOUNT
          ? <KeyboardNumber valueInput={""} setValueKeyboard={setRefundAmount} />
          : <KeyboardNumber valueInput={customRefund()} index={focusIndex()} setValueKeyboard={setInputValue} />
        }
        <div
          className="self-stretch rounded-t-none rounded-b-lg bg-gray-solid-gray-166-ededee flex flex-row items-center justify-end py-1 px-3 text-sm text-black-solid-black-880-1d1d26">
          <div className="flex flex-row items-center justify-end gap-[8px]">
            <div ref={makeRipple} onClick={orderHistoryLogic.handleCancelRefund}
                 className="w-[120px] rounded-80xl box-border flex flex-row items-center justify-center py-2 px-3 border-[1px] border-solid border-black-solid-black-880-1d1d26">
              <b className="relative leading-[20px]">{LL0().ui.cancel()}</b>
            </div>
            <div ref={makeRipple}
                 className="w-[120px] rounded-80xl bg-gray-solid-gray-720-8d8d8d flex flex-row items-center justify-center py-2 px-3 box-border relative gap-[8px] cursor-pointer text-white-solid-white-100-ffffff"
                 onClick={openOrderHistoryRefundReason}
            >
              <div
                className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-80xl [background:linear-gradient(180deg,_#2196f3,_#1f8be0)] z-[0]" />
              <b className="relative leading-[20px] z-[1]">{LL0().posSetup.next()}</b>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrderHistoryRestaurantPOS
