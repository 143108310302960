import * as Comlink from 'comlink'
import { toast } from 'react-toastify'

import { signal } from '@/react/core/reactive.ts'
import dialogService from '@/react/SystemService/dialogService.tsx'
import SurrealClient from '@/shared/SurrealClient'
import { rnHost } from '@/shared/webview/rnwebview.ts'
import axios from "axios";
import { getApiUrl } from "@/shared/utils.ts";
import { getOsFromParam, getUrlParam } from "@/shared/utils2.ts";
import { updateFlow } from "@/shared/logger.ts";
import { isHanseatic } from "@/react/CompanyInformationView/companyInfomation.logic.ts";

const percentFormat = Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format
export const [openUpdateNowPopup, setOpenUpdateNowPopup] = signal<boolean>(false)
export const handleUpdate = async (mode: 'store' | 'codepush' | 's3' | 'electron', url: string = '') => {
  updateFlow(`handle update ${mode}, downloadUrl: ${url}`)
  setOpenUpdateNowPopup(false)
  let closeDialog = dialogService.progress({ title: 'Updating...' })
  const toastId = toast.loading('Updating...', { progress: 0, isLoading: true })
  try {
    const _mode = mode === 'electron' ? await getElectronUpdatePackageUrl() : mode;
    const success = await rnHost.updateNow(
      _mode,
      url,
      Comlink.proxy((p: number) => {
        updateFlow(`progress update ${p}...`)
        toast.update(toastId, {
          render: `Downloading update package... ${percentFormat(p / 100)}`,
          progress: p / 100,
        })
      })
    )
    if (!success) {
      updateFlow(`Failed to update ${mode}! `)
      toast.error('Failed to update!')
      return
    }
    toast.success('Update new version successful!')
  } catch (e) {
    console.error('Failed to update:', e)
    if (e instanceof Error) toast.error('Failed to update! ' + e.message)
  } finally {
    toast.dismiss(toastId)
    closeDialog()
  }
}

interface AppRelease {
  id: string
  name: string
  version: string
  releaseDate: number
  downloadUrl: string
}
async function getElectronUpdatePackageUrl() {
  const client = await SurrealClient.getSurrealClient('cloud')
  const [release] = await client.query(`SELECT * FROM ONLY app_releases ORDER BY releaseDate DESC LIMIT 1;`)
  if (!release) throw new Error('Fail to fetch latest version! Please try again.')
  console.log('» Query result:', release)
  const { downloadUrl } = release as AppRelease
  console.log('» Download url:', downloadUrl)
  return downloadUrl
}

// Testing only
Object.assign(window, { getElectronUpdatePackageUrl })

export const handleClickUpdate = async (mode: "store" | "codepush" | "s3" | "electron", version?: string, downloadUrl?: string) => {
  updateFlow(`handle update mode: ${mode} version: ${version} downloadUrl: ${downloadUrl}`)
  if (mode === 'codepush') {
    await handleUpdate(mode, '')
    return
  }
  try {
    if (!downloadUrl) {
      const response = await axios.post(
        `${getApiUrl()}/api/getUpdateUrl`,
        {
          mode,
          version,
          flavor: (isHanseatic && mode === 'store') ? getUrlParam('flavor') : ''
        }, {
          timeout: 20000
        }
      )
      updateFlow(`response check update: ${JSON.stringify(response.data)}`)
      if (response?.data?.downloadUrl) {
        downloadUrl = response.data?.downloadUrl
      }
    }
    updateFlow(`start update ${downloadUrl}`)
  } catch (e: any) {
    updateFlow(`error when getUpdateUrl ${e.message}`)
    console.error(e)
    throw new Error(`error when getUpdateUrl ${e.message}`)
  }
  if (!downloadUrl && (mode !== 'store' && getOsFromParam() !== 'ios')) {
    updateFlow(`Failed to update, can not get url download!`)
    return toast.error('Failed to update, can not get url download!')
  }
  await handleUpdate(mode, downloadUrl)
}

