import { FunctionComponent } from "react";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { PosScreen, router } from "@/pos/PosRouter.ts";

export type DashboardButtonsType = {
  className?: string;
  icon?: string;
  buttonName?: string;
  showChip?: boolean;
  chipValue?: string;
  showIconPopup?: boolean;
  onClick?: () => void;
};

const DashboardButtons: FunctionComponent<DashboardButtonsType> = ({
  className = "",
  icon,
  buttonName,
  showChip,
  chipValue,
  showIconPopup,
  onClick,
}) => {
  return (
    <div
      className={`shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] rounded [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.55))] flex flex-col items-center justify-center py-3 px-1 box-border relative gap-2 min-w-[100px] text-center text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      ref={makeRipple}
      onClick={onClick}
    >
      <img
        className="w-10 relative h-10 object-cover z-[0]"
        alt=""
        src={icon}
      />
      <b className="self-stretch relative flex items-center justify-center h-9 px-2 shrink-0 z-[1]">
        {buttonName}
      </b>
      {showIconPopup && (
        <img
          className="w-3 absolute !m-[0] top-[4px] right-[3.5px] h-3 object-cover z-[2]"
          alt=""
          src="/icondashboardpopup-icon-ver1@2x.png"
        />
      )}
      {showChip && (
        <div className="w-6 absolute !m-[0] top-[4px] right-[3.5px] h-6 z-[3] text-white-solid-white-100-ffffff">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-red-solid-red-320-e63950 w-6 h-6" />
          <b className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
            {chipValue}
          </b>
        </div>
      )}
      <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded border-yellow-solid-yellow-300-f6b400 border-[3px] border-solid box-border hidden z-[4]" />
    </div>
  );
};

export default DashboardButtons;
