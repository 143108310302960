import type {FunctionComponent} from "react";
import {useSignal} from "@/react/core/reactive.ts";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {clsx} from "clsx";
import { selectedUser } from "@/react/UserSettingView/UserSettingView";
import { UserRole } from "@/data/User";
import _ from "lodash";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

// TODO: enable i18n
const ROLE_MAPPER = {
  [UserRole.STAFF]: "staff",
  [UserRole.MANAGER]: "manager",
  [UserRole.ADMIN]: "admin"
}

const SetRole: FunctionComponent = () => {
  const [menuOpen, setMenuOpen] = useSignal<boolean>(false)
  return (
    <div className="flex flex-col items-end justify-start relative gap-[8px_0px] text-left text-base text-pink-solid-pink-600-ee0ba1 font-mulish">
      <div className="flex flex-row items-center justify-end gap-[0px_10px] z-[0]"
        onClick={()=> setMenuOpen(!menuOpen())}
        ref={makeRipple}
      >
        <b className="relative [text-decoration:underline] sm:text-smi">
          {_.get(LL0().monthlyReport,`${ROLE_MAPPER[selectedUser()?.role?? UserRole.STAFF]}`)?.()}
        </b>
        <img
          className="w-3.5 relative h-3.5 object-cover"
          alt=""
          src="/iconuser-settingpink-arrow@2x.png"
        />
      </div>
      {menuOpen() &&
        <div className="w-[140px] !m-[0] absolute right-[0.5px] bottom-[-102.5px] rounded-md [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.98),_rgba(232,_238,_241,_0.8)),_rgba(0,_0,_0,_0.6)] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] [backdrop-filter:blur(100px)] box-border flex flex-col items-center justify-center pt-2.5 px-2.5 pb-[11px] gap-[8px_0px] z-[1] text-center text-sm text-black-solid-black-880-1d1d26 border-[1px] border-solid border-blue-solid-blue-250-559ad1">
          <div className={clsx("self-stretch rounded-md h-5 flex flex-row items-center justify-center py-px px-0 box-border relative gap-[0px_10px]",
            selectedUser()?.role === UserRole.STAFF && 'bg-blue-solid-blue-9cc8f5'
          )}
               onClick={()=> {
                 userFLow(`set role staff`, {
                   username: loginUser()?.name
                 });
                 _.assign(selectedUser(), {role: UserRole.STAFF})
                 setMenuOpen(false)
               }}
               ref={makeRipple}
          >
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md hidden z-[0]" />
            <b className="flex-1 relative z-[1]">{LL0().monthlyReport.staff()}</b>
          </div>
            <div className={clsx("self-stretch rounded-md h-5 flex flex-row items-center justify-center py-px px-0 box-border relative gap-[0px_10px]",
              selectedUser()?.role === UserRole.MANAGER && 'bg-blue-solid-blue-9cc8f5'
            )}
               onClick={()=> {
                 userFLow(`set role manager`, {
                   username: loginUser()?.name
                 });
                 _.assign(selectedUser(), {role: UserRole.MANAGER})
                 setMenuOpen(false)
               }}
               ref={makeRipple}
          >
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md hidden z-[0]" />
            <b className="flex-1 relative z-[1]">{LL0().monthlyReport.manager()}</b>
          </div>
            <div className={clsx("self-stretch rounded-md h-5 flex flex-row items-center justify-center py-px px-0 box-border relative gap-[0px_10px]",
              selectedUser()?.role === UserRole.ADMIN && 'bg-blue-solid-blue-9cc8f5'
            )}
               onClick={()=> {
                 userFLow(`set role admin`, {
                   username: loginUser()?.name
                 });
                 _.assign(selectedUser(), {role: UserRole.ADMIN})
                 setMenuOpen(false)
               }}
               ref={makeRipple}
          >
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-md hidden z-[0]" />
            <b className="flex-1 relative z-[1]">{LL0().monthlyReport.admin()}</b>
          </div>
        </div>
      }
    </div>
  );
};

export default SetRole;
