import { type FunctionComponent, useMemo } from "react";
import { monthlyReport } from '@/react/MonthlyReportView/MonthlyReportView.tsx';
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { countReportTotal } from "@/pos/logic/report-utils.ts";

const MonthlyReportStatiscal: FunctionComponent = () => {
  const takeAwayGross = useMemo(() => {
    const takeAwayReport = monthlyReport()?.takeAwayReport;
    return takeAwayReport ? countReportTotal(takeAwayReport).gross : 0;
  }, [monthlyReport()]);
  const dineInGross = useMemo(() => {
    const dineInReport = monthlyReport()?.dineInReport;
    return dineInReport ? countReportTotal(dineInReport).gross : 0;
  }, [monthlyReport()]);

  return (
    <div className="self-stretch flex flex-col items-center justify-start gap-[8px] text-left text-sm text-gray font-mulish">
      <div className="self-stretch h-7 flex flex-col items-start justify-between text-base text-blue-solid-blue-500-0051c1">
        <b className="self-stretch relative uppercase md:text-mini sm:text-sm Tablet_768:text-sm Tablet_600:text-sm">
          {LL0().monthlyReport.statistical()}
        </b>
        <div className="self-stretch relative box-border h-px border-t-[1px] border-dashed border-black-solid-black-1000-000000" />
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.dineInQuantity()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {monthlyReport()?.dineInQuantity}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.takeAwayQuantity()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {monthlyReport()?.takeAwayQuantity}
        </div>
      </div>
      {/*<div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">*/}
      {/*  <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">*/}
      {/*    {LL0().report.totalOrders()}:*/}
      {/*  </div>*/}
      {/*  <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">*/}
      {/*    /!*{LL3().format.currency(monthlyReport()?.avgItemInOrder)}*!/*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.avgItemPrice()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.avgItemPrice || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.avgItemsPerOrder()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.avgItemInOrder || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.avgOrderPrice()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.avgOrderPrice || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.dineInSales()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(dineInGross || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.avgDineInSale()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.avgDineInSale || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.takeAwaySales()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(takeAwayGross || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.avgTakeAwaySale()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.avgTakeAwaySale || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.vouchersSold()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.voucherReport?.soldTotal || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
        <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
          {LL0().report.vouchersUsed()}:
        </div>
        <div className="relative text-right Tablet_768:text-smi Tablet_600:text-xs">
          {LL3().format.currency(monthlyReport()?.voucherReport?.redeemedTotal || 0)}
        </div>
      </div>
    </div>
  );
};

export default MonthlyReportStatiscal;
