import { type CSSProperties, type FunctionComponent, useMemo } from "react";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import * as React from "react";

type SeatTitleType = {
  seatNumber?: string;
  showMenuOpenIcon?: boolean;
  isSelected?: boolean;
  isPaid?: boolean;
  price?: number
  addItem?: (_seat?: string) => void;

  /** Style props */
  rectangleDivHeight?: CSSProperties["height"];
  seatNumberColor?: CSSProperties["color"];
};

const SeatTitle: FunctionComponent<SeatTitleType> = (
  {
    seatNumber = "Seat 1",
    isSelected = true,
    showMenuOpenIcon = true,
    isPaid,
    price,
    rectangleDivHeight,
    seatNumberColor,
    addItem,
  }) => {
  const recCoverStyle: CSSProperties = useMemo(() => {
    return {
      height: rectangleDivHeight,
    };
  }, [rectangleDivHeight]);

  const seatNumberStyle: CSSProperties = useMemo(() => {
    return {
      color: seatNumberColor,
    };
  }, [seatNumberColor]);

  return (
    <div
      className="rounded bg-blue-solid-blue-100-c9e2ff flex flex-row items-center justify-between p-3 box-border relative text-left text-base text-white-solid-white-100-ffffff font-mulish self-stretch height_414:pt-3 height_414:pb-3 height_414:box-border">
      {isSelected && (
        <div
          className="cover-div absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded bg-blue-solid-blue-350-2196f3"
          style={recCoverStyle}
        />
      )}
      <div className="flex-1 shrink-0 flex flex-row items-center justify-start gap-[8px]">
        {showMenuOpenIcon && (
          <img
            className="relative w-[19px] h-3 object-cover hidden"
            alt=""
            src="/order-split-menu-open-icon.svg"
          />
        )}
        <b
          className="first-b relative uppercase height_414:text-sm"
          style={seatNumberStyle}
        >
          {seatNumber}
        </b>
      </div>
      <div className="shrink-0 flex flex-row items-center justify-start gap-[10px] text-center text-lg text-orange-solid-orange-ffa216-700">
        <div className="rounded-8xs-6 h-[30px] flex flex-row items-center justify-center py-0 px-0.5 box-border gap-[2px]">
          {isPaid &&
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconseat-titletick-icon-3@2x.png"
            />
          }
          {!!price &&
            <div className="relative font-medium text-[#FFA216]">{LL3().format.currency(price)}</div>
          }
        </div>
        {addItem && (
          <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addItem(seatNumber?.match(/\d+/)?.[0])
          }} className="w-[100px] z-[2] mq432:w-[80px] text-xs text-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.10)] rounded-[6px] [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] h-8 flex flex-row items-center justify-center py-[4px] px-1 box-border relative gap-[4px] text-center text-sm mq432:h-[34px]">
            <img
              className="w-6 relative h-6 object-cover z-[0]"
              alt=""
              src="/icon-add-white.png"
            />
            <b className="relative leading-[14px]">Add Item</b>
            <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-981xl bg-darkslategray hidden z-[3]" />
          </div>
        )}
        <img
          className="up-arrow relative w-6 h-6 object-contain"
          alt=""
          src="/iconblue-colapse-arrow-down@2x.png"
        />
      </div>
    </div>
  );
};

export default SeatTitle;
