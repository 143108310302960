import { type FunctionComponent, useState } from 'react';
import { loginUsers } from '@/data/UserSignal.computed'
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { onBack } from '@/react/FloorPlanView/FloorPlanView.tsx';
import _ from 'lodash';
import dayjs from 'dayjs';
import { clockedInMap, lastAction, onClockIn, onClockOut } from "@/react/PasscodeView/PasscodeView.tsx";
import { clsx } from "clsx";
import { computed } from '@/react/core/reactive';
import CurrentTime, {currentHourUnix} from "@dashboard/CurrentTime.tsx";
import { shouldWarnBecauseOffline } from "@/data/ReplicateEffect.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { isChangeBg } from "@/react/Developer/RegisterStore.tsx";


const HOUR_TIME_REFRESH_INTERVAL = 30 * 1000;

const UserInfo: FunctionComponent = () => {
  const [showBackground, setShowBackground] = useState<boolean>(false);
  // implement logic to rerender minuteTime every HOUR_TIME_REFRESH_INTERVAL milliseconds
  // HOUR_TIME_REFRESH_INTERVAL can be reduced when we need more accurate time (e.g. 15 * 1000 for 15 seconds interval)
  const clockDuration = computed(() => {
    const timeDiff = dayjs.unix(currentHourUnix()).diff(dayjs.unix(lastAction()?.clockInTime || 0), 'h', true);
    return timeDiff > 0 ? timeDiff : 0.01
  })

  return (
    <div className={clsx("self-stretch flex flex-row items-center justify-between p-2 text-left text-base text-gray-200 font-mulish",
      (import.meta.env.MODE === 'production' && isChangeBg()) && 'bg-red-500'
      )}>
      <div className="flex-1 flex flex-row items-center justify-start gap-[8px]">
        <img
          className="w-10 relative rounded-981xl h-10 Tablet_768:w-9 Tablet_768:h-9 Tablet_600:w-8 Tablet_600:h-8 Mobile_480:w-[26px] Mobile_480:h-[26px]"
          style={{
            opacity: shouldWarnBecauseOffline() ? 0.5 : 1
          }}
          alt=""
          src={loginUser()?.avatar ?? '/hansel.svg'}
        />
        <div className="flex-1 flex flex-col items-start justify-start gap-[1px]">
          <div className="self-stretch relative font-semibold Tablet_768:text-sm Tablet_600:text-smi Mobile_480:text-xs">
            {_.startCase(loginUsers()?.[0])}
          </div>
          {/*/!*TODO: Might need to implement real-time timer here*!/*/}
          {/*<div className="self-stretch relative text-sm Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">*/}
          {/*  {currentHourTime}*/}
          {/*</div>*/}
          <CurrentTime />
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[6px] text-center text-sm text-basic-color-white">
        { !clockedInMap()[loginUsers()[0]] ?
        <div className="rounded-81xl bg-royalblue-200 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border relative gap-[3px]"
             onClick={() => onClockIn(loginUser()?.name || "")}
        >
          <img
            className="w-[30px] relative h-[30px] object-cover z-[0] Tablet_768:w-[26px] Tablet_768:h-[26px] Tablet_600:w-[22px] Tablet_600:h-[22px] Mobile_480:w-[18px] Mobile_480:h-[18px]"
            alt=""
            src="/dashboard-clock-icon@2x.png"
          />
          <div className="absolute my-0 mx-[!important] top-[calc(50%_-_25.2px)] left-[calc(50%_-_25.2px)] rounded-[50%] [background:linear-gradient(180deg,_rgba(255,_131,_102,_0.85),_rgba(228,_78,_44,_0.85))] box-border w-[51.5px] h-[51.5px] opacity-[0.5] z-[1] border-[0.5px] border-solid border-red-100" />
          <div className="!bg-[rgba(151,151,151,0.95)] absolute my-0 mx-[!important] top-[calc(50%_-_0px)] left-[calc(50%_-_1px)] rounded-[50%] bg-red-200 w-[3px] h-[3px] z-[2]" />
        </div>
          :
        <div className="!flex rounded-81xl bg-blue-solid-blue-450-1271ff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden hidden flex-row items-center justify-center py-0.5 px-2.5 box-border relative gap-[3px]"
             onClick={() => onClockOut(loginUser()?.name || "")}
        >
          <div className="absolute my-0 mx-[!important] h-[calc(100%_+_2px)] w-[calc(100%_+_2px)] top-[-1px] right-[-1px] bottom-[-1px] left-[-1px] rounded-81xl bg-gray-solid-gray-600-979797 box-border z-[0] border-[1px] border-solid border-gray-solid-gray-600-979797"
               style={{
                background: `conic-gradient(#FF8266D9 ${_.round(_.ceil(clockDuration() / 2) / 12 * 100, 2)}%, transparent 0)`,
          }}
          />
          <img
            className="w-[30px] relative h-[30px] object-cover z-[1] Tablet_768:w-[26px] Tablet_768:h-[26px] Tablet_600:w-[22px] Tablet_600:h-[22px] Mobile_480:w-[18px] Mobile_480:h-[18px]"
            alt=""
            src="/dashboard-clock-icon@2x.png"
          />
        </div>
        }
        <div className="rounded-81xl bg-royalblue-200 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
             ref={makeRipple}
             onClick={onBack}
             data-testid='dashboard.logoutBtn'
        >
          <img
            className="w-[30px] relative h-[30px] object-cover Tablet_768:w-[26px] Tablet_768:h-[26px] Tablet_600:w-[22px] Tablet_600:h-[22px] Mobile_480:w-[18px] Mobile_480:h-[18px]"
            alt=""
            src="/iconback-icon@2x.png"
          />
          <b className="w-[45px] relative leading-[17px] hidden">Button</b>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
