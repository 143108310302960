import { type FunctionComponent, useState, useCallback } from "react";
import InventoryAddEditItemPopu, {
  EditInventoryMode,
  setEditInventoryMode
} from './Popups/EditItemPopup/InventoryAddEditItemPopu'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import InventoryAddEditCategory from "./Popups/InventoryAddEditCategory";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { router } from '@/pos/PosRouter.ts';
import { initInventoryItem, setInventory0 } from '@/react/InventoryView/InventoryView.tsx'
import { deepSignal } from '@/react/core/reactive.ts'
import { clone } from 'json-fn'
import {LL0} from "@/react/core/I18nService.tsx";
import { mainScreen } from "@/data/PosSettingsSignal.ts";

const InventoryTopNavbar: FunctionComponent = () => {
  const [isInventoryAddEditItemPopuOpen, setInventoryAddEditItemPopuOpen] =
    useState(false);
  const [isInventoryAddEditCategoryOpen, setInventoryAddEditCategoryOpen] =
    useState(false);

  const openInventoryAddEditItemPopu = useCallback(() => {
    setEditInventoryMode(EditInventoryMode.ADD);
    setInventory0(deepSignal(clone(initInventoryItem())));
    setInventoryAddEditItemPopuOpen(true);
  }, []);

  const closeInventoryAddEditItemPopu = useCallback(() => {
    setInventoryAddEditItemPopuOpen(false);
  }, []);

  const openInventoryAddEditCategory = useCallback(() => {
    setInventoryAddEditCategoryOpen(true);
  }, []);

  const closeInventoryAddEditCategory = useCallback(() => {
    setInventoryAddEditCategoryOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch bg-gray-opacity-gray-95-4d5663 h-[50px] flex flex-row items-center justify-end py-0 px-4 box-border text-center text-sm text-black-solid-black-900-1e1e23 font-mulish Tablet_768:h-[42px] Tablet_768:pl-3 Tablet_768:pr-3 Tablet_768:box-border Tablet_600:pl-2 Tablet_600:pr-2 Tablet_600:box-border">
        <div className="flex flex-row items-center justify-end gap-[6px] Tablet_768:gap-[4px] Tablet_600:gap-[2px]">
          <div
            className="rounded-xl [background:linear-gradient(180deg,_#c5f1ff,_#6fc3f2)] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] h-[34px] flex flex-row items-center justify-start py-0 px-2.5 box-border gap-[4px] cursor-pointer"
            onClick={openInventoryAddEditItemPopu}
            ref={makeRipple}
          >
            <img
              className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_768:hidden Tablet_768:w-5 Tablet_768:h-5"
              alt=""
              src="/iconplus-icon@2x.png"
            />
            <b className="relative sm:text-smi Tablet_768:text-xs">{LL0().inventory.addItem()}</b>
          </div>
          <div
            className="rounded-xl [background:linear-gradient(180deg,_#ffe79b,_#e3a400)] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] h-[34px] flex flex-row items-center justify-start py-0 px-2.5 box-border gap-[4px] cursor-pointer"
            onClick={openInventoryAddEditCategory}
            ref={makeRipple}
          >
            <img
              className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_768:hidden Tablet_768:w-5 Tablet_768:h-5"
              alt=""
              src="/iconplus-icon@2x.png"
            />
            <b className="relative sm:text-smi Tablet_768:text-xs">{LL0().inventory.filter.category()}</b>
          </div>
          <button className="opacity-40 rounded-81xl [background:linear-gradient(180deg,_#deffb1,_#85d31a)] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
          >
            <img
              className="relative w-[26px] h-[26px] object-cover Tablet_768:hidden Tablet_600:hidden"
              alt=""
              src="/iconemail-icon@2x.png"
            />
            <b className="relative sm:text-smi Tablet_768:text-xs">{LL0().posSetup.accountTab.email()}</b>
          </button>
          <div className="rounded-81xl bg-blue-solid-blue-450-1271ff-200 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border min-w-[72px]"
               ref={makeRipple}
               onClick={() => router.screen = mainScreen()}
          >
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
          </div>
        </div>
      </div>
      {isInventoryAddEditItemPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryAddEditItemPopu}
        >
          <InventoryAddEditItemPopu onClose={closeInventoryAddEditItemPopu} />
        </PortalPopup>
      )}
      {isInventoryAddEditCategoryOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryAddEditCategory}
        >
          <InventoryAddEditCategory onClose={closeInventoryAddEditCategory} />
        </PortalPopup>
      )}
    </>
  );
};

export default InventoryTopNavbar;
