import type { RxCollection, RxDatabase, RxDocument } from 'rxdb';
import { createCollection } from "@/data/utils/migration.ts";

export const IMAGE_COLLECTION_NAME = 'images'

export const VERSION = 4;

const imageSchema = {
  title: 'image schema',
  version: VERSION,
  primaryKey: "_id",
  type: "object",
  properties: {
    _id: { type: "string", maxLength: 24 },
    data: { type: "string" },
    type: { type: 'string', maxLength: 24 },
    printData: { type: 'string' },
    hash: { type: 'string', maxLength: 24 },
  },
  indexes: ['hash'],
}

export interface Image {
  _id?: string;
  data?: string | null;
  type?: ImageType;
  printData?: string;
  hash?: string
}

export enum ImageType {
  SecondDisplayBackground = 'SecondDisplayBackground',
  SecondDisplayLogo = 'SecondDisplayLogo',
  KioskLogo = 'KioskLogo',
  KioskCoverPhoto = 'KioskCoverPhoto',
  KioskBannerPhoto = 'KioskBannerPhoto',
  ProductImage = 'ProductImage',
  PrintCache = 'PrintCache',
}


export type ImageCollection = RxCollection<Image>;
export type ImageDocument = RxDocument<Image>

export const ImageCollection: ImageCollection = {} as ImageCollection;

export const Image = ImageCollection;

// @ts-ignore
window.Image = Image;

export const createImageCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Image,
    collectionName: IMAGE_COLLECTION_NAME,
    version: VERSION,
    schema: imageSchema,
  });
};

