import _ from "lodash";
import { type FunctionComponent, useState, useCallback, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import type {TOpenHour} from "@/data/OpenHour.ts";
import {removeOpenHour, updateOpenHours} from '@/data/OpenHourHub.ts';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { companyInfo0 } from "@/data/PosSettingsSignal.ts";

const SetHourItem: FunctionComponent<{oh: TOpenHour}> = ({
  oh
}) => {
  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const fmt = 'HH:mm'

  const [openTime, setOpenTime] = useState(dayjs(oh.openTime, fmt));
  const [closeTime, setCloseTime] = useState(dayjs(oh.closeTime, fmt));
  const [deliveryStart, setDeliveryStart] = useState(dayjs(oh.deliveryStart, fmt));
  const [deliveryEnd, setDeliveryEnd] = useState(dayjs(oh.deliveryEnd, fmt));

  useEffect(() => setOpenTime(dayjs(oh.openTime, fmt)), [oh.openTime]);
  useEffect(() => setCloseTime(dayjs(oh.closeTime, fmt)), [oh.closeTime]);
  useEffect(() => setDeliveryStart(dayjs(oh.deliveryStart, fmt)), [oh.deliveryStart]);
  useEffect(() => setDeliveryEnd(dayjs(oh.deliveryEnd, fmt)), [oh.deliveryEnd]);

  const openDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    setDeleteConfirmationPopupOpen(false);
  }, []);

  const deleteOpenHour = useCallback(async () => {
    closeDeleteConfirmationPopup();
    await removeOpenHour(oh);
  }, []);

  const toggle = async (index: any) => {
    console.log('toggle', index)
    const dayInWeeks = _.cloneDeep(oh.dayInWeeks) as boolean[]
    dayInWeeks[index] = !dayInWeeks[index]
    await updateOpenHours(oh, {dayInWeeks})
  }

  const updateOpenTime = async (v: any) => {
    await updateOpenHours(oh, {openTime: v.format(fmt)})
  }

  const updateCloseTime = async (v: any) => {
    await updateOpenHours(oh, {closeTime: v.format('HH:mm')})
  }

  const updateDeliveryStart = async (v: any) => {
    await updateOpenHours(oh, {deliveryStart: v.format('HH:mm')})
  }

  const updateDeliveryEnd = async (v: any) => {
    await updateOpenHours(oh, {deliveryEnd: v.format('HH:mm')})
  }

  return (
    <>
      <div
        className={`self-stretch rounded bg-gray-solid-gray-130-f4f4f4 overflow-hidden shrink-0 flex flex-row items-start justify-start text-left text-sm text-black-solid-black-1000-000000 font-mulish-button-name-bold border-[1px] border-solid border-gray-solid-gray-355-c6c6c6 Tablet_768:h-auto`}
      >
        <div className="flex-1 rounded flex flex-col items-start justify-start p-2 gap-[10px] Tablet_768:h-auto">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[5px_20px]">
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((d, index) => (
              <div
                className="flex flex-row items-center justify-start gap-[5px]"
                key={index}
              >
                <div className="w-6 relative h-6">
                  <FormControlLabel
                    className="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px]"
                    label=""
                    control={<Checkbox
                      color="primary"
                      checked={oh.dayInWeeks?.[index]}
                      onChange={() => toggle(index)}/>}
                  />
                </div>
                <div className="self-stretch w-20 relative font-semibold flex items-center shrink-0">
                  {_.get(LL0().settings.happyHour.daysOfWeek, d)?.()}
                </div>
              </div>
            ))}
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <div className="w-[100px] relative font-semibold flex items-center shrink-0">
                {LL0().onlineProdiverSetting.openHours()}:
              </div>
              <div className="flex flex-row items-start justify-start gap-[2px] text-green-solid-green-800-4e8700">
                <TimePicker ampm={companyInfo0()?.country !== 'de'} value={openTime} onChange={updateOpenTime}></TimePicker>
                <TimePicker ampm={companyInfo0()?.country !== 'de'} value={closeTime} onChange={updateCloseTime}></TimePicker>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[12px]">
              <div className="w-[100px] relative font-semibold flex items-center shrink-0">
                {LL0().onlineProdiverSetting.deliveryHours()}:
              </div>
              <div className="flex flex-row items-start justify-start gap-[2px] text-green-solid-green-800-4e8700">
                <TimePicker ampm={companyInfo0()?.country !== 'de'} value={dayjs(deliveryStart)} onChange={updateDeliveryStart}></TimePicker>
                <TimePicker ampm={companyInfo0()?.country !== 'de'} value={dayjs(deliveryEnd)} onChange={updateDeliveryEnd}></TimePicker>
              </div>
            </div>
          </LocalizationProvider>
        </div>
        <div
          className="self-stretch bg-gray-solid-gray-164-e1e1e1 flex flex-row items-center justify-center p-2 cursor-pointer"
          onClick={openDeleteConfirmationPopup}
        >
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconbig-red-bin-icon@2x.png"
          />
        </div>
      </div>
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onConfirm={deleteOpenHour} />
        </PortalPopup>
      )}
    </>
  );
};

export default SetHourItem;
