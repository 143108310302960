import { type FunctionComponent, useMemo, type CSSProperties } from "react";
import { batch, useComputed } from "@/react/core/reactive.ts";
import {
  categories0,
  isSelected,
  setCategory0,
  setCurrentCategory
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { Category } from "@/data/Category.ts";
import { ITEM_MODE, setEditorMode0 } from "@/react/EditMenuView/EditMenuView.tsx";

export type OrderScreenCategoryItemType = {
  className?: string;
  showIsSelected?: boolean;
  label?: string;
  categoryId?: string;
  category?:Category;
  /** Style props */
  backgroundColor?: CSSProperties["backgroundColor"];
};

const OrderScreenCategoryItem: FunctionComponent<
  OrderScreenCategoryItemType
> = ({ className = "", backgroundColor, showIsSelected, label, categoryId,onSelectCategory, category }) => {
  const isDefaultStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: backgroundColor,
    };
  }, [backgroundColor]);

  const category1 = useComputed(() => {
    let found = categories0()?.find((c) =>
      c.get()?.layout?.top === category?.layout?.top && c.get()?.layout?.left === category?.layout?.left);
    return found?.get();
  });
  const index = useComputed(() => categories0()?.findIndex(_category => _category?.get()?._id === categoryId) as number);
  const active = isSelected(index());

  return (
    <div
      className={`h-10 shadow-[0px_1px_4px_rgba(0,_0,_0,_0.25)] rounded flex flex-row items-center justify-center py-0.5 px-1 relative gap-0.5 text-center text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      ref={makeRipple}
      onClick={()=>{
        batch(() => {
          setCurrentCategory(index());
          setCategory0(category1() as Category);
          setEditorMode0(ITEM_MODE.CATEGORY);
        });
      }}
    >
      <div
        className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded z-[0]"
        style={isDefaultStyle}
      />
      {active && (
        <div className="w-full h-full absolute !m-[0] inset-0 rounded !bg-transparent [background:linear-gradient(175.2deg,_rgba(255,_255,_255,_0.12)_26.49%,_rgba(0,_0,_0,_0.12))] border-pink-solid-pink-600-ee0ba1 border-[2px] border-solid box-border z-[1]" />
      )}
      <div className="flex-1 relative font-semibold [display:-webkit-inline-box] overflow-hidden text-ellipsis line-clamp-2 [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
        {label}
      </div>
    </div>
  );
};

export default OrderScreenCategoryItem;
