import PortalPopup from "@/react/core/PortalPopup.tsx";
import { Button } from "@/components/ui/button.tsx";
import React from "react";
import {
  handleClickUpdate,
  openUpdateNowPopup,
  setOpenUpdateNowPopup
} from "@/react/Developer/UpdateNowPopup.logic.ts";

const UpdateNowPopup = () => {
  return (
    <>
      {openUpdateNowPopup() &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setOpenUpdateNowPopup(false)}
        >
          <div
            className="relative rounded-10xs bg-white flex flex-col justify-center items-center pt-[19px] px-[32px] pb-[26px] box-border gap-[16px] w-full max-w-full min-w-[200px] max-h-full">
            <Button
              variant={"default"}
              size={"sm"}
              className={"h-[30px] w-fit float-right"}
              onClick={()=>{
                handleClickUpdate('codepush')
              }}
            >
              Update core
            </Button>
            <Button
              variant={"default"}
              size={"sm"}
              className={"h-[30px] w-fit float-right"}
              onClick={()=>{
                handleClickUpdate('store')
              }}
            >
              Update APK
            </Button>
            <Button
              variant={"default"}
              size={"sm"}
              className={"h-[30px] w-fit float-right"}
              onClick={()=>{
                handleClickUpdate('s3')
              }}
            >
              Update S3
            </Button>
            <Button
              variant={"default"}
              size={"sm"}
              className={"h-[30px] w-fit float-right"}
              onClick={()=>{
                handleClickUpdate('electron')
              }}
            >
              Update Electron
            </Button>
          </div>
        </PortalPopup>}
    </>

  )
}

export default UpdateNowPopup;