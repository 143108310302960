import PaymentProcessingPopUp from '@payment/PaymentProcessingPopUp.tsx'
import { isMasterMachineNotTurnedOn, setIsMasterMachineNotTurnedOn } from '@payment/PaymentScreenPlugin.tsx'
import PaymentScreenTurnOnMasterDev from '@payment/PaymentScreenTurnOnMasterDev.tsx'
import PortalPopup from '@payment/PortalPopup.tsx'
import PaymentScreenPlugin from '@vertical-payment2/PaymentScreenPlugin.tsx'
import PaymentVerticalPlugin from '@vertical-payment2/PaymentVerticalPlugin.tsx'
import { log } from 'debug'
import delay from 'delay'
import _ from 'lodash'
import { memo } from 'react'
import { toast } from 'react-toastify'

import { Order } from '@/data/Order.ts'
import { removeOrder } from '@/data/OrderHub.ts'
import { makePaymentsAvailable } from '@/data/PaymentHub.ts'
import { isQuebecSrmEnabled, mainScreenVertical, posSetting0 } from '@/data/PosSettingsSignal.ts'
import { makeTerminalsAvailable } from '@/data/TerminalHub.ts'
import { OrderStatus, type OrderStrip } from '@/pos/OrderType.ts'
import { onEnter, params, PosScreen, router } from '@/pos/PosRouter.ts'
import { computed, signal } from '@/react/core/reactive.ts'
import { useOrderEditLocking } from '@/react/hooks/useOrderEditLocking.ts'
import { orderMode0 } from '@/react/OrderView/OrderView.tsx'
import { OrderMode } from '@/react/OrderView/OrderViewShare.ts'
import {
  blockMutateOrderButton,
  callbackPayment,
  dialogProcessTerminalPaymentState,
  hasPaidSeatOrder,
  order0,
  paymentContext0,
  PaymentScreenMode,
  scopeOrder0,
  setMultiPayment0,
  setPaymentScreenMode,
  updateValue0,
} from '@/react/PaymentView/PaymentView.tsx'
import { PrintStack } from '@/react/PaymentView/PrintStack.ts'
import { srmTransactionLogic } from '@/srm/transaction.logic.ts'

import { ItemFactoryContext } from '../PaymentView/OrderItemsFactory2'

export const [printPaidBillTimes, setPrintPaidBillTimes] = signal<number>(0);

export const [isPrintMultipleSeat, setIsPrintMultipleSeat] = signal<boolean>(false);

export const [openSplitBillPaymentV, setOpenSplitBillPaymentV] = signal(false)
// export const [order0, setOrder0] = signal<TOrder>(createOrder())

// export const paymentContext1 = useItemFactory(order0, setOrder0, () => null)

export const [isViewBill, setIsViewBill] = signal<boolean>(false);

export const [isPayment, setIsPayment] = signal<boolean>(true);

export const [isTip, setIsTip] = signal<boolean>(false);

export const [isAddCustomerPlugin, setIsAddCustomerPlugin] = signal<boolean>(false);

// export const isDisableBack = computed(() => {
//   if (!order0()) return false;
//   if (order0().printInvoice) return true;
//   if (order0()?.seatMode) {
//     const isAnySeatPrint = order0()?.seatMap?.filter(o => o.printInvoice && o.status === OrderStatus.IN_PROGRESS);
//     if (isAnySeatPrint && isAnySeatPrint?.length > 0) return true
//   }
//   return false
// })

export const isAllowPrintInvoice = computed(() => {
  if (!scopeOrder0()) return;
  const paymentValue = _.sumBy(scopeOrder0().payments, 'value')
  console.log('paymentValue', paymentValue);
  if (paymentValue < scopeOrder0().vTotal) return false
  if (paymentValue >= scopeOrder0().vTotal) return true;
  return false
})

export function checkAmountPayment(order: Order | OrderStrip) {
  const paymentValue = _.sumBy(order.payments, 'value')
  if (paymentValue >= order.vTotal) return true;
  return false
}



export function verticalBack() {
  //check if in PaymentScreen, used for Order History simulate payment change case
  if (router.screen !== PosScreen.VERTICAL_PAYMENT) return

  if (orderMode0() === OrderMode.FAST_CHECKOUT) {
    router.screen = PosScreen.VERTICAL_ORDER
    return
  }
  //fixme: change mainScreen
  router.screen = mainScreenVertical()
}
export const [isBackAndSavePopupOpen, setBackAndSavePopupOpen] = signal<boolean>(false);


export const onClickBack = async () => {
  if (blockMutateOrderButton() || hasPaidSeatOrder())
    return toast('You have already printed the invoice!', {
      type: 'info',
      autoClose: 500,
    })
  setBackAndSavePopupOpen(true);
}

export async function onVerticalBack() {
  if (callbackPayment.fn) {
    router.screen = PosScreen.VERTICAL_PENDING_ORDERS
    return
  }
  router.screen = PosScreen.VERTICAL_ORDER

  if (params()?.orderMode === OrderMode.FAST_CHECKOUT || !posSetting0()?.generalSetting?.hasTablePlan) {
    const _order = order0()
    log('⚡️ Payment cancelling detected')
    if (isQuebecSrmEnabled()) {
      // Record cancelled Transaction
      // TODO: check if cancellation has item nor not
      await srmTransactionLogic.recordCancellation(_order, { makingPayment: true })
    }
    // _order.commits?.push({ action: CommitAction.CANCEL_BEFORE_PRINT, })
    // await updateOrder(_order, true);
    //fixme: handle cancel order immediately
    await removeOrder(_order)
  }
  setMultiPayment0(false)
}

const VerticalPayment2View = () => {
  //<editor-fold desc="onEnter">
  onEnter(PosScreen.VERTICAL_PAYMENT, async () => {
    // let order1 = createOrder(demoOnlineorder0);
    // const _order = _.cloneDeep(order1);
    // const _order = clone(stripOrder(_order0()));
    // let order1 = createOrder(_order);
    // console.log('after create order', order1);
    // setOrder0(order1);
    // onPaymentClick({name: 'cash'} as Payment);
    setPaymentScreenMode(PaymentScreenMode.PAYMENT)
    await delay(100)
    // await printBody0();
    PrintStack._id = order0()._id
    if (order0().seatMode) {
      paymentContext0.setSeat(order0().seatMap!.findIndex(o => o.status !== OrderStatus.PAID))
    }
    if (order0()?.reactive) {
      updateValue0()
    }
  })
  //</editor-fold>
  makePaymentsAvailable()
  makeTerminalsAvailable()
  useOrderEditLocking(order0()._id, PosScreen.VERTICAL_PAYMENT)

  return (
    <ItemFactoryContext.Provider value={{ ...paymentContext0, isPayment: isPayment() }}>
       <div className="flex-1 overflow-x-hidden no-scrollbar">
        {isPayment() ? <PaymentVerticalPlugin /> : <PaymentScreenPlugin />}
        {/*{paymentScreenMode() === PaymentScreenMode.PAYMENT ? <PaymentScreenPlugin /> : <>{paymentContext0.splitEnable() ? <SplitBillVerticalPlugin /> : <PaymentVerticalPlugin />}</>}*/}
      </div>
      {dialogProcessTerminalPaymentState().show && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <PaymentProcessingPopUp />
        </PortalPopup>
      )}
      {isMasterMachineNotTurnedOn() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsMasterMachineNotTurnedOn(false)}
        >
          <PaymentScreenTurnOnMasterDev onClose={() => setIsMasterMachineNotTurnedOn(false)} />
        </PortalPopup>
      )}
    </ItemFactoryContext.Provider>
  )
}

export default memo(VerticalPayment2View)