import type { FunctionComponent } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { order0 } from "@/react/PaymentView/PaymentView.tsx";

export type CancelBillReasonConfirmType = {
  className?: string;
  onClose?: () => void;
  onConfirm?: () => void;
};

const CancelBillReasonConfirm: FunctionComponent<
  CancelBillReasonConfirmType
> = ({ className = "", onClose, onConfirm }) => {

  const [openReason, setOpenReason] = useSignal<boolean>(false)
  const [cancelReason, setCancelReason] = useSignal<string>('')

  return (
    <div
      className={`w-full h-full relative rounded bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-6 px-3 pb-4 box-border gap-[10px] max-w-full max-h-full overflow-auto text-center text-base text-red-solid-red-550-cf2222 font-mulish ${className}`}
    >
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[12px] z-[1]">
        <b className="self-stretch relative">{LL0().order.receipt.cancelBill()}</b>
        <div
          className="self-stretch flex flex-col items-start justify-start relative gap-[4px] text-left text-sm text-black-solid-black-880-1d1d26">
          <div className="self-stretch relative leading-[18px] mix-blend-normal z-[0]">
            {LL0().order.receipt.cancelReason()}:
          </div>

          <Input
            className="w-full"
            value={cancelReason()}
            onChange={value => setCancelReason(value)}
            onFocus={() => setOpenReason(v => !v)}
          />

          {(openReason() && !cancelReason()) && <div
            className="w-full !m-[0] absolute top-[64px] right-[0px] left-[0px] shadow-[0px_4px_12px_rgba(0,_0,_0,_0.08)] rounded bg-white-solid-white-100-ffffff box-border flex flex-col items-start justify-start p-1 z-[2] text-black-solid-black-900-1e1e23 border-[1px] border-solid border-gray-solid-gray-320-d2d2d2">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div
                className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 relative gap-[4px] border-b-[1px] border-solid border-gray-solid-gray-320-d2d2d2"
                ref={makeRipple}
                onClick={() => setCancelReason(LL0().order.receipt.theCustomerForgotToPay())}
              >
                <div
                  className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-solid-blue-30-e3f2fd hidden z-[0]"/>
                <div className="relative font-semibold z-[1]">
                  {LL0().order.receipt.theCustomerForgotToPay()}
                </div>
              </div>
              <div
                className="self-stretch bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-2 relative gap-[4px] border-b-[1px] border-solid border-gray-solid-gray-320-d2d2d2"
                ref={makeRipple}
                onClick={() => setCancelReason(LL0().order.receipt.freeMealOrderForThisGuest())}
              >
                <div
                  className="!hidden w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-blue-solid-blue-30-e3f2fd z-[0]"/>
                <div className="relative font-semibold z-[1]">
                  {LL0().order.receipt.freeMealOrderForThisGuest()}
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <TableManualKeyboard
        inputController={inputController0}
      />
      <div
        className="self-stretch flex flex-row items-start justify-start gap-[8px] z-[0] text-sm text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff box-border h-9 flex flex-row items-center justify-center py-1 px-2.5 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
          ref={makeRipple}
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff h-9 flex flex-row items-center justify-center py-1 px-2.5 box-border text-white-solid-white-100-ffffff"
          ref={makeRipple}
          onClick={() => {
            userFLow(`cancel bill reason in payment`, {
              username: loginUser()?.name,
              orderId: order0()._id
            })
            onClose?.()
            onConfirm?.()
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default CancelBillReasonConfirm;