import type { FunctionComponent } from "react";
import InputPasscode from "./InputPasscode";
import { computed, signal } from '@/react/core/reactive.ts';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { login, openSRMCertificateWarningPopup, setOpenSRMCertificateWarningPopup } from '@/data/UserHub.ts';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { toast } from 'react-toastify';
import { LL0 } from "@/react/core/I18nService.tsx";
import { autoLoginShortcut } from "@/react/utils/shortcut-init.ts";
import { clockedInMap, onClockIn } from "@/react/PasscodeView/PasscodeView.tsx";
import { mainScreen } from "@/data/PosSettingsSignal.ts";
import { loginUser } from "@/data/UserSignal.ts";
import SRMCertificateWarningPopup from "@passcode/SRMCertificateWarningPopup.tsx";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import DeveloperOnlyVerifyPopu from "@dashboard/DeveloperOnlyVerifyPopu.tsx";
import { INSTALLATION_PASSWORD } from "@dashboard/DashboardSettingPopup.tsx";
import { userFLow } from "@/shared/logger.ts";

export const [password, setPassword] = signal<string>('');
const [openInstallationVerifyPopup, setOpenInstallationVerifyPopup] = signal<boolean>(false)
export const displayPassword = computed(() => {
  if (password().length === 0) {
    return '****';
  } else {
    return password().split('').fill('*').join('');
  }
});

function add(s: string) {
  if (s === '<-') {
    if (password().length !== 0) {
      setPassword(password().slice(0, password().length - 1));
    }
  } else if (s === '<>') {
    setPassword('');
  } else {
    setPassword((prev) => prev + s);
  }
}

//Just for 1-9 since they have the same logic, the rest are manually implemented below
const keyboardMap = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
];

const PasscodeRight: FunctionComponent = () => {
  const handleLogin = async () => {
    const result = await login(password());
    if (!result) {
      toast(`${LL0().timeClock.clockOutPassCodeDialogContent.incorrectPassword()}!`, { type: 'error' });
      return;
    }
    setPassword('');
    router.screen = mainScreen();
  }

  return (
    <>
      <div className="self-stretch flex-1 flex flex-col items-center justify-start gap-[20px] text-center text-3xl text-white-solid-white-100-ffffff font-mulish">
        <b className="self-stretch h-7 relative inline-block sm:text-2xl sm:hidden mq768:text-lg mq768:hidden mq600:hidden mq480:hidden height_414:hidden height_598:hidden">
          {LL0().login.password().toUpperCase()}
        </b>
        <div className="self-stretch flex-1 rounded bg-blue-opacity-blue-15-f3f9ff [backdrop-filter:blur(30px)] flex flex-col items-center justify-start pt-6 px-[30px] pb-5 box-border gap-[20px] max-h-[540px] text-left text-9xl lg:pl-7 lg:pr-7 lg:box-border lg:max-h-[440px] md:pl-6 md:pr-6 md:box-border md:max-h-[440px] sm:pl-6 sm:pr-6 sm:box-border sm:max-h-[440px] mq1440:max-h-[540px] mq768:pl-5 mq768:pr-5 mq768:box-border mq768:max-h-[440px] mq600:pl-4 mq600:pr-4 mq600:box-border mq600:max-h-[440px] mq480:pl-4 mq480:pr-4 mq480:box-border mq480:max-h-[440px] height_414:gap-[8px_20px] height_414:pt-4 height_414:pb-2.5 height_414:box-border height_340:gap-[8px_20px] height_340:pt-2.5 height_340:pb-2 height_340:box-border">
          <InputPasscode
            visibleCode={false}
            hideCode
            showVisibleIcon={false}
            hideIcon
          />
          <div className="w-full self-stretch h-full flex flex-col items-center justify-center">
            <div className="w-full flex-1 relative max-w-[480px] grid grid-rows-[repeat(4,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:8px] [grid-column-gap:9px] lg:grid lg:grid-rows-[repeat(4,1fr)] lg:grid-cols-[repeat(3,1fr)] lg:[grid-row-gap:8px] lg:[grid-column-gap:9px] lg:max-w-[420px] sm:grid sm:grid-rows-[repeat(4,1fr)] sm:grid-cols-[repeat(3,1fr)] sm:[grid-row-gap:7px] sm:[grid-column-gap:7px] mq768:grid mq768:grid-rows-[repeat(4,1fr)] mq768:grid-cols-[repeat(3,1fr)] mq768:[grid-row-gap:6px] mq768:[grid-column-gap:6px] mq600:grid mq600:grid-rows-[repeat(4,1fr)] mq600:grid-cols-[repeat(3,1fr)] mq600:[grid-row-gap:5px] mq600:[grid-column-gap:5px] height_414:grid height_414:grid-rows-[repeat(4,1fr)] height_414:grid-cols-[repeat(3,1fr)] height_414:[grid-row-gap:5px] height_414:[grid-column-gap:5px] height_340:grid height_340:grid-rows-[repeat(4,1fr)] height_340:grid-cols-[repeat(3,1fr)] height_340:[grid-row-gap:5px] height_340:[grid-column-gap:5px]">
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90   shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[1/1] row-[1/1] height_414:relative height_414:col-[1/1] height_414:row-[1/1]"
                   onClick={() => add('1'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input1'
              >
                <div className="!text-white relative height_414:text-5xl">1</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[2/2] row-[1/1]"
                   onClick={() => add('2'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input2'
              >
                <div className="!text-white relative height_414:text-5xl">2</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[3/3] row-[1/1]"
                   onClick={() => add('3'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input3'
              >
                <div className="!text-white relative height_414:text-5xl">3</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[1/1] row-[2/2]"
                   onClick={() => add('4'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input4'
              >
                <div className="!text-white relative height_414:text-5xl">4</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[2/2] row-[2/2]"
                   onClick={() => add('5'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input5'
              >
                <div className="!text-white relative height_414:text-5xl">5</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[3/3] row-[2/2]"
                   onClick={() => add('6'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input6'
              >
                <div className="!text-white relative height_414:text-5xl">6</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[1/1] row-[3/3]"
                   onClick={() => add('7'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input7'
              >
                <div className="!text-white relative height_414:text-5xl">7</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[2/2] row-[3/3]"
                   onClick={() => add('8'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input8'
              >
                <div className="!text-white relative height_414:text-5xl">8</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[3/3] row-[3/3]"
                   onClick={() => add('9'.toString())}
                   ref={makeRipple}
                   data-testid='passcode.input9'
              >
                <div className="!text-white relative height_414:text-5xl">9</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[1/1] row-[4/4]"
                   onClick={() => add('<>')}
                   ref={makeRipple}
                   data-testid='passcode.inputReset'
              >
                <img
                  className="w-[22.7px] relative h-[21.5px]"
                  alt=""
                  src="/iconpasscoderepeat-icon.svg"
                />
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[2/2] row-[4/4]"
                   onClick={() => add('0')}
                   ref={makeRipple}
                   data-testid='passcode.input0'
              >
                <div className="!text-white relative height_414:text-5xl">0</div>
              </div>
              <div className="rounded-9xs-4 bg-[#8b96ae] bg-opacity-90  shadow-[0px_0px_4px_rgba(255,_255,_255,_0.2),_-1.7px_-1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset,_1.7px_1.7px_1.7px_rgba(189,_189,_189,_0.2)_inset] [backdrop-filter:blur(30px)] flex flex-row items-center justify-center relative col-[3/3] row-[4/4]"
                   onClick={() => add('<-')}
                   ref={makeRipple}
                   data-testid='passcode.inputDel'
              >
                <img
                  className="w-[29.4px] relative h-[20.2px]"
                  alt=""
                  src="/iconpasscodedelete-icon.svg"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-center py-0 px-4 gap-[10px] text-center text-lg lg:flex-1 mq1440:gap-[10px_26px] mq768:h-auto mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:gap-[10px_8px] mq600:pl-1 mq600:pr-1 mq600:box-border height_414:pt-1 height_414:pb-1 height_414:box-border">
            <div className="h-11 flex-1 rounded-41xl [background:linear-gradient(170.9deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#005fed] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-[11px] px-2.5 box-border max-w-[180px] sm:w-full mq1440:h-14 mq1440:max-w-[180px] mq768:w-full mq768:h-auto height_340:h-auto height_340:pt-2.5 height_340:pb-2.5 height_340:box-border"
                 ref={makeRipple}
                 data-testid='passcode.buttonClockIn'
                 onClick={async () => {
                   await handleLogin();
                   userFLow(`Clock in passcode ${clockedInMap()[loginUser()?.name || ""]}`, {
                     username: loginUser()?.name
                   })
                   if (clockedInMap()[loginUser()?.name || ""]) return;
                   await onClockIn(loginUser()?.name || "")
                 }}
            >
              <div className="flex-1 relative font-semibold md:text-base mq1440:text-xl">
                {LL0().monthlyReport.clockIn()}
              </div>
            </div>
            <div className="h-11 flex-1 rounded-41xl [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-[11px] px-2.5 box-border max-w-[180px] sm:w-full mq1440:h-14 mq1440:gap-[10px_30px] mq768:w-full height_340:h-auto height_340:pt-2.5 height_340:pb-2.5 height_340:box-border"
                 onClick={handleLogin}
                 data-testid='passcode.buttonLogIn'
                 ref={makeRipple}
            >
              <div className="flex-1 relative font-semibold md:text-base mq1440:text-xl">
                {LL0().sidebar.logIn()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openSRMCertificateWarningPopup() &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={() => setOpenSRMCertificateWarningPopup(false)}
        >
          <SRMCertificateWarningPopup
            onClose={() => setOpenSRMCertificateWarningPopup(false)}
            onConfirm={() => {
              userFLow(`open SRMCertificateWarningPopup`, {
                username: loginUser()?.name
              })
              setOpenSRMCertificateWarningPopup(false)
              setOpenInstallationVerifyPopup(true)
            }}
          />
        </PortalPopup>
      }
      {openInstallationVerifyPopup() &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setOpenInstallationVerifyPopup(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setOpenInstallationVerifyPopup(false)}
            password={INSTALLATION_PASSWORD}
            onComplete={() => {
              userFLow(`open DeveloperOnlyVerifyPopu and next PosScreen.INSTALLATION`, {
                username: loginUser()?.name
              })
              setOpenInstallationVerifyPopup(false)
              router.screen = PosScreen.INSTALLATION;
            }}
          />
        </PortalPopup>
      }
    </>
  );
};

export default PasscodeRight;