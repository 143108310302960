import { Switch } from "@mui/material";
import _ from "lodash";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { setOnlineOrdersV } from "@/react/PendingOrder/PendingOrderLogic.tsx";

export const DeveloperDeviceFeaturesTab = () => {
  return (
    <>
      <div className="grid gap-2 items-center place-items-start">
        <label className="flex items-center gap-2">
          <Switch
            checked={!deviceSetting0()?.hidePendingOrders}
            onChange={(_e, checked) => {
              _.assign(deviceSetting0(), { hidePendingOrders: !checked })
              if (checked) {
                setOnlineOrdersV(v => v + 1)
              }
            }}
          />
          <p>Pending Order</p>
        </label>
      </div>
    </>
  )
}
export default DeveloperDeviceFeaturesTab;