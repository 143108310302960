import { dataLock } from "@/data/DataUtils.ts";
import { database, database2, database3 } from "@/data/data-utils.ts";

const intervalIds: { [key: string]: NodeJS.Timeout | null } = {};
const idleTimeCounters: { [key: string]: number } = {};

export const idleHandler = (
  timeout: number,
  purpose: string,
  func: () => void,
  options: { interval?: number; runOnce?: boolean } = {}
) => {
  const { interval = 5000, runOnce = true } = options
  if (!intervalIds[purpose]) {
    console.log(`start wait ${purpose}`)
    idleTimeCounters[purpose] = 0;

    document.onpointerdown = function () {
      Object.keys(idleTimeCounters).forEach(purpose => {
        idleTimeCounters[purpose] = 0;
      });
    };

    intervalIds[purpose] = setInterval(async () => {
      idleTimeCounters[purpose] += interval;
      if (idleTimeCounters[purpose] >= timeout) {
        idleTimeCounters[purpose] = 0;
        await dataLock.acquireAsync();
        await Promise.all([
          database.v?.requestIdlePromise(),
          database2.v?.requestIdlePromise(),
          database3.v?.requestIdlePromise(),
        ]);
        func?.();
        if (runOnce) {
          clearInterval(intervalIds[purpose]!);
          intervalIds[purpose] = null;
          console.log(`Interval for ${purpose} cleared`);
        }
      }
    }, interval);
  }
};


