import React, { type FunctionComponent } from "react";
import { kitchenGroupPrinters0, labelPrinters0 } from "@/data/GroupPrinterHub.ts";
import { LL0 } from '@/react/core/I18nService.tsx';
import TextField from "@/react/core/TextField.tsx";
import Collapsible from "@/react/core/Collapsible.tsx";
import { useSignal, useComputed } from "@/react/core/reactive";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { dineInTaxCategories0, takeAwayTaxCategories0, taxCategories0 } from "@/data/TaxCategoryHub.ts";
import clsx from "clsx";
import _ from "lodash";
import { orderLayout0 } from "@/react/OrderView/OrderView.tsx";
import { products0 } from "@/data/ProductHub.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type PopupMatchProductProps = {
  onClose?: () => void,
  onSave?: (data: any) => void,
  product: Partial<{ name: string }>
};

const PopupMatchProduct: FunctionComponent<
  PopupMatchProductProps
> = ({ onClose, onSave, product }) => {
  // data set
  const groupPrinters = [...kitchenGroupPrinters0(), labelPrinters0()];
  const iProduct = _.cloneDeep(product);

  const LL = LL0();

  const MODES = {
    NEW: 1,
    MAPPING: 2
  }
  const [mode, setMode] = useSignal(MODES.MAPPING);
  const isModeSelected = (v: any) => v === mode();

  const [productName, setProductName] = useSignal<string>(product?.name);
  const [printerGroups, setPrinterGroups] = useSignal<string[]>([])

  const togglePrinterGroup = (id: string) => {
    if (printerGroups().includes(id)) {
      setPrinterGroups(printerGroups().filter(_id => _id != id))
    } else {
      setPrinterGroups([...printerGroups(), id])
    }
  }
  const isPrinterGroupSelected = (_id: string) => printerGroups().includes(_id);

  const renderLeftColumn = () => (
    <div className="flex flex-col">
      <div className="cursor-pointer hover:bg-[#ddd] px-5 py-5"
           onClick={() => setMode(v => MODES.NEW)}
           style={{
             borderBottom: '1px solid #ddd',
             background: isModeSelected(MODES.NEW) ? '#ddd' : 'transparent',
           }}
      >
        Create new product
      </div>
      <div className="cursor-pointer hover:bg-[#ddd] px-5 py-5"
           onClick={() => setMode(v => MODES.MAPPING)}
           style={{
             borderBottom: '1px solid #ddd',
             background: isModeSelected(MODES.MAPPING) ? '#ddd' : 'transparent',
           }}
      >
        Map existing product
      </div>
    </div>
  )

  const getTaxCategoryValue = (taxCategory: any) => {
    console.log('getTaxCategoryValue', taxCategory);
    return taxCategory?.components?.reduce((accumulator: any, currentValue: any) => {
      return accumulator + (currentValue?.value || 0);
    }, 0) || taxCategory.value
  }

  const renderOneTax = () => (
    <div
      className="flex-1 flex flex-col items-start justify-start gap-[12px] sm:flex-[unset] sm:self-stretch">
      <b className="relative sm:text-sm Tablet_600:text-smi">{LL0().article.tax()}:</b>
      <div
        className="gap-[8px] self-stretch flex flex-row flex-wrap items-start justify-start text-black-solid-black-880-1d1d26">
        {taxCategories0().map((taxCategory, index) => (
          <div
            className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1')}
            key={index}
            onClick={() => {
              userFLow(`add tax category`, {
                username: loginUser()?.name
              });
              _.assign(iProduct, {
                tax: taxCategory.value,
                tax2: taxCategory.value,
                taxCategory: taxCategory.name,
                taxCategory2: taxCategory.name,
                taxComponents: taxCategory.components,
                taxComponents2: taxCategory.components
              })
            }}>
            {taxCategory?.name} {getTaxCategoryValue(taxCategory)}%
          </div>
        ))}
      </div>
    </div>
  )
  const renderTwoTax = () => (
    <div
      className="flex-1 flex flex-row items-start justify-start gap-[10px] sm:flex-[unset] sm:self-stretch">
      {dineInTaxCategories0().length > 0 &&
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
          <b className="relative sm:text-sm Tablet_600:text-smi">
            {LL0().editMenu.dineInTax()}:
          </b>
          <div
            className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
            {dineInTaxCategories0().map((taxCategory, index) => (
              <div
                className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1')}
                key={index}
                onClick={() => {
                  userFLow(`add dine tax`, {
                    username: loginUser()?.name
                  });
                  _.assign(iProduct, {
                    tax: taxCategory.value,
                    taxCategory: taxCategory.name,
                    taxComponents: taxCategory.components
                  })
                }}>
                {taxCategory.printLabel || `${taxCategory.value}%`}
              </div>
            ))}
          </div>
        </div>}
      {takeAwayTaxCategories0().length > 0 &&
        <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
          <b className="relative sm:text-sm Tablet_600:text-smi">
            {LL0().editMenu.takeAwayTax()}:
          </b>
          <div
            className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
            {takeAwayTaxCategories0().map((taxCategory, index) => (
              <div
                className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1')}
                key={index}
                onClick={() => {
                  userFLow(`add take away tax`, {
                    username: loginUser()?.name
                  });
                  _.assign(iProduct, {
                    tax2: taxCategory.value,
                    taxCategory2: taxCategory.name,
                    taxComponents2: taxCategory.components
                  })
                }}>
                {taxCategory.printLabel || `${taxCategory.value}%`}
              </div>
            ))}
          </div>
        </div>}
    </div>
  );
  const renderNewMode = () => (
    <div className="flex flex-col gap-[12px]">

      <p>Product name:</p>
      <TextField
        value={productName()}
        onChange={e => setProductName(e.target.value)} />

      <p>{LL.onlineOrder.pleaseSelectAtLeastOnePrinterGroup()}:</p>
      <div className="w-full flex flex-row flex-wrap gap-2">
        {groupPrinters.map(gp => <div
          key={gp._id}
          onClick={() => togglePrinterGroup(gp._id!)}>
          {
            isPrinterGroupSelected(gp._id!)
              ? <div className="bg-blue-500 text-white px-4 py-2 cursor-pointer rounded">{gp.name}</div>
              : <div className="bg-[#ddd] px-4 py-2 cursor-pointer rounded">{gp.name}</div>
          }
        </div>)}
      </div>

      <div className="w-full flex flex-row flex-wrap gap-2 mb-5">
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] sm:flex-col sm:gap-[16px]">
          {generalSetting0()?.taxType === 'one' && renderOneTax()}
          {generalSetting0()?.taxType === 'two' && renderTwoTax()}
        </div>
      </div>
    </div>
  )

  const productsMap = useComputed(() => {
    const map: Record<string, any> = {}
    for (const item of products0()) {
      map[item._id] = item;
    }
    return map;
  })
  const [selectedProduct, setProduct] = useSignal(null);
  const isProductSelected = (p: any) => p && p._id === selectedProduct();
  const [searchText, setSearchText] = useSignal('');
  const filteredCategoriesLayout = useComputed(() => {
    const categoryHasName = (c: any) => c.name;
    const categoryHasProduct = (c: any) => c.products && c.products.length > 0;
    const addRawProductField = (item: any) => Object.assign({}, item, {rawProduct: productsMap()[item.product]})
    const isNormalItem = (item: any) => item.type === 'Article'
    const findProductFromProductLayout = (item: any) => {
      return productsMap()[item.product];
    }
    const hasLinkedItem = (item: any) => {
      return !!findProductFromProductLayout(item)
    }
    const hasLinkedFilteredItem = (item: any) => {
      const txt = searchText();
      const p = findProductFromProductLayout(item);
      if (!p)
        return false;
      return p.name?.includes(txt) || p.id?.includes(txt);
    }
    let rs;
    if (_.isEmpty(searchText())) {
      rs = (
        orderLayout0().categories
          .filter(categoryHasName)
          .map(c => {
            const products = c?.products?.filter(isNormalItem).filter(hasLinkedItem).map(addRawProductField);
            return {
              ...c,
              products
            }
          })
          .filter(categoryHasProduct)
      )
    } else {
      rs = (
        orderLayout0().categories
          .filter(categoryHasName)
          .map(c => {
            const products = c?.products?.filter(isNormalItem).filter(hasLinkedFilteredItem).map(addRawProductField);
            return { ...c, products }
          })
          .filter(categoryHasProduct)
      )
    }

    return rs
  })
  const renderMappingMode = () => (
    <div className="flex flex-col gap-[10px]">
      <p>{LL.onlineOrder.pleaseSelectProductToMapWith()} <b>{productName()}</b></p>
      <TextField value={searchText()} onChange={e => setSearchText(e.target.value || '')}></TextField>
      <div className="max-h-[400px] overflow-y-auto">
        {
          filteredCategoriesLayout().map(category =>
            <Collapsible key={category.name} className="mb-[2px]" title={category.name}>
              {
                category?.products?.map(item =>
                  <div
                    className="py-2 px-2 cursor-pointer hover:bg-[#00000014]"
                    style={{
                      borderTop: '1px solid #ddd',
                      background: isProductSelected(item.rawProduct) ? '#1271ff' : 'transparent',
                      color: isProductSelected(item.rawProduct) ? '#fff' : '#000'
                    }}
                    key={item.rawProduct._id}
                    onClick={() => {
                      userFLow(`set product ${item.rawProduct._id}`, {
                        username: loginUser()?.name
                      });
                      setProduct(item.rawProduct._id)
                    }}
                  >
                    {item.rawProduct.id ? `${item.rawProduct.id}. ` : ''}{item.rawProduct.name}
                  </div>
                )
              }
            </Collapsible>
          )
        }
      </div>
    </div>
  )
  const renderRightColumn = () => (
    <div style={{ borderLeft: '1px solid #ddd' }} className="flex-1 px-5 py-5">
      {mode() === MODES.NEW ? renderNewMode() : renderMappingMode()}
    </div>
  )


  function submit() {
    if (mode() === MODES.NEW) {
      // insert product somewhere
      // setProduct({})
    }
    const submitData = { productId: selectedProduct() }
    userFLow(`submit ${submitData}`, {
      username: loginUser()?.name
    });
    console.log('submit', submitData)
    onSave && onSave(submitData);
  }

  const renderFooter = () => (
    <div className="w-full flex flex-row items-center justify-end gap-2 px-4 py-4"
         style={{ borderTop: '1px solid #ddd' }}>

      <div className="px-4 py-2 cursor-pointer rounded"
           onClick={onClose}>
        {LL.ui.close()}
      </div>

      <div className="bg-blue-500 px-7 py-2 text-white cursor-pointer rounded"
           onClick={() => {
             submit();
             onClose?.();
           }}>
        {LL.ui.save()}
      </div>
    </div>
  )

  return (
    <div
      className="relative rounded-2xl overflow-hidden bg-basic-color-white flex flex-col w-full box-border h-full max-w-full min-w-[600px] min-h-[460px] max-h-full text-left text-sm text-gray font-mulish"
      style={{ border: '1px solid #888' }}>
      <div className="flex flex-row flex-1">
        {/*{renderLeftColumn()}*/}
        {renderRightColumn()}
      </div>
      {renderFooter()}
    </div>
  );
};

export default PopupMatchProduct;
