import PortalPopup from "@/react/core/PortalPopup.tsx";
import { createTheme, Tab, Tabs, ThemeProvider } from '@mui/material'
import { memo, useMemo, useState } from 'react'

import { Button } from '@/components/ui/button.tsx'
import { companyInfo0, mainScreen } from '@/data/PosSettingsSignal.ts'
import { router } from '@/pos/PosRouter.ts'
import { processing } from '@/react/Developer/DataMenu.logic.ts'
import DeleteAllPopup from '@/react/Developer/DeleteAllPopup.tsx'
import { TabPanel } from '@/react/Developer/Developer.tsx'
import RegisterStore from '@/react/Developer/RegisterStore.tsx'
import UpdateNowPopup from '@/react/Developer/UpdateNowPopup.tsx'

import SecondDisplayConfig from '../Installation/SecondDisplayConfig'
import { InstallationBackupTab } from './InstallationBackupTab'
import { InstallationPairingCodeTab } from './InstallationPairingCodeTab'
import { InstallationSrmTab } from './InstallationSrmTab'
import DeveloperPosFeaturesTab from "@/react/Developer/DeveloperPosFeaturesTab.tsx";
import DeveloperDeviceFeaturesTab from "@/react/Developer/DeveloperDeviceFeaturesTab.tsx";
import OTSKiosk from "@/react/Developer/OTSKiosk.tsx";

const muiTheme = createTheme()

const Installation = () => {
  const [currentTab, setCurrentTab] = useState('0')
  const handleChangeTab = (_: unknown, newValue: string) => setCurrentTab(newValue)

  const tabs = useMemo(
    () => [
      { key: 'general', value: 'General', Comp: () => <RegisterStore type="Installation" /> },
      { key: 'secondDisplay', value: 'Second Display', Comp: () => <SecondDisplayConfig /> },
      ...(companyInfo0()?.country !== 'de' ? [{ key: 'srm', value: 'SRM', Comp: () => <InstallationSrmTab /> }] : []),
      ...(companyInfo0()?.country !== 'de' ? [{ key: 'backup', value: 'Backup', Comp: () => <InstallationBackupTab /> }] : []),
      { key: 'pairingCode', value: 'Pairing Code', Comp: () => <InstallationPairingCodeTab /> },
      { key: 'posFeatures', value: 'Pos Features', Comp: () => <DeveloperPosFeaturesTab /> },
      { key: 'deviceFeatures', value: 'Device Features', Comp: () => <DeveloperDeviceFeaturesTab /> },
      { key: 'kiosk', value: 'OTS (Kiosk)', Comp: () => <OTSKiosk /> },
    ],
    []
  )

  return (
    <ThemeProvider theme={muiTheme}>
      <div className="w-full h-full no-scrollbar p-[10px] overflow-y-auto">
        <div className="relative border-b border-b-slate-200 grid grid-cols-[1fr_max-content] gap-2 items-center">
          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons={false}
          >
            {tabs.map(({ key, value }, i) => (
              <Tab
                key={key}
                value={`${i}`}
                label={value}
                data-testid={`installation.${key}`}
              />
            ))}
          </Tabs>
          <Button
            data-testid={'developer.back'}
            onClick={() => (router.screen = mainScreen())}
          >
            Back
          </Button>
        </div>
        {tabs.map(({ key, Comp }, i) => (
          <TabPanel
            key={key}
            value={currentTab}
            index={`${i}`}
          >
            <Comp />
          </TabPanel>
        ))}
      </div>
      {processing() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
        >
          <button
            type="button"
            className="bg-gray-solid-gray-750-696969 text-white p-4 rounded-xl ..."
            disabled
          >
            Processing...
          </button>
        </PortalPopup>
      )}
      <UpdateNowPopup />
      <DeleteAllPopup />
    </ThemeProvider>
  )
}

export default memo(Installation)
