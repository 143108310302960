//cach 1 -> convert to class
//cach 2 -> factory

import dayjs from 'dayjs'
import debug from 'debug'
import _, { capitalize } from 'lodash'

import { invoiceGroupPrinters0 } from '@/data/GroupPrinterHub.ts'
import type { Order } from '@/data/Order.ts'
import { taxCategories0 } from '@/data/TaxCategoryHub.ts'
import { InvoiceTypes, MarketPlaceProvider, OrderType } from '@/pos/OrderType.ts'
import { getLL2 } from '@/react/core/I18nBackend.tsx'
import { printQrCode } from '@/react/Printer/print-invoice.ts'
import { getMappedPrinter } from '@/react/Printer/print-utils.ts'
import type { ColItemMetaData, TableColumnData } from '@/shared/printer/pure-image-printer'
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { logo0 } from "@/data/ImageHub.ts";
import { companyInfo0, posSetting0, posSettings0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from '@/shared/printer/types'
import { findCustomerByCompanyName } from '@/react/CustomerInfoView/customer-utils.ts'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import assert from 'assert'

const log = debug('data:printing')

export async function printInvoiceRasterFactory(order?: Order, invoiceType?: InvoiceTypes)/*: Promise<PrintInvoiceRasterFactory> */{
  // console.log('[print] printInvoiceRasterFactory', order);
  //fixme: hide all print code
  //region invoice raster factory init
  const isOnlineOrder = !!order?.provider
  const isDeliveryOrder = order?.type === 'delivery'
  const groupPrinter = invoiceGroupPrinters0()[0]
  const invoicePrinter = getMappedPrinter(groupPrinter)
  assert(!!invoicePrinter, 'Invoice printer uninitialized')
  const posSetting = posSetting0()
  const logo = logo0()?.data
  assert(!!posSetting, 'Pos setting uninitialized')
  const { companyInfo } = posSetting
  assert(!!companyInfo, 'Missing company info')
  const { name: companyName, address: companyAddress, telephone: companyTel, taxLabel, taxNumber: companyVatNumber } = companyInfo
  const { hideUnitPrice, merge, printDelivery, printCustomerInfo, printTipLine, marginTop, escPOS, fontSize, fontSizeForReceiptHeader } = invoicePrinter
  const LL = getLL2()
  const pickup =  order?.type == OrderType.PickUp;
  const delivery =  order?.type == OrderType.Delivery;
  const printing = LL().printing;
  const _fontSize = 20 + ((fontSize || 1) - 1) * 8;
  const fontSizeHeader = fontSizeForReceiptHeader ? 20 + ((fontSizeForReceiptHeader || 1) - 1) * 8 : 28;
  //add
  const customerPrint = LL().customer;
  const deliveryPrint = LL().delivery;
  const settingPrint = LL().settings;
  //
  const orderDate = dayjs.unix(order?.date || 0).format(LL().dates.dateFormat())
  const orderTime = dayjs.unix(order?.date || 0).format(LL().dates.timeFormat())

  const printer = new VPrinter({...invoiceGroupPrinters0()[0].printers[0]});
  let shippingFee: number, taxGroups: any
  //endregion

  //fixme: logo
  // await printHeader0();
  // await printOnlineOrderHeader1();
  // await printId();
  // await printBody0();
  // await printPayment()

  // await printFooterText();

  // await printRedInvoice();

  // if (process.env.NODE_ENV !== 'test' && invoiceType !== InvoiceTypes.GUEST_CHECK) await cms.emit('printQrCode', printer, order);

  // await printQrCode0();

  // const raster: Raster = (await printer.print())!;
  async function getRaster(): Promise<ScriptedRaster> {
    const raster = await printer.getRaster();
    return raster
  }

  return {
    printer,
    printHeader0,
    printTime,
    printOnlineOrderHeader1,
    printId,
    printBody0,
    printPayment,
    printFooterText,
    printRedInvoice,
    printQrCode0,
    getRaster,
  }
  // return raster;

//region print-functions
  async function printHeader0() {
    await printer.marginTop(marginTop || 0)
    await printer.alignCenter()
    await printLogo()
    await printer.newLine()

    await printer.setTextQuadArea()
    await printer.bold(true)
    if (companyName!.trim()) {
      await printer.setFontSize(_.round(_fontSize * 0.9, 0));
      await printer.println(companyName!)
    }
    await printer.newLine(4)
    await printer.bold(false)
    await printer.setFontSize(_.round(_fontSize * 0.5, 0))
    await printer.println(companyAddress!)
    await printer.println(companyTel ? `${printing.tel()}: ${companyTel}` : '')
    // await printer.println(taxLabel && companyVatNumber ? `${taxLabel || ''}: ${companyVatNumber}` : '')
    await printer.println(/*taxLabel &&*/ companyVatNumber ? `${taxLabel || 'St.-Nr'}: ${companyVatNumber}` : '')

    await printer.newLine(10)
    await printer.bold(true)
    await printer.setFontSize(_.round(_fontSize * 0.9, 0))
    if (invoiceType === InvoiceTypes.GUEST_CHECK) {
      await printer.println(printing.unpaidBill());
    } else {
      await printer.println(printing.invoice());
    }
    await printer.setTextNormal()
    if (isOnlineOrder) {
      if (isDeliveryOrder) {
        await printer.println(printing.delivery())
      } else {
        await printer.println(printing.pickup())
      }
    } else {
      if (isDeliveryOrder && printDelivery) {
        await printer.println(printing.delivery())
      }
    }
    await printer.newLine(10)
    await printer.bold(false)

    await printer.setFontSize(_.round(_fontSize * 0.6, 0))
    await printer.alignLeft()
  }

  async function printTime() {
    await printer.setFontSize(_.round(fontSizeHeader * 0.7, 0))
    await printer.println(`${printing.date()}: ${orderDate} ${orderTime}`)
  }

  // await printTime();

  async function printOnlineOrderHeader1() {
    await printer.setFontSize(_.round(fontSizeHeader * 0.7, 0))
    if (isOnlineOrder) {
      if (isDeliveryOrder) {
        // TODO: i18n
        await printer.println(`Delivery Date: ${dayjs(order.dropOffDate).format(printing.dateFormat())}`)
        if (order.shippingData?.comment) await printer.println(`Delivery Note: ${order.shippingData?.comment}`)
      } else {
        // TODO: i18n
        await printer.println(`Pickup Date: ${dayjs(order.pickupDate).format(printing.dateFormat())}`)
      }
      if (order.externalId) {
        if (order.provider === MarketPlaceProvider.UBER_EATS) {
          await printer.println(`${printing.invoiceNo()}: #${order.externalId.substring(order.externalId.length - 6)}`)
        } else {
          await printer.println(`${printing.invoiceNo()}: #${order.externalId}`)
        }
      }

      if (order.provider) {
        await printer.println(`Provider: ${order.provider}`) // TODO: i18n
      }
    }
  }

  function assignTaxGroup() {
    const taxType = posSettings0()[0].generalSetting?.taxType
    taxGroups = order?.vTaxSum
      ? Object.keys(order.vTaxSum)
        .map(taxAmount => {
          return {
            taxAmount,
            tax: order!.vTaxSum![taxAmount].tax,
            taxName: taxType === 'one' && taxCategories0().length > 0 ? taxCategories0()[0].name : printing.tax(),
          }
        })
        .sort((a, b) => a.tax - b.tax)
      : []
  }

  async function printBody0() {
    await printer.setFontSize(_.round(fontSizeHeader * 0.7, 0))
    if (!order) return;
    order.table && (await printer.println(`${printing.table()}: ${order.table}`))
    const user = order.users?.[0]
    if (user) {
      await printer.println(`${printing.cashier()}: ${user}`)
    }
    const customer = order.customerRaw

    if(!order.table && !customer){
      await printer.println(`${LL().dashboard.fastCheckout()}`)
    }
    if(!order.table && customer){
      if (pickup){
        await printer.println(`${LL().editOnlineMenu.pickup()}`);
      }
      if (delivery){
        await printer.println(`${LL().editOnlineMenu.delivery()}`);
      }
    }
    if (order.pickUpNumber) {
      await printer.bold(true)
      await printer.println(`${printing.pickUpNumber()}: ${order.pickUpNumber}`)
      await printer.setTextNormal()
    }

    if (isOnlineOrder) {
      const metadata = order.metadata
      if (metadata) {
        if (order.provider === MarketPlaceProvider.DELIVERECT) {
          metadata.channel && (await printer.println(`Channel: ${metadata.channel}`))
          metadata.channelOrderDisplayId && (await printer.println(`Channel Order Display Id: ${metadata.channelOrderDisplayId}`))
        }
      }
    }

    if (customer && companyInfo0()?.country !== 'de') {
      customer.name && (await printer.println(`${printing.customerName()}: ${customer.name}`))
      customer.phone && (await printer.println(`${printing.customerPhone()}: ${customer.phone}`))
      customer.address && (await printer.println(`${printing.customerAddress()}: ${customer.extraAddressInfo ? customer.extraAddressInfo + ', ' : ''}${customer.address}`))
    }

    const hasNote = order.note || customer?.note
    if (hasNote) {
      // TODO: i18n
      await printer.println(`${printing.note()}:`)
      order.note && (await printer.println(`- ${order.note}`))
      customer?.note && (await printer.println(`- ${customer.note}`))
    }
    await printer.newLine(16)

    const metaData = {
      colMetaData: createColMetaData(),
      rowMetaData: [{ borderBottom: true }],
    }
    await printer.setFontSize(_.round(_fontSize * 0.65, 0))
    const data: TableColumnData[][] = [createOrderDetailTableHeader(printing.product(), printing.quantity(), printing.price(), printing.sum())]
    for (const item of order.items) {
      if (item.quantity === 0) continue
      data.push(
        createItemRowData((item.isVoucher ? `${item.name} - ${item.code || ''}` : `${item.id ? item.id + '.': ''}${item.name}`)!, `${item.quantity}`, LL3().format.currency(item.price), LL3().format.currency(item.price * item.quantity), true)
      )

      if (item.note) {
        data.push([{ text: `  - ${item.note}` }, { text: '' }, { text: '' }, { text: '' }])
      }

      for (const modifier of item.modifiers || []) {
        data.push(createItemRowData(` * ${modifier.name} (${LL3().format.currency(modifier.price)})`, `\u200B${modifier.quantity}`, '', LL3().format.currency(modifier.price * modifier.quantity * item.quantity)))
      }
    }
    await printer.advancedTableCustom({ metaData, data }, true)
    await printer.drawLine()
    const net = isOnlineOrder ? order.vSubTotal! : _.sumBy(_.values(order.vTaxSum), 'net') - _.sumBy(_.values(order.shippingData?.vTaxSum), 'net')

    async function printSubtotal() {
      await printer.leftRight(printing.subTotal(), LL3().format.currency(net))
    }

    shippingFee = 0
    if (order.shippingData) {
      shippingFee = _.sumBy(_.values(order.shippingData.vTaxSum), 'net')
    }

    assignTaxGroup();

    await printDiscount()
    await printSubtotal()
  }

  async function printPayment() {
    if (!order) return;
    await printer.setFontSize(_.round(_fontSize * 0.65, 0))
    assignTaxGroup();
    if (isOnlineOrder) {
      if (_.isNil(order.taxTotal)) {
        await printTaxes()
      } else {
        await printer.leftRight(LL().pendingOrder.totalTax(), LL3().format.currency(order.taxTotal))
      }
      // TODO: generic for all provider
      if (order.provider === MarketPlaceProvider.DELIVERECT) {
        if (order.shippingData?.fee) {
          await printer.leftRight(LL().payment.deliveryFee(), LL3().format.currency(order.shippingData?.fee || 0))
        }
      } else {
        await printShippingFee()
      }
      await printServiceFee()
      await printer.leftRight(LL().pendingOrder.bagFee(), LL3().format.currency(order.bagFee || 0))
      await printTotal()
      await printer.leftRight(printing.totalTip(), LL3().format.currency(order.tip || 0))
    } else {
      await printServiceFee()
      await printShippingFee()
      await printTaxes()
      await printTotal()
    }
    if (invoiceType !== InvoiceTypes.GUEST_CHECK) {
      for (const payment of order.payments) {
        await printer.leftRight(capitalize(payment.type), LL3().format.currency(payment.value))
      }
      if (printTipLine && !isOnlineOrder) await printer.leftRight(printing.totalTip(), LL3().format.currency(order.tip || 0))
      await printer.leftRight(printing.changeDue(), LL3().format.currency(order.cashback || 0))
    }
    await printer.newLine(8)
    await printer.alignLeft()


    //add
    const customer = order.customerRaw;
    if (customer && companyInfo0()?.country === 'de') {
      await printer.newLine(8)
      const customerInfo = findCustomerByCompanyName(customer.company);

      customer?.name && await printer.println(`${printing.customerName()}: ${customer.name}`);
      customer?.company && await printer.println(`${settingPrint.companyName()}: ${customer?.company}`);
      customer?.phone && await printer.println(`${printing.customerPhone()}: ${customer.phone}`);
      customer?.address && await printer.println(`${printing.customerAddress()}: ${customer.extraAddressInfo ? customer.extraAddressInfo + ', ' : ''}${customer.address}`);
      customer?.city && await printer.println(`${settingPrint.city()}: ${customer?.city}`);
      // customer.extraAddressInfo && await printer.println(customer.extraAddressInfo)
      customer?.zipCode && await printer.println(`${customerPrint.zipcode()}: ${customer.zipCode}`);
      customer?.email && await printer.println(`${deliveryPrint.customer.email()}: ${customer.email}`);
      customer?.ustId && await printer.println(`${settingPrint.ustId()}: ${customer.ustId}`);


      await printer.newLine(4)

      customerInfo?.id && await printer.println(`Kundennummer: ${customerInfo?.id}`);
      customer?.taxNo && await printer.println(`${settingPrint.taxNo()}: ${customer.taxNo}`);
      // customerInfo?.cardNo && await printer.println(`Kartennummer: ${customerInfo?.cardNo}`);
      // customer?.note && await printer.println(`Weitere Informationen: ${customer?.note}`);
    }
    await printer.alignCenter();
    await printer.newLine(8)
    //
  }

  async function printFooterText() {
    await printer.setFontSize(_.round(_fontSize * 0.65, 0))
    await printer.alignCenter();
    await printer.println(posSetting!.companyInfo?.footerText || '')
    await printer.newLine(8)
  }

  async function printRedInvoice(_invoiceType: InvoiceTypes = InvoiceTypes.RED_INVOICE) {
    if (invoiceType === InvoiceTypes.RED_INVOICE || _invoiceType === InvoiceTypes.RED_INVOICE) {
      await printer.alignLeft()
      await printer.println(posSetting!.companyInfo?.redInvoiceContent || '')
    }
  }

  async function printQrCode0() {
    if (!order?.qrCode) return;
    await printQrCode(printer, order).printFull();
  }

  async function printId() {
    await printer.setFontSize(_.round(fontSizeHeader * 0.7, 0))
    if (!order) return;
    if (!order.externalId && order.id && order.id !== 0) await printer.println(`${printing.invoiceNo()}: ${order.id}`)
    if (order?.ticketNumber) {
      await printer.bold(true)
      await printer.println(`${LL().printing.ticketNumber()}: ${order.ticketNumber}`)
      await printer.bold(false)
    }
  }
  //endregion

  //region print-invoice-utils
  async function printShippingFee() {
    if (shippingFee > 0) await printer.leftRight(printing.shippingFee(), LL3().format.currency(shippingFee))
  }

  async function printTotal() {
    if (!order) return;
    await printer.bold(true)
    // if (isOnlineOrder) {
    //   await printer.leftRight(printing.total(), `${LL3().format.currency(order.vTotal!)}`)
    // } else {
      await printer.leftRight(printing.total(), `${LL3().format.currency(order.vSum!)}`)
    // }
    await printer.bold(false)
  }

  async function printTaxes() {
    // console.log('[print] printTaxes')
    for (let i = 0; i < taxGroups.length; i++) {
      const { taxName, taxAmount, tax } = taxGroups[i];
      // console.log('[print] printTaxes', taxAmount, tax)
      await printer.leftRight(`${taxAmount}%`, LL3().format.currency(tax))
    }
  }

  async function printDiscount() {
    if (!order) return;
    if (order.vDiscount && !isNaN(order.vDiscount!) && order.vDiscount! > 0)
      await printer.leftRight(`${printing.discount()} ${typeof order.discount === 'string' && order.discount.includes('%') ? order.discount : ''}`, `-${LL3().format.currency(order.vDiscount)}`)
  }

  async function printServiceFee() {
    if (!order) return;
    if (order.serviceFee) {
      await printer.leftRight(printing.serviceFee(), LL3().format.currency(order.serviceFee || 0))
    }
  }

  function createColMetaData() {
    const res: ColItemMetaData[] = [{ align: 'LEFT' }]
    if (!merge) res.push({ align: 'RIGHT', /*priority: 'HIGH',*/ padding: escPOS ? 0.05 : 0.03 })
    if (!hideUnitPrice) res.push({ align: 'RIGHT', /*priority: 'HIGH',*/ padding: escPOS ? 0.05 : 0.03 })
    res.push({ align: 'RIGHT', priority: 'HIGH' })
    return res
  }

  function createOrderDetailTableHeader(name: string, quantity: string, unitPrice: string, totalPrice: string): TableColumnData[] {
    const res: TableColumnData[] = [{ text: name, bold: true }]
    if (!merge) res.push({ text: quantity, bold: true })
    if (!hideUnitPrice) res.push({ text: unitPrice, bold: true })
    res.push({ text: totalPrice, bold: true })
    return res
  }

  function createItemRowData(name: string, quantity: string, unitPrice: string, totalPrice: string, isBold?: boolean) {
    const res = []
    if (merge) res.push({ text: `${quantity} x ${name}` , ...(isBold && { bold: true })})
    else res.push({ text: name , ...(isBold && { bold: true })})
    if (!merge) res.push({ text: `${quantity}` , ...(isBold && { bold: true })})
    if (!hideUnitPrice) res.push({ text: `${unitPrice}` , ...(isBold && { bold: true })})
    res.push({ text: `${totalPrice}` , ...(isBold && { bold: true })})
    return res
  }

  async function printLogo() {
    if (logo && logo.length > 100) {
      const _logo = logo.replace(/^data:image\/\w+;base64,/, '')
      await printer.printImage(_logo, 'base64', 0.25 + ((posSetting!.companyInfo?.logoSize || 1) - 1) * 0.15)
    }
  }
  //endregion
}

export function clearPrintCache() {
  _.assign(posSetting0(), { printerCache: null })
}
