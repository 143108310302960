import { type FunctionComponent, useCallback, useEffect, useState } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from '@mui/material'
import EditMenuPopupModifiers from '../Popups/EditMenuPopupModifiers.tsx'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import {
  activeSecondPrinter0,
  getPrinterClassname,
  handleInputPopup,
  ITEM_MODE,
  onToggleCategory,
  onToggleHappyHour,
  product0,
  PRODUCT_COLORS,
  productLayout0,
  pushData,
  setActiveSecondPrinter0,
  setEditorMode0
} from '@/react/EditMenuView/EditMenuView.tsx'
import _ from 'lodash'
import { kitchenGroupPrinters0, labelPrinters0 } from '@/data/GroupPrinterHub.ts'
import { dineInTaxCategories0, takeAwayTaxCategories0, taxCategories0 } from '@/data/TaxCategoryHub.ts'
import clsx from 'clsx'
import { addModifierFactory, dineInModifiers, setShowAllVariants, variantModifiers } from '@/data/ModifierHub.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import { happyHours0 } from '@/data/HappyHourHub.ts'
import { PosScreen, router } from '@/pos/PosRouter.ts'
import { discountTypeLabels, weekDayPeriodConverter } from '@/react/HappyHourView/HappyHourView.tsx'
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts'
import Input3KeyboardPopUp from '@/react/core/Input3KeyboardPopUp2.tsx'
import { materialCategories } from '@/data/CategoryHub.ts'
import { mappedInventories } from '@/data/InventoryHub.ts'
import InventoryAddEditCategory from '@inventory/Popups/InventoryAddEditCategory.tsx'
import EditMenuProviderMenuName from "@edit-menu/EditMenuProviderMenuName.tsx";
import { isLabelPrinterEnable } from "@/react/Printer/PrinterSettingView.tsx";
import { calculateTaxValues } from "@/react/EditMenuView/EditMenuUtils.ts";
import {
  closeEditMenuProviderMenuName,
  dineIn,
  isEditMenuProviderMenuNameOpen,
  menuSelected,
  multipleMenus0,
  openEditMenuProviderMenuName,
  pricingLevelFactory,
  setMenuSelected
} from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import TextField from "@/react/core/TextField.tsx";
import EditMenuPopupVariants from "@edit-menu/Popups/EditMenuPopupVariants.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";


const EditNormalItemForm: FunctionComponent = () => {
  const [isEditMenuPopupModifiersOpen, setEditMenuPopupModifiersOpen] =
    useState(false)
  const [isEditMenuPopupVariantsOpen, setEditMenuPopupVariantsOpen] =
    useState(false)
  const [isInventoryAddEditCategoryOpen, setInventoryAddEditCategoryOpen] =
    useState(false)

  const openEditMenuPopupModifiers = useCallback(() => {
    userFLow(`open edit menu popup modifier`, {
      username: loginUser()?.name
    });
    setEditMenuPopupModifiersOpen(true)
  }, [])

  const closeEditMenuPopupModifiers = useCallback(() => {
    userFLow(`close edit menu popup modifier`, {
      username: loginUser()?.name
    });
    setEditMenuPopupModifiersOpen(false)
  }, [])

  const openEditMenuPopupVariants = useCallback(() => {
    userFLow(`open edit menu popup variants`, {
      username: loginUser()?.name
    });
    setEditMenuPopupVariantsOpen(true)
  }, [])

  const closeEditMenuPopupVariants = useCallback(() => {
    userFLow(`close edit menu popup variants`, {
      username: loginUser()?.name
    });
    setShowAllVariants(false)
    setEditMenuPopupVariantsOpen(false)
  }, [])

  const openInventoryAddEditCategory = useCallback(() => {
    userFLow(`open inventory add edit category`, {
      username: loginUser()?.name
    });
    setInventoryAddEditCategoryOpen(true)
  }, [])

  const closeInventoryAddEditCategory = useCallback(() => {
    userFLow(`close inventory add edit category`, {
      username: loginUser()?.name
    });
    setInventoryAddEditCategoryOpen(false)
  }, [])

  const {computedPrice,
    getMenusTax,
    computedMenuTaxes,
    updateMenuSelection,
    changeProductPrice,
    checkTakeAwayTax,
    checkDineInTax,
    checkOneTypeTax,
    getInfo} = pricingLevelFactory()

  const {multipleEnable} = getInfo()


  useEffect(() => {
    setMenuSelected(dineIn() as string);
    }, [productLayout0()])

  return (
    <>
      <div
        className="!flex self-stretch hidden flex-col items-start justify-start py-4 px-3 gap-[16px] text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish">
        { multipleEnable && (
        <div className="self-stretch flex flex-row items-center justify-start gap-[10px]">
          <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
            {LL0().editMenu.menu()}:
          </b>
          <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
            <FormControl
              className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
              variant="outlined"
            >
              <InputLabel color="primary"/>
              <Select
                color="primary"
                size="small"
                value={menuSelected() || dineIn()}
                onChange={(e) => {
                  updateMenuSelection(e);
                }}
              >
                {multipleMenus0()?.map((menu, index) => (
                  <MenuItem value= {menu._id} key = {index}> {menu.name} </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText/>
            </FormControl>
            <div
              className="h-[36.7px] self-stretch rounded [background:linear-gradient(180deg,_#82e1ff,_#2aacf5)] overflow-hidden flex flex-row items-center justify-start py-2.5 px-[5px] cursor-pointer"
              onClick={openEditMenuProviderMenuName}
            >
              <img
                className="w-[16.4px] relative h-[16.4px] object-cover"
                alt=""
                src="/iconeditmenuaddmenu@2x.png"
              />
            </div>
          </div>
        </div>
        )}
        <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
          <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
            {LL0().article.type()}:
          </b>
          <FormControl
            className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
            variant="outlined"
          >
            <InputLabel color="primary"/>
            <Select color="primary" size="small"
                    value={productLayout0()?.type || 'Text'}
                    onChange={(e) => {
                      const _productLayout = productLayout0()
                      if (_productLayout) _productLayout.type = e.target.value as any
                    }}
            >
              <MenuItem value="Text">{LL0().article.types.titleName()}</MenuItem>
              <MenuItem value="Article">{LL0().article.types.normalItem()}</MenuItem>
              <MenuItem value="NonFixed">{LL0().article.types.nonFixedItem()}</MenuItem>
              <MenuItem value="Extra">{LL0().article.types.extra()}</MenuItem>
            </Select>
            <FormHelperText/>
          </FormControl>
        </div>
        <Input3KeyboardPopUp
          value={''}
          onSave={(name, id, price) => {
            if (!product0()) return;
            product0()!.name = name
            product0()!.id = id
            if (multipleEnable && !(menuSelected() === dineIn())) {
              product0()!.menuPrices!.find(m => m.menu === menuSelected())!.value = parseFloat(price || '0')
            } else {
              product0()!.price = parseFloat(price || '0')
            }
          }}>
          {(show, setShow, inputControllers) => (
            <>
              <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
                <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                  <span>{LL0().article.name()}</span>
                  <span className="text-firebrick">*</span>
                  <span>:</span>
                </b>
                <TextField
                  className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                  value={product0()?.name || ''}
                  onChange={(e) => {
                    const _product = product0()
                    if (_product) _product.name = e.target.value
                  }}
                  onKeyboardIconClick={() => {
                    handleInputPopup(setShow, inputControllers, pushData, 0)
                  }}
                />
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
                <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                  <span>{LL0().article.id()}</span>
                </b>
                <TextField
                  className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                  value={product0()?.id || ''}
                  onChange={(e) => {
                    const _product = product0()
                    if (_product) _product.id = e.target.value
                  }}
                  onKeyboardIconClick={() => {
                    handleInputPopup(setShow, inputControllers, pushData, 1)
                  }}
                />
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
                <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                  <span>{LL0().article.price()}</span>
                </b>
                <TextField
                  className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                  value={computedPrice() || ''}
                  onChange={(e) => {
                    changeProductPrice(e.target.value)
                  }}
                  onKeyboardIconClick={() => {
                    if (!getAccessibility(AccessPort.EDIT_ITEM_PRICE)) return;
                    handleInputPopup(setShow, inputControllers, pushData, 2)
                  }}
                />
              </div>
            </>
          )}
        </Input3KeyboardPopUp>
        <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-sm">
          <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
            {LL0().ui.color()}:
          </b>
          <div className="flex-1 flex flex-row flex-wrap items-start justify-start gap-[12px]">
            {PRODUCT_COLORS.map((color, index) =>
              <div className="rounded-981xl flex flex-row flex-wrap items-start justify-start relative"
                   key={index}
                   style={{ backgroundColor: color }}
                   onClick={() => {
                     userFLow(`change color ${color}`, {
                       itemId: product0()?._id,
                       username: loginUser()?.name
                     });
                     _.assign(productLayout0(), { color })
                   }}
              >
                <div
                  className="relative rounded-981xl box-border w-6 h-6 z-[0] border-[1px] border-solid border-gray-solid-gray-320-d2d2d2"/>
                {productLayout0()?.color === color &&
                  <>
                    <div
                      className="absolute my-0 mx-[!important] top-[-0.1px] left-[0px] rounded-981xl box-border w-6 h-6 z-[1] border-[1px] border-solid border-blue-500"/>
                    <img
                      className="absolute my-0 mx-[!important] top-[0.1px] right-[-4px] w-3 h-3 object-contain z-[2]"
                      alt=""
                      src="/iconchecked-icon1@2x.png"
                    />
                  </>
                }
              </div>
            )}
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[4px]"
                 onClick={() => {
                   userFLow(`go to screen printer setting`, {
                     username: loginUser()?.name
                   });
                   router.screen = PosScreen.PRINTER_SETTING
                 }}>
              <img
                className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
                alt=""
                src="/iconshift-icon@2x.png"
              />
              <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">
                {LL0().productViewSetting.modifierEditorDialog.printer()}:
              </b>
            </div>
            <div
              className={clsx('rounded bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.14)] flex flex-row items-center justify-center py-1 px-2 text-smi text-blue-solid-blue-420-2979ff',
                activeSecondPrinter0() && 'border-[2px] !border-[tomato]')}
              onClick={() => {
                userFLow(`set active second printer`, {
                  itemId: product0()?._id,
                  username: loginUser()?.name
                });
                setActiveSecondPrinter0((v) => !v)
              }}
            >
              <div className="relative">
                <b>+</b>
                <span className="text-black-solid-black-600-424242">
                  2 {LL0().editMenu.printers()}
                </span>
              </div>
            </div>
          </div>
          <div
            className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
            {kitchenGroupPrinters0().map((groupPrinter, index) => (
              <div
                className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px]',
                  getPrinterClassname(groupPrinter))}
                key={index}
                onClick={() => {
                  userFLow(`choose groupPrinter ${groupPrinter.name}`, {
                    itemId: product0()?._id,
                    username: loginUser()?.name
                  });
                  if (activeSecondPrinter0()) {
                    setActiveSecondPrinter0(false)
                    return _.assign(product0(), { groupPrinter2: groupPrinter._id })
                  }
                  const _product = product0();
                  if (!_product) return;
                  if (_product?.labelPrinter) {
                    _.assign(product0(), {
                      labelPrinter: "",
                    })
                  }
                  if (generalSetting0()?.taxType === 'one') {

                    _.assign(product0(), {
                      groupPrinter: groupPrinter._id,
                      groupPrinter2: "",
                    })

                    if (taxCategories0().length === 1) {
                      _.assign(product0(), {
                        taxCategory: taxCategories0()[0].name,
                        taxCategory2: taxCategories0()[0].name,
                        tax: taxCategories0()[0].value,
                        tax2: taxCategories0()[0].value,
                        taxComponents: taxCategories0()[0].components,
                        taxComponents2: taxCategories0()[0].components,
                      })
                    }
                  } else {
                    const result = calculateTaxValues(groupPrinter);

                    _.assign(product0(), {
                      ...result,
                      groupPrinter: groupPrinter._id,
                      groupPrinter2: "",
                    })
                  }
                }}
              >
                {groupPrinter.name}
              </div>
            ))}
            {isLabelPrinterEnable() && (
              <div
                className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px]',
                  getPrinterClassname(labelPrinters0()))}
                onClick={() => {
                  userFLow(`set labelPrinter ${labelPrinters0()._id}`, {
                    itemId: product0()?._id,
                    username: loginUser()?.name
                  });
                  const _product = product0();
                  if (!_product) return;
                  if (_product?.labelPrinter) {
                    _.assign(product0(), {
                      labelPrinter: "",
                    })
                    return
                  }
                  _.assign(product0(), {
                    labelPrinter: labelPrinters0()._id,
                  })
                  _.assign(product0(), {
                    groupPrinter: "",
                  })
                }}
              >
                Label
              </div>
            )}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[10px] sm:flex-col sm:gap-[16px]">
          {generalSetting0()?.taxType === 'two' &&
            <div className="flex-1 flex flex-row items-start justify-start gap-[10px] sm:flex-[unset] sm:self-stretch">
              {dineInTaxCategories0().length > 0 &&
                <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                  <b className="relative sm:text-sm Tablet_600:text-smi">
                    {LL0().editMenu.dineInTax()}:
                  </b>
                  <div
                    className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
                    {dineInTaxCategories0().map((taxCategory, index) => (
                      <div
                        className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1',
                          checkDineInTax(taxCategory) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                        key={index}
                        onClick={() => {
                          userFLow(`set dine tax ${taxCategory._id}`, {
                            itemId: product0()?._id,
                            username: loginUser()?.name
                          });
                          if (menuSelected() === dineIn() || !multipleEnable) {
                            _.assign(product0(), {
                              tax: taxCategory.value,
                              taxCategory: taxCategory.name,
                              taxComponents: taxCategory.components
                            })
                          } else {
                            const taxData = { menu: menuSelected(), ref: taxCategory.name, value: taxCategory.value };
                            const menuTaxes = getMenusTax(1);
                            const updatedMenuTaxes = computedMenuTaxes(taxData, menuTaxes)
                            _.assign(product0(), {menuTaxes: updatedMenuTaxes});
                          }
                        }}>
                        {taxCategory.printLabel || `${taxCategory.value}%`}
                      </div>
                    ))}
                  </div>
                </div>}
              {takeAwayTaxCategories0().length > 0 &&
                <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
                  <b className="relative sm:text-sm Tablet_600:text-smi">
                    {LL0().editMenu.takeAwayTax()}:
                  </b>
                  <div
                    className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
                    {takeAwayTaxCategories0().map((taxCategory, index) => (
                      <div
                        className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1',
                          checkTakeAwayTax(taxCategory) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}

                        key={index}
                        onClick={() => {
                          userFLow(`set take away tax ${taxCategory._id}`, {
                            itemId: product0()?._id,
                            username: loginUser()?.name
                          });
                          if (menuSelected() === dineIn() || !multipleEnable) {
                            _.assign(product0(), {
                              tax2: taxCategory.value,
                              taxCategory2: taxCategory.name,
                              taxComponents2: taxCategory.components
                            })
                          } else {
                            const taxData = { menu: menuSelected(), ref: taxCategory.name, value: taxCategory.value };
                            const menuTaxes = getMenusTax(2);
                            const updatedMenuTaxes = computedMenuTaxes(taxData, menuTaxes)
                            _.assign(product0(), {menuTaxes2: updatedMenuTaxes});
                          }
                        }}>
                        {taxCategory.printLabel || `${taxCategory.value}%`}
                      </div>
                    ))}
                  </div>
                </div>}
            </div>
          }
          {generalSetting0()?.taxType === 'one' &&
            <div className="flex-1 flex flex-col items-start justify-start gap-[12px] sm:flex-[unset] sm:self-stretch">
              <b className="relative sm:text-sm Tablet_600:text-smi">{LL0().article.tax()}:</b>
              <div
                className="gap-[8px] self-stretch flex flex-row flex-wrap items-start justify-start text-black-solid-black-880-1d1d26">
                {taxCategories0().map((taxCategory, index) => (
                  <div
                    className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1',
                      checkOneTypeTax(taxCategory) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                    key={index}
                    onClick={() => {
                      userFLow(`set tax ${taxCategory.name} (${taxCategory.value})`, {
                        itemId: product0()?._id,
                        username: loginUser()?.name
                      });
                      if (menuSelected() === dineIn() || !multipleEnable) {
                        _.assign(product0(), {
                          tax: taxCategory.value,
                          tax2: taxCategory.value,
                          taxCategory: taxCategory.name,
                          taxCategory2: taxCategory.name,
                          taxComponents: taxCategory.components,
                          taxComponents2: taxCategory.components
                        })
                      } else {
                        if (!taxCategory) {
                          console.log(`tax is undefined`)
                        }
                        const taxData = { menu: menuSelected(), ref: taxCategory.name, value: taxCategory.value };
                        const menuTaxes = getMenusTax(1);
                        const updatedMenuTaxes = computedMenuTaxes(taxData, menuTaxes)
                        _.assign(product0(), { menuTaxes: updatedMenuTaxes, menuTaxes2: updatedMenuTaxes });
                      }
                    }}>
                    {taxCategory?.name} {
                    taxCategory?.components?.reduce((accumulator, currentValue) => {
                      return accumulator + (currentValue?.value || 0);
                    }, 0)
                    || taxCategory.value}%
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
          <div
            className="flex flex-row items-center justify-start gap-[4px] cursor-pointer"
            onClick={openEditMenuPopupModifiers}
          >
            <img
              className="w-6 relative h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
              alt=""
              src="/iconshift-icon@2x.png"
            />
            <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">
              {LL0().productViewSetting.popupModifiers()}:
            </b>
          </div>
          <div
            className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
            {dineInModifiers().map((_modifier, index) => (
              <div
                className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1',
                  product0()?.activePopupModifierGroup?.includes(_modifier._id!) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                key={index}
                onClick={() => addModifierFactory(product0, _modifier)(!product0()?.activePopupModifierGroup?.includes(_modifier._id!))}>
                <span>{_modifier.name}</span>
              </div>
            ))}
          </div>
        </div>
        {
          product0()?.name &&
          <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            <div
              className="flex flex-row items-center justify-start gap-[4px] cursor-pointer"
              onClick={openEditMenuPopupVariants}
            >
              <img
                className="w-6 relative h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
                alt=""
                src="/iconshift-icon@2x.png"
              />
              <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">
                {LL0().productViewSetting.popupVariants()}:
              </b>
            </div>
            <div
              className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
              {variantModifiers().map((_modifier, index) => (
                <div
                  className={clsx('rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1',
                    product0()?.activePopupModifierGroup?.includes(_modifier._id!) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                  key={index}
                  onClick={() => addModifierFactory(product0, _modifier)(!product0()?.activePopupModifierGroup?.includes(_modifier._id!))}>
                  <span>{_modifier.name}</span>
                </div>
              ))}
            </div>
          </div>
        }
        {(menuSelected() === dineIn() || !multipleEnable) && (
          <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            <div className="flex flex-row items-center justify-start gap-[4px]"
                 onClick={() => {
                   userFLow(`go to screen happy hour`, {
                     username: loginUser()?.name
                   });
                   router.screen = PosScreen.HAPPY_HOUR
                 }}
            >
              <img
                className="w-6 relative h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
                alt=""
                src="/iconshift-icon@2x.png"
              />
              <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">
                {LL0().restaurant.product.happyHour()}:
              </b>
            </div>
            {
              happyHours0().map((happyHour, index) =>
                <div
                  className={clsx('rounded-sm flex flex-col items-center justify-center py-1 px-2 gap-[4px] text-center text-smi text-black-solid-black-1000-000000 border-[1px] border-solid border-gainsboro',
                    product0()?.happyHours?.includes(happyHour._id!) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                  key={index}
                  onClick={() => {
                    userFLow(`add happy hour ${happyHour._id}`, {
                      itemId: product0()?._id,
                      username: loginUser()?.name
                    });
                    onToggleHappyHour(happyHour._id)
                  }}
                >
                  <div className="flex flex-row items-center justify-center gap-[4px]">
                    <div className="relative">{happyHour.from} - {happyHour.to}</div>
                    <div className="relative text-sm font-extrabold text-blue-solid-blue-350-2196f3">
                      : {discountTypeLabels?.[happyHour?.discountType!]?.valueLabel}{happyHour.discountValue}
                    </div>
                  </div>
                  <div className="relative">{weekDayPeriodConverter(happyHour?.days!)}</div>
                </div>
              )
            }
          </div>)}
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px] text-mini">
          <div
            className="flex flex-row items-center justify-start gap-[4px] cursor-pointer"
            onClick={openInventoryAddEditCategory}
          >
            <img
              className="w-6 relative h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
              alt=""
              src="/iconshift-icon@2x.png"
            />
            <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">
              {LL0().restaurant.menuEdit.materialCategories()}
            </b>
          </div>
          <div
            className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[8px] text-black-solid-black-880-1d1d26">
            {materialCategories().map(category => (<div
              className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
              key={category._id}
              onClick={() => onToggleCategory(category._id, category.name)}
            >
              {product0()?.categories?.includes(category._id) &&
                <div
                  className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff"/>
              }
              <div
                className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee box-border hidden z-[1] border-[2px] border-solid border-gray-solid-gray-150-eeeeee"/>
              <div className="relative z-[2]">{category.name}</div>
            </div>))}
          </div>
        </div>
        {getAccessibility(PosScreen.INVENTORY) &&
          <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
            <div
              className="flex flex-row items-center justify-start gap-[4px] cursor-pointer"
              onClick={() => {
                userFLow(`set editor mode`, {
                  username: loginUser()?.name
                });
                setEditorMode0(ITEM_MODE.INGREDIENT)
              }}
            >
              <img
                className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] Tablet_600:w-5 Tablet_600:h-5"
                alt=""
                src="/iconshift-icon@2x.png"
              />
              <b
                className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">{LL0().productViewSetting.ingredients()}:</b>
            </div>
            {product0().ingredients?.map((ingredient, index) => {
              const foundInventory = mappedInventories().find(inventory => inventory._id === ingredient.inventoryId)
              return (
                <div
                  className="self-stretch flex flex-row flex-wrap items-start justify-start text-black-solid-black-880-1d1d26"
                  key={index}
                >
                  <div
                    className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-6 flex flex-col items-center justify-center py-0 px-3 relative gap-[8px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1">
                    <div
                      className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff"/>
                    <div
                      className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-gray-solid-gray-150-eeeeee box-border z-[1] border-[2px] border-solid border-gray-solid-gray-150-eeeeee"/>
                    <div
                      className="relative z-[2]">{foundInventory?.product?.name}: {ingredient.amount}{foundInventory?.unit}</div>
                  </div>
                </div>
              )
            })}
          </div>}
      </div>
      {isEditMenuProviderMenuNameOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuProviderMenuName}
        >
          <EditMenuProviderMenuName onClose={closeEditMenuProviderMenuName}/>
        </PortalPopup>
      )}
      {isEditMenuPopupModifiersOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuPopupModifiers}
        >
          <EditMenuPopupModifiers onClose={closeEditMenuPopupModifiers}/>
        </PortalPopup>
      )}
      {isEditMenuPopupVariantsOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuPopupVariants}
        >
          <EditMenuPopupVariants onClose={closeEditMenuPopupVariants} />
        </PortalPopup>
      )}
      {isInventoryAddEditCategoryOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryAddEditCategory}
        >
          <InventoryAddEditCategory onClose={closeInventoryAddEditCategory}/>
        </PortalPopup>
      )}
    </>
  )
}

export default EditNormalItemForm
