import { FunctionComponent, useCallback, useState } from "react";
import OrderAllCategoryPopup from "./Popups/OrderAllCategoryPopup";
import PortalPopup from "./PortalPopup";
import RenderCategory from "@/react/OrderView/Vertical/RenderCategory.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";

export type CategoryTabsType = {
  className?: string;
};

const CategoryTabs: FunctionComponent<CategoryTabsType> = ({
  className = "",
}) => {
  const [isAllCategoryPopupOpen, setAllCategoryPopupOpen] =
    useState(false);

  const openAllCategoryPopup = useCallback(() => {
    setAllCategoryPopupOpen(true);
  }, []);

  const closeAllCategoryPopup = useCallback(() => {
    setAllCategoryPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className={`self-stretch flex flex-row items-center justify-start py-1.5 pl-2.5 gap-[4px] z-[1] text-center text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div
          className="self-stretch w-[30px] rounded [background:linear-gradient(180deg,_#82e1ff,_#2aacf5)] flex flex-col items-center justify-center py-2 px-1 box-border cursor-pointer"
          ref={makeRipple}
          onClick={openAllCategoryPopup}
        >
          <img
            className="w-7 relative h-7 object-cover mq432:w-6 mq432:h-6 mq360:w-6 mq360:h-6"
            alt=""
            src="/retailscash-registermen-clothing@2x.png"
          />
        </div>
        <RenderCategory row={2} />
      </div>
      {isAllCategoryPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeAllCategoryPopup}
        >
          <OrderAllCategoryPopup onClose={closeAllCategoryPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default CategoryTabs;
