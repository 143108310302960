import { FunctionComponent } from "react";
import DashboardButtons from "./DashboardButtons";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { HANDLERS, menus } from "@/react/Dashboard.logic.ts";
import { LL0 } from "@/react/core/I18nService.tsx";

export type OnlineMenuPopupType = {
  className?: string;
  onClose?: () => void;
  onClosePanel?: () => void;
};

const OnlineMenuPopup: FunctionComponent<OnlineMenuPopupType> = ({
  className = "",
  onClose,
  onClosePanel,
}) => {

  const onClickButton = (screen: PosScreen) => {
    router.screen = screen
    onClosePanel?.()
    onClose?.()
  }

  return (
    <div
      className={`relative [background:linear-gradient(180deg,_#fff,_rgba(232,_238,_241,_0.55))] flex flex-col items-start justify-start pt-3 px-2.5 pb-4 box-border gap-2 max-w-full max-h-full overflow-auto ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-end">
        <img
          className="w-6 relative h-6 object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon2@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="no-scrollbar self-stretch overflow-y-auto flex flex-row items-start justify-start">
        <div
          className={`flex-1 relative h-auto grid ${menus().length > 6 ? "grid-cols-3" : "grid-cols-2" } px-2 pb-2 [grid-row-gap:4px] [grid-column-gap:10px] mq414:grid mq414:grid-cols-2 mq414:[grid-row-gap:4px] mq414:[grid-column-gap:10px]`}>
          <DashboardButtons
            icon="/iconfast-checkout-icon41@2x.png"
            buttonName={LL0().syncMenu.setupProviders()}
            showChip={false}
            chipValue="20"
            showIconPopup={false}

            onClick={() => onClickButton(PosScreen.VERTICAL_ONLINE_PROVIDER_SETTINGS)}
          />
          {menus().map((o, _i, arr) =>
            <DashboardButtons
              key={o._id}
              icon="/iconfast-checkout-icon42@2x.png"
              buttonName={arr.length === 1 ? LL0().dashboard.editOnlineMenu(): `${LL0().dashboard.editMenu()} ${o.name}`}
              showChip={false}
              chipValue="20"
              showIconPopup={false}
              onClick={() => {
                HANDLERS.selectMenu(o._id)
                onClickButton(PosScreen.VERTICAL_ONLINE_MENU)
              }}
            />)}
        </div>
      </div>
    </div>
  );
};

export default OnlineMenuPopup;
