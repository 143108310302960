import React, { type FunctionComponent } from "react";
import { cancelOrder, completeOrder, popupOrder, readyOrder } from "@/react/PendingOrder/PendingOrderLogic";
import _ from 'lodash'
import { DeliveryService, MarketPlaceProvider, OrderType } from "@/pos/OrderType";
import { useComputed } from "@/react/core/reactive";
import OrderList from "@pending-order/OrderList.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { smartFormatShortDateTime } from "@/shared/dateTimeUtils.ts";
import DottedLine from './DottedLine.tsx';
import DeliveryIcon from "@pending-order/DeliveryIcon.tsx";
import PickupIcon from "@pending-order/PickupIcon.tsx";
import InHouse from "@pending-order/InHouseIcon.tsx";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { orderConfig } from "@/shared/order/order-config.ts";

type PopupOrderDetailsPluginType = {
  onClose?: () => void;
};

const PopupOrderDetailsPlugin: FunctionComponent<
  PopupOrderDetailsPluginType
> = ({ onClose }) => {
  const order = popupOrder()

  const orderType = order.type;
  const isPickupOrder = orderType === OrderType.PickUp;
  const isDeliveryOrder = orderType === OrderType.Delivery;
  const isInHouse = orderType === OrderType.InHouse;
  const customerAddress = order.customerRaw?.address;
  const provider = order.provider;

  const tax = orderConfig.useTaxComponents && !_.isEmpty(order.vTaxComponents) ? _.round(_.sum(_.values(order.vTaxComponents)), 2) : _.sum(_.map(popupOrder().vTaxSum, ({ tax }) => tax));

  async function cancelPopupOrder() {
    userFLow(`cancel popup order`, {
      username: loginUser()?.name
    });
    cancelOrder(popupOrder()).then()
    onClose && onClose()
  }

  async function readyPopupOrder() {
    userFLow(`ready popup order`, {
      username: loginUser()?.name
    });
    readyOrder(popupOrder()).then()
    onClose && onClose()
  }

  async function completePopupOrder() {
    userFLow(`complete popup order`, {
      username: loginUser()?.name
    });
    completeOrder(popupOrder()).then()
    onClose && onClose()
  }

  // @ts-ignore
  const orderId = order && (
    (order.provider === MarketPlaceProvider.DELIVERECT && order?.metadata?.channelOrderDisplayId) ||
    (order.provider === MarketPlaceProvider.UBER_EATS && order.externalId && order.externalId.substring(order.externalId.length - 6)) ||
    order.externalId ||
    order.id)

  const orderTime = useComputed(() => {
    if (popupOrder().pickupDate === 'asap') return 'asap'
    if (companyInfo0()?.backendLanguage === 'de')
      return smartFormatShortDateTime(popupOrder().pickupDate) || ''
    return smartFormatShortDateTime(popupOrder().pickupDate, 'hh:mm A') || ''
  })

  const payments = _.map(popupOrder().payments, pm => _.toUpper(pm.extraType))
  const isPaid = payments.length && !payments.includes('CASH')

  return (
    <div className="relative bg-basic-color-white w-full min-w-[350px] overflow-y-scroll flex flex-col items-start justify-start pt-4 px-6 pb-6 box-border gap-[24px] h-full max-w-full max-h-full text-left text-sm text-gray font-mulish">
      <div className="self-stretch relative text-[22px] font-semibold Tablet_600:text-lg">
        {LL0().onlineOrder.orderDetails()}
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-2 text-royalblue-100">
          <div className="flex flex-row items-center justify-start gap-[8px]">
            {(
              isDeliveryOrder
              ? <DeliveryIcon/>
                : isPickupOrder
                  ? <PickupIcon/>
                  : <InHouse/>
            )}
            <div className="relative font-semibold">#{orderId}</div>
          </div>
          <div className="relative text-[20px] font-extrabold text-gray text-right Tablet_600:text-base">
            {orderTime()}
          </div>
        </div>
        { isInHouse ?
          <div className="w-full flex-1 flex flex-row items-center justify-start gap-[2px] text-xs text-black">
            <div className="relative text-gray">Table: {popupOrder().table}</div>
          </div> :
          <div className="w-full flex-1 flex flex-row items-center justify-start gap-[2px] text-xs text-black">
            <div className="relative text-gray">{popupOrder().customerRaw?.name} - {popupOrder().customerRaw?.phone}</div>
          </div>
        }
        {isDeliveryOrder && (<>
            {customerAddress ? <div className="self-stretch flex flex-row items-start justify-start text-black z-[8]">
              <img
                className="relative w-[18px] h-[18px]"
                alt=""
                src="/pin-icon.svg"
              />
              <div className="flex-1 relative leading-[117.4%] sm:text-smi">
                {customerAddress}
              </div>
            </div> : null}
            {
              popupOrder()?.shippingData?.comment && <div className="text-black self-stretch">
                Delivery note: {popupOrder()?.shippingData?.comment}
              </div>
            }
            {
              popupOrder()?.shippingData?.service && <div className="text-black self-stretch">
                Delivery service: {popupOrder()?.shippingData?.service === DeliveryService.PIKADRIVE ? 'inhouse' : popupOrder()?.shippingData?.service}
              </div>
            }
          </>
        )}
        {popupOrder().note && <div className="self-stretch flex flex-row items-start justify-start gap-[12px] text-black">
          {popupOrder().note}
        </div>}
        <OrderList {...popupOrder()} showPrice/>
        {/*{popupOrder().items.map((item, index) => <div key={index} className="self-stretch flex flex-row items-center justify-between">*/}
        {/*  <div className="flex flex-row items-center justify-start gap-[8px]">*/}
        {/*    <b className="relative flex items-center w-5 shrink-0 Tablet_600:text-smi">*/}
        {/*      {item.quantity}x*/}
        {/*    </b>*/}
        {/*    <div className="relative Tablet_600:text-smi">{item.name}</div>*/}
        {/*  </div>*/}
        {/*  <div className="relative Tablet_600:text-smi">{item.price} €</div>*/}
        {/*</div>)}*/}
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <div className="relative Tablet_600:text-smi">{LL0().onlineOrder.total()}</div>
            <b className="relative Tablet_600:text-smi">{_.sumBy(popupOrder().items, item => item.quantity)}</b>
            <div className="relative Tablet_600:text-smi">{LL0().onlineOrder.items()}</div>
          </div>
          <DottedLine/>
          <div className="relative text-xs">{LL3().format.currency(popupOrder().vSubTotal || 0)}</div>
        </div>
        {
          popupOrder().discount ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().common.discount()}</div>
            <DottedLine/>
            <div className="relative text-xs text-right">-{LL3().format.currency(popupOrder().discount || 0)}</div>
          </div> : null
        }
        <>
          {
            _.map(popupOrder().discountDetails, dd => <div className="self-stretch flex items-center" key={dd.name}>
              <div className="relative text-xs text-[#777] ml-[20px]">{dd.name}</div>
              <DottedLine/>
              <div className="relative text-xs text-right">-{LL3().format.currency(dd.value || 0)}</div>
            </div>)
          }
        </>
        {
          popupOrder().taxTotal
            ? <div className="self-stretch flex items-center">
              <div className="relative">{LL0().pendingOrder.totalTax()}</div>
              <DottedLine/>
              <div className="relative text-xs text-right">{LL3().format.currency(popupOrder().taxTotal || 0)}</div>
            </div>
            : <div className="self-stretch flex items-center">
              <div className="relative">{LL0().pendingOrder.totalTax()}</div>
              <DottedLine/>
              <div
                className="relative text-xs text-right">
                {/*{LL3().format.currency((_.sum(_.map(popupOrder().vTaxSum, ({ tax }) => tax)) + (popupOrder().vExtraTax || 0)) || 0)}</div>*/}
                {LL3().format.currency((tax + (popupOrder().vExtraTax || 0)) || 0)}</div>
            </div>
        }
        {
          popupOrder().donation ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().payment.donation()}</div>
            <DottedLine/>
            <div className="relative text-xs text-right">{LL3().format.currency(popupOrder().donation || 0)}</div>
          </div> : null
        }
        {
          popupOrder().tip ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().payment.tip()}</div>
            <DottedLine/>
            <div className="relative text-xs text-right">{LL3().format.currency(popupOrder().tip || 0)}</div>
          </div> : null
        }
        {
          /* delivery cost */
          popupOrder().shippingData?.serviceFee ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().pendingOrder.deliveryServiceFee()}</div>
            <DottedLine/>
            <div
              className="relative text-xs text-right">{LL3().format.currency(popupOrder().shippingData?.serviceFee || 0)}</div>
          </div> : null
        }
        {
          /* delivery fee */
          popupOrder().shippingData?.fee ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().payment.deliveryFee()}</div>
            <DottedLine/>
            <div
              className="relative text-xs text-right">{LL3().format.currency(popupOrder().shippingData?.fee || 0)}</div>
          </div> : null
        }
        {
          /* serviceFee */
          popupOrder().serviceFee ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().payment.serviceFee1()}</div>
            <DottedLine/>
            <div className="relative text-xs text-right">{LL3().format.currency(popupOrder().serviceFee || 0)}</div>
          </div> : null
        }
        {
          popupOrder().bagFee ? <div className="self-stretch flex items-center">
            <div className="relative">{LL0().pendingOrder.bagFee()}</div>
            <DottedLine/>
            <div className="relative text-xs text-right">{LL3().format.currency(popupOrder().bagFee || 0)}</div>
          </div> : null
        }
        <div className="self-stretch flex flex-row justify-between items-center">
          <div className="relative font-extrabold Tablet_600:text-smi">{LL0().payment.total()}</div>
          <DottedLine/>
          <div className="flex flex-row items-start justify-start py-0 pr-0 pl-2 text-right">
            <div className="relative font-extrabold Tablet_600:text-smi">
              {LL3().format.currency(popupOrder().vTotal || 0)}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row justify-between items-center">
          <div className="relative font-extrabold Tablet_600:text-smi">Payment</div>
          <DottedLine/>
          <div className="relative font-extrabold Tablet_600:text-smi uppercase">
            {isPaid ? LL0().onlineOrder.paid() : LL0().onlineOrder.unpaid()}
          </div>
        </div>
      </div>

      <div className="self-stretch gap-[8px] text-center text-lg text-basic-color-white flex flex-row flex-wrap">
        <div
          className="cursor-pointer rounded bg-materialize-red-lighten-2 flex-grow flex flex-row items-center justify-center p-4 relative">
          <div className="relative font-semibold" onClick={cancelPopupOrder}>{LL0().onlineOrder.cancelOrder()}</div>
        </div>
        {
          provider !== MarketPlaceProvider.RESTABLO ? (
            <div className="cursor-pointer rounded bg-lightgreen flex-grow flex flex-row items-center justify-center p-4 relative">
              <div className="relative font-semibold"
                   onClick={readyPopupOrder}>{LL0().pendingOrder.notifyOrderIsReady()}</div>
            </div>
          ) : null
        }
        <div className="cursor-pointer rounded bg-royalblue-100 flex-grow flex flex-row items-center justify-center p-4 relative">
          <div className="relative font-semibold" onClick={completePopupOrder}>{LL0().onlineOrder.completeOrder()}</div>
        </div>
      </div>
    </div>
  );
};

export default PopupOrderDetailsPlugin;
