import type { RxCollection, RxJsonSchema } from 'rxdb'

import type { OrderStrip } from '@/pos/OrderType'

export const ROOMS_COLLECTION_NAME = 'rooms'
export const ROOM_OBJECTS_COLLECTION_NAME = 'room_objects'

const VERSION = 7

export type RoomObjectType = 'table' | 'wall' | 'tree'
export interface RoomObject {
  _id: string
  name?: string
  type?: RoomObjectType
  location?: { x: number; y: number; order: number }
  size?: { width: number; height: number }
  bgColor?: string
  partition?: string
  takeAway?: boolean
  borderRadius?: { topLeft: number; topRight: number; bottomLeft: number; bottomRight: number }
  isTrusted?: boolean
  shape?: string
  room?: string
  isTransparent?: boolean
  busy?: boolean
  /** This will be set when entering a table */
  ref?: OrderStrip
}
export interface Room {
  _id: string
  name?: string
  order?: number
  roomObjects?: RoomObject[]
  alignModeEnable?: boolean
  partitionModeEnable?: boolean
}

export const roomSchema: RxJsonSchema<Room> = {
  title: 'room schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  // @ts-expect-error We don't want to update the schema
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    order: { type: 'number' },
    alignModeEnable: {type: 'boolean'},
    partitionModeEnable: {type: 'boolean'},
  },
  indexes: [],
}

export const roomObjectSchema: RxJsonSchema<RoomObject> = {
  title: 'room schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 50 },
    type: { type: 'string' },
    partition: { type: 'string' },
    location: {
      type: 'object',
      properties: {
        x: { type: 'integer' },
        y: { type: 'integer' },
        order: { type: 'integer' },
      },
    },
    size: {
      type: 'object',
      properties: {
        width: { type: 'integer' },
        height: { type: 'integer' },
      },
    },
    bgColor: { type: 'string' },
    takeAway: { type: 'boolean' },
    borderRadius: {
      type: 'object',
      properties: {
        topLeft: { type: 'integer' },
        topRight: { type: 'integer' },
        bottomLeft: { type: 'integer' },
        bottomRight: { type: 'integer' },
      },
    },
    isTrusted: { type: 'boolean' },
    shape: { type: 'string' },
    room: { type: 'string', maxLength: 24 },
    isTransparent: { type: 'boolean' },
    ref: { type: 'object' },
    busy: { type: 'boolean' },
  },
  indexes: ['name', 'room'],
}

export const Room = {} as RxCollection<Room>
export const RoomObject = {} as RxCollection<RoomObject>

Object.assign(window, { Room, RoomObject }) // Make available global
