import type { FunctionComponent } from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils";
import { menuTaxResult1, menuTaxResult2, printerResult, taxResult } from "@edit-menu/EditMenuForm/EditMenuForm.tsx";
import { multipleMenus0 } from "@/react/EditMenuView/EditPricingLevel.logic.ts";

import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

type PopupRemind = {
  onClose?: () => void;
  onConfirmed?: () => void
};

const PopupRemind: FunctionComponent<
  PopupRemind
> = ({ onClose, onConfirmed }) => {


  return (
    <div
      className="rounded-2xl bg-basic-color-white shadow-[0px_0px_4px_rgba(0,_0,_0,_0.1)] [backdrop-filter:blur(30px)] w-[700px] overflow-hidden flex flex-col items-center justify-start p-6 box-border gap-[16px] min-w-[360px] max-w-full max-h-full text-center text-xl text-blue-solid-blue-200-017efa font-mulish-button-name-bold">
      <div className="self-stretch flex flex-col items-center justify-start py-0.5 gap-[4px]">
        <b className="self-stretch relative leading-[28px] text-center mb-2">
          {LL0().ui.reminder()}
        </b>
      </div>
      <div className="self-stretch no-scrollbar overflow-y-auto flex flex-col justify-start py-0.5 gap-[4px]">
        <div>
          <div
            className="self-stretch relative text-left text-base leading-[28px] font-light italic text-green-600">
            {`${LL0().dialogs.reminder.exit()} ${LL0().ui.ok()}`}
          </div>
          <div
            className="self-stretch relative text-left text-base leading-[28px] font-light italic text-green-600 mb-4">
            {`${LL0().dialogs.reminder.remain()} ${LL0().ui.cancel()}`}
          </div>
        </div>
        {printerResult()?.length > 0 && (
          <div>
            <div
              className="self-stretch relative text-left text-base leading-[28px] font-bold text-blue-solid-blue-900-081a51">
              {LL0().dialogs.reminder.lackPrinter()}
            </div>
            <div
              className="self-stretch relative text-left text-base leading-[28px] font-medium text-blue-solid-blue-900-081a51 mb-4"
              style={{ userSelect: 'text' }}>
              {`${printerResult()}`}
            </div>
          </div>
        )}
        {taxResult()?.length > 0 && (
          <div>
            <div
              className="self-stretch relative text-left text-base leading-[28px] font-bold text-blue-solid-blue-900-081a51">
              {LL0().dialogs.reminder.taxForMenu()}
            </div>
            <div
              className="self-stretch relative text-left text-base leading-[28px] font-medium text-blue-solid-blue-900-081a51 mb-4"
              style={{ userSelect: 'text' }}>
              {`${taxResult()}`}
            </div>
          </div>
        )}
        {generalSetting0()?.taxType === 'two' && (
          <div>
            {menuTaxResult1()?.length > 0 && menuTaxResult1()?.map((menu, index) => {
                const menuKey = Object.keys(menu)[0];
                const menuValues = menu[menuKey];
                const menuName = multipleMenus0()?.find(m => m._id === menuKey)?.name;
                return (
                  <div key={index}>
                    <div
                      className="self-stretch relative text-left text-base leading-[28px] font-bold text-blue-solid-blue-900-081a51">
                      {`${LL0().dialogs.reminder.dineInTaxForMenu({menuName})}:`}
                    </div>
                    <div
                      className="self-stretch relative text-left text-base leading-[28px] font-medium text-blue-solid-blue-900-081a51 mb-4"
                      style={{ userSelect: 'text' }}>
                      {`${menuValues}`}
                    </div>
                  </div>
                )
              }
            )}
            {menuTaxResult2()?.length > 0 && menuTaxResult2()?.map((menu, index) => {
                const menuKey = Object.keys(menu)[0];
                const menuValues = menu[menuKey];
                const menuName = multipleMenus0()?.find(m => m._id === menuKey)?.name;
                return (
                  <div key={index}>
                    <div
                      className="self-stretch relative text-left text-base leading-[28px] font-bold text-blue-solid-blue-900-081a51">
                      {`${LL0().dialogs.reminder.takeAwayTaxForMenu({menuName})}:`}
                    </div>
                    <div
                      className="self-stretch relative text-left text-base leading-[28px] font-medium text-blue-solid-blue-900-081a51 mb-4"
                      style={{ userSelect: 'text' }}>
                      {`${menuValues}`}
                    </div>
                  </div>
                )
              }
            )}
          </div>
        )}
        {generalSetting0()?.taxType === 'one' && (
          <div>
            {menuTaxResult2()?.length > 0 && menuTaxResult2()?.map((menu, index) => {
                const menuKey = Object.keys(menu)[0];
                const menuValues = menu[menuKey];
                const menuName = multipleMenus0()?.find(m => m._id === menuKey)?.name;
                return (
                  <div key={index}>
                    <div
                      className="self-stretch relative text-left text-base leading-[28px] font-bold text-blue-solid-blue-900-081a51">
                      {`${LL0().dialogs.reminder.oneTaxForMenu({menuName})}:`}
                    </div>
                    <div
                      className="self-stretch relative text-left text-base leading-[28px] font-medium text-blue-solid-blue-900-081a51 mb-4"
                      style={{ userSelect: 'text' }}>
                      {`${menuValues}`}
                    </div>
                  </div>
                )
              }
            )}
          </div>
        )}
      </div>
      <div
        className="w-full flex flex-row items-center justify-center gap-[20px] max-w-[312px] text-sm text-blue-solid-blue-200-017efa mx-12">
        <button ref={makeRipple}
                className="flex-1 rounded-md bg-basic-color-white overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 cursor-pointer border-[1px] border-solid border-blue-solid-blue-200-017efa"
                onClick={onClose}
        >
          <b className="flex-1 relative">{LL0().ui.cancel()}</b>
        </button>
        <button ref={makeRipple} onClick={onConfirmed}
                className="flex-1 rounded-md [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 text-basic-color-white">
          <b className="relative">{LL0().ui.ok()}</b>
        </button>
      </div>
    </div>
  );
};

export default PopupRemind;