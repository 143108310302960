import { context } from '@opentelemetry/api'
import axios from 'axios'
import debug from 'debug'
import pTimeout from 'p-timeout'

import { posSync0 } from '@/data/PosSyncState.ts'
import { createSpan } from '@/lib/open-telemetry.ts'
import { getDeviceId } from '@/shared/getDeviceId'
import { ComlinkError, ensureComlinkWorking } from '@/shared/webview/rnwebview'

import { WebSrmInvalidResponseError } from '../errors'
import { isCertificateResponse, isDocumentResponse, isTransactionResponse, isUserResponse } from '../response-validators'
import { CONSTANT_VALUES } from './constants'
import type { SrmRelaySchema } from './schema'
import { formatDateForJson } from './timezone'
import type {
  CertificateRequestBody,
  CertificateResponse,
  DocumentData,
  DocumentReqestBody,
  DocumentResponseBody,
  RequestHeaders,
  RequestHeadersWithTax,
  SDK,
  SignedDocumentData,
  SignedTransactionData,
  SignedTransactionRequestHeaders,
  TransactionData,
  TransactionRequestBody,
  TransactionResponseBody,
  UserRequestBody,
  UserResponseBody,
} from './types'
import { encryptWithCertificate, getCertificateThumb, signWithPrivateKey } from './utils.cert'

const log = debug('data:srm')

export const QUEBEC_WEBSRM_BASE_URL = {
  dev: 'https://cnfr.api.rq-fo.ca',
  prod: 'https://api.rq-fo.ca',
} as const
export const QUEBEC_WEBSRM_CERT_BASE_URL = {
  dev: 'https://certificats.cnfr.api.rq-fo.ca',
  prod: 'https://certificats.api.rq-fo.ca',
} as const

export const QUEBEC_WEBSRM_QRCODE_BASE_URL = {
  dev: 'https://cnfr.qr.mev-web.ca',
  prod: 'https://qr.mev-web.ca',
} as const

export interface SdkOption {
  /** Private Key */
  key?: string
  /** Certificate issued by Quebec (including Public Key) */
  cert?: string
  /** Certificate of WEB-SRM, used for signing QRCode */
  certWebSRM?: string
  /** Authenticatein Code, provided separatly by Quebec via email. */
  authCode?: string
  /** Url for the relay server. Should point to our manage's `api/quebec-srm/relay`. */
  relayUrl?: string
  /** Relay function to send data to Quebec's WEB-SRM. */
  relayFn?: (data: SrmRelaySchema) => Promise<unknown>
  /** Default timeout setting. */
  timeout?: number
  /** Turn on Production mode */
  isProd?: boolean
  /** Verbose logging */
  debug?: boolean
}

enum RelayMode {
  relayFn = 'relayFn',
  relayUrl = 'relayUrl',
}

export class QuebecSDK implements SDK {
  #isProd: boolean
  /** Default to 15s */
  #timeout: number
  #authCode?: string
  #key?: string
  #cert?: string
  #certWebSRM?: string
  #relayFn?: (data: SrmRelaySchema) => Promise<unknown>
  #relayUrl?: string
  #certThump?: string
  #debug?: boolean

  constructor(option?: SdkOption) {
    this.#isProd = option?.isProd ?? false
    this.#timeout = option?.timeout ?? 15_000
    this.#key = option?.key
    this.#cert = option?.cert
    this.#certWebSRM = option?.certWebSRM
    this.#authCode = option?.authCode
    this.#relayFn = option?.relayFn
    this.#relayUrl = option?.relayUrl
    this.#debug = option?.debug
    this.#certThump = getCertificateThumb(this.#cert)
  }

  async #send(relayData: SrmRelaySchema, mode = RelayMode.relayFn, retryCount = 0): Promise<unknown> {
    const span = createSpan(`Sending relay data`, undefined, context.active())
    span?.setAttribute('storeId', `${posSync0()?.id ?? '?'}`)
    span?.setAttribute('deviceId', getDeviceId())
    span?.setAttribute('relayData', JSON.stringify(relayData))
    span?.setAttribute('retryCount', retryCount)
    const { url, body, headers } = relayData
    const baseUrl =
      // Use specific base url for certificate enrolement
      url === '/enrolement' ? (this.#isProd ? QUEBEC_WEBSRM_CERT_BASE_URL.prod : QUEBEC_WEBSRM_CERT_BASE_URL.dev) : this.#isProd ? QUEBEC_WEBSRM_BASE_URL.prod : QUEBEC_WEBSRM_BASE_URL.dev
    const finalUrl = baseUrl + url

    if (this.#debug) console.log(`Relaying request... ${retryCount > 0 ? `(#${retryCount + 1} attempt)` : ''}`)

    try {
      if (mode === RelayMode.relayFn && !this.#relayFn) throw new Error('Relay function unavailable!')
      if (mode === RelayMode.relayUrl && !this.#relayUrl) throw new Error('Relay URL unavailable!')

      if (mode === RelayMode.relayFn && this.#relayFn) {
        await ensureComlinkWorking()
        const res = await pTimeout(
          this.#relayFn({
            url: finalUrl,
            key: this.#key,
            cert: this.#cert,
            headers,
            body,
          }),
          { milliseconds: this.#timeout }
        )
        span?.setAttribute('res', JSON.stringify(res))
        span?.end()
        return res
      }

      if (mode === RelayMode.relayUrl && this.#relayUrl) {
        const res = await pTimeout(
          axios
            .post(
              this.#relayUrl,
              {
                url: finalUrl,
                key: this.#key,
                cert: this.#cert,
                headers,
                body,
              },
              { timeout: this.#timeout }
            )
            .then(r => r.data),
          { milliseconds: this.#timeout }
        )
        span?.setAttribute('res', JSON.stringify(res))
        span?.end()
        return res
      }

      throw new Error(`Unsupported mode: ${mode}`)
    } catch (e) {
      log(`Relay failed in ${retryCount + 1} attempt, using mode ${mode}: ${e instanceof Error ? e.message : JSON.stringify(e)}`)
      span?.recordException(e instanceof Error ? e : new Error(JSON.stringify(e)))
      // Use relay url if Comlink failed
      if (e instanceof ComlinkError) return this.#send(relayData, RelayMode.relayUrl, retryCount + 1)
      throw e
    } finally {
      span?.end()
    }
  }

  /**
   * Calulate the signature using Trans data
   */
  #calculateTransactionSignature(trans: TransactionData, previousTransactionSignature: string = CONSTANT_VALUES.emptySig) {
    if (!this.#key) throw new Error('Missing key to sign!')
    const content = [
      trans.noTrans,
      trans.datTrans,
      trans.mont.TPS,
      trans.mont.TVQ,
      trans.mont.apresTax,
      trans.noTax.noTPS,
      trans.noTax.noTVQ,
      trans.modImpr,
      trans.modTrans,
      previousTransactionSignature,
    ].join('')

    return signWithPrivateKey(this.#key, content)
  }

  /**
   * Calulate the signature using Doucment data
   */
  #calculateDocumentSignature(doc: DocumentData) {
    if (!this.#key) throw new Error('Missing key to sign!')
    const arr: string[] = []
    let content = ''
    switch (doc.type) {
      case 'RUT':
        arr.push(
          ...[
            doc.RT, // GST registration number
            doc.TQ, // QST registration number
            doc.UT, // Name of the mandatary or the first name and last name of the person linked to the user account
            doc.NO, // Transaction number of the last bill
            doc.MT, // After-tax amount of the last bill
            doc.DF, // Date and time the last bill was produced
            doc.AN, // Year (current or preceding) the transactions in the sales summary were recorded
            doc.SN, // Total number of transactions
            doc.SV, // Number of payment transactions
            doc.SS, // Before-tax amount from the sales summary
            doc.SF, // GST amount from the sales summary
            doc.SP, // QST amount from the sales summary
            doc.ST, // After-tax amount from the sales summary
            doc.IA, // Unique device identifier
            doc.IS, // Unique SRS identifier
            doc.VR, // SRS version number assigned by the developer
            doc.DC, // Date and time the user logged into their account
            doc.DR, // Date and time the user report was produced
          ]
        )
        if (this.#debug) console.log('ℹ️ Calculate doc RUT signature...', arr)
        content = arr.join('')
        break
      case 'HAB':
        arr.push(
          ...[
            `TQ=${doc.TQ}`,
            `NM=${doc.NM}`,
            `AD=${doc.AD}`,
            `CP=${doc.CP}`,
            `TE=${doc.TE}`,
            `PO=${doc.PO}`,
            `NE=${doc.NE}`,
            `RA=${doc.RA}`,
            `DC=${doc.DC}`,
            `DV=${doc.DV}`,
            `DE=${doc.DE}`,
            `BS=${doc.BS}`,
            `FR=${doc.FR}`,
            `MO=${doc.MO}`,
          ]
        )
        if (this.#debug) console.log('ℹ️ Calculate doc HAB signature...', arr)
        content = arr.join(';')
        break
      default:
        throw new Error('Unsupported doc')
    }
    return signWithPrivateKey(this.#key, content)
  }

  #concatenateDocumnet(doc: SignedDocumentData) {
    let content = ''
    switch (doc.type) {
      case 'RUT':
        content = [
          `RT=${doc.RT}`,
          `TQ=${doc.TQ}`,
          `UT=${doc.UT}`,
          `NO=${doc.NO}`,
          `MT=${doc.MT}`,
          `DF=${doc.DF}`,
          `AN=${doc.AN}`,
          `SN=${doc.SN}`,
          `SV=${doc.SV}`,
          `SS=${doc.SS}`,
          `SF=${doc.SF}`,
          `SP=${doc.SP}`,
          `ST=${doc.ST}`,
          `SA=${doc.SA}`,
          `SD=${doc.SD}`,
          `TS=${doc.TS}`,
          `SR=${doc.SR}`,
          `CM=${doc.CM}`,
          `IA=${doc.IA}`,
          `IS=${doc.IS}`,
          `VR=${doc.VR}`,
          `DC=${doc.DC}`,
          `DR=${doc.DR}`,
          `SI=${doc.SI}`,
          `EM=${doc.EM}`,
          `AD=${doc.AD}`,
        ].join(';')
        break
      case 'HAB':
        content = [
          `TQ=${doc.TQ}`,
          `NM=${doc.NM}`,
          `AD=${doc.AD}`,
          `CP=${doc.CP}`,
          `TE=${doc.TE}`,
          `PO=${doc.PO}`,
          `NE=${doc.NE}`,
          `RA=${doc.RA}`,
          `DC=${doc.DC}`,
          `DV=${doc.DV}`,
          `DE=${doc.DE}`,
          `BS=${doc.BS}`,
          `FR=${doc.FR}`,
          `MO=${doc.MO}`,
          `SI=${doc.SI}`,
          `EM=${doc.EM}`,
        ].join(';')
        break
      default:
        throw new Error('Unsupported doc')
    }
    if (this.#debug) console.log('ℹ️ Concatenated doc content...', content)
    return content
  }

  async #calculateTransactionHeaderSignature(deviceId: string, tranasctionSignature: string) {
    if (!this.#key) throw new Error('Missing key to sign!')
    if (!this.#authCode) throw new Error('Missing AuthCode!')
    const content = [this.#authCode, deviceId, tranasctionSignature].join('')
    return await signWithPrivateKey(this.#key, content)
  }

  async certificate(headers: RequestHeaders, body: CertificateRequestBody): Promise<CertificateResponse> {
    const url = body.reqCertif.modif === 'AJO' ? '/enrolement' : '/certificats'
    if (this.#debug) console.log('🪲 Certificate Body', body)
    const res = await this.#send({ url, body, headers })
    if (this.#debug) console.log('🪲 Certificate Response', res)
    if (!isCertificateResponse(res)) throw new WebSrmInvalidResponseError(res)
    return res
  }

  async user(headers: RequestHeaders, body: UserRequestBody): Promise<UserResponseBody> {
    if (this.#debug) console.log('ℹ️ User request body', body)
    const res = await this.#send({ url: '/utilisateur', body, headers })
    if (this.#debug) console.log('🪲 User Response', res)
    if (!isUserResponse(res)) throw new WebSrmInvalidResponseError(res)
    return res
  }

  async transactions(headers: RequestHeadersWithTax, data?: SignedTransactionData, batch?: SignedTransactionData[]): Promise<TransactionResponseBody> {
    if (!this.#certThump) throw new Error('Missing certificate info!')
    const body: TransactionRequestBody = {
      reqTrans: {
        transActu: data,
        transLot: batch?.length
          ? // The most recent transaction must be the first in the batch.
            batch.sort((a, b) => -1 * a.datTrans.localeCompare(b.datTrans))
          : undefined,
      },
    }
    if (this.#debug) console.log('🪲 Transaction Body', body)

    /**
     * **IMPORTANT**
     *
     * When the SRS sends a batch of transactions, the server must concatenate
     * all the digital signatures of the transactions in the batch **in order
     * from the oldest to the current transaction.**
     */
    const tSig = [...(batch ?? []), data]
      .filter((a): a is SignedTransactionData => !!a)
      .sort((a, b) => a.datTrans.localeCompare(b.datTrans)) // NOTE: This sort is different with the one in the `body`
      .map(a => a.signa.actu)
      .join('')
    if (!tSig) throw new Error('No transaction!')
    if (this.#debug) console.log('🪲 Concatenated Signature', tSig)

    const signedHeaders: SignedTransactionRequestHeaders = {
      ...headers,
      APPRLINIT: 'SEV',
      EMPRCERTIFTRANSM: this.#certThump,
      SIGNATRANSM: await this.#calculateTransactionHeaderSignature(headers.IDAPPRL, tSig),
    }
    if (this.#debug) console.log('🪲 Signed Headers', Date.now(), signedHeaders)

    const res = await this.#send({
      url: '/transaction',
      body,
      headers: signedHeaders,
    })
    if (this.#debug) console.log('🪲 Transaction Response', Date.now(), res)
    if (!isTransactionResponse(res)) throw new WebSrmInvalidResponseError(res)
    return res
  }

  async document(headers: RequestHeadersWithTax, data: SignedDocumentData): Promise<DocumentResponseBody> {
    const body: DocumentReqestBody = {
      reqDoc: {
        typDoc: data.type,
        doc: this.#concatenateDocumnet(data),
      },
    }
    if (this.#debug) console.log('ℹ️ Document request body', body)
    const res = await this.#send({ url: '/document', body, headers })
    if (!isDocumentResponse(res)) throw new WebSrmInvalidResponseError(res)
    return res
  }

  async generateQRCode(data: SignedTransactionData): Promise<string> {
    if (!this.#certWebSRM) throw new Error('Missing cert to sign QRCode!')
    const content = [
      data.emprCertifSEV,
      data.datTrans,
      data.mont.TPS,
      data.mont.TVQ,
      data.mont.apresTax,
      data.mont.mtdu,
      data.noTax.noTPS,
      data.noTax.noTVQ,
      data.modImpr,
      data.modTrans,
      data.signa.actu,
      data.signa.preced,
      data.noTrans.padStart(10, '='),
    ].join('')
    const sig = await encryptWithCertificate(this.#certWebSRM, content)
    const baseUrl = this.#isProd ? QUEBEC_WEBSRM_QRCODE_BASE_URL.prod : QUEBEC_WEBSRM_QRCODE_BASE_URL.dev
    return `${baseUrl}?f=${encodeURIComponent(sig)}`
  }

  async generateReportQRCode(d: SignedDocumentData): Promise<string> {
    if (d.type !== 'RUT') throw new Error('Unsupported report type!')

    const qrcodeContent = [
      `RT=${d.RT}`, // GST registration number
      `TQ=${d.TQ}`, // QST registration number
      `UT=${d.UT}`, // Name of the mandatary or the first name and last name of the person linked to the user account
      `NO=${d.NO}`, // Transaction number of the last bill
      `MT=${d.MT}`, // After-tax amount of the last bill
      `DF=${d.DF}`, // Date and time the last bill was produced
      `IA=${d.IA}`, // Unique device identifier
      `IS=${d.IS}`, // Unique SRS identifier
      `VR=${d.VR}`, // SRS version number assigned by the developer
      `DC=${d.DC}`, // Date and time the user logged into their account
      `DR=${d.DR}`, // Date and time the user report was produced
      `SI=${d.SI}`, // Mandatary's digital signature
      `EM=${d.EM}`, // Thumbprint of the digital certificate linked with the private key that generated the digital signature
      `AD=${d.AD}`, // Address of the establishment
    ].join(';')
    return qrcodeContent
  }

  async signTransaction(data: TransactionData, lastSignature: string): Promise<SignedTransactionData> {
    if (!this.#certThump) throw new Error('Missing certificate info!')
    const signed: SignedTransactionData = {
      ...data,
      emprCertifSEV: this.#certThump,
      signa: {
        actu: await this.#calculateTransactionSignature(data, lastSignature),
        preced: lastSignature,
        datActu: formatDateForJson(new Date()),
      },
    }
    return signed
  }

  async signDocument(data: DocumentData): Promise<SignedDocumentData> {
    if (!this.#certThump) throw new Error('Missing certificate info!')
    const signed: SignedDocumentData = {
      ...data,
      EM: this.#certThump,
      SI: await this.#calculateDocumentSignature(data),
    }
    return signed
  }
}
