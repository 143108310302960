import assert from 'assert'

import debug from 'debug'

import { roundNumber } from '@/shared/order/order-config'
import { stringifyObj } from '@/shared/utils2'

import type { TransactionData } from './lib/types'

const log = debug('dev:transaction-assert')

export function assertTransaction(t: TransactionData, ref: string) {
  const net = Number(t.mont.avantTax)
  const gst = Number(t.mont.TPS)
  const qst = Number(t.mont.TVQ)
  const gross = Number(t.mont.apresTax)
  const ajus = Number(t.mont.ajus)
  const mtdu = Number(t.mont.mtdu)

  const amounts = { net, gst, qst, gross, ajus, mtdu }

  log('Asserting transaction...', { orderId: ref })
  console.table({ net, gst, qst, gross, ajus, mtdu })

  try {
    if (net === 0) assert(gst === 0 && qst === 0 && gross === 0, 'Something wrong when calculating order amount: NET = 0 but GST, QST or GROSS are not 0: ' + stringifyObj(amounts))
    assert(gross === roundNumber(net + gst + qst), 'Transaction amount is not correct apresTax should equal avantTax + TPS + TVQ : ' + stringifyObj(amounts))
    if (!isNaN(ajus)) {
      assert(!isNaN(mtdu), 'Transaction amount is not correct mtdu should be set when ajustement is set: ' + stringifyObj(amounts))
      assert(mtdu === roundNumber(gross + ajus), 'Transaction amount is not correct mtdu should equal apresTax + ajus: ' + stringifyObj(amounts))
    }
  } catch (e) {
    if (e instanceof Error) log(e.message, { orderId: ref, alert: true })
    else log('Unknown error' + stringifyObj(e as object), { orderId: ref, alert: true })

    // If the assertion failed, log it with alert, but still allow to proceed
    // throw new Error(LL0().srm.errors.failToMakeTransaction({ refCode: ref }))
  }
  // TODO: add more check here
}
