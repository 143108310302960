import type { FunctionComponent } from "react";
import OrderScreenCategoryItem from "./OrderScreenCategoryItem";
import { categories2 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export type OrderScreenAllCategoryPoType = {
  className?: string;
  onClose?: () => void;
};

const OrderScreenAllCategoryPo: FunctionComponent<
  OrderScreenAllCategoryPoType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-[400px] h-[381px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-[18px] px-0 pb-0 box-border max-w-full max-h-full text-left text-base text-black-solid-black-1000-000000 font-mulish ${className}`}
    >
      <div className="self-stretch overflow-hidden flex flex-row items-center justify-between py-0.5 px-4">
        <b className="flex-1 relative">{LL0().inventory.allCategory()}</b>
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon1@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-4 px-0 pb-0 box-border max-h-[278px]">
        <div className="self-stretch grid flex-row items-start justify-start flex-wrap content-start py-0.5 px-4 gap-2.5 grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))] sm:grid sm:grid-cols-[repeat(auto-fill,_minmax(128px,_1fr))]">
        {categories2().map((category, index) => (
            <OrderScreenCategoryItem
              key={index}
              backgroundColor={category?.layout?.color || '#fff'}
              categoryId={category._id}
              label={category.name}
              category={category}
            />
          ))}

        </div>
      </div>
      <div className="self-stretch bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start pt-2 px-4 pb-4 text-center text-sm text-white-solid-white-100-ffffff">
        <div className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border"
             onClick={onClose}
        >
          <b className="relative">{LL0().editOnlineMenu.select()}</b>
        </div>
      </div>
    </div>
  );
};

export default OrderScreenAllCategoryPo;