import {
  type FunctionComponent,
  useState,
  useMemo,
  type CSSProperties,
  useCallback,
} from "react";
import NewEditTerminalPopup from "./Popups/NewEditTerminalPopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import type { Terminal } from '@/data/Terminal.ts';
import {
  setTerminal0,
  terminal0, updateTerminal
} from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import {test} from "@/react/Terminal";
import dialogService from "@/react/SystemService/dialogService.tsx";
import { toast } from "react-toastify";

export type TaxPaymentTerminalRowType = {
  terminal: Terminal
  /** Style props */
  taxPaymentTerminalRowBackgroundColor?: CSSProperties["backgroundColor"];
};

const TaxPaymentTerminalRow: FunctionComponent<TaxPaymentTerminalRowType> = ({
  terminal,
  taxPaymentTerminalRowBackgroundColor,
}) => {
  const {_id, name, type} = terminal || {};
  const [isNewEditTerminalPopupOpen, setNewEditTerminalPopupOpen] = useState(false);
  const taxPaymentTerminalRowStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: taxPaymentTerminalRowBackgroundColor,
    };
  }, [taxPaymentTerminalRowBackgroundColor]);

  const testTerminal = useCallback(async () => {
    const closeProgress = dialogService.progress({title: 'Testing terminal'});
    console.log('testing terminal', terminal);
    try {
      const response = await test(_id, (progress: string) => toast.info(progress));
      if (response?.error) {
        toast.error(response?.error, {})
      } else {
        console.log(response)
      }
    } catch (e: any) {
      toast.error(e, {})
    }
    closeProgress()
  }, [])
  const openNewEditTerminalPopup = useCallback(() => {
    setTerminal0(terminal);
    setNewEditTerminalPopupOpen(true);
  }, []);

  const saveChange = useCallback(() => {
    updateTerminal(terminal0()!)
    setNewEditTerminalPopupOpen(false);
  }, [])

  const closeNewEditTerminalPopup = useCallback(() => {
    setNewEditTerminalPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className="bg-gray-solid-gray-180-ebecee flex flex-row items-center justify-between py-1.5 px-[11px] box-border text-left text-sm text-grey-grey-darken-3 font-mulish self-stretch"
        style={taxPaymentTerminalRowStyle}
      >
        <div className="flex-1 relative font-semibold Tablet_768:text-smi">
          {name}
        </div>
        <div className="flex-1 relative font-semibold Tablet_768:text-smi">
          {type}
        </div>
        <div className="w-[104px] flex flex-row items-center justify-end gap-[0px_10px] text-center text-mini text-white-solid-white-100-ffffff">
          <div className="rounded-md bg-blue-solid-blue-420-2979ff box-border h-8 flex flex-row items-center justify-start py-0 px-4 border-[1px] border-solid border-blue-solid-blue-420-2979ff Tablet_600:h-6 Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:box-border"
               onClick={testTerminal}>
            <div className="relative font-semibold">Test</div>
          </div>
          <img
            className="w-8 relative h-8 object-cover cursor-pointer sm:w-[26px] sm:h-[26px] Tablet_600:w-[22px] Tablet_600:h-[22px]"
            alt=""
            src="/yellow-edit-icon1@2x.png"
            onClick={openNewEditTerminalPopup}
          />
        </div>
      </div>
      {isNewEditTerminalPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewEditTerminalPopup}
        >
          <NewEditTerminalPopup onClose={closeNewEditTerminalPopup} onComplete={saveChange} mode={'edit'}/>
        </PortalPopup>
      )}
    </>
  );
};

export default TaxPaymentTerminalRow;
