import React, { memo, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  checkDatePrinted,
  getOrderGross,
  order0,
  setOrder0
} from '@/react/OrderHistoryView/OrderHistoryView.logic.tsx';
import { useSelector } from '@/react/core/reactive.ts';
import type { OrderStrip } from '@/pos/OrderType.ts';
import { OrderStatus } from "@/pos/OrderType.ts";
import { LL0 } from '@/react/core/I18nService';
import PaymentMethod from "@order-history/PaymentMethod.tsx";
import clsx from 'clsx';
import _ from 'lodash'
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";


interface OrderHistoryTableRowProps {
  order?: OrderStrip;
  _id?: string;
  isEven?: boolean;
}

const orderStatusLabelMapper = {
  split: <div className="text-green-600">Split</div>,
  paid: <div className="text-green-600">Paid</div>,
  inProgress: <div className="text-blue-600">In Progress</div>,
  cancelled: <div className="text-materialize-red-accent-2">Declined</div>,
};

const OrderHistoryTableRow = memo(({ order, _id, isEven }: OrderHistoryTableRowProps) => {
  if (!order) return <></>

  const onClick = useCallback(() => {
    setOrder0(order);
  }, []);
  // compare between ids bc we are not sure if the fetched data is correct or not
  const isCurrentOrder = useSelector<OrderStrip, string | undefined>(order0, (p1, p2) => p1?._id === p2);
  // useEffect(() => {
  //   checkDatePrinted(order?.date!)
  // },[maxId0()?.lastEodCompleted!])
  return (
    // <tr className={clsx('text-gray-200 odd:bg-white bg-whitesmoke-200 border', isCurrentOrder(order._id) ? 'border-[2px] border-[#FF3797]' : 'border-[#1D1D26]')} onClick={onClick}>

    <>
      <div
        className={clsx('!text-[#1e1e23] !h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-row items-center justify-center py-[7px] px-1.5 relative text-smi text-text-color-black-1e1e23-400 border-b-[1px] border-solid border-black-solid-black-700-364150 sm:relative sm:min-w-[40px]', isEven && "bg-white")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date!)
        }}
      >
        <div className="relative">{order.id}</div>
      </div>
      <div
        className={clsx('!text-[#1e1e23] !h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-row items-center justify-center py-[7px] px-1.5 relative text-smi text-text-color-black-1e1e23-400 border-b-[1px] border-solid border-black-solid-black-700-364150 border-l-[1px] sm:relative', isEven && "bg-white")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date)
        }}
      >
        {order.date &&
          <div className="relative">{dayjs.unix(order.date || 0).format(`${LL2().dates.dateFormat()} ${LL2().dates.timeFormat()}`)}</div>
        }
      </div>
      <div
        className={clsx('!text-[#1e1e23] !h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-row items-center justify-center py-[7px] px-1.5 relative text-smi text-text-color-black-1e1e23-400 border-b-[1px] border-solid border-black-solid-black-700-364150 border-l-[1px] sm:relative', isEven && "bg-white")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date)
        }}
      >
        {order.date &&
          <div className="relative">{order.provider || ''}</div>
        }
      </div>
      <div
        className={clsx('!text-[#1e1e23] !h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-col items-center justify-center py-[7px] px-1.5 gap-[2px] relative text-smi text-text-color-black-1e1e23-400 border-b-[1px] border-solid border-black-solid-black-700-364150 border-l-[1px] Tablet_600:relative', isEven && "bg-white")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date)
        }}
      >
        <div className="flex flex-col items-center justify-center gap-[2px]">
          {order.table && <div className="relative">{LL0().orderHistory.tableNo()} {order.table}</div>}
          {order.type && <div
            className="relative">{_.get(LL0().onlineOrder, order.type)?.() ? _.get(LL0().onlineOrder, order.type)?.() : order.type}</div>}
          {order?.trainingMode && <div
            className="rounded-981xl bg-cyan-solid-cyan-250-8aeaff flex flex-row items-center justify-start p-0.5 text-left text-3xs text-black-solid-black-600-424242">
            <i
              className="relative [display:-webkit-inline-box] font-medium overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical] Tablet_600:text-5xs Mobile_480:text-5xs mq320:text-5xs">
              {LL0().settings.trainingMode()}
            </i>
          </div>}
        </div>
        <div className="relative hidden">TakeAway</div>
        <div className="hidden flex-row items-center justify-center gap-[2px]">
          <div className="relative">Pickup</div>
          <div className="relative">-Uber</div>
        </div>
        <div className="hidden flex-row items-center justify-center gap-[2px]">
          <div className="relative">Delivery</div>
          <div className="relative">-Uber</div>
        </div>
        <i className="relative leading-[16px] hidden text-red-solid-red-350-ec3323 text-left">
          Declined
        </i>
      </div>
      <div
        className={clsx('!text-[#1e1e23] !h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-row items-center justify-center py-[7px] px-1.5 relative text-smi text-text-color-black-1e1e23-400 border-b-[1px] border-solid border-black-solid-black-700-364150 border-l-[1px] Tablet_600:relative Mobile_480:hidden Mobile_480:relative mq320:hidden mq320:relative', isEven && "bg-white",
          [OrderStatus.CANCELLED, OrderStatus.CANCELLED_BEFORE_PAID].includes(order.status) && "line-through")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date)
        }}
      >
        {/*<div className="relative">{LL3().format.currency(order.vSum!)}</div>*/}
        <div className="relative">{LL3().format.currency(getOrderGross(order))}</div>
      </div>
      <div
        className={clsx('!h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-row items-center justify-center py-[7px] px-1.5 gap-[8px] relative text-smi text-gray-500 border-b-[1px] border-solid border-black-solid-black-700-364150 border-l-[1px]', isEven && "bg-white")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date)
        }}
      >
        <PaymentMethod isShowText={false} payments={order.payments} />
        {order.printInvoice &&
          <img
            className="relative w-[23px] h-[23px] object-cover"
            alt=""
            src="/iconorderhistoryprinted-icon@2x.png"
          />}
        <div className="relative hidden">-</div>
      </div>
      <div
        className={clsx('!h-full bg-gray-solid-gray-180-ebecee box-border h-11 flex flex-row items-center justify-center py-[7px] px-1.5 gap-[2px] relative text-smi text-text-color-black-1e1e23-400 border-b-[1px] border-solid border-black-solid-black-700-364150 border-l-[1px] sm:relative Tablet_768:hidden Tablet_768:relative Tablet_600:hidden Tablet_600:relative Mobile_480:hidden Mobile_480:relative mq320:hidden mq320:relative', isEven && "bg-white")}
        onClick={ () => {
          setOrder0(order)
          checkDatePrinted(order?.date)
        }}
      >
        <div className="relative hidden">Staff</div>
        <div className="relative text-cyan-solid-cyan-500-00a7cc">
          {order.users?.[0] || (order?.customerRaw?.name)}
        </div>
      </div>
    </>
  );
});

export default OrderHistoryTableRow;