import { LL0 } from '@/react/core/I18nService.tsx';
import dialogService from '@/react/SystemService/dialogService.tsx';

export const dialogWarningProduct = async (productName: string, onConfirm: () => void) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={''}>
      <div className="w-full max-w-[540px] relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center box-border gap-[14px] min-w-[280px] px-4 pb-2 h-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px] text-red-500 text-[20px]">⚠️{LL0().ui.warning()}</b>
          <div className="flex flex-col gap-1 justify-center">
            <b className="self-stretch relative text-[16px] leading-[28px] text-black">{LL0().warning.productNoTaxOrPrinter({ productName: productName || ''})}</b>
            <b className="self-stretch relative text-[16px] leading-[28px] text-black">{LL0().warning.doYouWantAddThisProduct()}</b>
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-end gap-[6px] text-sm">
          <div
            className="flex-1 rounded-md bg-white-solid-white-100-ffffff text-blue-solid-blue-420-2979ff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-1 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
            onClick={() => {
              onClose()
            }}
          >
            <b className="relative ">{LL0().ui.cancel()}</b>
          </div>
          <div
            className="flex-1 rounded-md bg-blue-600 box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-1 max-w-[100px] cursor-pointer"
            onClick={() => {
              onConfirm?.()
              onClose()
            }}
          >
            <b className="relative text-white">{LL0().ui.ok()}</b>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
