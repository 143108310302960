import type { FunctionComponent } from "react";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { inputController0 } from "@/react/core/Input.tsx";
import { useComputed, useSignal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import Autocomplete from "@/react/core/Autocomplete.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { products, sections } from "@/react/OrderView/OrderView.tsx";
import type { Product } from "@/data/Product.ts";

export type OrderScreenSearchItemType = {
  className?: string;
  onClose?: () => void;
  onConfirm?: (product: Product, layoutType: string, quantity: number) => void
}


const SearchItemVertical: FunctionComponent<OrderScreenSearchItemType> = ({
  className = "",
  onClose,
  onConfirm,
}) => {
  const [searchProduct, setSearchProduct] = useSignal<string>('');
  const [productName, setProductName] = useSignal<string>('');
  // const [selectedProductLayout, setSelectedProductLayout] = useSignal<ProductLayout>(undefined)
  const [selectedProduct, setSelectedProduct] = useSignal<Product>(undefined)


  // const suggestedProducts = useComputed(() => {
  //   const productLayoutsMap = productLayouts().reduce((acc, pl) => {
  //     if (pl.product) acc[pl.product] = pl;
  //     return acc;
  //   }, {} as Record<string, ProductLayout>);
  //
  //   const categoriesLayoutMap = layoutCategories0().reduce((map, category) => {
  //     category.products?.forEach(productLayout => {
  //       if (productLayout.product) {
  //         map[productLayout.product] = category.name;
  //       }
  //     });
  //     return map;
  //   }, {} as Record<string, string>);
  //
  //   return products0().filter(product => product._id in productLayoutsMap && product.id !== "")
  //     .map(product => ({ ...product,
  //                                                     productLayout: productLayoutsMap[product._id] ,
  //                                                     categoryLayout: categoriesLayoutMap[product._id]
  //                                                   })
  //     );
  // });

  const suggestedProductsVertical = useComputed(() => {
    const sectionMap = sections().reduce((acc, s) => {
      if (s._id) if (s.name != null) {
        acc[s._id] = s.name;
      }
      return acc;
    }, {} as Record<string, string>);

    // const categoriesLayoutMap = layoutCategories0().reduce((map, category) => {
    //   category.products?.forEach(productLayout => {
    //     if (productLayout.product) {
    //       map[productLayout.product] = category.name;
    //     }
    //   });
    //   return map;
    // }, {} as Record<string, string>);

    return products()
      .filter(p => !!p.section)
      .map(p => ({ ...p,
        sectionName: p.section ? sectionMap[p.section] || null : null,
        }));
      });

  const filteredProducts = useComputed(() => {
    return (
      searchProduct()
        ? suggestedProductsVertical().filter(product => product.name?.toLowerCase().includes(searchProduct().toLowerCase()))
        : suggestedProductsVertical()
    )
  })

  const filteredProductNames = useComputed(() => {
    const productCounts = filteredProducts().reduce((counts, product) => {
      if (product.name) {
        counts[product.name] = (counts[product.name] || 0) + 1;
      }
      return counts;
    }, {} as Record<string, number>);

    return filteredProducts().map(product => {
      const count = product.name ? productCounts[product.name] : undefined;
      return {
        product: product,
        name: product.name,
        // productLayout: product.productLayout,
        // categoryLayout: product.categoryLayout,
        displayName: count && count > 1 ? `${product.name} (${product.sectionName})` : product.name
      };
    });
  });

  function autoCompleteProps() {
    return {
      id: "combo-box-service",
      freeSolo: true,
      className: "flex-1",
      value: productName(), // ?
      filterOptions: (opts: any) => opts,
      onChange: (e: any, v: any) => {
        // console.log('onChange', v);
        setSearchProduct('')
        setProductName(v ? (typeof v === 'string' ? v : (v.displayName || '')) : '')
      },
      options: filteredProductNames(),
      getOptionLabel: (option: string | { id: string, name: string, displayName: string } | null) => {
        if (!option) return '';
        return typeof option === 'string' ? option : (option.displayName || '');
      },
      inputValue: searchProduct() || productName(),
      inputProps: {
        label: '',
        value: searchProduct(),
        onChange: (value: any) => {
          // console.log('inputProps.onChange', value)
          const inputValue = value || '';
          setSearchProduct(inputValue)
          setProductName(inputValue)
        },
        className: "w-full font-mulish text-sm text-gray-100"
      },
      renderOption: ({style, className, ...restProps}, option, state) => {
        return (
          <li className="self-stretch box-border h-10 flex flex-row items-center justify-between py-1 px-2 relative border-b-[1px] border-solid border-gray-solid-gray-164-e1e1e1"
              key={option.id}
              {...restProps}
              onClick={(e) => {
                console.log(option)
                setProductName(option.displayName)
                setSelectedProduct(option.product)
                restProps.onClick?.(e);
              }}
          >
            { state.index % 2 === 0 &&
              <div className="!flex w-full absolute my-0 mx-[!important] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] bg-[#ddd] hidden z-[0]" />
            }
            <div className="text-left flex-1 relative text-black-solid-black-880-1d1d26 z-[1]">
              <span>{option.displayName}</span>
            </div>
          </li>
        )
      },
    }
  }

  return (
    <div
      className={`border-4 w-full h-[85%] relative bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-8  box-border gap-[24px] max-w-full max-h-full overflow-auto text-left text-base text-blue-solid-blue-500-0051c1 font-mulish ${className}`}
    >
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 object-cover cursor-pointer z-[10]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={onClose}
      />
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[11px] z-[0] px-6 pb-6">
        <b className="self-stretch relative">{LL0().order.searchItem()}</b>
        <div
          className="self-stretch flex flex-row items-start justify-start gap-[2px] text-sm text-black-solid-black-900-1e1e23">
          <Autocomplete {...autoCompleteProps()} />
        </div>
      </div>
      {deviceSetting0()?.useVirtualKeyboard &&
        <div className="self-stretch flex flex-row items-center justify-end z-[1] text-center text-sm text-white-solid-white-100-ffffff px-6 pb-6">
          <div
            className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-8"
            ref={makeRipple}
            onClick={() => {
              const product = selectedProduct();
              const layoutType = product.layout?.type ?? '';
              onConfirm?.(product, layoutType, 1);
              onClose?.()
            }}
          >
            <b className="relative">{LL0().ui.ok()}</b>
          </div>
        </div>
      }
      {!deviceSetting0()?.useVirtualKeyboard &&
          <TableManualKeyboard
            inputController={inputController0}
            onEnter={() => {
              const product = selectedProduct();
              const layoutType = product.layout?.type ?? '';
              onConfirm?.(product, layoutType, 1);
              onClose?.()
            }}
          />
        }
    </div>
  );
};

export default SearchItemVertical;
