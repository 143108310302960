import React, { memo, useMemo } from 'react';
import { Product } from "@/data/Product.ts";
import { untracked } from "@preact/signals-react";
import ProductItem from "@vertical-order-view/ProductItem.tsx";
import { ColorDarken } from '@/react/core/ColorDarken.tsx';
import { isActiveCategory, onProductClickHandle, products, sections } from "@/react/OrderView/OrderView.tsx";
import { styleVisible } from "@/react/core/tw.ts";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";
import { useComputed } from "@/react/core/reactive.ts";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";

const RenderProduct = memo(({ product }: { product: Product }) => {

  const calculatePrintedQuantity = useComputed(() =>
    order0().items
      .filter(item => item?.productId === product._id && item.printed === true)
      .reduce((total, item) => total + item.quantity, 0)
  );

  const modifiers = useComputed(() => {
    return order0().items
      .filter(item => item.modifiers?.length > 0)
      ?.flatMap(item =>
        item.modifiers.map(modifier => ({
          ...modifier,
          printed: item.printed
        })))
      ?.filter(modifier => modifier.modifierId === product._id);
    // return matchingModifiers.reduce((total, modifier) => total + modifier.quantity, 0);
  });

  const printedModifiers = useComputed(() => {
    return modifiers()?.filter(modifier => modifier.printed === true)?.reduce((total, modifier) => total + modifier.quantity, 0);
  })

  const unPrintedModifiers = useComputed(() => {
    return modifiers()?.filter(modifier => modifier.printed === false)?.reduce((total, modifier) => total + modifier.quantity, 0);
  })

  const calculateUnprintedQuantity = useComputed(() =>
    order0().items
      .filter(item => item?.productId === product._id && item.printed === false)
      .reduce((total, item) => total + item.quantity, 0)
  );

  return (
    <ColorDarken color={product?.layout?.color || '#FFFFFF'} key={product._id}>
      {(color, setClicked) => (
        <div
          onClick={() => {
            setClicked(true)
            onProductClickHandle(product, product?.layout?.type, 1)
          }}
          className="self-stretch rounded-1"
        >
          <ProductItem
            backgroundColor={color}
            productNumber={product?.id}
            productType={product?.layout?.type}
            unPrintQuantity={calculateUnprintedQuantity() || unPrintedModifiers() }
            printQuantity = {calculatePrintedQuantity() || printedModifiers()}
            showPopupIcon={(product?.activePopupModifierGroup?.length || 0) > 0}
            productName={product?.name}
          />
        </div>
      )}
    </ColorDarken>
  )
})

export const RenderProductsGridWrapper = memo(({ rows, columns, sectionId, colsGap = 2, rowsGap = 2 }: {
  rows: number,
  columns: number,
  sectionId: string,
  colsGap?: number,
  rowsGap?: number
}) => {
  const _products = useMemo(() => {
    return untracked(() => products())?.filter(product => product?.section === sectionId)?.sort((a, b) => (a?.layout?.order || 0) - (b?.layout?.order || 0)) || [];
  }, [products(), sectionId]);

  const calculatedColumns = Math.min(Math.ceil(_products.length / rows), columns);
  const maxWidth = (calculatedColumns / columns) * 100 + '%';
  return (
    <div
      className="grid w-full items-start justify-start gap-[4px]"
      style={{
        gridTemplateRows: `repeat(${rows}, auto)`,
        gridTemplateColumns: `repeat(${calculatedColumns}, minmax(0, 1fr))`,
        alignItems: 'stretch',
        gap: `${rowsGap}px ${colsGap}px`,
        maxWidth: _products.length < columns ? maxWidth : '100%',
      }}
    >
      {_products.map((product, idx) => (
        <RenderProduct product={product} key={idx} />
      ))}
    </div>
  );
});

const RenderSectionsOrderWrapper = memo(({ sectionId }: {
  sectionId: string,
}) => {
  const section = useMemo(() =>
      untracked(() => sections())?.find(s => s?._id === sectionId)
    , [sectionId])
  if (!section) return <></>;

  return (
    <div
      className="w-full flex-col"
      {...styleVisible(isActiveCategory(section?.categories?.[0] || ''))}
    >
      <div className="rounded flex flex-row items-center justify-start p-1 pl-0 gap-[4px]">
        {section?.icon && <img
          className="w-6 relative h-6 object-cover"
          alt=""
          src={section?.icon}
        />}
        <b className="relative leading-[20px] lg:text-mid md:text-base sm:text-sm mq600:text-sm mq432:text-sm">
          {section?.name}
        </b>
      </div>
      <RenderProductsGridWrapper
        sectionId={section?._id!}
        rows={1}
        columns={generalSetting0()?.useMenuV2 ? 6 : 3} //menu0().columns
        rowsGap={4}
        colsGap={4}
      />
    </div>
  )
});

export default RenderSectionsOrderWrapper;
