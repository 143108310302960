import { toast } from "react-toastify";
import { Image, ImageType } from "@/data/Image.ts";
import { images0 } from "@/data/ImageHub.ts";
import { computed, signal } from "@/react/core/reactive.ts";
import { convertBase64ToPngBase64, toBase64 } from "@/react/shared/utils.ts";
import { getUrlParam } from "@/shared/utils2.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";


export const [imageLoadingState, setImageLoadingState] = signal<{
  logo: boolean;
  coverPhoto: boolean;
  bannerPhoto: boolean;
}>({
  logo: false,
  coverPhoto: false,
  bannerPhoto: false,
});

export const kioskImage = computed(() => {
  const images = images0();
  return {
    logo: images.find((img) => img.type === 'KioskLogo'),
    coverPhoto: images.find((img) => img.type === 'KioskCoverPhoto'),
    bannerPhoto: images.find((img) => img.type === 'KioskBannerPhoto'),
  };
});

export async function onChooseImg(typeLogo: string) {
  try {
    setImageLoadingState((prevState) => ({ ...prevState, [typeLogo]: true, }));
    if (getUrlParam('os')) {
      const data = await rnHost.chooseImage();
      if (!data) {
        toast.error('No image data received');
      }
      const base64 = await convertBase64ToPngBase64(data);
      console.log(base64.length);
      const updateFunctions: Record<string, (base64: string) => Promise<void>> = {
        logo: updateKioskLogo,
        coverPhoto: updateKioskCoverPhoto,
        bannerPhoto: updateKioskBannerPhoto,
      };
      const updateFunction = updateFunctions[typeLogo];
      if (updateFunction) {
        await updateFunction(base64);
        toast.success('Success');
      }
    } else {
      const uploadHandlers = {
        logo: {
          inputId: 'uploadLogoKiosk',
          updateFunction: updateKioskLogo,
        },
        coverPhoto: {
          inputId: 'uploadCoverPhotoKiosk',
          updateFunction: updateKioskCoverPhoto,
        },
        bannerPhoto: {
          inputId: 'uploadBannerPhotoKiosk',
          updateFunction: updateKioskBannerPhoto,
        },
      };
      const handler = uploadHandlers[typeLogo as keyof typeof uploadHandlers];
      if (handler) {
        const input = document.getElementById(handler.inputId);
        if (input) {
          input.click();
          input.oninput = async (e: Event) => {
            const target = e.target as HTMLInputElement | null;
            const file = target?.files?.item(0);
            if (file) {
              const base64 = await toBase64(file);
              const logo = await convertBase64ToPngBase64(base64);
              await handler.updateFunction(logo as string);
            }
          };
        }
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    setImageLoadingState((prevState) => ({ ...prevState, [typeLogo]: false, }));
  }
}

async function updateKioskLogo(base64: string) {
  await Image.upsert({
    _id: 'kioskLogo',
    type: ImageType.KioskLogo,
    data: base64 as string,
  })
}

async function updateKioskCoverPhoto(base64: string) {
  await Image.upsert({
    _id: 'kioskCoverPhoto',
    type: ImageType.KioskCoverPhoto,
    data: base64 as string,
  })
}
async function updateKioskBannerPhoto(base64: string) {
  await Image.upsert({
    _id: 'kioskBannerPhoto',
    type: ImageType.KioskBannerPhoto,
    data: base64 as string,
  })
}

export async function deleteKioskPhoto(id?: string) {
  if (id) {
    await Image.find({selector: { _id: id}}).remove();
  }
}