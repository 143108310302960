import { type FunctionComponent, useCallback, useContext, useState } from 'react';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import DiscountPopupItem from '@order-view/DiscountPopup/DiscountPopupItem';
import OrderScreenCustomDishPo from '@order-view/OrderScreenCustomDishPo.tsx';
import {
  handleAddSeat,
  onProductClick,
  onToggleTakeOut,
  setShowTabSidebar,
  setTabDiscountPopup,
  setVoucherStatus,
  TabsDiscountPopup
} from '@/react/OrderView/OrderView.tsx'
import OrderScreenFunctionsPopup from '@order-view/OrderScreenFunctionsPopup.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts';
import { signal } from '@/react/core/reactive.ts';
import { ItemFactoryContext } from '@/react/PaymentView/OrderItemsFactory2.tsx';
import { totalOrder } from "@/react/PendingOrder/PendingOrderLogic.tsx";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import EditMenuChangeMenuPopup from "@order-view/Popups/EditMenuChangeMenuPopup.tsx";
import { orderPricingLevelFactory } from "@/react/OrderView/OrderPricingLevel.logic.ts";
import { isFeatureEnabled, PosFeatures } from '@/srm/features.logic';
import OrderScreenSearchItemPopUp from "@order-view/OrderScreenSearchItem";
import VoucherPopup from "@/react/VoucherView/VoucherPopup.tsx";
import { checkItemBeforeSplit, removeDiscountBeforeSplit } from "@/react/OrderView/order-splitbill-utils.ts";
import PrintKitchenPopUp from "@order-view/Popups/PrintKitchenPopUp.tsx";
import { isPrintKitchenPopUpOpen, setPrintKitchenPopUpOpen } from "@order-view/OrderBurgerMenuButton.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { isShowPendingOrder } from "@/shared/posFeatures.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { order0 } from "@/react/OrderView/OrderViewShare.ts";

const { computedMenuName } = orderPricingLevelFactory();


export const [isDiscountPopupOpen, setDiscountPopupOpen] = signal<boolean>(false);
export const [isEditMenuChangeMenuPopupOpen, setEditMenuChangeMenuPopupOpen] =
  signal<boolean>(false);


const OrderScreenBottomBar: FunctionComponent = () => {
  const { order0, onSeatToggle, splitEnable } = useContext(ItemFactoryContext)

  const [isOrderScreenFunctionsPopupOpen, setOrderScreenFunctionsPopupOpen] =
    useState(false);
  const [isOrderScreenSearchItemPopupOpen, setOrderScreenSearchItemPopupOpen] =
    useState(false);
  const [isOrderSreenCustomDishPoOpen, setOrderSreenCustomDishPoOpen] =
    useState(false);
  const [isVoucherPopupOpen, setVoucherPopupOpen] = useState(false);

  const openOrderScreenFunctionsPopup = useCallback(() => {
    userFLow(`open order screen functions popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderScreenFunctionsPopupOpen(true);
  }, []);

  const closeOrderScreenFunctionsPopup = useCallback(() => {
    userFLow(`close order screen functions popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderScreenFunctionsPopupOpen(false);
  }, []);

  const openOrderScreenSearchItemPopup = useCallback(() => {
    userFLow(`click open search popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderScreenSearchItemPopupOpen(true);
  }, []);

  const closeOrderScreenSearchItemPopup = useCallback(() => {
    userFLow(`close search popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderScreenSearchItemPopupOpen(false);
  }, []);

  const openEditMenuChangeMenuPopup = useCallback(() => {
    userFLow(`click open change menu popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setEditMenuChangeMenuPopupOpen(true);
  }, []);

  const closeEditMenuChangeMenuPopup = useCallback(() => {
    userFLow(`close change menu popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setEditMenuChangeMenuPopupOpen(false);
  }, []);

  const openOrderSreenCustomDishPo = useCallback(() => {
    userFLow(`open custom dish`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenCustomDishPoOpen(true);
  }, []);

  const closeOrderSreenCustomDishPo = useCallback(() => {
    userFLow(`close custom dish`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setOrderSreenCustomDishPoOpen(false);
  }, []);

  const openVoucherPopup = useCallback(() => {
    userFLow(`open voucher popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setVoucherPopupOpen(true);
  }, []);

  const closeVoucherPopup = useCallback(() => {
    userFLow(`close voucher popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setVoucherPopupOpen(false);
    setVoucherStatus("")
  }, []);

  const openDiscountPopup = useCallback(() => {
    userFLow(`open discount popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setDiscountPopupOpen(true);
    setShowTabSidebar(false)
    setTabDiscountPopup(TabsDiscountPopup.DISCOUNT)
  }, []);

  const closeDiscountPopup = useCallback(() => {
    userFLow(`close discount popup`, {
      orderId: order0?.()?._id,
      username: loginUser()?.name
    });
    setDiscountPopupOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] [backdrop-filter:blur(100px)] flex flex-row items-start justify-between py-2 px-4 text-center text-sm text-black-solid-black-900-1e1e23 font-mulish sm:flex sm:pr-3.5 sm:box-border Tablet_768:pl-3.5 Tablet_768:pr-3.5 Tablet_768:box-border Tablet_600:pl-3 Tablet_600:pr-3 Tablet_600:box-border Mobile_480:pl-2.5 Mobile_480:pr-2.5 Mobile_480:box-border height_414:hidden mq320:pl-2 mq320:pr-2 mq320:box-border">
        <div
          className="flex flex-row items-start justify-start gap-[0px_12px] sm:gap-[0px_12px] Tablet_600:gap-[0px_12px]">
          {!generalSetting0()?.hasTablePlan &&
            <div
              className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px] cursor-pointer"
              onClick={openOrderScreenFunctionsPopup}
              ref={makeRipple}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/order-function-icon.png"
              />
              <b className="relative leading-[17px] Tablet_768:text-xs">
                {LL0().sidebar.functions()}
              </b>
            </div>
          }
          <div
            className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white h-[34px] flex flex-row items-center justify-center py-0.5 pr-3 pl-2.5 box-border gap-[4px] cursor-pointer Tablet_768:h-[30px]"
            onClick={openOrderScreenSearchItemPopup}
          >
            <img
              className="w-5 relative h-5 object-cover"
              alt=""
              src="/iconsearch-blue@2x.png"
            />
            <b className="relative Tablet_768:text-xs">{LL0().order.search()}</b>
          </div>
          {generalSetting0()?.multipleDineInMenusEnable && (<div
            className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 pr-2.5 pl-[7px] box-border cursor-pointer"
            onClick={openEditMenuChangeMenuPopup}
            ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
              alt=""
              src="/ordersplit-bill-icon2@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-xs">{computedMenuName()}</b>
          </div>)
          }
          {/*<div*/}
          {/*  className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 pr-[9px] pl-1.5 box-border cursor-pointer"*/}
          {/*  onClick={openEditMenuChangeMenuPopup}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"*/}
          {/*    alt=""*/}
          {/*    src="/ordersplit-bill-icon@2x.png"*/}
          {/*  />*/}
          {/*  <b className="relative leading-[17px] Tablet_768:text-xs">*/}
          {/*    Menu-Price*/}
          {/*  </b>*/}
          {/*</div>*/}
          <div
            className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2 box-border gap-[3px] cursor-pointer"
            onClick={openOrderSreenCustomDishPo}
            ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
              alt=""
              src="/ordercustom-dish-icon@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-xs">
              {LL0().order.customDish()}
            </b>
          </div>
          <div
            className="Tablet_600:hidden flex shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-white h-[34px] overflow-hidden flex-row items-center justify-center py-0.5 px-2 box-border gap-[3px] cursor-pointer"
            onClick={openVoucherPopup}
            ref={makeRipple}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
              alt=""
              src="/ordervoucher-icon2@2x.png"
            />
            <b className="relative leading-[17px] Tablet_768:text-xs">
              {LL0().order.voucher()}
            </b>
          </div>
          {getAccessibility(AccessPort.DISCOUNT) &&
            <div
              className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
              onClick={openDiscountPopup}
              ref={makeRipple}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
                alt=""
                src="/orderdiscount-icon@2x.png"
              />
              <b className="relative leading-[17px] Tablet_768:text-xs">
                {LL0().order.discount()}
              </b>
            </div>
          }
          {!generalSetting0()?.hasTablePlan && isShowPendingOrder() && <div
            className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0.5 px-3 box-border gap-[6px] text-smi text-gray-solid-gray-145-f9fafb sm:pl-2 sm:pr-2 sm:box-border Tablet_768:pl-2 Tablet_768:pr-2 Tablet_768:box-border Tablet_600:pl-1.5 Tablet_600:pr-1.5 Tablet_600:box-border Mobile_480:pl-1.5 Mobile_480:pr-1.5 Mobile_480:box-border mq320:pl-1 mq320:pr-1 mq320:box-border"
            onClick={() => {
              userFLow(`go to screen pending order`, {
                orderId: order0?.()?._id,
                username: loginUser()?.name
              });
              router.screen = PosScreen.PENDING_ORDERS
            }}
            ref={makeRipple}
          >
            <div
              className="w-6 rounded-981xl bg-red-solid-red-320-e63950 h-6 flex flex-row items-center justify-center p-[2.5px] box-border">
              <b className="relative tracking-[-0.23px]">{totalOrder()}</b>
            </div>
            <b className="relative text-sm leading-[17px] text-black-solid-black-900-1e1e23 Tablet_768:text-xs">
              {LL0().order.pending()}
            </b>
          </div>
          }
          <div className="self-stretch w-1 relative bg-gray-opacity-gray-0-d9d9d9" />
        </div>
        <div
          className="flex flex-row items-start justify-start gap-[12px] sm:gap-[12px_8px] Tablet_768:gap-[12px_10px] Tablet_600:gap-[12px_8px] mq320:gap-[12px_4px]">
          <div
            className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2 box-border relative gap-[3px]"
            ref={makeRipple}
            onClick={onToggleTakeOut}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover z-[1] sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
              alt=""
              src="/ordertake-out-icon@2x.png"
            />
            {order0?.().takeAway ? <>
                <div
                  className="!block absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl bg-blue-solid-blue-350-2196f3 hidden z-[0]" />
                <b className="!w-fit !block relative leading-[17px] hidden text-white-solid-white-100-ffffff items-center w-16 h-[17px] shrink-0 z-[3] Tablet_768:text-xs">
                  {LL0().order.takeAway()}
                </b>
              </> :
              <b className="!w-fit relative leading-[17px] flex items-center w-16 h-[17px] shrink-0 z-[2]">
                {LL0().order.takeAway()}
              </b>
            }
          </div>
          {isFeatureEnabled(PosFeatures.SplitBill) &&
          <div className="flex flex-col items-center justify-center gap-[10px]">
            {order0?.().items.length === 0 || (!splitEnable?.() && order0?.().seatMode) ?
              <div
                className="rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] cursor-pointer"
                ref={makeRipple}
                onClick={handleAddSeat}
              >
                <img
                  className="iconaddseat-icon--%123 w-[30px] relative h-[30px] object-cover sm:hidden Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden mq320:hidden"
                  alt=""
                  src="/iconaddseat-icon@2x.png"
                />
                <b className="relative leading-[17px] Tablet_768:text-xs">
                  {LL0().order.addSeat()}
                </b>
              </div>
              :
              <div
                className="!flex rounded-81xl bg-white shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 hidden flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px]"
                ref={makeRipple}
                onClick={() => {
                  userFLow(`split order`, {
                    orderId: order0?.()?._id,
                    username: loginUser()?.name
                  });
                  if (checkItemBeforeSplit(order0?.()!)) {
                    removeDiscountBeforeSplit(order0?.()!)
                    onSeatToggle?.()
                  } else {
                    setPrintKitchenPopUpOpen(true)
                  }
                }}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover sm:flex Tablet_768:hidden Tablet_768:w-[26px] Tablet_768:h-[26px]"
                  alt=""
                  src="/iconsplit-icon@2x.png"
                />
                <b className="relative leading-[17px] Tablet_768:text-xs">
                  {LL0().order.splitOrder()}
                </b>
              </div>
            }
          </div>
          }
        </div>
      </div>
      {isOrderScreenFunctionsPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderScreenFunctionsPopup}
        >
          <OrderScreenFunctionsPopup onClose={closeOrderScreenFunctionsPopup}/>
        </PortalPopup>
      )}
      {isOrderScreenSearchItemPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderScreenSearchItemPopup}
        >
          <OrderScreenSearchItemPopUp onClose={closeOrderScreenSearchItemPopup} onConfirm={onProductClick}/>
        </PortalPopup>
      )}
      {isEditMenuChangeMenuPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuChangeMenuPopup}
        >
          <EditMenuChangeMenuPopup onClose={closeEditMenuChangeMenuPopup}/>
        </PortalPopup>
      )}
      {isOrderSreenCustomDishPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOrderSreenCustomDishPo}
        >
          <OrderScreenCustomDishPo onClose={closeOrderSreenCustomDishPo} />
        </PortalPopup>
      )}
      {isVoucherPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeVoucherPopup}
        >
          <VoucherPopup onClose={closeVoucherPopup} order={order0?.()} />
        </PortalPopup>
      )}
      {isDiscountPopupOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDiscountPopup}
        >
          <DiscountPopupItem onClose={closeDiscountPopup} />
        </PortalPopup>
      )}
      {isPrintKitchenPopUpOpen() && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setPrintKitchenPopUpOpen(false)}
        >
          <PrintKitchenPopUp onClose={() => setPrintKitchenPopUpOpen(false)} />
        </PortalPopup>
      )}

    </>
  );
};

export default OrderScreenBottomBar;
