import { posSetting0 } from '@/data/PosSettingsSignal.ts'
import { rnHost } from '@/shared/webview/rnwebview'
import { AWS_LAMBDA_RELAY_URL } from '@/srm/lib/constants'
import { QuebecSDK } from '@/srm/lib/sdk'

export function getSdk() {
  const { authCode, env } = posSetting0()?.srm ?? {}
  if (!authCode) throw new Error('Missing authCode!')

  const sdk = new QuebecSDK({
    relayFn: rnHost.relay,
    relayUrl: AWS_LAMBDA_RELAY_URL,
    authCode,
    debug: import.meta.env.MODE !== 'production',
    isProd: env === 'PROD',
  })
  return sdk
}
