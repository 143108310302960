/* document-load.ts|js file - the code is the same for both the languages */

import { context, trace, type Context, type Meter, type Span, type Tracer } from '@opentelemetry/api'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { OTLPMetricExporter } from '@opentelemetry/exporter-metrics-otlp-http'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { Resource } from '@opentelemetry/resources'
import { MeterProvider, PeriodicExportingMetricReader } from '@opentelemetry/sdk-metrics'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { isRxDocument, isRxQuery } from 'rxdb'
import { map } from 'rxjs'

import { posSync0 } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { isMaster } from '@/lib/master-signal.ts'
import { servers } from '@/lib/servers.ts'
import { effectOn } from '@/react/core/reactive.ts'
import { getDeviceId } from '@/shared/getDeviceId'

const ENABLE_TRACE = import.meta.env.MODE !== 'development'

const AsyncCollectionMethod = ['insert', 'upsert', 'remove']
const FindMethod = ['find', 'findOne']
const AsyncRxDocMethod = ['patch', 'incrementalPatch', 'update', 'incrementalUpdate', 'remove', 'incrementalRemove']
export const LogOperation = ['bulkWrite']

let provider: WebTracerProvider | null = null
let tracer: Tracer | null = null
let metricExporter: OTLPMetricExporter | null = null
let meterProvider: MeterProvider | null = null
let meter: Meter | null = null
const connectionCounter: Record<any, any> = {}

function defineProperty(obj: any, name: string, value: any) {
  const enumerable = !!obj[name] && obj.propertyIsEnumerable(name)
  Object.defineProperty(obj, name, {
    configurable: true,
    enumerable: enumerable,
    writable: true,
    value: value,
  })
}

export function patchCollection(obj: any) {
  if (!ENABLE_TRACE) return
  for (const key of AsyncCollectionMethod) {
    if (typeof obj[key] === 'function') {
      const originalMethod = obj[key]

      obj[key] = function (...args: any[]) {
        if (!tracer) return originalMethod.apply(this, args)
        const span = tracer!.startSpan(`Method ${key} called`, undefined, context.active())
        span.setAttribute('storeId', `${posSync0()?.id!}`)
        span.setAttribute('deviceId', getDeviceId())
        span.setAttribute('stack trace', JSON.stringify(new Error().stack))
        span.setAttribute('collectionName', obj.name)
        return context.with(trace.setSpan(context.active(), span), async () => {
          try {
            const result = await originalMethod.apply(this, args)
            span.end()
            return result
          } catch (error: any) {
            span.recordException(error)
            span.end()
            throw error
          }
        })
      }
    }
  }

  for (const key of FindMethod) {
    if (typeof obj[key] === 'function') {
      const originalMethod = obj[key]

      obj[key] = function (...args: any[]) {
        const result = originalMethod.apply(this, args)
        if (isRxQuery(result)) {
          patchRxQuery(result)
        }
        return result
      }
    }
  }
}

export function patchRxQuery(rxQuery: any) {
  if (!ENABLE_TRACE) return
  if (rxQuery.__patch) return
  defineProperty(rxQuery, '__patched', true)
  const originalExecMethod = rxQuery['exec']

  rxQuery['exec'] = async function (...args: any[]) {
    try {
      const result = await originalExecMethod.apply(rxQuery, args)
      if (isRxDocument(result)) {
        patchRxDoc(result)
      }
      return result
    } catch (error: any) {
      throw error
    }
  }

  const originalGetter = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(rxQuery), '$')?.get
  Object.defineProperty(rxQuery, '$', {
    get: function () {
      const observable = originalGetter!.call(rxQuery)
      return patchObservable(observable)
    },
    configurable: true, // Allow further modifications
    enumerable: true, // Ensure the property remains enumerable
  })
}

function patchObservable(obs: any) {
  if (!ENABLE_TRACE) return obs
  if (obs.__patched) return obs

  const newObs = obs.pipe(
    map((arr: any) => {
      if (Array.isArray(arr)) {
        for (const obj of arr) {
          if (typeof obj === 'object' && obj !== null && 'incrementalPatch' in obj) {
            patchRxDoc(obj)
          }
        }
      }
      return arr
    })
  )
  defineProperty(newObs, '__patched', true)
  return newObs
}

export function patchRxDoc(rxDoc: any) {
  if (!ENABLE_TRACE) return
  if (rxDoc.__patched) return

  defineProperty(rxDoc, '__patched', true)
  for (const key of AsyncRxDocMethod) {
    const originalMethod = rxDoc[key]
    defineProperty(rxDoc, `__original_$${key}`, originalMethod)
    defineProperty(rxDoc, key, async function (...args: any[]) {
      if (!tracer) return originalMethod.apply(rxDoc, args)
      const span = tracer!.startSpan(`Calling RxDoc ${key}`, undefined, context.active())
      span.setAttribute('storeId', `${posSync0()?.id!}`)
      span.setAttribute('deviceId', getDeviceId())
      span.setAttribute('stack trace', JSON.stringify(new Error().stack))
      return context.with(trace.setSpan(context.active(), span), async () => {
        try {
          const result = await originalMethod.apply(rxDoc, args)
          span.end()
          return result
        } catch (error: any) {
          span.recordException(error)
          span.end()
          throw error
        }
      })
    })
  }
}

// temporary unused, and is not completed
export function patchStorage(rxStorage: any) {
  if (!ENABLE_TRACE) return
  for (const key in rxStorage) {
    if (key === 'bulkWrite' && typeof rxStorage[key] === 'function') {
      const originalMethod = rxStorage[key]
      rxStorage[key] = async function (...args: any[]) {
        const result = await originalMethod.apply(this, args)
        return result
      }
    }
  }
}

export function createSpan(name: string, options: any, context: Context) {
  if (!ENABLE_TRACE || !tracer) return null
  const span = tracer!.startSpan(name, options, context)
  return span
}

export function runWithCurrentContext(span: Span | null, fn: any) {
  if (!span) return fn()
  return context.with(trace.setSpan(context.active(), span), fn)
}

export function updateConnectionStatus(name: string, isConnected: boolean) {
  if (!ENABLE_TRACE || !meterProvider) return
  if (!connectionCounter[name]) {
    connectionCounter[name] = { counter: meter!.createObservableGauge(name), value: false }
    connectionCounter[name].counter.addCallback((result: any) => {
      result.observe(connectionCounter[name].value, {
        storeId: `${posSync0()?.id!}`,
        deviceId: `${getDeviceId()}`,
        isMaster: `${isMaster()}`,
        name,
      })
    })
  }
  const status = isConnected ? 1 : 0
  connectionCounter[name].value = status
}

function clearCounter() {
  Object.keys(connectionCounter).forEach(name => {
    delete connectionCounter[name]
  })
}

export function startCollectingMemUsage() {
  if (!ENABLE_TRACE || !meterProvider) return
  const memoryUsageGauge = meter?.createObservableGauge('Memory usage')
  memoryUsageGauge?.addCallback((result: any) => {
    //@ts-ignore
    result.observe(window.performance?.memory?.usedJSHeapSize / 1048576, {
      storeId: `${posSync0()?.id!}`,
      deviceId: `${getDeviceId()}`,
      isMaster: `${isMaster()}`,
      name: 'Memory usage',
    })
  })
}

effectOn([server], async () => {
  if (server() && ENABLE_TRACE) {
    if (provider) {
      tracer = null
      await provider.shutdown()
    }
    if (metricExporter) {
      await metricExporter.shutdown()
      clearCounter()
    }
    const serverName = server()
    provider = new WebTracerProvider({
      resource: new Resource({
        'service.name': 'pos-frontend', // Set your service name here
      }),
    })

    provider!.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          url: servers[serverName!].o2.url,
          headers: {
            Authorization: servers[serverName!].o2.authToken,
            'stream-name': 'default',
          },
        })
      )
    )

    provider!.register({
      // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
      contextManager: new ZoneContextManager(),
    })

    tracer = provider?.getTracer('frontend')!

    metricExporter = new OTLPMetricExporter({
      url: servers[serverName!].o2.metrics, // Replace with your OpenObserve endpoint
      headers: {
        Authorization: servers[serverName!].o2.authToken, // Replace with your actual auth token
      },
    })

    meterProvider = new MeterProvider({
      readers: [
        new PeriodicExportingMetricReader({
          exporter: metricExporter,
          exportIntervalMillis: 30000,
        }),
      ],
    })

    meter = meterProvider.getMeter('connection-status-meter')
    startCollectingMemUsage()
  }
})
