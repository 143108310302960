import { useMemo } from "react"
import uuid from "time-uuid"
import { rnHost } from "@/shared/webview/rnwebview.ts";

export function getDeviceId() {
  const id = localStorage.getItem('deviceId')
  if (id) {
    return id
  } else {
    const _id = uuid()
    localStorage.setItem('deviceId', _id)
    return _id
  }
}

//@ts-ignore
window.getDeviceId=getDeviceId

export const useDeviceId = () => {
  const deviceId = useMemo(() => {
    return getDeviceId()
  }, [])
  return deviceId
}

export const getUniqueDeviceId = async (): Promise<string> => {
  try {
    let deviceId = getDeviceId();

    const timeoutPromise = new Promise<string>((resolve) => {
      setTimeout(() => {
        resolve(deviceId);
      }, 5000);
    });

    const uniqueId = await Promise.race([
      rnHost.getUniqueId(),
      timeoutPromise,
    ]);

    if (!!uniqueId) {
      localStorage.setItem('deviceId', uniqueId);
      deviceId = uniqueId;
    }
    return deviceId;
  } catch (error) {
    console.error('Error in getUniqueDeviceId:', error);
    return getDeviceId()
  }
};


