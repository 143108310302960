import { convertDocuments } from "@/data/data-utils";
import { computed, effectOn, signal } from "@/react/core/reactive";
import { dataLock } from "@/data/DataUtils.ts";
import { PosSync } from "@/data/PosSync.ts";
import { nextTick } from "rxdb";
import uuid from "time-uuid";
import { initedPosId, pause } from "@/data/dev.ts";
import SurrealClient from "@/shared/SurrealClient.ts";
import { getDefaultPosSetting } from "@/data/ImportData.ts";
import { posSync0, posSyncLock, posSyncLockReady, setPosSyncs0 } from "@/data/PosSyncState.ts";
import { GERMANY_SERVER_1 } from "@/lib/servers.ts";
import { isGermany } from "@/react/CompanyInformationView/companyInfomation.logic.ts";
import { rnHost } from "@/shared/webview/rnwebview.ts";

export const [posSyncV, setPosSyncV] = signal(1);

effectOn([posSyncV], async () => {
  await dataLock.acquireAsync();
  const initServer = await getDefaultPosSetting()
  let _posSyncs = await PosSync.find().exec();
  if (_posSyncs.length === 0) {
    await PosSync.insert({
      _id: uuid(),
      ...initedPosId() && {
        id: initedPosId()
      },
      syncProtocol: 'v2',
      server: initServer?.server
    });
    _posSyncs = await PosSync.find().exec();
  } else if (!_posSyncs[0].syncProtocol) {
    const posSyncs = convertDocuments<PosSync>(_posSyncs, true, []);
    posSyncs[0].syncProtocol = 'v1';
  }


  const posSyncs = convertDocuments<PosSync>(_posSyncs, true, []);
  setPosSyncs0(posSyncs);
  if (posSyncs[0].syncProtocol === 'v2-http') {
    SurrealClient.switchToHttp().then();
  }

  if (isGermany()) {
    posSyncs[0].server = GERMANY_SERVER_1
  }

  if (posSyncs[0].server && localStorage.getItem('send-server') !== posSyncs[0].updatedAt?.toString()) {
    rnHost.sendServer(posSyncs[0]?.server!).then().catch(e=>{})
    //@ts-ignore
    localStorage.setItem('send-server', posSyncs[0].updatedAt?.toString())
  }

  if (localStorage.getItem('send-store-id') !== posSyncs[0].updatedAt?.toString()) {
    rnHost.sendStoreId(posSyncs[0].id?.toString() || 'null').then().catch(e=>{})
    //@ts-ignore
    localStorage.setItem('send-store-id', posSyncs[0].updatedAt?.toString())
  }

  if (pause()) return;
  posSyncLockReady.release().then();
  if (posSyncs[0]?.id) {
    await posSyncLock.release()
  }
}/*, {defer: true}*/);

(async () => {
  await nextTick();
  await dataLock.acquireAsync();
  PosSync.$.subscribe(() => setPosSyncV(posSyncV() + 1))
})()

// @ts-ignore
window.posSync0 = posSync0;

export const replicationIdentifierPrefix = computed(() => posSync0()?.replicationIdentifierPrefix?.toString() || '');
