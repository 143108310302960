import { useSignalEffect } from '@preact/signals-react'
import type { Eruda } from 'eruda'
import { useEffect } from 'react'

import { signal } from '@/react/core/reactive.ts'

let eruda: Eruda | undefined
let isInitialized = false
const ERUDA_KEY = 'enable-eruda'

export const [erudaEnabled, setErudaEnabled] = signal(false)

/** React hook. Enable Eruda (https://github.com/liriliri/eruda) */
export function useEruda() {
  useEffect(() => {
    const shouldEnable = localStorage.getItem(ERUDA_KEY) === '1'
    setErudaEnabled(shouldEnable)
    if (shouldEnable) {
      console.log('🪲 Re-enabling Eruda...')
      initializeEruda()
    }
    isInitialized = true
  }, [])

  useSignalEffect(() => {
    if (!isInitialized) return

    if (erudaEnabled()) {
      console.log('🪲 Enabling Eruda...')
      initializeEruda()
      localStorage.setItem(ERUDA_KEY, '1')
    } else {
      console.log('🪲 Disabling Eruda...')
      destroyEruda()
      localStorage.removeItem(ERUDA_KEY)
    }
  })
}

export async function initializeEruda() {
  eruda = await import('eruda').then(m => m.default)
  eruda?.init()
}

export const destroyEruda = (): void => eruda?.destroy()
