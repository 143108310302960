import React, { type FunctionComponent } from "react";
import { OrderKitchenStatus, type TOrder } from "@/pos/OrderType.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import {
  filterPrinter, getNameKitchenPrinter, groupItemsPrinter, setItemStatus,
  setOrderStatus
} from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type ArchivedConfirmPopupType = {
  onClose?: () => void;
  order?: TOrder;
  closeParent?: () => void;
};

const ArchivedConfirmPopup: FunctionComponent<
  ArchivedConfirmPopupType
> = ({ onClose, order, closeParent }) => {


  const onCompleteOrder: () => Promise<void> = async () => {
    if (order?._id) {
      console.log("completed order: ", order?._id || '')
      await setOrderStatus(order?._id, OrderKitchenStatus.DONE)
    } else {
      console.log("order is not found")
    }
    onClose?.()
    closeParent?.()
  }

  const onArchivedOrder: () => Promise<void> = async () => {
    if (order?._id) {
      console.log("archived order: ", order?._id)
      await setOrderStatus(order?._id, OrderKitchenStatus.ARCHIVED)
    } else {
      console.log("order is not found")
    }
    onClose?.()
    closeParent?.()
  }

  const groupItemsNotDone = () => {
    const items = order?.items?.filter(item => !item.isKitchenDone) || []
    return items?.reduce<Record<string, typeof items>>((result, item) => {
      const groupKey = `${item.groupPrinter || "undefined"},${item.groupPrinter2 || "undefined"}`;
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(item);
      return result;
    }, {});
  }

  return (
    <div
      className="w-[400px] bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border relative">
      <div
        className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
        onClick={onClose}
      >
        <img
          className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
          alt=""
          src="/icongeneralclose-popup-icon--grey@2x.png"
        />
      </div>

      <div style={{fontSize: (deviceSetting0()?.screenConfig?.kitchenFontSize || 14) + 6}} className="w-full pt-[24px] self-stretch flex flex-row items-center justify-start text-start">
        {order?.kitchenStatus === OrderKitchenStatus.PREPARE && LL0().kitchenMonitor.completeOrderPrompt()}
        {order?.kitchenStatus === OrderKitchenStatus.DONE && LL0().kitchenMonitor.archiveOrderPrompt()}
      </div>
      {(order?.kitchenStatus === OrderKitchenStatus.PREPARE) &&
        <div className="w-full" style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}}>
          {LL0().kitchenMonitor.incompleteItemsNotice()}
          <div className="ml-2 overflow-y-auto no-scrollbar max-h-[320px]">
            {Object.entries(groupItemsNotDone()).map(([key, items], index) => (
              <div key={index}>
                <p
                  className="text-red-solid-red-660-8f181d">{key.split(",").filter(id => id !== "undefined").length ? key.split(",").filter(id => id !== "undefined").map(id => getNameKitchenPrinter(id)).join(', ') : LL0().kitchenMonitor.cannotFindPrinter()}:</p>
                {items?.map((item, idx) => (
                  <p key={idx} style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}} className="ml-2">{item.quantity}x {item.name}</p>
                ))}
              </div>
            ))}
          </div>
        </div>}
      <div
        className="pt-6 self-stretch flex flex-row items-start justify-end pt-1 px-0 pb-0 gap-[16px] z-[3] text-center" style={{fontSize: (deviceSetting0()?.screenConfig?.kitchenFontSize || 14) + 1}}>
        <div
          className="rounded-md box-border flex-1 h-12 flex flex-row items-center justify-center p-2.5 cursor-pointer border-[1px] border-solid border-gray-solid-gray-600-979797"
          onClick={onClose}
          ref={makeRipple}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        {order?.kitchenStatus === OrderKitchenStatus.PREPARE && <div
          className="flex flex-1 rounded bg-green-solid-green-420-a9e15d h-12 flex-row items-center justify-start py-0 px-2 box-border relative max-w-[200px] min-h-[36px]"
          ref={makeRipple}
          onClick={onCompleteOrder}
        >
          <img
            className="relative w-6 h-6 object-cover z-[0]"
            alt=""
            src="/iconcheckmark-icon@2x.png"
          />
          <div
            style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}}
            className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_19px)] font-semibold z-[1]">
            {LL0().kitchenMonitor.done()}
          </div>
        </div>}
        {order?.kitchenStatus === OrderKitchenStatus.DONE && <div
          className="flex flex-1 rounded bg-purple-solid-purple-200-c0c0ff h-12 flex-row items-center justify-start py-0 px-2 box-border relative max-w-[200px] min-h-[36px]"
          ref={makeRipple}
          onClick={onArchivedOrder}
        >
          <img
            className="relative w-6 h-6 object-cover z-[0]"
            alt=""
            src="/iconarchived-icon@2x.png"
          />
          <div
            style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}}
            className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_31px)] font-semibold z-[1]">
            {LL0().kitchenMonitor.archived()}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default ArchivedConfirmPopup;
