import debug from "debug";

export const kitchenFLow = debug('printer:print-kitchen');

export const invoiceFLow = debug('printer:print-invoice');

export const printDetailFLow = debug('print-detail:print-stack');

export const userFLow = debug('user-action:action');

export const printLabelFlow = debug('print-detail:print-label');

export const masterHandlerFLow = debug('master-handler:handler');

export const updateFlow = debug('view:update')

export const lockFlow = debug('app:lock');

export const appFlow = debug('app:app');
