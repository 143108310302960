import  { type FunctionComponent, useEffect } from "react";
import VerticalEditMenuForm from "./VerticalEditMenuForm.tsx";
import VerticalEditIngredientForm from "./VerticalEditIngredientForm.tsx";
import { editorMode0, ITEM_MODE } from "@/react/EditMenuView/EditMenuView.tsx";

export type EditMenuEditFormType = {
  className?: string;
  onClose?: () => void;
};

const VerticalEditMenuFormDrawer: FunctionComponent<EditMenuEditFormType> = ({
  className = "",
  onClose,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <div
      className={`!opacity-100 w-full h-[685px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start py-1 px-0 box-border gap-3 [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-bottom] opacity-[0] max-h-[90%] ${className}`}
      data-animate-on-scroll
    >
      <div className="w-[72px] relative rounded-[40px] bg-gray-solid-gray-380-cdcdcd h-1.5" />
      {(editorMode0() !== ITEM_MODE.INGREDIENT && editorMode0() !== ITEM_MODE.KEYBOARD) && <VerticalEditMenuForm />}
      {editorMode0() === ITEM_MODE.INGREDIENT && <VerticalEditIngredientForm />}
    </div>
  );
};

export default VerticalEditMenuFormDrawer;
