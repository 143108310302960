import { type FunctionComponent, useCallback, useEffect, useState } from 'react';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts';
import DeveloperOnlyVerifyPopu from '@dashboard/DeveloperOnlyVerifyPopu.tsx'
import { DEVELOPER_PASSWORD, INSTALLATION_PASSWORD } from '@dashboard/DashboardSettingPopup.tsx'
import { tseConfig0 } from '@/data/TseConfigHub';

import { isCashbookEnabled } from "@/data/PosSettingsSignal.ts";
import PortalPopup from "@order-view/PortalPopup.tsx";
import DataExportPopup from "@vertical-data-export/DataExportPopup.tsx";
import DashboardPopupTabButton from "@order-view/DashboardPopupTabButton.tsx";
import DashboardButtons from "@vertical-dashboard/DashboardButtons.tsx";
import OnlineMenuPopup from "@vertical-dashboard/OnlineMenuPopup.tsx";
import { totalOrder } from "@/react/PendingOrder/PendingOrderLogic.tsx";
import { isFeatureEnabled, PosFeatures } from '@/srm/features.logic';
import { todayPendingReservations } from '@/data/ReservationHub';

type OrderScreenFunctionsPopupType = {
  onClose?: () => void;
  className?: string;
};

const OrderScreenFunctionsPopup: FunctionComponent<
  OrderScreenFunctionsPopupType
> = ({ onClose, className }) => {
  const onNavigate = (screen: PosScreen) => {
    router.screen = screen;
    onClose?.();
  }

  const [isDataExportPopupOpen, setDataExportPopupOpen] = useState(false);
  const [isOnlineMenuPopupOpen, setOnlineMenuPopupOpen] = useState(false);
  const [isInstallationVerifyPopuOpen, setInstallationVerifyPopuOpen] = useState(false);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);


  const openDataExportPopup = useCallback(() => {
    setDataExportPopupOpen(true);
  }, []);

  const closeDataExportPopup = useCallback(() => {
    setDataExportPopupOpen(false);
  }, []);

  const openOnlineMenuPopup = useCallback(() => {
    setOnlineMenuPopupOpen(true);
  }, []);

  const closeOnlineMenuPopup = useCallback(() => {
    setOnlineMenuPopupOpen(false);
    onClose?.();
  }, []);

  const [isDeveloperOnlyVerifyPopuOpen, setDeveloperOnlyVerifyPopuOpen] =
    useState(false);

  return (
    <>

      <div
        className={`no-scrollbar overflow-y-auto !opacity-100 w-[500px] relative bg-white-solid-white-400-f2f2f2 flex flex-col items-end justify-start py-4 px-2.5 box-border [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-right] opacity-[0] h-full max-w-[90%] overflow-auto lg:w-[500px] sm:w-[440px] mq600:w-[360px] ${className}`}
      >
        <img
          className="w-5 relative h-5 object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[0]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="self-stretch relative sm:text-sm Tablet_600:text-smi">
              {LL0().order.orderStatus()}
            </b>
            <div
              className="self-stretch grid grid-cols-[repeat(2,1fr)] items-start justify-start gap-[12px] Tablet_768:gap-[8px]">
              {getAccessibility(PosScreen.VERTICAL_ORDER_HISTORY) &&
                <DashboardButtons
                  icon="/iconorder-history-icon1@2x.png"
                  buttonName={LL0().dashboard.orderHistory()}
                  chipValue="20"
                  showChip={false}
                  showIconPopup={false}
                  onClick={() => onNavigate(PosScreen.VERTICAL_ORDER_HISTORY)}
                />
              }
              {isFeatureEnabled(PosFeatures.PendingOrder) && <DashboardButtons
                icon="/iconpending-orders-icon2@2x.png"
                buttonName={LL0().onlineOrder.pendingOrders()}
                chipValue={totalOrder().toString()}
                showChip
                showIconPopup={false}
                onClick={() => onNavigate(PosScreen.VERTICAL_PENDING_ORDERS)}
              />}
              {/*<DashboardButtons*/}
              {/*  icon="/iconnew-delivery-icon@2x.png"*/}
              {/*  chipValue="Pickup"*/}
              {/*  showChip*/}
              {/*  onClick={() => onNavigate(PosScreen.VERTICAL_PICKUP)}*/}
              {/*/>*/}
              <DashboardButtons
                icon="/icononline-menu-icon71@2x.png"
                buttonName={LL0().dashboard.onlineMenu()}
                chipValue="20"
                showChip={false}
                showIconPopup={false}
                onClick={openOnlineMenuPopup}
              />
              {/*<DashboardButtons*/}
              {/*  icon="/iconshipping-status-icon@2x.png"*/}
              {/*  buttonName={LL0().dashboard.shippingStatus()}*/}
              {/*  chipValue="20"*/}
              {/*  showChip={false}*/}
              {/*  showIconPopup={false}*/}
              {/*  onClick={() => onNavigate(PosScreen.VERTICAL_SHIPPING_STATUS)}*/}
              {/*/>*/}
              <DashboardButtons
                icon="/iconreservation-icon101@2x.png"
                buttonName={LL0().dashboard.reservation()}
                chipValue={todayPendingReservations().toString()}
                showChip={true}
                showIconPopup={false}
                onClick={() => onNavigate(PosScreen.VERTICAL_RESERVATION)}
              />
              {getAccessibility(PosScreen.VIRTUAL_PRINTER) && <DashboardButtons
                icon="/iconvirtual-printer-icon@2x.png"
                buttonName={LL0().dashboard.virtualPrinter()}
                chipValue="20"
                showChip={false}
                showIconPopup={false}
                onClick={() => onNavigate(PosScreen.VERTICAL_VIRTUAL_PRINTER)}
              />}
            </div>
          </div>
          {<div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="self-stretch relative">{LL0().order.reports()}</b>
            <div
              className="self-stretch grid grid-cols-[repeat(2,1fr)] items-start justify-start gap-[12px] Tablet_768:gap-[8px]">
              {getAccessibility(PosScreen.VERTICAL_EOD) &&
                <DashboardButtons
                  icon="/iconend-of-day-icon5@2x.png"
                  buttonName={LL0().dashboard.endOfDay()}
                  chipValue="20"
                  showChip={false}
                  showIconPopup={false}
                  onClick={() => onNavigate(PosScreen.VERTICAL_EOD)}
                />
              }
              {getAccessibility(PosScreen.VERTICAL_MONTHLY_REPORT) &&
                <DashboardButtons
                  icon="/iconmonthly-report-icon61@2x.png"
                  buttonName={LL0().dashboard.monthlyReport()}
                  chipValue="20"
                  showChip={false}
                  showIconPopup={false}
                  onClick={() => onNavigate(PosScreen.VERTICAL_MONTHLY_REPORT)}
                />
              }
              {getAccessibility(PosScreen.STAFF_REPORT) && <DashboardButtons
                icon="/iconstaff-report-icon71@2x.png"
                buttonName={LL0().dashboard.staffReport()}
                chipValue="20"
                showChip={false}
                showIconPopup={false}
                onClick={() => onNavigate(PosScreen.VERTICAL_STAFF_REPORT)}
              />}
              {isCashbookEnabled() && <DashboardButtons
                icon="/iconcashbook-icon111@2x.png"
                buttonName={LL0().dashboard.cashbook()}
                chipValue="20"
                showChip={false}
                showIconPopup={false}
                onClick={() => onNavigate(PosScreen.VERTICAL_CASHBOOK)}
              />
              }
            </div>
          </div>}
        </div>
        {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) && <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <b className="self-stretch relative">{LL0().dashboard.settings()}</b>
          <div
            className="self-stretch grid grid-cols-[repeat(2,1fr)] items-start justify-start gap-[12px] Tablet_768:gap-[8px]">
            <DashboardButtons
              icon="/icongeneral-settings-icon34@2x.png"
              buttonName={LL0().dashboard.generalSetting()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_GENERAL_SETTINGS)}
            />
            {getAccessibility(PosScreen.VERTICAL_EDIT_MENU) &&
              <DashboardButtons
                icon="/iconedit-menu-icon8@2x.png"
                buttonName={LL0().order.menuEditing()}
                chipValue="20"
                showChip={false}
                showIconPopup={false}
                onClick={() => onNavigate(PosScreen.VERTICAL_EDIT_MENU)}
              />
            }
            <DashboardButtons
              icon="/iconuser-settings-icon9@2x.png"
              buttonName={LL0().sidebar.user()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_USER_SETTING)}
            />
            <DashboardButtons
              icon="/iconhappy-hour-icon10@2x.png"
              buttonName={LL0().restaurant.product.happyHour()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_HAPPY_HOUR)}
            />
            <DashboardButtons
              icon="/iconcompany-info-icon11@2x.png"
              buttonName={LL0().dashboard.companyInfo()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_COMPANY_INFO)}
            />
            <DashboardButtons
              icon="/iconpayment-settings-icon12@2x.png"
              buttonName={LL0().dashboard.taxAndPayment()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_TAX_PAYMENT)}
            />
            <DashboardButtons
              icon="/iconprinter-settings-icon13@2x.png"
              buttonName={LL0().productViewSetting.modifierEditorDialog.printer()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_PRINTER_SETTING)}
            />
            <DashboardButtons
              icon="/icon-master-machine@2x.png"
              buttonName={LL0().dashboard.masterMachine()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => onNavigate(PosScreen.VERTICAL_SET_MASTER_MACHINE)}
            />
            <DashboardButtons
              icon="/icon-developer-only-icon@2x.png"
              buttonName={LL0().dashboard.developerOnly()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => {
                if (import.meta.env.MODE === 'production') {
                  setDeveloperOnlyVerifyPopuOpen(true)
                } else {
                  onClose?.();
                  router.screen = PosScreen.VERTICAL_DEVELOPER_ONLY;
                }
              }}
            />
            <DashboardButtons
              icon="/icongeneral-settings-icon11@2x.png"
              buttonName="Installation"
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={() => {
                if (import.meta.env.MODE === 'production') {
                  setInstallationVerifyPopuOpen(true)
                } else {
                  onClose?.();
                  router.screen = PosScreen.VERTICAL_INSTALLATION;
                }
              }}
            />
            {tseConfig0()?.tseEnable && (<DashboardButtons
              icon="/icondata-export-icon@2x.png"
              buttonName={LL0().dashboard.dataExport()}
              chipValue="20"
              showChip={false}
              showIconPopup={false}
              onClick={openDataExportPopup}
            />)}
            {/*<DashboardButtons*/}
            {/*  icon="/iconweb-menu-icon@2x.png"*/}
            {/*  buttonName={LL0().dashboard.webMenu()}*/}
            {/*  chipValue="20"*/}
            {/*  showChip={false}*/}
            {/*  showIconPopup={false}*/}
            {/*  onClick={() => onNavigate(PosScreen.VERTICAL_WEB_MENU)}*/}
            {/*/>*/}
          </div>
        </div>}
      </div>

      {isDataExportPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDataExportPopup}
        >
          <DataExportPopup onClose={closeDataExportPopup} />
        </PortalPopup>
      )}
      {isOnlineMenuPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOnlineMenuPopup}
        >
          <OnlineMenuPopup onClose={closeOnlineMenuPopup} />
        </PortalPopup>
      )}
      {isDeveloperOnlyVerifyPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setDeveloperOnlyVerifyPopuOpen(false)} >
          <DeveloperOnlyVerifyPopu
            onClose={() => setDeveloperOnlyVerifyPopuOpen(false)}
            password={DEVELOPER_PASSWORD}
            onComplete={() => {
              onClose?.();
              router.screen = PosScreen.VERTICAL_DEVELOPER_ONLY;
            }}
          />
        </PortalPopup>
      }
      {isInstallationVerifyPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setDeveloperOnlyVerifyPopuOpen(false)} >
          <DeveloperOnlyVerifyPopu
            onClose={() => setInstallationVerifyPopuOpen(false)}
            password={INSTALLATION_PASSWORD}
            onComplete={() => {
              onClose?.();
              router.screen = PosScreen.VERTICAL_INSTALLATION;
            }}
          />
        </PortalPopup>
      }
    </>
  );
};

export default OrderScreenFunctionsPopup;