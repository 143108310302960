import { type FunctionComponent, memo } from "react";
import EditMenuLeft from "./EditMenuLeft";
import EditMenuForm from "./EditMenuForm/EditMenuForm.tsx";
import EditIngredientForm from "./EditIngredientForm.tsx";
import { editorMode0, ITEM_MODE } from '@/react/EditMenuView/EditMenuView.tsx';
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import VerticalEditMenuView from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";
import VerticalEditIngredientForm from "@vertical-edit-menu/VerticalEditIngredientForm.tsx";

const EditMenuPlugin: FunctionComponent = () => {

  return (
    <div
      className="edit-menu-screen !opacity-100 rounded-md w-full h-full flex flex-row items-start justify-start !bg-[url('/edit-menu-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] [&.animate]:animate-[1s_ease_0s_1_normal_forwards_fade-in] opacity-[0]"
    >
      {
        generalSetting0()?.useMenuV2 ?
          <VerticalEditMenuView />
          :
          <EditMenuLeft />
      }
      <div className="w-[310px] self-stretch bg-white-solid-white-100-ffffff flex flex-row items-start justify-start gap-[24px] sm:w-[260px] Tablet_600:w-60">
        {
          editorMode0() !== ITEM_MODE.INGREDIENT ?
            <EditMenuForm />
            :
            <>
              {generalSetting0()?.useMenuV2 ? <VerticalEditIngredientForm /> : <EditIngredientForm />}
            </>
        }
      </div>
    </div>
  );
};

export default memo(EditMenuPlugin);
