import { type FunctionComponent, useState, useCallback } from "react";
import PasscodeScreenChooseServer from "./PasscodeScreenChooseServer";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { HANDLERS, updateAvailable } from "@/react/PasscodeView/PasscodeView";
import PasscodeScreenViewLicenseP from "@passcode/PasscodeScreenViewLicenseP.tsx";
import ButtonWrapper from "@/react/core/ButtonWrapper.tsx";
import { startSyncit } from "@/pos/logic/syncit-utils.ts";
import { onReconnect } from "@/react/Developer/developer.logic.ts";
import { server } from "@/data/Server.ts";
import { posSync0 } from "@/data/PosSyncState.ts";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { isMaster } from "@/lib/master-signal.ts";

type MoreButtonType = {
  isDropdown?: boolean;
};

const MoreButton: FunctionComponent<MoreButtonType> = ({
  isDropdown = true,
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);
  const [isPasscodeScreenViewLicensePOpen, setPasscodeScreenViewLicensePOpen] = useState(false);
  isDropdown = isDropdownOpened;

  const [isPasscodeScreenChooseServerOpen, setPasscodeScreenChooseServerOpen] =
    useState(false);

  const openPasscodeScreenChooseServer = useCallback(() => {
    setPasscodeScreenChooseServerOpen(true);
    setIsDropdownOpened(false);
  }, []);

  const openLicensePopup = useCallback(() => {
    setPasscodeScreenViewLicensePOpen(true);
    setIsDropdownOpened(false);
  }, []);

  const closePasscodeScreenChooseServer = useCallback(() => {
    setPasscodeScreenChooseServerOpen(false);
  }, []);

  const closeLicensePopup = useCallback(() => {
    setPasscodeScreenViewLicensePOpen(false);
  }, []);

  const toggleDropDown = useCallback(() => setIsDropdownOpened(open => {
    userFLow(`open dropdown left in passcode`, {
      username: loginUser()?.name
    })
    // Check for update when the dropdown is currently closed and going to be open
    if (!open) HANDLERS.checkUpdate()
    return !open
  }), []);

  return (
    <>
      {isDropdownOpened &&
        <div className="z-[1] w-screen h-screen fixed opacity-0 top-0 left-0"
             onClick={() => setIsDropdownOpened(false)} />
      }
      <div
        className="w-[35px] h-[35px] cursor-pointer flex flex-col items-start justify-start gap-[7px] text-left text-mini text-black-solid-black-400-424242 font-mulish [&_.choose-server-option]:active:flex">
        <img
          className="w-[35px] relative h-[35px] " alt="" src="/bar-icon.svg"
          onClick={toggleDropDown}
        />
        {isDropdown &&
          <div
            className="no-scrollbar overflow-y-auto absolute top-full z-40 h-[calc(100vh_-_100px)] max-h-[360px] choose-server-option rounded bg-[#f3f9ff] shadow-[0px_3px_4px_rgba(0,_0,_0,_0.3)] box-border flex flex-col items-start pt-1.5 px-0 pb-[7px] min-w-[264px] border-[1px] border-solid border-black-solid-black-400-424242"
          >
            <div
              className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
              <div
                className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                onClick={() => {
                  userFLow("Passcode reload", {
                    username: loginUser()?.name
                  })
                  location.reload();
                }}
                ref={makeRipple}
              >
                <div className="relative font-semibold">{LL0().login.restart()}</div>
              </div>
            </div>

            <div
              className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
              <ButtonWrapper
                onClick={async () => {
                  userFLow("Passcode updated new version", {
                    username: loginUser()?.name
                  })
                  await HANDLERS.updateNow()
                }}
                isDisabled={!updateAvailable()}
                className={'w-full'}
              >
                <div
                  className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                >
                  <div className="relative font-semibold">{LL0().login.updateNow()}</div>
                </div>
              </ButtonWrapper>
            </div>

            <div
              className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
              <ButtonWrapper
                isDisabled={true}
                className={'w-full'}
              >
                <div
                  className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                >
                  <div className="relative font-semibold">{LL0().login.reboot()}</div>
                </div>
              </ButtonWrapper>
            </div>

            <div
              className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
              <div
                className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                onClick={openPasscodeScreenChooseServer}
                ref={makeRipple}
              >
                <div className="relative font-semibold">{LL0().login.chooseServer()}</div>
              </div>
            </div>
            <div
              className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
              <div
                className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                onClick={openLicensePopup}
                ref={makeRipple}
              >
                <div className="relative font-semibold">{LL0().posSetup.accountTab.viewLicense()}:</div>
              </div>
            </div>
            <div
              className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
              <div
                className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                onClick={startSyncit}
                ref={makeRipple}
              >
                <div className="relative font-semibold">{LL0().login.remoteControl()}</div>
              </div>
            </div>
            {/*Vertical mode button*/}
            {/*<div*/}
            {/*  className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">*/}
            {/*  <div*/}
            {/*    className="self-stretch rounded-81xl [background:linear-gradient(183.09deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#ff1ea5] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"*/}
            {/*    ref={makeRipple}*/}
            {/*  >*/}
            {/*    <div className='flex flex-row gap-2 items-center justify-center'>*/}
            {/*      <img src='/VerticalModeWhite.png' alt='' className='w-[18px] h-[18px] object-cover' />*/}
            {/*      <div className="relative font-semibold">{LL0().login.verticalMode()}</div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {posSync0()?.id &&
              <div
                className="self-stretch flex flex-col items-start justify-start pt-1 px-3.5 pb-[3px] text-white-solid-white-100-ffffff">
                <div
                  className="self-stretch rounded-81xl [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.5),_rgba(0,_0,_0,_0.5)),_#585efa] flex flex-row items-center justify-center py-[5px] px-5 cursor-pointer"
                  onClick={onReconnect}
                  ref={makeRipple}
                >
                  <div className="relative font-semibold">{LL0().login.reconnect()}</div>
                </div>
              </div>}
            <div className="self-stretch flex flex-row items-start justify-start py-[7px] px-3.5 gap-[8px]">
              <div className="relative font-semibold"> {LL0().login.server()}:</div>
              <div className="relative font-semibold text-text-color-green-00c37c capitalize">
                {server()}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[7px] px-3.5 gap-[8px]">
              <div
                className="relative font-semibold">{`${LL0().onlineOrder.settings.deviceName()} ${LL0().article.id()}:`}</div>
              <div className="relative font-semibold text-text-color-green-00c37c">
                {deviceSetting0()?._id}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[7px] px-3.5 gap-[8px]">
              <div className="relative font-semibold">{LL0().login.version()}:</div>
              <div className="relative font-semibold text-text-color-green-00c37c">
                {import.meta.env.VITE_APP_VERSION} ({import.meta.env.VITE_APP_DATE})
              </div>
            </div>
            {
              isMaster() ?
                <div
                  className="self-stretch flex flex-row items-start justify-start py-1.5 px-3.5 text-orange-solid-orange-300-ff6d2e">
                  <i className="relative font-semibold">~ {LL0().login.isMasterDevice()}</i>
                </div> :
                <div
                  className="self-stretch shrink-0 flex flex-row items-start justify-start py-1.5 px-3.5 text-orange-solid-orange-300-ff6d2e">
                  <i className="relative font-semibold">~ {LL0().login.isClientDevice()}</i>
                </div>
            }
            <div
              className="self-stretch shrink-0 flex flex-row items-start justify-start pt-1 px-3.5 pb-1.5 text-[#008df4]">
              <i className="relative font-semibold">~ {LL0().login.syncViaWifi()}</i>
            </div>
          </div>}
      </div>
      {isPasscodeScreenChooseServerOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePasscodeScreenChooseServer}
        >
          <PasscodeScreenChooseServer
            onClose={closePasscodeScreenChooseServer}
          />
        </PortalPopup>
      )}
      {isPasscodeScreenViewLicensePOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeLicensePopup}
        >
          <PasscodeScreenViewLicenseP
            onClose={closeLicensePopup}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default MoreButton;
