import debug from 'debug'
import _ from 'lodash'
import { toast } from 'react-toastify'
import type { RxDocument } from 'rxdb'

import { Modifier } from '@/data/Modifier'
import { Product } from '@/data/Product'
import { TaxType, type TaxCategory } from '@/data/TaxCategory'
import { busyIndicator, requireConfirmation } from '@/shared/decorators'

import { LL0 } from '../core/I18nService'
import { signal } from '../core/reactive'
import { Icons } from '../SystemService/msgBox'

const log = debug('dev:payment-setting')

const [applyingTaxToAllProduct, setApplyingTaxToAllProduct] = signal(false)

class PaymentSettingViewLogic {
  @requireConfirmation(t => ({
    title: LL0().ui.confirmation(),
    content: LL0().tax.applyTaxToAllConfirm({ name: t.name ?? '_' }),
    icons: Icons.Warning,
  }))
  @busyIndicator(setApplyingTaxToAllProduct)
  async applyTaxToAllProduct(taxCategory: TaxCategory) {
    const taxValue = taxCategory.value ?? _.sumBy(taxCategory.components, 'value')
    const taxComponents = taxCategory.components ? _.cloneDeep(taxCategory.components) : undefined

    // Counter
    const count = { products: 0, modifiers: 0 }
    const updatedProducts: RxDocument<Product>[] = []

    if ([TaxType.COMBO, TaxType.HST, TaxType.DRINK_DINE_IN, TaxType.FOOD_DINE_IN].includes(taxCategory.type)) {
      log(`Applying dine-in tax to all products...`)
      updatedProducts.push(
        ...(await Product.find({ selector: { taxCategory: taxCategory.name } }).update({
          $set: { tax: taxValue, ...(taxComponents ? { taxComponents } : {}) },
        })),
        ...(await Product.find({ selector: { menuTaxes: { $exists: true, $elemMatch: { ref: taxCategory.name } } } }).update({
          $set: { 'menuTaxes.$[].value': taxValue },
        }))
      )
    }
    if ([TaxType.COMBO, TaxType.HST, TaxType.DRINK_TAKE_AWAY, TaxType.FOOD_TAKE_AWAY].includes(taxCategory.type)) {
      log(`Applying take away tax to all products...`)
      updatedProducts.push(
        ...(await Product.find({ selector: { taxCategory2: taxCategory.name } }).update({
          $set: { tax2: taxValue, ...(taxComponents ? { taxComponents2: taxComponents } : {}) },
        })),
        ...(await Product.find({ selector: { menuTaxes2: { $exists: true, $elemMatch: { ref: taxCategory.name } } } }).update({ $set: { 'menuTaxes2.$[].value': taxValue } }))
      )
    }

    log(`Applying tax to all modifiers: ${taxCategory.name}`)
    const updatedModifier = await Modifier.find({ selector: { items: { $elemMatch: { tax: { $exists: true, $ne: taxValue } } } } }).update({
      $set: { 'items.$[].tax': taxValue },
    })

    count.products += _.uniqBy(updatedProducts, a => a._id).length
    count.modifiers += updatedModifier.length

    log(`Tax applied to ${count.products} product(s) and ${count.modifiers} modifier(s)`)
    toast.success(`Tax applied to ${count.products} product(s) and ${count.modifiers} modifier(s)`)
  }
}

export const paymentSettingViewLogic = new PaymentSettingViewLogic()
export { applyingTaxToAllProduct }
