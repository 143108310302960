import { type FunctionComponent, useCallback, useState } from 'react';
import DeleteConfirmationPopup from '@edit-room/DeleteConfirmationPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import _ from 'lodash';
import { activeRoom, deleteRoom, EditorMode, isSelectedMode, liftRoom } from '@/react/EditRoomView/EditRoomView.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import {LL0} from "@/react/core/I18nService.tsx";
import TextField from '@/react/core/TextField.tsx';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type EditRoomFormType = {
  isRoomEdit?: boolean;
};

const EditRoomForm: FunctionComponent<EditRoomFormType> = ({
  isRoomEdit = false,
}) => {
  isRoomEdit = isSelectedMode(EditorMode.Room)

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);

  const openDeleteConfirmationPopup = useCallback(() => {
    userFLow(`open delete confirmation popup`, {
      username: loginUser()?.name
    });
    setDeleteConfirmationPopupOpen(true);
  }, []);

  const closeDeleteConfirmationPopup = useCallback(() => {
    userFLow(`close delete confirmation popup`, {
      username: loginUser()?.name
    });
    setDeleteConfirmationPopupOpen(false);
  }, []);

  return (
    <>
      {isRoomEdit && (
        <div className="!flex self-stretch bg-basic-color-white shadow-[2px_2px_18px_rgba(121,_125,_183,_0.14)] hidden flex-col items-center justify-start p-[11px] gap-[11px] text-left text-mini text-royalblue-300 font-mulish border-[1px] border-solid border-whitesmoke-200">
          <div className="self-stretch flex flex-col items-start justify-start gap-[9px]">
            <b className="self-stretch relative">{LL0().restaurant.roomName()}:</b>
            <TextField
              className="self-stretch"
              color="primary"
              name="roomName"
              size="small"
              label={LL0().restaurant.roomName()}
              placeholder={LL0().restaurant.roomName()}
              variant="outlined"
              onChange={(e) => _.assign(activeRoom(), {name: e.target.value })}
              value={activeRoom()?.name || ''}
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between text-smi text-tomato-200">
            <div className="flex flex-row items-center justify-start gap-[13px]">
              <div className="rounded shadow-[0px_0px_4px_#7cb3ff] box-border w-[35px] h-9 flex flex-row items-center justify-center border-[1px] border-solid border-gray-300"
                  onPointerDown={() => liftRoom('UP')}
                   ref={makeRipple}
              >
                <img
                  className="relative w-[23px] h-[15px]"
                  alt=""
                  src="/lift-up-icon.svg"
                />
              </div>
              <div className="rounded shadow-[0px_0px_4px_#7cb3ff] box-border w-[35px] h-9 flex flex-row items-center justify-center border-[1px] border-solid border-gray-300"
                   onPointerDown={() => liftRoom('DOWN')}
                   ref={makeRipple}

              >
                <img
                  className="relative w-[23px] h-[15px]"
                  alt=""
                  src="/lift-down-icon.svg"
                />
              </div>
            </div>
            <div
              className="rounded-md flex flex-row items-center justify-center py-[9.5px] px-[18px] gap-[8px] cursor-pointer border-[1px] border-solid border-tomato-100 md:pl-3 md:pr-3 md:box-border"
              onClick={openDeleteConfirmationPopup}
            >
              <img
                className="relative w-[17px] h-[17px] object-cover"
                alt=""
                src="/iconbin-icon--red@2x.png"
              />
              <b className="relative leading-[15px] inline-block w-[42.8px] shrink-0 md:hidden">
                {LL0().ui.delete()}
              </b>
            </div>
          </div>
        </div>
      )}
      {isDeleteConfirmationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup} onComplete={deleteRoom} />
        </PortalPopup>
      )}
    </>
  );
};

export default EditRoomForm;
