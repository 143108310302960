import { type FunctionComponent, useContext } from "react";
import SeatItem from "./SeatItem";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { handleAddSeat, onPrintPay, orderMode0 } from "@/react/OrderView/OrderView.tsx";
import { isOrderViewCart, order0, OrderMode, setIsOrderViewCart } from "@/react/OrderView/OrderViewShare.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { calculateItemLength } from "@/react/VerticalOrderView/VerticalOrderView.tsx";
import { OrderStatus } from "@/pos/OrderType.ts";
import clsx from "clsx";
import { params } from "@/pos/PosRouter.ts";

export type OrderScreenFloatBtnWrapperType = {
  className?: string;
  isSplitSeat?: boolean;
  isCart?: boolean;
  isPrint?: boolean;
};

const OrderScreenFloatBtnWrapper: FunctionComponent<
  OrderScreenFloatBtnWrapperType
> = ({
  className = "",
  isSplitSeat,
  isCart,
  isPrint,
}) => {

  const {
    seat,
    currentSeat,
    setSeat,
    splitEnable,
  } = useContext(ItemFactoryContext);

  isSplitSeat = order0()?.seatMode && (!isOrderViewCart() || splitEnable?.())
  isPrint = isOrderViewCart() && !order0()?.payable
  isCart = !isOrderViewCart()


  const isSelectedSeat = (index: number) => {
    if (!isOrderViewCart()) {
      return seat?.() === index
    } else {
      return currentSeat?.() === index
    }
  }
  return (
    <div
      className={`w-full !m-[0] relative right-[0%] bottom-[0%] left-[0%] [backdrop-filter:blur(24px)] bg-gray-opacity-gray-30-b3b3b3 flex flex-col items-center justify-start pt-1.5 px-2.5 pb-2.5 box-border gap-[8px] z-[3] text-left text-lg text-white-solid-white-100-ffffff font-mulish ${className}`}
    >
      {isSplitSeat && (
        <div className="self-stretch rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 text-sm text-black-solid-black-1000-000000">
          <div className="flex-1 grid flex-row flex-wrap items-center justify-start gap-[4px] grid-cols-[repeat(auto-fill,_minmax(94px,_1fr))] z-[0] lg:grid lg:grid-cols-[repeat(auto-fill,_minmax(124px,_1fr))] md:grid md:grid-cols-[repeat(auto-fill,_minmax(124px,_1fr))] sm:grid sm:grid-cols-[repeat(auto-fill,_minmax(72px,_1fr))] mq600:grid mq600:grid-cols-[repeat(auto-fill,_minmax(72px,_1fr))] mq432:grid mq432:grid-cols-[repeat(auto-fill,_minmax(72px,_1fr))]">
            {order0()?.seatMode &&
              order0().seatMap!.filter(seatOrder => seatOrder.status !== OrderStatus.PAID).map((seatOrder, index) => (
                <SeatItem
                  _setSeat={setSeat}
                  isCart={isCart}
                  key={index}
                  seatNumber={seatOrder.seat!}
                  isSselected={isSelectedSeat(index)}
                  quantity={calculateItemLength(seatOrder)}
                />
              ))}
            {!isOrderViewCart() &&
              <div onClick={handleAddSeat} className="flex-1 rounded-10xs bg-blue-solid-blue-100-c9e2ff h-8 flex flex-row items-center justify-center py-1 px-2 box-border min-w-[64px] text-blue-solid-blue-470-356ff6 sm:min-w-[72px] mq600:min-w-[72px] mq432:min-w-[72px]">
                <b className="relative sm:text-smi mq600:text-smi mq432:text-smi">
                  +{LL0().inventory.add()}
                </b>
              </div>}
          </div>
        </div>
      )}
      <div className={'w-full gap-[8px] flex flex-row'}>
        {!isPrint && isCart && (
          <div
            onClick={() => {
              if (!order0().payable) return;
              onPrintPay(true)
              setIsOrderViewCart(false)
            }}
            ref={makeRipple}
            className={clsx("w-full flex-1 self-stretch rounded bg-blue-solid-blue-370-0094ff h-12 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-5 box-border gap-[4px] lg:h-14 md:h-14 sm:h-14 mq600:h-14 mq432:h-12", order0().payable ? '' : 'opacity-60')}>
            <img
              className="w-9 relative h-9 object-cover lg:w-10 lg:h-10 md:w-10 md:h-10 sm:w-10 sm:h-10 mq600:w-10 mq600:h-10 mq432:w-9 mq432:h-9"
              alt=""
              src="/iconorderpay-icon-ver1@2x.png"
            />
            {/*<i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">*/}
            {/*  {LL0().orderHistory.pay().toUpperCase()}*/}
            {/*</i>*/}
            <i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">
              {LL0().format.currency(order0()?.vSum || 0)}
            </i>
          </div>
        )}
        {isCart && (
          <div
            className="w-full flex-1 self-stretch rounded bg-[#FF9519] h-12 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-5 box-border gap-[8px] text-center text-base lg:h-14 md:h-14 sm:h-14 mq600:h-14 mq432:h-12 mq414:h-12 mq360:h-12 mq340:h-12 mq320:h-12"
            onClick={() => setIsOrderViewCart(true)}
            ref={makeRipple}
          >
            <div
              className="w-8 rounded-981xl [background:linear-gradient(180deg,_#fb6b6b,_#fd0000)] h-8 flex flex-row items-center justify-center lg:w-9 lg:h-9 md:w-9 md:h-9 sm:w-9 sm:h-9 mq600:w-9 mq600:h-9 mq432:w-[34px] mq432:h-[34px]">
              {order0()?.items &&
                <div
                  className="relative font-extrabold lg:text-mid md:text-mid sm:text-mid mq600:text-mid mq432:text-base">
                  {calculateItemLength(order0())}
                </div>}
            </div>
            <i
              className="relative text-lg font-extrabold text-left lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">
              {LL0().order.cart().toUpperCase()}
            </i>
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconorder-screencart-arrow-right@2x.png"
            />
          </div>
        )}
      </div>

      {/*{isCartDisable && (*/}
      {/*  <div className="self-stretch rounded [background:linear-gradient(180deg,_#2196f3,_#0a80de)] h-12 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-5 box-border gap-[8px] opacity-[0.5] lg:h-14 md:h-14 sm:h-14 mq600:h-14 mq432:h-12 mq414:h-12 mq360:h-12 mq340:h-12 mq320:h-12">*/}
      {/*    <i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">*/}
      {/*      CART*/}
      {/*    </i>*/}
      {/*    <img*/}
      {/*      className="w-6 relative h-6 object-cover"*/}
      {/*      alt=""*/}
      {/*      src="/iconorder-screencart-arrow-right@2x.png"*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*todo: order.payable*/}
      {/*{(!isPrint && !isCart && !isSplitSeat) && (*/}
      {/*  <div*/}
      {/*    onClick={() => {*/}
      {/*      onPrintPay(true)*/}
      {/*      setIsOrderViewCart(false)*/}
      {/*    }}*/}
      {/*    ref={makeRipple}*/}
      {/*    className="self-stretch rounded bg-blue-solid-blue-370-0094ff h-12 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-5 box-border gap-[4px] lg:h-14 md:h-14 sm:h-14 mq600:h-14 mq432:h-12">*/}
      {/*    <img*/}
      {/*      className="w-9 relative h-9 object-cover lg:w-10 lg:h-10 md:w-10 md:h-10 sm:w-10 sm:h-10 mq600:w-10 mq600:h-10 mq432:w-9 mq432:h-9"*/}
      {/*      alt=""*/}
      {/*      src="/iconorderpay-icon-ver1@2x.png"*/}
      {/*    />*/}
      {/*    <i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">*/}
      {/*      {LL0().orderHistory.pay().toUpperCase()}*/}
      {/*    </i>*/}
      {/*    <i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">*/}
      {/*      {LL0().format.currency(order0()?.vSum || 0)}*/}
      {/*    </i>*/}
      {/*  </div>*/}
      {/*)}*/}

      {isPrint && (
        <div
          onClick={() => {
            onPrintPay(true)
            setIsOrderViewCart(false)
          }}
          ref={makeRipple}
          className="self-stretch rounded bg-orange-solid-orange-800-f25936 h-12 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-5 box-border gap-[4px] lg:h-14 md:h-14 sm:h-14 mq600:h-14 mq432:h-12">
          <img
            className="w-9 relative h-9 object-cover lg:w-10 lg:h-10 md:w-10 md:h-10 sm:w-10 sm:h-10 mq600:w-10 mq600:h-10 mq432:w-9 mq432:h-9"
            alt=""
            src="/iconorderprint-icon-ver1@2x.png"
          />
          <i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">
            {LL0().order.receipt.print().toUpperCase()}
          </i>
          <i className="relative font-extrabold lg:text-xl md:text-xl sm:text-xl mq600:text-xl mq432:text-lg">
            {LL0().format.currency(order0()?.vSum || 0)}
          </i>
        </div>
      )}
    </div>
  );
};

export default OrderScreenFloatBtnWrapper;
