import type { FunctionComponent } from "react";
import { useSignal } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { CustomDish } from "@/react/EditMenuView/EditMenuView.tsx";
import { kitchenGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { dataCustomDish, setDataCustomDish } from "@/react/OrderView/OrderView.tsx";

export type EditMenuCustomDishPopupType = {
  className?: string;
  onClose?: () => void;
};


const EditMenuCustomDishPopup: FunctionComponent<
  EditMenuCustomDishPopupType
> = ({ className = "", onClose }) => {

  const [customDishTab, setCustomDishTab] = useSignal<CustomDish>(CustomDish.FOOD)

  return (
    <div
      className={`!w-[448px] w-full relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-[18px] px-0 pb-0 box-border gap-4 h-full max-w-full max-h-full text-left text-base text-black-solid-black-1000-000000 font-mulish ${className}`}
    >
      <div className="self-stretch overflow-hidden flex flex-row items-center justify-between py-0 px-4">
        <b className="flex-1 relative">Custom Dish</b>
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon1@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="self-stretch rounded-10xs border-gray-solid-gray-170-e3e4e5 border-[1px] border-solid flex flex-row items-start justify-start p-1 gap-2 text-center text-sm">
        <div className="flex-1 rounded-10xs h-8 flex flex-row items-center justify-center py-0 px-0.5 box-border relative gap-1"
             onClick={() => setCustomDishTab(CustomDish.FOOD)}
             ref={makeRipple}
        >
          {customDishTab() === CustomDish.FOOD &&
            <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-400-0091ff z-[0]" />
          }
          {customDishTab() !== CustomDish.FOOD ?
            <div className="relative leading-[15px] capitalize font-semibold z-[1] mq350small:text-sm">
              FOOD
            </div>
            :
            <div className="flex-1 relative leading-[15px] capitalize font-semibold text-white-solid-white-100-ffffff z-[2] mq350small:text-sm">
              FOOD
            </div>
          }
        </div>
        <div className="flex-1 rounded-10xs h-8 flex flex-row items-center justify-center py-0 px-0.5 box-border relative gap-1"
             onClick={() => setCustomDishTab(CustomDish.DRINK)}
             ref={makeRipple}
        >
          {customDishTab() === CustomDish.DRINK &&
            <div className="!flex w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-400-0091ff hidden z-[0]" />
          }
          {customDishTab() !== CustomDish.DRINK ?
            <div className="flex-1 relative leading-[15px] capitalize font-semibold z-[1] mq350small:text-sm">
              DRINK
            </div>
            :
            <div className="flex-1 relative leading-[15px] capitalize font-semibold text-white-solid-white-100-ffffff z-[2] mq350small:text-sm">
              DRINK
            </div>
          }
        </div>
      </div>

      <div className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-6 text-black-solid-black-900-1e1e23">
        <div className="self-stretch overflow-y-auto shrink-0 flex flex-col items-start justify-start gap-4">
          <div className="self-stretch flex flex-col items-start justify-center gap-2">
            <div className="relative font-semibold md:text-xs">Printers:</div>
            <div className="gap-2 self-stretch flex flex-row items-center justify-start flex-wrap content-center text-mini text-black-solid-black-880-1d1d26">

              {
                customDishTab() === CustomDish.DRINK && (
                  <>
                    {kitchenGroupPrinters0().map((groupPrinter, index) => (
                      <div key={index} className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-2"
                           ref={makeRipple}
                           onClick={() => {
                             setDataCustomDish((prevData) => ({
                               ...prevData,
                               DivArticleSettings: [
                                 {
                                   ...prevData.DivArticleSettings?.[0],
                                   drink: {
                                     ...prevData.DivArticleSettings?.[0]?.drink,
                                     groupPrinter: groupPrinter._id
                                   }
                                 },
                                 ...(prevData.DivArticleSettings?.slice(1) || []) // Copy the rest of the array
                               ]
                             }));
                             // _.assign(demoDataCustomDish?.DivArticleSettings[0]?.drink, { groupPrinter: groupPrinter._id});
                           }}
                      >
                        {dataCustomDish()?.DivArticleSettings?.[0]?.drink?.groupPrinter === groupPrinter._id &&
                          <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border z-[0]" />
                        }
                        <div className="relative z-[1]">{groupPrinter.name}</div>
                      </div>
                    ))}
                  </>
                )
              }
              {customDishTab() === CustomDish.FOOD &&
                (
                  <>
                    {kitchenGroupPrinters0().map((groupPrinter, index) => (
                      <div key={index} className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-2"
                           ref={makeRipple}
                           onClick={() => {
                             setDataCustomDish((prevData) => ({
                               ...prevData,
                               DivArticleSettings: [
                                 {
                                   ...prevData.DivArticleSettings?.[0],
                                   food: {
                                     ...prevData.DivArticleSettings?.[0]?.food,
                                     groupPrinter: groupPrinter._id
                                   }
                                 },
                                 ...(prevData.DivArticleSettings?.slice(1) || []) // Copy the rest of the array
                               ]
                             }));
                             // _.assign(demoDataCustomDish?.DivArticleSettings[0]?.food, { groupPrinter: groupPrinter._id});
                           }}
                      >
                        {dataCustomDish()?.DivArticleSettings?.[0]?.food?.groupPrinter === groupPrinter._id &&
                          <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border z-[0]" />
                        }
                        <div className="relative z-[1]">{groupPrinter.name}</div>
                      </div>
                    ))}
                  </>
                )
              }
            </div>
          </div>
          {/*<div className="self-stretch flex flex-col items-start justify-center gap-3">*/}
          {/*  <div className="relative font-semibold md:text-xs">Taxes:</div>*/}
          {/*  <div className="self-stretch flex flex-row items-center justify-start flex-wrap content-center gap-4 text-sm text-blue-solid-blue-350-2196f3">*/}
          {/*    <div className="flex-1 flex flex-col items-start justify-center gap-2 min-w-[180px]">*/}
          {/*      <div className="relative font-semibold md:text-xs">*/}
          {/*        Dine-in Tax:*/}
          {/*      </div>*/}
          {/*      <div className="self-stretch flex flex-row items-center justify-start flex-wrap content-center text-mini text-black-solid-black-880-1d1d26">*/}
          {/*        <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-2">*/}
          {/*          <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border z-[0]" />*/}
          {/*          <div className="relative z-[1]">(7%)</div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="flex-1 flex flex-col items-start justify-center gap-2 min-w-[180px]">*/}
          {/*      <div className="relative font-semibold md:text-xs">*/}
          {/*        Tax-away Tax:*/}
          {/*      </div>*/}
          {/*      <div className="self-stretch flex flex-row items-center justify-start flex-wrap content-center text-mini text-black-solid-black-880-1d1d26">*/}
          {/*        <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.2)] rounded-10xs bg-gray-solid-gray-130-f4f4f4 border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-2">*/}
          {/*          <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-170-ceffff border-cyan-solid-cyan-250-8aeaff border-[2px] border-solid box-border z-[0]" />*/}
          {/*          <div className="relative z-[1]">(7%)</div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default EditMenuCustomDishPopup;