import { type FunctionComponent, memo, useCallback, useState } from 'react'
import InventoryIDFilterPopup from './Popups/FilterPopups/InventoryIDFilterPopup'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import InventoryNameFilterPopu from './Popups/FilterPopups/InventoryNameFilterPopu'
import InventoryCategoryFilter from './Popups/FilterPopups/InventoryCategoryFilter'
import InventoryStockFilterPop from './Popups/FilterPopups/InventoryStockFilterPop'
import InventoryDateChoosingPo from './Popups/InventoryDateChoosingPo'
import InventoryTopNavbar from './InventoryTopNavbar'
import InventoryTableRow from '@inventory/InventoryTableRow.tsx'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { filterInventoryValues, mappedInventories, setFilterInventoryValues } from '@/data/InventoryHub.ts'
import { LL0 } from '@/react/core/I18nService.tsx'
import dayjs from 'dayjs'
import _ from 'lodash'
import { EditInventoryMode, setEditInventoryMode } from '@inventory/Popups/EditItemPopup/InventoryAddEditItemPopu.tsx'
import { LL2 } from "@/react/core/I18nBackend.tsx";

const InventoryPlugin: FunctionComponent = () => {
  const [isInventoryIDFilterPopupOpen, setInventoryIDFilterPopupOpen] =
    useState(false);
  const [isInventoryNameFilterPopuOpen, setInventoryNameFilterPopuOpen] =
    useState(false);
  const [isInventoryCategoryFilterOpen, setInventoryCategoryFilterOpen] =
    useState(false);
  const [isInventoryStockFilterPopOpen, setInventoryStockFilterPopOpen] =
    useState(false);
  const [isInventoryDateChoosingPoOpen, setInventoryDateChoosingPoOpen] =
    useState(false);

  const openInventoryIDFilterPopup = useCallback(() => {
    setInventoryIDFilterPopupOpen(true);
  }, []);

  const closeInventoryIDFilterPopup = useCallback(() => {
    setInventoryIDFilterPopupOpen(false);
  }, []);

  const openInventoryNameFilterPopu = useCallback(() => {
    setInventoryNameFilterPopuOpen(true);
  }, []);

  const closeInventoryNameFilterPopu = useCallback(() => {
    setInventoryNameFilterPopuOpen(false);
  }, []);

  const openInventoryCategoryFilter = useCallback(() => {
    setInventoryCategoryFilterOpen(true);
  }, []);

  const closeInventoryCategoryFilter = useCallback(() => {
    setInventoryCategoryFilterOpen(false);
  }, []);

  const openInventoryStockFilterPop = useCallback(() => {
    setInventoryStockFilterPopOpen(true);
  }, []);

  const closeInventoryStockFilterPop = useCallback(() => {
    setInventoryStockFilterPopOpen(false);
  }, []);

  const openInventoryDateChoosingPo = useCallback(() => {
    setInventoryDateChoosingPoOpen(true);
    setEditInventoryMode(EditInventoryMode.ADD)
  }, []);

  const closeInventoryDateChoosingPo = useCallback(() => {
    setInventoryDateChoosingPoOpen(false);
  }, []);
  const renderInventory = () => {
    const lastUpdatedFilter = filterInventoryValues().date
    const stockFilter = filterInventoryValues().stock
    const idFilter = filterInventoryValues().id;
    const nameFilter = filterInventoryValues().name;
    const categoryFilter = filterInventoryValues().category?.id;

    const filteredInventory = mappedInventories()
      .filter((inventory) => (stockFilter ? stockFilter.min <= inventory.stock && inventory.stock <= stockFilter.max : true))
      .filter((inventory) => (lastUpdatedFilter ? lastUpdatedFilter.from / 1000 <= inventory.lastUpdated && inventory.lastUpdated <= lastUpdatedFilter.to / 1000 : true))
      .filter((inventory) => (nameFilter ? inventory.product?.name?.includes(nameFilter) : true))
      .filter((inventory) => (idFilter ? inventory?.id === idFilter : true))
      .filter((inventory) => (categoryFilter ? inventory.product?.categories?.includes(categoryFilter) : true));

    return (
      filteredInventory.map((inventory, index) => <InventoryTableRow key={inventory._id} inventoryData={inventory} isEven={index % 2 !== 0} />)
    );
  }

  return (
    <>
      <div className="inventory-screen w-full h-full flex flex-col items-center justify-start !bg-[url('/-inventory-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] gap-[0px] text-center text-sm text-black-solid-black-600-424242 font-mulish hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[0px] hover:items-center hover:justify-start">
        <InventoryTopNavbar />
        <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-center justify-start">
          <div className="self-stretch relative h-[219px] shrink-0 grid grid-rows-[repeat(5,44px)] grid-cols-[63px_1fr_1fr_87px_119px_79px_114px_58px] [grid-row-gap:0px] [grid-column-gap:0px] sm:grid Tablet_768:grid Tablet_768:grid-cols-[63px_1fr_0px_0px_92px_79px_108px_58px] Tablet_600:grid Tablet_600:grid-cols-[56px_1fr_0px_0px_80px_72px_102px_50px] Mobile_480:grid Mobile_480:grid-cols-[52px_1fr_0px_0px_74px_66px_96px_48px] height_414:grid height_414:grid-rows-[repeat(5,44px)] height_414:grid-cols-[52px_1fr_0px_0px_74px_66px_96px_48px] height_414:[grid-row-gap:0px] height_414:[grid-column-gap:0px] mq1440:grid mq1440:grid-rows-[repeat(5,44px)] mq1440:grid-cols-[89px_1fr_1fr_122px_167px_122px_151px_82px] mq1440:[grid-row-gap:0px] mq1440:[grid-column-gap:0px]">
            <div
              className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[1/1] row-[1/1] cursor-pointer text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff"
              onClick={openInventoryIDFilterPopup}
              ref={makeRipple}
            >
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.filter.id()}
              </b>
            </div>
            <div
              className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[2/2] row-[1/1] cursor-pointer text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff hover:relative Tablet_768:col-[2/5]"
              onClick={openInventoryNameFilterPopu}
              ref={makeRipple}
            >
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 sm:w-[15px] sm:h-[15px] mq1440:w-[18px] mq1440:h-[18px]"
                alt=""
                src="/left-icon.svg"
              />
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.name()}
              </b>
            </div>
            <div
              className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[3/3] row-[1/1] cursor-pointer text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff hover:relative Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden height_414:hidden"
              onClick={openInventoryCategoryFilter}
              ref={makeRipple}
            >
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 sm:w-[15px] sm:h-[15px]"
                alt=""
                src="/left-icon.svg"
              />
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs mq1440:text-sm mq1440:hover:text-sm height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.filter.category()}
              </b>
            </div>
            <div className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[4/4] row-[1/1] text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff hover:gap-[2px] hover:relative Tablet_768:hidden Tablet_600:hidden Mobile_480:hidden height_414:hidden">
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.price()}
              </b>
            </div>
            <div
              className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[5/5] row-[1/1] cursor-pointer text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff hover:relative "
              onClick={openInventoryStockFilterPop}
              ref={makeRipple}
            >
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 sm:w-[15px] sm:h-[15px]"
                alt=""
                src="/left-icon.svg"
              />
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex sm:text-sm Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.stock()}
              </b>
            </div>
            <div className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[6/6] row-[1/1] text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff hover:gap-[2px] hover:relative ">
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex sm:text-sm Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.threshold()}
              </b>
            </div>
            <div
              className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[7/7] row-[1/1] cursor-pointer text-white-solid-white-100-ffffff border-r-[1px] border-solid border-white-solid-white-100-ffffff hover:relative"
              onClick={openInventoryDateChoosingPo}
              ref={makeRipple}
            >
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0 sm:w-[15px] sm:h-[15px]"
                alt=""
                src="/left-icon1.svg"
              />
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex sm:text-sm Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().inventory.lastUpdate()}
              </b>
            </div>
            <div className="bg-black-solid-black-700-364150 flex flex-row items-center justify-center py-2.5 px-1 gap-[2px] relative col-[8/8] row-[1/1] text-white-solid-white-100-ffffff hover:gap-[2px] hover:relative ">
              <b className="relative flex font-bold hover:font-bold hover:font-mulish hover:text-sm hover:text-center hover:text-white-solid-white-100-ffffff hover:flex sm:text-sm Tablet_768:text-smi Tablet_768:hover:text-smi Tablet_600:text-xs Tablet_600:hover:text-xs Mobile_480:text-2xs Mobile_480:hover:text-2xs height_414:text-2xs height_414:hover:text-2xs mq1440:text-base mq1440:hover:text-base mq320:text-3xs">
                {LL0().ui.edit()}
              </b>
            </div>
            {Object.entries(filterInventoryValues()).length > 0 && <div
              className="bg-[#d6d7d7] flex flex-row items-center justify-end py-[7px] px-2 gap-[10px] relative col-[1/9] row-[2/2] text-left text-xs text-gray-solid-gray-750-696969 hover:relative Tablet_768:pl-1 Tablet_768:pr-1">
              <div
                className="flex-1 grid flex-row flex-wrap items-center justify-start gap-[10px] grid-flow-col h-auto hover:grid hover:flex-1 hover:h-auto hover:flex-row hover:flex-wrap hover:gap-[10px] hover:items-center hover:justify-start hover:grid-flow-col">
                <b className="relative inline-block text-red-solid-red-660-8f181d w-[34px] shrink-0 mq1440:text-smi">
                  {LL0().orderHistory.filter()}:
                </b>
                {filterInventoryValues()?.category && <div
                  className="rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 gap-[5px]">
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative mq1440:text-smi">{LL0().article.category()}:</div>
                    <div
                      className="relative mq1440:text-smi">{filterInventoryValues().category?.name}</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icondelete-fillter-icon@2x.png"
                    onPointerDown={() => {
                      const {category, ...rest} = filterInventoryValues()
                      setFilterInventoryValues(rest)
                    }}
                  />
                </div>}
                {filterInventoryValues()?.stock && <div
                  className="rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 gap-[5px]">
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative mq1440:text-smi">{LL0().inventory.stock()}:</div>
                    <div
                      className="relative mq1440:text-smi">{filterInventoryValues().stock?.min}-{filterInventoryValues().stock?.max}</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icondelete-fillter-icon@2x.png"
                    onPointerDown={() => {
                      const {stock, ...rest} = filterInventoryValues()
                      setFilterInventoryValues(rest)
                    }}
                  />
                </div>}
                {filterInventoryValues()?.id && <div
                  className="rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 gap-[5px]">
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative mq1440:text-smi">ID:</div>
                    <div
                      className="relative mq1440:text-smi">{filterInventoryValues().id}</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icondelete-fillter-icon@2x.png"
                    onPointerDown={() => {
                      const {id, ...rest} = filterInventoryValues()
                      setFilterInventoryValues(rest)
                    }}
                  />
                </div>}
                {filterInventoryValues()?.name && <div
                  className="rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 gap-[5px]">
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative mq1440:text-smi">{LL0().article.name()}:</div>
                    <div
                      className="relative mq1440:text-smi">{filterInventoryValues().name}</div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icondelete-fillter-icon@2x.png"
                    onPointerDown={() => {
                      const {name, ...rest} = filterInventoryValues()
                      setFilterInventoryValues(rest)
                    }}
                  />
                </div>}
                {filterInventoryValues()?.date && <div
                  className="rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-start p-1 gap-[5px]">
                  <div className="flex flex-row items-start justify-start gap-[2px]">
                    <div className="relative mq1440:text-smi">{LL0().common.date()}:</div>
                    <div
                      className="relative mq1440:text-smi">
                      {filterInventoryValues().date.type ? _.get(LL0().onlineOrder, filterInventoryValues().date.type)?.() :
                        `${dayjs(filterInventoryValues().date.from).format(LL2().dates.dateFormat())} - ${dayjs(filterInventoryValues().date.to).format(LL2().dates.dateFormat())}`}
                    </div>
                  </div>
                  <img
                    className="relative w-4 h-4 overflow-hidden shrink-0 object-cover"
                    alt=""
                    src="/icondelete-fillter-icon@2x.png"
                    onPointerDown={() => {
                      const {date, ...rest} = filterInventoryValues()
                      setFilterInventoryValues(rest)
                    }}
                  />
                </div>}
              </div>
              <div
                className="rounded-81xl bg-white-solid-white-230-f3f4f4 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] overflow-hidden flex flex-row items-center justify-center py-0 px-2 box-border gap-[3px] w-auto [align-self:unset] h-auto text-center text-sm text-black-solid-black-900-1e1e23 hover:bg-white-solid-white-230-f3f4f4 hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[3px] hover:items-center hover:justify-center hover:rounded-81xl hover:py-0 hover:px-2 hover:box-border hover:shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] active:rounded-81xl"
                onClick={() => {
                  setFilterInventoryValues({})
                }}
              >
                <img
                  className="relative w-[30px] h-[30px] object-cover"
                  alt=""
                  src="/iconbin-icon--red@2x.png"
                />
                <b className="relative leading-[17px] flex items-end justify-center w-9 shrink-0 mq1440:text-mini">
                  {LL0().customer.clear()}
                </b>
              </div>
            </div>}
            {/*{ mappedInventories().map(inventory => <InventoryTableRow key={inventory._id} inventoryData={inventory} />) }*/}
            {renderInventory()}

          </div>
        </div>
      </div>
      {isInventoryIDFilterPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryIDFilterPopup}
        >
          <InventoryIDFilterPopup onClose={closeInventoryIDFilterPopup} />
        </PortalPopup>
      )}
      {isInventoryNameFilterPopuOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryNameFilterPopu}
        >
          <InventoryNameFilterPopu onClose={closeInventoryNameFilterPopu} />
        </PortalPopup>
      )}
      {isInventoryCategoryFilterOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryCategoryFilter}
        >
          <InventoryCategoryFilter onClose={closeInventoryCategoryFilter} />
        </PortalPopup>
      )}
      {isInventoryStockFilterPopOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryStockFilterPop}
        >
          <InventoryStockFilterPop onClose={closeInventoryStockFilterPop} />
        </PortalPopup>
      )}
      {isInventoryDateChoosingPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeInventoryDateChoosingPo}
        >
          <InventoryDateChoosingPo onClose={closeInventoryDateChoosingPo} />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(InventoryPlugin)