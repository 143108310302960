import { type FunctionComponent, useCallback, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Switch
} from '@mui/material'
import PrinterTemplatePopup from '@printer-setting/PrinterTemplatePopup'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PrinterConfigsForm from '@printer-setting/PrinterConfigsForm'
import {
  currentPrinter,
  FONT_SIZES, handlePrinterIncludeToggle,
  handleProductTypeChange,
  INTEGRATE_SDKS,
  PRINTER_TYPES, PrinterMode, printerSettingMode,
  selectedGroupPrinter,
  TOP_MARGINS, usbPrinterDevices
} from '@/react/Printer/PrinterSettingView.tsx';
import _ from 'lodash'
import { LL0 } from '@/react/core/I18nService.tsx'
import TextField from '@/react/core/TextField.tsx'
import { type BluetoothPrinterData, rnHost } from "@/shared/webview/rnwebview.ts";
import { testBluetoothPrinter } from "@/react/Printer/print-bluetooth.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { toast } from 'react-toastify'
import { testNetworkPrinter } from "@/react/Printer/print-network.ts";
import { testUSBPrinter } from "@/react/Printer/print-usb.ts";
import { testIntegratePrint } from "@/react/Printer/print-integrate.ts";
import { findSerialPorts } from "@/shared/printer/serialPortManager.ts";
import { testSerialPortPrinter } from "@/react/Printer/print-serial-port.ts";
import { invoiceGroupPrinters0, kitchenGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { generalSetting0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type IsClientRecheckBarType = {
  isBar?: boolean;
  isRecheckReceipt?: boolean;
  isClientReceipt?: boolean;
  showSelected?: boolean;
  isSelected?: boolean;
};

const IsClientRecheckBar: FunctionComponent<IsClientRecheckBarType> = ({
  isBar = true,
  isRecheckReceipt = true,
  isClientReceipt = true,
  showSelected,
  isSelected,
}) => {
  const [bluetoothDevices, setBluetoothDevices] = useState<BluetoothPrinterData[]>([])
  const [serialPorts, setSerialPorts] = useState<string[]>([])
  const [isPrinterTemplatePopup17Open, setPrinterTemplatePopup17Open] =
    useState(false);
  const [isAdvancedSetting, setIsAdvancedSetting] =
    useState(false);

  const [isFontSizeForReceiptHeaderOpen, setIsFontSizeForReceiptHeaderOpen] = useState(false)
  const [isFontSizeForReceiptItemsOpen, setIsFontSizeForReceiptItemsOpen] = useState(false)

  const openPrinterTemplatePopup17 = useCallback(() => {
    userFLow(`open printer template popup`, {
      username: loginUser()?.name
    });
    setPrinterTemplatePopup17Open(true);
  }, []);

  const closePrinterTemplatePopup17 = useCallback(() => {
    userFLow(`close printer template popup`, {
      username: loginUser()?.name
    });
    setPrinterTemplatePopup17Open(false);
  }, []);
  return (
    <>
      <div
        className="no-scrollbar overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-4 box-border gap-[16px] text-left text-sm text-black font-mulish self-stretch flex-1">
        <div
          className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-4 gap-[12px] text-gray-400 border-b-[1px] border-solid border-gray-divider-1">
          {selectedGroupPrinter()?.hasMultiplePositions && (
            <div className="text-[#1d1d26] self-stretch flex flex-col items-start justify-start gap-[4px]">
              <div className="relative">{LL0().settings.position()}</div>
              <TextField
                className="bg-[transparent] self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
                color="primary"
                size="small"
                fullWidth={true}
                variant="outlined"
                value={currentPrinter()?.name || ""}
                onChange={e => _.assign(currentPrinter(), { name: e.target.value })}
              />
            </div>
          )}
          <div className="text-[#1d1d26] self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
            {!isClientReceipt &&
              <div className="relative">{LL0().settings.name()}</div>
            }
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[0px_16px] Tablet_768:flex-col Tablet_768:gap-[0px_16px]">
              {!isClientReceipt &&
                <TextField
                  className="bg-[transparent] flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26 Tablet_768:flex-[unset] Tablet_768:self-stretch"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  value={selectedGroupPrinter()?.name || ""}
                  onChange={e => {
                    if (selectedGroupPrinter()) {
                      selectedGroupPrinter()!.name = e.target.value;
                    }
                  }}

                />
              }
              <FormControlLabel
                className="text-[#1d1d26] mr-0 Tablet_768:flex-[unset] Tablet_768:self-stretch"
                label={LL0().printerSetting.multiplePosition()}
                control={<Switch color="primary"
                                 checked={selectedGroupPrinter()?.hasMultiplePositions || false}
                                 onChange={(e) => {
                                   if (selectedGroupPrinter()) {
                                     selectedGroupPrinter()!.hasMultiplePositions = e.target.checked;
                                   }
                                 }}
                />}
              />
              <FormControlLabel
                className="text-[#1d1d26] flex-1 Tablet_768:flex-[unset] Tablet_768:self-stretch"
                label={"Advance setting"}
                control={<Switch color="primary"
                                 checked={isAdvancedSetting}
                                 onChange={(e) => {
                                   setIsAdvancedSetting(!isAdvancedSetting)
                                 }}
                />}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="relative">{LL0().printerSetting.terminalPrinter()}</b>
            <div
              className="self-stretch gap-[8px] grid grid-cols-[repeat(auto-fit,140px)] [grid-row-gap:8px] [grid-column-gap:8px] text-mini">
              {PRINTER_TYPES.map(({ value, label }, index) =>
                <div
                  className="!text-[#1d1d26] rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                  key={index}
                  onPointerDown={() => _.assign(currentPrinter(), { printerType: value })}
                >
                  {currentPrinter()?.printerType === value &&
                    <div
                      className="!flex !text-[#1d1d26] absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                  }
                  <div className="relative z-[1]">{label}</div>
                </div>
              )}
              <div
                className="text-[#1d1d26] rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                onPointerDown={() => delete currentPrinter()?.printerType}
              >
                <div
                  className="absolute my-0 mx-[!important] h-full w-[calc(100%_+_0.2px)] top-[0px] right-[-0.2px] bottom-[0px] left-[0px] rounded-10xs bg-lightcyan box-border hidden z-[0] border-[2px] border-solid border-skyblue" />
                <div className="relative z-[1]">{LL0().ui.reset()}</div>
              </div>
            </div>
            {currentPrinter()?.printerType === "ip" &&
              <div
                className="self-stretch flex flex-row items-start justify-start items-end gap-[0px_8px] text-center text-basic-color-white">
                <TextField
                  className="bg-[transparent] flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  value={currentPrinter()?.ip || ""}
                  onChange={e => _.assign(currentPrinter(), { ip: e.target.value })}
                  label="IP"
                />
                <div
                  className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                  ref={makeRipple}
                  onClick={async () => {
                    userFLow(`test network printer`, {
                      username: loginUser()?.name
                    });
                    await testNetworkPrinter()
                  }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {currentPrinter()?.printerType === "star" &&
              <div
                className="self-stretch flex flex-row items-start justify-start items-end gap-[0px_8px] text-center text-basic-color-white">
                <TextField
                  className="bg-[transparent] flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26"
                  color="primary"
                  size="small"
                  fullWidth={true}
                  variant="outlined"
                  value={currentPrinter()?.ip || ""}
                  onChange={e => _.assign(currentPrinter(), { ip: e.target.value })}
                  label="MAC"
                />
                <div
                  className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                  ref={makeRipple}
                  onClick={async () => {
                    userFLow(`test Network printer`, {
                      username: loginUser()?.name
                    });
                    await testNetworkPrinter()
                  }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {currentPrinter()?.printerType === "bluetooth" &&
              <div
                className="self-stretch flex flex-row items-start justify-start gap-[0px_8px] text-center text-basic-color-white">
                <div
                  className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-5 box-border"
                  ref={makeRipple}
                  onClick={async () => {
                    const t = toast("Scanning Bluetooth Devices..., please wait !!!", {
                      autoClose: false,
                      theme: "dark",
                      progress: 100,
                    })
                    try {
                      const result = (await rnHost.scanDevices())?.paired || [];
                      console.log("scanDevices", result);
                      userFLow(`scanDevices ${result}`, {
                        username: loginUser()?.name
                      });
                      setBluetoothDevices(result);
                    } catch (e) {
                      toast((e as Error).message, { autoClose: 4000, type: 'error' });
                    } finally {
                      toast.done(t);
                    }
                  }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.scan()}</div>
                </div>
                <FormControl className="self-stretch flex-1" variant="outlined">
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          sx={{
                            borderRadius: "0px 0px 0px 0px",
                            height: "37px",
                            m: 0,
                            "& .MuiInputBase-root": {
                              m: 0,
                              minHeight: "37px",
                              justifyContent: "center",
                              display: "inline-flex",
                            },
                            "& .MuiInputBase-input": {
                              textAlign: "left",
                            },
                          }}
                          value={currentPrinter()?.address || ''}
                          onChange={(e) => _.assign(currentPrinter(), { address: e.target.value })}
                  >
                    {
                      ((currentPrinter()?.address && !bluetoothDevices.length) ? [{
                        address: currentPrinter()?.address?.split("/")[0],
                        name: currentPrinter()?.address?.split("/")[1],
                      }] : bluetoothDevices).map(({ address, name }, index) => (
                        <MenuItem value={`${address}/${name || 'unpair'}`}
                                  key={index}>{address} - {name || 'unpair'}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText />
                </FormControl>
                <div
                  className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                  ref={makeRipple}
                  onClick={async () => {
                    userFLow(`test bluetooth printer`, {
                      username: loginUser()?.name
                    });
                    await testBluetoothPrinter()
                  }}>
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {currentPrinter()?.printerType === "usb" &&
              <div
                className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center text-basic-color-white">
                <div
                  className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-5 box-border"
                  ref={makeRipple}

                >
                  <div className="relative font-semibold">{LL0().printerSetting.scan()}</div>
                </div>
                <FormControl
                  className="self-stretch flex-1 font-mulish text-sm text-black-solid-black-880-1d1d26"
                  variant="outlined"
                >
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          sx={{
                            borderRadius: "0px 0px 0px 0px",
                            height: "37px",
                            m: 0,
                            "& .MuiInputBase-root": {
                              m: 0,
                              minHeight: "37px",
                              justifyContent: "center",
                              display: "inline-flex",
                            },
                            "& .MuiInputBase-input": {
                              textAlign: "left",
                            },
                          }}
                          value={currentPrinter()?.usb || ''}
                          onChange={(e) => _.assign(currentPrinter(), { usb: e.target.value })}
                  >
                    {
                      ((currentPrinter()?.usb && !usbPrinterDevices().length) ? [currentPrinter()?.usb] : usbPrinterDevices()).map((path, index) => (
                        <MenuItem value={path} key={index}>{path}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText />
                </FormControl>
                <div
                  className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                  ref={makeRipple}
                  onClick={async () => {
                    userFLow(`test USB printer`, {
                      username: loginUser()?.name
                    });
                    await testUSBPrinter()
                  }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {currentPrinter()?.printerType === "integrate" &&
              <div className="text-[#1d1d26] self-stretch flex flex-col items-start justify-start gap-[8px] mt-2">
                <b className="relative">SDK</b>
                <div className="flex flex-row flex-wrap items-center justify-start gap-[8px] text-mini text-gray-400">
                  {INTEGRATE_SDKS.map(({ label, value }, index) =>
                    <div
                      className="!w-fit !text-[#1d1d26] px-2 rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border w-10 h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                      key={index}
                      onPointerDown={() => _.assign(currentPrinter(), { sdk: value })}
                    >
                      {currentPrinter()?.sdk === value &&
                        <div
                          className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                      }
                      <i className="relative z-[1]">{label}</i>
                    </div>
                  )}
                </div>
                <div
                  className="text-white rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                  ref={makeRipple}
                  onClick={async () => {
                    userFLow(`test integrate printer`, {
                      username: loginUser()?.name
                    });
                    await testIntegratePrint()
                  }}
                >
                  <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                </div>
              </div>
            }
            {/*custom UI for Serial Port*/}
            {currentPrinter()?.printerType === "serialPort" &&
              <div className={'flex flex-col gap-2 w-full'}>
                <div
                  className="self-stretch flex flex-row items-start justify-start gap-[0px_8px] text-center text-basic-color-white">
                  <div
                    className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-5 box-border"
                    ref={makeRipple}
                    onClick={async () => {

                      toast("Scanning Serial Ports...", { autoClose: 600 });
                      const result = await findSerialPorts() || [];
                      userFLow(`scan devices ${result}`, {
                        username: loginUser()?.name
                      });
                      console.log("scanDevices", result);
                      setSerialPorts(result);
                    }}
                  >
                    <div className="relative font-semibold">{LL0().printerSetting.scan()}</div>
                  </div>
                  <FormControl className="self-stretch flex-1" variant="outlined">
                    <InputLabel color="primary" />
                    <Select color="primary" size="small"
                            sx={{
                              borderRadius: "0px 0px 0px 0px",
                              height: "37px",
                              m: 0,
                              "& .MuiInputBase-root": {
                                m: 0,
                                minHeight: "37px",
                                justifyContent: "center",
                                display: "inline-flex",
                              },
                              "& .MuiInputBase-input": {
                                textAlign: "left",
                              },
                            }}
                            value={currentPrinter()?.serialPort}
                            onChange={(e) => _.assign(currentPrinter(), { serialPort: e.target.value })}
                    >
                      {
                        ((currentPrinter()?.serialPort && !serialPorts.length) ? [currentPrinter()?.serialPort] : serialPorts).map((serialPort, index) => (
                          <MenuItem value={serialPort} key={index}>{serialPort}</MenuItem>
                        ))
                      }
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  <div
                    className="rounded-10xs bg-blue-500 h-[37px] flex flex-row items-center justify-center py-0 px-3 box-border"
                    ref={makeRipple}
                    onClick={async () => {
                      userFLow(`test serial port printer`, {
                        username: loginUser()?.name
                      });
                      await testSerialPortPrinter();
                    }}
                  >
                    <div className="relative font-semibold">{LL0().printerSetting.testPrinter()}</div>
                  </div>
                </div>
                <FormControl className="self-stretch flex-1" variant="outlined">
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          value={currentPrinter()?.baudrate?.toString() || '19200'}
                          onChange={(e) => _.assign(currentPrinter(), { baudrate: parseInt(e.target.value) })}
                  >
                    {
                      ['9600', '19200'].map((value, index) => (
                        <MenuItem value={value} key={index}>{value}</MenuItem>
                      ))
                    }
                  </Select>
                  <FormHelperText />
                </FormControl>

              </div>
            }

          </div>
        </div>
        <PrinterConfigsForm
          iconQuestionIcon="/iconquestion-icon2@2x.png"
          isRecheckReceipt={isRecheckReceipt}
          isClientReceipt={isClientReceipt}
          showAnItem={isRecheckReceipt || isBar}
          anItemVisible={isRecheckReceipt || isBar}
          anItemVisible1={isRecheckReceipt || isBar}
          anItemVisible2={isRecheckReceipt || isBar}
          anItemVisible3={isRecheckReceipt || isBar}
          anItemVisible4={isRecheckReceipt || isBar}
          anItemVisible5={isRecheckReceipt || isBar}
        />

        {isAdvancedSetting && <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <div
            className="self-stretch flex flex-row items-center justify-start py-0 pr-4 pl-0 gap-[47px] Tablet_768:flex-col Tablet_768:gap-[8px] Tablet_768:items-start Tablet_768:justify-start Tablet_768:pl-3 Tablet_768:box-border">
            <div
              className="relative">{LL0().settings.canvasWidth({ canvasWidth: currentPrinter()?.canvasWidth || 0 })} </div>
            <Box className="flex-1 Tablet_768:flex-[unset] Tablet_768:self-stretch">
              <Slider color="primary"
                      valueLabelDisplay="auto"
                      value={currentPrinter()?.canvasWidth || 0}
                      onChange={(_e, number) => _.assign(currentPrinter(), { canvasWidth: number })}
                      max={600}
              />
            </Box>
          </div>
          <div
            className="self-stretch flex flex-row items-center justify-start py-0 pr-4 pl-0 gap-[0px_24px] Tablet_768:flex-col Tablet_768:gap-[0px_24px] Tablet_768:items-start Tablet_768:justify-start Tablet_768:pl-3 Tablet_768:box-border">
            <div
              className="relative">{LL0().settings.numberOfCharactersPerLine({ numberOfCharactersPerLine: currentPrinter()?.numberOfCharactersPerLine || 0 })}</div>
            <Box className="flex-1 Tablet_768:flex-[unset] Tablet_768:self-stretch">
              <Slider color="primary"
                      valueLabelDisplay="auto"
                      value={currentPrinter()?.numberOfCharactersPerLine || 0}
                      max={100}
                      onChange={(_e, number) => _.assign(currentPrinter(), { numberOfCharactersPerLine: number })}
              />
            </Box>
          </div>
        </div>}



          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="relative flex flex-row items-center gap-2">
              <b>{LL0().printing.fontSizeForReceiptHeader()}</b>
              {printerSettingMode() === PrinterMode.NORMAL && <img
                className="w-8 relative h-8 object-cover cursor-pointer"
                alt=""
                src="/iconquestion-icon2@2x.png"
                onClick={() => {
                  userFLow(`open is font size for receipt header`, {
                    username: loginUser()?.name
                  });
                  setIsFontSizeForReceiptHeaderOpen(true)
                }}
              />}
            </div>
            <div
              className="flex flex-row text-[#1d1d26] flex-wrap items-center justify-start gap-[8px] text-mini text-gray-400">
              {FONT_SIZES.map((fontSize, index) =>
                <div
                  className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border w-10 h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                  key={index}
                  onPointerDown={() => _.assign(currentPrinter(), { fontSizeForReceiptHeader: fontSize })}
                >
                  {(currentPrinter()?.fontSizeForReceiptHeader || 2) === fontSize &&
                    <div
                      className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                  }
                  <i className="relative z-[1]">{fontSize}</i>
                </div>
              )}
            </div>
          </div>

        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="relative flex flex-row items-center gap-2">
            <b>{LL0().printing.fontSizeForReceiptItems()}</b>
            {printerSettingMode() === PrinterMode.NORMAL &&
              <img
                className="w-8 relative h-8 object-cover cursor-pointer"
                alt=""
                src="/iconquestion-icon2@2x.png"
                onClick={() => {
                  userFLow(`open is font size for receipt item`, {
                    username: loginUser()?.name
                  });
                  setIsFontSizeForReceiptItemsOpen(true)
                }}
              />
            }
          </div>
          <div
            className="flex flex-row text-[#1d1d26] flex-wrap items-center justify-start gap-[8px] text-mini text-gray-400">
            {FONT_SIZES.map((fontSize, index) =>
              <div
                className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border w-10 h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                key={index}
                onPointerDown={() => _.assign(currentPrinter(), { fontSize })}
              >
                {currentPrinter()?.fontSize === fontSize &&
                  <div
                    className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                }
                <i className="relative z-[1]">{fontSize}</i>
              </div>
            )}
          </div>
        </div>
        {printerSettingMode() === PrinterMode.NORMAL &&
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <div className="relative flex flex-row items-center gap-2">
              <b>{LL0().printing.fontSizeForReceiptFooter()}</b>
              {/*<img*/}
              {/*  className="w-8 relative h-8 object-cover cursor-pointer"*/}
              {/*  alt=""*/}
              {/*  src="/iconquestion-icon2@2x.png"*/}
              {/*  onClick={() => setIsFontSizeForReceiptHeaderOpen(true)}*/}
              {/*/>*/}
            </div>
            <div
              className="flex flex-row text-[#1d1d26] flex-wrap items-center justify-start gap-[8px] text-mini text-gray-400">
              {FONT_SIZES.map((fontSize, index) =>
                <div
                  className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border w-10 h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                  key={index}
                  onPointerDown={() => _.assign(currentPrinter(), { fontSizeFooter: fontSize })}
                >
                  {(currentPrinter()?.fontSizeFooter || 2) === fontSize &&
                    <div
                      className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                  }
                  <i className="relative z-[1]">{fontSize}</i>
                </div>
              )}
            </div>
          </div>
        }
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <b className="relative">{LL0().settings.receiptTopMargin()}</b>
          <div
            className="text-[#1d1d26] flex flex-row flex-wrap items-start justify-start gap-[8px] text-mini text-gray-400">
            {TOP_MARGINS.map((margin, index) => (
              <div
                className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                onPointerDown={() => _.assign(currentPrinter(), { marginTop: margin })}
                key={index}
              >
                {currentPrinter()?.marginTop === margin &&
                  <div
                    className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                }
                <i className="relative z-[1]">+{margin} Cm</i>
              </div>
            ))}
          </div>
        </div>

        {isBar && <div className="w-full flex flex-col gap-2">
          <b className="relative">{LL0().printing.takeAwayTextIcon()}</b>
          <FormControl size="small" className="w-1/2">
            <Select
              size="small"
              value={posSetting0()?.printerGeneralSetting?.takeAwayLabel ?? 'icon'}
              onChange={e => _.assign(posSetting0()?.printerGeneralSetting, { takeAwayLabel: e.target.value })}
            >
              <MenuItem value="icon">{LL0().article.icon()}</MenuItem>
              <MenuItem value="takeAway">{LL0().common.text()}</MenuItem>
            </Select>
          </FormControl>
        </div>}
        {isBar && generalSetting0()?.taxType == "two" &&
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px_0px]">
            <div className="flex flex-row items-center justify-start gap-[0px_8px]">
              <b className="relative">{LL0().settings.selectProductTypeForPrinter()}</b>
              <img
                className="w-5 relative h-5 object-cover cursor-pointer"
                alt=""
                src="/iconquestion-icon@2x.png"
                onClick={openPrinterTemplatePopup17}
              />
            </div>
            <div className="flex flex-row items-start justify-start gap-[8px] text-mini text-gray-400">
              {["food", "drink", "other"].map((productType, index) =>
                <div
                  className="rounded-10xs bg-whitesmoke-100 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                  key={index}
                  onPointerDown={() => handleProductTypeChange(productType)}
                >
                  {selectedGroupPrinter()?.productType === productType &&
                    <div
                      className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-cyan-solid-cyan-100-ceffff box-border hidden z-[0] border-[2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                  }
                  <i className="relative z-[1]">{LL0().printerSetting[productType as "food" | "drink" | "other"]()}</i>
                </div>
              )}
            </div>
          </div>
        }
        {isRecheckReceipt &&
          <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
            <b className="relative">{LL0().printerSetting.include()}:</b>
            <div className="flex flex-row items-center justify-start gap-[8px] text-mini text-gray-400">
              {kitchenGroupPrinters0().map((groupPrinter, index) => (

                <div key={index}
                     className="rounded-10xs bg-gray-solid-gray-130 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                     onClick={() => {
                       userFLow(`handle printer include toggle ${groupPrinter?._id}`, {
                         username: loginUser()?.name
                       });
                       handlePrinterIncludeToggle(groupPrinter?._id, currentPrinter)
                     }}
                >
                  {_.includes(currentPrinter()?.includes, groupPrinter._id) &&
                    <div
                      className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-lightcyan box-border z-[0] border-[2px] border-solid border-skyblue" />}
                  <i className="relative z-[1]">{groupPrinter.name}</i>
                </div>
              ))}
              <div
                className="rounded-10xs bg-gray-solid-gray-130 shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] box-border h-10 flex flex-col items-center justify-center py-0 px-4 relative gap-[8px] border-[0.5px] border-solid border-darkgray Tablet_768:h-9"
                onClick={() => {
                  userFLow(`handle printer include toggle ${invoiceGroupPrinters0()?.[0]?._id}`, {
                    username: loginUser()?.name
                  });
                  handlePrinterIncludeToggle(invoiceGroupPrinters0()?.[0]?._id, currentPrinter)
                }}
              >
                {_.includes(currentPrinter()?.includes, invoiceGroupPrinters0()?.[0]?._id) &&
                  <div
                    className="!flex absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-lightcyan box-border hidden z-[0] border-[2px] border-solid border-skyblue" />}
                <i className="relative z-[1]">{LL0().settings.generalSetting.receipt()} </i>
              </div>
            </div>
          </div>
        }
      </div>
      {isPrinterTemplatePopup17Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closePrinterTemplatePopup17}
        >
          <PrinterTemplatePopup
            onClose={closePrinterTemplatePopup17}
            title={LL0().settings.selectProductTypeForPrinter()}
            content={LL0().settings.selectProductTypeForPrinterExplain()}
          />
        </PortalPopup>
      )}
      {isFontSizeForReceiptHeaderOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsFontSizeForReceiptHeaderOpen(false)}
        >
          <PrinterTemplatePopup
            onClose={() => setIsFontSizeForReceiptHeaderOpen(false)}
            title={LL0().printing.fontSizeForReceiptHeader()}
            content={LL0().printing.kitchenReceiptHeaderInfo()}
          />
        </PortalPopup>
      )}
      {isFontSizeForReceiptItemsOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setIsFontSizeForReceiptItemsOpen(false)}
        >
          <PrinterTemplatePopup
            onClose={() => setIsFontSizeForReceiptItemsOpen(false)}
            title={LL0().printing.fontSizeForReceiptItems()}
            content={LL0().printing.kitchenReceiptBodyInfo()}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default IsClientRecheckBar;
