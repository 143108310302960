import type {FunctionComponent} from "react";
import {LL0} from "@/react/core/I18nService.tsx";
import {makeRipple} from "@/react/core/ripple-utils.ts";
import {STATES, Tabs, VIEW__TOP_NAV} from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type EditOnlineMenuTopNav1Type = {
  isSelected?: boolean;
};

const EditOnlineMenuTopNav1: FunctionComponent<EditOnlineMenuTopNav1Type> = ({
  isSelected,
}) => {
  return (
    <div
      className="bg-blue-solid-blue-700-1b2b65 flex flex-row items-center justify-between py-1.5 px-5 box-border text-left text-lg text-white-solid-white-100-ffffff font-mulish-button-name-bold self-stretch md:pl-4 md:pr-4 md:box-border sm:pl-3.5 sm:pr-3.5 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:pl-2.5 mq480:pr-2.5 mq480:box-border height_414:pl-2.5 height_414:pr-2.5 height_414:box-border">
      <div
        className="h-11 flex flex-row items-center justify-start gap-[12px] lg:gap-[10px] md:h-10 md:gap-[8px] sm:h-9 sm:gap-[6px] mq768:h-8 mq768:gap-[4px] mq600:h-8 mq600:gap-[2px] mq480:h-8 mq480:gap-[2px]">
        <button ref={makeRipple} onClick={() => {
          userFLow(`Menu list in Online Menu`, {
            username: loginUser()?.name
          });
          VIEW__TOP_NAV.onMenuListClick()
        }}
                className="self-stretch flex flex-row items-center justify-center py-0 px-4 relative gap-[12px] lg:gap-[10px] lg:pl-4 lg:box-border md:gap-[8px] md:pl-3.5 md:pr-3.5 md:box-border sm:gap-[6px] sm:pl-3 sm:pr-3 sm:box-border mq768:gap-[4px] mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:gap-[4px] mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:gap-[4px] mq480:pl-2.5 mq480:pr-2.5 mq480:box-border">
          {STATES.selectedTab() === Tabs.MenuList && (
            <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-11xl bg-blue-solid-blue-600-3f4e84 z-[0]"/>
          )}
          <img
            className="relative w-6 h-6 object-cover z-[1] sm:w-5 sm:h-5 mq768:w-[18px] mq768:h-[18px]"
            alt=""
            src="/icononlinemenu-icon@2x.png"
          />
          <b
            className="relative z-[2] lg:text-base md:text-sm sm:text-smi mq768:text-xs mq768:text-center mq600:text-2xs mq600:text-center mq480:text-2xs">
            {LL0().editOnlineMenu.menuList()}
          </b>
        </button>
        <button ref={makeRipple} onClick={() => {
          userFLow(`click Option list in Online Menu`, {
            username: loginUser()?.name
          })
          VIEW__TOP_NAV.onOptionListClick()
        }}
                className="self-stretch flex flex-row items-center justify-center py-0 px-4 relative gap-[12px] lg:gap-[10px] lg:pl-4 lg:box-border md:gap-[8px] md:pl-3.5 md:pr-3.5 md:box-border sm:gap-[6px] sm:pl-3 sm:pr-3 sm:box-border mq768:gap-[4px] mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:gap-[4px] mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:gap-[4px] mq480:pl-2.5 mq480:pr-2.5 mq480:box-border">
          {STATES.selectedTab() === Tabs.OptionList && (
            <div
              className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-11xl bg-blue-solid-blue-600-3f4e84 z-[0]"/>
          )}
          <img
            className="relative w-6 h-6 object-cover sm:w-5 sm:h-5 mq768:w-[18px] mq768:h-[18px]"
            alt=""
            src="/icononlinemenu-icon2@2x.png"
          />
          <b
            className="relative lg:text-base md:text-sm sm:text-smi mq768:text-xs mq768:text-center mq600:text-2xs mq600:text-center mq480:text-2xs">
            {LL0().editOnlineMenu.optionList()}
          </b>
        </button>
        <div
          className="opacity-30 self-stretch flex flex-row items-center justify-center py-0 px-4 gap-[12px] lg:gap-[10px] lg:pl-4 lg:box-border md:gap-[8px] md:pl-3.5 md:pr-3.5 md:box-border sm:gap-[6px] sm:pl-3 sm:pr-3 sm:box-border mq768:gap-[4px] mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:gap-[4px] mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:gap-[4px] mq480:pl-2.5 mq480:pr-2.5 mq480:box-border">
          <img
            className="relative w-6 h-6 object-cover sm:w-5 sm:h-5 mq768:w-[18px] mq768:h-[18px]"
            alt=""
            src="/iconmenudiscount-icon2@2x.png"
          />
          <b
            className="relative lg:text-base md:text-sm sm:text-smi mq768:text-xs mq768:text-center mq600:text-2xs mq600:text-center mq480:text-2xs">
            {LL0().editOnlineMenu.createDiscount()}
          </b>
        </div>
      </div>
      <button ref={makeRipple} onClick={VIEW__TOP_NAV.onGoBack}
              className="rounded-3xs [background:linear-gradient(135deg,_#868dff,_#4318ff)] shadow-[0px_4px_4px_rgba(0,_0,_0,_0.25)] h-11 flex flex-row items-center justify-center py-0 px-5 box-border gap-[12px] text-center lg:gap-[12px] md:h-10 md:gap-[6px] md:py-2 md:px-4 md:box-border sm:h-9 sm:gap-[4px] sm:py-1.5 sm:px-2.5 sm:box-border mq768:h-8 mq768:gap-[4px] mq768:rounded-3xs mq768:pt-1 mq768:pb-1 mq768:box-border mq600:h-8 mq480:h-8">
        <img
          className="relative w-6 h-6 object-cover sm:w-[22px] sm:h-[22px] mq768:w-5 mq768:h-5 mq600:w-5 mq600:h-5 mq480:w-5 mq480:h-5"
          alt=""
          src="/iconback-icon@2x.png"
        />
        <b
          className="relative flex font-bold hover:font-bold hover:font-mulish-button-name-bold hover:text-lg hover:text-center hover:text-white-solid-white-100-ffffff hover:flex lg:text-base md:text-sm sm:text-smi mq768:text-xs mq600:text-2xs mq480:text-2xs">
          {LL0().ui.back()}
        </b>
      </button>
    </div>
  );
};

export default EditOnlineMenuTopNav1;
