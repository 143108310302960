import { type FunctionComponent, useEffect } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import EditIngredientToolbar from "./EditIngredientToolbar";
import TextField from "@/react/core/TextField.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { mappedInventories } from "@/data/InventoryHub.ts";
import _ from "lodash";
import { product0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { setInputControllers, setShowKeyboardIngredients } from "@/react/EditMenuView/EditMenuView.tsx";
import Input from "@/react/core/Input.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { clsx } from "clsx";

export type EditIngredientFormType = {
  className?: string;
  onClose?: () => void;
};

const VerticalEditIngredientForm: FunctionComponent<EditIngredientFormType> = ({
  className = "",
  onClose,
}) => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  const [filterName, setFilterName] = useSignal<string>('')

  return (
    <div
      className={clsx(`!opacity-100 !flex w-full flex-1 bg-white-solid-white-100-ffffff overflow-hidden hidden flex-col items-start justify-start [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-bottom] opacity-[0] max-h-[90%] text-left text-sm text-black-solid-black-1000-000000 font-mulish ${className}`,
        generalSetting0()?.useMenuV2 && '!w-[310px]'
        )}
      data-animate-on-scroll
    >
      <EditIngredientToolbar />
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-4 px-3 gap-2 z-[0] text-black-solid-black-600-424242">
        {generalSetting0()?.useMenuV2 ?
          <div className='w-full' onClick={() => setShowKeyboardIngredients(true)}>
            <Input
              className={'w-full'}
              label={LL0().editMenu.searchIngredient()}
              value={filterName()}
              onChange={(value) => setFilterName(value)}
            />
          </div>
          :
          <TextField
            className="self-stretch font-mulish text-sm text-darkgray"
            value={filterName()}
            onChange={(e) => setFilterName(e.target.value)}
          />
        }
        {mappedInventories().filter(inventory => inventory?.product?.name.includes(filterName())).map(inventory => {
          const foundIngredient = product0()?.ingredients?.find(ingredient => ingredient.inventoryId === inventory._id)
          return (
            <div className="self-stretch rounded-sm bg-gray-solid-gray-180-ebecee border-gray-solid-gray-300-dbdbdb border-[1px] border-solid flex flex-row items-start justify-start"
                 key={inventory._id}
            >
              <div className="flex-1 h-[37px] flex flex-row items-center justify-between py-0 pl-2 pr-1 box-border">
                <FormControlLabel
                  className="flex-1"
                  label={inventory?.product?.name}
                  control={<Checkbox color="primary"
                                     checked={!!foundIngredient}
                                     onChange={e => {
                                       if (!foundIngredient) {
                                         _.assign(product0(), {
                                           ingredients: [...(product0().ingredients || []), {
                                             inventoryId: inventory._id,
                                             amount: '0'
                                           }]
                                         })
                                         return;
                                       }
                                       const foundIngredientIndex = product0()?.ingredients?.findIndex(ingredient => ingredient.inventoryId === inventory._id);
                                       product0().ingredients.splice(foundIngredientIndex, 1);
                                     }} />}
                />
                <div className="relative md:text-xs">({inventory.unit})</div>
              </div>
              <div className="flex-1 border-gray-solid-gray-300-dbdbdb border-l-[1px] border-solid box-border h-[37px] flex flex-row items-center justify-start py-0 px-1 max-w-[56px]"
                   onClick={() => !!foundIngredient && setShowKeyboardIngredients(true)}
              >
                {/*<div className="flex-1 relative md:text-xs">100</div>*/}
                <Input className='bg-transparent border-none !pl-1 flex-1 max-w-full'
                       disabled={!foundIngredient} value={foundIngredient?.amount?.toString() || '0'}
                       onChange={(value) => _.assign(foundIngredient, { amount: value })}
                       refInputController={i => setInputControllers(() => [i])}
                />
              </div>
            </div>
          )
        })}

      </div>
    </div>
  );
};

export default VerticalEditIngredientForm;
