import { isQuebecSrmEnabled } from '@/data/PosSettingsSignal.ts'
import type { OrderItem } from '@/pos/OrderType'

import { TESTCASES_REQUIRE_DOWNLOAD } from '../testcase/constants'
import { CONSTANT_VALUES } from './constants'

/** Check if the testcase should download the receipt. For local debug only */
export function shouldDownloadReceipt(testcaseNumber: string = CONSTANT_VALUES.CASESSAI_EMPTY): testcaseNumber is string {
  if (!import.meta.env.DEV) return false
  return TESTCASES_REQUIRE_DOWNLOAD.includes(testcaseNumber)
}

const priceFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

/** Reformat the price. Used in **Report** or **Bill** only */
export const reformatCurrency = (n: number | string) => priceFormatter.format(Number(n)) + ' $'

/** Trim zipcode and test if it in correct format (`A9A9A9`) */
export const getValidZipcode = (zipCode: string) => {
  const result = zipCode.trim().replaceAll(' ', '')
  if (/^\w\d\w\d\w\d$/.test(result)) return result
  throw new Error(`Invalid Zip Code ${result}`)
}

/** Get the street number from street address (Take "123-456" from "123-456 rue Unetelle") */
export const getStreetNumber = (streetAddress: string) => {
  return streetAddress.trim().split(/ |,/).shift() ?? ''
}

const repleacemenStrategy: [RegExp, string][] = [
  [/ạ|ả|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a'],
  [/ẹ|ẻ|ẽ|ề|ế|ệ|ể|ễ/g, 'e'],
  [/ị|ỉ|ĩ/g, 'i'],
  [/ọ|ỏ|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o'],
  [/ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u'],
  [/ỳ|ỵ|ỷ|ỹ/g, 'y'],
  [/đ/g, 'd'],
  [/Ạ|Ả|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A'],
  [/Ẹ|Ẻ|Ẽ|Ề|Ế|Ệ|Ể|Ễ/g, 'E'],
  [/Ị|Ỉ|Ĩ/g, 'I'],
  [/Ọ|Ỏ|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O'],
  [/Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U'],
  [/Ỳ|Ỵ|Ỷ|Ỹ/g, 'Y'],
  [/Đ/g, 'D'],
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  [/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''], // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  [/\u02C6|\u0306|\u031B/g, ''], // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Final check, remove all char not matched
  [/[^a-zA-Z0-9 @:!#$%&'()*+,\-.=?_|~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÑÒÓÔÕÖØÙÚÛÜÝàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿ]/g, ''],
]

/**
 * [SW-73-V 4.1] Remove/replace any character that not conform to the spec.
 *
 * Example: "phở gà" -> "pho gà"
 */
export const ensureValidAsciiStandard = (s: string) => repleacemenStrategy.reduce((a, [r, p]) => a.replace(r, p), s.trim())

export const nameLengthRegex = /^.{2,128}$/s

export const ensureValidItemNameLength = (items: OrderItem[]) => {
  if (!isQuebecSrmEnabled()) return true
  const checkedItems = items?.filter(o => o.quantity !== 0)
  const nameLessThan2 =
    checkedItems?.filter(item => {
      if (!nameLengthRegex.test(item.name ?? '')) return true
      return item.modifiers?.some(modifier => !nameLengthRegex.test(modifier.name))
    })?.length || 0

  return nameLessThan2 < 1
}
