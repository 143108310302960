import type {FunctionComponent} from "react";
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { params, type PosScreen, router } from '@/pos/PosRouter.ts';
import { OrderMode } from "@/react/OrderView/OrderViewShare.ts";

type ManuelTableFloorPlanButtonType = {
  imageId?: string;
  buttonText?: string;
  noticeNumber?: string;
  showSelected?: boolean;
  showTable?: boolean;
  showChip?: boolean;

  onClick?: () => any;
  screen?: PosScreen;
};

const ManuelTableFloorPlanButton: FunctionComponent<
  ManuelTableFloorPlanButtonType
> = ({
  imageId,
  buttonText = "Fast Checkout",
  noticeNumber = "20",
  showSelected = true,
  showTable = true,
  showChip = true,
  onClick,
  screen
}) => {
  return (
    <div className="overflow-hidden rounded [background:linear-gradient(180deg,_#fff,_#f8f7f7)] shadow-[0px_0.5px_1.5px_rgba(0,_0,_0,_0.5)] h-[108px] flex flex-col items-center justify-center py-3 px-1 box-border relative gap-[8px] text-center text-sm text-materialize-grey-darken-3 font-mulish"
         ref={makeRipple}
         onClick={() => {
           if (params()?.orderMode === OrderMode.FAST_CHECKOUT) {
             userFLow(`go to screen fast checkout`, {
               username: loginUser()?.name
             });
           } else {
             userFLow(`go to screen ${screen}`, {
               username: loginUser()?.name
             });
           }
           if (screen) router.screen = screen;
           onClick?.();
         }}
    >
      <img
        className="w-10 relative h-10 object-cover z-[0] Tablet_600:w-8 Tablet_600:h-8 Mobile_480:w-[26px] Mobile_480:h-[26px]"
        alt=""
        src={imageId}
      />
      <b className="self-stretch relative z-[1] Tablet_600:text-xs Mobile_480:text-2xs">
        {buttonText}
      </b>
      {showTable && (
        <div className="absolute my-0 mx-[!important] top-[4px] right-[4px] w-[13px] h-[13px] z-[2]">
          <div className="absolute top-[7px] right-[0px] rounded-12xs bg-lightseagreen w-1.5 h-1.5 opacity-[0.6]" />
          <div className="absolute top-[7px] right-[7px] rounded-12xs bg-gold w-1.5 h-1.5 opacity-[0.6]" />
          <div className="absolute top-[0px] right-[7px] rounded-12xs bg-tomato w-1.5 h-1.5 opacity-[0.6]" />
          <div className="absolute top-[0px] right-[0px] rounded-12xs bg-darkorchid w-1.5 h-1.5 opacity-[0.6]" />
        </div>
      )}
      {showChip && (
        <div className="w-6 absolute !m-[0] top-[4px] right-[4px] h-6 z-[3] text-white">
          <div className="absolute top-[0px] left-[0px] rounded-[50%] bg-[#e63950] w-6 h-6" />
          <b className="absolute w-[91.67%] top-[calc(50%_-_7px)] left-[4.17%] tracking-[-0.28px] flex items-center justify-center h-3.5">
            {noticeNumber}
          </b>
        </div>
      )}
      {showSelected && (
        <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded box-border z-[4] border-[3px] border-solid border-orange" />
      )}
    </div>
  );
};

export default ManuelTableFloorPlanButton;
