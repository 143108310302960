import * as Comlink from 'comlink'
import type { ILogger } from "zvt-js/src/adapters/ILogger";
import type {IPayTxPayload, IPayTxResp, IXTerminal} from "./IXTerminal";
import {CommandResponseState, ZvtClient, TcpNetworkDeviceCommunication, RegistrationConfig} from "zvt-js";
import {ZvtClientConfig} from "zvt-js/src/ZvtClientConfig.ts";
import type {CancellationToken} from "zvt-js/src/adapters/CancellationTokenSource.ts"
import {EventHandler} from "zvt-js/src/adapters/EventHandler.ts";
import type {byte} from "zvt-js/src/adapters/number.ts";
import TimeSpan from "zvt-js/src/adapters/TimeSpan.ts"
import type {ITcpClient} from "zvt-js/src/adapters/ITcpClient.ts"
import {rnHost} from "@/shared/webview/rnwebview.ts";
import type { StatusInformation } from 'zvt-js/src/models/StatusInformation';
import {StimulateClient} from "zvt-js/src/adapters/StimulateTcpClient.ts"
import { ZvtLog } from "@/data/ZvtLog.ts";
import uuid from 'time-uuid';
import _ from 'lodash';
import {captureException} from '@sentry/react';
import debug from "debug";

const zvtLog = debug('zvt:log');

export type ZvtMetadata = {
  ipAddr: string;
  port: number;
  password: string;
  language: string;
  encoding: string;
  commandCompletionTimeout: number;
  receiptPrintoutForPaymentFunctionsViaPaymentTerminal: boolean;
  receiptPrintoutForAdministrationFunctionsViaPaymentTerminal: boolean;
  allowStartPaymentViaPaymentTerminal: boolean;
  allowAdministrationViaPaymentTerminal: boolean;
  receiptPrintoutGeneratedViaPaymentTerminal: boolean;
  serviceMenuIsDisabledOnTheFunctionKeyOfThePaymentTerminal: boolean;
  activateTlvSupport: boolean;
  skipRegistration: boolean;
  logging: boolean;
}

const zvtTerminalCache: Record<string, ZvtClient> = {}
const zvtTcpClientCache: Record<string, ITcpClient> = {}
const zvtLoggerCache: Record<string, ILogger<any>> = {}
const zvtInProgressActions: Record<string, Record<string, boolean>> = {}

class COMLinkClient implements ITcpClient {
  Connected: EventHandler<() => void>;
  DataReceived: EventHandler<(data: byte[]) => void>;
  Disconnected: EventHandler<() => void>;
  #ipAddr?: string;
  #port?: number;
  #isConnected: boolean = false;

  constructor() {
    this.Connected = new EventHandler<() => void>();
    this.DataReceived = new EventHandler<(data: byte[]) => void>();
    this.Disconnected = new EventHandler<() => void>();
  }

  async ConnectAsync(ipAddr: string, port: number, cs: CancellationToken) {
    if (this.#isConnected) return
    this.#ipAddr = ipAddr;
    this.#port = port;
    try {
      await new Promise(async (resolve, reject) => {
        const onData = Comlink.proxy((buffer: Buffer) => {
          // @ts-ignore
          this.DataReceived?.Invoke(buffer.data)
        })
        const onError = Comlink.proxy((error: any) => {
          this.#isConnected = false;
          reject(error);
        })
        const onClose = Comlink.proxy(() => this.#isConnected = false)
        await rnHost.openTcpConnection(ipAddr, port, onData, onError, onClose);
        this.#isConnected = true;
        resolve(true);
      })
    } catch (e) {
      console.error('COMLinkClient:ConnectAsync: exception', e)
      captureException(new Error('ZVT::ConnectAsync Failed to open tcp connection', {cause: e}), { tags: { type: 'zvt' } })
      throw new Error('Failed to connect to ZVT device. Please check the IP address & port of ZVT terminal in Tax & Payment screen to make sure the setting is correct');
    }
  }

  Disconnect() {
    return rnHost.closeTcpConnection(this.#ipAddr!, this.#port!);
  }

  Dispose() {
    this.Disconnect().then()
  }

  get IsConnected(): boolean {
    return this.#isConnected;
  }

  async SendAsync(data: byte[], cs?: CancellationToken): Promise<any> {
    try {
      return await rnHost.sendDataTcpConnection(this.#ipAddr!, this.#port!, data)
    } catch (e) {
      console.error('COMLinkClient::SendAsync: error', e)
      captureException(new Error('COMLinkClient: Failed to send data', { cause: e}), { tags: { type: 'zvt' } })
      throw new Error('Failed to send data to ZVT device. Please try again later')
    }
  }
}

class PersistentLogger implements ILogger<any> {
  _disable: boolean;
  ssid?: string;

  constructor(enable: boolean) {
    console.log('PersistentLogger', enable)
    this._disable = !enable;
  }

  _log(level: string, ...log: any): void {
    // debug.log({
    //   _id: uuid(),
    //   ssid: this.ssid!,
    //   data: `[${level}] ${_.join(log, ',')}`,
    //   ts: Date.now()
    // })
    zvtLog(`[${level}] ${_.join(log, ',')} , ssid: ${this.ssid!}`,);
    // console.log(level, log);
    if (this._disable) return;
    ZvtLog.insert({
      _id: uuid(),
      ssid: this.ssid!,
      data: `[${level}] ${_.join(log, ',')}`,
      ts: Date.now()
    }).then().catch(console.error)
  }

  LogCritical(...log: any): void {
    this._log('Critical', ...log)
  }

  LogDebug(...log: any): void {
    this._log('Debug', ...log)
  }

  LogError(...log: any): void {
    this._log('Error', ...log)
  }

  LogInformation(...log: any): void {
    this._log('Information', ...log)
  }

  LogTrace(...log: any): void {
    this._log('Trace', ...log)
  }

  LogWarning(...log: any): void {
    this._log('Warning', ...log)
  }
}

export default class ZvtTerminal implements IXTerminal {
  #tcpClient: ITcpClient;
  #client: ZvtClient;
  #logger: ILogger<any>;
  #options: ZvtMetadata;
  #terminalRegistered: boolean;
  #isStimulate: boolean = false; // set true to use stimulate
  #inProgressActions: Record<string, boolean>;

  constructor(options: ZvtMetadata) {
    this.#options = options;
    this.#terminalRegistered = false;
    const {ipAddr, port} = options;
    const key = `${ipAddr}:${port}`;
    if (zvtTerminalCache[key]) {
      this.#client = zvtTerminalCache[key];
      this.#tcpClient = zvtTcpClientCache[key];
      this.#logger = zvtLoggerCache[key];
      this.#inProgressActions = zvtInProgressActions[key];
    } else {
      this.#inProgressActions = {}
      zvtInProgressActions[key] = this.#inProgressActions
      const logger = new PersistentLogger(options.logging);
      this.#logger = logger;
      zvtLoggerCache[key] = logger;
      const tcpClient = this.#isStimulate ? new StimulateClient() : new COMLinkClient();
      this.#tcpClient = tcpClient;
      zvtTcpClientCache[key] = tcpClient;
      const deviceCommunication = new TcpNetworkDeviceCommunication(
        tcpClient,
        options.ipAddr,
        options.port,
        false,
        logger
      );
      const clientConfig = new ZvtClientConfig({
        Password: options.password,
        Language: options.language,
        Encoding: options.encoding,
        CommandCompletionTimeout: TimeSpan.FromSeconds(options.commandCompletionTimeout),
        T3Timeout: TimeSpan.FromSeconds(30)
      });
      const zvtClient = new ZvtClient(deviceCommunication, logger, clientConfig);
      zvtTerminalCache[key] = zvtClient;
      this.#client = zvtClient;
    }
  }

  async cancel(payload: any, onProgress?: Function): Promise<any> {
    for (const action of Object.keys(this.#inProgressActions)) {
      delete this.#inProgressActions[action]
    }
    // @ts-ignore
    this.#logger.ssid = `Abort-${uuid()}`;
    let statusInformation: StatusInformation | undefined = undefined;
    const statusInformationReceived = (si: StatusInformation) => statusInformation = si;
    const intermediateStatusInformationReceived = (status: string) => onProgress?.(status);
    this.#client.StatusInformationReceived.Add(statusInformationReceived);
    this.#client.IntermediateStatusInformationReceived.Add(intermediateStatusInformationReceived)
    // @ts-ignore
    const response = await this.#client.AbortAsync(null)
    this.#client.StatusInformationReceived.Remove(statusInformationReceived);
    this.#client.IntermediateStatusInformationReceived.Remove(intermediateStatusInformationReceived)
    const {State, ErrorMessage} = response;
    console.log(State, ErrorMessage, statusInformation);
    if (ErrorMessage)
      return {error: ErrorMessage}
    switch (State) {
      case CommandResponseState.Successful:
        return {
          response: {}
        }
      case CommandResponseState.Abort:
        return {error: `The transaction has been aborted: ${ErrorMessage}`}
      case CommandResponseState.Error:
        return {error: ErrorMessage}
      case CommandResponseState.Unknown:
        return {error: `Unknown: ${ErrorMessage}`}
      case CommandResponseState.Timeout:
        return {error: `Timeout: ${ErrorMessage}`}
      case CommandResponseState.NotSupported:
      default:
        return {error: `NotSupported: ${ErrorMessage}`}
    }
  }

  async ensureConnection() {
    const {ipAddr, port} = this.#options;
    // @ts-ignore
    this.#logger.ssid = `Connect-${uuid()}`
    // @ts-ignore
    await this.#tcpClient.ConnectAsync(ipAddr, port, null);
    if (!this.#terminalRegistered) {
      console.log('[ZvtTerminal] RegistrationAsync')
      if (this.#isStimulate || this.#options.skipRegistration) {
        console.log('skip RegistrationAsync');
      } else {
        // @ts-ignore
        this.#logger.ssid = `Registration-${uuid()}`
        const cfg = new RegistrationConfig();
        cfg.ReceiptPrintoutForPaymentFunctionsViaPaymentTerminal = this.#options.receiptPrintoutForPaymentFunctionsViaPaymentTerminal;
        cfg.ReceiptPrintoutForAdministrationFunctionsViaPaymentTerminal = this.#options.receiptPrintoutForAdministrationFunctionsViaPaymentTerminal;
        cfg.SendIntermediateStatusInformation = true;
        cfg.AllowStartPaymentViaPaymentTerminal = this.#options.allowStartPaymentViaPaymentTerminal;
        cfg.AllowAdministrationViaPaymentTerminal = this.#options.allowAdministrationViaPaymentTerminal;
        cfg.ReceiptPrintoutGeneratedViaPaymentTerminal = this.#options.receiptPrintoutGeneratedViaPaymentTerminal;
        cfg.ServiceMenuIsDisabledOnTheFunctionKeyOfThePaymentTerminal = this.#options.serviceMenuIsDisabledOnTheFunctionKeyOfThePaymentTerminal;
        // cfg.TextAtDisplayInCapitalLettersAtThePaymentTerminal = this.#options.textAtDisplayInCapitalLettersAtThePaymentTerminal;
        cfg.ActivateTlvSupport = this.#options.activateTlvSupport;
        // @ts-ignore
        await this.#client.RegistrationAsync(cfg, null);
      }
      this.#terminalRegistered = true;
    }
  }

  async payTx({amount, tip}: IPayTxPayload, onProgress?: Function): Promise<{ error: string } | IPayTxResp> {
    const ssid = `Payment-${uuid()}`;
    this.#inProgressActions[ssid] = true;

    try {
      onProgress && onProgress('Connecting...');
      await this.ensureConnection();
    } catch (e: any) {
      return {error: e.message}
    }

    if (!this.#inProgressActions[ssid])
      return {error: `The transaction has been aborted: User cancel`};

    const totalAmount = amount + (tip || 0);

    let statusInformation: StatusInformation | undefined = undefined;
    const statusInformationReceived = (si: StatusInformation) => statusInformation = si;
    const intermediateStatusInformationReceived = (status: string) => onProgress?.(status);
    this.#client.StatusInformationReceived.Add(statusInformationReceived);
    this.#client.IntermediateStatusInformationReceived.Add(intermediateStatusInformationReceived)

    if (this.#isStimulate) {
      // "CompletionCase1" | "CompletionCase2" | "CompletionCase3" | "Abort" | "FailedCard" | "Busy"
      // @ts-ignore
      await this.#tcpClient?.init("CompletionCase2")
    }

    // @ts-ignore
    this.#logger.ssid = ssid;

    if (!this.#inProgressActions[ssid])
      return {error: `The transaction has been aborted: User cancel`};

    onProgress && onProgress('Payment...');
    const response = await this.#client.PaymentAsync(totalAmount);

    delete this.#inProgressActions[ssid];

    this.#client.StatusInformationReceived.Remove(statusInformationReceived);
    this.#client.IntermediateStatusInformationReceived.Remove(intermediateStatusInformationReceived)

    onProgress && onProgress('Process final response...');
    const {State, ErrorMessage} = response;
    switch (State) {
      case CommandResponseState.Successful:
        if (!statusInformation)
          return {error: `Payment Status information is not received`}

        // @ts-ignore
        const paymentInfo = statusInformation?.Amount === totalAmount ? {
          amount: Math.round(amount * 100),
          tipAmount: Math.round(tip * 100),
          taxAmount: 0,
          createdTime: new Date(),
          result: 'SUCCESS',
        } : {
          // fallback
          // @ts-ignore
          amount: statusInformation?.Amount * 100,
          tipAmount: 0,
          taxAmount: 0,
          createdTime: new Date(),
          result: 'SUCCESS',
        }

        const result = {
          response: {
            type: 'zvt',
            payment: Object.assign(statusInformation, paymentInfo)
          }
        }
        console.log('result', result)
        // @ts-ignore
        return result;
      case CommandResponseState.Abort:
        return {error: `The transaction has been aborted: ${ErrorMessage}`}
      case CommandResponseState.Error:
        return {error: `The transaction have an error: ${ErrorMessage}`}
      case CommandResponseState.Unknown:
        return {error: `Unknown: ${ErrorMessage}`}
      case CommandResponseState.Timeout:
        return {error: `Timeout: ${ErrorMessage}`}
      case CommandResponseState.NotSupported:
      default:
        return {error: `NotSupported: ${ErrorMessage}`}
    }
  }

  async refundTx(payload: any, onProgress?: Function): Promise<any> {
    const ssid = `Refund-${uuid()}`;
    this.#inProgressActions[ssid] = true;

    // @ts-ignore
    this.#logger.ssid = ssid;
    this.#logger.LogInformation('[ZvtTerminal] Start refund')

    try {
      onProgress && onProgress('Connecting...');
      await this.ensureConnection();
    } catch (e: any) {
      // @ts-ignore
      this.#logger.ssid = ssid;
      this.#logger.LogError(`[ZvtTerminal] error ${e.message}`);
      return {error: e.message}
    }

    // @ts-ignore
    this.#logger.ssid = ssid;

    if (!this.#inProgressActions[ssid])
      return {error: `The transaction has been aborted: User cancel`};

    onProgress && onProgress('Refund...');
    // @ts-ignore
    const response = await this.#client.RefundAsync(payload.amount)

    delete this.#inProgressActions[ssid]

    onProgress && onProgress('Process final response...');
    const {State, ErrorMessage} = response;

    if (ErrorMessage)
      return {error: ErrorMessage}

    switch (State) {
      case CommandResponseState.Successful:
        return {
          response: {}
        }
      case CommandResponseState.Abort:
        return {error: `The transaction has been aborted: ${ErrorMessage}`}
      case CommandResponseState.Error:
        return {error: ErrorMessage}
      case CommandResponseState.Unknown:
        return {error: `Unknown: ${ErrorMessage}`}
      case CommandResponseState.Timeout:
        return {error: `Timeout: ${ErrorMessage}`}
      case CommandResponseState.NotSupported:
      default:
        return {error: `NotSupported: ${ErrorMessage}`}
    }
  }

  async voidTx(payload: any, onProgress?: Function): Promise<any> {
    return {
      error: "NotSupported"
    }
  }

  async isOnline() {
    return true;
  }

  async test(onProgress?: Function) {
    const ssid = `DiagnosisAsync-${uuid()}`;
    this.#inProgressActions[ssid] = true;

    try {
      onProgress && onProgress('Connecting to terminal...');
      await this.ensureConnection();
      onProgress && onProgress('Terminal connected');
    } catch (e: any) {
      return {error: e.message}
    }

    if (!this.#inProgressActions[ssid])
      return {error: `The transaction has been aborted: User cancel`};

    // @ts-ignore
    this.#logger.ssid = ssid;

    const intermediateStatusInformationReceived = (status: string) => onProgress?.(status);

    this.#client.IntermediateStatusInformationReceived.Add(intermediateStatusInformationReceived);
    onProgress && onProgress('Diagnosis...');
    // @ts-ignore
    const {State, ErrorMessage} = await this.#client.DiagnosisAsync(null);
    this.#client.IntermediateStatusInformationReceived.Remove(intermediateStatusInformationReceived);

    onProgress && onProgress('Process final response...');
    delete this.#inProgressActions[ssid]

    switch (State) {
      case CommandResponseState.Successful:
        return {data: true};
      case CommandResponseState.Abort:
        return {error: `The transaction has been aborted: ${ErrorMessage}`}
      case CommandResponseState.Error:
        return {error: `The transaction have an error: ${ErrorMessage}`}
      case CommandResponseState.Unknown:
        return {error: `Unknown: ${ErrorMessage}`}
      case CommandResponseState.Timeout:
        return {error: `Timeout: ${ErrorMessage}`}
      case CommandResponseState.NotSupported:
      default:
        return {error: `NotSupported: ${ErrorMessage}`}
    }
  }

  eod(): void {
    this.ensureConnection().then(async () => {
      const ssid = `EndOfDay-${uuid()}`;
      // @ts-ignore
      this.#logger.ssid = ssid;
      // @ts-ignore
      return await this.#client.EndOfDayAsync(null)
    });
  }
}