import type { Optional } from 'utility-types'

import type { VoucherInfo } from '@/data/Voucher.ts'
import type { TransactionData } from '@/srm/lib/types'
import type { PaymentIcons } from '@/react/PaymentSettingView/PaymentMap'
import type { PaymentType } from '@/data/Payment'
import type { TaxCalMethod } from './orderUtils'

export type SubTax = {
  name?: string
  value?: number
  printLabel?: string
}

export enum InvoiceTypes {
  NONE = 0,
  INVOICE = 1,
  RED_INVOICE = 2,
  GUEST_CHECK = 3,
  ALL_GUEST_CHECK = 4,
  ALL_INVOICE = 5,
}

export const InvoiceTypesMap: {[k: string]: string} = {
  '1': 'INVOICE',
  '2': 'RED_INVOICE',
  '3': 'GUEST_CHECK',
}

export enum MarketPlaceProvider {
  DOOR_DASH = 'doordash',
  SKIP_THE_DISH = 'std',
  UBER_EATS = 'uber_eats',
  DELIVERECT = 'deliverect',
  PIKAPOINT = 'pika',
  RESTABLO = 'restablo',
  LIEFERANDO = 'lieferando',
  PHONE = 'phone',
  RESTAURANT_PLUS = 'restaurant_plus',
}

export enum DeliveryService {
  UBER = 'uber',
  DOORDASH = 'doordash',
  DELIVERECT = 'deliverect',
  LIEFERANDO = 'lieferando',
  SELF_DELIVERY = 'self-delivery',
  PIKADRIVE = 'pikadrive',
  EXTERNAL = 'external',
}

export enum OrderType {
  PickUp = 'pickup',
  Delivery = 'delivery',
  Kiosk = 'kiosk',
  InHouse = 'inHouse',
}

export enum TransferProtocol {
  V1 = 1,
}

export enum OrderStatus {
  CREATED = 'created',
  IN_PROGRESS = 'inProgress',
  CANCELLING = 'cancelling',
  CANCELLED = 'cancelled',
  CANCELLED_BEFORE_PAID = 'cancelledBeforePaid',
  CANCELLATION_REFERENCE = 'cancellationReference',
  ACCEPTING = 'accepting',
  ACCEPTED = 'accepted',
  REJECTING = 'rejecting',
  REJECTED = 'rejected',
  READY = 'ready',
  COMPLETED = 'completed',
  REFUNDED = 'refunded',
  PAID = 'paid', // <--> choose one: completed <-> paid
  UNPAID = 'unpaid', // <--> ???
  SPLIT = 'split',
  SCHEDULED = 'scheduled',
  DEBITOR = 'debitor',
}

export enum Reason {
  REFUND = 'refund',
  CHANGE_PAYMENT = 'changePayment',
  REACTIVE = 'reactive',
  CANCELLED = 'cancelled',
}

export interface ItemModifier {
  modifierId: string;
  _id?: string
  id?: string
  originalModifierId?: string
  name: string
  vSum?: number
  price: number
  quantity: number
  vPrice?: number
  commitRef?: string
  groupPrinter?: string
  groupPrinter2?: string
  type?: string
}

export interface itemModifierInput extends Omit<ItemModifier, 'quantity'> {
  quantity?: number
}

export interface Tax {
  name?: string
  tax: number
  net: number
  gross: number
}

export type VTaxSum = { [p: string]: Tax }

export enum TseMethod {
  passthrough = 'passthrough',
  apply = 'apply',
  applyPart = 'apply:part',
  apply1 = 'apply:1',
  auto = 'auto',
  forcePassthrough = 'forcePassthrough',
}

export type VTaxComponents = { [k: string]: number }

export interface OrderItem {
  originalInfo?: OriginalInfo
  tax2?: number
  originalProductId?: string
  taxComponents2?: SubTax[] | undefined
  _id?: string
  productId?: string
  movedQuantity?: number
  isKitchenDone?: boolean
  isVoucher?: boolean
  voucher?: VoucherInfo
  sent?: boolean
  note?: string
  separate?: boolean
  course?: number // 0: takeAway, -1: separate, n: course n
  vTaxSum?: VTaxSum
  vDiscount?: number
  discountLabel?: string
  taxes?: Array<number>
  id?: string
  name?: string
  vTakeAway?: boolean
  takeAway?: boolean
  /**
   * Promotion discount. Can be a specific amount (number) or a percentage (string).
   *
   * When set, it will be used to calculate `vDiscount` and `vTotal`.
   */
  discount?: number | string
  /**
   * Before tax value of one item, including discount
   */
  vPrice?: number
  /**
   * Before tax value of one item, including modifiers and discount
   *
   * Recipe: `vTotal = vPrice + Σ modifiers.vPrice`
   */
  vTotal?: number
  /**
   * Before tax value of the items, including modifiers and discount
   *
   * Recipe: `vSubTotal = vTotal * quantity`
   */
  vSubTotal?: number
  price: number
  //use in case merge items
  lastQuantity?: number
  quantity: number
  tax?: number
  modifiers: Array<ItemModifier>
  /**
   * Total value of items, including tax and discount
   *
   * Recipe: `vSubTotal = vTotal * quantity`
   */
  vSum?: number
  printed?: boolean
  printedRound?: number
  commitRefs?: Array<string>
  groupPrinter?: string
  groupPrinter2?: string
  labelPrinter?: string
  seat?: number
  //todo: voucher commit
  code?: string
  isModifier?: boolean
  category?: string
  categories?: string[]
  ingredients?: {
    inventoryId: string
    amount: number
  }[]
  tseMethod?: TseMethod
  date?: number
  taxComponents?:
    | {
        name?: string
        value?: number
        printLabel?: string
      }[]
    | undefined
  /**
   * Tax components for each tax type. Only used for SRM.
   *
   * Recipe: vSubTotal * taxComponents[taxType]
   */
  vTaxComponents?: VTaxComponents
  splitId?: string
  variation?: string
}

export type orderItemInput = Optional<OrderItem, 'modifiers' | 'course' | 'quantity' | 'commitRefs'>

export interface OrderPayment {
  value: number
  /** Payment's name (set from the field `Payment.name`) */
  type: string
  /** Payment's type (set from the field `Payment.type`) */
  extraType?: PaymentType
  icon?: PaymentIcons
  commitRefs?: Array<string>
  by?: string // clover | nuvei | etc
}

export interface OriginalInfo {
  oldId?: string
  oldPrice?: number
  oldQuantity?: number

  /**
   * The latest transaction (`order.srm_transactions`) in the order that this item was split from
   *
   * This will be set by parent order, and passing to child order (seats)
   */
  srm_originalTransactionId?: string
}

export interface GroupModifier {
  name: string
  items: Array<ItemModifier>
  _id: string
}

export type Customer = {
  name: string
  company?: string
  phone: string
  address?: string
  zipCode?: string
  placeId?: string
  email?: string
  distance?: number
  lng?: number
  lat?: number
  note?: string
  extraAddressInfo?: string
  city?: string
  country?: string
  ustId?: string
  type?: string
  taxNo?: string
}

export enum MasterHandlerCommand {
  cmd1 = 'cmd1',
  cmd2 = 'cmd2',
  doCancelOrder = 'doCancelOrder',
  onKitchenPrint = 'onKitchenPrint',
  onKitchenAndLabelPrint = 'onKitchenAndLabelPrint',
  onKitchenPrintFastCheckout = 'onKitchenPrintFastCheckout',
  payOrderHandler = 'payOrderHandler',
  paySplitHandler = 'paySplitHandler',
  preSplitHandler = 'preSplitHandler',
  printInvoice = 'printInvoice',
  rePrintInvoice = 'rePrintInvoice',
  singleComplete = 'singleComplete',
  srmReproduceBill = 'srm_reproduceBill',
  srmCancelOrder = 'srm_cancelOrder',
  srmCancelRefund = 'srm_cancelRefund',
  srmRecordClosingReceipt = 'srm_recordClosingReceipt',
  srmRecordTemporaryBill = 'srm_recordTemporaryBill',
  srmPrintStaffReport = 'srm_printStaffReport',
}

export type MasterHandler = {
  cmd: MasterHandlerCommand[]
  cmdUuids?: string[]
  status: string
  reset?: boolean
}

export interface OrderSrmTransaction extends TransactionData {
  _id: string
  date: number
  total: number
}
export enum OrderKitchenStatus {
  NEW = 'new',
  PREPARE = 'prepare',
  DONE = 'done',
  ARCHIVED = 'archived'
}

interface Order {
  _id: string
  /** ID for display only */
  id?: number
  isStarted?: boolean
  ticketNumber?: number
  rawId?: string
  kitchenStatus?: OrderKitchenStatus
  startAt?: number
  externalStoreId?: string
  externalId?: string
  provider?: MarketPlaceProvider
  protocol?: TransferProtocol
  type?: OrderType
  tip?: number
  donation?: number
  cashback?: number
  /**
   * After tax value of discount
   * 
   * Recipe: `vDiscount = Σ items.vDiscount`
   */
  vDiscount?: number
  /**
   * Promotion discount. Can be a specific amount (number) or a percentage (string).
   *
   * When set, it will be used to calculate `items.vDiscount` and `items.vPrice`.
   */
  discount?: string | number
  pDiscount?: number
  discountLabel?: string
  discountDetails?: Array<{ name: string; value: number }>
  preventReset?: boolean
  items: Array<OrderItem>
  cancellationItems?: Array<OrderItem>
  takeAway?: boolean
  manual?: boolean
  status: OrderStatus
  payments: Array<OrderPayment>
  users?: Array<string>
  /**
   * After-tax value of order, including tax, discount and service fee (excluding tip)
   *
   * Recipe:
   * - In seat mode: `vSum = Σ seatMap.vSum`
   * - In non-seat mode: `vSum = (Σ items.vSum) + serviceFee`
   */
  vSum?: number
  /**
   * After-tax value of order, including tax and discount (excluding service fee and tip)
   *
   * Recipe: `vSum = Σ items.vSum`
   */
  vItemTotal?: number
  /**
   * Before tax value of order, including discount
   *
   * Recipe: `vSubTotal = Σ items.vSubTotal`
   */
  vSubTotal?: number
  vDate?: number
  date?: number
  createdDate?: string // ISO the time the order has been created
  answerDate?: string // ISO the time to answer the order: reject | accept
  pickupDate?: string // ISO time ready for pickup the items (end-user + courier)
  dropOffDate?: string // time to drop off the package (end-user receive the items)
  responseMessage?: string
  /**
   * Total tax value of order
   * 
   * Recipe: `vTaxSum = Σ items.vTaxSum`
   */
  vTaxSum?: VTaxSum
  commits?: Array<ItemCommit>
  count?: number
  table?: string
  /** Split bill on table */
  seatMode?: boolean
  isGroupBill?: boolean
  seat?: number
  //need strip before save
  payable?: boolean
  /**
   * groupBy
   * [1] -> items
   */
  seatMap?: Array<Omit<Order, 'seatMap'>> // Omit itself to avoid circular dependency
  selectedSeat?: string
  getRecent?: (printed?: boolean, lastPrintedRound?: number) => Array<OrderItem>
  getCancellationRecent?: (printed?: boolean, lastPrintedRound?: number) => Array<OrderItem>
  getMoveOrder?: () => Order
  lack?: number
  serviceFee?: number
  pickUpNumber?: number
  note?: string
  customer?: string
  customerRaw?: Customer
  shippingData?: {
    tip?: number
    fee: number
    tax: number
    vTax?: number
    vTaxSum?: VTaxSum
    comment: string // delivery_comment
    estimatedDurationInMinutes: number
    service: DeliveryService
    serviceFee: number
    serviceTax: number
    vServiceTax?: number
    vServiceTaxSum?: VTaxSum
    trackingUrl: string
  }
  getNet?: () => number
  getTax?: () => number
  z?: number
  //only use for move items to another order (none-persistent)
  isMoveOrder?: boolean
  //use for online order pre-computed tip
  manualTip?: number
  //use for manual cash tip
  cashTip?: number
  isFake?: boolean
  bagFee?: number
  taxTotal?: number // total tax by provider
  providerTotal?: number // total order value by provider
  vExtraTax?: number //
  /**
   * After-tax value of order, including tax, discount, service fee and tip
   *
   * Recipe: `vTotal = vSum + tip`
   */
  vTotal?: number
  vTaxComponents?: VTaxComponents
  qrCode?: string
  tseMethod?: TseMethod
  originalOrder?: string
  /** Reference to other Order, usually used in refund or changing payment method. Take value of `order._id` */
  refOrder?: string
  /** Reference to other Order, usually used in refund or changing payment method. Take value of `order.id` */
  refOrderId?: number
  splitId?: string
  printInvoice?: boolean
  needReassignQr?: boolean
  reactive?: true
  immediatelyPay?: boolean
  masterHandlers?: MasterHandler
  metadata?: Record<string, string>
  refunded?: boolean
  trainingMode?: boolean
  refundReason?: string
  reason?: Reason
  appVersion?: string
  eod?: string
  // FIXME: Redesign this schema, prefer moving it to `users`
  modifiedBy?: string
  multiplePriceMenu?: string
  discountSeat?: Array<{ seat: number; discount: string }>
  directCancellationItems?: Array<OrderItem>
  lastPrintedRound?: number

  /** Transaction Data. Should only hold Temporary Bills */
  srm_transactions?: Array<OrderSrmTransaction>

  /**
   * Define the way to calculate tax
   * 
   * One step: calculate tax after discount
   * Two step: calculate tax before discount
   */
  taxCalMethod?: TaxCalMethod
}
export type TOrder = Order
export type OrderItemStrip = OrderItem
export type OrderStrip = Order

export enum CommitAction {
  ADD_PRODUCT = 0,
  ADD_MODIFIER = 1,
  ADD_MODIFIER_TO_LAST_ITEM = 2,
  CHANGE_QUANTITY = 3,
  PRINT = 4,
  SEAT_SPLIT = 5,
  CHANGE_SEAT_MODE = 6,
  CHANGE_SEAT_QUANTITY = 7,
  ASSIGN_SEAT = 8,
  MOVE = 9,
  REDUCE = 10,
  ADD_PAYMENT = 11,
  CHANGE_PAYMENT = 12,
  CLEAR_PAYMENTS = 13,
  PAY = 14,
  CHANGE_TAKE_AWAY = 15,
  ADD_ORDER_DISCOUNT = 16,
  CREATE_MOVE_ORDER = 17,
  MOVE_TO_ORDER = 18,
  ASSIGN_MOVE_ORDER = 19,
  SET_TSE_METHOD = 20,
  CHANGE_ORDER_TAKE_AWAY = 21,
  CLEAR_DISCOUNT_ORDER = 22,
  COMPLETE_MOVE_ORDER = 23,
  SET_COURSE = 24,
  ADD_DISCOUNT_ORDER_ITEM = 25,
  CLEAR_DISCOUNT_ORDER_ITEM = 26,
  SET_TIP = 27,
  SET_SERVICE_FEE = 28,
  SET_ORDER_TYPE = 29,
  SET_PROVIDER_TYPE = 30,
  SET_STATUS = 31,
  REFUND = 32,
  CANCEL_BEFORE_PRINT = 33,
  EDIT_ITEM = 34,
  CHANGE_CASH_TIP = 35,
  SPLIT_ITEM = 36,
  PRINT_UNPAID_BILL = 37,
  ADD_TICKET_NUMBER = 38,
  EQUALLY_SPLIT = 39,
  ADD_SPLIT_ITEM = 40,
  ADD_SPLIT_MODIFIER = 41,
  CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE = 42,
  MERGE_SEAT = 43,
  GROUP_TABLE = 44,
  HANDLE_ZERO_SEAT = 45,
  SET_ORDER_GROUP_BILLS = 46,
  ADD_PAYMENT_ALL_SEATS = 47,
  RECORD_SRM_TRANSACTIONS = 48,
  ADD_DIRECT_CANCELLATION_ITEM = 49,
  SET_ITEM_STATUS = 50,
  CHANGE_NOTE = 51,
  CHANGE_ORDER_TAKE_AWAY_KIOSK = 52,
  CHANGE_MODIFIER_QUANTITY = 53,
  DELETE_MODIFIER_QUANTITY = 54,
  SET_ORDER_TSE_METHOD = 55,
}

//map CommitAction to string
export const CommitActionString: { [T in CommitAction]: string } = {
  [CommitAction.ADD_PRODUCT]: 'ADD_PRODUCT',
  [CommitAction.ADD_MODIFIER]: 'ADD_MODIFIER',
  [CommitAction.ADD_MODIFIER_TO_LAST_ITEM]: 'ADD_MODIFIER_TO_LAST_ITEM',
  [CommitAction.CHANGE_QUANTITY]: 'CHANGE_QUANTITY',
  [CommitAction.PRINT]: 'PRINT',
  [CommitAction.SEAT_SPLIT]: 'SEAT_SPLIT',
  [CommitAction.CHANGE_SEAT_MODE]: 'CHANGE_SEAT_MODE',
  [CommitAction.CHANGE_SEAT_QUANTITY]: 'CHANGE_SEAT_QUANTITY',
  [CommitAction.ASSIGN_SEAT]: 'ASSIGN_SEAT',
  [CommitAction.MOVE]: 'MOVE',
  [CommitAction.REDUCE]: 'REDUCE',
  [CommitAction.ADD_PAYMENT]: 'ADD_PAYMENT',
  [CommitAction.CHANGE_PAYMENT]: 'CHANGE_PAYMENT',
  [CommitAction.CLEAR_PAYMENTS]: 'CLEAR_PAYMENTS',
  [CommitAction.PAY]: 'PAY',
  [CommitAction.CHANGE_TAKE_AWAY]: 'CHANGE_TAKE_AWAY',
  [CommitAction.ADD_ORDER_DISCOUNT]: 'ADD_ORDER_DISCOUNT',
  [CommitAction.CREATE_MOVE_ORDER]: 'CREATE_MOVE_ORDER',
  [CommitAction.MOVE_TO_ORDER]: 'MOVE_TO_ORDER',
  [CommitAction.ASSIGN_MOVE_ORDER]: 'ASSIGN_MOVE_ORDER',
  [CommitAction.SET_TSE_METHOD]: 'SET_TSE_METHOD',
  [CommitAction.CHANGE_ORDER_TAKE_AWAY]: 'CHANGE_ORDER_TAKE_AWAY',
  [CommitAction.CLEAR_DISCOUNT_ORDER]: 'CLEAR_DISCOUNT_ORDER',
  [CommitAction.COMPLETE_MOVE_ORDER]: 'COMPLETE_MOVE_ORDER',
  [CommitAction.SET_COURSE]: 'SET_COURSE',
  [CommitAction.ADD_DISCOUNT_ORDER_ITEM]: 'ADD_DISCOUNT_ORDER_ITEM',
  [CommitAction.CLEAR_DISCOUNT_ORDER_ITEM]: 'CLEAR_DISCOUNT_ORDER_ITEM',
  [CommitAction.SET_TIP]: 'SET_TIP',
  [CommitAction.SET_SERVICE_FEE]: 'SET_SERVICE_FEE',
  [CommitAction.SET_ORDER_TYPE]: 'SET_ORDER_TYPE',
  [CommitAction.SET_PROVIDER_TYPE]: 'SET_PROVIDER_TYPE',
  [CommitAction.SET_STATUS]: 'SET_STATUS',
  [CommitAction.REFUND]: 'REFUND',
  [CommitAction.CANCEL_BEFORE_PRINT]: 'CANCEL_BEFORE_PRINT',
  [CommitAction.EDIT_ITEM]: 'EDIT_ITEM',
  [CommitAction.CHANGE_CASH_TIP]: 'CHANGE_CASH_TIP',
  [CommitAction.SPLIT_ITEM]: 'SPLIT_ITEM',
  [CommitAction.PRINT_UNPAID_BILL]: 'PRINT_UNPAID_BILL',
  [CommitAction.ADD_TICKET_NUMBER]: 'ADD_TICKET_NUMBER',
  [CommitAction.EQUALLY_SPLIT]: 'EQUALLY_SPLIT',
  [CommitAction.ADD_SPLIT_ITEM]: 'ADD_SPLIT_ITEM',
  [CommitAction.ADD_SPLIT_MODIFIER]: 'ADD_SPLIT_MODIFIER',
  [CommitAction.CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE]: 'CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE',
  [CommitAction.MERGE_SEAT]: 'MERGE_SEAT',
  [CommitAction.SET_ORDER_GROUP_BILLS]: 'SET_ORDER_GROUP_BILLS',
  [CommitAction.ADD_PAYMENT_ALL_SEATS]: 'ADD_PAYMENT_ALL_SEATS',
  [CommitAction.GROUP_TABLE]: 'GROUP_TABLE',
  [CommitAction.HANDLE_ZERO_SEAT]: 'HANDLE_ZERO_SEAT',
  [CommitAction.RECORD_SRM_TRANSACTIONS]: 'RECORD_SRM_TRANSACTIONS',
  [CommitAction.ADD_DIRECT_CANCELLATION_ITEM]: 'ADD_DIRECT_CANCELLATION_ITEM',
  [CommitAction.SET_ITEM_STATUS]: 'SET_ITEM_STATUS',
  [CommitAction.CHANGE_NOTE]: 'CHANGE_NOTE',
  [CommitAction.CHANGE_ORDER_TAKE_AWAY_KIOSK]: 'CHANGE_ORDER_TAKE_AWAY_KIOSK',
  [CommitAction.CHANGE_MODIFIER_QUANTITY]: 'CHANGE_MODIFIER_QUANTITY',
  [CommitAction.DELETE_MODIFIER_QUANTITY]: 'DELETE_MODIFIER_QUANTITY',

}

export enum EquallySplit {
  CHANGE_PRICE,
  CHANGE_QUANTITY,
}

export interface ItemCommitCommon {
  date?: number
  processed?: boolean
  seat?: number
  _id?: string
}

export interface ItemCommitEqualSplit extends ItemCommitCommon {
  action: CommitAction.EQUALLY_SPLIT
  parts: number
  option: EquallySplit
}

export interface ItemCommitMergeSeat extends ItemCommitCommon {
  action: CommitAction.MERGE_SEAT
}

export interface ItemCommitGroupBills extends ItemCommitCommon {
  action: CommitAction.SET_ORDER_GROUP_BILLS
  value: boolean
}

export interface ItemCommitGroupTable extends ItemCommitCommon {
  action: CommitAction.GROUP_TABLE
  seatMode?: boolean
}

export interface ItemCommitHandleZeroSeat extends ItemCommitCommon {
  action: CommitAction.HANDLE_ZERO_SEAT
}

export interface ItemCommitAddSplitItem extends Omit<ItemCommitAddProduct, 'action'> {
  commitId: string
  action: CommitAction.ADD_SPLIT_ITEM
  seat?: number
  option: EquallySplit
}

export interface ItemCommitAddSplitModifier extends Omit<ItemCommitAddModifier, 'action'> {
  action: CommitAction.ADD_SPLIT_MODIFIER
  option: EquallySplit
}

export interface ItemCommitAddProduct extends ItemCommitCommon {
  price?: number
  ref?: string
  productRef?: {
    type?: string
    id?: string
    name: string
    price?: number
    taxes?: Array<number>
    category?: string
    categories?: string[]
    isVoucher?: boolean
    voucher?: VoucherInfo
    code?: string
    ingredients?: {
      amount: number
      inventoryId: string
    }[]
    taxComponents?: SubTax[]
    taxComponents2?: SubTax[]
  }
  note?: string
  _id: string
  action: CommitAction.ADD_PRODUCT
  quantity?: number
  groupPrinter?: string
  groupPrinter2?: string
  labelPrinter?: string
  printed?: boolean
}

export interface ItemCommitSplitItem extends Omit<ItemCommitAddProduct, 'action'> {
  commitId: string
  action: CommitAction.SPLIT_ITEM
  printedRound?: number
  oldId?: string
  oldQuantity?: number
  oldPrice?: number
  originalSeat?: number
  originalQuantity?: number
  /**
   * The latest transaction (`order.srm_transactions`) in the order that this item was split from
   *
   * This will be set by parent order, and passing to child order (seats)
   */
  srm_originalTransactionId?: string
}

export interface ItemCommitAddModifier extends Omit<ItemCommitCommon, '_id'>, Omit<ItemCommitAddProduct, 'action'> {
  commitId: string
  action: CommitAction.ADD_MODIFIER
  seat?: number
}

export interface ItemCommitAddModifierToLastItem extends Omit<ItemCommitAddProduct, 'action'> {
  action: CommitAction.ADD_MODIFIER_TO_LAST_ITEM
}

export interface ItemCommitChangeQuantity extends ItemCommitCommon {
  quantityDelta: number
  commitId: string
  action: CommitAction.CHANGE_QUANTITY
}
export interface ItemCommitChangeModifierQuantity extends ItemCommitCommon {
  quantityDelta: number
  modifierId: string
  commitId: string
  action: CommitAction.CHANGE_MODIFIER_QUANTITY
}

export interface ItemCommitDeleteQuantity extends ItemCommitCommon {
  modifierId: string
  commitId: string
  action: CommitAction.DELETE_MODIFIER_QUANTITY
}

export interface ItemCommitChangeNote extends ItemCommitCommon {
  note: string
  commitId: string
  action: CommitAction.CHANGE_NOTE
}

export interface ItemCommitPrint extends ItemCommitCommon {
  action: CommitAction.PRINT
}
export interface ItemCommitTicketNumber extends ItemCommitCommon {
  action: CommitAction.ADD_TICKET_NUMBER
  ticketNumber: number
}
export interface ItemCommitChangeSeatMode extends ItemCommitCommon {
  seatMode: boolean
  action: CommitAction.CHANGE_SEAT_MODE
}

export interface ItemCommitChangeSeatQuantity extends ItemCommitCommon {
  delta: number
  action: CommitAction.CHANGE_SEAT_QUANTITY
  ids: string[]
}

export interface ItemCommitSeatSplit extends ItemCommitCommon {
  seat: number
  action: CommitAction.SEAT_SPLIT
}

/**
 * if quantity > 0 -> move from main -> seat
 *
 * if quantity < 0 -> move from seat -> main
 */
export interface ItemCommitMove extends ItemCommitCommon {
  commitId: string
  quantity: number
  seat: number
  action: CommitAction.MOVE
  printed?: boolean
}

export interface ItemCommitReduce extends ItemCommitCommon {
  action: CommitAction.REDUCE
}

export interface ItemCommitAssignSeat extends ItemCommitCommon {
  commitId: string
  seat: number
  delta: number
  resetQuantity?: boolean
  discount?: string | number
  action: CommitAction.ASSIGN_SEAT
}

//problem -> create too many commit : is not the good way
//mutate is bad too
export interface ItemCommitAddPayment extends ItemCommitCommon {
  action: CommitAction.ADD_PAYMENT
  type: string
  extraType?: PaymentType
  value: number
  by?: string // clover | nuvei | etc
}

export interface ItemCommitAddPaymentAllSeat extends Omit<ItemCommitAddPayment, 'action'> {
  action: CommitAction.ADD_PAYMENT_ALL_SEATS
}

export interface ItemCommitChangePayment extends ItemCommitCommon {
  action: CommitAction.CHANGE_PAYMENT
  type: string
  extraType?: PaymentType
  value: number
  by?: string // clover | nuvei | etc
}

export interface ItemCommitClearPayments extends ItemCommitCommon {
  action: CommitAction.CLEAR_PAYMENTS
}

export interface ItemCommitPay extends ItemCommitCommon {
  action: CommitAction.PAY
}

export interface ItemCommitChangeTakeAway extends ItemCommitCommon {
  takeAway: boolean
  commitId: string
  action: CommitAction.CHANGE_TAKE_AWAY
}

export interface ItemCommitAddOrderDiscount extends ItemCommitCommon {
  action: CommitAction.ADD_ORDER_DISCOUNT
  type: string
  value: string
  label: string
  seat?: number
}

export interface ItemCommitCreateMoveOrder extends ItemCommitCommon {
  action: CommitAction.CREATE_MOVE_ORDER
}

export interface ItemCommitSetItemStatus extends ItemCommitCommon {
  action: CommitAction.SET_ITEM_STATUS
  commitId: string
  isKitchenDone: boolean
}

export interface ItemCommitMoveToOrder extends Omit<ItemCommitMove, 'seat' | 'action'> {
  action: CommitAction.MOVE_TO_ORDER
}

export interface ItemCommitAssignMoveOrder extends Omit<ItemCommitAssignSeat, 'seat' | 'action'> {
  action: CommitAction.ASSIGN_MOVE_ORDER
}

export interface ItemCommitSetTseMethod extends ItemCommitCommon {
  action: CommitAction.SET_TSE_METHOD
  commitId: string
  tseMethod: TseMethod
  date?: number
  seat?: number
}

export interface ItemCommitSetOrderTseMethod extends ItemCommitCommon {
  action: CommitAction.SET_ORDER_TSE_METHOD
  tseMethod: TseMethod
  date?: number
  seat?: number
}

export interface ItemCommitChangeOrderTakeAway extends ItemCommitCommon {
  takeAway: boolean
  action: CommitAction.CHANGE_ORDER_TAKE_AWAY
}

export interface ItemCommitChangeOrderTakeAwayKiosk extends ItemCommitCommon {
  takeAway: boolean
  action: CommitAction.CHANGE_ORDER_TAKE_AWAY_KIOSK
}

export interface ItemCommitClearDiscountOrder extends ItemCommitCommon {
  action: CommitAction.CLEAR_DISCOUNT_ORDER
  seat?: number
}

export interface ItemCommitSetTip extends ItemCommitCommon {
  action: CommitAction.SET_TIP
  value: number
}

export interface ItemCommitChangeTip extends ItemCommitCommon {
  action: CommitAction.CHANGE_CASH_TIP
  value: number
}

export interface ItemCommitSetServiceFee extends ItemCommitCommon {
  action: CommitAction.SET_SERVICE_FEE
  value: number
  seat?: number
}

export interface ItemCommitCompleteMoveOrder extends ItemCommitCommon {
  action: CommitAction.COMPLETE_MOVE_ORDER
  isGroup?: boolean
}

export interface ItemCommitChangeCourse extends ItemCommitCommon {
  commitId: string
  course: number
  action: CommitAction.SET_COURSE
}

export interface ItemCommitAddDiscountOrderItem extends ItemCommitCommon {
  action: CommitAction.ADD_DISCOUNT_ORDER_ITEM
  commitId: string
  type: string
  value: number
  label: string
}

export interface ItemCommitClearDiscountOrderItem extends ItemCommitCommon {
  action: CommitAction.CLEAR_DISCOUNT_ORDER_ITEM
  commitId: string
}

export interface ItemCommitSetOrderType extends ItemCommitCommon {
  action: CommitAction.SET_ORDER_TYPE
  orderType: OrderType
}

export interface ItemCommitSetProviderType extends ItemCommitCommon {
  action: CommitAction.SET_PROVIDER_TYPE
  provider: MarketPlaceProvider
}

export interface ItemCommitSetStatus extends ItemCommitCommon {
  action: CommitAction.SET_STATUS
  status: OrderStatus
  seat?: number
}

export interface ItemCommitRefund extends ItemCommitCommon {
  action: CommitAction.REFUND
  itemsIds: string[]
  reason: string
}

export interface ItemCommitCancelBeforePrint extends ItemCommitCommon {
  action: CommitAction.CANCEL_BEFORE_PRINT
}

export interface ItemCommitEditItem extends ItemCommitCommon {
  action: CommitAction.EDIT_ITEM
  commitId: string
  name: string
  price: number
}

export interface ItemCommitPrintUnpaidBill extends ItemCommitCommon {
  action: CommitAction.PRINT_UNPAID_BILL
}

export interface ItemCommitClearDiscountCommits extends ItemCommitCommon {
  action: CommitAction.CLEAR_DISCOUNT_COMMIT_BEFORE_MOVE
}

export interface ItemCommitRecordSrmTransaction extends ItemCommitCommon {
  action: CommitAction.RECORD_SRM_TRANSACTIONS
  data: OrderSrmTransaction
  seat?: number
}

export interface ItemCommitAddDirectCancellationItem extends ItemCommitCommon {
  action: CommitAction.ADD_DIRECT_CANCELLATION_ITEM
  data: OrderItem
  orderId: string
}

export type ItemCommit =
  | ItemCommitAddProduct
  | ItemCommitPrint
  | ItemCommitChangeQuantity
  | ItemCommitAddModifier
  | ItemCommitAddModifierToLastItem
  | ItemCommitSeatSplit
  | ItemCommitChangeSeatQuantity
  | ItemCommitChangeSeatMode
  | ItemCommitReduce
  | ItemCommitAssignSeat
  | ItemCommitMove
  | ItemCommitAddPayment
  | ItemCommitChangePayment
  | ItemCommitClearPayments
  | ItemCommitPay
  | ItemCommitChangeTakeAway
  | ItemCommitAddOrderDiscount
  | ItemCommitCreateMoveOrder
  | ItemCommitMoveToOrder
  | ItemCommitAssignMoveOrder
  | ItemCommitSetTseMethod
  | ItemCommitChangeOrderTakeAway
  | ItemCommitChangeOrderTakeAwayKiosk
  | ItemCommitClearDiscountOrder
  | ItemCommitCompleteMoveOrder
  | ItemCommitChangeCourse
  | ItemCommitAddDiscountOrderItem
  | ItemCommitClearDiscountOrderItem
  | ItemCommitSetTip
  | ItemCommitSetServiceFee
  | ItemCommitSetOrderType
  | ItemCommitSetProviderType
  | ItemCommitSetStatus
  | ItemCommitRefund
  | ItemCommitCancelBeforePrint
  | ItemCommitEditItem
  | ItemCommitChangeTip
  | ItemCommitSplitItem
  | ItemCommitPrintUnpaidBill
  | ItemCommitTicketNumber
  | ItemCommitEqualSplit
  | ItemCommitAddSplitItem
  | ItemCommitAddSplitModifier
  | ItemCommitClearDiscountCommits
  | ItemCommitMergeSeat
  | ItemCommitGroupBills
  | ItemCommitAddPaymentAllSeat
  | ItemCommitGroupTable
  | ItemCommitHandleZeroSeat
  | ItemCommitRecordSrmTransaction
  | ItemCommitAddDirectCancellationItem
  | ItemCommitSetItemStatus
  | ItemCommitChangeNote
  | ItemCommitChangeModifierQuantity
  | ItemCommitDeleteQuantity
  | ItemCommitSetOrderTseMethod
// Self type checking: This will report error on `ItemCommit` if any of its variant does not extends `ItemCommitCommon`
