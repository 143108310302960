import { type FunctionComponent } from "react";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import DeliveryIcon from "./DeliveryIcon.tsx";
import PickupIcon from "./PickupIcon.tsx";

type PendingOrdersOrderTimeType = {
  showTakeawayIcon?: boolean;
  showDeliverIcon?: boolean;
  orderTime?: string;
  onShowOrderDetail: Function;
};

const PendingOrdersOrderTime: FunctionComponent<PendingOrdersOrderTimeType> = ({
  showTakeawayIcon,
  showDeliverIcon,
  orderTime,
  onShowOrderDetail
}) => {
  return (
    <>
      <div
        className="rounded-sm bg-whitesmoke flex flex-row items-center justify-start py-1 px-2 gap-[4px] cursor-pointer text-right text-sm text-black font-mulish border-[1px] border-solid border-silver Tablet_768:gap-[2px]">
        {showDeliverIcon && <DeliveryIcon/>}
        {showTakeawayIcon && <PickupIcon/>}
        <b className="relative leading-[15px] inline-block shrink-0 Tablet_768:text-xs">
          {orderTime}
        </b>
      </div>
    </>
  );
};

export default PendingOrdersOrderTime;
