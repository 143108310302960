import { browserTracingIntegration, init, setTag } from '@sentry/react'

import { getDeviceId } from '@/shared/getDeviceId'
import { posSync0 } from '@/data/PosSyncState.ts'
import { server } from '@/data/Server.ts'
import { effect, effectOn } from '@/react/core/reactive'
import { wsLock } from '@/react/websocket/ws-state.ts'
import { rnHost } from '@/shared/webview/rnwebview.ts'

export function initSentry() {
  init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      browserTracingIntegration(),
      // replayIntegration({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })

  setTag('deviceId', getDeviceId())
  setTag('version', import.meta.env.VITE_APP_VERSION)
  setTag('versionDate', import.meta.env.VITE_APP_DATE)

  effectOn([posSync0], async () => {
    const storeId = posSync0()?.id
    if (storeId) setTag('storeId', storeId)
    await wsLock.acquireAsync()
  })

  effectOn([server], () => {
    const serverName = server()
    if (serverName) setTag('server', serverName)
  })
  effect(() => {
    wsLock.acquireAsync().then(() => rnHost.sendVersion(import.meta.env.VITE_APP_VERSION))
  })
}
