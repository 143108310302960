import { Fragment, type FunctionComponent, useEffect, useState } from 'react'
import { LL0 } from '@/react/core/I18nService.tsx'
import type { TimeClock } from '@/data/TimeClock.ts'
import dayjs from 'dayjs'
import { monthlyReport, } from '@/react/MonthlyReportView/MonthlyReportView.tsx';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type MonthtlyReportItemListType = {
  staff?: string;
  username?: string;
  staffInfo?: string;
  time?: string;
  userRoleLabel?: string;
  showViewDetailButton?: boolean;
  showViewDetail?: boolean;
  showDefault?: boolean;
  timeClocksByDate: Record<string, TimeClock[]>;
};

type TooltipState = {
  date: string | null;
  index: number | null;
};

export function secondsToHoursMinutes(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return seconds ? (hours === 0 ? `${minutes}'` : (minutes === 0 ? `${hours}h` : `${hours}h${minutes}'`)) : '0'
}


const MonthtlyReportItemList: FunctionComponent<MonthtlyReportItemListType> = ({
  staff = 'Staff 2:',
  username,
  staffInfo = '40 shifts in 20days',
  time = '160.5 hours',
  userRoleLabel,
  showViewDetailButton = true,
  showViewDetail = true,
  showDefault = true,
  timeClocksByDate
}) => {
  const [isShowDefault, setIsShowDefault] = useState<boolean>(true)
  showDefault = isShowDefault
  showViewDetail = !isShowDefault
  const toggleShowDefault = () => setIsShowDefault(prev => !prev)

  const [hovered, setHovered] = useState<TooltipState>({ date: null, index: null });

  const handleMouseEnter = (date: string, index: number) => {
    userFLow(`mouse enter`, {
      username: loginUser()?.name
    });
    setHovered({ date, index });
  };

  const handleMouseLeave = () => {
    userFLow(`mouse leave`, {
      username: loginUser()?.name
    });
    setHovered({ date: null, index: null });
  };
  const [totalBreakTimeByUser, setTotalBreakTimeByUser] = useState<any>(monthlyReport()?.totalBreakTimeByUser[username!]?.totalBreakTimeByDate)

  const [timeBreaksByDate, setTimeBreaksByDate] = useState<any>(monthlyReport()?.totalBreakTimeByUser[username!]?.timeBreaksByDate)

  useEffect(() => {
    setTotalBreakTimeByUser(monthlyReport()?.totalBreakTimeByUser[username!]?.totalBreakTimeByDate)
    setTimeBreaksByDate(monthlyReport()?.totalBreakTimeByUser[username!]?.timeBreaksByDate)
  }, [username, monthlyReport().totalBreakTimeByUser]);

  return (
    <div className="max-w-full flex flex-col items-start justify-start gap-[16px] text-right text-sm text-gray font-mulish self-stretch">
      {showDefault && (
        <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
          <div className="flex flex-row items-start justify-start gap-[4px] text-left">
            <div className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs">
              {staff}
            </div>
            <div className="relative Tablet_768:text-smi Tablet_600:text-xs">
              {staffInfo}
            </div>
          </div>
          {showViewDetailButton && (
            <div
              className="flex-1 relative [text-decoration:underline] text-dodgerblue Tablet_768:text-smi Tablet_600:text-xs"
              onClick={toggleShowDefault}
            >
              {LL0().monthlyReport.viewDetail().toLowerCase()}
            </div>
          )}
          <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
            {time}
          </div>
        </div>
      )}
      {showViewDetail && (
        <div
          className="!h-auto self-stretch box-border h-[228px] flex flex-col items-start justify-start py-2 px-0 gap-[8px] border-t-[1px] border-solid border-whitesmoke-200 border-b-[1px]">
          <div className="self-stretch flex flex-row items-center justify-between py-0 pr-0 pl-4">
            <div className="flex flex-row items-start justify-start gap-[4px] text-left">
              <div className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs">
                {userRoleLabel}
              </div>
              <div className="relative Tablet_768:text-smi Tablet_600:text-xs">
                {staffInfo}
              </div>
            </div>
            <div
              className="flex-1 relative [text-decoration:underline] text-dodgerblue Tablet_768:text-smi Tablet_600:text-xs"
              onClick={toggleShowDefault}
            >
              {LL0().monthlyReport.shortened().toLowerCase()}
            </div>
            <div className="flex-1 relative Tablet_768:text-smi Tablet_600:text-xs">
              {time}
            </div>
          </div>
          <div
            className="self-stretch flex-1 relative bg-lavender grid grid-rows-[repeat(1,34)] grid-cols-[repeat(3,1fr)_repeat(1,24px)_repeat(3,1fr)] [grid-row-gap:0px] [grid-column-gap:0px] text-left text-xs">
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] h-[34px] flex flex-row items-center justify-center gap-[4px] relative text-center text-sm text-white">
              <b className="relative leading-[84.5%] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                {LL0().settings.happyHour.days()}
              </b>
              <img
                className="w-4 relative h-4 object-cover hidden"
                alt=""
                src="/iconarrow-down-icon5@2x.png"
              />
            </div>
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] h-[34px] flex flex-row items-center justify-center gap-[4px] relative text-center text-sm text-white">
              <b className="relative leading-[84.5%] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                {LL0().monthlyReport.shift()}
              </b>
              <img
                className="relative w-4 h-4 object-cover "
                alt=""
                src="/iconarrow-down-icon@2x.png"
              />
            </div>
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] h-[34px] flex flex-row items-center justify-center gap-[4px] relative text-center text-sm text-white">
              <b className="relative leading-[84.5%] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                {LL0().monthlyReport.in()}
              </b>
              <img
                className="w-4 relative h-4 object-cover hidden"
                alt=""
                src="/iconarrow-down-icon3@2x.png"
              />
            </div>
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] w-[23.3px] h-[34px] relative" />
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] h-[34px] flex flex-row items-center justify-center gap-[4px] relative text-center text-sm text-white">
              <b className="relative leading-[84.5%] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                {LL0().monthlyReport.out()}
              </b>
              <img
                className="w-4 relative h-4 object-cover hidden"
                alt=""
                src="/iconarrow-down-icon2@2x.png"
              />
            </div>
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] h-[34px] flex flex-row items-center justify-center gap-[4px] relative text-center text-sm text-white">
              <b className="relative leading-[84.5%] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                {LL0().timeClock.clockOutSuccessDialog.totalHours()}
              </b>
              <img
                className="relative w-4 h-4 object-cover hidden"
                alt=""
                src="/iconarrow-down-icon@2x.png"
              />
            </div>
            <div
              className="!row-auto !col-auto [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.3),_rgba(38,_93,_219,_0.3))] [backdrop-filter:blur(98.99px)] h-[34px] flex flex-row items-center justify-center gap-[4px] relative text-center text-sm text-white">
              <b className="relative leading-[84.5%] Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                {LL0().monthlyReport.breakTime()}
              </b>
              <img
                className="relative w-4 h-4 object-cover hidden"
                alt=""
                src="/iconarrow-down-icon4@2x.png"
              />
            </div>
            {Object.entries(timeClocksByDate).map(([date, timeClocks], index) => {
              return (
                <div key={index} className="grid col-span-full border-b-[1px] border-solid border-white"
                  style={{
                    gridTemplateColumns: 'subgrid'
                  }}
                >
                  <div className="h-[38px] flex flex-col items-center justify-center relative col-[1/1] row-[2/2]"
                    style={{
                      gridRow: `1/${timeClocks.length + 1}`
                    }}
                  >
                    <div className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                      {date}
                    </div>
                  </div>

                  <div
                    className="flex flex-col relative items-center justify-center col-[7/7] row-[2/4] text-[11.95px] border-solid border-white"
                    style={{
                      gridRow: `1/${timeClocks.length + 1}`
                    }}
                  >
                    {totalBreakTimeByUser && totalBreakTimeByUser?.[date]?.map((breakTime: number, index: number) => (
                      <div key={index} style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}
                           onMouseEnter={() => handleMouseEnter(date, index)}
                           onMouseLeave={handleMouseLeave}>
                        <div
                          data-tooltip-target="tooltip-hover" data-tooltip-trigger="hover"
                          className="relative [text-decoration:underline] font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs mb-4 mt-2"
                        >
                          {secondsToHoursMinutes(breakTime)}
                        </div>
                        {hovered?.date === date && hovered?.index === index && (
                        <div
                          className = "visible w-[100px] bg-white text-black text-center rounded-lg py-1.5 absolute bottom-[125%] left-1/2 -ml-[50px] opacity-100 transition-opacity duration-300"
                          style={{
                            zIndex: 1,
                          }}
                        >
                          {`${dayjs.unix(timeBreaksByDate?.[date]?.[index]?.out).format('HH:mm')} - ${dayjs.unix(timeBreaksByDate?.[date]?.[index]?.in).format('HH:mm')}`}
                          <div
                            className = "absolute top-full left-1/2 -ml-[5px] border-[5px] border-solid"
                            style={{
                              content: '""',
                              borderColor: 'white transparent transparent transparent'
                            }}
                          ></div>
                        </div>
                        )}
                      </div>
                    ))}
                  </div>

                  {timeClocks.map(({
                    clockInTime,
                    clockOutTime
                  }, index) => (

                    <Fragment key={index}>
                      <div
                        className="!row-auto !col-auto h-[38px] flex flex-col items-center justify-center relative col-[2/2] row-[2/2]">
                        <div
                          className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                          {index + 1}
                        </div>
                      </div>
                      <div
                        className="!row-auto !col-auto h-[38px] flex flex-col items-center justify-center relative col-[3/3] row-[2/2] text-center">
                        <div
                          className="self-stretch rounded [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] shadow-[0px_0px_4.27px_rgba(0,_0,_0,_0.3)_inset] flex flex-row items-center justify-center py-2 px-0 Tablet_768:pt-1.5 Tablet_768:pb-1.5 Tablet_768:box-border Tablet_600:pt-1 Tablet_600:pb-1 Tablet_600:box-border">
                          <div
                            className="flex-1 relative Mobile_480:text-2xs">{dayjs.unix(clockInTime || 0).format('HH:mm')}</div>
                        </div>
                      </div>
                      <div
                        className="!row-auto !col-auto h-[38px] flex flex-col items-center justify-center py-0.5 px-2 box-border relative col-[4/4] row-[2/2] text-white">
                        <div className="relative font-semibold">-</div>
                      </div>
                      <div
                        className="!row-auto !col-auto h-[38px] flex flex-col items-center justify-center relative col-[5/5] row-[2/2] text-center">
                        {clockOutTime && (
                        <div
                          className="self-stretch rounded [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] shadow-[0px_0px_4.27px_rgba(0,_0,_0,_0.3)_inset] flex flex-row items-center justify-center py-2 px-0 Tablet_768:pt-1.5 Tablet_768:pb-1.5 Tablet_768:box-border Tablet_600:pt-1 Tablet_600:pb-1 Tablet_600:box-border">
                            <div className="flex-1 relative Mobile_480:text-2xs">
                              {dayjs.unix(clockOutTime).format('HH:mm')}
                            </div>
                        </div>
                        )}
                        {!clockOutTime && (
                        <div
                          className="self-stretch rounded bg-gradient-to-b from-[#FFDA68] to-[#E3AC00] shadow-[0px_0px_4.27px_rgba(0,_0,_0,_0.3)_inset] flex flex-row items-center justify-center py-2 px-0 Tablet_768:pt-1.5 Tablet_768:pb-1.5 Tablet_768:box-border Tablet_600:pt-1 Tablet_600:pb-1 Tablet_600:box-border">
                            <div
                              className="flex-1 relative Mobile_480:text-2xs">
                              {LL0().timeClock.clockOutDialog.forgot()}
                            </div>
                        </div>
                        )}
                      </div>
                      <div
                        className="!row-auto !col-auto h-[38px] flex flex-col items-center justify-center relative col-[6/6] row-[2/2]">
                        <div
                          className="relative font-semibold Tablet_768:text-smi Tablet_600:text-xs Mobile_480:text-2xs">
                          {/*{clockOutTime ? dayjs.unix(clockOutTime || 0).diff(dayjs.unix(clockInTime || 0), 'hours') : '-'}*/}
                          {clockOutTime ? secondsToHoursMinutes(clockOutTime - clockInTime!) : '-'}
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default MonthtlyReportItemList
