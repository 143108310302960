import type { FunctionComponent } from "react";
import PaymentMethod from "@payment/PaymentMethod";
import { scopeOrder0 } from "@/react/PaymentView/PaymentView.tsx";
import { OrderType } from '@/pos/OrderType.ts';
import { LL0 } from "@/react/core/I18nService.tsx";
import { orderConfig, useServiceFeeBeforeTax } from "@/shared/order/order-config";
import { LL3 } from "@/react/core/I18nCurrency.tsx";
import { isRefundOrder } from "@/pos/logic/order-utils"
import _ from "lodash";

const PaymentInformation: FunctionComponent = () => {
  const tax = orderConfig.useTaxComponents && scopeOrder0()?.vTaxComponents ? _.round(_.sum(_.values(scopeOrder0().vTaxComponents)), 2) : scopeOrder0().getTax?.();
  return (
    <div
      className="self-stretch rounded bg-white-solid-white-100-ffffff shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] box-border flex flex-col items-start justify-start pt-2 px-2.5 pb-2.5 gap-[4px] min-w-[240px] text-left text-xs text-blue-solid-blue-400-0091ff font-mulish border-[1px] border-solid border-gray-solid-gray-300-dbdbdb Tablet_768:p-1.5 Tablet_768:box-border Tablet_600:p-1.5 Tablet_600:box-border mq1440:pt-3 mq1440:px-4 mq1440:pb-4 mq1440:box-border height_414:pt-1 height_414:px-1.5 height_414:pb-1.5 height_414:box-border">
      {!isRefundOrder(scopeOrder0()) && !!scopeOrder0()?.vDiscount &&
        <div
          className="self-stretch flex flex-row items-end justify-between pt-0 px-0 pb-1 border-b-[1px] border-dashed border-gray-solid-gray-600-979797">
          <b className="relative mq1440:text-sm">{LL0().orderHistory.promotionApplied()}</b>
          <div className="relative text-base text-black-solid-black-900-1e1e23 text-center">
            {LL3().format.currency(scopeOrder0().vDiscount || 0)}
          </div>
        </div>
      }
      <div
        className="self-stretch flex flex-row items-start justify-between text-gray-solid-gray-800-777777 height_414:hidden">
        <div className="relative mq1440:text-sm">{LL0().common.subtotal()}:</div>
        <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
          {/*{LL3().format.currency(scopeOrder0().getNet?.() || 0)}*/}
          {LL3().format.currency(scopeOrder0().vSubTotal || 0)}
        </div>
      </div>
      {!!scopeOrder0().serviceFee && (<div className="relative mq1440:text-sm italic text-gray-solid-gray-800-777777 Tablet_768:hidden">{`(${LL0().payment.includeSF()})`}</div>)}
      {/*{useServiceFeeBeforeTax() && !!scopeOrder0().serviceFee &&*/}
      {/*  <div*/}
      {/*    className="self-stretch flex flex-row items-start justify-between text-gray-solid-gray-800-777777 height_414:hidden">*/}
      {/*    <div className="relative mq1440:text-sm">{LL0().payment.serviceFee1()}:</div>*/}
      {/*    <div*/}
      {/*      className="relative text-smi text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">*/}
      {/*      {LL3().format.currency(scopeOrder0().serviceFee || 0)}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*}*/}
      <div
        className="self-stretch flex flex-row items-end justify-between pt-0 px-0 pb-1 text-gray-solid-gray-800-777777 border-b-[1px] border-dashed border-gray-solid-gray-600-979797 height_414:hidden">
        <div className="relative mq1440:text-sm">{LL0().order.tax()}:</div>
        {/*<div className="relative mq1440:text-sm">HST(10%):</div>*/}
        <div className="relative text-smi text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
          {/*{LL3().format.currency(scopeOrder0().getTax?.() || 0)}*/}
          {LL3().format.currency(tax || 0)}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-between">
        <b className="relative mq1440:text-sm">{LL0().common.total()}:</b>
        <b
          className="relative text-lg text-red-solid-red-550-cf2222 text-right sm:text-sm Tablet_600:text-sm mq1440:text-3xl">
          {LL3().format.currency(scopeOrder0().vSum || 0)}
        </b>
      </div>
      <div className = 'self-stretch flex flex-col justify-between border-b-[1px] border-dashed border-gray-solid-gray-600-979797'>
        <div
          className="self-stretch flex flex-row items-center justify-between pt-0 px-0 pb-1 Tablet_768:flex-col Tablet_768:gap-[0px] Tablet_768:items-start Tablet_768:justify-start Tablet_600:flex-col Tablet_600:gap-[0px]">
          <b className="relative mq1440:text-sm">{LL0().order.payment()}:</b>
          <PaymentMethod/>
        </div>
        <div className="Tablet_768:hidden relative mq1440:text-sm italic text-gray-solid-gray-800-777777 ml-0 pb-1">{`(${LL0().order.payment()} = ${[LL0().common.total(), LL0().payment.tip(), LL0().ui.change()].join(' + ')})`}</div>
      </div>
      <div
        className="self-stretch flex flex-row items-start justify-start gap-[8px] text-gray-solid-gray-800-777777 Tablet_768:gap-[6px] Tablet_600:gap-[6px]">
        <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <b className="relative mq1440:text-sm">{LL0().payment.tip()}:</b>
            <div className="relative text-smi font-semibold text-pink-solid-pink-600-ee0ba1 text-right mq1440:text-sm">
              {LL3().format.currency(scopeOrder0().tip || 0)}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-between">
            <b className="relative mq1440:text-sm">{LL0().ui.change()}:</b>
            <div
              className="relative text-smi font-semibold text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
              {LL3().format.currency(scopeOrder0().cashback || 0)}
            </div>
          </div>
          {/*!useServiceFeeBeforeTax() &&*/ !!scopeOrder0().serviceFee &&
            (
              <div className="self-stretch flex flex-col items-start justify-between">
                <div className="self-stretch flex flex-row items-start justify-between">
                  <b className="relative mq1440:text-sm">{LL0().payment.serviceFee1()}:</b>
                  <div
                    className="relative text-smi font-semibold text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
                    {LL3().format.currency(scopeOrder0().serviceFee || 0)}
                  </div>
                </div>
                <div className={'Tablet_768:hidden relative mq1440:text-sm italic text-gray-solid-gray-800-777777 ml-0 pb-1'}>
                  {orderConfig.isNetPriceBase ? `(${LL0().payment.beforeTax()})` : `(${LL0().payment.includeTax()})`}
                </div>
              </div>
            )}
        </div>
        {/*TODO: Map this into real data*/}
        {scopeOrder0().type === OrderType.Delivery && <>
          <div
            className="self-stretch relative box-border w-px border-r-[1px] border-dashed border-gray-solid-gray-600-979797"/>
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px]">
            <div className="self-stretch flex flex-row items-start justify-between">
              <b className="relative mq1440:text-sm">{LL0().payment.deliveryFee()}:</b>
              <div
                className="relative text-smi font-semibold text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
                {LL3().format.currency(0)}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <b className="relative mq1440:text-sm">{LL0().payment.dServiceFee()}:</b>
              <div
                className="relative text-smi font-semibold text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
                {LL3().format.currency(0)}
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-between">
              <b className="relative mq1440:text-sm">{LL0().payment.donation()}:</b>
              <div
                className="relative text-smi font-semibold text-black-solid-black-900-1e1e23 text-right mq1440:text-sm">
                {LL3().format.currency(0)}
              </div>
            </div>
          </div>
        </>}
      </div>
    </div>
  );
};

export default PaymentInformation;
