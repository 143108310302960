import type { RxDatabase } from 'rxdb'

import { Modifier, MODIFIER_COLLECTION_NAME, modifierSchema } from './Modifier'
import { createCollection } from './utils/migration'

export const createModifierCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Modifier,
    collectionName: MODIFIER_COLLECTION_NAME,
    version: modifierSchema.version,
    schema: modifierSchema,
    extraStrategy: {
      17: doc => {
        if (doc.product) doc.products = [doc.product]
        return doc
      },
    },
  })
}
