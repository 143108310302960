import { deleteMany } from "@/data/data-utils";
import { DATA_INITED_2 } from './data-const'
import { Order, PaidOrder } from "@/data/Order";
import { InvoiceTypes, type MasterHandler, OrderStatus, type OrderStrip } from "@/pos/OrderType";
import { stripOrder, stripPaidOrder } from "@/pos/logic/order-reactive.ts";
import { getMaxIdZ } from "@/data/MaxIdHub.ts";
import dayjs from "dayjs";
import { OnlineOrder } from "@/data/OnlineOrder.ts";
import { Eod } from "@/data/Eod.ts";
import { EodCache } from "@/data/EodCache.ts";
import { MaxId } from "@/data/MaxId.ts";
import { TxLogCollection } from "@/data/TxLog.ts";
import { TxRefundLogCollection } from "@/data/TxRefundLog.ts";
import { TxVoidLogCollection } from "@/data/TxVoidLog.ts";
import { TseTransaction } from "@/data/TseTransaction.ts";
import { OrderTseTemp } from "@/data/OrderTseTemp.ts";
import { PrintImage } from "@/data/PrintImage.ts";
import { printInvoice } from "@/react/Printer/print-invoice.ts";
import { rePrintInvoiceTse } from "@/tse/tse-init.ts";
import MultiAwaitLock from "@/shared/MultiAwaitLock.ts";
import _ from "lodash";
import { printKitchen } from "@/react/Printer/print-kitchen.ts";
import { now } from "@/pos/logic/time-provider.ts";
import { paymentHook } from "@/react/utils/hooks.ts";
import { loginUsers } from '@/data/UserSignal.computed'
import { SrmTransactionLog } from "@/data/SrmTransactionLog.ts";
import { Voucher } from "@/data/Voucher.ts";
import { CashbookHistories, CashbookTransactions } from "@/data/Cashbook.ts";
import { getVDate } from "@/pos/orderUtils.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { OrderHandler } from "@/data/OrderHandler.ts";
import { kitchenFLow } from "@/shared/logger.ts";
import { ReservationCollection } from "@/data/Reservation.ts";
import { InventoryAction } from "@/data/InventoryAction.ts";
import { Call, MissedCall } from "@/data/Call.ts";
import { SrmEventLog } from "@/data/SrmEventLog.ts";
import { SrmDocumentLog } from "@/data/SrmDocumentLog.ts";
import { ZvtLog } from "@/data/ZvtLog.ts";
import { TimeClock } from "@/data/TimeClock.ts";
import { OrderCommits } from "@/data/OrderCommits.ts";
import { PrintScripts } from "@/data/PrintScripts.ts";
import { UserActionHistory } from "@/data/UserActionHistory.ts";
import { MasterAction } from "@/data/MasterAction.ts";
import { TableStaffStatus } from "@/data/TableStaffStatus.ts";

/**
 * use by order history
 */

export async function removeOrder(order: Order) {
  const _order = await Order.findOne({ selector: { _id: order._id } }).exec();
  await _order?.remove();
}

/**
 * Clone, cleanup and add to PaidOrder. Also recorded the order's commits
 */
export async function updateOrder(order: Order, getOnlyZ: boolean = false) {
  // await recordOrderCommits(order)
  //todo: upsert new order to PaidOrder Collection
  const stripOrder0 = stripPaidOrder(order);
  await removeOrder(order);
  // await Order.upsert(stripOrder0);
  //get maxId
  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!), getOnlyZ);
  if (!getOnlyZ) stripOrder0.id = id;
  stripOrder0.z = z;
  await PaidOrder.upsert(stripOrder0);
  return stripOrder0;
  // await OrderZ.upsert({ _id: order._id, z: -1, vDate: order.vDate });
}

export async function assignZ(order: OrderStrip) {
  //todo: upsert new order to PaidOrder Collection
  const { z } = await getMaxIdZ(dayjs.unix(order.vDate!), true);
  order.z = z;
  // await OrderZ.upsert({ _id: order._id, z: -1, vDate: order.vDate });
}

export async function clearAllOrder(needReload: boolean = true) {
  await deleteMany(PaidOrder, {});
  await deleteMany(Order, {});
  await deleteMany(OnlineOrder, {});
  await deleteMany(Eod, {});
  await deleteMany(EodCache, {});
  await deleteMany(MaxId, {});
  await deleteMany(TxLogCollection, {});
  await deleteMany(TxRefundLogCollection, {});
  await deleteMany(TxVoidLogCollection, {});
  await deleteMany(TseTransaction, {});
  await deleteMany(OrderTseTemp, {});
  await deleteMany(PrintImage, {});
  await deleteMany(Voucher, {});
  await deleteMany(SrmTransactionLog, {});
  await deleteMany(CashbookTransactions, {});
  await deleteMany(CashbookHistories, {});
  //add new
  await deleteMany(ReservationCollection, {});
  await deleteMany(InventoryAction, {});
  await deleteMany(Call, {});
  await deleteMany(MissedCall, {});
  await deleteMany(SrmEventLog, {});
  await deleteMany(SrmDocumentLog, {});
  await deleteMany(ZvtLog, {});
  await deleteMany(TimeClock, {});
  await deleteMany(OrderCommits, {});
  await deleteMany(TableStaffStatus, {});
  await deleteMany(OrderHandler, {});
  await deleteMany(UserActionHistory, {});
  await deleteMany(MasterAction, {});
  await deleteMany(PrintScripts, {});


  // await deleteMany(TseConfig, {});
  localStorage.removeItem(DATA_INITED_2);
  if (needReload) location.reload();
}

//@ts-expect-error debug only
window.clearAllOrder = clearAllOrder;

export async function rePrintInvoice(order: Order, type: InvoiceTypes = InvoiceTypes.INVOICE) {
  //todo: print
  await printInvoice(order, type);
  await rePrintInvoiceTse(order);
}

interface OrderLocks {
  [k: string]: MultiAwaitLock
}

export async function onKitchenPrint(order: Order, postProcess: boolean = true, printed = true) {
  kitchenFLow(`onKitchenPrint after send command to master ${order._id}`, {orderId: order._id});
  printKitchen(order, printed).catch(e => console.error('Failed to print kitchen from master', e));
  if (postProcess) {
    await paymentHook.emit('postKitchenPrint', order);
  }
}

export async function updateInProgressOrder(order: Order) {
  const stripOrder0 = stripOrder(order);
  await Order.upsert(stripOrder0);
}

export const lockCondition: { v: () => boolean } = { v: () => false };

export async function runMasterHandler(order: Order) {
  order.modifiedBy = loginUser()?.name
  const stripOrder0 = stripOrder(order);
  await Order.upsert(stripOrder0);
}

type OrderType = typeof PaidOrder | typeof Order | typeof OnlineOrder;

export function runMasterHandlerPaidOrderFactory(order: Order) {
  let stripOrder0: OrderStrip;
  let Collection: OrderType = PaidOrder;

  return {
    assignInfoToOrder,
    _removeOrder,
    upsertToPaidOrder,
    runFull,
    assignUserToOrder,
    runOnlineOrder
  }

  function useCollection(col: OrderType) {
    Collection = col;
  }

  // use for kitchen print
  async function runOnlineOrder(col: OrderType = PaidOrder) {
    useCollection(col);
    await assignUserToOrder();
    await upsertToPaidOrder();
  }

  async function runFull(getOnlyZ: boolean = false, col: OrderType = PaidOrder) {
    useCollection(col);
    await assignInfoToOrder(getOnlyZ);
    await upsertToPaidOrder();
    await _removeOrder();
  }

  async function assignUserToOrder() {
    order.modifiedBy = loginUser()?.name
  }

  async function assignInfoToOrder(getOnlyZ: boolean = false) {
    // Save current user to order, before forward to master. TODO: redesign this schema
    order.modifiedBy = loginUser()?.name
    order.users = loginUsers();

    order.date = dayjs(now()).unix();
    order.vDate = getVDate(order.date);
    stripOrder0 = stripPaidOrder(order);

    const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!), getOnlyZ);
    if (!getOnlyZ && !stripOrder0.id) {
      console.log('assign id to order', stripOrder0.id = id);
    }
    stripOrder0.z = z;
    const eod = await Eod.findOne({ selector: { z: z } }).exec();
    stripOrder0.eod = eod?._id;
  }

  async function _removeOrder() {
    await removeOrder(order);
  }

  async function upsertToPaidOrder() {
    await Collection.upsert({
      ...stripOrder0,
      // Since we inserting to Paid Order, convert "In Progress", status to "Cancelled"
      status: stripOrder0.status === OrderStatus.IN_PROGRESS ? OrderStatus.CANCELLED_BEFORE_PAID : stripOrder0.status,
    })
  }
}

export async function runMasterHandlerPaidOrder(order: Order) {
  // Save current user to order, before forward to master. TODO: redesign this schema
  order.modifiedBy = loginUser()?.name

  order.date = dayjs(now()).unix();
  order.vDate = getVDate(order.date);
  
  // await recordOrderCommits(order)
  const stripOrder0 = stripPaidOrder(order);
  await removeOrder(order);
  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!));
  stripOrder0.id = id;
  stripOrder0.z = z;
  const eod = await Eod.findOne({ selector: { z: z } }).exec();
  stripOrder0.eod = eod?._id;

  stripOrder0.masterHandlers = _.defaults(stripOrder0.masterHandlers, { cmd: [], cmdUuids: [], status: 'completed' });

  await PaidOrder.upsert(stripOrder0);
}

export async function runMasterHandlerAfterPaid(order: Order) {
  order.modifiedBy = loginUser()?.name
  await PaidOrder.upsert(stripPaidOrder(order));
}
