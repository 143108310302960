import { type FunctionComponent, useState } from 'react';
import QuickSelectPrintersPopup from './QuickSelectPrintersPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PaymentSelectTerminalPopu from './PaymentSelectTerminalPopu';
import DeveloperOnlyVerifyPopu from './DeveloperOnlyVerifyPopu';
import DataExportPopup from './DataExportPopup';
import DashboardPopupTabButton from './DashboardPopupTabButton';
import { LL0 } from '@/react/core/I18nService.tsx';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts';
import { multiplePositionGroupPrinters } from '@/data/GroupPrinterHub.ts';
import { tseConfig0 } from '@/data/TseConfigHub';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type DashboardSettingPopupType = {
  className?: string;
  onClose?: () => void;
};

export const DEVELOPER_PASSWORD = "886"
export const INSTALLATION_PASSWORD = "999"

const DashboardSettingPopup: FunctionComponent<DashboardSettingPopupType> = ({
  className = "",
  onClose,
}) => {
  const LL = LL0()
  const onClickTab = (screen: PosScreen) => {
    userFLow(`go to screen ${screen}`, {
      username: loginUser()?.name
    });
    router.screen = screen;
    onClose?.();
  }

  const [isQuickSelectPrintersPopupOpen, setQuickSelectPrintersPopupOpen] =
    useState(false);
  const [isPaymentSelectTerminalPopuOpen, setPaymentSelectTerminalPopuOpen] =
    useState(false);
  const [isDeveloperOnlyVerifyPopuOpen, setDeveloperOnlyVerifyPopuOpen] =
    useState(false);
  const [isInstallationVerifyPopuOpen, setInstallationVerifyPopuOpen] =
    useState(false);
  const [isDataExportPopupOpen, setDataExportPopupOpen] = useState(false);

  const closeDataExportPopup = () => {
    userFLow(`close data export popup`, {
      username: loginUser()?.name
    });
    setDataExportPopupOpen(false);
  }

  const closeQuickSelectPrintersPopup = () => {
    userFLow(`close quick select printers popup`, {
      username: loginUser()?.name
    });
    setQuickSelectPrintersPopupOpen(false);
  }

  const closePaymentSelectTerminalPopup = () => {
    userFLow(`close payment select terminal popup`, {
      username: loginUser()?.name
    });
    setPaymentSelectTerminalPopuOpen(false);
  }
  return (
    <>
      <div className="dashboard-setting-popup h-full relative" data-testid='dashboard-setting-popup'>
        <div className="no-scrollbar relative rounded-md [background:linear-gradient(180deg,_rgba(247,_247,_247,_0.42),_rgba(170,_169,_169,_0.42))] [backdrop-filter:blur(100px)] w-full flex flex-col items-center justify-start py-9 px-8 box-border gap-[20px] min-w-[496px] h-full max-w-full max-h-full overflow-auto text-left text-mini text-white-solid-white-100-ffffff font-mulish Tablet_768:pl-6 Tablet_768:pr-6 Tablet_768:pb-6 Tablet_768:box-border Tablet_600:pl-6 Tablet_600:pr-6 Tablet_600:pb-6 Tablet_600:box-border">
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[0]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <b className="self-stretch relative sm:text-sm Tablet_600:text-smi">
                {LL.dashboard.editing()}
              </b>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] Tablet_768:gap-[8px] Tablet_600:gap-[8px]">
                {getAccessibility(PosScreen.EDIT_MENU) &&
                  <DashboardPopupTabButton
                    iconImageUrl="/iconedit-menu-icon@2x.png"
                    buttonText={LL.dashboard.editMenu()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onClickTab(PosScreen.EDIT_MENU)}
                  />
                }
                {getAccessibility(PosScreen.EDIT_FLOOR_PLAN) &&
                  <DashboardPopupTabButton
                    iconImageUrl="/icon-floor-plan-icon2@2x.png"
                    buttonText={LL.restaurant.floorPlan()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onClickTab(PosScreen.EDIT_FLOOR_PLAN)}
                  />
                }
                {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) &&
                  <DashboardPopupTabButton
                    iconImageUrl="/icon-users-settings-icon13@2x.png"
                    buttonText={LL.dashboard.users()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onClickTab(PosScreen.EDIT_USER)}
                  />}
                {getAccessibility(PosScreen.HAPPY_HOUR) &&
                  <DashboardPopupTabButton
                    iconImageUrl="/icon-happy-hours-settings-icon4@2x.png"
                    buttonText={LL.restaurant.product.happyHour()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onClickTab(PosScreen.HAPPY_HOUR)}
                  />
                }
                <DashboardPopupTabButton
                  iconImageUrl="/icon-create-vouccher@2x.png"
                  buttonText={LL0().restaurant.createVoucher()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.VOUCHER)}
                />
              </div>
            </div>
            {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) &&
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <b className="self-stretch relative">{LL.dashboard.settingForThisDevice()}:</b>
                <div className="self-stretch overflow-y-auto flex flex-row flex-wrap items-start justify-start py-0.5 px-0 gap-[12px]">
                  {!!multiplePositionGroupPrinters().length &&
                    <DashboardPopupTabButton
                      iconImageUrl="/iconquick-select-printer@2x.png"
                      buttonText={LL.dashboard.selectPrinter()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => setQuickSelectPrintersPopupOpen(true)}
                    />
                  }
                  <DashboardPopupTabButton
                    iconImageUrl="/iconterminal-setting-icon@2x.png"
                    buttonText={LL.dashboard.terminalSetup()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => setPaymentSelectTerminalPopuOpen(true)}
                  />
                </div>
              </div>
            }
          </div>
          {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) &&
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <b className="self-stretch relative sm:text-sm Tablet_600:text-smi">
                {LL.dashboard.settings()}
              </b>
              <div className="self-stretch overflow-y-auto flex flex-row flex-wrap items-start justify-start py-0.5 px-0 gap-[12px] text-center text-base text-black-solid-black-600-424242 Tablet_600:gap-[12px_8px]">
                <DashboardPopupTabButton
                  iconImageUrl="/icongeneral-settings-icon34@2x.png"
                  buttonText={LL.dashboard.generalSetting()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.GENERAL_SETTING)}
                />
                <DashboardPopupTabButton
                  iconImageUrl="/icongeneral-settings-company-info.png"
                  buttonText={LL.dashboard.companyInfo()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.COMPANY_INFORMATION)}
                />
                <DashboardPopupTabButton
                  iconImageUrl="/iconpayment-setting-icon@2x.png"
                  buttonText={LL.dashboard.taxAndPayment()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.PAYMENT_SETTING)}
                />
                {/*<DashboardPopupTabButton*/}
                {/*  iconImageUrl="/iconemail-report-icon@2x.png"*/}
                {/*  buttonText={LL.dashboard.emailReport()}*/}
                {/*  noticeNumber="20"*/}
                {/*  showChip={false}*/}
                {/*  showInfoIcon={false}*/}
                {/*  onClick={() => onClickTab(PosScreen.EMAIL_REPORT)}*/}
                {/*/>*/}
                <DashboardPopupTabButton
                  iconImageUrl="/icongeneral-settings-icon10@2x.png"
                  buttonText={LL.productViewSetting.modifierEditorDialog.printer()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.PRINTER_SETTING)}
                />

                <DashboardPopupTabButton
                  iconImageUrl="/iconmaster-machine-icon@2x.png"
                  buttonText={LL.dashboard.masterMachine()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.MASTER_MACHINE)}
                />
                {/*<DashboardPopupTabButton*/}
                {/*  iconImageUrl="/icongeneral-settings-icon12@2x.png"*/}
                {/*  buttonText="Monitor"*/}
                {/*  noticeNumber="20"*/}
                {/*  showChip={false}*/}
                {/*  showInfoIcon={false}*/}
                {/*/>*/}
                <DashboardPopupTabButton
                  iconImageUrl="/icondeveloper-icon@2x.png"
                  buttonText={LL.dashboard.developerOnly()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  data-testid='dashboardSettingPopup.developerOnly'
                  onClick={() => {
                    if (import.meta.env.MODE === 'production') {
                      setDeveloperOnlyVerifyPopuOpen(true)
                    } else {
                      onClose?.();
                      router.screen = PosScreen.DEVELOPER;
                    }
                  }}
                />
                <DashboardPopupTabButton
                  iconImageUrl="/icon-install-icon13@2x.png"
                  buttonText={LL0().settings.userSettings.installation()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  data-testid='dashboardSettingPopup.installation'
                  onClick={() => {
                    if (import.meta.env.MODE === 'production') {
                      setInstallationVerifyPopuOpen(true)
                    } else {
                      onClose?.();
                      router.screen = PosScreen.INSTALLATION
                    }
                  }}
                />
                {tseConfig0()?.tseEnable && (<DashboardPopupTabButton
                  iconImageUrl="/icondata-export-icon@2x.png"
                  buttonText={LL.dashboard.dataExport()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => setDataExportPopupOpen(true)}
                />)}
                <DashboardPopupTabButton
                  iconImageUrl="/iconsetting-icon@2x.png"
                  buttonText={LL.onlineOrder.setting()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.ONLINE_ORDER_SETTING)}
                />
                {/*<DashboardPopupTabButton*/}
                {/*  iconImageUrl="/iconsync-online-menu-icon@2x.png"*/}
                {/*  buttonText={LL.dashboard.syncOnlineMenu()}*/}
                {/*  noticeNumber="20"*/}
                {/*  showChip={false}*/}
                {/*  showInfoIcon={false}*/}
                {/*/>*/}
                <DashboardPopupTabButton
                  iconImageUrl="/icondashboard-terminal-icon@2x.png"
                  buttonText={LL.payment.terminal()}
                  noticeNumber="20"
                  showChip={false}
                  showInfoIcon={false}
                  onClick={() => onClickTab(PosScreen.TERMINAL)}
                />
              </div>
            </div>
          }
        </div>
        <img
          className="w-[25px] absolute !m-[0] top-[7px] right-[7px] h-[25.4px] cursor-pointer z-[1]"
          alt=""
          src="/close-tab.svg"
          onClick={onClose}
        />
      </div>
      {isDeveloperOnlyVerifyPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setDeveloperOnlyVerifyPopuOpen(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setDeveloperOnlyVerifyPopuOpen(false)}
            password={DEVELOPER_PASSWORD}
            onComplete={() => {
              onClose?.();
              router.screen = PosScreen.DEVELOPER;
            }}
          />
        </PortalPopup>
      }
      {isInstallationVerifyPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setDeveloperOnlyVerifyPopuOpen(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setInstallationVerifyPopuOpen(false)}
            password={INSTALLATION_PASSWORD}
            onComplete={() => {
              onClose?.();
              router.screen = PosScreen.INSTALLATION;
            }}
          />
        </PortalPopup>
      }
      {isDataExportPopupOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={closeDataExportPopup}>
          <DataExportPopup onClose={closeDataExportPopup} />
        </PortalPopup>
      }
      {isQuickSelectPrintersPopupOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={closeQuickSelectPrintersPopup}>
          <QuickSelectPrintersPopup onClose={closeQuickSelectPrintersPopup} />
        </PortalPopup>
      }
      {isPaymentSelectTerminalPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={closePaymentSelectTerminalPopup}>
          <PaymentSelectTerminalPopu onClose={closePaymentSelectTerminalPopup} />
        </PortalPopup>
      }
    </>
  );
};

export default DashboardSettingPopup