import { type FunctionComponent, useCallback, useEffect, useState } from "react";
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import TextField from "@/react/core/TextField.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import { createNewInventory, inventory0, updateInventoryStock } from "@/react/InventoryView/InventoryView.tsx";
import _ from "lodash";
import { LL0 } from "@/react/core/I18nService.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { convertDocument } from "@/data/data-utils.ts";
import { materialCategories } from "@/data/CategoryHub.ts";
import { product0 } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { makeInventoriesAvailable } from "@/data/InventoryHub.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type EditMenuAddIngredientPType = {
  className?: string;
  onClose?: () => void;
};

const EditMenuAddIngredientP: FunctionComponent<EditMenuAddIngredientPType> = ({
  className = "",
  onClose,
}) => {
  makeInventoriesAvailable()
  const [isConfirmApplyVoucherPopupOpen, setConfirmApplyVoucherPopupOpen] =
    useState(false);

  const openConfirmApplyVoucherPopup = useCallback(() => {
    setConfirmApplyVoucherPopupOpen(true);
  }, []);

  const closeConfirmApplyVoucherPopup = useCallback(() => {
    setConfirmApplyVoucherPopupOpen(false);
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );
    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const [stock, setStock] = useSignal<number>(0);

  const handleEditItemSave = async () => {
    onClose?.();
    const _inventory = inventory0();
    if (!_inventory) return;
    const newInventory = await createNewInventory(_inventory);
    _.assign(product0(), {ingredients: [...(product0().ingredients || []), {
        inventoryId: _inventory._id,
        amount: '0'
      }]})
    updateInventoryStock(convertDocument(newInventory, true, ["product", "lastUpdated"]),
      stock(), `${LL0().inventory.initStockBy()} ${loginUser()?.name}`)

  }

  return (
    <>
      <div
        className={`!opacity-100 w-full h-[640px] relative rounded-t-3xs rounded-b-none bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-start gap-3 [&.animate]:animate-[0.25s_ease_0s_1_normal_forwards_slide-in-bottom] opacity-[0] max-w-full max-h-full text-left text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
        data-animate-on-scroll
      >
        <div className="self-stretch bg-white-solid-white-100-ffffff border-gray-solid-gray-164-e1e1e1 border-b-[1px] border-solid box-border h-11 flex flex-row items-center justify-end py-2 px-3 gap-2 text-base">
          <b className="flex-1 relative overflow-hidden text-ellipsis whitespace-nowrap">{LL0().product.newIngredient()}</b>
          <img
            className="w-5 relative h-5 object-cover"
            alt=""
            src="/iconclose-popup-icon2@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-2 pb-[78px] gap-4">
          <div className="self-stretch flex flex-col items-start justify-center gap-1">
            <div className="relative font-medium">{LL0().article.name()}</div>
            <Input
              className="self-stretch h-[37px]"
              value={inventory0()?.product?.name?.trim() || ""}
              onChange={(v) => _.assign(inventory0()?.product, { name: v })}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-1">
            <div className="relative font-medium">
              <span>{LL0().article.category()}</span>
              <span className="text-crimson">*</span>
            </div>
            <div className="self-stretch relative h-9">
              <FormControl
                className="font-mulish font-medium text-sm text-black-solid-black-1000-000000"
                variant="outlined"
                sx={{
                  borderRadius: "0px 0px 0px 0px",
                  width: "100%",
                  height: "36px",
                }}
              >
                <InputLabel color="primary" />
                <Select color="primary" size="small"
                        multiple
                        value={inventory0()?.product?.categories || []}
                        onChange={e => _.assign(inventory0()?.product, { categories: e.target.value })}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, paddingLeft: 2 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={materialCategories().find(category => category._id === value)?.name} />
                            ))}
                          </Box>
                        )}
                >
                  {materialCategories().map(({ _id, name }) =>
                    <MenuItem value={_id} key={_id}>{name}</MenuItem>
                  )}
                </Select>
                <FormHelperText />
              </FormControl>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-4">
            <div className="flex-1 flex flex-col items-start justify-center gap-1">
              <div className="relative font-medium">{LL0().inventory.stock()}</div>
              <Input
                className="self-stretch font-mulish font-medium text-sm text-black-solid-black-1000-000000"
                value={inventory0()?.stock.toString() || '0'}
                onChange={(v) => {
                  setStock(parseFloat(v.replace(",", ".") || "0"))
                }}
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-1">
              <div className="relative font-medium">{LL0().article.unit()}</div>
              <div className="self-stretch relative h-9">
                <FormControl
                  className="absolute w-full top-[0px] right-[0px] left-[0px]"
                  variant="outlined"

                >
                  <InputLabel color="primary" />
                  <Select color="primary" size="small"
                          value={inventory0()?.unit || "piece"}
                          onChange={e => _.assign(inventory0(), { unit: e.target.value })}
                  >
                    <MenuItem value="piece">{LL0().inventory.units.piece()} (pc)</MenuItem>
                    <MenuItem value="g">{LL0().inventory.units.g()}</MenuItem>
                    <MenuItem value="kg">{LL0().inventory.units.kg()}</MenuItem>
                    <MenuItem value="ml">{LL0().inventory.units.ml()}</MenuItem>
                    <MenuItem value="l">{LL0().inventory.units.l()}</MenuItem>
                  </Select>
                  <FormHelperText />
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-center text-center text-white-solid-white-100-ffffff">
          <div className="self-stretch flex flex-col items-start justify-start pt-1 px-3 pb-4">
            <div
              className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center py-1 px-8 box-border cursor-pointer z-[0]"
              onClick={handleEditItemSave}
            >
              <b className="relative">{LL0().ui.save()}</b>
            </div>
          </div>
        </div>
        <TableManualKeyboard
          value=''
          inputController={inputController0}
          onEnter={handleEditItemSave}
        />
      </div>

    </>
  );
};

export default EditMenuAddIngredientP;