import dayjs from 'dayjs'
import { json2csv } from 'json-2-csv'
import _ from 'lodash'
import { toast } from 'react-toastify'
import type { RxDocument } from 'rxdb'
import uuid from 'time-uuid'

import { deviceSetting0 } from '@/data/DeviceSettingSignal.ts'
import { posSetting0 } from '@/data/PosSettingsSignal'
import { PrintScripts } from '@/data/PrintScripts'
import { SrmDocumentLog } from '@/data/SrmDocumentLog'
import { SrmEventLog, SrmEvents } from '@/data/SrmEventLog'
import { SrmTransactionLog } from '@/data/SrmTransactionLog'
import { UserRole } from '@/data/User'
import { UserActionHistory } from '@/data/UserActionHistory.ts'
import { isValidSrmUser } from '@/data/UserSignal.computed'
import { loginUser } from '@/data/UserSignal.ts'
import { LL0 } from '@/react/core/I18nService'
import { signal } from '@/react/core/reactive'
import msgBox, { Buttons, Icons } from '@/react/SystemService/msgBox'
import { virtualPrinterLogic } from '@/react/VirtualPrinterView/VitualPrinterLogic'
import { bound, handleError, progressDialog, progressToast } from '@/shared/decorators'
import { sendEmail } from '@/shared/EmailSender'
import { uploadFile } from '@/shared/FileUploader'
import { generateUserActionMessages } from '@/shared/user-action-share.ts'
import { downloadFile } from '@/shared/utils'
import { zipTextToBlob } from '@/shared/zipUtils'

import { TESTCASE_VARS } from './testcase/constants'

/** True if exported data succeeded */
export const [isBackupSucceeded, setIsBackupSucceeded] = signal(false)

/** True if exported data succeeded within an hour ago */
// export const isBackupSucceeded = computed(() => {
//   const { deviceId } = deviceSetting0()?.srm ?? {}
//   const oneHourAgo = dayjs().subtract(1, 'hour').unix()
//   return srmEventLog0().some(e => e.deviceId === deviceId && e.type === SrmEvents.EXPORT_DATA && e.date >= oneHourAgo)
// })

const flattenDataUserActions = (row: Record<string, unknown>) => {
  const flattened: Record<string, unknown> = {}
  const clonedRow = { ...row }
  if (clonedRow.transNo) {
    clonedRow.transNo = `#${clonedRow.transNo.toString()}`
  }
  Object.entries(clonedRow).forEach(([key, value]) => {
    if (key === 'data' && value) {
      Object.entries(value).forEach(([subKey, subValue]) => {
        flattened[subKey] = subValue
      })
    } else {
      flattened[key] = value
    }
  })
  return flattened
}

async function prepareDataToExport() {
  const transactions = await SrmTransactionLog.find({ sort: [{ date: 'desc' }] })
    .exec()
    .then(data =>
      data
        .map(item => item.toJSON())
        .map(row => ({
          orderId: row.ref,
          date: row.date ? dayjs.unix(row.date).format() : undefined,
          ..._.omit(row, ['updateAt', 'ref', 'date']),
        }))
    )
  const documents = await SrmDocumentLog.find({ sort: [{ date: 'desc' }] })
    .exec()
    .then(data =>
      data
        .map(item => item.toJSON())
        .map(row => ({
          date: row.date ? dayjs.unix(row.date).format() : undefined,
          ..._.omit(row, ['updateAt', 'date']),
        }))
    )
  const events = await SrmEventLog.find({ sort: [{ date: 'desc' }] })
    .exec()
    .then(data =>
      data
        .map(item => item.toJSON())
        .map(row => ({
          date: row.date ? dayjs.unix(row.date).format() : undefined,
          ..._.omit(row, ['updateAt', 'date']),
        }))
    )
  const userActions = await UserActionHistory.find({ sort: [{ date: 'asc' }] })
    .exec()
    .then(data =>
      data.map(item => {
        const row = item.toJSON()
        return {
          transNo: row.transNo,
          message: generateUserActionMessages([row])[0],
          date: row.date ? dayjs.unix(row.date).format() : undefined,
          ..._.omit(row, ['updatedAt', 'date', 'updatedOn', '_id']),
        }
      })
    )

  const userActionsForCsv = userActions.map(row => {
    return flattenDataUserActions(row)
  })

  const blob = await zipTextToBlob(
    ['data.json', JSON.stringify({ transactions, documents, events, userActions }, null, 4)],
    ['transactions.csv', json2csv(transactions, { emptyFieldValue: '' })],
    ['documents.csv', json2csv(documents, { emptyFieldValue: '' })],
    ['events.csv', json2csv(events, { emptyFieldValue: '' })],
    ['user_actions.csv', json2csv(userActionsForCsv, { emptyFieldValue: '' })]
  )
  return blob
}

interface Options {
  /** Change the user that making transaction. If not specified, default to current login user */
  impersonate?: string
}

export class SrmLogic {
  constructor(public options: Options = {}) {}

  @progressDialog(() => ({ title: `Exporting data...` }))
  @handleError()
  @bound()
  async exportData(email: string) {
    if (!email) return console.warn('No email to export!')

    console.log('💾 Exporting data to', email)
    const blob = await prepareDataToExport()
    const blobUrl = await uploadFile('srmReport', blob, progress => console.log('progress', progress))
    console.log('💾 Data uploaded', blobUrl)
    await sendEmail(email, `Exported data ${dayjs().format()}`, `You can download the file and unzip ${blobUrl}`)
    await this.recordSrmEvent(SrmEvents.EXPORT_DATA)
    setIsBackupSucceeded(true)
  }

  @handleError()
  @bound()
  async clearTransactionData() {
    if (!isBackupSucceeded()) throw new Error(LL0().srm.errors.backupRequired())
    if (!isValidSrmUser() || ![UserRole.MANAGER, UserRole.ADMIN].includes(loginUser()?.role ?? UserRole.STAFF)) throw new Error('You are not authorized to do this!')

    // Remove only sent transaction
    await SrmTransactionLog.find({ selector: { sent: true } }).remove()
    await this.recordSrmEvent(SrmEvents.WIPE_DATA)
    setIsBackupSucceeded(false)
  }

  async showTransactionError(t: SrmTransactionLog) {
    if (!t.response?.listErr?.length) return
    console.log(`ℹ️ #${t.data.noTrans}:`, t.data)
    const errMsg = LL0().srm.errors.transactionFailed({
      count: t.response.listErr.length,
      detail: t.response.listErr.map(({ id, codRetour, mess }) => `[${[codRetour, id].filter(Boolean).join('-')}] ${mess}`).join('\n\n'),
    })
    msgBox.show(LL0().ui.error(), errMsg, Buttons.Dismiss, Icons.Error)
  }

  async showTransactionDetails(t: SrmTransactionLog) {
    console.log(`ℹ️ #${t.data.noTrans}:`, t.data)
    const msg = JSON.stringify(t.data, null, 2)
    msgBox.show(LL0().srm.caption.transactionDetails({ no: t.data.noTrans }), msg, Buttons.Dismiss, Icons.Information)
  }

  async displayPrintedTransaction(t: SrmTransactionLog) {
    const row = await PrintScripts.findOne({ selector: { 'metadata.srm_logRecordId': t._id } }).exec()
    const imgUrl = row ? await virtualPrinterLogic.renderImageFromScripts(row.scripts) : undefined
    const msg = `Printed bill${imgUrl ? ':' : ' <NOT_FOUND>!'}`
    msgBox.show(LL0().srm.caption.transactionDetails({ no: t.data.noTrans }), msg, Buttons.Dismiss, Icons.Information, imgUrl)
  }

  @progressToast(() => 'Recording SRM event...')
  @handleError()
  @bound()
  /**
   * Record an event in SRM event log
   *
   * @param type Type of event
   * @throws If device ID is not set in device setting
   * @throws If user is not logged in
   * @returns The inserted event log
   */
  async recordSrmEvent(type: SrmEvents): Promise<RxDocument<SrmEventLog> | undefined> {
    const { deviceId } = deviceSetting0()?.srm ?? {}
    const user = this.options.impersonate ?? loginUser()?.name
    if (!deviceId) throw new Error(LL0().srm.errors.missingDeviceId())
    if (!user) throw new Error(LL0().srm.errors.loginRequired())

    return await SrmEventLog.insert({
      _id: uuid(),
      deviceId,
      date: dayjs(Date.now()).unix(),
      type,
      user,
    })
  }

  @bound()
  async setSrmTrainingMode(isOn: boolean) {
    await deviceSetting0()?.doc?.incrementalUpdate({ $set: { 'srm.trainingMode': isOn } })
    await this.recordSrmEvent(isOn ? SrmEvents.TRAINING_MODE_ON : SrmEvents.TRAINING_MODE_OFF)
  }

  @bound()
  async resetSrmSettingsToDefault(): Promise<void> {
    const doc = posSetting0()?.doc
    if (!doc) throw new Error('Fail to setup Pos Settings: DB not found!')

    // Update setting for tests
    await doc.incrementalUpdate({
      $set: {
        'srm.qstNumber': TESTCASE_VARS.qstNumber,
        'srm.gstNumber': TESTCASE_VARS.gstNumber,
        'srm.authCode': TESTCASE_VARS.authCode,
        'srm.certificateCode': TESTCASE_VARS.certificateCode,
        'srm.identificationNumber': TESTCASE_VARS.identificationNumber,
        'srm.billingNumber': TESTCASE_VARS.billingNumber,
        'srm.timezone': TESTCASE_VARS.timezone,
        'srm.env': 'DEV',
      },
    })
    toast.success('Srm settings reset to default!')
  }
}

export const srmLogic = new SrmLogic()

Object.assign(window, {
  async dev_exportData() {
    const blob = await prepareDataToExport()
    const file = new File([blob], 'data.zip', { type: 'application/zip' })
    console.log('💾 Exported data', file)
    downloadFile(file)
  },
})
