import { memo, useState } from 'react';
import PrinterPrintOrderTicketAcc from '@printer-setting/PrinterPrintOrderTicketAcc';
import PrinterLeftPanelItem from '@printer-setting/PrinterLeftPanelItem';
import PrinterSettingRight from '@printer-setting/PrinterSettingRight';
import {
  onBack,
  PrinterMode,
  printerSettingMode, setCurrentPrinterIndex,
  setPrinterSettingMode,
  setSelectedGroupPrinterId
} from '@/react/Printer/PrinterSettingView.tsx';
import { invoiceGroupPrinters0, labelPrinters0 } from '@/data/GroupPrinterHub.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import DeleteConfirmationPopup from '@printer-setting/DeleteConfirmationPopup.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { recheckGroupPrinters0 } from '@/data/GroupPrinterHub.ts';
import {
  createNewGroupPrinter,
  deleteCurrentGroupPrinter,
  isSelectedGroupPrinter
} from '@/react/Printer/PrinterSettingView.tsx';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const RecheckPrinterAccordion = () => {
  const [isAccordionOpened, setIsAccordionOpened] = useState<boolean>(true);
  const [isDeleteConfirmationPopup1Open, setIsDeleteConfirmationPopup1Open] = useState<boolean>(false);
  const closeDeleteConfirmationPopup1 = () => {
    userFLow(`close delete confirmation popup`, {
      username: loginUser()?.name
    });
    setIsDeleteConfirmationPopup1Open(false)
  }

  return (
    <>
      <div className="printer-setting-screen self-stretch flex flex-col items-start justify-start gap-[8px]">
        <div className="!text-dodgerblue h-10 self-stretch flex flex-row items-center justify-between bg-whitesmoke px-2 relative"
             onPointerDown={() => setIsAccordionOpened(prev => !prev)}
        >
          <div className="flex flex-row items-center justify-start gap-[12px] z-[1]">

              <img className="w-5 relative h-5 overflow-hidden shrink-0 object-cover"
                alt=""
                src="/virtual-printer-icon@2x.png"
              />
            <b className="relative">{LL0().printerSetting.recheckPrinter()}</b>
            <b className="w-[105px] relative hidden text-dodgerblue">
              {LL0().printerSetting.recheckPrinter()}
            </b>
          </div>
          {isAccordionOpened ?
            <img className="w-5 relative h-5 object-cover z-[2]"
              alt=""
              src="/iconaccordion-arow-down@2x.png"
            />
            :
            <img
              className="!flex relative w-5 h-5 object-cover hidden z-[3]"
              alt=""
              src="/iconaccordion-arow-right@2x.png"
            />
          }
        </div>
        {isAccordionOpened && <div className="!flex self-stretch bg-whitesmoke-200 hidden flex-col items-center justify-center gap-[4px] text-darkslategray">
          {recheckGroupPrinters0().map((g) => {
            const isSelected = isSelectedGroupPrinter(g) && printerSettingMode() === PrinterMode.RECHECK_PRINTER

            return (
              <div className={clsx("self-stretch h-10 flex flex-row items-center justify-start py-0 px-2 box-border relative gap-[7px] text-smi",
                isSelected && "text-white")}
                   onPointerDown={() => {
                     setPrinterSettingMode(PrinterMode.RECHECK_PRINTER);
                     setSelectedGroupPrinterId(g._id);
                     setCurrentPrinterIndex(0);
                   }}
                   key={g._id}
              >
                {isSelected &&
                <div className="!flex absolute my-0 mx-[!important] h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] [background:linear-gradient(45deg,_#3949ab,_#4fc3f7)] hidden z-[0]" />
                }
                <div className="relative rounded-[50%] box-border w-3 h-3 z-[1] border-[1px] border-solid border-text-color-gray-979797" />
                <b className="relative leading-[15px] z-[2] md:text-2xs">{g.name}</b>
              </div>
            )
          })}
          <div className="self-stretch overflow-hidden flex flex-col items-center justify-center p-2 text-center text-royalblue-200">
            <div className="self-stretch flex flex-row items-center justify-center gap-[0px_8px]">
              <div className="flex-1 rounded bg-blue-solid-blue-30-e3f2fd flex flex-row items-center justify-center py-[9.5px] px-0 border-[1px] border-dashed border-blue-solid-blue-350-2196f3"
                   ref={makeRipple}
                   onPointerDown={() => createNewGroupPrinter("recheck")}
              >
                <b className="relative">+ {LL0().ui.add()}</b>
              </div>
              {recheckGroupPrinters0()?.length ?
                <Button className="!flex-1 !rounded !bg-red-solid-red-10-f6d5dd !flex !flex-row !items-center !justify-center !py-[9.5px] !px-0 !text-red-solid-red-300-ff4836 !border-[1px] !border-dashed !border-red-solid-red-300-ff4836"
                        ref={makeRipple}
                        onClick={() => {
                          userFLow(`open delete confirmation popup`, {
                            username: loginUser()?.name
                          });
                          setIsDeleteConfirmationPopup1Open(true)
                        }}
                        disabled={printerSettingMode() !== PrinterMode.RECHECK_PRINTER}
                        sx={{textTransform: "capitalize", fontFamily: "inherit"}}
                >
                  <b className="relative text-sm leading-none">{LL0().ui.delete()}</b>
                </Button>
                :
                null
              }
            </div>
          </div>
        </div>
        }
      </div>
      {isDeleteConfirmationPopup1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup1}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup1} onComplete={deleteCurrentGroupPrinter}/>
        </PortalPopup>
      )}
    </>)
}

const PrinterSettingPlugin = () => {
  const [isDeleteConfirmationPopup1Open, setIsDeleteConfirmationPopup1Open] = useState<boolean>(false);

  const openDeleteConfirmationPopup1 = () => {
    setIsDeleteConfirmationPopup1Open(true);
  }

  const closeDeleteConfirmationPopup1 = () => {
    setIsDeleteConfirmationPopup1Open(false);
  }

  return (
    <>
      <div className="bg-white-solid-white-100-ffffff w-full h-full overflow-hidden flex flex-row items-start justify-start text-left text-sm text-white-solid-white-100-ffffff font-mulish">
        <div className="self-stretch bg-white-solid-white-100-ffffff shadow-[0px_4px_8px_rgba(0,_0,_0,_0.25)] w-60 flex flex-col items-center justify-center sm:w-[210px]">
          <div className="no-scrollbar self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex flex-col items-center justify-start pt-2 px-0 pb-3 gap-[8px]">
            <PrinterPrintOrderTicketAcc
              showButtonGroup
              isExpanded
              isDefault={false}
              isSelected={false}
            />
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              {/*<PrinterLeftPanelItem*/}
              {/*  iconCode="/printerlabel-printer-icon@2x.png"*/}
              {/*  text="Label Printer"*/}
              {/*  showAddIcon={false}*/}
              {/*  isSelected={false}*/}
              {/*  rectangleLabelColor="#616161"*/}
              {/*  onClick={() => {*/}
              {/*    setSelectedGroupPrinterId(labelPrinters0()._id)*/}
              {/*    setPrinterSettingMode(PrinterMode.CLIENT_RECEIPT);*/}
              {/*  }}*/}
              {/*/>*/}
              <RecheckPrinterAccordion />
              <PrinterLeftPanelItem
                iconCode="/printerclient-receipt-icon@2x.png"
                text={LL0().sidebar.clientReceipt()}
                showAddIcon={false}
                isSelected={printerSettingMode() === PrinterMode.CLIENT_RECEIPT}
                rectangleLabelColor="#424242"
                onClick={() => {
                  userFLow(`set selected printer mode ${LL0().sidebar.clientReceipt()}`, {
                    username: loginUser()?.name
                  });
                  setSelectedGroupPrinterId(invoiceGroupPrinters0()?.[0]._id)
                  setPrinterSettingMode(PrinterMode.CLIENT_RECEIPT);
                }}
              />
              <PrinterLeftPanelItem
                iconCode="/printerlabel-printer-icon@2x.png"
                text={LL0().sidebar.labelPrinter()}
                showAddIcon={false}
                isSelected={printerSettingMode() === PrinterMode.LABEL_PRINTER}
                rectangleLabelColor="#424242"
                onClick={() => {
                  userFLow(`set selected printer mode ${LL0().sidebar.labelPrinter()}`, {
                    username: loginUser()?.name
                  });
                  setSelectedGroupPrinterId(labelPrinters0()?._id)
                  setPrinterSettingMode(PrinterMode.LABEL_PRINTER);
                }}
              />
              <PrinterLeftPanelItem
                iconCode="/iconadditional-settings-icon@2x.png"
                text={LL0().sidebar.additionalSettings()}
                showAddIcon={false}
                isSelected={printerSettingMode() === PrinterMode.ADDITIONAL_SETTING}
                rectangleLabelColor="#fff"
                onClick={() => {
                  userFLow(`set printer setting mode ${LL0().sidebar.additionalSettings()}`, {
                    username: loginUser()?.name
                  });
                  setPrinterSettingMode(PrinterMode.ADDITIONAL_SETTING);
                }}
              />
            </div>
          </div>
          <div className="self-stretch bg-white-solid-white-100-ffffff shadow-[0px_-2px_8px_rgba(0,_0,_0,_0.12)] flex flex-col items-center justify-center py-2 px-0 text-center">
            <div className="rounded-81xl bg-blue-500 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[0px_3px]"
                 ref={makeRipple}
                 onClick={onBack}
            >
              <img
                className="w-[30px] relative h-[30px] object-cover"
                alt=""
                src="/iconback-icon@2x.png"
              />
            <b className="relative leading-[17px]">{LL0().ui.back()}</b>
            </div>
          </div>
        </div>
        <PrinterSettingRight />
      </div>
      {isDeleteConfirmationPopup1Open && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDeleteConfirmationPopup1}
        >
          <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup1} />
        </PortalPopup>
      )}
    </>
  );
};

export default memo(PrinterSettingPlugin);
