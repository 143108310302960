import { type FunctionComponent, useState, useCallback } from 'react'
import Avatar from './Avatar'
import { AVATAR_LIST } from '@/react/UserSettingView/UserSettingView'
import _ from 'lodash'
import {LL0} from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type UserSettingSelectUserAvatarModalType = {
  onClose?: () => void
  selected?: string
  onOk: (val?: string) => void
}

const UserSettingSelectUserAvatarModal: FunctionComponent<UserSettingSelectUserAvatarModalType> = ({ onClose, selected, onOk }) => {
  const [selectedAvatar, setSelectedAvatar] = useState<string | undefined>(selected)
  const handleOk = useCallback(() => onOk(selectedAvatar), [selectedAvatar])

  return (
    <>
      <div className="w-full bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start py-[18px] px-0 box-border gap-[20px_0px] max-w-[688px] h-full cursor-pointer max-h-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish Tablet_768:w-[560px] Tablet_768:max-w-[560px] Tablet_600:w-[486px] Tablet_600:gap-[16px_0px] Tablet_600:max-w-[486px] height_414:w-[486px] height_414:gap-[16px_0px] height_414:max-w-[486px]">
        <div className="self-stretch overflow-hidden flex flex-row items-center justify-between py-0 px-[18px]">
          <b className="relative">{LL0().settings.selectAvatar()}</b>
          <img
            className="w-6 relative h-6 object-cover cursor-pointer"
            alt=""
            src="/iconclose-popup-icon--grey@2x.png"
            onClick={onClose}
          />
        </div>
        <div className="self-stretch overflow-hidden flex flex-row flex-wrap items-start justify-start py-0 px-9 gap-[29.6px] Tablet_768:gap-[29.6px_16px] Tablet_768:pl-6 Tablet_768:pr-6 Tablet_768:box-border Tablet_600:gap-[29.6px_14px] Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border height_414:gap-[29.6px_14px] height_414:pl-4 height_414:pr-4 height_414:box-border">
          {AVATAR_LIST.map(avatar => (
            <Avatar
              isSelected={avatar === selectedAvatar}
              avatarBackgroundImage={`url(${avatar})`}
              avatarBackgroundSize="cover"
              onClick={() => {
                userFLow(`set selectedAvatar ${selectedAvatar}`, {
                  username: loginUser()?.name
                });
                setSelectedAvatar(avatar)
              }}
              key={avatar}
            />
          ))}
        </div>
        <div className="self-stretch overflow-hidden flex flex-row items-start justify-end py-0 pr-8 pl-0 gap-[0px_12px] text-center text-mini">
          <button
            className="w-[120px] rounded-md bg-white-solid-white-100-ffffff box-border h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center cursor-pointer border-[1px] border-solid border-materialize-blue-accent-3 Tablet_768:w-[116px] Tablet_768:h-8"
            onClick={onClose}
            type="button"
          >
            <div className="w-[65px] relative font-semibold flex items-center justify-center shrink-0 Tablet_768:text-smi">{LL0().ui.cancel()}</div>
          </button>
          <button
            className="w-[120px] rounded-md bg-materialize-blue-accent-3 h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center text-white-solid-white-100-ffffff Tablet_768:w-[116px] Tablet_768:h-8"
            onClick={handleOk}
            type="button"
          >
            <div className="relative font-semibold Tablet_768:text-smi uppercase">{LL0().ui.ok()}</div>
          </button>
        </div>
      </div>
    </>
  )
}

export default UserSettingSelectUserAvatarModal
