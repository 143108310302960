import { Partition } from "@/data/Partition.ts";
import uuid from "time-uuid";
import {
  activeRoom,
  activeRoomId,
  roomObject0,
  roomObjects1, rooms0,
  setRoomObject0
} from "@/react/EditRoomView/EditRoomView.tsx";
import { computed, selector, signal } from "@/react/core/reactive.ts";
import { RoomObject } from "@/data/Room.ts";
import { convertDocument } from "@/data/data-utils.ts";
import { partitions0, setPartitionV } from "@/data/PartitionHub.ts";
import _ from "lodash";
import delay from "delay";
import { onTableClick } from "@/react/FloorPlanView/FloorPlanView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { toast } from "react-toastify";

export const [activePartitionId, setActivePartitionId] = signal<string>(""),
  isActivePartitionId = selector<string>(activePartitionId)
export const activePartition = computed(() => partitions0().find(r => r._id === activePartitionId()));
export const [switchMode0, setSwitchMode0] = signal<'table' | 'partition' | undefined>()
export const [tempItemSwitchP, setTempItemSwitchP] = signal<any>()
export const isTablePartitionsNameExist = (tableName: string) =>
  roomObjects1()?.findIndex(value => value.name === tableName) !== -1

export const newTablePartitionName = () => {
  let res = 1
  while (isTablePartitionsNameExist(res.toString())) res++
  return res.toString();
}
//@ts-ignore
export const addPartition = async () => {
  setActivePartitionId()
  const lastPartition = await Partition.findOne({
    selector: { room: activeRoomId() },
    sort: [
      { 'order': 'desc' },
    ],
  }).exec()
  const newOrder = (lastPartition?.order || 0) + 1
  const _id = uuid()
  await Partition.insert({
    _id: _id,
    room: activeRoomId(),
    name: 'Partition ' + newOrder,
    order: newOrder,
  });
  setTimeout(() => {
    setActivePartitionId(_id)
  }, 300)
  return _id
}
export const handleClickTable = async (table: RoomObject) => {
  await onTableClick(table, true)
}

export const onClickSwitchP = async (itemSwitch: any, itemMode: 'partition' | 'table') => {
  if (!tempItemSwitchP() || !switchMode0()) {
    setSwitchMode0()
    setTempItemSwitchP()
    return
  }
  if (switchMode0() !== itemMode) { //fixme: this case move table to different partition
    if (itemMode === 'table') {
      setSwitchMode0()
      setTempItemSwitchP()
      return
    }
    const lastTablePartition = await RoomObject.findOne({
      selector: {
        partition: itemSwitch._id,
      },
      sort: [{ 'location.order': 'desc' }]
    }).exec()
    let tableLocation = _.clone(lastTablePartition)?.location
    let newOrder = 0
    console.log('lastTableLocation ', tableLocation)
    if (lastTablePartition) {
      newOrder = (tableLocation?.order || 0) + 1
    }
    console.log('newOrder ', newOrder)
    _.assign(tempItemSwitchP(), { location: { ...tableLocation, order: newOrder }, partition: itemSwitch._id });
    setTimeout(() => {
      //todo: update lai partitions0 thay vi +1
      setPartitionV(prev => prev + 1)
    }, 200)
    setSwitchMode0()
    setTempItemSwitchP()
    toast.success(`${LL0().settings.successful()}!`)
    return true
  }
  if (switchMode0() === 'table') {
    const tableLocation = { ...itemSwitch?.location };
    const tempLocation = { ...tempItemSwitchP()?.location };
    const tableP = itemSwitch?.partition
    const itemP = tempItemSwitchP()?.partition
    _.assign(tempItemSwitchP(), { location: tableLocation, partition: tableP });
    _.assign(itemSwitch, { location: tempLocation, partition: itemP });
    setSwitchMode0()
    setTempItemSwitchP()
    setTimeout(() => {
      setPartitionV(prev => prev + 1)
    }, 200)
    toast.success(`${LL0().settings.successful()}!`)
    return true
  } else if (switchMode0() === 'partition') {
    const tempOrder = tempItemSwitchP()?.order
    const pOrder = itemSwitch?.order
    const tempRoom = tempItemSwitchP()?.room
    const partitionRoom = itemSwitch?.room
    _.assign(tempItemSwitchP(), { order: pOrder, room: partitionRoom });
    _.assign(itemSwitch, { order: tempOrder, room: tempRoom });
    setSwitchMode0()
    setTempItemSwitchP()
    toast.success(`${LL0().settings.successful()}!`)
    return true
  }
}

export const deletePartition = (partitionId?: string) => {
  if (partitionId) {
    const p = partitions0().find(r => r._id === partitionId)
    p?.doc?.incrementTalRemove?.()
    return
  }
  activePartition()?.doc?.incrementalRemove()
}

export const addRoomObjectForPartition = async (partitionId: string) => {
  const activePartition = partitions0().find(r => r._id === partitionId);
  const partitions1 = partitions0().filter(p => p.room === activeRoomId())
  const pIds = partitions1.map(p => p._id)
  const lastOrderRaw = await RoomObject.findOne({
    selector: { partition: { $in: pIds } },
    sort: [{ 'location.order': 'desc' }]
  }).exec()

  // => lay order lon nhat cua table trong room (tat ca partition)
  const lastOrder = lastOrderRaw?.location?.order || 0
  const newTable = await RoomObject.insert({
    _id: uuid(),
    partition: partitionId,
    location: { order: (lastOrder || 0) + 1 },
    shape: 'rectangle',
    type: 'table',
    name: newTablePartitionName(),
    bgColor: '#FFFFFF',
  });

  const newRoomObject = convertDocument<RoomObject>(newTable, true)
  if (!activePartition?.roomObjects) {
    _.assign(activePartition, { roomObjects: [newRoomObject] })
  } else {
    activePartition?.roomObjects?.push(newRoomObject);
  }
  setRoomObject0(newRoomObject);
  console.log('success')
}

export const moveAllTableToPartition1 = async () => {
  if (!activeRoomId()) return
  if (!activeRoom()?.roomObjects) {
    console.log('not found roomObjects')
    return
  }
  if (partitions0()?.filter(p => p.room === activeRoomId())?.length > 0) return
  const partitionId = await addPartition()
  const activePartition = convertDocument(
    await Partition.findOne({ selector: { _id: partitionId } }).exec(), true)
  console.log('activePartition ', activePartition?._id, activeRoom()?.roomObjects)
  let order = 0
  const arr = []
  for (const table of activeRoom()!.roomObjects!) {
    if (table.type === 'table' && table.name) {
      const newLocation = { ...table.location, order }
      _.assign(table, { partition: partitionId, location: newLocation })
      arr.push(table)
      order++
      await delay(100)
    }
  }
  console.log('new roomObjects ', arr)
  _.assign(activePartition, { roomObjects: arr });
  console.log('success')
}

export const mergeAllPartition = () => {
  if (!activeRoom() || partitions0()?.length < 1) return
  const _partitions0 = partitions0().filter(p => p.room === activeRoomId())
  for (let i = 1; i < _partitions0!.length; i++) {
    if (!_partitions0[0].roomObjects || _partitions0[0].roomObjects?.length === 0) {
      _.assign(_partitions0[0], { roomObjects: [] })
    }
    if (!_partitions0[i].roomObjects) continue
    for (const r of _partitions0[i].roomObjects!) {
      _.assign(r, { partition: _partitions0[0]._id })
    }
    _partitions0[i]?.doc?.incrementalRemove()
    // partitions0()[0]!.roomObjects!.concat(partitions0()[i]!.roomObjects!)
  }
  setTimeout(() => {
    setPartitionV(prev => prev + 1)
  }, 400)
}

export const deleteTablePartition = async () => {
  const activeRoom = partitions0().find(r => r._id === activePartitionId());
  const index = activeRoom?.roomObjects?.findIndex(o => o._id === roomObject0()._id);
  console.log('index', index)
  if (index !== -1) {
    activeRoom?.roomObjects?.splice(index!, 1);
  }
  await roomObject0()?.doc?.incrementalRemove();
  setRoomObject0();
};

