import { FunctionComponent, useMemo, type CSSProperties } from "react";

export type CategoryItemType = {
  onClick: () => void;
  className?: string;
  isSelected?: boolean;
  categoryName?: string;

  /** Style props */
  backgroundColor?: CSSProperties["backgroundColor"];
  orderScreenCategoryItemV1Flex?: CSSProperties["flex"];
  orderScreenCategoryItemV1Height?: CSSProperties["height"];
  orderScreenCategoryItemV1MinWidth?: CSSProperties["minWidth"];
};

const CategoryItem: FunctionComponent<CategoryItemType> = ({
  onClick,
  className = "",
  backgroundColor,
  isSelected,
  categoryName,
  orderScreenCategoryItemV1Flex,
  orderScreenCategoryItemV1Height,
  orderScreenCategoryItemV1MinWidth,
}) => {
  const isDefaultStyle: CSSProperties = useMemo(() => {
    return {
      backgroundColor: backgroundColor,
    };
  }, [backgroundColor]);

  const orderScreenCategoryItemV1Style: CSSProperties = useMemo(() => {
    return {
      flex: orderScreenCategoryItemV1Flex,
      height: orderScreenCategoryItemV1Height,
      minWidth: orderScreenCategoryItemV1MinWidth,
    };
  }, [
    orderScreenCategoryItemV1Flex,
    orderScreenCategoryItemV1Height,
    orderScreenCategoryItemV1MinWidth,
  ]);

  return (
    <div
      onClick={onClick}
      className={`shadow-[0px_1px_4px_rgba(0,_0,_0,_0.25)] rounded flex flex-row items-center justify-center py-0.5 px-1 relative gap-[2px] text-center text-sm text-black-solid-black-1000-000000 font-mulish ${className}`}
      style={orderScreenCategoryItemV1Style}
    >
      <div
        className="w-[100.21%] absolute !m-[0] h-full top-[0%] right-[-0.21%] bottom-[0%] left-[0%] rounded bg-red-solid-red-60-ff938a z-[0]"
        style={isDefaultStyle}
      />
      {isSelected && (
        <div className="w-full absolute !m-[0] h-full inset-0 rounded [background:linear-gradient(175.2deg,_rgba(255,_255,_255,_0.12)_26.49%,_rgba(0,_0,_0,_0.12))] box-border z-[1] border-[2px] border-solid border-pink-solid-pink-600-ee0ba1" />
      )}
      <div className="flex-1 relative font-semibold [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] z-[2]">
        {categoryName}
      </div>
    </div>
  );
};

export default CategoryItem;
