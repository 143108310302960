import { type FunctionComponent, useContext } from "react";
import { ItemFactoryContext } from "@/react/PaymentView/OrderItemsFactory2.tsx";
import type { Setter } from "solid-js";
import { LL0 } from "@/react/core/I18nService.tsx";

export type SeatItemType = {
  className?: string;
  seatNumber: number;
  isSselected?: boolean;
  isCart?: boolean;
  quantity: number;
  _setSeat?: Setter<number>
};

const SeatItem: FunctionComponent<SeatItemType> = ({
  className = "",
  seatNumber,
  isSselected,
  isCart,
  quantity,
  _setSeat
}) => {
  const { order0, seat, onNext, setSeat, setCurrentSeat, splitEnable, moveEnable } = useContext(ItemFactoryContext);

  return (
    <div
      onClick={() => {
        if (isCart) {
          _setSeat?.(seatNumber)
        } else {
          setCurrentSeat?.(seatNumber)
        }
      }}
      className={`flex-1 flex flex-col items-start justify-start gap-[6px] min-w-[94px] text-left text-sm text-black-solid-black-1000-000000 font-mulish sm:min-w-[72px] mq600:min-w-[72px] mq432:min-w-[72px] ${className}`}
    >
      {!isSselected && (
        <div className="self-stretch rounded-10xs bg-blue-solid-blue-100-c9e2ff h-9 flex flex-row items-center justify-center p-1 box-border gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[4px]">
            <div className="relative font-medium sm:text-smi mq600:text-smi mq432:text-smi">
              {LL0().payment.seat()}
            </div>
            <div className="relative font-medium sm:text-smi mq600:text-smi mq432:text-smi">
              {seatNumber + 1}
            </div>
          </div>
          {quantity > 0 && (
            <div className="w-5 rounded-981xl [background:linear-gradient(180deg,_#fb6b6b,_#fd0000)] h-5 flex flex-row items-center justify-center text-center text-white-solid-white-100-ffffff">
              <b className="relative">{quantity}</b>
            </div>
          )}
        </div>
      )}
      {isSselected && (
        <div className="self-stretch rounded-10xs bg-blue-solid-blue-350-2196f3 text-white h-9 flex flex-row items-center justify-center p-1 box-border gap-[4px]">
          <div className="flex flex-row items-center justify-center gap-[4px]">
            <div className="relative font-medium sm:text-smi mq600:text-smi mq432:text-smi">
              {LL0().payment.seat()}
            </div>
            <div className="relative font-medium sm:text-smi mq600:text-smi mq432:text-smi">
              {seatNumber + 1}
            </div>
          </div>
          {quantity > 0 && (
            <div className="w-5 rounded-981xl [background:linear-gradient(180deg,_#fb6b6b,_#fd0000)] h-5 flex flex-row items-center justify-center text-center text-white-solid-white-100-ffffff">
              <b className="relative">{quantity}</b>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SeatItem;
