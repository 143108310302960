import { isMaster } from "@/lib/master-signal.ts";
import { offlineSync, onlineSync } from "@/data/ReplicateEffect.ts";
import { setMasterAvailable, setOnlineMasterAvailable } from "@/data/data-utils.ts";
import ClientCaller from "@/lib/master-caller/client-caller.ts";
import debug from 'debug'
import { syncMode0 } from "@/data/DeviceSettingHub.ts";
import { SyncMode } from "@/data/data-enum.ts";

const log = debug('app:ping-online')

enum IntervalTime {
  Online = import.meta.env.MODE === 'production' ? 30000 : 10000,
  Offline = 5000
}

export class PingOnlineClient {
  static clientPingInterval: ReturnType<typeof setInterval> | undefined
  static currentIntervalTime = IntervalTime.Offline;

  static async pingMasterOnline() {
    if (!isMaster() && onlineSync()) {
      try {
        await ClientCaller.callMasterCommand('pingMaster', [], { onlyOnline: true })
        log('online master available')
        setOnlineMasterAvailable(true)
        return true;
      } catch (e) {
        log('online master unavailable')
        setOnlineMasterAvailable(false)
        return false;
      }
    }
  }

  static startOnlinePingInterval() {
    if (isMaster()) return;
    if (PingOnlineClient.clientPingInterval) {
      PingOnlineClient.stopOnlinePingInterval();
    }
    log(`starting online ping interval ${PingOnlineClient.currentIntervalTime}`);
    const pingOnline = async () => {
      if (isMaster()) return;
      if (syncMode0() === SyncMode.offline) return;
      if (offlineSync()) return;
      log('pinging master online');
      const result = await PingOnlineClient.pingMasterOnline()
      if (result === true && PingOnlineClient.currentIntervalTime === IntervalTime.Offline) {
        log('change interval to 30s')
        PingOnlineClient.currentIntervalTime = IntervalTime.Online;
        PingOnlineClient.startOnlinePingInterval();
      } else if (result === false && PingOnlineClient.currentIntervalTime === IntervalTime.Online) {
        log('change interval to 5s')
        PingOnlineClient.currentIntervalTime = IntervalTime.Offline;
        PingOnlineClient.startOnlinePingInterval();
      }
    };
    pingOnline().then();
    PingOnlineClient.clientPingInterval = setInterval(pingOnline, PingOnlineClient.currentIntervalTime);
  }

  static stopOnlinePingInterval() {
    log('stopping online ping interval');
    if (!PingOnlineClient.clientPingInterval) return;
    clearInterval(PingOnlineClient.clientPingInterval)
    PingOnlineClient.clientPingInterval = undefined;
  }
}