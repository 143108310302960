import type { RxConflictHandler, RxConflictHandlerInput, RxConflictHandlerOutput } from "rxdb/src/types";
import { stripAttachmentsDataFromDocument } from "rxdb";
import _ from "lodash";
import type { Order } from "@/data/Order.ts";
import { createOrder, resetOrder, stripOrder } from "@/pos/logic/order-reactive.ts";


function mergeOrder(order1: Order, order2: Order) {
  const commits1 = order1.commits || [];
  const commits2 = order2.commits || [];
  const mergedCommits = _.sortBy(_.uniqBy([...commits1, ...commits2], '_id'), ['date']);
  let order = _.cloneDeep(order2)
  //@ts-ignore
  order.updatedAt = Date.now()
  order.commits = mergedCommits;
  order = createOrder(resetOrder(order));
  return stripOrder(order);
}

export const conflictHandler: RxConflictHandler<any> = function (
  i: RxConflictHandlerInput<any>,
  _context: string
): Promise<RxConflictHandlerOutput<any>> {
  const newDocumentState = stripAttachmentsDataFromDocument(i.newDocumentState);
  const realMasterState = stripAttachmentsDataFromDocument(i.realMasterState);

  if (newDocumentState.updatedAt === realMasterState.updatedAt) {
    return Promise.resolve({
      isEqual: true
    });
  }

  if (_context === 'replication-resolve-conflict' || _context === 'masterWrite-online') {
    const doc = mergeOrder(newDocumentState, realMasterState);
    return Promise.resolve({
      isEqual: false,
      documentData: doc
    });
  }

  return Promise.resolve({
    isEqual: false,
    documentData: i.realMasterState
  });
};