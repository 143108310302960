import { type FunctionComponent, useState, useCallback } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Switch,
  FormControlLabel,
} from "@mui/material";
import PopupDeleteConfirmation from "../../Popup/PopupDeleteConfirmation";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PopupModifierGroupEdit from "./PopupModifierGroupEdit";
import type { ModifierItem } from "@/data/Modifier";
import { STATES, VIEW__MODIFIER } from "@/react/EditOnlineMenuView/EditOnlineMenuView.logic";
import { numberFromString, numberToString } from "@/react/core/utils";
import TextField from "@/react/core/TextField";
import {LL0} from "@/react/core/I18nService.tsx";
import {taxCategoriesVM} from "@/data/TaxCategoryHub.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import _ from "lodash";

const ModifierTypeSelectFieldset: FunctionComponent = () => {
  const [isEditItemDeleteConfirmPoOpen, setEditItemDeleteConfirmPoOpen] =
    useState(false);
  const [isOptionAddNewSelectionOpen, setOptionAddNewSelectionOpen] =
    useState(false);
  const [isEditItemDeleteConfirmPo1Open, setEditItemDeleteConfirmPo1Open] =
    useState(false);
  const [isEditItemDeleteConfirmPo2Open, setEditItemDeleteConfirmPo2Open] =
    useState(false);

  const openEditItemDeleteConfirmPo = useCallback(() => {
    userFLow(`openEditItemDeleteConfirmPo in Online Menu`, {
      username: loginUser()?.name
    })
    setEditItemDeleteConfirmPoOpen(true);
  }, []);

  const closeEditItemDeleteConfirmPo = useCallback(() => {
    setEditItemDeleteConfirmPoOpen(false);
  }, []);

  const openOptionAddNewSelection = useCallback(() => {
    VIEW__MODIFIER.onCreateItemGroup()
    setOptionAddNewSelectionOpen(true);
  }, []);

  const closeOptionAddNewSelection = useCallback(() => {
    setOptionAddNewSelectionOpen(false);
  }, []);

  const openEditItemDeleteConfirmPo1 = useCallback(() => {
    setEditItemDeleteConfirmPo1Open(true);
  }, []);

  const closeEditItemDeleteConfirmPo1 = useCallback(() => {
    setEditItemDeleteConfirmPo1Open(false);
  }, []);

  const openEditItemDeleteConfirmPo2 = useCallback(() => {
    setEditItemDeleteConfirmPo2Open(true);
  }, []);

  const closeEditItemDeleteConfirmPo2 = useCallback(() => {
    setEditItemDeleteConfirmPo2Open(false);
  }, []);

  const [stagingItem, setStagingItem] = useState<ModifierItem | undefined>()

  return (
    <>
      <table className="self-stretch border-spacing-[0]">
        <tbody>
          <tr>
            <td className="relative pr-0 pb-0">
              <div className="w-full max-w-[24] flex h-auto flex-row gap-[10px] items-center justify-start py-0 px-2 box-border hover:flex hover:w-6 hover:self-stretch hover:h-auto hover:flex-row hover:gap-[10px] hover:items-center hover:justify-start hover:py-0 hover:px-2 hover:box-border hover:max-w-[24]" />
            </td>
            <td className="relative pb-0">
              <div className="rounded-t-2xl rounded-b-none box-border overflow-hidden flex flex-col items-start justify-start h-auto gap-[0px] w-full border-t-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-r-[1px] border-l-[1px] hover:flex hover:flex-1 hover:h-auto hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start hover:rounded-t-2xl hover:rounded-b-none">
                <div className="self-stretch bg-orange-solid-orange-200-fbe5c9 flex flex-row items-center justify-start py-2 px-3 gap-[10px]">
                  <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-base sm:text-sm">
                    {LL0().ui.name()}:
                  </div>
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    size="small"
                    placeholder="Selection"
                    fullWidth={true}
                    variant="outlined"
                    disabled={!STATES.editingModifierGroupId()}
                    value={STATES.editingModifierGroup()?.name ?? ''}
                    onChange={() => {
                      _.debounce(() => userFLow(`editingModifierGroup in Online Menu, ${STATES.editingModifierGroup()?.name}`, {
                        username: loginUser()?.name
                      }), 1000, { leading: true, trailing: true })
                      VIEW__MODIFIER.onItemGroupNameChanged
                    }}
                  />
                  <button
                    className="rounded-3xs bg-pink-solid-pink-200-ffe9f5 shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] w-12 h-11 flex flex-row items-center justify-center py-2.5 px-2.5 box-border cursor-pointer md:h-10 md:py-2 md:px-4 md:box-border sm:w-10 sm:h-8 sm:p y-1.5 sm:px-3 sm:box-border mq768:w-8 mq768:h-7"
                    disabled={!STATES.editingModifierGroupId()}
                    onClick={openEditItemDeleteConfirmPo}
                  >
                    <img
                      className="relative w-6 h-6 object-cover sm:w-5 sm:h-5"
                      alt=""
                      src="/iconmenubin-icon@2x.png"
                    />
                  </button>
                  <button
                    className="rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_20px_rgba(48,_48,_48,_0.2)] shrink-0 flex flex-row items-center justify-center py-2.5 px-3 gap-[2px] cursor-pointer md:pt-2 md:pb-2 md:box-border sm:py-1.5 sm:px-4 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2 mq600:pr-2 mq600:box-border"
                    onClick={openOptionAddNewSelection}
                  >
                    <img
                      className="relative w-6 h-6 object-cover"
                      alt=""
                      src="/iconmenuarrow-dropdown-icon--white@2x.png"
                    />
                    <b className="relative text-xl font-mulish-button-name-bold text-white-solid-white-100-ffffff text-center lg:text-lg md:text-base sm:text-sm mq768:text-smi mq600:text-smi">
                      {LL0().editOnlineMenu.addNewSelection()}
                    </b>
                  </button>
                </div>
                <div className="self-stretch bg-blue-solid-blue-100-eff1f9 flex flex-row items-center justify-start py-0.5 px-0 gap-[2px]">
                  {STATES.editingModifier()?.itemGroups?.map(g => (
                  <button key={g._id} onClick={() => VIEW__MODIFIER.onItemGroupEdit(g._id)} className="relative rounded-10xs bg-basic-color-white shadow-[0px_3px_4px_rgba(0,_0,_0,_0.2),_0px_0px_4px_rgba(0,_0,_0,_0.25)_inset] h-[54px] overflow-hidden flex flex-row items-center justify-center py-0 px-[30px] box-border lg:h-14 md:h-12 sm:h-11">
                    {STATES.editingModifierGroupId() === g._id && (
                    <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-orange-solid-orange-200-fbe5c9 z-[0]" />
                    )}
                    <div className="relative text-lg font-semibold font-mulish text-orange-solid-orange-600-bf710f text-left lg:text-base md:text-mini sm:text-sm">
                      {g.name}
                    </div>
                  </button>
                  ))}
                  {/*
                  <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_3px_4px_rgba(0,_0,_0,_0.2),_0px_0px_4px_rgba(0,_0,_0,_0.25)_inset] h-[54px] overflow-hidden flex flex-row items-center justify-center py-0 px-[30px] box-border lg:h-14 md:h-12 sm:h-11">
                    <div className="relative text-lg font-semibold font-mulish-button-name-bold text-orange-solid-orange-600-bf710f text-left lg:text-base md:text-mini sm:text-sm">
                      Meat
                    </div>
                  </div>
                  <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_3px_4px_rgba(0,_0,_0,_0.2),_0px_0px_4px_rgba(0,_0,_0,_0.25)_inset] h-[54px] overflow-hidden flex flex-row items-center justify-center py-0 px-[30px] box-border lg:h-14 md:h-12 sm:h-11">
                    <div className="relative text-lg font-semibold font-mulish-button-name-bold text-orange-solid-orange-600-bf710f text-left lg:text-base md:text-mini sm:text-sm">
                      Vegatable
                    </div>
                  </div>
                  <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_3px_4px_rgba(0,_0,_0,_0.2),_0px_0px_4px_rgba(0,_0,_0,_0.25)_inset] h-[54px] overflow-hidden flex flex-row items-center justify-center py-0 px-[30px] box-border relative gap-[10px] lg:h-14 md:h-12 sm:h-11">
                    <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-orange-solid-orange-200-fbe5c9 z-[0]" />
                    <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left z-[1] lg:text-base md:text-mini sm:text-sm">
                      Starch
                    </div>
                  </div>
                  <div className="rounded-10xs bg-white-solid-white-100-ffffff shadow-[0px_3px_4px_rgba(0,_0,_0,_0.2),_0px_0px_4px_rgba(0,_0,_0,_0.25)_inset] h-[54px] overflow-hidden flex flex-row items-center justify-center py-0 px-[30px] box-border lg:h-14 md:h-12 sm:h-11">
                    <div className="relative text-lg font-semibold font-mulish-button-name-bold text-orange-solid-orange-600-bf710f text-left lg:text-base md:text-mini sm:text-sm">
                      Drink
                    </div>
                  </div>
                  */}
                </div>
              </div>
            </td>
          </tr>
          {STATES.editingModifierGroupId() && (<>
          <tr>
            <td className="relative pr-0 pb-0">
              <div className="w-6 max-w-[24px]" />
            </td>
            <td className="relative pb-0">
              <div className="bg-green-solid-green-300-bdebdc box-border shrink-0 flex flex-row items-center justify-start h-auto gap-[0px] w-full border-t-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-r-[1px] border-l-[1px] hover:bg-green-solid-green-300-bdebdc hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-center hover:justify-start">
                <div className="flex-1 h-14 flex flex-row items-center justify-start py-0 px-2 box-border md:h-12 sm:h-10">
                  <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                    {LL0().editOnlineMenu.optionValue()}
                  </div>
                </div>
                <div className="w-40 h-14 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] md:h-12 sm:w-[110px] sm:h-10">
                  <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                    {LL0().printing.price()} ({LL0().printing.currency()})
                  </div>
                </div>
                <div className="w-40 h-14 flex flex-row items-center justify-start py-0 px-2 box-border md:w-[130px] md:h-12 sm:w-[110px] sm:h-10">
                  <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                    {LL0().article.tax()}
                  </div>
                </div>
                <div className="w-[88px] h-14 flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] md:h-12 sm:w-16 sm:h-10 mq768:w-14">
                  <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                    {LL0().onlineOrder.status()}
                  </div>
                </div>
                <div className="w-[88px] h-14 flex flex-row items-center justify-center py-0 px-2 box-border md:w-20 md:h-12 sm:w-16 sm:h-10 mq768:w-12">
                  <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-mini sm:text-sm mq768:text-sm">
                    {LL0().ui.delete()}
                  </div>
                </div>
              </div>
            </td>
          </tr>
          {STATES.editingModifier()?.items?.filter(a => a.typeSelect_group === STATES.editingModifierGroupId()).map(item => (
          <tr key={item._id}>
            <td className="relative pr-0 pb-0">
              <div className="w-full flex flex-row items-center justify-center py-0 px-2 box-border max-w-[24px] h-full">
                <div className="relative w-6 h-6 overflow-hidden shrink-0">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/iconreplace-position-icon@2x.png"
                  />
                </div>
              </div>
            </td>
            <td className="relative pb-0">
              <div className="bg-white-solid-white-100-ffffff box-border shrink-0 flex flex-row items-start justify-start py-2 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-l-[1px] hover:bg-white-solid-white-100-ffffff hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-start hover:justify-start hover:py-2 hover:px-0 hover:box-border">
                <div className="self-stretch flex-1 flex flex-row items-center justify-start py-0 px-2">
                  <div className="flex-1 flex flex-col items-center justify-start gap-[8px]">
                    <TextField
                      className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                      color="primary"
                      label=""
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                      value={item.name}
                      onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'name', e.target.value)}
                    />
                    <div className="self-stretch flex flex-row items-start justify-center gap-[16px] sm:flex-col sm:gap-[8px]">
                      <div className="flex-1 h-[77px] flex flex-row items-center justify-start sm:flex-[unset] sm:self-stretch height_414:gap-[2px]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                          <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-base sm:text-sm height_414:text-smi">
                            {LL0().editOnlineMenu.itemsPack()}
                          </div>
                          <TextField
                            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                            color="primary"
                            size="small"
                            placeholder="1"
                            fullWidth={true}
                            variant="outlined"
                            type="number"
                            value={numberToString(item.typeQuantity_itemCount)}
                            onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'typeQuantity_itemCount', numberFromString(e.target.value))}
                          />
                        </div>
                      </div>
                      <div className="flex-1 h-[77px] flex flex-row items-center justify-start sm:flex-[unset] sm:self-stretch height_414:gap-[2px]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                          <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-base sm:text-sm height_414:text-smi">
                            {LL0().editOnlineMenu.maxSelected()}
                          </div>
                          <TextField
                            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                            color="primary"
                            size="small"
                            placeholder="2"
                            fullWidth={true}
                            variant="outlined"
                            type="number"
                            value={numberToString(item.typeQuantity_selectLimit)}
                            onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'typeQuantity_selectLimit', numberFromString(e.target.value))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch w-40 flex flex-row items-start justify-center py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    size="small"
                    placeholder="0"
                    fullWidth={true}
                    variant="outlined"
                    type="number"
                    value={numberToString(item.price)}
                    onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'price', numberFromString(e.target.value))}
                  />
                </div>
                <div className="self-stretch w-40 flex flex-row items-start justify-center py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <FormControl
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-260-304eee"
                    variant="outlined"
                  >
                    <InputLabel color="primary" />
                    <Select color="primary" size="small"
                      value={numberToString(item.tax)}
                      onChange={e => VIEW__MODIFIER.onItemChanged(item._id, 'tax', numberFromString(e.target.value))}
                    >
                      {
                        taxCategoriesVM().map((taxCategory, index) =>
                          <MenuItem key={index} value={taxCategory.value}>
                            {taxCategory.name} ({taxCategory.value}%)
                          </MenuItem>)
                      }
                    </Select>
                    <FormHelperText />
                  </FormControl>
                </div>
                <div className="w-[88px] h-[46px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] sm:w-16 mq768:w-14">
                  <div className="relative w-10 h-[46px]">
                    <FormControlLabel
                      label=""
                      className="absolute h-[43.48%] w-full top-[28.26%] right-[0%] bottom-[28.26%] left-[0%]"
                      control={<Switch color="primary"
                        checked={!!item.isEnabled}
                        onChange={(_,checked) => VIEW__MODIFIER.onItemChanged(item._id, 'isEnabled', checked)}
                      />}
                    />
                  </div>
                </div>
                <div className="w-[88px] h-[46px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-20 sm:w-16 mq768:w-12">
                  <img
                    className="relative w-10 h-10 object-cover cursor-pointer"
                    alt=""
                    src="/iconcell-bin-icon@2x.png"
                    onClick={() => (openEditItemDeleteConfirmPo(), setStagingItem(item))}
                  />
                </div>
              </div>
            </td>
          </tr>
          ))}
          {/*
          <tr>
            <td className="relative pr-0 pb-0">
              <div className="w-full flex flex-row items-center justify-center py-0 px-2 box-border max-w-[24px] h-full">
                <div className="relative w-6 h-6 overflow-hidden shrink-0">
                  <img
                    className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/iconreplace-position-icon@2x.png"
                  />
                </div>
              </div>
            </td>
            <td className="relative pb-0">
              <div className="bg-blue-solid-blue-100-eff1f9 box-border shrink-0 flex flex-row items-start justify-start py-2 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-l-[1px] hover:bg-blue-solid-blue-100-eff1f9 hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-start hover:justify-start hover:py-2 hover:px-0 hover:box-border">
                <div className="self-stretch flex-1 flex flex-row items-center justify-start py-0 px-2">
                  <div className="flex-1 flex flex-col items-center justify-start gap-[8px]">
                    <TextField
                      className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                      color="primary"
                      label="Name"
                      size="small"
                      fullWidth={true}
                      variant="outlined"
                    />
                    <div className="self-stretch flex flex-row items-start justify-center gap-[16px] sm:flex-col sm:gap-[8px]">
                      <div className="flex-1 h-[77px] flex flex-row items-center justify-start sm:flex-[unset] sm:self-stretch height_414:gap-[2px]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                          <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-base sm:text-sm height_414:text-smi">
                            Items/Pack
                          </div>
                          <TextField
                            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                            color="primary"
                            size="small"
                            placeholder="1"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className="flex-1 h-[77px] flex flex-row items-center justify-start sm:flex-[unset] sm:self-stretch height_414:gap-[2px]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                          <div className="relative text-lg font-semibold font-mulish-button-name-bold text-gray-solid-gray-900-2e2e48-200 text-left lg:text-mid md:text-base sm:text-sm height_414:text-smi">
                            Max Selected
                          </div>
                          <TextField
                            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                            color="primary"
                            size="small"
                            placeholder="2"
                            fullWidth={true}
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch w-40 flex flex-row items-start justify-center py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <TextField
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
                    color="primary"
                    name="0"
                    size="small"
                    placeholder="0"
                    fullWidth={true}
                    variant="outlined"
                  />
                </div>
                <div className="self-stretch w-40 flex flex-row items-start justify-center py-0 px-2 box-border md:w-[130px] sm:w-[110px]">
                  <FormControl
                    className="flex-1 font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-260-304eee"
                    variant="outlined"
                  >
                    <InputLabel color="primary" />
                    <Select color="primary" size="small">
                      <MenuItem value="Exempt">{LL0().editOnlineMenu.exempt()}</MenuItem>
                      <MenuItem value="5%">5%</MenuItem>
                      <MenuItem value="7%">7%</MenuItem>
                      <MenuItem value="8%">8%</MenuItem>
                      <MenuItem value="10%">10%</MenuItem>
                      <MenuItem value="19%">19%</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                </div>
                <div className="w-[88px] h-[46px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-[72px] sm:w-16 mq768:w-14">
                  <div className="relative w-10 h-[46px]">
                    <FormControlLabel
                      label=""
                      className="absolute h-[43.48%] w-full top-[28.26%] right-[0%] bottom-[28.26%] left-[0%]"
                      control={<Switch color="primary" />}
                    />
                  </div>
                </div>
                <div className="w-[88px] h-[46px] flex flex-row items-center justify-center py-0 px-2 box-border md:w-20 sm:w-16 mq768:w-12">
                  <img
                    className="relative w-10 h-10 object-cover cursor-pointer"
                    alt=""
                    src="/iconcell-bin-icon@2x.png"
                    onClick={openEditItemDeleteConfirmPo2}
                  />
                </div>
              </div>
            </td>
          </tr>
          */}
          <tr>
            <td className="relative pr-0">
              <div className="relative bg-gray-opacity-gray-0-d9d9d9-300 w-6 max-w-[24px]" />
            </td>
            <td className="relative">
              <div className="rounded-t-none rounded-b-2xl bg-gray-solid-gray-80-fafafa box-border shrink-0 flex flex-row items-start justify-start py-3 px-0 h-auto gap-[0px] w-full border-r-[1px] border-solid border-gray-solid-gray-300-e1e1e1-200 border-b-[1px] border-l-[1px] hover:bg-gray-solid-gray-80-fafafa hover:flex hover:flex-1 hover:h-auto hover:flex-row hover:gap-[0px] hover:items-start hover:justify-start hover:rounded-t-none hover:rounded-b-2xl hover:py-3 hover:px-0 hover:box-border sm:pt-2.5 sm:pb-2.5 sm:box-border mq768:pt-2 mq768:pb-2 mq768:box-border mq600:pt-1 mq600:pb-1 mq600:box-border">
                <button disabled={!STATES.editingModifierGroupId()} onClick={VIEW__MODIFIER.onCreateItem} className="self-stretch flex-1 flex flex-row items-center justify-start py-0 pr-2 pl-3 gap-[10px]">
                  <img
                    className="relative w-11 h-11 object-cover sm:w-10 sm:h-10 mq768:w-9 mq768:h-9 mq600:w-8 mq600:h-8"
                    alt=""
                    src="/iconadd-option-value-icon@2x.png"
                  />
                  <div className="flex-1 relative text-base font-semibold font-mulish-button-name-bold text-gray-opacity-gray-60-2e2e48-300 text-left md:text-smi sm:text-xs">
                    {LL0().editOnlineMenu.addValue()}
                  </div>
                </button>
                <div className="self-stretch w-40 md:w-[130px] sm:w-[110px]" />
                <div className="self-stretch w-40 md:w-[130px] sm:w-[110px]" />
                <div className="self-stretch w-[88px] md:w-[72px] sm:w-16 mq768:w-14" />
                <div className="self-stretch w-[88px] md:w-20 sm:w-16 mq768:w-12" />
              </div>
            </td>
          </tr>
          </>)}
        </tbody>
      </table>
      {isEditItemDeleteConfirmPoOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo}
        >
          <PopupDeleteConfirmation onClose={closeEditItemDeleteConfirmPo}
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${STATES.editingModifierGroup()?.name}"?`}
            onConfirmed={() => (closeEditItemDeleteConfirmPo(), VIEW__MODIFIER.onCurrentItemGroupDelete())} />
        </PortalPopup>
      )}
      {isOptionAddNewSelectionOpen && (
        <PortalPopup
        overlayColor="rgba(113, 113, 113, 0.3)"
        placement="Centered"
        onOutsideClick={closeOptionAddNewSelection}
        >
          <PopupModifierGroupEdit onClose={closeOptionAddNewSelection} />
        </PortalPopup>
      )}
      {isEditItemDeleteConfirmPo1Open && (
        <PortalPopup
        overlayColor="rgba(113, 113, 113, 0.3)"
        placement="Centered"
        onOutsideClick={closeEditItemDeleteConfirmPo1}
        >
          <PopupDeleteConfirmation onClose={closeEditItemDeleteConfirmPo1}
            message={`${LL0().editOnlineMenu.doYouWantToDelete()} "${stagingItem?.name}"?`}
            onConfirmed={() => (closeEditItemDeleteConfirmPo(), VIEW__MODIFIER.onItemDelete(stagingItem?._id))} />
        </PortalPopup>
      )}
      {/*
      {isEditItemDeleteConfirmPo2Open && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeEditItemDeleteConfirmPo2}
        >
          <PopupDeleteConfirmation onClose={closeEditItemDeleteConfirmPo2} />
        </PortalPopup>
      )}
      */}
    </>
  );
};

export default ModifierTypeSelectFieldset;
