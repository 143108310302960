import type { RxCollection, RxDatabase, RxJsonSchema } from 'rxdb'
import { createCollection } from "@/data/utils/migration.ts";
import type { RoomObject } from "@/data/Room.ts";

export const PARTITION_COLLECTION_NAME = 'partitions'

export interface Partition {
  _id: string
  color?: string
  name?: string
  room?: string
  order: number
  roomObjects?: RoomObject[]
}

export const VERSION = 1
const schema: RxJsonSchema<any> = {
  title: 'section schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 64 },
    room: { type: 'string' },
    color: { type: 'string' },
    order: { type: 'number' },
  },
  required: ['_id'],
} as const satisfies RxJsonSchema<any>

export const Partition = {} as RxCollection<Partition>

export const createPartitionCollection = async <T>(database: RxDatabase<T>) => {
  return await createCollection({
    database,
    collection: Partition,
    collectionName: PARTITION_COLLECTION_NAME,
    version: VERSION,
    schema: schema,
  });
}

Object.assign(window, { Partition }) // Make available global
