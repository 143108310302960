import type { RxDatabase } from 'rxdb'

import { CommitAction } from '@/pos/OrderType'

import { conflictHandler } from './conflict'
import { Order, ORDER_COLLECTION_NAME, orderSchema, PAID_ORDER_COLLECTION_NAME, PaidOrder, type OrderDocMethods, type OrderDocument } from './Order'
import { createCollection } from './utils/migration'
import { paidOrderConflictHandler } from "@/data/utils/paid-order-conflict.ts";

// export const getOrderCollection: () => OrderCollection = () => OrderCollection;
const orderDocMethodsFactory: <T>(database: RxDatabase<T>) => OrderDocMethods = database => ({
  async getItemsCommits(this: OrderDocument) {
    // @ts-expect-error TODO: add typing for this
    const Product = database.products
    const itemsCommits = this.toMutableJSON().commits
    for (const itemsCommit of itemsCommits || []) {
      if (itemsCommit.action === CommitAction.ADD_PRODUCT) {
        const product = await Product.findOne({ selector: { id: itemsCommit.ref } }).exec()
        itemsCommit.productRef = product?.toMutableJSON()
      }
    }
    return itemsCommits
  },
})

export const createOrderCollection = async <T>(database: RxDatabase<T>): Promise<typeof Order> => {
  return await createCollection({
    database,
    collection: Order,
    collectionName: ORDER_COLLECTION_NAME,
    version: orderSchema.version,
    schema: orderSchema,
    methods: orderDocMethodsFactory(database),
    conflictHandler: conflictHandler,
  })
}

export const createPaidOrderCollection = async <T>(database: RxDatabase<T>): Promise<typeof PaidOrder> => {
  return await createCollection({
    database,
    collection: PaidOrder,
    collectionName: PAID_ORDER_COLLECTION_NAME,
    version: orderSchema.version,
    schema: orderSchema,
    methods: orderDocMethodsFactory(database),
    conflictHandler: paidOrderConflictHandler,
  })
}
