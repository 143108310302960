import _ from "lodash";
import dayjs, { type Dayjs } from "dayjs";
import type { EodReportItem, TaxReport } from "@/pos/logic/ReportType.ts";
import { invoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import type { GroupPrinter } from "@/data/GroupPrinter.ts";
import { showMoreInfo, showProductSold } from "@/react/EndOfDayView/EndOfDayView.tsx";
import Debug from 'debug';
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { orders1 } from "@/react/OrderHistoryView/OrderHistoryView.logic.tsx";
import { OrderStatus } from "@/pos/OrderType.ts";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from "@/shared/printer/types"
import { countReportTotal } from "@/pos/logic/report-utils.ts";
import { orderConfig } from "@/shared/order/order-config.ts";

const debug = Debug("printer:z-report")

export async function printEod(eodReport: EodReportItem | undefined, z: number, date: Dayjs): Promise<ScriptedRaster | undefined> {
  if (!eodReport) {
    debug("eodReport is not defined");
    return;
  }

  const LL = LL2()
  const {
    // reportDate,
    // firstOrderDateString,
    // lastOrderDateString,
    totalDiscount: discount,
    totalCashlessSalesExcludingTip,
    totalCashSalesExcludingCashback,
    cancelledItemReport,
    voucherReport,
    dineInReport,
    takeAwayReport,
    groupItemsByCategory,
    sumByCategory,
    // showProductSold,
    // showMoreInfo,
    // country,
    sumByPayment,
    dineInQuantity,
    takeAwayQuantity,
    totalDiscountByLabel,
    avgItemPrice,
    avgItemInOrder,
    avgOrderPrice,
    avgDineInSale,
    avgTakeAwaySale,
    reportByHours,
    totalServiceFee,
    report,
    totalTip,
    totalDebitorAmount,
    paymentReport,
    totalShippingFee,
    totalShippingTip,
    totalDonation
  } = eodReport;
  const companyName = companyInfo0()?.name;
  const companyAddress = companyInfo0()?.address;
  const companyTel = companyInfo0()?.telephone;
  const companyVatNumber = companyInfo0()?.taxNumber;
  const country = companyInfo0()?.country;

  const {tax, net, gross} = countReportTotal(report)

  const {vTaxSum, vTaxComponents, from, to} = report;
  const reportDate = date.format(LL.dates.dateFormat());
  const firstOrderDateString = dayjs.unix(from).format(LL.dates.dateFormat());
  const lastOrderDateString = dayjs.unix(to).format(LL.dates.dateFormat());
  const groupPrinter = invoiceGroupPrinters0()[0] as GroupPrinter;
  const printer = new VPrinter({...invoiceGroupPrinters0()[0].printers[0]});
  const cancelledOrders = orders1().filter(order => order.status === OrderStatus.CANCELLED).length

  await printer.alignCenter();
  await printer.bold(true);
  await printer.println(companyName!);
  await printer.bold(false);
  await printer.setTextNormal();
  await printer.println(companyAddress!);
  if (companyTel) await printer.println(`${LL.printing.tel()}: ${companyTel}`);
  if (companyVatNumber) await printer.println(`${LL.printing.vatRegNo()}: ${companyVatNumber}`);

  await printer.newLine();
  await printer.setTextDoubleHeight();
  await printer.bold(true);
  await printer.println(LL.printing.zReport());

  await printer.newLine();
  await printer.bold(false);
  await printer.setTextNormal();
  await printer.alignLeft();
  await printer.println(`${LL.printing.reportDate()}: ${reportDate}`);
  await printer.println(`${LL.printing.zNumber()}: ${z}`);
  await printer.println(`${LL.printing.firstOrder()}: ${firstOrderDateString}`);
  await printer.println(`${LL.printing.lastOrder()}: ${lastOrderDateString}`);
  await printer.println(`${LL.printing.printDate()}: ${dayjs().format(LL.dates.dateFormat())}`);

  await printer.bold(true);
  await printer.drawLine();

  await printer.println(LL.printing.totalSale());
  await printer.bold(false);
  // await printer.leftRight(LL.printing.total(), LL.format.currency(vTaxSum.gross || 0));
  // await printer.leftRight(LL.printing.subTotal(), LL.format.currency(vTaxSum.net || 0));
  await printer.leftRight(LL.printing.total(), LL.format.currency(gross || 0));
  await printer.leftRight(LL.printing.subTotal(), LL.format.currency(net || 0));
  if (totalServiceFee)
    await printer.leftRight(LL.printing.serviceFee(), `${LL.format.currency(totalServiceFee || 0)}`);
  // await printer.leftRight(LL.printing.tax(), LL.format.currency(vTaxSum.tax || 0));
  await printer.leftRight(LL.printing.tax(), LL.format.currency(tax || 0));
  // if (isTaxCombo(report.vTaxComponents, groupType)) {
  // }
  await printer.bold(true);
  await printer.drawLine();

  await printer.bold(false);

  if (!orderConfig.useTaxComponents) {
    const groupTypes = Object.keys(vTaxSum.vTaxSum || {});
    for (let i = 0; i < groupTypes.length; i++) {
      const groupType = groupTypes[i];
      await printer.println(`${LL.printing.tax()} (${groupType}%)`);
      const { gross = 0, net = 0, tax = 0 } = vTaxSum?.vTaxSum?.[groupType] || {};
      await printer.leftRight(LL.printing.total(), LL.format.currency(gross));
      await printer.leftRight(LL.printing.subTotal(), LL.format.currency(net));
      await printer.leftRight(LL.printing.tax(), LL.format.currency(tax));
      await printer.newLine();
    }
  }

  if (orderConfig.useTaxComponents) {
    if (vTaxComponents && !_.isEmpty(vTaxComponents)) {
      for (const key of Object.keys(vTaxComponents)) {
        await printer.leftRight(key, LL.format.currency(vTaxComponents[key]));
      }
      await printer.newLine();
    }
  }

  await printer.bold(false);
  await printer.leftRight(LL.printing.voucherUsed(), `${LL.format.currency(voucherReport.redeemedTotal)}`);
  await printer.leftRight(LL.printing.voucherSold(), `${LL.format.currency(voucherReport.soldTotal)}`);

  if (showMoreInfo()) {
    await printer.drawLine()
    await printer.println(LL.printing.discount())
    for (const discountLabel of Object.keys(totalDiscountByLabel)) {
      await printer.leftRight(discountLabel, `${totalDiscountByLabel[discountLabel]}`)
    }
  } else {
    await printer.leftRight(LL.printing.discount(), `${LL.format.currency(discount || 0)}`);
  }
  await printer.bold(false);

  //region payment
  await printer.drawLine();

  if (country !== 'de') {
    await printer.bold(true)
    await printer.println(LL.report.payments());
    await printer.bold(false)
  }

  for (const paymentMethod of Object.keys(paymentReport)) {
    // @ts-expect-error TODO: fix typings
    await printer.leftRight(_.capitalize(LL.payment[paymentMethod]() || paymentMethod), `${LL.format.currency(paymentReport?.[paymentMethod]?.excludeCashback || 0)}`)
  }

  await printer.drawLine()
  await printer.leftRight(LL.printing.totalTip(), `${LL.format.currency(totalTip || 0)}`);
  await printer.drawLine()

  if (country !== 'de') {
    await printer.bold(true)
    await printer.println(LL.report.otherFees());
    await printer.bold(false)
    await printer.leftRight(LL.report.shippingFee(), LL.format.currency(totalShippingFee || 0))
    await printer.leftRight(LL.report.shippingTip(), LL.format.currency(totalShippingTip || 0))
    await printer.leftRight(LL.report.donation(), LL.format.currency(totalDonation || 0))
    await printer.drawLine();
  }

  if (companyInfo0()?.country !== 'de') {
    await printer.leftRight(LL.report.totalCashSalesExcludingCashback(), `${LL.format.currency(totalCashSalesExcludingCashback || 0)}`);
    await printer.leftRight(LL.report.totalCashlessSalesExcludingTip(), `${LL.format.currency(totalCashlessSalesExcludingTip || 0)}`);
    if (totalDebitorAmount) {
      await printer.leftRight(LL.report.debtAmount(), LL.format.currency(totalDebitorAmount || 0))
    }
    await printer.drawLine();
  }

  //endregion

  //cancellation:
  await printer.leftRight(`${LL.printing.cancelledTotal()}:`, `${LL.format.currency(cancelledItemReport)}`);
  await printer.leftRight(`${LL.printing.cancelledOrders()}:`, `${cancelledOrders}`);
  await printer.drawLine();

  //dineIn, takeAway:
  if (showMoreInfo()) {
    await printer.leftRight(LL.printing.dineIn(), `${dineInQuantity}`)
  } else {
    await printer.println(LL.printing.dineIn());
  }
  const { gross: dineInGross, net: dineInNet, tax: dineInTax } = countReportTotal(dineInReport as Partial<TaxReport>);

  await printer.leftRight(LL.printing.total(), LL.format.currency(dineInReport ? dineInGross : 0));
  await printer.leftRight(LL.printing.subTotal(), LL.format.currency(dineInReport ? dineInNet : 0));
  await printer.leftRight(LL.printing.tax(), LL.format.currency(dineInReport ? dineInTax : 0));



  await printer.newLine();
  if (showMoreInfo()) {
    await printer.leftRight(LL.printing.takeAway(), `${takeAwayQuantity}`)
  } else {
    await printer.println(LL.printing.takeAway());
  }

  const { gross: takeAwayGross, net: takeAwayNet, tax: takeAwayTax } = countReportTotal(takeAwayReport as Partial<TaxReport>);
  await printer.leftRight(LL.printing.total(), LL.format.currency(takeAwayReport ? takeAwayGross : 0));
  await printer.leftRight(LL.printing.subTotal(), LL.format.currency(takeAwayReport ? takeAwayNet : 0));
  await printer.leftRight(LL.printing.tax(), LL.format.currency(takeAwayReport ? takeAwayTax : 0));

  await printer.drawLine();
  await printer.bold(false)

  //
  if (showMoreInfo()) {
    await printer.println(LL.report.averagesBreakdown())
    await printer.leftRight(LL.report.avgItemPrice(), `${avgItemPrice}`)
    await printer.leftRight(LL.report.avgItemsPerOrder(), `${avgItemInOrder}`)
    await printer.leftRight(LL.report.avgOrderPrice(), `${avgOrderPrice}`)
    await printer.leftRight(LL.report.dineInSales(), `${_.get(dineInReport, 'gross', 0)}`)
    await printer.leftRight(LL.report.avgDineInSale(), `${avgDineInSale}`)
    await printer.leftRight(LL.report.takeAwaySales(), `${_.get(takeAwayReport, 'gross', 0)}`)
    await printer.leftRight(LL.report.avgTakeAwaySale(), `${avgTakeAwaySale}`)
    await printer.drawLine()
    const reportByHoursTableData = Object.keys(reportByHours).map(period => (
      [{ text: period }, { text: `${reportByHours[period].orderCnt}` }, { text: `${LL.format.currency(reportByHours[period].total)}` }]))
    await printer.advancedTableCustom({
      metaData: {
        colMetaData: [
          { align: 'LEFT' },
          { align: 'LEFT', priority: 'HIGH', padding: 0.2 },
          { align: 'LEFT', priority: 'HIGH' }],
        rowMetaData: []
      },
      data: reportByHoursTableData
    }, true)
    await printer.drawLine()
  }

  await printer.leftRight(LL.orderHistory.totalRefund(), LL.format.currency(eodReport.totalRefund));
  if (showProductSold()) {
    await printer.drawLine();
    for (const category of Object.keys(groupItemsByCategory)) {
      await printer.println(`${category || LL.printing.noCategory} (${LL.format.currency(sumByCategory[category] || 0)}):`)
      for (const itemName of Object.keys(groupItemsByCategory[category])) {
        const item = {
          name: itemName,
          ...groupItemsByCategory[category][itemName]
        }
        if (item.quantity && item.name) await printer.println(`  ${item.quantity} x ${item.name} (${LL.format.currency(item.vSum)})`)
      }
      await printer.newLine()
    }
  }

  const raster = await printer.getRaster()
  if (!raster) throw new Error('Failed to get raster from printer for EOD report!')
  return raster
}
