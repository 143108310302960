import { FunctionComponent } from "react";

export type TableManualKeyboardVType = {
  className?: string;
  showTooltips?: boolean;
  isSelected?: boolean;
  showNumber?: boolean;
  isDefault?: boolean;
  isEnter?: boolean;
  istext?: boolean;
};

const TableManualKeyboardV: FunctionComponent<TableManualKeyboardVType> = ({
  className = "",
  showTooltips,
  isSelected,
  showNumber,
  isDefault,
  isEnter,
  istext,
}) => {
  return (
    <div
      className={`w-full !m-[0] absolute right-[0px] bottom-[0px] left-[0px] bg-gray-solid-gray-180-ebecee h-[216px] hidden flex-col items-start justify-start gap-[12px] z-[4] text-center text-3xl text-black-solid-black-900-1e1e23 font-mulish ${className}`}
    >
      <div className="w-[480px] flex-1 [backdrop-filter:blur(30px)] rounded bg-gray-solid-gray-180-ebecee hidden flex-col items-center justify-center py-2 px-2.5 box-border gap-[8px]">
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] mq600:gap-[4px] mq432:gap-[4px]">
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">Q</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver1@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">W</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver11@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">E</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver12@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">R</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver13@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">T</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver14@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">Y</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver1@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">U</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver11@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">I</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver12@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">O</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver13@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">P</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver14@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-3 gap-[4px] mq600:gap-[4px] mq600:pl-6 mq600:pr-6 mq600:box-border mq432:gap-[4px] mq432:pl-6 mq432:pr-6 mq432:box-border mq360:pl-3 mq360:pr-3 mq360:box-border">
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">A</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver15@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">S</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver16@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">D</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver17@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">F</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver18@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">G</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver19@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">H</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver110@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">J</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver111@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">K</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver112@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">L</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver113@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] mq600:gap-[12px] mq432:gap-[4px] mq360:gap-[4px]">
          <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center py-0 px-4 lg:pl-7 lg:pr-7 lg:box-border md:pl-7 md:pr-7 md:box-border mq600:pl-5 mq600:pr-5 mq600:box-border mq360:pl-3 mq360:pr-3 mq360:box-border">
            <img
              className="w-[18.9px] relative h-[16.5px]"
              alt=""
              src="/shift.svg"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[4px] mq600:gap-[4px] mq432:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">Z</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver114@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">X</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver115@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">C</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver116@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">V</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver117@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">B</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver118@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">N</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver14@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">M</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver119@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-0 px-4 lg:pl-7 lg:pr-7 lg:box-border md:pl-7 md:pr-7 md:box-border mq600:pl-5 mq600:pr-5 mq600:box-border mq360:pl-3 mq360:pr-3 mq360:box-border">
            <img
              className="w-[22.7px] relative h-[17px]"
              alt=""
              src="/delete-button1.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] mq600:gap-[4px] mq432:gap-[4px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] mq600:gap-[4px] mq432:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex flex-col items-center justify-center text-base">
              <div className="relative mq600:text-xs">123</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver111@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">,</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver16@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative sm:text-base">EN</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver110@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-base">
            <div className="relative mq360:text-mid">Space</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver19@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] mq600:gap-[4px] mq432:gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">.</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver19@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center text-white-solid-white-100-ffffff">
              <div className="relative sm:text-base">Add</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver16@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex-1 [backdrop-filter:blur(30px)] rounded bg-gray-solid-gray-180-ebecee flex flex-col items-center justify-center py-2 px-2.5 gap-[8px]">
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">Q</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver120@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">W</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver121@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">E</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver122@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">R</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver123@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">T</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver124@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">Z</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver125@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">U</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver126@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">I</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver127@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">O</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver128@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">P</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver129@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative mq360:text-mid">Ü</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver130@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">A</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver120@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-start relative gap-[2px] text-white-solid-white-100-ffffff">
            <div className="!m-[0] absolute top-[-50px] left-[0px] shadow-[0px_2px_4px_rgba(0,_0,_0,_0.22)] rounded-8xs-6 bg-white-solid-white-120-fcfcfe box-border h-12 flex flex-row items-center justify-center py-0 px-2 gap-[6px] z-[1] border-[0.3px] border-solid border-gray-solid-gray-450-b6b6b6">
              <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                <div className="w-3.5 relative hidden z-[1]">S</div>
                <div className="relative text-black-solid-black-900-1e1e23 z-[2]">
                  S
                </div>
              </div>
              <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb z-[0]" />
                <div className="relative z-[1]">ß</div>
                <div className="w-3.5 relative text-black-solid-black-1000-000000 hidden z-[2]">
                  ß
                </div>
              </div>
              <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                <div className="w-3.5 relative hidden z-[1]">Ś</div>
                <div className="relative text-black-solid-black-900-1e1e23 z-[2]">
                  Ś
                </div>
              </div>
              <div className="rounded-10xs flex flex-row items-center justify-center py-1 px-2 relative gap-[2px]">
                <div className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-480-4175fb hidden z-[0]" />
                <div className="w-3.5 relative hidden z-[1]">Š</div>
                <div className="relative text-black-solid-black-900-1e1e23 z-[2]">
                  Š
                </div>
              </div>
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center z-[0] text-black-solid-black-900-1e1e23">
              <div className="relative">S</div>
            </div>
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">D</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver122@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">F</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver123@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">G</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver124@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">H</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver125@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">J</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver126@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">K</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver127@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">L</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver128@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative mq360:text-mid">Ö</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver129@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative mq360:text-mid">Ä</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver130@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px] lg:gap-[28px] md:gap-[22px] mq600:gap-[8px] mq432:gap-[4px]">
          <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center py-3 px-4 lg:pl-7 lg:pr-7 lg:box-border md:pl-7 md:pr-7 md:box-border mq432:pl-4 mq432:pr-4 mq432:box-border mq360:pl-3 mq360:pr-3 mq360:box-border">
            <img
              className="w-[18.9px] relative h-[16.5px]"
              alt=""
              src="/shift1.svg"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center py-0 px-0.5 gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
              <div className="relative mq360:text-mid">Y</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver131@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">X</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver132@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">C</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver133@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">V</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver134@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">B</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver122@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">N</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver135@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">M</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver136@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-3 px-4 lg:pl-7 lg:pr-7 lg:box-border md:pl-7 md:pr-7 md:box-border mq432:pl-4 mq432:pr-4 mq432:box-border mq360:pl-3 mq360:pr-3 mq360:box-border">
            <img
              className="w-[22.7px] relative h-[17px]"
              alt=""
              src="/delete-button2.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] text-base">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex flex-col items-center justify-center">
              <div className="relative mq600:text-xs">123</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver137@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-3xl">
              <div className="relative mq360:text-mid">,</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver138@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="h-5 relative inline-block sm:text-base">DE</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver139@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative tracking-[-0.32px] leading-[20px]">
              Leerzeichen
            </div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver125@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] text-3xl">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center mq414:max-w-[36px]">
              <div className="relative mq360:text-mid">.</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver125@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center text-3xs text-white-solid-white-100-ffffff">
              <div className="w-0 relative hidden sm:text-3xs mq432:text-3xs" />
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconkeyboardenter-icon-ver138@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[480px] flex-1 [backdrop-filter:blur(30px)] rounded bg-gray-solid-gray-180-ebecee hidden flex-col items-center justify-center py-2 px-2.5 box-border gap-[8px]">
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">1</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver1@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">2</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver11@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">3</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver12@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">4</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver13@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">5</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver14@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">6</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver1@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">7</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver11@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">8</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver12@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">9</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver13@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">0</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver14@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">-</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver111@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">/</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver16@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">:</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver110@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">(</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver15@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">)</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver19@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
            <div className="relative mq360:text-mid">$</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver113@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative mq360:text-mid">€</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver18@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative mq360:text-mid">£</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver112@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-1000-000000">
            <div className="relative mq360:text-mid">¥</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver17@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[8px]">
          <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center py-0 px-4 lg:pl-7 lg:pr-7 lg:box-border md:pl-7 md:pr-7 md:box-border mq600:pl-4 mq600:pr-4 mq600:box-border">
            <div className="relative mq360:text-mid">@</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver12@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px] text-black-solid-black-1000-000000">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">{`&`}</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver140@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">#</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver14@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">%</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver141@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">?</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver142@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-black-solid-black-900-1e1e23">
              <div className="relative mq360:text-mid">!</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver13@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_#e0e4ec,_#b8bbc2)] flex flex-col items-center justify-center py-0 px-4 lg:pl-7 lg:pr-7 lg:box-border md:pl-7 md:pr-7 md:box-border mq600:pl-4 mq600:pr-4 mq600:box-border">
            <img
              className="w-[22.7px] relative h-[17px]"
              alt=""
              src="/delete-button3.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 [background:linear-gradient(180deg,_rgba(227,_240,_255,_0.42),_rgba(174,_183,_193,_0.42))] flex flex-col items-center justify-center text-base">
              <div className="relative mq600:text-xs">123</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver111@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">,</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver16@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative sm:text-base">EN</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver110@2x.png"
              />
            </div>
          </div>
          <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center text-base">
            <div className="relative mq360:text-mid">Space</div>
            <img
              className="w-6 relative h-6 object-cover hidden"
              alt=""
              src="/iconkeyboardenter-icon-ver19@2x.png"
            />
          </div>
          <div className="self-stretch flex-1 flex flex-row items-center justify-center gap-[4px]">
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-white-solid-white-120-fcfcfe flex flex-col items-center justify-center">
              <div className="relative mq360:text-mid">.</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver19@2x.png"
              />
            </div>
            <div className="self-stretch flex-1 shadow-[0px_1px_0px_#898a8d] rounded-8xs-6 bg-blue-solid-blue-350-2196f3 flex flex-col items-center justify-center text-white-solid-white-100-ffffff">
              <div className="relative sm:text-base">Add</div>
              <img
                className="w-6 relative h-6 object-cover hidden"
                alt=""
                src="/iconkeyboardenter-icon-ver16@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableManualKeyboardV;
