import _ from 'lodash'

import { TaxCategory } from '@/data/TaxCategory'
import { computed, type Accessor, effectOn } from '@/react/core/reactive.ts'
import { generateSignalDataAccess } from '@/react/utils/generateSignalDataAccess'
import { DEFAULT_QUEBEC_TAX_RATES } from '@/srm/lib/constants'
import { orderConfig } from "@/shared/order/order-config.ts";

export const [taxCategories0, makeTaxCategoriesAvailable] = generateSignalDataAccess(TaxCategory, {
  convertOpts: {
    autoSave: true,
    preSaved: taxCategory => {
      if (taxCategory.type === 'combo') {
        return {
          ...taxCategory,
          value: _.sumBy(taxCategory.components, comp => +(comp.value || 0)),
        }
      }
      return taxCategory
    }
  },
})

effectOn([taxCategories0], () => {
  if (taxCategories0().some(a => a.type === 'combo')) {
    orderConfig.useTaxComponents = true
  }
})


export const taxCategoriesVM = computed(() => {
  return taxCategories0()
})

window.taxCategories0 = taxCategories0

//used to prevent duplicating taxes in edit menu, so that this won't affect edit flow
export const dineInTaxCategories0 = computed(() => taxCategories0().filter(tax => tax.type === 'food:dineIn' || tax.type === 'drink:dineIn'))
export const takeAwayTaxCategories0 = computed(() => taxCategories0().filter(tax => tax.type === 'drink:takeAway' || tax.type === 'food:takeAway'))
export const defaultQuebecTax0: Accessor<typeof DEFAULT_QUEBEC_TAX_RATES> = computed(() => {
  const defaultTax = taxCategories0().find(tax => tax.type === 'combo' && tax.name === 'Quebec')
  return {
    TPS: defaultTax?.components?.find(c => c.printLabel === 'TPS')?.value ?? DEFAULT_QUEBEC_TAX_RATES.TPS,
    TVQ: defaultTax?.components?.find(c => c.printLabel === 'TVQ')?.value ?? DEFAULT_QUEBEC_TAX_RATES.TVQ,
  }
})

export const getTotalTaxFromComponents = (components: TaxCategory['components']) => {
  return _.sumBy(components, comp => +(comp.value || 0))
}
