import { type FunctionComponent, useState, useCallback } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import EditMenuKeyboardPosition from "../Popups/EditMenuKeyboardPosition.tsx";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import {LL0} from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { changeExtraColumns, extraColumns, resetKeyboard } from '@/react/EditMenuView/EditMenuView.tsx';
import {makeRipple} from "@/react/core/ripple-utils.ts";
import { keyboardConfig0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const KeyboardSettingForm: FunctionComponent = () => {
  const [isEditMenuKeyboardPositionOpen, setEditMenuKeyboardPositionOpen] =
    useState(false);

  const openEditMenuKeyboardPosition = useCallback(() => {
    userFLow(`open edit menu keyboard position`, {
      username: loginUser()?.name
    });
    setEditMenuKeyboardPositionOpen(true);
  }, []);

  const closeEditMenuKeyboardPosition = useCallback(() => {
    userFLow(`close edit menu keyboard position`, {
      username: loginUser()?.name
    });
    setEditMenuKeyboardPositionOpen(false);
  }, []);

  return (
    <>
      <div className="self-stretch flex flex-col items-start justify-start py-4 px-3 gap-[16px] text-left text-mini text-black-solid-black-900-1e1e23 font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
          <b className="relative sm:text-sm Tablet_600:text-smi">
            {LL0().restaurant.menuEdit.keyboardPosition()}
          </b>
          <div
            className="rounded-sm bg-gray-solid-gray-130-f4f4f4 box-border h-[37px] flex flex-row items-center justify-center py-0 px-3 gap-[8px] cursor-pointer text-center text-sm text-black-solid-black-1000-000000 border-[1px] border-solid border-gainsboro"
            onClick={openEditMenuKeyboardPosition} ref={makeRipple}
          >
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconposition-icon@2x.png"
            />
            <div className="relative font-semibold">{LL0().ui.choose()}</div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
          <b className="relative sm:text-sm Tablet_600:text-smi">
            {LL0().restaurant.menuEdit.expansionColumn()}
          </b>
          <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-base text-black-solid-black-600-424242">
            <div className="flex-1 rounded-sm box-border h-10 flex flex-row items-center justify-between border-[1px] border-solid border-gainsboro">
              <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-r-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
                onClick={() => changeExtraColumns(extraColumns() - 1)}
                ref={makeRipple}
              >
                <img
                  className="w-6 relative h-6 object-cover"
                  alt=""
                  src="/iconsteper-minus@2x.png"
                />
              </div>
              <b className="relative md:text-xs">{extraColumns()}</b>
              <div className="box-border w-12 h-10 flex flex-row items-center justify-center border-l-[1px] border-solid border-gray-solid-gray-200-e8e8e8"
                   onPointerDown={() => changeExtraColumns(extraColumns() + 1)}
                   ref={makeRipple}
              >
                <img
                  className="relative w-6 h-6 object-cover"
                  alt=""
                  src="/iconstepper-plus@2x.png"
                />
              </div>
            </div>
            <div className="rounded-sm bg-gray-solid-gray-130-f4f4f4 box-border h-10 flex flex-row items-center justify-center py-0 px-3 gap-[8px] text-center text-sm text-black-solid-black-1000-000000 border-[1px] border-solid border-gainsboro sm:pl-2 sm:pr-2 sm:box-border"
                 onClick={resetKeyboard}
                 ref={makeRipple}
            >
              <img
                className="relative w-6 h-6 object-cover sm:hidden"
                alt=""
                src="/iconreset-icon@2x.png"
              />
              <div className="relative font-semibold">{LL0().ui.reset()}</div>
            </div>
          </div>
          <FormControlLabel
            className="self-stretch"
            label={LL0().settings.keyboard.activeKeyboard()}
            control={<Checkbox color="primary" checked={keyboardConfig0()?.active || false}
                               onChange={(e, checked:boolean)=> {
                                 userFLow(`active keyboard ${checked}`, {
                                   username: loginUser()?.name
                                 });
                                 _.assign(keyboardConfig0(), { active: checked })
                               }}/>}

          />
          <FormControlLabel
            className="self-stretch"
            label={LL0().settings.keyboard.onlyShowInFirstPage()}
            control={<Checkbox color="primary" checked={keyboardConfig0()?.onlyShowInFirstPage || false}
                               onChange={(e, checked:boolean)=> {
                                 userFLow(`only Show In First Page ${checked}`, {
                                   username: loginUser()?.name
                                 });
                                 _.assign(keyboardConfig0(), { onlyShowInFirstPage: checked })
                               }}/>}

          />
          <FormControlLabel
            className="self-stretch"
            label={LL0().editMenu.showXButton()}
            control={<Checkbox color="primary" checked={keyboardConfig0()?.x || false}
                               onChange={(e, checked:boolean)=> {
                                 userFLow(`show x button ${checked}`, {
                                   username: loginUser()?.name
                                 });
                                 _.assign(keyboardConfig0(), { x: checked })
                               }}/>}

          />
        </div>
      </div>
      {isEditMenuKeyboardPositionOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuKeyboardPosition}
        >
          <EditMenuKeyboardPosition onClose={closeEditMenuKeyboardPosition} />
        </PortalPopup>
      )}
    </>
  );
};

export default KeyboardSettingForm;
