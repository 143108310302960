import React, { type FunctionComponent } from 'react';
import { LL0 } from "@/react/core/I18nService.tsx";
import { order0 } from "@/react/OrderView/OrderViewShare";
import _ from "lodash";
import { setInputControllers } from "@/react/OrderView/OrderView.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";


type NoteOrderPopupType = {
  onClose?: () => void;

};

const OrderGroupPopup: FunctionComponent<NoteOrderPopupType> = ({
  onClose
}) => {

  const [newV, setNewV] = useSignal<string | undefined>(order0()?.note);

  return (
    <div className="relative bg-white w-full h-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[24px] mq600:p-2 mq600:gap-1 min-w-[120px] max-w-full text-left text-base text-blue-solid-blue-500-0051c1 font-mulish">
      <div className="overflow-y-auto no-scrollbar self-stretch flex-1 flex flex-col items-start justify-start gap-[16px] z-[0]">
        <b className="self-stretch relative">{LL0().reservation.note()}</b>

        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] text-center text-sm text-black-solid-black-880-1d1d26">
          <Input
            className={'w-full'}
            value={newV() || ''}
            onChange={(e: string) => setNewV(e)}
            refInputController={i => {
              i.setFocus(true)
              i.moveCaretToEnd()
              return setInputControllers?.([i]);
            }}
          />
        </div>
        <TableManualKeyboard
          value={''}
          onEnter={() => {
            userFLow(`add note ${newV()}`, {
              username: loginUser()?.name
            });
            console.log('newV', newV())
            _.assign(order0(), { note: newV() })
            onClose?.()
          }}
          inputController={inputController0}
        />
      </div>
      <img
        className="w-6 absolute !m-[0] top-[12px] right-[12px] h-6 overflow-hidden shrink-0 object-cover cursor-pointer z-[2]"
        alt=""
        src="/icong-close-popup-icon--grey12@2x.png"
        onClick={() => {
          onClose?.()
        }}
      />
    </div>
  );
};

export default OrderGroupPopup;