import _ from "lodash";
import clsx from "clsx";
import React, { type FunctionComponent, useEffect, useState } from "react";
import { products0 } from "@/data/ProductHub";
import { useComputed, useSignal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService";
import TextField from "@/react/core/TextField.tsx";
import { unmapProduct, mapProduct, productMatchesGroupByProvider } from "@/react/PendingOrder/ProductMatchingLogic.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PopupMatchProduct from "@pending-order/ProductMatching/PopupMatchProduct.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type Props = {
  onClose?: () => void;
}

const Component: FunctionComponent<Props> = ({ onClose }) => {
  const LL = LL0();


  const renderOriginal = (original: any) => (
    <>
      <p><b>{original.id || original.sku}</b></p>
      <p>{original.name}</p>
    </>
  )

  const renderProduct = (productId: string) => {
    const product = products0().find((item: any) => item._id === productId);
    return (
      <>
        <p><b>{product?.id}</b></p>
        <p>{product?.name}</p>
      </>
    )
  }

  const providerNames = useComputed(() => Object.keys(productMatchesGroupByProvider()))
  const [provider, setProvider] = useSignal<string>('')
  useEffect(() => {
    if (providerNames().length) {
      setProvider(providerNames()[0])
    }
  }, []);
  const [searchText, setSearchText] = useSignal<string>('')
  const [selectedProduct, setSelectedProduct] = useSignal();
  const [isProductMatchingDialogOpen, setIsProductMatchingDialogOpen] = useState(false);

  const openProductMatchDialog = function (onlineOrderProduct: any) {
    userFLow(`open product match dialog`, {
      username: loginUser()?.name
    });
    setSelectedProduct(onlineOrderProduct)
    setIsProductMatchingDialogOpen(true)
  }

  const closeProductMatchDialog = function () {
    userFLow(`close product match dialog`, {
      username: loginUser()?.name
    });
    setIsProductMatchingDialogOpen(false)
  }

  const saveProductMatchResult = async function({productId}: {productId: string}) {
     await mapProduct(provider(), selectedProduct(), productId)
  }

  const renderProviderTabs = () => (
    <div className="flex flex-row">
      {
        _.map(providerNames(), p => (
          <div
            className={clsx(['px-2 py-2 cursor-pointer hover:bg-[#aaa]', provider() === p ? 'bg-[#bbb]' : 'bg-white'])}
            key={p}
            onClick={() => {
              userFLow(`set provider ${p}`, {
                username: loginUser()?.name
              });
              setProvider(p)
            }}>
            <b className="uppercase">{p}</b>
          </div>
        ))
      }
      <div className="flex-grow"></div>
      <div>
        <TextField
          type="string"
          className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
          color="primary"
          size="small"
          fullWidth={true}
          variant="outlined"
          onChange={e => setSearchText(e.target.value)}
          value={searchText()}
          placeholder="Search..."
          sx={{ '& .MuiInputBase-root': { height: '37px' } }}
        />
      </div>
    </div>
  )

  const productMapDocsOfSelectedProvider = useComputed(() => productMatchesGroupByProvider()[provider()])
  const filteredProductMapDocsOfSelectedProvider = useComputed(() => {
    const txt = searchText()
    if (_.isEmpty(txt))
      return productMapDocsOfSelectedProvider()

    return productMapDocsOfSelectedProvider().filter(
      doc => doc.externalProductKey?.includes(txt) || doc.original?.name?.includes(txt)
    )
  })

  const renderProductsMapTable = () => (
    <div style={{ height: '380px', overflowY: 'auto' }}>
      {
        <table style={{ width: '100%' }}>
          <thead>
          <tr className="sticky top-0" style={{ borderBottom: '1px solid #bbb', backgroundColor: '#bbb' }}>
            <th className="px-2 py-2">Online</th>
            <th>POS</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {
            _.map(filteredProductMapDocsOfSelectedProvider(), ({
                _id,
                externalProductKey,
                original,
                productId
              }, index) => (
                <tr key={`${index}__${externalProductKey}`} className="odd:bg-white even:bg-[#ddd]">
                  <td className={clsx(['px-2 py-1'])}>{renderOriginal(original || {})}</td>
                  <td>{renderProduct(productId)}</td>
                  <td className="w-[90px]">
                    <div className="flex flex-row gap-[6px]">
                      <div className="cursor-pointer bg-yellow-100 px-2 py-2 text-right"
                           onClick={() => openProductMatchDialog(original)}>
                        <img alt="edit" className="w-[20px] h-[20px]" src="/iconshift-icon@2x.png" />
                      </div>
                      <div className="cursor-pointer bg-red-100 px-2 py-2 text-right" onClick={() => unmapProduct(_id)}>
                        <img alt="delete" className="w-[20px] h-[20px]" src="/iconbin-icon--red11@2x.png" />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            )
          }
          </tbody>
        </table>
      }
    </div>
  )

  const renderBody = () => (
    <div className="w-full">
      {renderProviderTabs()}
      {renderProductsMapTable()}
    </div>
  )

  const renderFooter = () => (
    <div className="w-full flex flex-row items-center justify-end gap-2 px-2 py-2"
         style={{ borderTop: '1px solid #ddd' }}>
      <div className="px-4 py-2 cursor-pointer rounded"
           onClick={onClose}>
        {LL.ui.close()}
      </div>
    </div>
  )

  return (
    <div
      className="relative overflow-hidden bg-basic-color-white flex flex-col w-full box-border h-full max-w-full min-w-[600px] min-h-[460px] max-h-full text-left text-sm text-gray font-mulish"
      style={{ border: '1px solid #888' }}>
      <div className="w-full flex flex-row flex-1">
        {renderBody()}
      </div>
      {renderFooter()}

      {isProductMatchingDialogOpen &&
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeProductMatchDialog}
        >
          <PopupMatchProduct
            onClose={closeProductMatchDialog}
            onSave={saveProductMatchResult}
            product={selectedProduct()}
          />
        </PortalPopup>
      }
    </div>
  )
}

export default Component