import { type FunctionComponent, useCallback } from 'react';
import { happyHour0 } from '@/react/HappyHourView/HappyHourView.tsx';
import _ from 'lodash';
import {LL0} from "@/react/core/I18nService.tsx";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type HappyHourWeekdayItemType = {
  weekday?: string;
  isSelected?: boolean;

  weekdayIndex: number;
};

const HappyHourWeekdayItem: FunctionComponent<HappyHourWeekdayItemType> = ({
  weekday = "Monday",
  isSelected = true,
  weekdayIndex
}) => {
  isSelected = happyHour0()?.days?.includes(weekdayIndex);

  const onClick = useCallback(() => {
    //toggle weekday
    let newWeekdayList = happyHour0()?.days || []
    userFLow(`toggle weekend happy hours ${newWeekdayList}`, {
      username: loginUser()?.name
    })
    if (happyHour0()?.days?.includes(weekdayIndex)) {
      const dayIndexInList = newWeekdayList.indexOf(weekdayIndex);
      newWeekdayList.splice(dayIndexInList, 1);
    } else {
      newWeekdayList = [...newWeekdayList, weekdayIndex];
    }
    _.assign(happyHour0(), { days: newWeekdayList })
  }, [])

  return (
    <div className="rounded-10xs bg-gray-solid-gray-130-f4f4f4 box-border w-32 h-[30px] overflow-hidden flex flex-row items-center justify-center py-[10.5px] px-5 relative gap-[10px] text-left text-mini text-black-solid-black-880-1d1d26 font-mulish border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
      onPointerDown={onClick}
    >
      {isSelected && (
        <div className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-10xs bg-blue-solid-blue-30-e3f2fd box-border z-[0] border-[1px] border-solid border-cyan-solid-cyan-450-90caf9" />
      )}
      <div className="relative z-[1] Tablet_768:text-smi">{_.get(LL0().settings.happyHour.daysOfWeek, weekday)?.()}</div>
    </div>
  );
};

export default HappyHourWeekdayItem;
