import debug from 'debug'

import { logOut } from '@/data/UserHub'
import { isQuebecSrmEnabled, posSetting0 } from '@/data/PosSettingsSignal.ts';
import { wakeUpEE } from '@/shared/wakeUpEE.ts';

const log = debug('data:srm')

declare global {
  interface Window {
    pos_onWakeup: (eslapsed: number) => void
  }
}

window.pos_onWakeup = onWakeup

async function onWakeup(eslapsed: number) {
  log(`⚡️ [Native] app just come to foreground after ${eslapsed}ms!`)
  wakeUpEE.emit('wakedUp');
  const { autoLogOutWhenCloseApp } = posSetting0()?.generalSetting ?? {}
  if (isQuebecSrmEnabled() && autoLogOutWhenCloseApp) {
    log(`[autoLogOutWhenCloseApp] logging out...`)
    logOut().then();
  }

}