import { FunctionComponent } from "react";
import voucherInfo from "@voucher/VoucherInfo.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import dayjs from "dayjs";
import { LL2 } from "@/react/core/I18nBackend.tsx";

export interface VoucherInfo {
  code?: string,
  amount?: string,
  price?: string,
  firstName?: string,
  lastName?: string,
  expiryDate?: any,
  value?: string,
  used?: string
}

export type ConfirmApplyVoucherPopupPluginType = {
  className?: string;
  onClose?: () => void;
  voucherInfo?: VoucherInfo;
  handleRedeemVoucher?: any
};


const ConfirmApplyVoucherPopupPlugin: FunctionComponent<
  ConfirmApplyVoucherPopupPluginType
> = ({ className = "", onClose,voucherInfo,handleRedeemVoucher }) => {
  return (
    <div
      className={`w-full h-full relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start pt-4 px-0 pb-0 box-border gap-3 max-w-full max-h-full text-left text-sm text-gray-solid-gray-930-3c3c3c font-mulish ${className}`}
    >
      <div className="self-stretch overflow-hidden flex flex-row items-center justify-end py-0 px-4">
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start py-0 px-3">
        <div className="self-stretch rounded border-gray-solid-gray-380-cdcdcd border-[1px] border-solid flex flex-col items-start justify-start p-3 gap-2">
          <div className="self-stretch flex flex-col items-start justify-start gap-1 text-blue-solid-blue-0057ff">
            <div className="self-stretch relative text-mini font-medium">
              {voucherInfo?.code}
            </div>
            <div className="self-stretch relative font-semibold text-black-solid-black-900-1e1e23">
              {voucherInfo?.firstName}
            </div>
            <div className="self-stretch relative font-medium text-gray-solid-gray-930-546073">
              {voucherInfo?.lastName}
            </div>
          </div>
          <div className="self-stretch relative border-gray-solid-gray-460-9ca3af border-[1px] border-dashed box-border h-px" />
          <div className="self-stretch flex flex-row items-start justify-start gap-1">
            <i className="relative">Expiry Date:</i>
            <i className="relative">{voucherInfo?.expiryDate ? dayjs.unix(voucherInfo?.expiryDate).format(`${LL2().dates.dateFormat()} ${LL2().dates.timeFormat()}`) : '--'}</i>
          </div>
          <div className="self-stretch relative border-gray-solid-gray-460-9ca3af border-[1px] border-dashed box-border h-px" />
          <div className="self-stretch flex flex-row items-start justify-between flex-wrap content-start">
            <div className="flex flex-row items-start justify-start pt-0 px-0 pb-1 gap-1">
              <i className="relative">Voucher Price:</i>
              <i className="relative">{voucherInfo?.price}</i>
            </div>
            <div className="flex flex-row items-start justify-start pt-0 px-0 pb-1 gap-1">
              <i className="relative">Voucher Value:</i>
              <i className="relative">{voucherInfo?.value}</i>
            </div>
            <div className="flex flex-row items-start justify-start pt-0 px-0 pb-1 gap-1">
              <i className="relative">Used:</i>
              <i className="relative">{voucherInfo?.used}</i>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start pt-2 px-4 pb-3 gap-3 text-center text-blue-solid-blue-420-2979ff">
        <div
          className="flex-1 rounded-md border-blue-solid-blue-420-2979ff border-[1px] border-solid overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer"
          onClick={onClose}
        >
          <b className="relative">Cancel</b>
        </div>
        <div
          ref={makeRipple}
          onClick={handleRedeemVoucher}
          className="flex-1 rounded-md bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff">
          <b className="relative">Apply</b>
        </div>
      </div>
    </div>
  );
};

export default ConfirmApplyVoucherPopupPlugin;
