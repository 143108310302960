import dayjs from 'dayjs'
import _ from 'lodash'
import uuid from 'time-uuid'

import { getMaxIdZ } from '@/data/MaxIdHub'
import { Order, PaidOrder } from '@/data/Order'

import { OrderStatus, TseMethod, type OrderPayment, type OrderStrip } from '../OrderType'
import { createOrder, stripOrder, stripPaidOrder } from './order-reactive'
import { clearPayment, handleAddPayment } from './order-utils'
import { now } from './time-provider'

export async function makeCancelOrder(order: Order, cb?: (o: Order) => void) {
  let _order = _.cloneDeep(order)
  delete _order.id
  _order._id = uuid()
  delete _order.qrCode
  _order.refOrder = order._id
  _order.refOrderId = order.id
  _order.status = OrderStatus.CANCELLATION_REFERENCE
  for (const item of _order.items) {
    item.quantity = -item.quantity
  }
  _order.cancellationItems = []
  _order.date = dayjs(now()).unix()

  const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!))
  _order.id = id
  _order.z = z

  _order = createOrder(_order)
  //fixme: check if it is multiple payment or not
  //fixme: multiple payment => cash
  //fixme: single payment => using that payment
  const payments = Array.isArray(order.payments) ? order.payments : Object.values(order.payments || {})

  clearPayment(_order)
  //todo: use last payment ??
  //todo: what will happen if cancel order is multiple payment but don't pay by cash???
  handleAddPayment(payments as OrderPayment[], _order)
  // await recordOrderCommits(_order)
  const stripOrder = stripPaidOrder(_order)
  await PaidOrder.insert(stripOrder)
  if (cb) cb(_order)
  return _order
}

export async function makeCancelOrderNoTse(order: Order) {
  let _order = _.cloneDeep(order)
  for (const item of _order.items) {
    item.quantity = -item.quantity
  }
  _order = createOrder(_order)
  const payments = Array.isArray(order.payments) ? order.payments : Object.values(order?.payments || {})
  clearPayment(_order)
  //todo: use last payment ??
  handleAddPayment(payments as OrderPayment[], _order)

  return _order
}

export function remakeReactiveOrder(order: OrderStrip, removeCancellationItems: boolean) {
  const rawOrder = _.assign(_.cloneDeep(order), removeCancellationItems ? { cancellationItems: [] } : {})
  for (const item of rawOrder.items!) {
    item.printed = true
  }
  const _order = createOrder(rawOrder)
  return _order
}

export function reCreateOrder(order: Order) {
  let _order: Order

  return {
    get order() {
      return _order
    },
    internalCreateOrder,
    recordCommits,
    insertOrder,
  }

  //region reCreateOrder.utils
  async function internalCreateOrder() {
    //re-make id, qrcode, all tseMethod = TseMethod.apply, payment v.v
    //todo: commit base ?
    _order = _.cloneDeep(order)
    delete _order.id
    _order._id = uuid()
    delete _order.qrCode

    _order.cancellationItems = []
    _order.date = dayjs(now()).unix()
    const { id, z } = await getMaxIdZ(dayjs.unix(order.vDate!))
    _order.id = id
    _order.z = z
    _order.preventReset = true
    _order.items.forEach(i => ([i.tseMethod, i.date] = [TseMethod.apply, dayjs(now()).unix()]))
    ;[_order.tseMethod, _order.date] = [TseMethod.apply, dayjs(now()).unix()]
    _order = createOrder(_order)
  }

  async function insertOrder(isPaidOrder = true) {
    if (isPaidOrder) return await PaidOrder.insert(stripPaidOrder(_order))
    await Order.insert(stripOrder(_order))
  }

  async function recordCommits() {
    // await recordOrderCommits(_order)
  }
  //endregion
}
