import { type FunctionComponent, useCallback, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { deleteTerminal, terminal0 } from '@/react/PaymentSettingView/PaymentSettingView.tsx';
import _ from 'lodash';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { TerminalTypes } from "@/data/Terminal.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import TextField from "@/react/core/TextField.tsx";
import TaxAndPaymentDeleteConfi from '@tax-payment/Popups/TaxAndPaymentDeleteConfi.tsx';
import CloverTerminalConfiguration from './CloverTerminalConfiguration.tsx'
import ZvtTerminalConfiguration from './ZvtTerminalConfiguration.tsx'
import CCVA920TerminalConfiguration from './CCVA920TerminalConfiguration.tsx'

export type NewEditTerminalPopupType = {
  mode: string
  onClose?: () => void;
  onComplete?: () => void;
};

const NewEditTerminalPopup: FunctionComponent<NewEditTerminalPopupType> = ({
  mode,
  onClose,
  onComplete
}) => {
  const [isTaxAndPaymentDeleteConfiOpen, setTaxAndPaymentDeleteConfiOpen] =
    useState(false);

  const openTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(true);
  }, []);

  const closeTaxAndPaymentDeleteConfi = useCallback(() => {
    setTaxAndPaymentDeleteConfiOpen(false);
  }, []);


  return (
    <>
      <div className="new-terminal-popup bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-start justify-between py-8 px-6 box-border relative min-w-[580px] max-w-full max-h-full text-left text-sm text-gray-300 font-mulish height_414:h-auto">
        <div className="self-stretch overflow-y-auto flex flex-col items-start justify-start pt-0 px-0 pb-3 gap-[12px] z-[0]">
          <b className="self-stretch relative text-mini text-text-color-black-1e1e23">
            {LL0().taxPayment.linkToCreditCardReaderDevice()}
          </b>
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
            <div className="relative">{LL0().ui.name()}</div>
            <TextField
              className="self-stretch"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              value={terminal0()?.name || ""}
              onChange={e => _.assign(terminal0(), { name: e.target.value })}
            />
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
            <div className="relative">{LL0().article.type()}</div>
            <FormControl className="self-stretch" variant="outlined">
              <InputLabel color="primary" />
              <Select color="primary" size="small" value={terminal0()?.type || ''}
                      onChange={e => _.assign(terminal0(), { type: e.target.value })}
              >
                {_.entries(TerminalTypes).map(([value, label], index) =>
                  <MenuItem value={value} key={index}>{label}</MenuItem>)}
              </Select>
              <FormHelperText />
            </FormControl>
          </div>

          {terminal0()?.type === 'Clover' && <CloverTerminalConfiguration/>}
          {terminal0()?.type === 'Zvt' && <ZvtTerminalConfiguration/>}
          {terminal0()?.type === 'CCVA920' && <CCVA920TerminalConfiguration/>}

        </div>
        <div className="self-stretch bg-basic-color-white-solid-white-100-ffffff flex flex-row items-center justify-end pt-4 px-0 pb-0 gap-[0px_12px] z-[1] text-center text-tomato">
          {terminal0()?._id && <div
            className="rounded-md bg-basic-color-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 gap-[0px_3px] cursor-pointer border-[1px] border-solid border-tomato"
            onClick={openTaxAndPaymentDeleteConfi}
          >
            <img
              className="relative w-[17px] h-[17px] object-cover"
              alt=""
              src="/iconbin-icon--red@2x.png"
            />
            <b className="relative">{LL0().ui.delete()}</b>
          </div>}
          <div className="!text-white rounded-md bg-royalblue-100 min-h-[36px] h-full flex flex-row items-center justify-center py-0 px-5 box-border text-mini text-basic-color-white-solid-white-100-ffffff"
               onClick={onComplete}
          >
            <div className="relative font-semibold">{mode === 'edit' ? LL0().ui.save() : LL0().ui.add()}</div>
          </div>
        </div>
        <img
          className="absolute !m-[0] top-[8px] right-[8px] w-[21px] h-[21px] object-cover cursor-pointer z-[2]"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
      {isTaxAndPaymentDeleteConfiOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeTaxAndPaymentDeleteConfi}
        >
          <TaxAndPaymentDeleteConfi onClose={closeTaxAndPaymentDeleteConfi} onComplete={() => deleteTerminal(terminal0())}/>
        </PortalPopup>
      )}
    </>
  );
};

export default NewEditTerminalPopup;