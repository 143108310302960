import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'

import { cn } from '@/lib/utils'
import SeatTitle from '@/components/ui/SeatTitle.tsx'
import { clsx } from 'clsx'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({className, ...props}, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("w-full", className)}
    {...props}
  />
))
AccordionItem.displayName = "AccordionItem"

enum Color {
  default = '#c9e2ff',
  selected = '#2196f3'
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> &
  { text: string, isPaid: boolean, price?: number, addItem?: (_seat?: string) => void }
>(({className, children, text, isPaid, price, addItem}, ref) => (
  <AccordionPrimitive.Header className="flex w-full">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={clsx('flex-1 transition-all [&[data-state=open]_img.up-arrow]:rotate-90',
        '[&[data-state=closed]_div.cover-div]:hidden [&[data-state=closed]_b.first-b]:text-[#1E1E23] [&[data-state=open]_img.h-3]:flex')}
    >
      <SeatTitle
        seatNumber={text}
        isPaid={isPaid}
        price={price}
        addItem={addItem}
      />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({className, children, ...props}, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={cn(
      "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
      className
    )}
    {...props}
  >
    <div className="self-stretch flex flex-col items-center justify-start py-0 px-1">
      <div
        className="self-stretch rounded-md bg-white flex flex-col items-start justify-start p-px border-[1px] border-solid border-lightgray-200">
        {children}
      </div>
    </div>
    {/*<div className="pb-4 pt-0"></div>*/}
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export {Accordion, AccordionItem, AccordionTrigger, AccordionContent}
