import type { RxCollection, RxJsonSchema } from 'rxdb'

import { ONLINE_PROVIDER_COLLECTION_NAME } from '@/data/OnlineProvider'

export const MENU_COLLECTION_NAME = 'menus'

export enum MenuTypes {
  ONLINE = 'online',
  LOCAL = 'local',
  // TODO: add more menu types here
}

export interface Menu {
  _id: string
  name?: string
  type?: MenuTypes
  rows?: number;
  columns?: number;
  maxColumn?: number;
  providers?: Array<string>
  position?: number
}

export const menuSchema: RxJsonSchema<Menu> = {
  title: 'menu schema',
  version: 4, // update this when schema change
  primaryKey: '_id',
  type: 'object',
  properties: {
    _id: { type: 'string', maxLength: 24 },
    name: { type: 'string', maxLength: 64 },
    type: { type: 'enum', enum: ['online', 'local'] },
    rows: {type: 'number'},
    columns: {type: 'number'},
    maxColumn: {type: 'number'},
    providers: { type: 'array', items: { type: 'string' }, uniqueItems: true, ref: ONLINE_PROVIDER_COLLECTION_NAME },
    position: { type: 'number' },
  },
  required: ['_id'],
}

export const Menu = {} as RxCollection<Menu>

Object.assign(window, { Menu }) // Make available global
