import type { EodReportItem } from '@/pos/logic/ReportType.ts'
import dayjs, { type Dayjs } from 'dayjs'
import { showProductSold } from '@/react/EndOfDayView/EndOfDayView.tsx'
import _ from 'lodash'
import Debug from 'debug';
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { cashPaymentName0 } from "@/data/PaymentHub.ts";
import { VPrinter } from "@/react/Printer/VPrinter.ts";
import { invoiceGroupPrinters0 } from "@/data/GroupPrinterHub.ts";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from '@/shared/printer/types'
import { countReportTotal } from "@/pos/logic/report-utils.ts";

const debug = Debug("printer:x-report")

export const printXReport = async (eodReport?: EodReportItem, date?: Dayjs): Promise<ScriptedRaster | undefined> => {
  if (!eodReport) {
    debug("eodReport is not defined");
    return
  }

  const LL = LL2()

  const {
    from, to,
    report,
    totalTip,
    totalServiceFee,
    sumByPayment,
    sumByCategory,
    groupItemsByCategory,
    totalCashSalesExcludingCashback,
    totalCashlessSalesExcludingTip,
    totalCashlessSales,
    totalDebitorAmount,
    paymentReport,
    totalShippingFee,
    totalShippingTip,
    totalDonation
  } = eodReport;
  const reportDate = date?.format(LL.dates.dateFormat());
  const firstOrderDateString = dayjs.unix(report.from).format(LL.dates.dateFormat());
  const lastOrderDateString = dayjs.unix(report.to).format(LL.dates.dateFormat());

  const {
    name,
    address,
    telephone,
    taxNumber,
    country
  } = companyInfo0()

  if (!country) return
  const {vTaxSum, vTaxComponents, vDiscount = 0} = report;

  const printer = new VPrinter({...invoiceGroupPrinters0()[0].printers[0]});

  await printer.alignCenter();
  await printer.setTextDoubleHeight();
  await printer.bold(true);
  await printer.println(name!);

  await printer.bold(false);
  await printer.setTextNormal();
  await printer.println(address!);
  if (telephone) await printer.println(`${LL.printing.tel()}: ${telephone}`);
  if (taxNumber) await printer.println(`${LL.printing.vatRegNo()}: ${taxNumber}`);

  await printer.newLine();
  await printer.setTextDoubleHeight();
  await printer.bold(true);
  await printer.println(LL.printing.xReport());

  await printer.newLine();
  await printer.setTextNormal();
  await printer.alignLeft();
  await printer.bold(true);
  await printer.println(`${LL.printing.reportDate()}: ${reportDate}`);
  await printer.bold(false);
  await printer.println(`${LL.printing.firstOrder()}: ${firstOrderDateString}`);
  await printer.println(`${LL.printing.lastOrder()}: ${lastOrderDateString}`);
  await printer.println(`${LL.printing.printDate()}: ${dayjs().format(LL.dates.dateFormat())}`);
  await printer.bold(true);
  await printer.drawLine();

  await printer.println(LL.printing.sales());
  if (report) {
    await printer.bold(false);

    const {tax, net, gross} = countReportTotal(report)

    // await printer.leftRight(LL.printing.total(), LL.format.currency(vTaxSum.gross));
    // await printer.leftRight(LL.printing.subTotal(), LL.format.currency(vTaxSum.net));
    await printer.leftRight(LL.printing.total(), LL.format.currency(gross));
    await printer.leftRight(LL.printing.subTotal(), LL.format.currency(net));
    if (totalServiceFee)
      await printer.leftRight(LL.printing.serviceFee(), `${LL.format.currency(totalServiceFee)}`)
    await printer.leftRight(LL.printing.tax(), LL.format.currency(tax));

    if (vTaxComponents && !_.isEmpty(vTaxComponents)) {
      await printer.newLine();
      for (const key of Object.keys(vTaxComponents)) {
        await printer.leftRight(key, LL.format.currency(vTaxComponents[key]));
      }
    }

    await printer.bold(true);
    await printer.drawLine();

    await printer.bold(false);

    if (country === 'de') { // Canada | "Vietnam" | Germany | "United States"
      for (const key in vTaxSum.vTaxSum) {
        await printer.println(`${LL.printing.tax()} ${key}%:`);
        const {gross = 0, net = 0, tax = 0} = vTaxSum?.vTaxSum?.[key] || {};
        await printer.leftRight(LL.printing.total(), LL.format.currency(gross));
        await printer.leftRight(LL.printing.subTotal(), LL.format.currency(net));
        await printer.leftRight(LL.printing.tax(), LL.format.currency(tax));
        await printer.newLine();
      }
    }

    await printer.leftRight(LL.printing.discount(), LL.format.currency(vDiscount));
  }

  await printer.drawLine();

  // const cashPayment = sumByPayment && (sumByPayment[LL.payment.cash()] || sumByPayment[_.lowerCase(LL.payment.cash())])
  // const cashPayment = sumByPayment && Object.keys(sumByPayment).filter(type => type === cashPaymentName0())?.length > 0
  // if (cashPayment) {
  //   await printer.leftRight(LL.report.totalCashSales(), LL.format.currency(totalCashSalesExcludingCashback || 0))
  // }

  // const nonCashPayment = Object.keys(paymentReport).filter(type => type !== cashPaymentName0())

  if (country !== 'de') {
    await printer.leftRight(LL.report.totalCashSalesExcludingCashback(), LL.format.currency(totalCashSalesExcludingCashback || 0))
    await printer.leftRight(LL.report.totalCashlessSalesExcludingTip(), LL.format.currency(totalCashlessSalesExcludingTip || 0))
    if (totalDebitorAmount) {
      await printer.leftRight(LL.report.debtAmount(), LL.format.currency(totalDebitorAmount || 0))
    }
    await printer.drawLine();
  }

  if (country !== 'de') {
    await printer.bold(true)
    await printer.println(LL.report.payments());
    await printer.bold(false)
    // const cashPay = Object.keys(sumByPayment).filter(type => type === cashPaymentName0());
    // for (const paymentMethod of cashPay) {
    //   const paymentValue = paymentReport[paymentMethod]?.excludeCashback;
    //   // @ts-expect-error
    //   await printer.leftRight(_.capitalize(LL.payment[paymentMethod]() || paymentMethod), LL.format.currency(paymentValue || 0))
    // }
  }


  for (const paymentMethod of Object.keys(paymentReport)) {
    // @ts-expect-error TODO: fix typings
    await printer.leftRight(_.capitalize(LL.payment[paymentMethod]() || paymentMethod), `${LL.format.currency(paymentReport?.[paymentMethod]?.excludeCashback || 0)}`)
  }

  // for (const paymentMethod of nonCashPayment) {
  //   const paymentValue = paymentReport[paymentMethod]?.excludeCashback;
  //   // @ts-expect-error TODO: Fix this typing
  //   await printer.leftRight(_.capitalize(LL.payment[paymentMethod]() || paymentMethod), LL.format.currency(paymentValue || 0))
  // }

  await printer.drawLine();
  await printer.leftRight(LL.printing.totalTip(), `${LL.format.currency(totalTip || 0)}`)
  await printer.drawLine();

  if (country !== 'de') {
    await printer.bold(true)
    await printer.println(LL.report.otherFees());
    await printer.bold(false)
    await printer.leftRight(LL.report.shippingFee(), LL.format.currency(totalShippingFee || 0))
    await printer.leftRight(LL.report.shippingTip(), LL.format.currency(totalShippingTip || 0))
    await printer.leftRight(LL.report.donation(), LL.format.currency(totalDonation || 0))
    await printer.drawLine();
  }

  await printer.leftRight(LL.report.totalRefund(), LL.format.currency(eodReport.totalRefund));
  await printer.drawLine()
  await printer.bold(false)
  if (showProductSold())
    for(const category of Object.keys(groupItemsByCategory)) {
      await printer.println(`${category || LL.printing.noCategory()} (${LL.format.currency(sumByCategory[category] || 0)}):`)
      for(const itemName of Object.keys(groupItemsByCategory[category])) {
        const item = {
          name: itemName,
          ...groupItemsByCategory[category][itemName]
        }
        if (item.quantity) await printer.println(`  ${item.quantity} x ${item.name} (${LL.format.currency(item.vSum || 0)})`)
      }
      await printer.newLine()
    }

  const raster = await printer.getRaster()
  return raster
}
