import dialogService from "@/react/SystemService/dialogService.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import dayjs from "dayjs";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export const showDateNotComplete = async (date: string) => {
  const _date = dayjs(date).format(LL2().dates.dateFormat());
  const dialogDateNotComplete = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-2 px-4 pb-4 box-border gap-[32px_0px] min-w-[320px] h-full max-w-full max-h-full text-center text-[18px] font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch text-[20px] text-firebrick  relative leading-[28px]">{LL0().report.cannotPrintZ()}</b>
          <b className="self-stretch relative leading-[28px]">{LL0().report.printDateNotComplete({date: _date})}</b>
        </div>
        <div
          className="self-stretch flex justify-end text-base ">
          <button
            className="!h-full !rounded !bg-indianred box-border !w-[70px] !text-basic-color-white border-[1px] !border-solid !border-indianred"
            onClick={onClose}
            type="button"
          >
            <b className="relative uppercase">{LL0().ui.ok()}</b>
          </button>
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: dialogDateNotComplete })
}
export const confirmRePrintZ = async (date: string, onConfirm: () => void) => {
  const showDialog = ({ onClose }) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-2 px-4 pb-4 box-border gap-[32px_0px] min-w-[320px] h-full max-w-full max-h-full text-center text-[18px] font-mulish">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px]">{LL0().report.confirmReprintZ({date})}</b>
        </div>
        <div className="self-end flex flex-row gap-2">
          <div className="self-stretch flex justify-end text-base py-2">
            <button
              className="!h-full py-2 !rounded bg-white border-[1px] !w-auto px-2 !text-basic-color-white"
              onClick={() => {
                userFLow(`close reprint x report`, {
                  username: loginUser()?.name
                });
                onClose()
              }}
              type="button"
            >
              <b className="relative text-black">{LL0().ui.cancel()}</b>
            </button>
          </div>
          <div className="self-stretch flex justify-end text-base py-2">
            <button
              className="!h-full py-2 !rounded !bg-indianred box-border !w-[70px] !text-basic-color-white border-[1px] !border-solid !border-indianred"
              onClick={() => {
                userFLow(`reprint x report for ${date}`, {
                  username: loginUser()?.name
                });
                onConfirm();
                onClose();
              }}
              type="button"
            >
              <b className="relative ">{LL0().ui.ok()}</b>
            </button>
          </div>
        </div>
      </div>
    </dialogService.BaseDialog>
  );

  await dialogService.show({ component: showDialog });
};
