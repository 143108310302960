import { clsx } from 'clsx';
import { useCallback, useState } from 'react';
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { deleteKioskPhoto, imageLoadingState, kioskImage, onChooseImg } from "@/react/Developer/OTSKiosk.logic.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";


const DeleteConfirmationPopup = ({ onClose, onComplete }) => {
  return (
    <div className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center pt-8 px-6 pb-6 box-border gap-[32px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px] text-firebrick font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <b className="self-stretch relative leading-[28px]">{LL0().masterMachine.deleteConfirmation()}</b>
        <div className="self-stretch relative text-base leading-[28px] font-medium text-black-solid-black-880-1d1d26">{LL0().masterMachine.doYouWantToDelete()}</div>
      </div>
      <div className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-royalblue">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-royalblue"
          onClick={onClose}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="flex-1 rounded-md bg-royalblue overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
          onClick={() => {
            onComplete?.()
            onClose?.()
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  )
}

const OTSKiosk = () => {
  const [isUploadImagePopupOpen, setUploadAPhotoPopupOpen] = useState(false)
  const [imageId, setImageId] = useState<string | undefined>('')

  const openUploadAPhotoPopup = useCallback(() => {
    setUploadAPhotoPopupOpen(true)
  }, [])

  const closeUploadImagePopup = useCallback(() => {
    setUploadAPhotoPopupOpen(false)
  }, [])

  return (
    <>
      <input
        type="file"
        className="hidden"
        id="uploadLogoKiosk"
      />
      <input
        type="file"
        className="hidden"
        id="uploadCoverPhotoKiosk"
      />
      <input
        type="file"
        className="hidden"
        id="uploadBannerPhotoKiosk"
      />
      <div className="flex flex-col gap-4">
        <div className="flex-1 flex flex-col gap-2">
          <p className="text-[18px] font-bold text-[#1D1D26]">{LL0().settings.logo()}</p>
          <div className="w-auto flex flex-row items-center justify-start gap-[12px] text-center text-xs text-purple-solid-purple-454586">
            <div
              onClick={async () => {
                await onChooseImg('logo')
              }}
              className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[6px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]"
            >
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconupload-icon@2x.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start sm:gap-[8px] Tablet_768:gap-[2px]">
                <div className="self-stretch relative font-semibold md:text-sm sm:text-sm Tablet_768:text-smi">{LL0().editOnlineMenu.uploadImage()}</div>
              </div>
            </div>
            {imageLoadingState()?.logo ? (
              <div className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]">
                <div className=" border-gray-300 h-5 w-5 animate-spin rounded-full border-8 border-t-blue-600" />
              </div>
            ) : kioskImage().logo ? (
              <div
                onClick={async () => {
                  await onChooseImg('logo')
                }}
                className={clsx(
                  'w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]'
                )}
              >
                <img
                  className="w-[81px] relative h-[81px] object-cover"
                  alt=""
                  src={kioskImage().logo?.data || '/public/edit-image@3x.png'}
                />
                <img
                  className="w-[30px] absolute h-[30px] object-cover"
                  alt=""
                  src={'/iconedit-img-icon@2x.png'}
                />
              </div>
            ) : null}
            {kioskImage().logo && (
              <div
                className="bg-white text-[#FF4836] text-[14px] font-bold flex justify-center items-center px-4 h-10 border-[1px] rounded-[4px] border-[#FF4836]"
                ref={makeRipple}
                onClick={() => {
                  setImageId(kioskImage()?.logo?._id)
                  openUploadAPhotoPopup()
                }}
              >
                {LL0().ui.delete()}
              </div>
            )}
          </div>
          <p className="italic text-[14px] text-[#1E1E23]">{LL0().kiosk.uploadFile5MB()}</p>
          <p className="italic text-[14px] text-[#F55349]">{LL0().kiosk.invalidFile()}</p>
          <div className="h-1 border-t-[1px] border-t-[#DBDBDB]" />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex-1 flex flex-col gap-2">
          <p className="text-[18px] font-bold text-[#1D1D26]">{LL0().kiosk.coverPhoto()}</p>
          <div className="w-auto flex flex-row items-center justify-start gap-[12px] text-center text-xs text-purple-solid-purple-454586">
            <div
              onClick={async () => {
                await onChooseImg('coverPhoto')
              }}
              className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[6px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]"
            >
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconupload-icon@2x.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start sm:gap-[8px] Tablet_768:gap-[2px]">
                <div className="self-stretch relative font-semibold md:text-sm sm:text-sm Tablet_768:text-smi">{LL0().editOnlineMenu.uploadImage()}</div>
              </div>
            </div>
            {imageLoadingState()?.coverPhoto ? (
              <div className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]">
                <div className=" border-gray-300 h-5 w-5 animate-spin rounded-full border-8 border-t-blue-600" />
              </div>
            ) : kioskImage().coverPhoto ? (
              <div
                onClick={async () => {
                  await onChooseImg('coverPhoto')
                }}
                className={clsx(
                  'w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]'
                )}
              >
                <img
                  className="w-[81px] relative h-[81px] object-cover"
                  alt=""
                  src={kioskImage().coverPhoto?.data || '/public/edit-image@3x.png'}
                />
                <img
                  className="w-[30px] absolute h-[30px] object-cover"
                  alt=""
                  src={'/iconedit-img-icon@2x.png'}
                />
              </div>
            ) : null}
            {kioskImage().coverPhoto && (
              <div
                className="bg-white text-[#FF4836] text-[14px] font-bold flex justify-center items-center px-4 h-10 border-[1px] rounded-[4px] border-[#FF4836]"
                ref={makeRipple}
                onClick={() => {
                  setImageId(kioskImage()?.coverPhoto?._id)
                  openUploadAPhotoPopup()
                }}
              >
                {LL0().ui.delete()}
              </div>
            )}
          </div>
          <p className="italic text-[14px] text-[#1E1E23]">{LL0().kiosk.uploadFile10MB()}</p>
          <p className="italic text-[14px] text-[#F55349]">{LL0().kiosk.invalidFile()}</p>
          <div className="h-1 border-t-[1px] border-t-[#DBDBDB]" />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex-1 flex flex-col gap-2">
          <p className="text-[18px] font-bold text-[#1D1D26]">{LL0().kiosk.bannerPhoto()}</p>
          <div className="w-auto flex flex-row items-center justify-start gap-[12px] text-center text-xs text-purple-solid-purple-454586">
            <div
              onClick={async () => {
                await onChooseImg('bannerPhoto')
              }}
              className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[6px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]"
            >
              <img
                className="w-6 relative h-6 object-cover"
                alt=""
                src="/iconupload-icon@2x.png"
              />
              <div className="self-stretch flex flex-col items-center justify-start sm:gap-[8px] Tablet_768:gap-[2px]">
                <div className="self-stretch relative font-semibold md:text-sm sm:text-sm Tablet_768:text-smi">{LL0().editOnlineMenu.uploadImage()}</div>
              </div>
            </div>
            {imageLoadingState()?.bannerPhoto ? (
              <div className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]">
                <div className=" border-gray-300 h-5 w-5 animate-spin rounded-full border-8 border-t-blue-600" />
              </div>
            ) : kioskImage().bannerPhoto ? (
              <div
                onClick={async () => {
                  await onChooseImg('bannerPhoto')
                }}
                className={clsx(
                  'w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]'
                )}
              >
                <img
                  className="w-[81px] relative h-[81px] object-cover"
                  alt=""
                  src={kioskImage().bannerPhoto?.data || '/public/edit-image@3x.png'}
                />
                <img
                  className="w-[30px] absolute h-[30px] object-cover"
                  alt=""
                  src={'/iconedit-img-icon@2x.png'}
                />
              </div>
            ) : null}
            {kioskImage().bannerPhoto && (
              <div
                className="bg-white text-[#FF4836] text-[14px] font-bold flex justify-center items-center px-4 h-10 border-[1px] rounded-[4px] border-[#FF4836]"
                ref={makeRipple}
                onClick={() => {
                  setImageId(kioskImage()?.bannerPhoto?._id)
                  openUploadAPhotoPopup()
                }}
              >
                {LL0().ui.delete()}
              </div>
            )}
          </div>
          <p className="italic text-[14px] text-[#1E1E23]">{LL0().kiosk.uploadFile35MB()}</p>
          <p className="italic text-[14px] text-[#F55349]">{LL0().kiosk.invalidFile()}</p>
          <div className="h-1 border-t-[1px] border-t-[#DBDBDB]" />
        </div>
      </div>
      {isUploadImagePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeUploadImagePopup}
        >
          <DeleteConfirmationPopup onClose={closeUploadImagePopup} onComplete={() => deleteKioskPhoto(imageId)} />
        </PortalPopup>
      )}
    </>
  )
}

export default OTSKiosk
