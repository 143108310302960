import dialogService from "@/react/SystemService/dialogService.tsx";
import clsx from "clsx";

export const dialogModeUpdate = async (os: string, onUpdate: (mode: string) => void) => {
  const showDialog = ({ onClose }: any) => (
    <dialogService.BaseDialog title={""} onClose={onClose}>
      <div
        className='w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center box-border gap-[24px] min-w-[280px] pb-4 h-full max-w-full max-h-full text-center text-xl text-red-solid-red-550-cf2222 font-mulish'
      >
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <b className="self-stretch relative leading-[28px]">
            Select mode update:
          </b>
        </div>
        <div
          className="w-full flex flex-row items-center justify-center gap-[12px] max-w-[312px] text-sm text-blue-solid-blue-420-2979ff">
          {os === 'ios' &&
            <div
              className="flex-1 rounded-md min-h-[55px] bg-white box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-3 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
              onClick={() => {
                onUpdate('app_store')
                onClose()
              }}
            >
              <b className="relative">Update AppStore</b>
            </div>}
          <div
            className={clsx("flex-1 rounded-md min-h-[55px] box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-3 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
              , { 'bg-blue-solid-blue-420-2979ff text-white': os === 'ios', 'bg-white-solid-white-100-ffffff': os === 'android' })}
            onClick={() => {
              onUpdate('s3')
              onClose()
            }}
          >
            <b className="relative">Update s3</b>
          </div>
          {os === 'android' &&
            <div
              className="flex-1  min-h-[55px] rounded-md bg-blue-solid-blue-420-2979ff box-border overflow-hidden flex flex-row items-center justify-center py-[9px] px-3 max-w-[100px] cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
              onClick={() => {
                onUpdate('store')
                onClose()
              }}
            >
              <b className="relative text-white">Update APK</b>
            </div>}
        </div>
      </div>
    </dialogService.BaseDialog>
  )
  await dialogService.show({ component: showDialog })
}
