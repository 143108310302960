import { IconButton, InputAdornment, TextField as TextField0, type TextFieldProps } from "@mui/material";
import { Keyboard } from "@mui/icons-material";
import PortalPopup from "./PortalPopup.tsx";
import { type ChangeEventHandler, useState } from 'react';
import { effect, useSignal } from "@/react/core/reactive.ts";
import Input, { type InputController } from "@/react/core/Input.tsx";
import InputPopup from "@/react/core/InputPopup";
import _ from "lodash";
import clsx from "clsx";
import { useNativeInput } from "@/react/core/NativeInput.ts";

type NumberTextFieldValuePropsType = {
  type: "number";
  value?: number;
}

type StringTextFieldValuePropsType = {
  type?: "string"; // string type ?
  value?: string;
}

type TextFieldValuePropsType = (StringTextFieldValuePropsType | NumberTextFieldValuePropsType) & {
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const textFieldPropsConstructor = ({ type, value, onChange }: TextFieldValuePropsType): {
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
} => {
  switch (type) {
    case "string":
      return {
        value: value || "",
        onChange: onChange
      }
    case 'number':
      return {
        value: value || value === 0 ? value.toString() : "",
        onChange: e => onChange?.({ target: { value: parseFloat(e.target.value) } } as any)
      }
    default:
      return {
        value: value || "",
        onChange: onChange
      }
  }
}


const TextField = (props: Omit<TextFieldProps, "value" | "onChange" | "type"> & {
  onEdit?: () => void;
  showKeyboardIcon?: boolean;
  disabledLabel?: boolean;
  onKeyboardIconClick?: () => void;
} & TextFieldValuePropsType) => {
  //todo: show keyboard icon
  //todo: show pop up
  const { showKeyboardIcon = true, disabledLabel = false, onKeyboardIconClick} = props;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [inputControllers, setInputControllers] = useSignal<Array<InputController>>([]);

  const { value, onChange } = textFieldPropsConstructor(props);

  const _onClick = () => {
    props.onEdit?.();
    const dispose = effect(() => {
      if (inputControllers()[0]) {
        inputControllers()[0]?.setKeys(value?.split("") || []);
        setTimeout(() => {
          inputControllers()[0]?.setFocus(true);
          inputControllers()[0]?.moveCaretToEnd();
          dispose();
        }, 200);
      }
    })
    setIsPopupOpen(true);
  };
  return <>
    {useNativeInput() && <TextField0
      color="primary"
      size="small"
      variant="outlined"
      {..._.omit(props, ["onChange", 'onKeyboardIconClick'], ["label"])}
      value={value}
      onChange={onChange}
      disabled={props.disabled}
      label={disabledLabel ? "" : props.label as string}
      InputProps={showKeyboardIcon ? {
        endAdornment: (
          <InputAdornment position="end" disablePointerEvents={props.disabled}
                          className={clsx(props.disabled && 'opacity-40')}>
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={onKeyboardIconClick ? onKeyboardIconClick : _onClick}
            >
              <Keyboard />
            </IconButton>
          </InputAdornment>
        ),
      } : undefined}
    />}
    {!useNativeInput() && <Input
      className={props.className}
      value={value}
      disabled={props.disabled}
      label={disabledLabel ? "" : props.label as string}
      fullWidth={props.fullWidth}
      onChange={v => onChange?.({ target: { value: v } } as any)}
    >
      {showKeyboardIcon && <div className={'mr-3'}>
        <IconButton
          aria-label="toggle password visibility"
          disabled={props.disabled}
          edge="end"
          onClick={onKeyboardIconClick ? onKeyboardIconClick : _onClick}
        >
          <Keyboard />
        </IconButton>
      </div>}
    </Input>}
    {isPopupOpen && (
      <PortalPopup
        overlayColor="rgba(0, 0, 0, 0.2)"
        placement="Centered"
        onOutsideClick={() => setIsPopupOpen(false)}
      >
        <InputPopup onClose={() => setIsPopupOpen(false)}
                    inputControllers={inputControllers}
                    label={props.label as string}
                    setInputControllers={setInputControllers}
                    onEnter={() => {
                      setIsPopupOpen(false);
                      onChange?.({
                        target: {
                          value: inputControllers()[0].keys().join('')
                        }
                      } as any)
                    }}
                    value={value}
        />
      </PortalPopup>
    )}
  </>
}

export default TextField
