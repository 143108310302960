import type { JsonSchema, RxCollection, RxJsonSchema } from 'rxdb'

import type { SyncMode } from './data-enum'
import type { MenuDirection } from './MenuDirection.ts'

export const DEVICE_SETTING_COLLECTION_NAME = 'device_settings'

export const VERSION = 32

export interface DeviceSrmSetting {
  cert?: string
  certPSI?: string
  privateKey?: string
  publicKey?: string
  trainingMode?: boolean
  /**
   * Identifier of the device on which the SRS is installed or of the terminal used to access the SRS.
   * The identifier is generated and returned by the WEB-SRM with the first "certificates" request.
   */
  deviceId?: string
  /** Last transaction signature */
  lastTransSig?: string

}

export interface ScreenConfig {
  itemCardHeight?: number
  menuRows?: number
  menuColumns?: number
  productFontSize?: number
  textLineHeight?: number
  oneLine?: boolean
  oneLineShorten?: boolean
  categoryHeight?: number
  categoryWidth?: number
  categoryFontSize?: number
  categoryLineHeight?: number
  hideTitleName?: boolean
  scrollableLayout?: boolean
  smallSidebar?: boolean
  menuDirection: MenuDirection
  kitchenFontSize?: number
}

export interface LayoutConfig {
  enableScroll?: boolean,
  enableZoom?: boolean,
  partitionColumns?: number,
  partitionTableHeight?: boolean,
}

export type ScreenConfigDynamicValue = Pick<ScreenConfig, 'itemCardHeight' | 'productFontSize' | 'textLineHeight' | 'categoryHeight' | 'categoryFontSize' | 'categoryWidth' | 'categoryLineHeight'>

export interface DeviceSetting {
  _id?: string
  id?: number
  secondDisplayType?: 'usb' | 'serial' | 'second-display' | 'none'
  show?: boolean
  isSupportDevice?: boolean
  name?: string
  devicePos?: number
  partitionColumns?: number
  partitionTableHeight?: number
  frontendLanguage?: string
  isMaster?: boolean
  ip?: string
  publicIp?: string
  date?: number
  createdAt?: number
  timezone?: string
  forceTimezone?: string
  force?: boolean
  showToolbar?: boolean
  useVirtualKeyboard?: boolean
  printerMapping?: PrinterMappingItem[]
  callSystem?: {
    mode?: 'none' | 'artech' | '56k-modem'
    usbDevice?: {
      devicePath: string
      deviceManufacturerName: string
      deviceProductName: string
      deviceVID: number
      devicePID: number
    }
  }
  customerDisplay?: {
    mode?: string
    usbDevice?: {
      devicePath?: string
      deviceManufacturerName?: string
      deviceProductName?: string
      deviceVID?: string
      devicePID?: string
    }
    serialPort?: string
  }
  srm?: DeviceSrmSetting
  cloudRegister?: boolean
  syncMode: SyncMode
  autoOpenCashDrawer?: boolean
  screenConfig: ScreenConfig
  layoutConfig: LayoutConfig
  updatedAt?: number
  hidePendingOrders?: boolean
}

export const deviceSettingSchema: RxJsonSchema<DeviceSetting> = {
  title: 'deviceSetting schema',
  version: VERSION,
  primaryKey: '_id',
  type: 'object',
  // @ts-expect-error we don't need to define all properties
  properties: {
    _id: { type: 'string', maxLength: 24 },
    id: { type: 'number' },
    secondDisplayType: { type: 'string' },
    devicePos: { type: 'number' },
    createdAt: { type: 'number' },
    partitionColumns: { type: 'number' },
    partitionTableHeight: { type: 'number' },
    show: { type: 'boolean' },
    isSupportDevice: { type: 'boolean' },
    name: { type: 'string' },
    frontendLanguage: { type: 'string' },
    isMaster: { type: 'boolean' },
    ip: { type: 'string' },
    date: { type: 'number', multipleOf: 1, minimum: 0, maximum: 10 ** 10 },
    timezone: { type: 'string' },
    force: { type: 'boolean' },
    useVirtualKeyboard: { type: 'boolean' },
    showToolbar: { type: 'boolean' },
    printerMapping: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          groupPrinter: { type: 'string' },
          position: { type: 'string' },
        },
      },
    },
    callSystem: {
      type: 'object',
      properties: {
        mode: { type: 'string' },
        usbDevice: {
          type: 'object',
          properties: {
            devicePath: { type: 'string' },
            deviceManufacturerName: { type: 'string' },
            deviceProductName: { type: 'string' },
            deviceVID: { type: 'string' },
            devicePID: { type: 'string' },
          },
        },
      },
    },
    customerDisplay: {
      type: 'object',
      properties: {
        mode: { type: 'string' },
        usbDevice: {
          type: 'object',
          properties: {
            devicePath: { type: 'string' },
            deviceManufacturerName: { type: 'string' },
            deviceProductName: { type: 'string' },
            deviceVID: { type: 'string' },
            devicePID: { type: 'string' },
          },
        },
        serialPort: { type: 'string' },
      },
    },
    srm: <JsonSchema<DeviceSrmSetting>>{
      type: 'object',
      properties: {
        cert: { type: 'string' },
        certPSI: { type: 'string' },
        privateKey: { type: 'string' },
        publicKey: { type: 'string' },
        deviceId: { type: 'string' },
        timezone: { type: 'string' },
        timezoneZ: { type: 'string' },
        qstNumber: { type: 'string' },
        gstNumber: { type: 'string' },
        lastTransSig: { type: 'string' },
        trainingMode: { type: 'boolean' },
      },
    },
    cloudRegister: { type: 'boolean' },
    syncMode: { type: 'string' },
    autoOpenCashDrawer: { type: 'boolean' },
    screenConfig: {
      type: 'object',
      properties: {
        itemCardHeight: { type: 'number' },
        productFontSize: { type: 'number' },
        textLineHeight: { type: 'number' },
        oneLine: { type: 'boolean' },
        oneLineShorten: { type: 'boolean' },
        categoryHeight: { type: 'number' },
        categoryWidth: { type: 'number' },
        categoryFontSize: { type: 'number' },
        categoryLineHeight: { type: 'number' },
        hideTitleName: { type: 'boolean' },
        scrollableLayout: { type: 'boolean' },
        smallSidebar: { type: 'boolean' },
        menuDirection: { type: 'string' },
      }
    },
    layoutConfig: {
      type: 'object',
      properties: {
        enableScroll: { type: 'number' },
        enableZoom: { type: 'number' },
      }
    },
  },
  indexes: [],
}

// groupPrinter & position: to store groupPrinter._id & printer._id (printer in position) respectively
export type PrinterMappingItem = {
  groupPrinter: string
  position: string
}

export const DeviceSetting = {} as RxCollection<DeviceSetting>

Object.assign(window, { DeviceSetting })
