import _ from 'lodash';
import dayjs from 'dayjs';
import { type FunctionComponent, useCallback, useState } from "react";
import DateChoosingPopup from "./DateChoosingPopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import SettingHourPopup from "./SettingHourPopup";
import TabToolbar from "./TabToolbar";
import ReservationCard from "./ReservationCard";
import TableManualKeyboard from "./TableManualKeyboard";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { router } from "@/pos/PosRouter.ts";
import { formatCurrency } from "@/shared/utils";
import { type Reservation, ReservationStatus } from "@/data/Reservation.ts";
import {
  fmtEndDate,
  fmtStartDate,
  onEditReservation,
  onNewReservation,
  onRefreshReservation,
  reservationsViewModels,
  servicesSummary
} from "@/data/ReservationHub.ts"
import { LL0 } from "@/react/core/I18nService.tsx";
import { makeProductsAvailable } from "@/data/ProductHub.ts";
import MakeReservationPopup from "@reservation2/MakeReservationPopup.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { mainScreen } from "@/data/PosSettingsSignal.ts";

export type ReservationPluginType = {
  className?: string;
};

const ReservationPlugin: FunctionComponent<ReservationPluginType> = ({
  className = "",
}) => {
  makeProductsAvailable();

  const [isDateChoosingPopupOpen, setDateChoosingPopupOpen] = useState(false);
  const [isSettingHourPopupOpen, setSettingHourPopupOpen] = useState(false);
  const [isMakeReservationPopupOpen, setMakeReservationPopupOpen] = useState(false);

  const openDateChoosingPopup = useCallback(() => {
    setDateChoosingPopupOpen(true);
  }, []);

  const closeDateChoosingPopup = useCallback(() => {
    setDateChoosingPopupOpen(false);
  }, []);

  const openSettingHourPopup = useCallback(() => {
    setSettingHourPopupOpen(true);
  }, []);

  const closeSettingHourPopup = useCallback(() => {
    setSettingHourPopupOpen(false);
  }, []);

  const openMakeReservationPopup = useCallback(() => {
    setMakeReservationPopupOpen(true);
  }, []);

  const closeMakeReservationPopup = useCallback(() => {
    setMakeReservationPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className={`w-full h-full bg-white-solid-white-400-f2f2f2 max-w-full overflow-hidden flex flex-col items-start justify-start relative gap-[16px] text-center text-sm text-white-solid-white-100-ffffff font-mulish ${className}`}
      >
        <div
          className="self-stretch rounded-12xs bg-gray-solid-gray-570-5a6370 h-[50px] flex flex-row items-center justify-center py-2 px-4 box-border z-[0] mq768:h-11 mq600:h-10">
          <div className="w-full flex flex-row items-center justify-between">
            <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
              <div
                className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-blue-solid-blue-450-1271ff h-10 overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] min-w-[72px] mq768:h-9 mq600:h-8"
                ref={makeRipple}
                onClick={() => router.screen = mainScreen()}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover"
                  alt=""
                  src="/iconback-icon@2x.png"
                />
                <b className="w-[34px] relative leading-[17px] hidden">{LL0().ui.back()}</b>
              </div>
              <div
                className="flex flex-row items-center justify-start gap-[8px] cursor-pointer text-left font-inter"
                onClick={openDateChoosingPopup}
              >
                <img
                  className="w-[26px] relative h-[26px] object-cover"
                  alt=""
                  src="/icon-calendar@2x.png"
                />
                <div className="relative [text-decoration:underline]">
                  {fmtStartDate()} - {fmtEndDate()}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-end gap-[8px] text-smi text-black-solid-black-900-1e1e23">
              <div className="flex flex-row items-center justify-center mq768:h-9 mq600:h-8">
                <div
                  className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-81xl bg-cyan-solid-cyan-250-8aeaff h-10 overflow-hidden flex flex-row items-center justify-center py-0 px-2.5 box-border gap-[4px] mq768:h-9 mq768:pl-2.5 mq768:box-border mq600:h-8"
                  ref={makeRipple}
                  onClick={() => {
                    onNewReservation()
                    openMakeReservationPopup()
                    // dialogService.show({ component: ReservationNewEditPlugin, isLocofyPopup: true, attrs: { left: 0, right: 0, top: 0, bottom: 0} })
                  }}
                >
                  <img
                    className="w-6 relative h-6 object-cover"
                    alt=""
                    src="/icongeneralplus-icon@2x.png"
                  />
                  <b
                    className="w-[118px] relative leading-[17px] flex items-end justify-center shrink-0 mq600:flex mq480:hidden">{LL0().reservation.newReservation()}</b>
                </div>
              </div>
              {/*<div*/}
              {/*  className="self-stretch rounded-9981xl bg-green-solid-green-ddf08f flex flex-row items-center justify-start cursor-pointer"*/}
              {/*  onClick={openSettingHourPopup}*/}
              {/*>*/}
              {/*  <img*/}
              {/*    className="w-10 relative h-10 object-cover mq768:w-9 mq768:h-9 mq600:w-8 mq600:h-8"*/}
              {/*    alt=""*/}
              {/*    src="/iconsetting@2x.png"*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div
          className="no-scrollbar self-stretch overflow-y-auto flex flex-col items-start justify-start py-0 px-4 box-border gap-[12px] h-full z-[1] h.414:pl-2 h.414:pr-2 h.414:box-border mq320:pl-2 mq320:pr-2 mq320:box-border">
          <TabToolbar />
          <div
            className="no-scrollbar self-stretch overflow-y-auto shrink-0 grid flex-row flex-wrap items-start justify-start gap-[16px] grid-cols-[repeat(auto-fill,_minmax(275px,_1fr))] sm:gap-[8px] mq768:gap-[8px]">
            {reservationsViewModels().map((reservation: Reservation) => <ReservationCard
              _id={reservation._id}
              key={reservation._id}
              isUpcoming={reservation.status === ReservationStatus.Pending}
              isCompleted={reservation.status === ReservationStatus.Completed}
              isCancel={reservation.status === ReservationStatus.Declined}
              service={servicesSummary(reservation.items)}
              total={formatCurrency(reservation.vTotal!)}
              guests={reservation.noOfGuests}
              note={reservation.note || '-'}
              dateTime={dayjs.unix(reservation.date).format(`${LL2().dates.dateFormat()} ${LL2().dates.timeFormat()}:ss`)}
              customerName={`${reservation.customer.name}`}
              customerPhone={reservation.customer.phone || '-'}
              isStaffUnassigned={_.isEmpty(reservation.staff)}
              staff={reservation.isMultipleStaffs ? _.uniq(_.map(reservation.items, item => item.staff || '?')).join(', ') : reservation.staff}
              onRefresh={() => {
                onRefreshReservation(reservation)
                // dialogService.show({
                //   component: ReservationNewEditPlugin,
                //   isLocofyPopup: true,
                //   attrs: { left: 0, right: 0, top: 0, bottom: 0 }
                // })
                openMakeReservationPopup()
              }}
              onEdit={() => {
                onEditReservation(reservation)
                // dialogService.show({
                //   component: ReservationNewEditPlugin,
                //   isLocofyPopup: true,
                //   attrs: { left: 0, right: 0, top: 0, bottom: 0 }
                // })
                openMakeReservationPopup()
              }}
            />)}
          </div>
        </div>
        <TableManualKeyboard tableManualKeyboardIden={false} />
      </div>
      {isDateChoosingPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeDateChoosingPopup}
        >
          <DateChoosingPopup onClose={closeDateChoosingPopup} />
        </PortalPopup>
      )}
      {isSettingHourPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSettingHourPopup}
        >
          <SettingHourPopup onClose={closeSettingHourPopup} />
        </PortalPopup>
      )}
      {isMakeReservationPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeMakeReservationPopup}
        >
          <MakeReservationPopup onClose={closeMakeReservationPopup} />
        </PortalPopup>
      )}
    </>
  );
};

export default ReservationPlugin;
