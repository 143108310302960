import { type FunctionComponent } from "react";
import {
  onClickCopy,
  onClickDelete,
  onClickSwitchVertical,
  setOpenPopupImport
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { editorMode0, ITEM_MODE } from "@/react/EditMenuView/EditMenuView.tsx";

export type EditMenuToolbarType = {
  className?: string;
  onClose?: () => void;
};

const VerticalEditMenuToolbar: FunctionComponent<EditMenuToolbarType> = ({
  className = "",
  onClose,
}) => {
  return (
    <div
      className={`self-stretch bg-gray-solid-gray-170-e3e4e5 flex flex-row items-center justify-between py-3 px-2.5 gap-3 text-left text-sm text-black-solid-black-1000-000000 font-mulish sm:h-[42px] sm:pl-2 sm:pr-2 sm:box-border ${className}`}
    >
      {editorMode0() === ITEM_MODE.SECTION &&
        <div
          onClick={() => setOpenPopupImport(true)}
          className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-3.5 box-border relative gap-0.5"
          ref={makeRipple}
        >
          <img
            className="w-5 relative h-5 object-cover z-[1]"
            src="/icon-import-icon@2x.png"
            alt="" />
          <div
            className="w-[82px] absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_41px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
          <b className="relative z-[1] sm:text-smi">{LL0().voucher.import()}</b>
        </div>
      }
      <div className="flex flex-row items-center gap-4 flex-1 justify-end">
        {editorMode0() === ITEM_MODE.ITEM &&
          <div
            className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-3.5 box-border relative gap-2"
            onClick={onClickCopy} ref={makeRipple}
          >
            <div
              className="w-[82px] absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_41px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
            <b className="relative z-[1] sm:text-smi">{LL0().ui.duplicate()}</b>
          </div>
        }
        <div
          className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-3.5 box-border relative gap-2"
          onClick={onClickDelete}
          ref={makeRipple}
        >
          <div
            className="w-[82px] absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_40.5px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
          <b className="relative z-[1] sm:text-smi">{LL0().ui.delete()}</b>
        </div>
        {(editorMode0() !== ITEM_MODE.KEYBOARD && editorMode0() !== ITEM_MODE.INGREDIENT) &&
          <div
            className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-3.5 box-border relative gap-2"
            ref={makeRipple}
            onClick={() => onClickSwitchVertical('swap')}
          >
            <div
              className="w-[82px] absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_41px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
            <b className="relative z-[1] sm:text-smi">{LL0().ui.switch()}</b>
          </div>
        }
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
    </div>
  );
};

export default VerticalEditMenuToolbar;