import { paymentHook } from "@/react/utils/hooks.ts";
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import { lockCondition, runMasterHandler } from "@/data/OrderHub.ts";
import { MasterHandlerCommand, TseMethod } from "@/pos/OrderType.ts";
import { order0 } from "@/react/PaymentView/PaymentView.tsx";
import { onPrintTse, payOrderHandler, paySplitHandler, preSplitHandler } from "@/tse/tse-init.ts";

export function registerTseHook() {
  //1. tse condition
  lockCondition.v = () => {
    return tseConfig0().tseEnable || false;
  }

  paymentHook.on("preSplitHandler", async ([order, tseMethod = TseMethod.apply]) => {
    // await runMasterHandler(order0(), [MasterHandlerCommand.preSplitHandler, tseMethod], true);
    await preSplitHandler(order, tseMethod);
  })

  paymentHook.on("paySplitHandler", async (order) => {
    if (tseConfig0().tseEnable) {
      await paySplitHandler(order);
    }
  })

  paymentHook.on("payOrderHandler", async (order) => {
    // if (tseConfig0().tseEnable) cmds.push(MasterHandlerCommand.payOrderHandler);
    if (tseConfig0().tseEnable) {
      await payOrderHandler(order);
    }
  });

  //2. payment hook
  paymentHook.on('postKitchenPrint', async (order) => {
    await onPrintTse(order);
  })
}