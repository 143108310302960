import ClientCaller from "@/lib/master-caller/client-caller.ts";
import type TseClient from "@/tse/tse-client";
import type { TransactionRequest } from "@/tse/tse-client";
import { getMasterHandler } from "@/lib/master-caller/master-handler.ts";

export async function registerTseClient(tseClient: TseClient) {
  const masterHandler = getMasterHandler()
  masterHandler.registerCommand('tse_startAndFinishTransaction', ({clientId, transactionData, processType}: TransactionRequest) => {
    return tseClient.startAndFinishTransaction({clientId, transactionData, processType});
  })

  masterHandler.registerCommand('tse_bulkStartAndFinishTransaction', (requests: TransactionRequest[]) => {
    return tseClient.bulkStartAndFinishTransaction(requests);
  })
}