import { signal } from '@/react/core/reactive'
import { appFlow } from "@/shared/logger.ts";

export const [ip, setIp] = signal<string>(localStorage.getItem('localIp') ?? 'localhost')

//@ts-ignore
window.globalSetIp = (ip: string) => {
  setIp(ip)
  appFlow(`setIp ${ip}`);
}
