import {type FunctionComponent, useCallback, useState} from "react";
import PopupOrderDetailsPlugin from "./PopupOrderDetailsPlugin";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import StatePending from "./StatePending";
import RoundedButton from "./RoundedButton";
import PendingOrderAtRight from "./PendingOrderAtRight";
import {kitchenOrders0, setPopupOrder, totalKitchenOrder, stopBell} from "@/react/PendingOrder/PendingOrderLogic";
import {router} from "@/pos/PosRouter";
import Debug from "debug";
import type {TOrder} from "@/pos/OrderType";
import Select, {type SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useSignal, useComputed} from "@/react/core/reactive.ts";
import {OrderType} from "../../../../src/pos/OrderType";
import {LL0} from "@/react/core/I18nService.tsx";

import { mainScreen } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const PendingOrderRightPath: FunctionComponent = () => {
  const [isPopupOrderDetailsPluginOpen, setPopupOrderDetailsPluginOpen] =
    useState(false);

  const debug = Debug('PendingOrderRightPath')

  const openPopupOrderDetail = useCallback((order: TOrder) => {
    userFLow(`open popup order detail ${order}`, {
      username: loginUser()?.name
    });
    debug('openPopupOrderDetail', order)
    setPopupOrder(order)
    setPopupOrderDetailsPluginOpen(true);
  }, []);

  const closePopupOrderDetail = useCallback(() => {
    setPopupOrderDetailsPluginOpen(false);
  }, []);

  const [kitchenOrdersFilter, setKitchenOrdersFilter] = useSignal('all')
  const options = [
    {text: `${LL0().reservation.all()}`, value: 'all'},
    {text: `${LL0().editOnlineMenu.pickup()}`, value: OrderType.PickUp},
    {text: `${LL0().editOnlineMenu.delivery()}`, value: OrderType.Delivery}
  ]
  const handleChange = (event: SelectChangeEvent) => {
    setKitchenOrdersFilter(() => event.target.value as string);
  };

  const filteredKitchenOrders = useComputed(() => {
    if (kitchenOrdersFilter() === 'all')
      return kitchenOrders0()
    return kitchenOrders0().filter((order: TOrder) => order.type === kitchenOrdersFilter())
  })

  const filteredOrdersLength = useComputed(() => filteredKitchenOrders().length)

  return (
    <div
      className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px] text-left text-mini text-basic-color-white font-mulish shrink-0">
      <div className="h-[34px] min-h-[34px] self-stretch flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-start gap-[12px]">
          <b className="relative leading-[16px]">{LL0().onlineOrder.sentToKitchen()}</b>
          <StatePending
            chipNumber={totalKitchenOrder()}
            statePendingFlexShrink="0"
            statePendingBackgroundColor="#f9a825"
          />
        </div>
        <div className="flex flex-row items-center justify-start gap-[8px] sm:gap-[4px] Tablet_600:gap-[8px]">
          <Select
            className="bg-white w-auto !rounded-81xl"
            size="small"
            value={kitchenOrdersFilter()}
            onChange={handleChange}>
            {options.map(option => <MenuItem
              key={option.value}
              value={option.value}>
              {option.text}
            </MenuItem>)}
          </Select>
          <RoundedButton
            buttonIcon="/iconmute-icon@2x.png"
            text="Button"
            showButtonIcon
            showButtonText={false}
            roundedButtonBackgroundColor="#fff"
            onClick={() => stopBell()}
          />
          <RoundedButton
            buttonIcon="/iconback-icon@2x.png"
            text="Button"
            showButtonIcon
            showButtonText={false}
            roundedButtonBackgroundColor="#1271ff"
            onClick={() => {
              userFLow(`go to main screen`, {
                username: loginUser()?.name
              });
              router.screen = mainScreen()
            }}
          />
        </div>
      </div>
      <div className="h-[calc(100% - 34px)] overflow-y-scroll flex flex-col w-full gap-[8px] no-scrollbar">
        {filteredKitchenOrders().map(order => <PendingOrderAtRight
          order={order}
          key={order._id}
          onShowOrderDetail={() => openPopupOrderDetail(order)}
        />)}
        {filteredOrdersLength() === 0 &&
            <div className="min-h-[200px] h-[50%] flex bg-white w-full items-center justify-center rounded-[4px]">
                <p className="text-black">{LL0().pendingOrder.noOrdersSendToKitchen()}</p>
            </div>}
      </div>
      {isPopupOrderDetailsPluginOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closePopupOrderDetail}
        >
          <PopupOrderDetailsPlugin onClose={closePopupOrderDetail}/>
        </PortalPopup>
      )}
    </div>
  );
};

export default PendingOrderRightPath;
