import React, { type FunctionComponent, useRef } from "react";
import { onBackEditMenu } from "@/react/VerticalEditMenuView/VerticalEditMenuView.tsx";
import CategoryListVerticalImport from "@vertical-order-view/CategoryListVerticalImport.tsx";
import ProductGridVerticalImport from "@vertical-order-view/ProductGridVerticalImport.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { onImportProduct } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import { onSelectAllProducts, setImportProductLayouts } from "@/react/SyncMenuView/SyncMenuView.tsx";

export type ProductGridImportType = {
  onClose?: () => void;
};
const ProductGridImport: FunctionComponent<ProductGridImportType> = ({ onClose }) => {
  const transformWrapperRef = useRef(null);

  function resetPosition() {
    //@ts-ignore
    transformWrapperRef.current?.setTransform(0, 0, 1)
  }

  return (
    <div className="w-full h-full max-w-full overflow-hidden flex flex-col items-center justify-start relative bg-[url('/public/edit-menu-bg-plugin@3x.png')] bg-cover bg-no-repeat bg-[top] text-center text-sm text-black-solid-black-1000-000000 font-mulish">
      <div className="self-stretch bg-black-solid-black-500-59636f flex flex-row items-center justify-between pt-4 px-2.5 pb-2.5 z-[2]">
        <div className="self-stretch w-16 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-blue-solid-blue-450-1271ff overflow-hidden shrink-0 flex flex-col items-center justify-center">
          <img
            className="w-8 relative h-8 object-cover mq432:w-[26px] mq432:h-[26px] mq414:w-[26px] mq414:h-[26px] mq360:w-[26px] mq360:h-8 mq340:w-[26px] mq340:h-[26px] mq320:w-[26px] mq320:h-[26px]"
            alt=""
            src="/vertical--back-icon@2x.png"
            onClick={() => {
              setImportProductLayouts([])
              if (onClose) {
                onClose()
              } else onBackEditMenu()
            }}
          />
        </div>
        <div className="self-stretch flex flex-row gap-2 items-center justify-center">
          <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] px-4 rounded-81xl [background:linear-gradient(180deg,_#ffe79b,_#e3a400)] h-10 flex flex-row items-center justify-center py-[7px] px-2 box-border relative gap-1"
               ref={makeRipple}
               onClick={async () => {
                 onSelectAllProducts()
               }}
          >
            <b className="relative leading-[17px] z-[2]">Select All</b>
            <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-981xl bg-darkslategray hidden z-[3]" />
          </div>
          <div className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] px-4 rounded-81xl bg-[#1e96f5] h-10 flex flex-row items-center justify-center py-[7px] px-2 box-border relative gap-1"
               ref={makeRipple}
               onClick={async () => {
                 onImportProduct()
               }}
          >
            <b className="relative leading-[17px] z-[2]">Import</b>
            <div className="w-full absolute !m-[0] h-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-981xl bg-darkslategray hidden z-[3]" />
          </div>
        </div>
      </div>
      <div className="w-screen h-screen flex items-start justify-start">
        <TransformWrapper
          ref={transformWrapperRef}
          doubleClick={{ disabled: true }}
          wheel={{ step: 0.002, smoothStep: 0.01, disabled: true }}
          panning={{
            velocityDisabled: true,
            lockAxisX: false,
            lockAxisY: false,
          }}
          limitToBounds={true} // -> false: unlimited scroll
        >
          <TransformComponent>
            <div className="w-screen h-screen min-w-[700px] flex items-start justify-start bg-gray-solid-gray-165-f8f8f8 self-stretch flex-1 flex flex-col items-center justify-start pt-[5px] px-0 pb-0 text-center text-sm text-black-solid-black-900-1e1e23 font-mulish">
              <div className='absolute inset-0 z-0 bg-black-opacity-black-80-1b2533'>
                <img src="/out@2x.png" alt="" className='h-full w-full' />
              </div>
              <div
                className="self-stretch flex min-w-[680px] flex-row flex-wrap items-center justify-start pt-0 px-[5px] pb-[5px] box-border">
                <CategoryListVerticalImport />
              </div>
              <div
                className="self-stretch flex-1 min-w-[680px] flex flex-col items-center justify-start py-0.5 px-[5px] text-left text-smi text-black-opacity-black-70-1d1d26">
                <ProductGridVerticalImport />
              </div>
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>

    </div>
  )
    ;
};

export default ProductGridImport;
