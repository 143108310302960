import { type FunctionComponent, useCallback, useState } from "react";
import ReservationCancelPopup from "./ReservationCancelPopup";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import ReservationCheckinPopup from "./ReservationCheckinPopup";
import { LL0 } from "@/react/core/I18nService.tsx";
import { cancelReservation, checkInReservation } from "@/data/ReservationHub.ts";

export type ReservationCardType = {
  _id: string;
  className?: string;
  isUpcoming?: boolean;
  service?: string;
  total?: string;
  guests?: string | number;
  note?: string;
  isCancel?: boolean;
  dateTime?: string;
  customerName?: string;
  customerPhone?: string;
  isStaffUnassigned?: boolean;
  isCompleted?: boolean;
  staff?: string;
  onEdit?: () => void;
  onRefresh?: () => void;
};

const ReservationCard: FunctionComponent<ReservationCardType> = ({
  _id,
  className = "",
  isUpcoming,
  service,
  total,
  guests,
  note,
  isCancel,
  dateTime,
  customerName,
  customerPhone,
  isStaffUnassigned,
  isCompleted,
  staff,
  onEdit,
  onRefresh
}) => {
  const [isReservationCancelPopupOpen, setReservationCancelPopupOpen] =
    useState(false);
  const [isReservationCheckinPopupOpen, setReservationCheckinPopupOpen] =
    useState(false);

  const openReservationCancelPopup = useCallback(() => {
    setReservationCancelPopupOpen(true);
  }, []);

  const closeReservationCancelPopup = useCallback(() => {
    setReservationCancelPopupOpen(false);
  }, []);

  const openReservationCheckinPopup = useCallback(() => {
    setReservationCheckinPopupOpen(true);
  }, []);

  const closeReservationCheckinPopup = useCallback(() => {
    setReservationCheckinPopupOpen(false);
  }, []);

  return (
    <>
      <div
        className={`flex-1 shadow-[0px_4px_8px_rgba(38,_61,_88,_0.06)] rounded-lg bg-white-solid-white-100-ffffff box-border flex flex-col items-center justify-start pt-3 px-3 pb-4 gap-[8px] min-w-[275px] text-center text-sm text-orange-solid-orange-e47b00 font-mulish border-[1px] border-solid border-gainsboro h.414:pt-2 h.414:px-2 h.414:pb-3 h.414:box-border ${className}`}
      >
        <div className="self-stretch h-5 flex flex-row items-center justify-start gap-[4px] text-left">
          <b className="flex-1 relative leading-[20px] h.414:text-smi">
            {dateTime}
          </b>
          {isUpcoming && (
            <div
              className="rounded flex flex-row items-center justify-start text-center text-blue-solid-blue-460-1471ff"
              onClick={onEdit}
            >
              <b className="relative [text-decoration:underline] leading-[20px] h.414:text-smi">
                {LL0().ui.edit()}
              </b>
              <img
                className="w-5 relative h-5 object-cover"
                alt=""
                src="/iconarrow-right-blue@2x.png"
              />
            </div>
          )}
        </div>
        <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-gainsboro" />
        <div
          className="self-stretch flex flex-row items-start justify-start pt-1 px-0 pb-0 gap-[4px] text-base text-gray-solid-gray-940-394453">
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px] text-left">
            <div className="self-stretch flex flex-row items-center justify-start gap-[2px]">
              <img
                className="w-5 relative h-5 object-cover"
                alt=""
                src="/iconuser@2x.png"
              />
              <b className="relative leading-[20px] h.414:text-sm">
                {customerName}
              </b>
            </div>
            <div
              className="self-stretch relative text-sm leading-[18px] font-medium text-gray-solid-gray-930-546073 h.414:text-xs">
              {customerPhone}
            </div>
          </div>
          {isUpcoming && (
            <div
              className="rounded bg-blue-solid-blue-30-e3f2fd flex flex-row items-center justify-center py-1.5 px-3 text-blue-solid-blue-00adc5 border-[1px] border-solid border-blue-solid-blue-00adc5 sm:pl-[11px] sm:pr-[11px] sm:box-border mq768:py-[5px] mq768:px-2.5 mq768:box-border mq600:py-1 mq600:px-[9px] mq600:box-border">
              <b className="relative leading-[20px] sm:text-mini mq768:text-sm mq600:text-smi">
                {LL0().reservation.upcoming()}
              </b>
            </div>
          )}
          {isCancel && (
            <div
              className="rounded bg-red-solid-red-10-f6d5dd flex-row items-center justify-center py-1.5 px-3 text-red-solid-red-250-eb5558 border-[1px] border-solid border-red-solid-red-250-eb5558 sm:pl-[11px] sm:pr-[11px] sm:box-border mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:py-1 mq600:px-[9px] mq600:box-border">
              <b className="relative leading-[20px] sm:text-mini mq768:text-sm mq600:text-smi">
                {LL0().reservation.cancelled()}
              </b>
            </div>
          )}
          {isCompleted && (
            <div
              className="rounded bg-green-solid-green-50-f5fbec flex-row items-center justify-center py-1.5 px-3 text-green-solid-green-600-00c37c border-[1px] border-solid border-green-solid-green-600-00c37c sm:pl-[11px] sm:pr-3 sm:box-border mq768:pl-2.5 mq768:pr-2.5 mq768:box-border mq600:py-1 mq600:px-[9px] mq600:box-border">
              <b className="relative leading-[20px] sm:text-mini mq768:text-sm mq600:text-smi">
                {LL0().reservation.arrived()}
              </b>
            </div>
          )}
        </div>
        <div
          className="self-stretch h-[180px] flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border text-left text-gray-solid-gray-570-5a6370">
          {/*<div*/}
          {/*  className="self-stretch rounded bg-white-solid-white-230-f3f4f4 flex flex-row items-center justify-start py-1 px-2 gap-[8px]">*/}
          {/*  <div className="flex-1 relative leading-[20px] inline-block max-w-[80px] h.414:text-xs">*/}
          {/*    {LL0().reservation.service()}*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    className="flex-1 relative font-semibold text-gray-solid-gray-950-414c5e text-right [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:4] [-webkit-box-orient:vertical] h.414:text-xs">*/}
          {/*    {service}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="self-stretch rounded flex flex-row items-center justify-start py-1 px-2 gap-[8px] text-right">*/}
          {/*  <div className="flex-1 relative leading-[20px] text-left inline-block max-w-[100px] h.414:text-xs">*/}
          {/*    {LL0().reservation.bookedStaff()}*/}
          {/*  </div>*/}
          {/*  {!isStaffUnassigned && (*/}
          {/*    <div*/}
          {/*      className="flex-1 relative font-semibold text-pink-solid-pink-700-ff2fb8 inline-block overflow-hidden text-ellipsis whitespace-nowrap max-h-[36px] h.414:text-xs">*/}
          {/*      {staff}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*  {isStaffUnassigned && (*/}
          {/*    <div className="flex-1 relative font-semibold text-gray-solid-gray-950-414c5e h.414:text-xs">*/}
          {/*      {LL0().reservation.unassigned()}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  className="self-stretch rounded bg-white-solid-white-230-f3f4f4 flex flex-row items-center justify-start py-1 px-2 gap-[8px]">*/}
          {/*  <div className="flex-1 relative leading-[20px] inline-block max-w-[80px] h.414:text-xs">*/}
          {/*    {LL0().reservation.guests()}*/}
          {/*  </div>*/}
          {/*  <div className="flex-1 relative font-semibold text-right h.414:text-xs">*/}
          {/*    {guests}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className='bg-[#f2f2f2] flex flex-row px-2 gap-2 rounded-[2px] border-[1px] border-solid border-[#DBDBDB] py-1 px-2 '>
            <img
              className='w-4 h-4'
              src='/groupUserIcon.png' alt=''
            />
            <div className='text-[14px] text-[#414C5E] font-bold'>{LL0().reservation.manyGuest({ no: guests })}</div>
          </div>
          <div className="self-stretch rounded flex flex-row items-center justify-start py-1 px-2 gap-[8px]">
            <div className="flex-1 relative leading-[20px] inline-block max-w-[100px] underline">
              {LL0().reservation.note()}:
            </div>
          </div>
          <p className="flex-1 relative font-semibold text-gray-solid-gray-950-414c5e px-2">
            {note}
          </p>
          {/*<div*/}
          {/*  className="self-stretch rounded bg-white-solid-white-230-f3f4f4 flex flex-row items-center justify-start py-1 px-2 gap-[8px]">*/}
          {/*  <div className="w-20 relative leading-[20px] flex items-end shrink-0 max-w-[100px] h.414:text-xs">*/}
          {/*    {LL0().common.total()}*/}
          {/*  </div>*/}
          {/*  <div*/}
          {/*    className="flex-1 relative font-semibold text-gray-solid-gray-950-414c5e text-right [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] h.414:text-xs">*/}
          {/*    {total}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        {isUpcoming && (
          <div
            className="self-stretch flex flex-row items-start justify-start gap-[8px] text-blue-solid-blue-420-2979ff">
            <div
              className="self-stretch flex-1 rounded-md bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-2 px-2.5 cursor-pointer border-[1px] border-solid border-blue-solid-blue-420-2979ff"
              onClick={openReservationCancelPopup}
            >
              <b className="relative">{LL0().ui.cancel()}</b>
            </div>
            <div
              className="self-stretch flex-1 rounded-md bg-blue-solid-blue-420-2979ff flex flex-row items-center justify-center py-2 px-2.5 cursor-pointer text-white-solid-white-100-ffffff"
              onClick={openReservationCheckinPopup}
            >
              <b className="relative">{LL0().reservation.checkIn()}</b>
            </div>
          </div>
        )}
        {isCancel && (
          <div className="w-full flex flex-row items-start justify-start gap-[8px] text-white-solid-white-100-ffffff">
            <div
              className="self-stretch flex-1 rounded-md bg-purple-solid-purple-9a59ed flex flex-row items-center justify-center py-2 px-2.5 cursor-pointer border-[1px] border-solid border-purple-solid-purple-9a59ed"
              onClick={onRefresh}
            >
              <b className="relative">{LL0().dashboard.refresh()}</b>
            </div>
            <div
              className="self-stretch flex-1 rounded-md flex flex-row items-center justify-center py-2 px-2.5 cursor-pointer text-purple-solid-purple-9a59ed border-[1px] border-solid border-purple-solid-purple-9a59ed"
              onClick={openReservationCheckinPopup}
            >
              <b className="relative">{LL0().reservation.checkIn()}</b>
            </div>
          </div>
        )}
      </div>
      {isReservationCancelPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeReservationCancelPopup}
        >
          <ReservationCancelPopup onClose={closeReservationCancelPopup} onConfirm={() => cancelReservation(_id)} />
        </PortalPopup>
      )}
      {isReservationCheckinPopupOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeReservationCheckinPopup}
        >
          <ReservationCheckinPopup
            name={customerName}
            phone={customerPhone}
            onClose={closeReservationCheckinPopup}
            onConfirm={() => checkInReservation(_id)} />
        </PortalPopup>
      )}
    </>
  );
};

export default ReservationCard;
