import { type FunctionComponent, useState } from "react";

import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { checkExpireDate, genVoucherCode, selectedVoucher } from "@/react/VoucherView/VoucherView.tsx";
import dayjs from "dayjs";
import TextField from "@/react/core/TextField.tsx";
import _ from "lodash"
import { makeRipple } from "@/react/core/ripple-utils.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import { FormControlLabel, Switch } from "@mui/material";
import { toast } from "react-toastify";
import { LL3 } from "@/react/core/I18nCurrency";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type VoucherInfoType = {
  className?: string;
};

const VoucherInfo: FunctionComponent<VoucherInfoType> = ({
  className = "",
}) => {
  const [inputDateTimePickerValue, setInputDateTimePickerValue] =
    useState(null);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`self-stretch rounded-sm bg-white-solid-white-100-ffffff flex flex-col items-start justify-start pt-5 px-6 pb-4 gap-[16px] text-left text-base text-black-solid-black-1000-000000 font-mulish border-b-[1px] border-solid border-gray-solid-gray-420-b3b3b3 sm:py-4 sm:px-3 sm:box-border Tablet_768:py-3 Tablet_768:px-2 Tablet_768:box-border ${className}`}
      >
        <div
          className="self-stretch rounded bg-gray-opacity-gray-50-f2f2f2 flex flex-row items-start justify-start p-3 gap-[16px] border-[1px] border-solid border-gray-solid-gray-400-c9cbcc">
          <div className="flex-1 flex flex-col items-start justify-start gap-[4px] sm:items-start sm:justify-start">
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[4px] sm:items-start sm:justify-start Tablet_768:flex-col Tablet_768:gap-[2px]">
              <div className="w-[68px] relative inline-block shrink-0 Tablet_768:text-sm">
                {LL0().voucher.creator()}:
              </div>
              <b className="relative text-black-solid-black-900-1e1e23 Tablet_768:text-sm">
                {selectedVoucher()?.createdBy}
              </b>
            </div>
            <div
              className="self-stretch flex flex-row items-center justify-start gap-[4px] sm:items-start sm:justify-start Tablet_768:flex-col Tablet_768:gap-[2px] Tablet_768:pl-0 Tablet_768:box-border">
              <div className="w-[68px] relative inline-block shrink-0 Tablet_768:text-sm">
                {LL0().voucher.created()}:
              </div>
              <div className="relative font-medium text-black-solid-black-900-1e1e23 Tablet_768:text-sm">
                {selectedVoucher()?.createdAt ? dayjs.unix(selectedVoucher()?.createdAt || 0).format(`${LL2().dates.dateFormat()} ${LL2().dates.timeFormat()}`) : ''}
              </div>
            </div>
          </div>
          {selectedVoucher()?.status &&
            <div
              className="rounded flex flex-row items-center justify-center py-1 px-4 text-purple-solid-purple-600-904bff border-[1px] border-solid border-purple-solid-purple-600-904bff">
              <div className="relative font-semibold Tablet_768:text-sm">
                {checkExpireDate(selectedVoucher()?.expiredAt) ? `${LL0().voucher.expired()}` : _.get(LL0().voucher, selectedVoucher()?.status || '')?.()}
              </div>
            </div>
          }
        </div>
        <div
          className="self-stretch flex flex-row items-end justify-start gap-[24px] Tablet_768:flex-col Tablet_768:gap-[16px]">
          <div
            className="flex-1 flex flex-row items-end justify-start gap-[8px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="flex-1 flex flex-col items-start justify-center gap-[4px]">
              <div className="self-stretch relative Tablet_768:text-sm">
                {LL0().voucher.voucherCode()}
              </div>
              <TextField
                className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                color="primary"
                size="small"
                fullWidth={true}
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "36px" } }}
                value={selectedVoucher()?.code || ''}
                onChange={e => _.assign(selectedVoucher(), { code: e.target.value })}
              />
            </div>
            <div
              className="rounded-md bg-blue-solid-blue-420-2979ff h-9 flex flex-row items-center justify-center py-1 px-3 box-border text-center text-sm text-white-solid-white-100-ffffff"
              ref={makeRipple}
              onClick={() => _.assign(selectedVoucher(), { code: genVoucherCode() })}
            >
              <b className="relative">{LL0().voucher.autoGen()}</b>
            </div>
          </div>
          <div
            className="flex-1 flex flex-col items-start justify-center gap-[4px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="self-stretch relative Tablet_768:text-sm">
              {LL0().voucher.voucherName()}
            </div>
            <TextField
              className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "36px" } }}
              value={selectedVoucher()?.name || ''}
              onChange={e => {
                _.debounce(() => userFLow(`voucherName voucher in voucher ${e.target.value}`, {
                  username: loginUser()?.name
                }), 1000, { leading: true, trailing: true })
                _.assign(selectedVoucher(), { name: e.target.value })
              }}
            />
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-end justify-start gap-[24px] Tablet_768:flex-col Tablet_768:gap-[16px]">
          <div className="flex-1 flex flex-row items-end justify-start Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="flex-1 flex flex-col items-start justify-center gap-[4px]">
              <div className="self-stretch relative Tablet_768:text-sm">
                {LL0().voucher.customersFirstName()}
              </div>
              <TextField
                className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
                color="primary"
                size="small"
                fullWidth={true}
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "36px" } }}
                value={selectedVoucher()?.customerFirstName}
                onChange={e => {
                  _.debounce(() => userFLow(`customersFirstName voucher`, {
                    username: loginUser()?.name
                  }), 2000, { leading: true, trailing: true });
                  _.assign(selectedVoucher(), { customerFirstName: e.target.value })
                }}
              />
            </div>
          </div>
          <div
            className="flex-1 flex flex-col items-start justify-center gap-[4px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="self-stretch relative Tablet_768:text-sm">
              {LL0().voucher.customersLastName()}
            </div>
            <TextField
              className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "36px" } }}
              value={selectedVoucher()?.customerLastName}
              onChange={e => {
                _.debounce(() => userFLow(`customerLastName voucher`, {
                  username: loginUser()?.name
                }), 2000, { leading: true, trailing: true });
                _.assign(selectedVoucher(), { customerLastName: e.target.value })
              }}
            />
          </div>
        </div>
        <div
          className="self-stretch flex flex-row items-center justify-start gap-[24px] Tablet_768:flex-col Tablet_768:gap-[16px]">
          <div
            className="flex-1 flex flex-col items-start justify-center gap-[4px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="self-stretch relative flex items-center h-6 shrink-0 Tablet_768:text-sm">
              {LL0().voucher.voucherPrice()}
            </div>
            <TextField
              className="self-stretch font-mulish font-medium text-base text-black-solid-black-900-1e1e23"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              sx={{ "& .MuiInputBase-root": { height: "36px" } }}
              value={selectedVoucher()?.price?.toString() || ''}
              onChange={(e) => {
                _.debounce(() => userFLow(`voucherPrice`, {
                  username: loginUser()?.name
                }), 2000, { leading: true, trailing: true })
                _.assign(selectedVoucher(), { price: e.target.value })
                if (selectedVoucher()?.isEqualPrice) {
                  _.assign(selectedVoucher(), { totalValue: e.target.value })
                }
              }}
            />
          </div>
          <div
            className="flex-1 flex flex-col items-start justify-center gap-[4px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="self-stretch h-6 flex flex-row items-center justify-start gap-[8px]">
              <div className="self-stretch w-10 relative">
                <FormControlLabel
                  label={''}
                  className="absolute h-[66.67%] top-[16.67%] bottom-[16.67%] left-[calc(50%_-_20px)]"
                  control={<Switch color="primary"
                                   checked={!!selectedVoucher()?.expiredAt}
                                   onChange={(__, checked) => {
                                     userFLow(`expiredAt voucher`, {
                                       username: loginUser()?.name
                                     })
                                     if (checked) {
                                       _.assign(selectedVoucher(), { expiredAt: dayjs().add(7, 'day').unix() })
                                     } else {
                                       _.assign(selectedVoucher(), { expiredAt: undefined })
                                     }
                                   }}
                  />}
                />
              </div>
              <div className="flex-1 relative Tablet_768:text-sm">
                {LL0().voucher.expiryDate()}
              </div>
            </div>
            {selectedVoucher()?.expiredAt && <div className="self-stretch">
              <DateTimePicker
                format={LL2().dates.datetimeFormat()}
                ampm={companyInfo0()?.country !== 'de'}
                value={dayjs.unix(selectedVoucher()?.expiredAt)}
                onChange={(newValue: any) => {
                  userFLow(`expiredAt voucher in voucher`, {
                    username: loginUser()?.name
                  })
                  if (newValue.toString() === 'Invalid Date') return toast.warning('Invalid Date');
                  _.assign(selectedVoucher(), { expiredAt: dayjs(newValue).unix() });
                }}
                sx={{}}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    required: false,
                    autoFocus: false,
                    error: false,
                    color: "primary",
                    helperText: "",
                  },
                }}
              />
            </div>}
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start Tablet_768:flex-col Tablet_768:gap-[16px]">
          <div
            className="flex-1 flex flex-col items-start justify-center gap-[4px] Tablet_768:flex-[unset] Tablet_768:self-stretch">
            <div className="self-stretch relative Tablet_768:text-sm">
              {LL0().voucher.voucherValue()}
            </div>
            <div
              className="self-stretch flex flex-row items-start justify-start gap-[24px] Tablet_768:flex-col Tablet_768:gap-[12px] Tablet_600:flex-col Mobile_480:flex-col Mobile_480:gap-[12px] mq1440:flex-col height_414:flex-col mq320:gap-[12px]">
              <div
                className="flex-1 rounded-10xs box-border h-9 flex flex-row items-start justify-start p-0.5 gap-[4px] border-[0.5px] border-solid border-darkgray Tablet_768:flex-[unset] Tablet_768:self-stretch Tablet_600:flex-[unset] Tablet_600:self-stretch Mobile_480:flex-[unset] Mobile_480:self-stretch mq1440:flex-[unset] mq1440:self-stretch height_414:flex-[unset] height_414:self-stretch">
                <div
                  className="self-stretch flex-1 rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-2 px-3 relative gap-[8px] Mobile_480:pt-[7px] Mobile_480:pb-[7px] Mobile_480:box-border"
                  ref={makeRipple}
                  onClick={() => {
                    userFLow(`selected value amount voucher in order`, {
                      username: loginUser()?.name
                    })
                    _.assign(selectedVoucher(), { type: 'amount' })
                  }}
                >
                  {selectedVoucher()?.type === 'amount' &&
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-400-0091ff z-[0]"/>}

                  {selectedVoucher()?.type === 'amount' ?
                    <div
                      className="relative font-semibold text-white-solid-white-100-ffffff z-[2] Tablet_768:text-sm Mobile_480:text-sm">
                      {LL0().voucher.amount()}
                    </div>
                    :
                    <div className="relative font-semibold z-[1] Mobile_480:text-sm">
                      {LL0().voucher.amount()}
                    </div>}
                </div>
                <div
                  className="opacity-20 self-stretch flex-1 rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center p-2 relative gap-[8px] Mobile_480:pt-[7px] Mobile_480:pb-[7px] Mobile_480:box-border"
                  // ref={makeRipple}
                  // onClick={() => _.assign(selectedVoucher(), { type: 'percent' })}
                >
                  {selectedVoucher()?.type === 'percent' &&
                    <div
                      className="w-full absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-10xs bg-blue-solid-blue-400-0091ff z-[0]"/>}
                  {selectedVoucher()?.type === 'percent' ?
                    <div
                      className="relative font-semibold text-white-solid-white-100-ffffff z-[2] Mobile_480:text-sm">
                      {LL0().voucher.percentage()}
                    </div>
                    :
                    <div className="relative font-semibold z-[1] Tablet_768:text-sm Mobile_480:text-sm">
                      {LL0().voucher.percentage()}
                    </div>}
                </div>
              </div>
              <TextField
                className="flex-1 font-mulish font-medium text-base text-black-solid-black-900-1e1e23 Tablet_768:flex-[unset] Tablet_768:self-stretch Tablet_600:flex-[unset] Tablet_600:self-stretch Mobile_480:flex-[unset] Mobile_480:self-stretch mq1440:flex-[unset] mq1440:self-stretch height_414:flex-[unset] height_414:self-stretch"
                color="primary"
                size="small"
                fullWidth={true}
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { height: "36px" } }}
                value={selectedVoucher()?.totalValue?.toString() || ''}
                onChange={(e) => {
                  _.debounce(() => userFLow(`totalValue voucher in voucher`, {
                    username: loginUser()?.name
                  }), 2000, { leading: true, trailing: true });
                  _.assign(selectedVoucher(), { totalValue: e.target.value })
                }}
                disabled={selectedVoucher()?.isEqualPrice}
              />
            </div>
            <i
              className="self-stretch relative font-semibold text-purple-solid-purple-600-904bff text-right Tablet_768:text-sm">
              {/*$20 used*/}
              {LL3().format.currency(selectedVoucher()?.usedValue || 0)} {LL0().voucher.used().toLowerCase()}
            </i>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default VoucherInfo;
