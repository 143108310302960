import { InvoiceTypes } from '@/pos/OrderType.ts'
import { printInvoiceRasterFactory } from '@/react/Printer/print-invoice-api.ts'
import { type Order } from "@/data/Order.ts";
import { printQrCode } from "@/react/Printer/print-invoice.ts";
import { Buffer } from "buffer/";
import _ from "lodash";
import { posSetting0 } from "@/data/PosSettingsSignal.ts";
import type { ScriptedRaster } from '@/shared/printer/types'
import assert from 'assert'
import { printDetailFLow } from "@/shared/logger.ts";
import type MultiAwaitLock from "@/shared/MultiAwaitLock.ts";

export const PrintStack = {
  _id: undefined as string | undefined,
  header0: { raster: undefined as ScriptedRaster | undefined },
  body0: { raster: undefined as ScriptedRaster | undefined },
  payment0: { raster: undefined as ScriptedRaster | undefined },
  footer0: { raster: undefined as ScriptedRaster | undefined },
  qrCode: { raster: undefined as ScriptedRaster | undefined },
}

//region printInvoice_new
export async function printInvoice_new(order: Order, invoiceType: InvoiceTypes, qrCodeLock?: MultiAwaitLock) {
  const invoiceApi = await printInvoiceRasterFactory(order, invoiceType);
  const vPrinter = invoiceApi.printer;
  vPrinter.lazy = true;
  await vPrinter.useCacheStart();
  await invoiceApi.printHeader0();
  await vPrinter.useCacheStop();
  await invoiceApi.printTime();
  await invoiceApi.printBody0();
  await invoiceApi.printPayment();
  await vPrinter.useCacheStart();
  await invoiceApi.printFooterText();
  await vPrinter.useCacheStop();
  await vPrinter.useCacheStart();
  await invoiceApi.printRedInvoice();
  await vPrinter.useCacheStop();
  await qrCodeLock?.acquireAsync();
  if (order?.qrCode) {
    const qrCodeApi = printQrCode(vPrinter, order);
    await qrCodeApi.printTseHeader();
    await vPrinter.useCacheStart();
    await qrCodeApi.printSerialNumber(vPrinter);
    await qrCodeApi.printSignature();
    await qrCodeApi.printTimeFormat(vPrinter);
    await qrCodeApi.printSignatureAlgorithm(vPrinter);
    await qrCodeApi.printPublicKey(vPrinter);
    await qrCodeApi.printUserName(vPrinter);
    await vPrinter.useCacheStop();
    await qrCodeApi.printTseQr();
  }
  await vPrinter.print({viaMaster: true, createVirtualPrinter: true, virtualPrinterMetadata: {orderId: order._id, invoiceType: invoiceType}});

  return invoiceApi;
}
//endregion

export function mergeRasters(rasters: (ScriptedRaster | undefined)[]): ScriptedRaster | undefined {
  if (rasters.length === 0) {
    return undefined;
  }
  // assert(rasters.length > 0, 'No raster to merge');
  let index = 0;
  const combined = _.compact(rasters).reduce((accRaster, raster): ScriptedRaster => {
    if (!accRaster) return raster;
    if (!raster || (raster.height === 0 && !raster.esc?.length)) return accRaster;
    const { data, width, height , esc, scripts } = raster;
    const { data: accData, width: accWidth, height: accHeight, esc: accEsc, scripts: accScripts } = accRaster;
    if (!accData || !data)
      printDetailFLow(`mergeRasters: data is null ${index}`);
    index++;
    const getData = () => {
      if (!data) return undefined;
      if (!accData) return undefined;
      return Buffer.concat([accData, data]);
    }
    // @ts-expect-error TODO: fix typings
    return <ScriptedRaster>{ data: getData(), width: width, height: height + accHeight, esc: Buffer.concat([accEsc, esc]), scripts: [...accScripts, ...scripts] };
  }, undefined as ScriptedRaster | undefined)
  assert(combined, 'Failed to merge rasters')
  return combined
}

export function markPrintInvoice(order: Order, invoiceType: InvoiceTypes) {
  if (invoiceType === InvoiceTypes.RED_INVOICE || invoiceType === InvoiceTypes.INVOICE) {
    order.printInvoice = true;
  }
}

// @ts-expect-error debug only
window.clearPrintCache = () => {
  // @ts-expect-error debug only
  posSetting0()!.printerCache = {};
}
