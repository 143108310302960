import { type FunctionComponent, useCallback, useState } from 'react';
import DataExportPopup from './DataExportPopup';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import DashboardPopupTabButton from './DashboardPopupTabButton';
import { PosScreen, router } from '@/pos/PosRouter.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import { AccessPort, getAccessibility } from '@/react/DecentralizationPort.ts';
import OnlineMenuPopup from "@dashboard/OnlineMenuPopup.tsx";
import DeveloperOnlyVerifyPopu from '@dashboard/DeveloperOnlyVerifyPopu.tsx'
import { DEVELOPER_PASSWORD, INSTALLATION_PASSWORD } from '@dashboard/DashboardSettingPopup.tsx'
import { tseConfig0 } from '@/data/TseConfigHub';

import { generalSetting0, isQuebecSrmEnabled } from "@/data/PosSettingsSignal.ts";
import { isFeatureEnabled, PosFeatures } from "@/srm/features.logic.ts";
import { loginUsersReportsPermission } from "@/data/UserSignal.computed";
import { isShowOnlineMenu, isShowPendingOrder, isShowReservation } from "@/shared/posFeatures.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type OrderScreenFunctionsPopupType = {
  onClose?: () => void;
};

const OrderScreenFunctionsPopup: FunctionComponent<
  OrderScreenFunctionsPopupType
> = ({ onClose }) => {
  const onNavigate = (screen: PosScreen) => {
    userFLow(`go to screen ${screen}`, {
      username: loginUser()?.name
    });
    router.screen = screen;
    onClose?.();
  }

  const [isDataExportPopupOpen, setDataExportPopupOpen] = useState(false);
  const [isOnlineMenuPopupOpen, setOnlineMenuPopupOpen] = useState(false);
  const [isInstallationVerifyPopuOpen, setInstallationVerifyPopuOpen] = useState(false);

  const openDataExportPopup = useCallback(() => {
    userFLow(`open data export popup`, {
      username: loginUser()?.name
    });
    setDataExportPopupOpen(true);
  }, []);

  const closeDataExportPopup = useCallback(() => {
    userFLow(`close data export popup`, {
      username: loginUser()?.name
    });
    setDataExportPopupOpen(false);
  }, []);

  const openOnlineMenuPopup = useCallback(() => {
    userFLow(`open online menu popup`, {
      username: loginUser()?.name
    });
    setOnlineMenuPopupOpen(true);
  }, []);

  const closeOnlineMenuPopup = useCallback(() => {
    userFLow(`close online menu popup`, {
      username: loginUser()?.name
    });
    setOnlineMenuPopupOpen(false);
    onClose?.();
  }, []);

  const [isDeveloperOnlyVerifyPopuOpen, setDeveloperOnlyVerifyPopuOpen] =
    useState(false);

  return (
    <>
      <div className="w-full h-full relative">
        <div className="no-scrollbar rounded-md [background:linear-gradient(180deg,_rgba(247,_247,_247,_0.42),_rgba(170,_169,_169,_0.42))] [backdrop-filter:blur(100px)] w-full flex flex-col items-center justify-start py-9 px-8 box-border relative gap-[20px] min-w-[496px] h-full max-w-full max-h-full overflow-y-auto text-left text-mini text-white-solid-white-100-ffffff font-mulish Tablet_768:pl-6 Tablet_768:pr-6 Tablet_768:pb-6 Tablet_768:box-border Tablet_600:pl-6 Tablet_600:pr-6 Tablet_600:pb-6 Tablet_600:box-border">
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] z-[0]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <b className="self-stretch relative sm:text-sm Tablet_600:text-smi">
                {LL0().order.orderStatus()}
              </b>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] Tablet_768:gap-[8px]">
                {getAccessibility(PosScreen.ORDER_HISTORY) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconorder-history-icon1@2x.png"
                    buttonText={LL0().dashboard.orderHistory()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.ORDER_HISTORY)}
                  />
                }
                {isShowPendingOrder() && isFeatureEnabled(PosFeatures.PendingOrder) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconpending-orders-icon2@2x.png"
                    buttonText={LL0().onlineOrder.pendingOrders()}
                    noticeNumber="20"
                    showChip
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.PENDING_ORDERS)}
                  />
                }
                {/*<DashboardPopupTabButton*/}
                {/*  settingIconUrl="/iconnew-delivery-icon@2x.png"*/}
                {/*  noticeNumber="Pickup"*/}
                {/*  showChip*/}
                {/*  onClick={() => onNavigate(PosScreen.PICKUP)}*/}
                {/*/>*/}
                {isShowOnlineMenu() &&
                  <DashboardPopupTabButton
                    settingIconUrl="/icononline-menu-icon71@2x.png"
                    buttonText={LL0().dashboard.onlineMenu()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={openOnlineMenuPopup}
                  />
                }
                {/*<DashboardPopupTabButton*/}
                {/*  settingIconUrl="/iconshipping-status-icon@2x.png"*/}
                {/*  buttonText={LL0().dashboard.shippingStatus()}*/}
                {/*  noticeNumber="20"*/}
                {/*  showChip={false}*/}
                {/*  showInfoIcon={false}*/}
                {/*  onClick={() => onNavigate(PosScreen.SHIPPING_STATUS)}*/}
                {/*/>*/}
                {isShowReservation() &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconreservation-icon101@2x.png"
                    buttonText={LL0().dashboard.reservation()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.RESERVATION)}
                  />
                }
                {getAccessibility(PosScreen.VIRTUAL_PRINTER) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconvirtual-printer-icon@2x.png"
                    buttonText={LL0().dashboard.virtualPrinter()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.VIRTUAL_PRINTER)}
                  />
                }
                {isQuebecSrmEnabled() && (
                  <DashboardPopupTabButton
                    settingIconUrl="/icon-SRM@2x.png"
                    buttonText={"SRM"}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.SRM)}
                  />
                )}
                {generalSetting0()?.displayKitchenEnable &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconkichen-monitor-icon151@2x.png"
                    buttonText={LL0().dashboard.kitchenMonitor()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.KITCHEN_MONITOR)}
                />}
              </div>
            </div>
            {loginUsersReportsPermission() &&
              <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
                <b className="self-stretch relative">{LL0().order.reports()}</b>
                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] Tablet_768:gap-[8px]">
                  {getAccessibility(PosScreen.EOD) &&
                    <DashboardPopupTabButton
                      settingIconUrl="/iconend-of-day-icon5@2x.png"
                      buttonText={LL0().dashboard.endOfDay()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.EOD)}
                    />
                  }
                  {getAccessibility(PosScreen.MONTHLY_REPORT) &&
                    <DashboardPopupTabButton
                      settingIconUrl="/iconmonthly-report-icon61@2x.png"
                      buttonText={LL0().dashboard.monthlyReport()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.MONTHLY_REPORT)}
                    />
                  }
                  {getAccessibility(PosScreen.STAFF_REPORT) &&
                    <DashboardPopupTabButton
                      settingIconUrl="/iconstaff-report-icon71@2x.png"
                      buttonText={LL0().dashboard.staffReport()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.STAFF_REPORT)}
                    />
                  }
                  {getAccessibility(PosScreen.CASHBOOK) &&
                    <DashboardPopupTabButton
                      settingIconUrl="/iconcashbook-icon111@2x.png"
                      buttonText={LL0().dashboard.cashbook()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.CASHBOOK)}
                    />
                  }
                </div>
              </div>
            }
          </div>

          {(getAccessibility(PosScreen.EDIT_MENU) || getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) || getAccessibility(PosScreen.HAPPY_HOUR)) &&
            <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
              <b className="self-stretch relative">{LL0().dashboard.settings()}</b>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[12px] Tablet_768:gap-[8px]">
                {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/icongeneral-settings-icon34@2x.png"
                    buttonText={LL0().dashboard.generalSetting()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.GENERAL_SETTING)}
                  />
                }
                {getAccessibility(PosScreen.EDIT_MENU) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconedit-menu-icon8@2x.png"
                    buttonText={LL0().order.menuEditing()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.EDIT_MENU)}
                  />
                }
                {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconuser-settings-icon9@2x.png"
                    buttonText={LL0().sidebar.user()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.EDIT_USER)}
                  />
                }
                {getAccessibility(PosScreen.HAPPY_HOUR) &&
                  <DashboardPopupTabButton
                    settingIconUrl="/iconhappy-hour-icon10@2x.png"
                    buttonText={LL0().restaurant.product.happyHour()}
                    noticeNumber="20"
                    showChip={false}
                    showInfoIcon={false}
                    onClick={() => onNavigate(PosScreen.HAPPY_HOUR)}
                  />
                }
                {getAccessibility(AccessPort.EDIT_HIGH_SECURITY_SETTING) &&
                  <>
                    <DashboardPopupTabButton
                      settingIconUrl="/icon-create-vouccher@2x.png"
                      buttonText={LL0().restaurant.createVoucher()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.VOUCHER)}
                    />
                    <DashboardPopupTabButton
                      settingIconUrl="/iconcompany-info-icon11@2x.png"
                      buttonText={LL0().dashboard.companyInfo()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.COMPANY_INFORMATION)}
                    />
                    <DashboardPopupTabButton
                      settingIconUrl="/iconpayment-settings-icon12@2x.png"
                      buttonText={LL0().dashboard.taxAndPayment()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.PAYMENT_SETTING)}
                    />
                    <DashboardPopupTabButton
                      settingIconUrl="/iconprinter-settings-icon13@2x.png"
                      buttonText={LL0().productViewSetting.modifierEditorDialog.printer()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.PRINTER_SETTING)}
                    />
                    <DashboardPopupTabButton
                      settingIconUrl="/icon-master-machine@2x.png"
                      buttonText={LL0().dashboard.masterMachine()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.MASTER_MACHINE)}
                    />
                    <DashboardPopupTabButton
                      settingIconUrl="/icon-developer-only-icon@2x.png"
                      buttonText={LL0().dashboard.developerOnly()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => {
                        userFLow(`go to screen developer only`, {
                          username: loginUser()?.name
                        });
                        if (import.meta.env.MODE === 'production') {
                          setDeveloperOnlyVerifyPopuOpen(true)
                        } else {
                          onClose?.();
                          router.screen = PosScreen.DEVELOPER;
                        }
                      }}
                    />
                    <DashboardPopupTabButton
                      settingIconUrl="/icongeneral-settings-icon11@2x.png"
                      buttonText="Installation"
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => {
                        userFLow(`go to screen installation`, {
                          username: loginUser()?.name
                        });
                        if (import.meta.env.MODE === 'production') {
                          setInstallationVerifyPopuOpen(true)
                        } else {
                          onClose?.();
                          router.screen = PosScreen.INSTALLATION;
                        }
                      }}
                    />
                    {tseConfig0()?.tseEnable &&
                      <DashboardPopupTabButton
                        settingIconUrl="/icondata-export-icon@2x.png"
                        buttonText={LL0().dashboard.dataExport()}
                        noticeNumber="20"
                        showChip={false}
                        showInfoIcon={false}
                        onClick={openDataExportPopup}
                      />
                    }
                    <DashboardPopupTabButton
                      settingIconUrl="/icondashboard-terminal-icon@2x.png"
                      buttonText={LL0().payment.terminal()}
                      noticeNumber="20"
                      showChip={false}
                      showInfoIcon={false}
                      onClick={() => onNavigate(PosScreen.TERMINAL)}
                    />
                  </>
                }

                {/*<DashboardPopupTabButton*/}
                {/*  settingIconUrl="/iconweb-menu-icon@2x.png"*/}
                {/*  buttonText={LL0().dashboard.webMenu()}*/}
                {/*  noticeNumber="20"*/}
                {/*  showChip={false}*/}
                {/*  showInfoIcon={false}*/}
                {/*  onClick={() => onNavigate(PosScreen.WEB_MENU)}*/}
                {/*/>*/}
              </div>
            </div>
          }

        </div>
        <img
          className="absolute my-0 mx-[!important] top-[7px] right-[7px] w-[25px] h-[25.4px] cursor-pointer z-[1]"
          alt=""
          src="/close-tab.svg"
          onClick={onClose}
        />
      </div>
      {isDataExportPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeDataExportPopup}
        >
          <DataExportPopup onClose={closeDataExportPopup} />
        </PortalPopup>
      )}
      {isOnlineMenuPopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeOnlineMenuPopup}
        >
          <OnlineMenuPopup onClose={closeOnlineMenuPopup} />
        </PortalPopup>
      )}
      {isDeveloperOnlyVerifyPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setDeveloperOnlyVerifyPopuOpen(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setDeveloperOnlyVerifyPopuOpen(false)}
            password={DEVELOPER_PASSWORD}
            onComplete={() => {
              onClose?.();
              router.screen = PosScreen.DEVELOPER;
            }}
          />
        </PortalPopup>
      }
      {isInstallationVerifyPopuOpen &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setDeveloperOnlyVerifyPopuOpen(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setInstallationVerifyPopuOpen(false)}
            password={INSTALLATION_PASSWORD}
            onComplete={() => {
              onClose?.();
              router.screen = PosScreen.INSTALLATION;
            }}
          />
        </PortalPopup>
      }
    </>
  );
};

export default OrderScreenFunctionsPopup;