import debug from 'debug'
import _ from 'lodash'

import { dataLock } from '@/data/DataUtils.ts'
import { PrintScripts, type PrintingScripts } from '@/data/PrintScripts'
import { batch, signal, useEffectOn } from '@/react/core/reactive.ts'
import { convertToBase64Png } from "@/shared/utils"
import { bound, ensureExclusive, skipIf } from '@/shared/decorators'

import { VPrinter } from '../Printer/VPrinter'
import { generateSignalDataCount } from '../utils/generateSignalDataAccess'

const log = debug(`dev:virtual-printer`)

const IMAGES_PER_PAGE = 12
export const IMAGE_HEIGHT = 240

const [images0, setImages0] = signal<PrintScripts[]>([])
const [currentPage, setCurrentPage] = signal(0)
const [isLastPage, setIsLastPage] = signal(false)
const [totalImageCount, makeTotalImageCountAvailable] = generateSignalDataCount(PrintScripts, { debounce: 500 })

const loadedPages = [] as number[]

export { images0, isLastPage, totalImageCount }

class VirtualPrinterLogic {
  @ensureExclusive()
  async renderImageFromScripts(scripts: PrintingScripts): Promise<string> {
    // console.log('🖼 Rendering image from scripts...')
    const raster = await new VPrinter({ escPOS: false }).getRasterFromSavedScript(_.cloneDeep(scripts));
    const base64 = await convertToBase64Png(raster)
    // if (import.meta.env.DEV) printImageToConsole(`Rendered image from scripts (${scripts.length} items)`, base64)
    return base64
  }

  @skipIf(([page = 0]) => loadedPages.includes(page))
  async loadPageData(page = 0): Promise<void> {
    log(`📦 Loading page ${page}...`)
    await dataLock.acquireAsync()
    const rows = await PrintScripts.find({
      skip: page * IMAGES_PER_PAGE,
      limit: IMAGES_PER_PAGE + 1, // +1 to check if there are more
      sort: [{ date: 'desc' }],
    }).exec()
    const isLastPage = rows[IMAGES_PER_PAGE] === undefined
    const data = rows.map(row => row.toMutableJSON()).slice(0, IMAGES_PER_PAGE)

    batch(() => {
      setCurrentPage(page)
      setIsLastPage(isLastPage)
      setImages0(pre => [...pre, ...data])
    })
    loadedPages.push(page)
    log(`📦 Loaded page ${page} with ${data.length} items, isLastPage: ${isLastPage}`)
  }

  @bound()
  async handleEndReached(itemIndex: number): Promise<void> {
    if (isLastPage()) {
      log('🪲 Last page reached', currentPage())
      return
    }
    const page = Math.floor(itemIndex / IMAGES_PER_PAGE)
    log(`🪲 Reaching latest item (index: ${itemIndex}, in page: ${page}), fetching next page...`)
    await this.loadPageData(currentPage() + 1)
  }

  reset(): void {
    log('🧹 Resetting virtual printer images...')
    batch(() => {
      loadedPages.splice(0, loadedPages.length)
      setCurrentPage(0)
      setIsLastPage(false)
      setImages0([])
    })
  }
}

export const virtualPrinterLogic = new VirtualPrinterLogic()

export function makeImagesAvailable(): void {
  makeTotalImageCountAvailable()
  useEffectOn([totalImageCount], () => {
    log(`📦 Total image count changed (${totalImageCount()}) reloading page data...`)
    virtualPrinterLogic.reset()
    if (totalImageCount() > 0) {
      virtualPrinterLogic.loadPageData() // reload the first page
    } else {
      log('📦 No image data available')
      setIsLastPage(true)
    }
  })
}

// export const fetchImage = async (data: any) => {
//    const buffer = Buffer.from(data, 'base64');
//     const decompressed = pako.inflate(buffer);
//     const raster = rasterSchema.decode(Buffer.from(decompressed));
//     const base64 = await convertToBase64Png(raster);
//     setImgSrc(base64);
//     return base64;
// }
// effectOn(
//   [imagePage, imageV1],
//   async () => {
//     await dataLock.acquireAsync()
//     const images = await PrintImage.find({
//       sort: [{ date: 'desc' }],
//       limit: IMAGES_PER_PAGE,
//       skip: IMAGES_PER_PAGE * (imagePage() - 1)
//     }).exec()

//     setIsReachingEnd(images.length === 0)

//     if (imagePage() === 1) {
//       setImages0([...images])
//     } else {
//       setImages0(prev => [...prev, ...images])
//     }

//     setIsValidatingImage(false)

//     PrintImage.$.subscribe(p => {
//       setImagePage(0)
//     })

//   }, { defer: true }
// )

// export const makeImagesPageAvailable = () => {
//   useAsyncEffect(async () => {
//     if (imagePage() === 0) {
//       await dataLock.acquireAsync()
//       setImagePage(1)
//     }

//   }, [imagePage()])
// }
