import { type FunctionComponent, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import type { OrderItem } from "@/pos/OrderType.ts";
import { setItemStatus } from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type KitchenMonitorItemType = {
  orderId: string
  quantity?: number;
  itemName?: string;
  modifiers?: string;
  isCheckedAll?: boolean;
  orderStatus?: "new" | "prepare" | "done";
  item?: OrderItem;
  className?: string;
};

const KitchenMonitorItem: FunctionComponent<KitchenMonitorItemType> = (
  {
    orderId,
    quantity = 3,
    itemName = "Chicken soup",
    modifiers = "(khong hanh, them thit)",
    orderStatus = "new",
    isCheckedAll = false,
    item,
    className
  }) => {

  itemName = item?.name || ''
  quantity = item?.quantity || 0
  modifiers = item?.modifiers.map(modifier => modifier.name).join(", ") || ''
  const course = item?.course || 0
  const isTakeAway = !!item?.takeAway
  const size = (deviceSetting0()?.screenConfig?.kitchenFontSize || 14)
  const modifierSize = size > 25 ? 25 : size
  const quantitySize = size > 22 ? 22 : size

  return (
    <div
      style={{ fontSize: quantitySize }}
      className={`flex gap-2 w-full flex-row items-center justify-between py-0.5 px-2 box-border relative text-left text-red-solid-red-660-8f181d font-mulish h-auto ${className}`}>
      <div className="absolute my-0 mx-[!important] h-[calc(100%_+_1px)] w-[calc(100%_+_1px)] top-[-0.5px] right-[-0.5px] bottom-[-0.5px] left-[-0.5px] box-border z-[2] border-[1px] border-solid"
           style={{
             backgroundColor: orderStatus === "new" ? "#FFF4ED" : orderStatus === "prepare" ? "#F5FBEC" : "#F7F7FF",
             borderColor: orderStatus === "new" ? "#FFF4ED" : orderStatus === "prepare" ? "#F5FBEC" : "#F7F7FF",
           }}
      />
      <div
        style={{ gap: size > 20 ? 8 : 14 }}
        className="flex-1 flex flex-row items-start justify-start z-[3]">
        <b className={`relative flex items-center w-[26px] shrink-0`}
          style={{lineHeight: `${size < 18 ? size : size * 1.2}px`}}
        >
          {quantity}x
        </b>
        <div
          style={{ fontSize: (deviceSetting0()?.screenConfig?.kitchenFontSize || 14) + 2 }}
          className="flex-1 flex flex-col items-start justify-start gap-[2px] text-black-solid-black-1000-000000">
          <div className={`self-stretch max-w-[220px] break-words relative font-semibold ${item?.isKitchenDone && "line-through"}`}
            style={{lineHeight: `${(deviceSetting0()?.screenConfig?.kitchenFontSize || 14)}px`}}
          >
            {itemName}
          </div>
          {course > 1 &&
            <div
              style={{ fontSize: quantitySize }}
              className="flex-1 flex flex-col items-start justify-center gap-[2px] text-black-solid-black-1000-000000">
              <div className={`self-stretch relative leading-[15px]`}>
                ({LL0().order.course({ course })})
              </div>
            </div>}
          {isTakeAway &&
            <div
              style={{ fontSize: quantitySize }}
              className="flex-1 flex flex-col items-start justify-center gap-[2px] text-black-solid-black-1000-000000">
              <div className={`self-stretch relative leading-[15px]`}>
                ({LL0().order.takeAway()})
              </div>
            </div>}
          <i
            style={{ fontSize: modifierSize, lineHeight: `${modifierSize}px` }}
            className={`self-stretch relative text-black-solid-black-870-282828 ${item?.isKitchenDone && "line-through"}`}>
            {modifiers}
          </i>
        </div>
      </div>
      <div className="flex flex-row items-center justify-start gap-[4px] z-[4]">
        <div className="relative w-5 h-5">
          <FormControlLabel
            className="absolute h-[120%] w-[120%] top-[-10%] right-[-10%] bottom-[-10%] left-[-10%]"
            label=""
            control={
              <Checkbox color="primary" checked={item?.isKitchenDone} onChange={async (e) => {
                e.stopPropagation()
                await setItemStatus(orderId, item!._id!, e.target.checked)
              }} />}
          />
        </div>
      </div>
    </div>
  );
};

export default KitchenMonitorItem;
