import { type FunctionComponent } from "react";
import { FormControl, FormHelperText, MenuItem, Select, } from "@mui/material";
import { LL0 } from "@/react/core/I18nService.tsx";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import {
  createNewDiscount,
  currentItem,
  type Discount,
  setInputControllers,
  setOrderDiscount,
  setOrderDiscountItem
} from '@/react/OrderView/OrderView.tsx'
import { useSignal } from "@/react/core/reactive.ts";
import { LL3 } from "@/react/core/I18nCurrency.tsx";

export type EditDiscountLabelOtherType = {
  onClose?: () => void;
};

const EditDiscountLabelOther: FunctionComponent<EditDiscountLabelOtherType> = ({
  onClose,
}) => {

  const [discountLabel, setDiscountLabel] = useSignal<Discount>({ label: "", type: "percent", value: 0 })
  const handleAddDiscountLabel = async () => {
    if (!discountLabel().label || !discountLabel().value) return;
    await createNewDiscount(discountLabel())
    setOrderDiscount(discountLabel())
    currentItem() && setOrderDiscountItem(discountLabel())
    onClose?.()
  }

  return (
    <div
      className="add-label-popup-order w-full h-[85%] relative rounded-md bg-white overflow-hidden flex flex-col items-center justify-start gap-[8px] min-w-[138px] max-w-full max-h-full text-left text-mini text-blue-solid-blue-500-0051c1 font-mulish">
      <div
        className="self-stretch bg-white flex flex-row items-center justify-between pt-3.5 px-3.5 pb-2 Tablet_600:p-2  border-b-[1px] border-solid border-gray-solid-gray-150-eeeeee">
        <b className="flex-1 relative">{LL0().order.setupDiscountLabel()}</b>
        <img
          className="w-6 relative h-6 overflow-hidden shrink-0 object-cover cursor-pointer"
          alt=""
          src="/icong-close-popup-icon--grey12@2x.png"
          onClick={onClose}
        />
      </div>
      <div
        className="overflow-y-auto no-scrollbar self-stretch flex-1 flex Tablet_600:p-0 flex-col items-center justify-between py-2 px-4 gap-[12px] text-base text-grey-grey-darken-3">
        <div className="self-stretch Tablet_600:text-sm flex flex-col items-center Tablet_600:p-2 justify-start pt-0 px-0 pb-6 gap-[12px]">
          <div className="self-stretch flex flex-row items-center Tablet_600:gap-0">
            <div className="relative font-extrabold inline-block min-w-[100px]">
              {LL0().order.discountName()}:
            </div>
            <Input
              refInputController={i => {
                i.setFocus(true);
                return setInputControllers?.([i])
              }}
              className="font-mulish italic text-smi text-gray-400 w-full"
              value={discountLabel().label}
              onChange={value => setDiscountLabel(prev => ({ ...prev, label: value }))}
            />
          </div>
          <div className="self-stretch flex flex-row items-center Tablet_600:gap-0">
            <div className="relative font-extrabold inline-block min-w-[100px]">
              {LL0().order.chooseType()}:
            </div>
            <FormControl className="flex-1" variant="outlined" size="small">
              <Select color="primary" size="small"
                      value={discountLabel().type}
                      onChange={e => setDiscountLabel(prev => ({
                        ...prev,
                        type: e.target.value as "amount" | "percent"
                      }))}
              >
                <MenuItem value="amount">{LL0().discount.amount()}</MenuItem>
                <MenuItem value="percent">{LL0().discount.percent()}</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
          <div className="self-stretch flex flex-row items-center Tablet_600:gap-0">
            <div className="relative font-extrabold inline-block min-w-[100px]">
              {LL0().fnBtn.value()}:
            </div>
            <div
              className="flex-1 rounded bg-white box-border h-[39px] flex flex-row items-center justify-start relative text-smi text-black-solid-black-1000-000000 border-[1px] border-solid border-silver-100">
              <div
                className="absolute my-0 mx-[!important] h-full w-full top-[-1px] right-[1px] bottom-[1px] left-[-1px] rounded-10xs box-border hidden z-[0] border-[1px] border-solid border-cyan-solid-cyan-250-8aeaff" />
              <div
                className="self-stretch rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs bg-gray-solid-gray-130-f4f4f4-200 box-border w-[40.2px] flex flex-row items-center justify-center relative gap-[0px_8px] z-[1] border-r-[0.4px] border-solid border-silver-100">
                <div
                  className="absolute my-0 mx-[!important] h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-tl-10xs rounded-tr-none rounded-br-none rounded-bl-10xs bg-cyan-solid-cyan-170-ceffff box-border hidden z-[0] border-r-[0.2px] border-solid border-cyan-solid-cyan-250-8aeaff" />
                <div
                  className="relative font-semibold z-[1]">{discountLabel().type === "amount" ? LL3().common.currency() : "%"}</div>
              </div>
              <Input
                refInputController={i => {
                  return setInputControllers?.([i])
                }}
                className="font-mulish italic text-smi text-gray-400 w-full border-0"
                value={discountLabel().value.toString()}
                onChange={value => {
                  if (isNaN(Number(value))) return;
                  setDiscountLabel(prev => ({ ...prev, value: Number(value) }))
                }}
              />
            </div>
          </div>
        </div>
        <TableManualKeyboard
          inputController={inputController0}
          onEnter={handleAddDiscountLabel}
        />
      </div>
    </div>
  );
};

export default EditDiscountLabelOther;
