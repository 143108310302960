import { type FunctionComponent, useCallback, useEffect, useState } from "react";
import { FormControlLabel, Switch, } from "@mui/material";
import TableManualKeyboard from "@/react/core/TableManualKeyboard.tsx";
import Input, { inputController0 } from "@/react/core/Input.tsx";
import PortalPopup from "@payment/PortalPopup.tsx";
import { useSignal } from "@/react/core/reactive.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import clsx from "clsx";
import { addVoucher, checkVoucherStatus, genVoucherCode, redeemVoucher } from "@/react/VoucherView/VoucherView.tsx";
import type { TOrder } from "@/pos/OrderType.ts";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import MissingPopup from "@/react/VoucherView/MissingPopup.tsx";
import ConfirmApplyVoucherPopupPlugin from "@vertical-order-view/ConfirmApplyVoucherPopupPlugin.tsx";

export type VoucherVerticalPopupType = {
  className?: string;
  onClose?: () => void;
  order?: TOrder;
  seat?: number | null;
};

export enum VoucherTabs {
  CREATE,
  REDEEM,
  OTHERS
}

export interface VoucherInfo {
  code?: string,
  amount?: string,
  price?: string,
  firstName?: string,
  lastName?: string,
  expiryDate?: any,
  value?: string,
  used?: string
}

const VoucherVerticalPopup: FunctionComponent<VoucherVerticalPopupType> = ({
  className = "",
  onClose,
  order,
  seat
}) => {
  const [isNewDeliveryCustomerInfoMOpen, setNewDeliveryCustomerInfoMOpen] =
    useState(false);
  const [isConfirmApplyVoucher, setIsConfirmApplyVoucher] =
    useState(false)

  const openNewDeliveryCustomerInfoM = useCallback(() => {
    setNewDeliveryCustomerInfoMOpen(true);
  }, []);

  const closeNewDeliveryCustomerInfoM = useCallback(() => {
    setNewDeliveryCustomerInfoMOpen(false);
  }, []);

  const openConfirmApplyVoucher = useCallback(() => {
    setIsConfirmApplyVoucher(true);
  }, []);

  const closeConfirmApplyVoucher = useCallback(() => {
    setIsConfirmApplyVoucher(false);
  }, []);

  const [tab, setTab] = useSignal<VoucherTabs>(VoucherTabs.REDEEM)
  const [voucherInfo, setVoucherInfo] = useSignal<VoucherInfo>()
  const [isEqualPrice, setIsEqualPrice] = useSignal<boolean>(true)
  const [missing, setMissing] = useSignal<string>('')
  const [message, setMessage] = useSignal<string>()
  const [voucherExists, setVoucherExists] = useSignal<boolean>(false)
  const seatNumber = seat != null && typeof seat === 'number' ? seat : undefined
  console.log('seatNumber', seatNumber)

  const checkRequiredFields = useCallback((): boolean => {
    if (!voucherInfo()?.code) {
      setMissing(LL0().voucher.voucherCode());
      openNewDeliveryCustomerInfoM();
      return false;
    } else if (!voucherInfo()?.amount) {
      setMissing(LL0().voucher.voucherAmount());
      openNewDeliveryCustomerInfoM();
      return false;
    } else if (!voucherInfo()?.price && !isEqualPrice() && tab() !== VoucherTabs.REDEEM) {
      setMissing(LL0().voucher.voucherPrice());
      openNewDeliveryCustomerInfoM();
      return false;
    }
    return true;
  }, [missing()]);

  const handleAddVoucher = useCallback(() => {
    console.log('vertical popup', seatNumber)
    if (voucherExists()) return;
    if (checkRequiredFields()) {
      addVoucher({
        code: voucherInfo().code!,
        price: isEqualPrice() ? +voucherInfo().amount! : +voucherInfo().price!,
        voucher: {
          amount: +voucherInfo().amount!,
          firstName: voucherInfo().firstName,
          lastName: voucherInfo().lastName,
        },
        seat: seatNumber,
      }, order!)
      onClose?.()
    }
  }, [])

  const handleRedeemVoucher = useCallback(async () => {
    if (voucherExists() && tab() === VoucherTabs.CREATE) return;
    console.log('vertical popup redeem', seatNumber)
    if (checkRequiredFields()) {
      await redeemVoucher(
        voucherInfo().code!,
        +voucherInfo().amount!,
        order!,
        {
          firstName: voucherInfo().firstName,
          lastName: voucherInfo().lastName
        },
        seatNumber
      )
      onClose?.()
    }
  }, [])

  useEffect(() => {
    const data = checkVoucherStatus(voucherInfo()?.code || '')
    if (data && data.valid) {
      setMessage(`${LL0().voucher.remainingValue()}: ${data.remainingValue}`)
      if (data.voucher) {
        if (tab() === VoucherTabs.CREATE) {
          setVoucherExists(true)
          return
        }
        setVoucherInfo(p =>
          ({
            ...p,
            amount: data.remainingValue.toString(),
            lastName: data.voucher?.customerLastName?.toString(),
            firstName: data.voucher?.customerFirstName?.toString(),
            expiryDate: data.voucher?.expiredAt?.toString(),
            value: data.voucher?.totalValue?.toString(),
            used: data.voucher?.usedValue?.toString()
          }))

      }
    } else if (data && data.message) {
      if (tab() === VoucherTabs.CREATE) {
        setVoucherExists(false)
      }
      setMessage(data.message)
      if (voucherInfo()?.code === '') {
        setVoucherInfo(p =>
          ({
            ...p,
            amount: '',
            lastName: '',
            firstName: '',
          }))
      }
    }
  }, [voucherInfo()?.code]);

  return (
    <>
      <div
        className={`flex w-full min-w-[220px] h-[90%] relative rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex-col items-center justify-start max-w-full max-h-full text-left text-3xl text-black-solid-black-1000-000000 font-mulish ${className}`}
      >
        <div
          className="self-stretch rounded-t rounded-b-none bg-white-solid-white-400-f2f2f2 border-white-solid-white-100-ffffff border-b-[1px] border-solid box-border h-10 flex flex-row items-center justify-between py-0.5 px-3 z-[1] text-mini text-blue-solid-blue-500-0051c1 sm:h-[42px] sm:pl-4 sm:pr-4 sm:box-border">
          <div className='rounded-[30px] px-3 h-[32px] flex items-center justify-center gap-2'
               style={{
                 background: 'linear-gradient(180deg, #82E1FF 0%, #2AACF5 100%)',
               }}
               ref={makeRipple}
               onClick={() => {
                 onClose?.()
                 router.screen = PosScreen.VERTICAL_VOUCHER as PosScreen
               }}
          >
            <img className="w-[24px] relative h-[24px] object-cover z-[0]" alt="" src="/Create-voucher-Ver1.png" />
            <b className="relative text-white">{LL0().voucher.goBackToVoucherScreen()}</b>
          </div>
          <div className="flex flex-row items-center justify-end gap-4 text-center text-white-solid-white-100-ffffff">

            <img
              className="w-[22px] relative h-[22px] object-cover cursor-pointer"
              alt=""
              src="/icongeneralclose-popup-icon--grey@2x.png"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="no-scrollbar overflow-y-auto self-stretch flex-1 gap-4 flex flex-col items-start justify-start z-[0] text-xs">
          <div className="self-stretch flex p-2 flex-col overflow-hidden items-center justify-center text-center mq432:box-border mq340:box-border">
            <div className="self-stretch rounded-10xs flex flex-row items-start justify-start border-[1px] border-solid border-gray-solid-gray-170-e3e4e5 mq340:gap-[2px]">
              <div
                ref={makeRipple}
                onClick={() => {
                  setTab(VoucherTabs.CREATE)
                  setVoucherInfo({})
                }}
                className={clsx("flex-1 rounded-10xs h-8 flex flex-row items-center justify-center py-0 box-border relative",
                  { 'bg-blue-solid-blue-400-0091ff text-white': tab() === VoucherTabs.CREATE })}>
                <div className="flex-1 relative leading-[15px] capitalize font-semibold z-[2] text-sm mq432:text-smi">
                  {LL0().restaurant.createVoucher()}
                </div>
              </div>
              <div
                ref={makeRipple}
                onClick={() => {
                  setTab(VoucherTabs.REDEEM)
                  setVoucherInfo({})
                }}
                className={clsx("flex-1 rounded-10xs h-8 flex flex-row items-center justify-center py-0 box-border relative",
                  { 'bg-blue-solid-blue-400-0091ff text-white': tab() === VoucherTabs.REDEEM })}>
                <div className="flex-1 relative leading-[15px] capitalize font-semibold z-[2] text-sm mq432:text-smi">
                  {LL0().restaurant.redeemVoucher()}
                </div>
              </div>
            </div>
          </div>
          {tab() === VoucherTabs.CREATE && <div
            className="no-scrollbar gap-2.5 self-stretch flex-1 bg-white-solid-white-100-ffffff flex overflow-y-auto flex-col items-between justify-between box-border text-sm">
            <div className="overflow-y-auto no-scrollbar self-stretch text-smi flex-1 p-2 flex flex-col items-start justify-start gap-2.5">
              <div className="self-stretch flex flex-row items-end justify-start gap-2.5">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <div className="self-stretch relative">{LL0().voucher.voucherCode()}</div>
                  <Input
                    className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.code || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, code: value }))}
                  />
                </div>
                <div
                  className="rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden flex flex-row items-center justify-center py-[9px] px-2 box-border text-center text-white-solid-white-100-ffffff"
                  ref={makeRipple}
                  onClick={() => {
                    const code = genVoucherCode()
                    setVoucherInfo(p => ({ ...p, code: code }))
                  }}
                >
                  <b className="relative">{LL0().voucher.autoGen()}</b>
                </div>
              </div>
              {voucherExists() &&
                <span className="text-sm ml-2">{LL0().voucher.voucherExists()}</span>
              }
              <div className="self-stretch flex flex-row items-center justify-start gap-3">
                {!isEqualPrice() && <div className="self-stretch flex-1 flex-col items-start justify-start">
                  <div className="self-stretch relative">{LL0().voucher.voucherPrice()}</div>
                  <Input
                    className="w-full self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.price || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, price: value }))}
                  />
                </div>}
                <div className="self-stretch flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative">{LL0().voucher.voucherAmount()}</div>
                  <Input
                    className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.amount || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, amount: value }))}
                  />
                </div>
              </div>
              <div className="self-stretch flex flex-row items-center justify-start gap-3">
                <div className="flex-1 flex flex-col items-start justify-center">
                  <div className="self-stretch relative">
                    {LL0().voucher.customersFirstName()}
                  </div>
                  <Input
                    className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.firstName || ''}
                    onChange={value => setVoucherInfo(p => ({ ...p, firstName: value }))}
                  />
                </div>
                <div className="self-stretch flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    {LL0().voucher.customersLastName()}
                  </div>
                  <Input
                    className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.lastName || ''}
                    onChange={value => setVoucherInfo(p => ({ ...p, lastName: value }))}
                  />
                </div>
              </div>
              <div className="self-stretch h-6 grid flex-row items-center justify-start gap-2 grid-flow-col">
                <div className="self-stretch w-10 relative">
                  <FormControlLabel
                    label={''}
                    className="absolute h-[120%] w-full top-[-10%] right-[0%] bottom-[-10%] left-[0%]"
                    control={<Switch color="primary"
                                     checked={isEqualPrice()}
                                     onChange={(e, checked) => setIsEqualPrice(checked)}
                    />}
                  />
                </div>
                <div className="self-stretch flex-1 relative font-sm flex items-center Tablet_768:text-sm">
                  {LL0().voucher.voucherPriceEqualsVoucherValue()}
                </div>
              </div>
            </div>
            <div className={'flex flex-col gap-1 shrink-0'}>
              <div
                className="h-[36px] rounded-[6px] bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 flex-shrink-0"
                ref={makeRipple}
                onClick={async () => {
                  handleAddVoucher()
                }}
              >
                <b className="relative mq432:text-smi text-white">{LL0().ui.add()}</b>
              </div>
              <TableManualKeyboard
                inputController={inputController0}
                onEnter={handleAddVoucher}
              />
            </div>
          </div>
          }
          {tab() === VoucherTabs.REDEEM &&
            <div
              className="no-scrollbar gap-2.5 self-stretch flex-1 bg-white-solid-white-100-ffffff flex overflow-y-auto flex-col items-between justify-between box-border text-sm">
              <div className="no-scrollbar flex flex-1 flex-col gap-2.5 items-start justify-start overflow-y-auto p-2 self-stretch">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    {LL0().voucher.enterCode()}
                  </div>
                  <Input
                    className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.code || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, code: value }))}
                  />
                </div>
                {voucherInfo()?.code &&
                  <span className="text-sm ml-2">{message()}</span>
                }
                <div className="self-stretch flex flex-col items-start justify-start">
                  <div className="self-stretch relative">
                    {LL0().voucher.enterAmount()}
                  </div>
                  <Input
                    className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                    value={voucherInfo()?.amount || ''}
                    onChange={value =>
                      setVoucherInfo(p => ({ ...p, amount: value }))}
                  />
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-3 Mobile_480:flex-col">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start">
                    <div className="self-stretch relative">
                      {LL0().voucher.customersFirstName()}
                    </div>
                    <Input
                      className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                      value={voucherInfo()?.firstName || ''}
                      onChange={value => setVoucherInfo(p => ({ ...p, firstName: value }))}
                    />
                  </div>
                  <div className="self-stretch flex-1 flex flex-col items-start justify-start">
                    <div className="self-stretch relative">
                      {LL0().voucher.customersLastName()}
                    </div>
                    <Input
                      className="self-stretch font-mulish font-sm text-base text-black-solid-black-900-1e1e23"
                      value={voucherInfo()?.lastName || ''}
                      onChange={value => setVoucherInfo(p => ({ ...p, lastName: value }))}
                    />
                  </div>
                </div>
              </div>
              <div className={'flex flex-col gap-1 shrink-0'}>
                <div
                  className="h-[36px] rounded-[6px] bg-blue-solid-blue-420-2979ff overflow-hidden flex flex-row items-center justify-center py-[9px] px-4 flex-shrink-0"
                  ref={makeRipple}
                  onClick={async () => {
                    openConfirmApplyVoucher()
                  }}
                >
                  <b className="relative mq432:text-smi text-white">{LL0().ui.add()}</b>
                </div>
                <TableManualKeyboard
                  inputController={inputController0}
                  onEnter={handleRedeemVoucher}
                />
              </div>
            </div>
          }
          {tab() === VoucherTabs.OTHERS && <div
            className="no-scrollbar self-stretch flex-1 bg-white-solid-white-100-ffffff overflow-y-auto flex-col items-start justify-start pt-3 px-3 pb-2 box-border text-center text-mini text-white-solid-white-100-ffffff">
            <div className="self-stretch flex-1 flex flex-col items-center justify-center pt-0 px-0 pb-[200px]">
              <div
                className="shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)] rounded-11xl [background:linear-gradient(180deg,_rgba(139,_184,_255,_0.8),_rgba(38,_93,_219,_0.8))] h-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-center py-0 px-4 box-border gap-2.5 cursor-pointer"
                ref={makeRipple}
                onClick={() => {
                  router.screen = PosScreen.VOUCHER
                  onClose?.()
                }}
              >
                <img
                  className="w-[30px] relative h-[30px] object-cover sm:w-[60px] sm:h-[60px] Tablet_768:w-14 Tablet_768:h-14 Tablet_600:w-[50px] Tablet_600:h-[50px]"
                  alt=""
                  src="/icongeneral-settings-icon@2x.png"
                />
                <div className="relative leading-[16px] font-semibold">
                  {LL0().voucher.goToVoucherScreen()}
                </div>
              </div>
            </div>

          </div>}

        </div>
      </div>
      {isNewDeliveryCustomerInfoMOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeNewDeliveryCustomerInfoM}
        >
          <MissingPopup
            missingText={missing()}
            onClose={closeNewDeliveryCustomerInfoM}
          />
        </PortalPopup>
      )}
      {isConfirmApplyVoucher && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeConfirmApplyVoucher}
        >
          <ConfirmApplyVoucherPopupPlugin
            onClose={closeConfirmApplyVoucher}
            voucherInfo={voucherInfo()}
            handleRedeemVoucher={handleRedeemVoucher}
          />
        </PortalPopup>
      )}
    </>
  );
};

export default VoucherVerticalPopup;
