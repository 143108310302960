import { type FunctionComponent, useCallback, useState } from 'react';
import EditMenuDeleteConfirmatio from '../Popups/EditMenuDeleteConfirmatio.tsx';
import PortalPopup from "@/react/core/PortalPopup.tsx";
import EditCategoryForm from './EditCategoryForm.tsx';
import KeyboardSettingForm from './KeyboardSettingForm.tsx';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { router } from '@/pos/PosRouter.ts';
import {
  checkTaxAndPrinter,
  editorMode0,
  ITEM_MODE,
  onClickSwitch,
  onDelete0, product0,
  productLayout0,
  tempItem,
} from '@/react/EditMenuView/EditMenuView.tsx';
import clsx from 'clsx';
import { LL0 } from '@/react/core/I18nService.tsx';
import EditNonFixedItemForm from '@edit-menu/EditMenuForm/EditNonFixedItemForm.tsx';
import EditModifierForm from '@edit-menu/EditMenuForm/EditModifierForm.tsx';
import EditNormalItemForm from '@edit-menu/EditMenuForm/EditNormalItemForm.tsx';
import EditTitleItemForm from './EditTitleItemForm.tsx';
import { signal } from '@/react/core/reactive.ts';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import { generalSetting0, mainScreen } from "@/data/PosSettingsSignal.ts";
import VerticalEditMenuForm from "@vertical-edit-menu/VerticalEditMenuForm.tsx";
import {
  onClickCopy,
  onClickDelete,
  onClickSwitchVertical,
  setOpenPopupImport
} from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";


export const [showPopup, setShowPopup] = signal<boolean>(false);
export const [taxResult, setTaxResult] = signal<any[]>([]);
export const [menuTaxResult1, setMenuTaxResult1] = signal<any[]>([]);
export const [menuTaxResult2, setMenuTaxResult2] = signal<any[]>([]);
export const [printerResult, setPrinterResult] = signal<any[]>([]);

const EditMenuForm: FunctionComponent = () => {
  const [isEditMenuDeleteConfirmatioOpen, setEditMenuDeleteConfirmatioOpen] =
    useState(false);

  const openEditMenuDeleteConfirmatio = useCallback(() => {
    userFLow(`open edit menu delete confirmation`, {
      username: loginUser()?.name
    });
    setEditMenuDeleteConfirmatioOpen(true);
  }, []);

  const closeEditMenuDeleteConfirmatio = useCallback(() => {
    userFLow(`close edit menu delete confirmation`, {
      username: loginUser()?.name
    });
    setEditMenuDeleteConfirmatioOpen(false);
  }, []);


  const handleNotification = async () => {
    userFLow(`notification`, {
      username: loginUser()?.name
    });
    const result = await checkTaxAndPrinter();

    //when no product is created
    if (!result) {
      router.screen = mainScreen();
      return
    }

    const { resultTaxes, resultMenuTaxes, resultMenuTaxes2, resultPrinter } = result as any;
    const isGoOut = ![resultTaxes, resultMenuTaxes, resultMenuTaxes2, resultPrinter].some((list) => list?.length)

    if (isGoOut) {
      router.screen = mainScreen();
      return
    }

    setShowPopup(true);
    if (resultTaxes?.length > 0) setTaxResult(result?.resultTaxes ?? []);
    if (resultPrinter?.length > 0) setPrinterResult(result?.resultPrinter ?? [])
    if (resultMenuTaxes?.length > 0) setMenuTaxResult1(result?.resultMenuTaxes ?? []);
    if (resultMenuTaxes2?.length > 0) setMenuTaxResult2(result?.resultMenuTaxes2 ?? []);
  }

  return (
    <>
      <div className="self-stretch w-[310px] flex flex-col items-start justify-start z-[1] text-left text-mini text-black-solid-black-1000-000000 font-mulish">
        <div className="self-stretch bg-gray-solid-gray-170-e3e4e5 h-[50px] flex flex-row items-center justify-between py-0 px-2.5 box-border sm:h-[42px] sm:pl-2 sm:pr-2 sm:box-border Tablet_600:pl-1 Tablet_600:pr-1 Tablet_600:box-border">
          <div className="flex flex-row items-center justify-start gap-[8px] sm:gap-[4px] sm:pl-0 sm:box-border Tablet_600:gap-[2px]">
            {
              generalSetting0()?.useMenuV2 ?
                <>
                  {editorMode0() === ITEM_MODE.SECTION &&
                    <div
                      className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-1 box-border relative gap-0.5"
                      onClick={() => setOpenPopupImport(true)}
                      ref={makeRipple}
                    >
                      <img
                        className="w-5 relative h-5 object-cover z-[1]"
                        src="/icon-import-icon@2x.png"
                        alt="" />
                      <div
                        className="absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_41px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
                      <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().voucher.import()}</b>
                    </div>
                  }
                  {editorMode0() === ITEM_MODE.ITEM &&
                    <div
                      className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-1 box-border relative gap-2"
                      onClick={onClickCopy} ref={makeRipple}
                    >
                      <div
                        className="absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_41px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
                      <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.duplicate()}</b>
                    </div>
                  }
                  <div
                    className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-1 box-border relative gap-2"
                    onClick={onClickDelete}
                    ref={makeRipple}
                  >
                    <div
                      className="absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_40.5px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
                    <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.delete()}</b>
                  </div>
                  {(editorMode0() !== ITEM_MODE.KEYBOARD && editorMode0() !== ITEM_MODE.INGREDIENT) &&
                    <div
                      className="shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] rounded-11xl bg-white-solid-white-100-ffffff h-[34px] flex flex-row items-center justify-center py-0 px-1 box-border relative gap-2"
                      ref={makeRipple}
                      onClick={() => onClickSwitchVertical('swap')}
                    >
                      <div
                        className="absolute !m-[0] top-[calc(50%_-_17px)] left-[calc(50%_-_41px)] rounded-81xl bg-red-solid-red-300-ff4836 h-[34px] hidden z-[0]" />
                      <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.switch()}</b>
                    </div>
                  }
                </>
                :
                <>
                  {
                    editorMode0() !== ITEM_MODE.KEYBOARD &&
                    <>
                      {
                        editorMode0() === ITEM_MODE.ITEM &&
                        <div className={clsx("hover:bg-tomato rounded-11xl bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border Tablet_600:h-8",
                          !!tempItem()?.active && tempItem()?.mode === "copy" && "border-[2px] !border-tomato")}
                             onPointerDown={() => {
                         onClickSwitch("copy")
                         userFLow(`click copy item ${product0().name}`, {
                           itemId: product0()?._id,
                           username: loginUser()?.name
                         });
                       }}
                        >
                          <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.copy()}</b>
                        </div>
                      }
                      <button
                        disabled={editorMode0() === ITEM_MODE.CATEGORY}
                        className="disabled:opacity-30 disabled:cursor-not-allowed rounded-11xl bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border cursor-pointer hover:bg-tomato Tablet_600:h-8"
                        onClick={openEditMenuDeleteConfirmatio}
                      >
                        <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.delete()}</b>
                      </button>
                      <div className={clsx("hover:bg-tomato rounded-11xl bg-white-solid-white-100-ffffff shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] flex flex-row items-center justify-center py-0 px-2 box-border Tablet_600:h-8",
                        !!tempItem()?.active && tempItem()?.mode === "swap" && tempItem()?.type === editorMode0()
                        && "border-[2px] !border-tomato")}
                           onPointerDown={() => {
                       userFLow(`click swap item ${product0()?.name}`, {
                         itemId: product0()?._id,
                         username: loginUser()?.name
                       });
                       onClickSwitch("swap")
                     }}
                      >
                        <b className="relative sm:text-smi Tablet_600:text-smi">{LL0().ui.switch()}</b>
                      </div>
                    </>
                  }
                </>
            }
          </div>
          <div className="rounded-81xl bg-blue-500 shadow-[0px_0px_6px_rgba(0,_0,_0,_0.22)] h-[34px] overflow-hidden flex flex-row items-center justify-center py-0.5 px-2.5 box-border gap-[3px] text-center text-sm text-white-solid-white-100-ffffff"
               ref={makeRipple}
               onClick={handleNotification}
          >
            <img
              className="w-[30px] relative h-[30px] object-cover"
              alt=""
              src="/iconback-icon@2x.png"
            />
            <b className="relative leading-[17px] hidden">Button</b>
          </div>
        </div>
        <div className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start text-sm text-blue-solid-blue-500-0051c1">
          {generalSetting0()?.useMenuV2 ?
            <VerticalEditMenuForm />
            :
            <>
              {editorMode0() === ITEM_MODE.CATEGORY &&
                <EditCategoryForm />
              }
              {
                editorMode0() === ITEM_MODE.ITEM &&
                <>
                  {
                    productLayout0()?.type === "Text" ?
                      <EditTitleItemForm /> :
                      productLayout0()?.type === "Extra" ?
                        <EditModifierForm /> :
                        productLayout0()?.type === "Article" ?
                          <EditNormalItemForm /> :
                          <EditNonFixedItemForm />
                  }
                </>
              }
              {editorMode0() === ITEM_MODE.KEYBOARD &&
                <KeyboardSettingForm />
              }
            </>
          }
        </div>
      </div>
      {isEditMenuDeleteConfirmatioOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeEditMenuDeleteConfirmatio}
        >
          <EditMenuDeleteConfirmatio onClose={closeEditMenuDeleteConfirmatio} onComplete={() => onDelete0?.value?.()} />
        </PortalPopup>
      )}
    </>
  );
};

export default EditMenuForm;
