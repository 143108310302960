import { computed } from "@/react/core/reactive.ts";
import { posSettings0, tempServer } from "@/data/PosSettingsSignal.ts";
import { posSync0 } from "@/data/PosSyncState.ts";

export const server = computed<string | null>(() => {
  const server = posSync0()?.server ? posSync0().server : (tempServer() || posSettings0()[0]?.server)
  if (server && server !== localStorage.getItem('server')) {
    localStorage.setItem('server', server);
  }
  return server || localStorage.getItem('server')
});