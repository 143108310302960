import { type FunctionComponent } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, } from "@mui/material";
import { LL0 } from "@/react/core/I18nService.tsx";
import { getPrinterClassname, product0, pushDataVertical } from "@/react/EditMenuView/Vertical/EditMenuVertical.tsx";
import _ from "lodash";
import {
  activeSecondPrinter0,
  handleInputPopup,
  PRODUCT_COLORS,
  setActiveSecondPrinter0
} from "@/react/EditMenuView/EditMenuView.tsx";
import { PosScreen, router } from "@/pos/PosRouter.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import clsx from "clsx";
import { kitchenGroupPrinters0, labelPrinters0 } from "@/data/GroupPrinterHub.ts";
import { dineInTaxCategories0, takeAwayTaxCategories0, taxCategories0 } from "@/data/TaxCategoryHub.ts";
import { calculateTaxValues } from "@/react/EditMenuView/EditMenuUtils.ts";
import { isLabelPrinterEnable } from "@/react/Printer/PrinterSettingView.tsx";
import { dineIn, menuSelected, pricingLevelFactory } from "@/react/EditMenuView/EditPricingLevel.logic.ts";
import TextField from "@/react/core/TextField.tsx";
import { generalSetting0 } from "@/data/PosSettingsSignal.ts";
import { AccessPort, getAccessibility } from "@/react/DecentralizationPort.ts";
import Input3KeyboardPopUp from "@/react/core/Input3KeyboardPopUp2.tsx";

export type EditNonFixedItemFormType = {
  className?: string;
};

const VerticalEditNonFixedItemForm: FunctionComponent<EditNonFixedItemFormType> = ({
  className = "",
}) => {
  const {computedPrice,
    getMenusTax,
    computedMenuTaxes,
    updateMenuSelection,
    changeProductPrice,
    checkTakeAwayTax,
    checkDineInTax,
    checkOneTypeTax,
    getInfo} = pricingLevelFactory(product0())

  const {multipleEnable} = getInfo()
  return (
    <div
      className={`!flex !w-full w-[480px] hidden flex-col items-start justify-start pt-2 px-3 pb-4 box-border gap-4 text-left text-sm text-blue-solid-blue-500-0051c1 font-mulish lg:box-border mq350small:pl-3 mq350small:pr-3 mq350small:box-border ${className}`}
    >
      <div className="self-stretch flex flex-row items-center justify-start gap-3">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          {LL0().article.type()}:
        </b>
        <FormControl
          className="flex-1 font-mulish text-sm text-black-solid-black-600-424242"
          variant="outlined"
        >
          <InputLabel color="primary" />
          <Select color="primary" size="small"
                  value={product0()?.layout?.type || 'Article'}
                  onChange={(e) => {
                    const _productLayout = product0()?.layout;
                    if (_productLayout) _productLayout.type = e.target.value as any
                  }}>
            <MenuItem value="Article">{LL0().article.types.normalItem()}</MenuItem>
            <MenuItem value="NonFixed">{LL0().article.types.nonFixedItem()}</MenuItem>
            <MenuItem value="Extra">{LL0().article.types.extra()}</MenuItem>
          </Select>
          <FormHelperText />
        </FormControl>
      </div>
      <Input3KeyboardPopUp
        value={''}
        onSave={(name, id, price) => {
          if (!product0()) return;
          product0()!.name = name
          product0()!.id = id
          if (multipleEnable && !(menuSelected() === dineIn())) {
            product0()!.menuPrices!.find(m => m.menu === menuSelected())!.value = parseFloat(price || '0')
          } else {
            product0()!.price = parseFloat(price || '0')
          }
        }}>
        {(show, setShow, inputControllers) => (
          <>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.name()}</span>
                <span className="text-firebrick">*</span>
                <span>:</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={product0()?.name || ''}
                onChange={(e) => {
                  const _product = product0()
                  if (_product) _product.name = e.target.value
                }}
                onKeyboardIconClick={() => {
                  handleInputPopup(setShow, inputControllers, pushDataVertical, 0)
                }}
              />
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.id()}</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={product0()?.id || ''}
                onChange={(e) => {
                  const _product = product0()
                  if (_product) _product.id = e.target.value
                }}
                onKeyboardIconClick={() => {
                  handleInputPopup(setShow, inputControllers, pushDataVertical, 1)
                }}
              />
            </div>
            <div className="self-stretch flex flex-row items-center justify-start gap-[12px] text-sm">
              <b className="relative inline-block w-[50px] shrink-0 md:text-xs md:w-[43px]">
                <span>{LL0().article.price()}</span>
              </b>
              <TextField
                className="pl-2 flex justify-center overflow-hidden w-full font-mulish text-sm text-black-solid-black-600-424242 md:box-border"
                value={computedPrice() || ''}
                onChange={(e) => {
                  changeProductPrice(e.target.value)
                }}
                onKeyboardIconClick={() => {
                  if (!getAccessibility(AccessPort.EDIT_ITEM_PRICE)) return;
                  handleInputPopup(setShow, inputControllers, pushDataVertical, 2)
                }}
              />
            </div>
          </>
        )}
      </Input3KeyboardPopUp>
      <div className="self-stretch flex flex-row items-start justify-start gap-3 text-sm">
        <b className="w-[50px] relative inline-block shrink-0 md:text-xs md:w-[43px]">
          {LL0().ui.color()}:
        </b>
        <div className="flex-1 flex flex-row items-start justify-start flex-wrap content-start gap-3">
          {PRODUCT_COLORS.map((color, index) =>
            <div
              className="rounded-981xl bg-white-solid-white-100-ffffff flex flex-col items-start justify-start relative"
              key={index}
              style={{ backgroundColor: color }}
              onClick={() => {
                _.assign(product0()?.layout, { color })
              }}
            >
              <div
                className="w-10 relative rounded-981xl border-gray-solid-gray-320-d2d2d2 border-[1px] border-solid box-border h-10 z-[0]" />
              {product0()?.layout?.color === color &&
                <>
                  <div
                    className="w-10 absolute !m-[0] top-[-0.1px] left-[0px] rounded-981xl border-blue-solid-blue-450-1271ff border-[1px] border-solid box-border h-10 z-[1]" />
                  <img
                    className="w-4 absolute !m-[0] top-[0px] right-[0px] h-4 object-cover z-[2]"
                    alt=""
                    src="/iconedit-menuchecked-icon-ver1@2x.png"
                  />
                </>
              }
            </div>
          )}
        </div>
      </div>

      {/*<>fixme: set printer</>*/}
      <div className="self-stretch flex flex-col items-start justify-start gap-2.5">
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-start gap-1"
               onClick={() => router.screen = PosScreen.VERTICAL_PRINTER_SETTING}
               ref={makeRipple}>
            <img
              className="w-[25px] relative h-6 object-cover"
              alt=""
              src="/iconicon-screensedit-icon-ver1@2x.png"
            />
            <b className="relative [text-decoration:underline] sm:text-sm Tablet_600:text-smi">
              {LL0().productViewSetting.modifierEditorDialog.printer()}:
            </b>
          </div>
          {/*<>fixme: setting 2 printer</>*/}
          <div
            className={clsx('shadow-[0px_0px_6px_rgba(0,_0,_0,_0.14)] rounded bg-white-solid-white-100-ffffff flex flex-row items-center justify-center py-1 px-2 text-smi text-blue-solid-blue-420-2979ff',
              activeSecondPrinter0() && 'border-[2px] !border-[tomato]')}
            onClick={() => setActiveSecondPrinter0((v) => !v)}
          >
            <div className="relative">
              <b>+</b>
              <span className="text-black-solid-black-600-424242">
                  2 {LL0().editMenu.printers()}
                </span>
            </div>
          </div>
          {/*<>fixme: setting 2 printer</>*/}
        </div>
        <div
          className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-2 text-black-solid-black-880-1d1d26">
          {kitchenGroupPrinters0().map((groupPrinter, index) => (
            <div
              className={clsx('shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2',
                getPrinterClassname(groupPrinter))}
              key={index}
              onClick={() => {
                if (activeSecondPrinter0()) {
                  setActiveSecondPrinter0(false)
                  return _.assign(product0(), { groupPrinter2: groupPrinter._id })
                }
                const _product = product0();
                if (!_product) return;
                if (_product?.labelPrinter) {
                  _.assign(product0(), {
                    labelPrinter: "",
                  })
                }
                if (generalSetting0()?.taxType === 'one') {

                  _.assign(product0(), {
                    groupPrinter: groupPrinter._id,
                    groupPrinter2: "",
                  })

                  if (taxCategories0().length === 1) {
                    _.assign(product0(), {
                      taxCategory: taxCategories0()[0].name,
                      taxCategory2: taxCategories0()[0].name,
                      tax: taxCategories0()[0].value,
                      tax2: taxCategories0()[0].value,
                      taxComponents: taxCategories0()[0].components,
                      taxComponents2: taxCategories0()[0].components,
                    })
                  }
                } else {
                  const result = calculateTaxValues(groupPrinter);

                  _.assign(product0(), {
                    ...result,
                    groupPrinter: groupPrinter._id,
                    groupPrinter2: "",
                  })
                }
              }}
            >
              {groupPrinter.name}
            </div>
          ))}
          {isLabelPrinterEnable() && (
            <div
              className={clsx('shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2',
                getPrinterClassname(labelPrinters0()))}
              onClick={() => {
                const _product = product0();
                if (!_product) return;
                if (_product?.labelPrinter) {
                  _.assign(product0(), {
                    labelPrinter: "",
                  })
                  return
                }
                _.assign(product0(), {
                  labelPrinter: labelPrinters0()._id,
                })
                _.assign(product0(), {
                  groupPrinter: "",
                })
              }}
            >
              Label
            </div>
          )}
        </div>
      </div>


      {/*<>fixme: tax setting</>*/}
      <div className="self-stretch flex flex-row items-start justify-start gap-2.5 mq600:flex-col">
        {generalSetting0()?.taxType === 'two' &&
          <div
            className="flex-1 flex flex-row items-center justify-start gap-2.5 mq600:flex-col mq600:flex-[unset] mq600:self-stretch">
            {dineInTaxCategories0().length > 0 &&
              <div
                className="flex-1 flex flex-col items-start justify-start gap-3 mq600:flex-[unset] mq600:self-stretch">
                <b className="relative sm:text-sm">
                  {LL0().editMenu.dineInTax()}:
                </b>
                <div
                  className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-2 text-black-solid-black-880-1d1d26">
                  {dineInTaxCategories0().map((taxCategory, index) => (
                    <div
                      className={clsx('shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2',
                        checkDineInTax(taxCategory) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                      key={index}
                      onClick={() => {
                        if (menuSelected() === dineIn() || !multipleEnable) {
                          _.assign(product0(), {
                            tax: taxCategory.value,
                            taxCategory: taxCategory.name,
                            taxComponents: taxCategory.components
                          })
                        } else {
                          const taxData = { menu: menuSelected(), ref: taxCategory.name, value: taxCategory.value };
                          const menuTaxes = getMenusTax(1);
                          const updatedMenuTaxes = computedMenuTaxes(taxData, menuTaxes)
                          _.assign(product0(), { menuTaxes: updatedMenuTaxes });
                        }
                      }}>
                      {taxCategory.printLabel || `${taxCategory.value}%`}
                    </div>
                  ))}
                </div>
              </div>}
            {takeAwayTaxCategories0().length > 0 &&
              <div
                className="flex-1 flex flex-col items-start justify-start gap-3 mq600:flex-[unset] mq600:self-stretch">
                <b className="relative sm:text-sm">
                  {LL0().editMenu.takeAwayTax()}:
                </b>
                <div
                  className="self-stretch flex flex-row items-start justify-start flex-wrap content-start gap-2 text-black-solid-black-880-1d1d26">
                  {takeAwayTaxCategories0().map((taxCategory, index) => (
                    <div
                      className={clsx('shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2',
                        checkTakeAwayTax(taxCategory) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                      key={index}
                      onClick={() => {
                        if (menuSelected() === dineIn() || !multipleEnable) {
                          _.assign(product0(), {
                            tax2: taxCategory.value,
                            taxCategory2: taxCategory.name,
                            taxComponents2: taxCategory.components
                          })
                        } else {
                          const taxData = { menu: menuSelected(), ref: taxCategory.name, value: taxCategory.value };
                          const menuTaxes = getMenusTax(2);
                          const updatedMenuTaxes = computedMenuTaxes(taxData, menuTaxes)
                          _.assign(product0(), { menuTaxes2: updatedMenuTaxes });
                        }
                      }}>
                      {taxCategory.printLabel || `${taxCategory.value}%`}
                    </div>
                  ))}
                </div>
              </div>}
          </div>
        }
        {generalSetting0()?.taxType === 'one' &&
          <div className="w-[99px] flex flex-col items-start justify-center gap-3">
            <b className="relative sm:text-sm">{LL0().article.tax()}:</b>
            <div
              className="gap-[8px] self-stretch flex flex-row flex-wrap items-start justify-start text-black-solid-black-880-1d1d26">
              {taxCategories0().map((taxCategory, index) => (
                <div
                  className={clsx('shadow-[0.8px_1px_2px_rgba(0,_0,_0,_0.1)] rounded-10xs bg-white-solid-white-100-ffffff border-gray-solid-gray-480-b1b1b1 border-[0.5px] border-solid box-border h-10 flex flex-col items-center justify-center py-0 px-3 relative gap-2',
                    checkOneTypeTax(taxCategory) && '!border-[2px] !border-cyan-solid-cyan-250-8aeaff !bg-cyan-solid-cyan-100-ceffff')}
                  key={index}
                  onClick={() => {
                    if (menuSelected() === dineIn() || !multipleEnable) {
                      _.assign(product0(), {
                        tax: taxCategory.value,
                        tax2: taxCategory.value,
                        taxCategory: taxCategory.name,
                        taxCategory2: taxCategory.name,
                        taxComponents: taxCategory.components,
                        taxComponents2: taxCategory.components
                      })
                    } else {
                      if (!taxCategory) {
                        console.log(`tax is undefined`)
                      }
                      const taxData = { menu: menuSelected(), ref: taxCategory.name, value: taxCategory.value };
                      const menuTaxes = getMenusTax(1);
                      const updatedMenuTaxes = computedMenuTaxes(taxData, menuTaxes)
                      _.assign(product0(), { menuTaxes: updatedMenuTaxes, menuTaxes2: updatedMenuTaxes });
                    }
                  }}>
                  {taxCategory?.name} {
                  taxCategory?.components?.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue?.value || 0);
                  }, 0)
                  || taxCategory.value}%
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default VerticalEditNonFixedItemForm;