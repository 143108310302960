import { type FunctionComponent } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import _ from 'lodash'
import TextField from '@/react/core/TextField.tsx'
import { LL0 } from '@/react/core/I18nService.tsx'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { ChangeLanguage } from '@/data/language.ts'
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const CompanyInformationLeftForm: FunctionComponent = () => {
  // const [showErrorBeginHour, setShowErrorBeginHour] = useState<boolean>(false);
  //
  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value.trim();
  //   const isValidTimeFormat = /^([01]\d|2[0-3]):?([0-5]\d)$/.test(value);
  //
  //   if (isValidTimeFormat) {
  //     _.assign(generalSetting0(), { beginHour: value });
  //     setShowErrorBeginHour(false);
  //   } else {
  //     setShowErrorBeginHour(true);
  //   }
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className="no-scrollbar self-stretch flex-1 overflow-y-auto flex flex-col items-start justify-start pt-0 px-6 pb-4 gap-[12px] text-left text-sm text-black-solid-black-880-1d1d26 font-mulish Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().settings.companyName()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { name: e.target.value })}
            value={companyInfo0()?.name}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().settings.tel()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { telephone: e.target.value })}
            value={companyInfo0()?.telephone}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        {(companyInfo0()?.country === 'de' && <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">{LL0().companyInfo.owner()}:</i>
          <TextField
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "37px" } }}
            onChange={e => _.assign(companyInfo0(), { owner: e.target.value })}
            value={companyInfo0()?.owner || ''}
          />
        </div>)}
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().companyInfo.companyAddress()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { address: e.target.value })}
            value={companyInfo0()?.address}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">{LL0().companyInfo.addressDetails()}:</i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { address2: e.target.value })}
            value={companyInfo0()?.address2}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        {(companyInfo0()?.country === 'de' && <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">{LL0().posSetup.accountTab.email()}:</i>
          <TextField
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "37px" } }}
            onChange={e => _.assign(companyInfo0(), { email: e.target.value })}
            value={companyInfo0()?.email || ''}
          />
        </div>)}
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().settings.city()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { city: e.target.value })}
            value={companyInfo0()?.city}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>

        {(companyInfo0()?.country !== 'de' && <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">{LL0().companyInfo.province()}:</i>
          <TextField
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { height: "37px" } }}
            onChange={e => _.assign(companyInfo0(), { province: e.target.value })}
            value={companyInfo0()?.province}
          />
        </div>)}
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().settings.country()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <FormControl
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            variant="outlined"
            sx={{
              borderRadius: '0px 0px 0px 0px',
              width: '100%',
              height: '37px',
              m: 0,
              p: 0,
              '& .MuiInputBase-root': {
                m: 0,
                p: '8px',
                minHeight: '37px',
                justifyContent: 'center',
                display: 'inline-flex'
              },
              '& .MuiInputLabel-root': {
                m: 0,
                p: 0,
                minHeight: '37px',
                display: 'inline-flex'
              },
              '& .MuiMenuItem-root': {
                m: 0,
                p: 0,
                height: '37px',
                display: 'inline-flex'
              },
              '& .MuiSelect-select': {
                m: 0,
                p: 0,
                height: '37px',
                alignItems: 'center',
                display: 'inline-flex'
              },
              '& .MuiInput-input': { m: 0, p: 0 },
              '& .MuiInputBase-input': { textAlign: 'left', p: '0 !important' }
            }}
          >
            <InputLabel color="primary" />
            <Select color="primary" size="small" value={companyInfo0()?.country || 'ca'}
                    onChange={e => {
                      userFLow(`Country in Company Information: ${e.target.value}`, {
                        username: loginUser()?.name
                      })
                      _.assign(companyInfo0(), { country: e.target.value })
                    }}
            >
              <MenuItem value="ca">Canada</MenuItem>
              <MenuItem value="vn">Vietnam</MenuItem>
              <MenuItem value="de">Germany</MenuItem>
              <MenuItem value="us">United State</MenuItem>
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        {companyInfo0()?.country === 'ca' &&
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
            <i className="relative">{LL0().companyInfo.backendLanguage()}:</i>
            <FormControl
              className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
              variant="outlined"
              sx={{
                borderRadius: '0px 0px 0px 0px',
                width: '100%',
                height: '37px',
                m: 0,
                p: 0,
                '& .MuiInputBase-root': {
                  m: 0,
                  p: '8px',
                  minHeight: '37px',
                  justifyContent: 'center',
                  display: 'inline-flex'
                },
                '& .MuiInputLabel-root': {
                  m: 0,
                  p: 0,
                  minHeight: '37px',
                  display: 'inline-flex'
                },
                '& .MuiMenuItem-root': {
                  m: 0,
                  p: 0,
                  height: '37px',
                  display: 'inline-flex'
                },
                '& .MuiSelect-select': {
                  m: 0,
                  p: 0,
                  height: '37px',
                  alignItems: 'center',
                  display: 'inline-flex'
                },
                '& .MuiInput-input': { m: 0, p: 0 },
                '& .MuiInputBase-input': { textAlign: 'left', p: '0 !important' }
              }}
            >
              <InputLabel color="primary" />
              <Select color="primary" size="small"
                      value={companyInfo0()?.backendLanguage || ChangeLanguage.FR}
                      onChange={e => {
                        userFLow(`Backend language in Company Information: ${e.target.value}`, {
                          username: loginUser()?.name
                        })
                        _.assign(companyInfo0(), { backendLanguage: e.target.value })
                      }}
              >
                <MenuItem value={ChangeLanguage.FR}>{LL0().login.french()}</MenuItem>
                <MenuItem value={ChangeLanguage.EN}>{LL0().login.english()}</MenuItem>
                <MenuItem value={ChangeLanguage.DE}>{LL0().login.german()}</MenuItem>
              </Select>
              <FormHelperText />
            </FormControl>
          </div>}
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().settings.zipCode()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { zipCode: e.target.value })}
            value={companyInfo0()?.zipCode}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">
            <span>{LL0().settings.taxNo()}</span>
            <span className="text-red">({LL0().companyInfo.required()})</span>
            <span>:</span>
          </i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { taxNumber: e.target.value })}
            value={companyInfo0()?.taxNumber}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <i className="relative">{LL0().settings.taxLabel()}</i>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            onChange={e => _.assign(companyInfo0(), { taxLabel: e.target.value })}
            value={companyInfo0()?.taxLabel}
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
        {/*<div className="self-stretch flex flex-col items-start justify-start gap-[4px]">*/}
        {/*  <i className="relative">*/}
        {/*    <span>{LL0().companyInfo.aNewDayBeginsAt()}</span>*/}
        {/*    <span className="text-red">({LL0().companyInfo.required()})</span>*/}
        {/*    <span>:</span>*/}
        {/*  </i>*/}
        {/*  <div className="self-stretch">*/}
            {/*<TimePicker*/}
            {/*  value={dayjs(generalSetting0()?.beginHour, 'HH:mm')}*/}
            {/*  onChange={(newValue) => {*/}
            {/*    _.assign(generalSetting0(), { beginHour: dayjs(newValue).format('HH:mm') })*/}
            {/*  }}*/}
            {/*  ampm={companyInfo0()?.backendLanguage !== 'de'}*/}
            {/*  sx={{}}*/}
            {/*  slotProps={{*/}
            {/*    textField: {*/}
            {/*      size: 'small',*/}
            {/*      fullWidth: true,*/}
            {/*      required: false,*/}
            {/*      autoFocus: false,*/}
            {/*      error: false,*/}
            {/*      color: 'primary',*/}
            {/*      helperText: ''*/}
            {/*    },*/}
            {/*    // openPickerIcon: {*/}
            {/*    //   component: () => <></>*/}
            {/*    // }*/}
            {/*  }}*/}
            {/*/>*/}
        {/*  </div>*/}
        {/*  <TextField*/}
        {/*    className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"*/}
        {/*    value={generalSetting0()?.beginHour}*/}
        {/*    onChange={handleInputChange}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*{showErrorBeginHour && <p className='text-red'>{LL0().settings.invalidTimeFormat()}</p>}*/}
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
          <div className="flex flex-row items-center justify-start gap-[4px]">
            <img
              className="w-4 relative h-4 object-cover"
              alt=""
              src="/icongoogle-api-icon@2x.png"
            />
            <i className="relative">{LL0().companyInfo.googleMapAPIKey()}:</i>
          </div>
          <TextField
            type="string"
            className="self-stretch font-mulish text-sm text-black-solid-black-880-1d1d26"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            sx={{ '& .MuiInputBase-root': { height: '37px' } }}
          />
        </div>
      </div>
    </LocalizationProvider>
  )
}

export default CompanyInformationLeftForm
