import { type FunctionComponent, useCallback, useState } from "react";
import { FormControl, FormHelperText, MenuItem, Select, TextField, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSignal } from "@/react/core/reactive.ts";
import { LL0 } from "@/react/core/I18nService.tsx";
import _ from "lodash";
import { getDeviceId, initCallSystem, usbDevices } from "@/react/ws-java/init-java-ws.ts";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import PopupImageSelect from "@general-settings/PopupImageSelect.tsx";
import { imageChosen, loadingImage } from "@/react/GeneralSettingsView/GeneralSettingsView.tsx";
import { clsx } from "clsx";
import { generalSetting0, posSetting0 } from "@/data/PosSettingsSignal.ts";
import dayjs from "dayjs";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";
import { connectingSerialPort, fetchingSerialDevices, serialDeviceManager, selectedBaudRate, selectedSerialDevice, serialDevices, setSelectedBaudRate, setSelectedSerialDevice } from "@/shared/serial/serial.logic"
import { testVFD } from "@/shared/vfd/vfd-init"
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

const valueToOptionMapper = (value: string) => ({
  text: value,
  value,
});
enum SecondDisplayType {
  USB = 'usb',
  SERIAL = 'serial',
  SECOND_DISPLAY = 'second-display',
  NONE = 'none',
}
const languages = [
  { label: "English", value: "en" },
  { label: "Germany", value: "de" },
  { label: "Vietnamese", value: "vn" },
  { label: "Chinese", value: "cn" },
  { label: "French", value: "fr" },
];
const SecondDisplayOptions = [
  { value: SecondDisplayType.USB },
  { value: SecondDisplayType.SERIAL },
  { value: SecondDisplayType.SECOND_DISPLAY },
  { value: SecondDisplayType.NONE},
];
const ModemOptions = ['56k-modem', 'artech', 'none'].map(valueToOptionMapper);
const DeviceOptions = ['Devices', 'Off'].map(valueToOptionMapper);

const DevicesListRightForm: FunctionComponent = () => {

  const [updatedAt, setUpdatedAt] = useSignal<number>();

  const [modem, setModem] = useSignal<string>(ModemOptions[0].value);
  const [device, setDevice] = useSignal<string>(DeviceOptions[0].value);
  const [usb, setUsb] = useSignal<string>(SecondDisplayOptions[0].value);
  const [isUploadImagePopupOpen, setUploadAPhotoPopupOpen] = useState(false);
  const openUploadAPhotoPopup = useCallback(() => {
    setUploadAPhotoPopupOpen(true);
  }, []);

  const closeUploadImagePopup = useCallback(() => {
    setUploadAPhotoPopupOpen(false);
  }, []);
  const selectedLanguageLabel = (val: string)=>{
    return languages.find((lang) => lang.value === val)?.label || "";
  }


  return (
    <div className="overflow-auto self-stretch flex flex-col items-start justify-start pt-0 px-6 pb-2.5 gap-[12px] text-left text-sm text-black-solid-black-880-1d1d26 font-mulish Tablet_600:pl-4 Tablet_600:pr-4 Tablet_600:box-border">
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconsettingcaller-id-icon@2x.png"
          />
          <div className="flex-1 relative">{LL0().settings.callerIdDevices()}:</div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center">
          <FormControl className="flex-1" variant="outlined" size="small">
            {/*<InputLabel color="primary">{LL0().onlineOrder.callSystem.modemUSRobotics()}</InputLabel>*/}
            <Select color="primary" size="small"
                    value={deviceSetting0()?.callSystem?.mode || 'none'} className={"text-start"}
                    onChange={e => {
                      userFLow(`call device in general settings from ${deviceSetting0()?.callSystem?.mode} to ${e.target.value}`, {
                        username: loginUser()?.name
                      })
                      _.merge(deviceSetting0(), { callSystem: { mode: e.target.value } });
                    }}>
              {ModemOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText/>
          </FormControl>
          <div
            className="flex-1 rounded-10xs bg-gray-solid-gray-130-f4f4f4 shadow-[-0.5px_-0.5px_2px_rgba(0,_0,_0,_0.25)_inset,_0.5px_0.5px_2px_rgba(0,_0,_0,_0.25)_inset] box-border h-[37px] flex flex-row items-center justify-center py-0 px-2 max-w-[88px] border-[0.5px] border-solid border-gray-solid-gray-480-b1b1b1"
            onClick={initCallSystem}
            ref={makeRipple}>
            <div className="relative">{LL0().onlineProdiverSetting.connect()}</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
          <FormControl className="flex-1" variant="outlined" size="small">
            {/*<InputLabel color="primary">{LL0().settings.deviceManager.devices()}</InputLabel>*/}
            <Select color="primary" size="small"
                    value={getDeviceId(deviceSetting0()?.callSystem?.usbDevice)}
                    onChange={e => {
                      userFLow(`usbDevice in general settings from ${deviceSetting0()?.callSystem?.usbDevice} to ${e.target.value}`, {
                        username: loginUser()?.name
                      })
                      const usbDevice = usbDevices()?.find(device => getDeviceId(device) === e.target.value);
                      _.merge(deviceSetting0(), { callSystem: { usbDevice: usbDevice } });
                    }}
            >
              {usbDevices()?.map((option, index) => (
                <MenuItem key={index} value={getDeviceId(option)}>
                  <div>
                    <p>{option.deviceManufacturerName} - {option.deviceProductName} </p>
                    <p>{option.deviceVID} - {option.devicePID} </p>
                  </div>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText/>
          </FormControl>
          <div className="flex-1 rounded-10xs h-[37px] max-w-[88px]"/>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconsettingcustom-display-icon@2x.png"
          />
          <div className="flex-1 relative">
            {LL0().settings.customerDisplay()}:
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center">
          <FormControl className="flex-1" variant="outlined" size="small">
            {/*<InputLabel color="primary">USB</InputLabel>*/}
            <Select color="primary" size="small"
                    value={deviceSetting0()?.secondDisplayType || 'none'} className={"text-start"}
                    disabled={fetchingSerialDevices()}
                    onChange={e => {
                      userFLow(`update-second-display in general settings from ${deviceSetting0()?.secondDisplayType} to ${e.target.value}`, {
                        username: loginUser()?.name
                      })
                      localStorage.setItem('update-second-display',dayjs().unix().toString())
                      _.assign(deviceSetting0(), {secondDisplayType: e.target.value});
                    }}
            >
              {SecondDisplayOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText />
          </FormControl>
          <LoadingButton
            variant="outlined"
            color="inherit"
            className="!normal-case !font-[inherit]"
            loading={fetchingSerialDevices()}
            onClick={serialDeviceManager.getSerialDevices}
            >
            <div className="w-[66px] relative inline-block shrink-0">
              {LL0().onlineOrder.settings.testing()}
            </div>
          </LoadingButton>
        </div>
        {deviceSetting0()?.secondDisplayType === 'serial' && serialDevices().length > 0 && (
          <>
            <div className="self-stretch grid grid-cols-[3fr_70px_88px_88px] gap-2 items-center">
              <FormControl variant="outlined" size="small">
                <Select color="primary" size="small"
                        value={selectedSerialDevice() ?? ''}
                        onChange={e => setSelectedSerialDevice(e.target.value)}
                        disabled={connectingSerialPort()}
                >
                  {serialDevices().map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText/>
              </FormControl>
              <TextField variant="outlined" size="small" disabled={connectingSerialPort()} value={selectedBaudRate()} onChange={e => setSelectedBaudRate(e.target.value)} />
              <LoadingButton
                variant="outlined"
                color="inherit"
                className="!normal-case !font-[inherit]"
                onClick={serialDeviceManager.connectToSelectedSerialDevice}
                disabled={!selectedSerialDevice()}
                loading={connectingSerialPort()}
                >
                <div className="w-[66px] relative inline-block shrink-0">
                  {LL0().onlineProdiverSetting.connect()}
                </div>
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                color="inherit"
                className="!normal-case !font-[inherit]"
                onClick={() => testVFD()}
                disabled={!selectedSerialDevice()}
                loading={connectingSerialPort()}
                >
                <div className="w-[66px] relative inline-block shrink-0">
                  {LL0().ui.test()}
                </div>
              </LoadingButton>
            </div>
          </>
        )}
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/iconsettingcustom-display-icon@2x.png"
          />
          <div className="flex-1 relative">
            {LL0().settings.generalSetting.customerDisplayLanguage()}:
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px] text-center">
          <FormControl className="flex-1" variant="outlined" size="small">
            <Select color="primary" size="small"
                    value={generalSetting0()?.secondDisplayLanguage || posSetting0()?.companyInfo?.backendLanguage || 'en'}                    className={"text-start"}
                    onChange={e => {
                      userFLow(`devices display in general settings ${e.target.value}`, {
                        username: loginUser()?.name
                      })
                      _.assign(generalSetting0(), { secondDisplayLanguage: e.target.value });
                    }}
                    renderValue={(value) => {
                      return languages.find((lang) => lang.value === value)?.label || "";
                    }}
            >
              {languages.map((lang, index) => (
                <MenuItem key={index} value={lang.value}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch flex flex-row items-center justify-start gap-[8px]">
          <img
            className="w-6 relative h-6 object-cover"
            alt=""
            src="/icongeneral-settingsbanner@2x.png"
          />
          <div className="flex-1 relative">{LL0().settings.generalSetting.rearDisplayBanner()}:</div>
        </div>
        <div onClick={openUploadAPhotoPopup} className="flex flex-row items-center justify-start gap-[12px] text-center text-xs text-purple-solid-purple-454586">
          <div className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[6px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]">
            <img
              className="w-6 relative h-6 object-cover"
              alt=""
              src="/iconupload-icon@2x.png"
            />
            <div className="self-stretch flex flex-col items-center justify-start sm:gap-[8px] Tablet_768:gap-[2px]">
              <div className="self-stretch relative font-semibold md:text-sm sm:text-sm Tablet_768:text-smi">
                {LL0().editOnlineMenu.uploadImage()}
              </div>
            </div>
          </div>
          {loadingImage() ? (
            <div className="w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]">
              <div className=" border-gray-300 h-5 w-5 animate-spin rounded-full border-8 border-t-blue-600" />
            </div>
          ) : (
            <div className={clsx("w-[81px] shadow-[0px_0px_3px_rgba(0,_0,_0,_0.25)_inset] rounded-10xs bg-gray-solid-gray-eff0f9 box-border h-[81px] flex flex-col items-center justify-center py-0 px-1 gap-[4px] border-[1px] border-dashed border-cornflowerblue md:w-[81px] md:h-[81px] md:gap-[16px] sm:w-[81px] sm:h-[81px] sm:gap-[1px] Tablet_768:w-[81px] Tablet_768:h-[81px] Tablet_768:gap-[1px]"
              ,{'hidden': !imageChosen()})}>
              <img
                className="w-[81px] relative h-[81px] object-cover"
                alt=""
                src={imageChosen() || "/public/edit-image@3x.png"}
              />
              <img
                onClick={openUploadAPhotoPopup}
                className="w-[30px] absolute h-[30px] object-cover"
                alt=""
                src={"/iconedit-img-icon@2x.png"}
              />
            </div>
          )}
        </div>
      </div>
      {isUploadImagePopupOpen && (
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={closeUploadImagePopup}
        >
          <PopupImageSelect onClose={closeUploadImagePopup} />
        </PortalPopup>
      )}
    </div>
  );
};

export default DevicesListRightForm;
