import React, { type FunctionComponent, useState } from "react";
import KitchenMonitorItem from "./KitchenMonitorItem";
import { OrderKitchenStatus, type TOrder } from "@/pos/OrderType.ts";
import _ from "lodash";
import dayjs from "dayjs";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import ArchivedConfirmPopup from "@kitchen-monitor/ArchivedConfirmPopup.tsx";
import { makeRipple } from "@/react/core/ripple-utils.ts";
import {
  getNameKitchenPrinter,
  groupItemsPrinter,
  setOrderStatus
} from "@/react/KitchenMonitorView/KitchenMonitorView.tsx";
import { deviceSetting0 } from "@/data/DeviceSettingSignal.ts";

type KitchenMonitorCardDetailPType = {
  onClose?: () => void;
  order: TOrder;
};

const KitchenMonitorCardDetailP: FunctionComponent<
  KitchenMonitorCardDetailPType
> = ({ onClose, order }) => {

  const [isOpenArchivedConfirmPopup, setOpenArchivedConfirmPopup] = useState(false);

  const diffTime = () => {
    const now = dayjs()
    const time = dayjs.unix(order.startAt!)
    const diffInMinutes = now.diff(time, "minute")
    const diffInHours = now.diff(time, "hour");
    const diffDays = now.diff(time, "day");
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${LL0().kitchenMonitor.mins()}`;
    } else if (diffInHours < 24) {
      const remainingMinutes = diffInMinutes % 60;
      return `${diffInHours} ${LL0().kitchenMonitor.hours()}, ${remainingMinutes} ${LL0().kitchenMonitor.mins()}`;
    } else {
      return `${diffDays} ${LL0().kitchenMonitor.days()}`;
    }
  }

  const filterOrders = groupItemsPrinter(order)

  return (
    <>
      <div
        style={{fontSize: (deviceSetting0()?.screenConfig?.kitchenFontSize || 14) - 1}}
        className="bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border relative gap-[16px] h-full max-w-full max-h-full text-left text-black-solid-black-1000-000000 font-mulish sm:gap-[12px] sm:pl-4 sm:pr-4 sm:pb-4 sm:box-border Tablet_768:gap-[10px] Tablet_768:pl-3 Tablet_768:pr-3 Tablet_768:pb-3 Tablet_768:box-border Tablet_600:gap-[6px] Tablet_600:pl-2.5 Tablet_600:pr-2.5 Tablet_600:pb-2.5 Tablet_600:box-border">
        <div
          className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[0]"
          onClick={onClose}
        >
          <img
            className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
            alt=""
            src="/icongeneralclose-popup-icon--grey@2x.png"
          />
        </div>
        <div
          className="self-stretch flex flex-col items-start justify-start pt-3 px-0 pb-2 gap-[4px] z-[1] border-b-[1px] border-solid border-gray-solid-gray-300-dbdbdb height_414:gap-[0px]">
          <div style={{fontSize: (deviceSetting0()?.screenConfig?.kitchenFontSize || 14) + 1}} className="self-stretch flex flex-row items-start justify-between">
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}} className="relative font-semibold">{_.get(LL0().kitchenMonitor, order?.kitchenStatus || '')?.()}</div>
            </div>
            <div style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}} className="relative font-semibold text-right">{diffTime()}</div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="relative">{order?.table &&
              <div style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}} className="relative leading-[120%]">{LL0().orderHistory.tableNo()} {order?.table}</div>}
              {order?.type && <div
                style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}}
                className="relative leading-[120%]">{_.get(LL0().onlineOrder, order?.type)?.() ? _.get(LL0().onlineOrder, order?.type)?.() : order?.type}</div>}</div>
            <div className="flex flex-row items-center justify-start gap-[3px] text-right">
              <img
                className="relative w-[18px] h-[18px] object-cover"
                alt=""
                src="/iconclock-icon@2x.png"
              />
              <div style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}} className="relative">{dayjs.unix(order?.startAt || 0).format(LL2().dates.timeFormat())}</div>
            </div>
          </div>
          <div
            style={{fontSize: deviceSetting0()?.screenConfig?.kitchenFontSize}}
            className="self-stretch flex flex-row items-start justify-between">
            <div
              className="relative flex flex-col">
              {order?.id ? `#${order?.id}` : order?.ticketNumber ? `#${order?.ticketNumber}` : ``}
              {order?.customerRaw && <div className="relative leading-[120%]">
                <p>{order?.customerRaw.name} - {order?.customerRaw.phone}</p>
                <p className="w-3/4">{order?.customerRaw.address}.</p>
              </div>}
              {(order?.note || order?.customerRaw?.note) && <div className="relative leading-[120%]">
                <p>{order?.note}</p>
                <p>{order?.customerRaw?.note}</p>
              </div>}
            </div>
            <i className="relative text-right">{_.upperFirst(order?.users?.[0] || '')}</i>
          </div>
        </div>
        <div
          className="flex flex-col gap-2 flex-1 self-stretch relative overflow-y-auto no-scrollbar shrink-0 z-[2]">
          {Object.entries(filterOrders as Record<string, any[]>).map(([key, items], index) => (
            <div className="w-full flex flex-col gap-2" key={index}>
              <p className="ml-2">{key.split(",").filter(id => id !== "undefined").length ? key.split(",").filter(id => id !== "undefined").map(id => getNameKitchenPrinter(id)).join(', ') : LL0().kitchenMonitor.cannotFindPrinter()}:</p>
              <div className="grid [grid-row-gap:8px] [grid-column-gap:8px]"
                style={{gridTemplateColumns: `repeat(${items.length > 3 ? 3 : 2}, minmax(0, 1fr))`}}
              >
                {items.map((item, idx) => (
                  <KitchenMonitorItem
                    className="flex-1"
                    orderId={order?._id || ''}
                    key={idx}
                    item={item}
                    orderStatus={order?.kitchenStatus === OrderKitchenStatus.ARCHIVED ? undefined : order?.kitchenStatus || undefined}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{fontSize: Math.min(deviceSetting0()?.screenConfig?.kitchenFontSize || 14, 24)}}
          className="self-stretch flex flex-row items-start justify-end pt-1 px-0 pb-0 gap-[16px] z-[3] text-center">
          {order?.kitchenStatus === OrderKitchenStatus.NEW && <div
            className="flex flex-1 rounded bg-orange-solid-orange-400-ffa06a h-9 flex-row items-center justify-center py-0 px-2 box-border max-w-[200px] min-h-[36px]"
            ref={makeRipple}
            onClick={() => {
              setOrderStatus(order._id, OrderKitchenStatus.PREPARE).then();
              onClose?.()
            }}
          >
            <div className="relative font-semibold">{LL0().kitchenMonitor.start()}</div>
          </div>}
          {order?.kitchenStatus === OrderKitchenStatus.PREPARE && <div
            className="flex flex-1 rounded bg-green-solid-green-420-a9e15d h-9 flex-row items-center justify-start py-0 px-2 box-border relative max-w-[200px] min-h-[36px]"
            ref={makeRipple}
            onClick={() => setOpenArchivedConfirmPopup(true)}
          >
            <img
              className="relative w-6 h-6 object-cover z-[0]"
              alt=""
              src="/iconcheckmark-icon@2x.png"
            />
            <div
              className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_19px)] font-semibold z-[1]">
              {LL0().kitchenMonitor.done()}
            </div>
          </div>}
          {order?.kitchenStatus === OrderKitchenStatus.DONE && <div
            className="flex flex-1 rounded bg-purple-solid-purple-200-c0c0ff h-9 flex-row items-center justify-start py-0 px-2 box-border relative max-w-[200px] min-h-[36px]"
            ref={makeRipple}
            onClick={() => setOpenArchivedConfirmPopup(true)}
          >
            <img
              className="relative w-6 h-6 object-cover z-[0]"
              alt=""
              src="/iconarchived-icon@2x.png"
            />
            <div
              className="absolute my-0 mx-[!important] top-[calc(50%_-_9.5px)] left-[calc(50%_-_31px)] font-semibold z-[1]">
              {LL0().kitchenMonitor.archived()}
            </div>
          </div>}
        </div>
      </div>
      {isOpenArchivedConfirmPopup &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setOpenArchivedConfirmPopup(false)}
        >
          <ArchivedConfirmPopup closeParent={onClose} onClose={() => setOpenArchivedConfirmPopup(false)} order={order}/>
        </PortalPopup>
      }
    </>
  );
};

export default KitchenMonitorCardDetailP;
