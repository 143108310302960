import { type FunctionComponent, useCallback, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider, TimePicker, } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LL0 } from "@/react/core/I18nService.tsx";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getShift, updateStaffReport, updateTimeWithDate } from "@/react/StaffReportView/StaffReportView.tsx";
import PortalPopup from "@/react/core/PortalPopup.tsx";
import DeleteConfirmationPopup from "@staff-report/DeleteConfirmationPopUp.tsx";
import { setEditTimeEntryOpen } from "@staff-report/StaffReportTimeClock.tsx";
import { LL2 } from "@/react/core/I18nBackend.tsx";
import { companyInfo0 } from "@/data/PosSettingsSignal.ts";
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type Clock = {
  id?: string,
  clockInTime?: number,
  clockOutTime?: number,
  vDate?: number
}

type EditTimeEntryType = {
  onClose?: () => void;
  data?: Clock;
};



const EditTimeEntry: FunctionComponent<EditTimeEntryType> = ({ onClose, data }) => {
  const [datePickerValueFrom, setDatePickerValueFrom] = useState<any>(dayjs.unix(data?.clockInTime!));
  const [datePickerValueTo, setDatePickerValueTo] = useState<any>(dayjs.unix(data?.clockOutTime!));
  const [clockInPickerValue, setClockInPickerValue] = useState<
   any
  >(dayjs.unix(data?.clockInTime!));
  const [clockOutPickerValue, setClockOutPickerValue] =
    useState<any>(dayjs.unix(data?.clockOutTime!));

  const [isDeleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
    useState(false);
  const openDeleteConfirmationPopup = useCallback(() => {
      userFLow(`open delete confirmation popup`, {
        username: loginUser()?.name
      });
    setDeleteConfirmationPopupOpen(true);
  }, []);
  const closeDeleteConfirmationPopup = useCallback(() => {
    userFLow(`close delete confirmation popup`, {
      username: loginUser()?.name
    });
    setDeleteConfirmationPopupOpen(false);
  }, []);


  useEffect(() => {
    if (data) {
      const pickerDateFrom = dayjs.unix(data?.clockInTime!);
      const pickerDateTo = dayjs.unix(data?.clockOutTime! ?? data?.clockInTime)
      setDatePickerValueFrom(pickerDateFrom);
      setDatePickerValueTo(pickerDateTo);
      setClockInPickerValue(updateTimeWithDate(pickerDateFrom, clockInPickerValue));
      setClockOutPickerValue(updateTimeWithDate(pickerDateTo, clockOutPickerValue));
    }
  }, [data]);


  const onConfirm = async() => {
    userFLow(`confirm edit time entry`, {
      username: loginUser()?.name
    });
    const currentShift = await getShift(data as Clock)
    if (!currentShift) return
    if (!datePickerValueFrom || !datePickerValueTo || !clockInPickerValue || !clockOutPickerValue) return toast.error('Please fill in all field')

    const clockInTime = dayjs(clockInPickerValue).unix();
    const clockOutTime = dayjs(clockOutPickerValue).unix();

    const isValidClock = clockOutTime >= clockInTime
    if (!isValidClock) return toast.error('Unable to initialize a shift with a negative duration')

    await currentShift.incrementalPatch({clockInTime: dayjs(clockInPickerValue).unix(), clockOutTime: clockOutTime, vDate: dayjs(datePickerValueFrom).unix()})
    await updateStaffReport()

    onClose?.();
  }

  const onDelete = async() => {

    const query = await getShift(data as Clock);
    userFLow(`delete time entry ${query}`, {
      username: loginUser()?.name
    });
    if (query) {
      await query.remove()
    }
    await updateStaffReport()

    closeDeleteConfirmationPopup();
    setEditTimeEntryOpen(false)
  }


  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div
          className="relative bg-white-solid-white-100-ffffff w-full overflow-hidden flex flex-col items-start justify-start pt-8 px-6 pb-6 box-border gap-[16px] min-w-[320] h-full text-left text-lg text-gray font-mulish hover:bg-white-solid-white-100-ffffff hover:flex hover:w-full hover:h-full hover:flex-col hover:gap-[16px] hover:items-start hover:justify-start hover:pt-8 hover:px-6 hover:pb-6 hover:box-border hover:min-w-[320]">
          <b className="self-stretch relative z-[0]">{LL0().monthlyReport.editTimeEntry()}</b>
          <div className="self-stretch flex flex-row items-start justify-start gap-[4px] z-[1] text-sm">
            <div className="relative font-extrabold">{LL0().monthlyReport.staff()}:</div>
            <div className="relative text-right">{LL0().monthlyReport.admin()}</div>
          </div>
          <div className="self-stretch z-[2] flex flex-row items-start justify-center gap-[16px]">
            <div className="flex-1">
              <DatePicker
                format={LL2().dates.dateFormat()}
                label={LL0().report.from()}
                value={datePickerValueFrom}
                onChange={(newValue: any) => {
                  setDatePickerValueFrom(newValue);
                  setClockInPickerValue(updateTimeWithDate(newValue, clockInPickerValue));
                }}
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    fullWidth: true,
                    color: "primary",
                  },
                }}
              />
            </div>
            <div className="flex-1">
              <DatePicker
                format={LL2().dates.dateFormat()}
                label={LL0().report.to()}
                value={datePickerValueTo}
                onChange={(newValue: any) => {
                  setDatePickerValueTo(newValue);
                  setClockOutPickerValue(updateTimeWithDate(newValue, clockOutPickerValue));
                }}
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    fullWidth: true,
                    color: "primary",
                  },
                }}
              />
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-center gap-[16px] z-[3]">
            <div className="flex-1">
              <TimePicker
                label={LL0().monthlyReport.clockIn()}
                value={clockInPickerValue}
                onChange={(newValue: any) => {
                  setClockInPickerValue(updateTimeWithDate(datePickerValueFrom, newValue));
                }}
                ampm={companyInfo0()?.country !== 'de'}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    fullWidth: true,
                    color: "primary",
                  },
                }}
              />
            </div>
            <div className="flex-1">
            <TimePicker
                label={LL0().monthlyReport.clockOut()}
                value={clockOutPickerValue}
                onChange={(newValue: any) => {
                  setClockOutPickerValue(updateTimeWithDate(datePickerValueTo, newValue));
                }}
                ampm={companyInfo0()?.country !== 'de'}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    fullWidth: true,
                    color: "primary",
                  },
                }}
              />
            </div>
          </div>
          <div
            className="self-stretch flex flex-row items-start justify-center gap-[8px] z-[4] text-center text-white-solid-white-100-ffffff">
            <div className="rounded bg-indianred w-10 h-10 flex flex-row items-center justify-center">
              <div className="relative w-[30px] h-[30px]" onClick={openDeleteConfirmationPopup}>
                <img
                  className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
                  alt=""
                  src="/iconbin-icon--white@2x.png"
                />
              </div>
            </div>
            <div className="flex-1 rounded bg-royalblue-100 h-10 flex flex-row items-center justify-center"
                 onClick={onConfirm}>
              <div className="relative font-semibold">{LL0().ui.confirm()}</div>
            </div>
          </div>
          <div
            className="absolute my-0 mx-[!important] top-[12px] right-[12px] w-6 h-6 overflow-hidden shrink-0 cursor-pointer z-[5]"
            onClick={() => {
              onClose?.()
              userFLow(`close delete confirmation popup`, {
                username: loginUser()?.name
              });
            }}
          >
            <img
              className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] max-w-full overflow-hidden max-h-full object-cover"
              alt=""
              src="/icongeneralclose-popup-icon--grey@2x.png"
            />
          </div>
        </div>
      </LocalizationProvider>
      {
        isDeleteConfirmationPopupOpen && (
          <PortalPopup
            overlayColor="rgba(0, 0, 0, 0.2)"
            placement="Centered"
            onOutsideClick={closeDeleteConfirmationPopup}
          >
            <DeleteConfirmationPopup onClose={closeDeleteConfirmationPopup}
                                     onComplete={onDelete}
            />
          </PortalPopup>
        )
      }
    </>
  );
};

export default EditTimeEntry;
