// const wkPool = require('workerpool');
import wkPool from 'workerpool'
import {Buffer} from 'buffer/';

const isDev = import.meta.env.MODE === 'development';
const COMMANDS_PER_WORKER = 1;
const MAX_WORKERS = parseInt(localStorage.getItem('COMMANDS_PER_WORKER') || '4');
const worker = () => new Worker(new URL('./worker-script', import.meta.url),
  /* @vite-ignore */ isDev ? {type: 'module'} : {});
const workerPool = wkPool.pool(worker, {
  workerType: 'web',
  // COMMANDS_PER_WORKER,
  numWorkers: COMMANDS_PER_WORKER,
  maxWorkers: MAX_WORKERS
});

// functions that can be divided to separated workers
const DIVISIBLE_FUNCTIONS = [
  'marginTop',
  'newLine',
  'drawLine',
  'tableCustom',
  'advancedTableCustom',
  'leftRight',
  'println',
  'printQrCode',
  'printBarcode',
  'printImage',
];

// functions that need to be run on all workers
const INDIVISIBLE_FUNCTIONS = [
  'alignLeft',
  'alignRight',
  'alignCenter',
  'setTextDoubleHeight',
  'setTextDoubleWidth',
  'setTextQuadArea',
  'bold',
  'italic',
  'setTextNormal',
  'invert',
  'setFontSize',
];

function applyWorkerPool(obj, keys) {
  function reset() {
    obj.divisibleCommands = [];
    obj.indivisibleCommands = [];
    obj.commandIndex = 0;
  }

  reset()
  let invert = false
  keys.forEach(key => {
    if (key.startsWith('_') || typeof obj[key] !== 'function' || key === 'constructor') return;

    obj[key] = new Proxy(obj[key], {
      apply(target, thisArg, argArray) {
        return new Promise((async (resolve) => {
          if (DIVISIBLE_FUNCTIONS.includes(key)) {
            if (key === 'invert') {
              invert = argArray[0]
            }
            obj.divisibleCommands.push({fnName: key, argArray, commandIndex: obj.commandIndex++, invert});
          } else if (INDIVISIBLE_FUNCTIONS.includes(key)) {
            obj.indivisibleCommands.push({fnName: key, argArray, commandIndex: obj.commandIndex++, invert});
          } else if (key === 'print' || key === 'printToFile') {
            const buffers = await renderBuffers(obj);

            const canvasData = buffers.reduce((acc, {data, width, height}) => {
              if (!Buffer.isBuffer(data)) data = Buffer.from(data);
              return ({
                finalBuffer: Buffer.concat([acc.finalBuffer, data]),
                finalHeight: acc.finalHeight + height,
                finalWidth: Math.max(acc.finalWidth, width),
              });
            }, {finalBuffer: Buffer.from([]), finalHeight: 0, finalWidth: 0});

            const originalCanvasBuffer = thisArg.canvas.data;
            const originalCanvasWidth = thisArg.canvas.width;
            const originalCanvasHeight = thisArg.canvas.height;
            const originalPrintY = thisArg.canvas.currentPrintY;

            thisArg.canvas.data = canvasData.finalBuffer;
            thisArg.canvas.width = canvasData.finalWidth;
            thisArg.canvas.height = canvasData.finalHeight;
            thisArg.currentPrintY = canvasData.finalHeight;

            const result = await target.apply(thisArg, argArray);

            thisArg.canvas.data = originalCanvasBuffer;
            thisArg.canvas.width = originalCanvasWidth;
            thisArg.canvas.height = originalCanvasHeight;
            thisArg.currentPrintY = originalPrintY;

            reset()
            return resolve(result);
          } else {
            await target.apply(thisArg, argArray);
            reset()
          }

          resolve();
        }));
      }
    });
  });
}

async function renderBuffers({printWidth, divisibleCommands, indivisibleCommands, opts: {autoAdjustFontsize}}) {
  const commandsPerWorker = COMMANDS_PER_WORKER;
  let buffers = [];

  let currentInvert = false
  let sliceIndex = 0;
  while (sliceIndex < divisibleCommands.length) {
    const slicedCommands = divisibleCommands.slice(sliceIndex, sliceIndex + commandsPerWorker);
    for (const command of slicedCommands) {
      if (command.fnName === 'invert') {
        currentInvert = command.invert
      }
    }
    if (slicedCommands.length === 0) continue;
    const workerCommands = [...indivisibleCommands, ...slicedCommands]
      .filter(e => e.commandIndex <= slicedCommands[slicedCommands.length - 1].commandIndex)
      .sort((e1, e2) => e1.commandIndex - e2.commandIndex);

    buffers.push(execPrintTasks(workerCommands, currentInvert, printWidth, {autoAdjustFontsize}));

    sliceIndex += commandsPerWorker;
  }

  return Promise.all(buffers);
}

function execPrintTasks(printTasks, currentInvert, printWidth, opts = {}) {
  return new Promise((resolve, reject) => {
    workerPool.exec('execPrintTasks', [printTasks, currentInvert, printWidth, opts])
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
}

export {
  applyWorkerPool,
  workerPool,
}
