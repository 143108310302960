import type { FunctionComponent } from 'react'
import TableManualKeyboard from '@/react/core/TableManualKeyboard'
import Input, { type InputController, inputController0 } from '@/react/core/Input.tsx'
import type { Accessor, Setter } from '@/react/core/reactive.ts'

type InputPopupType = {
  onClose?: () => void;
  onEnter?: () => void;
  onChange?: (value: string) => void;
  inputControllers?: Accessor<Array<InputController>>;
  setInputControllers?: Setter<Array<InputController>>;
  label?: string;
  value: string;
  modeKeyboard?: number;
};

const InputPopup: FunctionComponent<InputPopupType> = (
  {
    value,
    onClose,
    onChange,
    inputControllers,
    setInputControllers,
    onEnter,
    label,
    modeKeyboard
  }) => {
  return (
    <div
      className="input-popup bg-basic-color-white w-full h-full overflow-hidden flex flex-col items-start justify-start p-5 box-border gap-[24px] min-w-[200px] max-w-full max-h-full text-left text-base text-materialize-grey-darken-3 font-mulish">
      <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
        {onClose && <img
          className="self-end relative w-6 h-6 object-cover cursor-pointer"
          alt=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAP8SURBVHgB7ZlLa1NBFMfPTfrQgvShLsSNH8B9H0kqtXXrogq+FTfd2SamLyqYNkI0bYmpIFK16Eq0CoILraAFF23Qgh9B3EiXgiQhLU3j/5RUQpw7j5vbgpIflExmzp05/3mcezohqlKlyj+NJWsMhUItNTU1vSjW4y81NTX1lXYBjHsM4/ZYlrW6vr6+kEwmv9nZ2goIh8O96GAOf01/jC0rMTk5GaYdAo7v9Xq9DzHOxZLqHL5fwrivRM94RJXBYPBIufNMoVC4PjQ0dJt2BgvO3y9zntmDcR/xbhA9JBRQW1vbWu58CaMQcY9cZnBwcB5jXrVpboK4U6IGoQB0dJDkXBseHp4hF+jr62tAX08x5mmZHdqF210oIJ/Pp7BsBZKA5n4MfIcqw2psbJxFX1dUhhsbG4uieq+oMpVKrXZ0dNRDdIDk+AOBQPPS0tICGQKnrVwu9xKfZzVsxxOJxGtRmzSMYl/ehYggKfB4PDPxeFxptw1HG4TJORTPqWwx/gQi0Lhdu0f28PT0dAjqk6Rgc3NzAAc7ThrwzONAzpKG87C9KXOe8ZKC5eXl99hOezETfoWpz+/3tyi2k5XJZJ6jr/OkJoIJvKUyUgpgIOKDz+drRrFNYdoKu/2wf1fewNGmq6trTtP5KN76Exp2egIYOLWgKwIrUY+V+Fha2d3dzdtGGW2wbSYw8+OkibYApiiiDkVVdAqUrISF88FpgHLmTZ1nLHIAotMMtkK/yo6jEw74ARQvkBreNhEyxJEARjfEahKB81FygNEWKsUgOknhUKkTbexwLIDh6AQRTRChOthCinve0cxvU5EAprgSLRDRavIcpwdwXitUyqhYAGMQnbYoznzFzjMecgF+SWEFDuvaIzo1k0u4IUA7Jd7GpVR8i4q2kElKLMBxKl6K4/eASUoswzQV/+t5coBJSqzCJBUX4WQLGaXEEPvFpVRciJEAJymxG6m4DCMBTlPiSlNxGboCKk6JHabiSpQCONog3D1B8QypicryeTi1qJl2tOqGWGkU4miDUPmY9PL5iE4+D4EDbl4U2K4AOz8yMjJPGjNvmhKbXBSoVsJWQDqdfoABLpMCpymxbiqO/tvYjkWL2oVvYlytd+IN+YkUuJES6/57ykIw1ufyerszcILUHbqSEvOZwEdMw/S4qNLudvonyYma3h7IwOG/gQ/pbTd2REZYL6rMZrPPMMO/RG08805uD1Sgz6AkOuUg4I2oQXiIV1ZW0u3t7T/wUCe+NhSr8xhgFDOvs9yO4IOKl1gNip0l1Vn4MYCMVXi9Lk2nx8bGDq2trfUg89yHH9ve4se277QL4GAfxWSdxFbO1tXVvYjFYqtUpUqV/5Pfnf7xvP2qqhsAAAAASUVORK5CYII="
          onClick={onClose}
        />}
        <div className="self-stretch flex flex-row items-center justify-between">
          <div className="relative font-extrabold inline-block min-w-[66px]">
            {label || 'Id'}:
          </div>
          <Input
            className={'flex-1 max-w-[450px]'}
            refInputController={i => {
              i.setFocus(true);
              i.moveCaretToEnd();
              return setInputControllers?.([i])
            }}
            value={value}
            onChange={onChange}
          />
        </div>
        {/*<div className="self-stretch flex flex-row items-center justify-between">*/}
        {/*  <div className="relative font-extrabold inline-block min-w-[66px]">*/}
        {/*    Name:*/}
        {/*  </div>*/}
        {/*  <Input className={'flex-1'} />*/}
        {/*</div>*/}
        {/*<div className="self-stretch flex flex-row items-center justify-between">*/}
        {/*  <div className="relative font-extrabold inline-block min-w-[66px]">*/}
        {/*    Price:*/}
        {/*  </div>*/}
        {/*  <Input className={'flex-1'} />*/}
        {/*</div>*/}
      </div>
      <TableManualKeyboard inputController={inputController0}
                           onEnter={() => onEnter?.()}
                           mode= {modeKeyboard}
                           value={value} />
    </div>
  )
}

export default InputPopup
