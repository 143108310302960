import { type FunctionComponent, useCallback, useState } from 'react'
import { FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, Switch } from '@mui/material'
import ModifierSelectFieldset from './Form/ModifierSelectFieldset'
import VariantSelectFieldset from './Form/VariantSelectFieldset'
import OrderRestrictionFieldset from './Form/OrderRestrictionFieldset'
import PickupTimeFieldset from './Form/PickupTimeFieldset'
import ValiditySelectFieldset from './Form/ValiditySelectFieldset'
import ValidityRecurringFieldset from './Form/ValidityRecurringFieldset'
import ValidityPeriodFieldset from './Form/ValidityPeriodFieldset'
import ImagePickerFieldset from './Form/ImagePickerFieldset'
import { getTax, STATES, VIEW__PRODUCT } from '@/react/EditOnlineMenuView/EditOnlineMenuView.logic'
import TextField from '@/react/core/TextField'
import { numberFromString, numberToString } from '@/react/core/utils'
import { LL0 } from '@/react/core/I18nService.tsx'
import { makeRipple } from '@/react/core/ripple-utils'
import {taxCategoriesVM} from "@/data/TaxCategoryHub.ts";
import TextFieldArea from '@/react/core/TextFieldArea.tsx'
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";
import _ from "lodash";

const ProductEditingForm: FunctionComponent = () => {
  const [showMoreSetting, setShowMoreSetting] = useState(false)
  const toggleShowMoreSetting = useCallback(() => {
    userFLow(`Show More Setting in Online Menu`, {
      username: loginUser()?.name
    })
    setShowMoreSetting(v => !v)
  }, [])
  return (
    <div className="no-scrollbar self-stretch overflow-y-auto flex flex-row items-start justify-start pt-2 px-5 pb-6 gap-[64px] text-left text-lg text-gray-opacity-gray-60-2e2e48-300 font-mulish-button-name-bold lg:gap-[60px] lg:pl-5 lg:pr-5 lg:box-border md:gap-[56px] md:pl-4 md:pt-3 md:pr-4 md:box-border sm:gap-[40px] sm:pl-3.5 sm:pt-2 sm:pr-3.5 sm:box-border mq768:gap-[24px] mq768:pl-2.5 mq768:pt-0 mq768:pr-2.5 mq768:box-border mq600:gap-[18px] mq600:pl-2.5 mq600:pr-2.5 mq600:box-border mq480:pl-2.5 mq480:pr-2.5 mq480:box-border">
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[24px] h-auto hover:flex hover:flex-1 hover:self-stretch hover:h-auto hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start md:gap-[20px] md:hover:gap-[20px] sm:gap-[16px] sm:hover:gap-[16px] mq768:gap-[12px] mq768:hover:gap-[12px] mq600:gap-[12px] mq600:hover:gap-[12px] mq480:gap-[12px] mq480:hover:gap-[12px] height_414:gap-[12px] height_414:hover:gap-[12px]">
        <div className="self-stretch flex flex-row items-start justify-start sm:flex-col sm:gap-[20px] mq768:gap-[12px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px] sm:flex-[unset] sm:self-stretch mq768:gap-[8px] height_414:gap-[2px]">
            <div className="relative font-semibold md:text-base sm:text-sm mq768:text-sm mq600:text-sm mq480:text-smi height_414:text-smi">
              {LL0().ui.name()}:
            </div>
            <TextField
              className="self-stretch bg-white"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              value={STATES.editingProduct()?.name}
              onChange={e => VIEW__PRODUCT.onChanged('name', e.target.value)}
            />
          </div>
        </div>
        <ModifierSelectFieldset />
        <VariantSelectFieldset />
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.description()}:
          </div>
          <TextFieldArea
            className="self-stretch w-full"
            value={STATES.editingProduct()?.description}
            onChange={e => VIEW__PRODUCT.onChanged('description', e.target.value)}
          />
        </div>
        <button onClick={toggleShowMoreSetting} ref={makeRipple} className="rounded-3xs [background:linear-gradient(135deg,_#7ebcfe,_#0072eb)] shadow-[0px_3px_20px_rgba(48,_48,_48,_0.2)] flex flex-row items-center justify-center py-2.5 px-5 text-center text-xl text-white-solid-white-100-ffffff md:pt-2 md:pb-2 md:box-border sm:py-1.5 sm:px-4 sm:box-border mq768:pl-3 mq768:pr-3 mq768:box-border mq600:pl-2 mq600:pr-2 mq600:box-border">
          <b className="relative lg:text-lg md:text-base sm:text-sm mq768:text-smi mq600:text-smi">
            {LL0().editOnlineMenu.moreSettings()}
          </b>
        </button>
        {showMoreSetting && <>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.saleNote()}
          </div>
          <TextField
            className="self-stretch bg-white"
            color="primary"
            size="small"
            fullWidth={true}
            variant="outlined"
            value={STATES.editingProduct()?.saleNote}
            onChange={e => VIEW__PRODUCT.onChanged('saleNote', e.target.value)}
          />
        </div>
        {/*
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            Location(s)
          </div>
          <FormControl
            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
            variant="outlined"
          >
            <InputLabel color="primary" />
            <Select color="primary" size="small" disabled />
            <FormHelperText />
          </FormControl>
        </div>
        */}
        <OrderRestrictionFieldset />
        {STATES.editingProduct()?.orderRestrictions?.includes('pickup') && (
          <PickupTimeFieldset />
        )}
        <div className="pb-8 self-stretch flex flex-col items-start justify-start gap-[24px] height_414:gap-[24px]">
          <ValiditySelectFieldset />
          {STATES.editingProduct()?.validityMode === 'recurring' && (
          <ValidityRecurringFieldset />
          )}
          {STATES.editingProduct()?.validityMode === 'period' && (
          <ValidityPeriodFieldset />
          )}
        </div>
        </>}
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[24px] h-auto hover:flex hover:flex-1 hover:self-stretch hover:h-auto hover:flex-col hover:gap-[24px] hover:items-start hover:justify-start md:gap-[20px] md:hover:gap-[20px] sm:gap-[16px] sm:hover:gap-[16px] mq768:gap-[12px] mq768:hover:gap-[12px] mq600:gap-[12px] mq600:hover:gap-[12px] mq480:gap-[12px] mq480:hover:gap-[12px] height_414:gap-[12px] height_414:hover:gap-[12px]">
        <div className="self-stretch flex flex-row items-start justify-start gap-[16px] md:flex-col md:gap-[20px] sm:flex-col sm:gap-[16px] mq768:gap-[12px]">
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px] md:flex-[unset] md:self-stretch sm:flex-[unset] sm:self-stretch mq768:gap-[8px] height_414:gap-[2px]">
            <div className="relative font-semibold md:text-base sm:text-sm mq768:text-sm mq600:text-sm mq480:text-smi height_414:text-smi">
              {LL0().article.id()}:
            </div>
            <TextField
              className="self-stretch bg-white"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              value={STATES.editingProduct()?.id}
              onChange={v => VIEW__PRODUCT.onChanged('id', v.target.value)}
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px] md:flex-[unset] md:self-stretch sm:flex-[unset] sm:self-stretch height_414:gap-[2px]">
            <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
              {LL0().printing.price()} ({LL0().printing.currency()})
            </div>
            <TextField
              className="self-stretch bg-white"
              color="primary"
              size="small"
              fullWidth={true}
              variant="outlined"
              type="number"
              value={STATES.editingProduct()?.price ? STATES.editingProduct()?.price + '' : ''}
              onChange={(e) => {
                _.debounce(() => userFLow(`edit product in Online Menu`, {
                  username: loginUser()?.name
                }), 1000, { leading: true, trailing: true })
                const _product = STATES.editingProduct();
                if (_product) _product.price = parseFloat(e.target.value);
              }}
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[8px] md:flex-[unset] md:self-stretch sm:flex-[unset] sm:self-stretch height_414:gap-[2px]">
            <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
              {LL0().printing.tax()}
            </div>
            <FormControl
              className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-260-304eee"
              variant="outlined"
            >
              <InputLabel color="primary" />
              <Select
                color="primary"
                size="small"
                className="bg-white"
                value={numberToString(STATES.editingProduct()?.tax2)}
                onChange={v => VIEW__PRODUCT.onChanged('tax2', numberFromString(v.target.value))}
              >
                {
                  taxCategoriesVM().map((taxCategory, index) =>
                    <MenuItem key={index} value={getTax(taxCategory)}>
                      {taxCategory?.name} {getTax(taxCategory)}%
                    </MenuItem>)
                }
              </Select>
              <FormHelperText />
            </FormControl>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.printers()}
          </div>
          <FormControl
            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
            variant="outlined"
          >
            <InputLabel color="primary" />
            <Select className="bg-white" color="primary" size="small" multiple value={STATES.editingProduct()?.groupPrinters ?? []} onChange={e => VIEW__PRODUCT.onChanged('groupPrinters', e.target.value)}>
              {STATES.printers()?.filter(p => p.type !== 'label')?.map(p =>
                <MenuItem key={p._id} value={p._id}>{p.name}</MenuItem>
              )}
              {/*
              <MenuItem value="Kitchen">Kitchen</MenuItem>
              <MenuItem value="Bar 1">Bar 1</MenuItem>
              <MenuItem value="Bar 2">Bar 2</MenuItem>
              <MenuItem value="Label">Label</MenuItem>
              */}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().sidebar.labelPrinter()}
          </div>
          <FormControl
            className="self-stretch font-mulish-button-name-bold font-semibold text-xl text-blue-solid-blue-900-081a51"
            variant="outlined"
          >
            <InputLabel color="primary" />
            <Select className="bg-white" color="primary" size="small" value={STATES.editingProduct()?.labelPrinter ?? ''}
                    onChange={e => {
                      userFLow(`change Label Printer (name group print: ${e.target.value}) in Online Menu`, {
                        username: loginUser()?.name
                      });
                      VIEW__PRODUCT.onChanged('labelPrinter', e.target.value)
                    }}>
              {STATES.printers()?.filter(p => p.type === 'label')?.map(p =>
                <MenuItem key={p._id} value={p._id}>{p.name}</MenuItem>
              )}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="self-stretch flex flex-row items-center justify-start gap-[8px] w-auto h-auto hover:flex hover:self-stretch hover:w-auto hover:h-auto hover:flex-row hover:gap-[8px] hover:items-center hover:justify-start">
            <div className="flex-1 relative font-semibold md:text-base sm:text-sm height_414:text-smi">
              {LL0().editOnlineMenu.discountable()}
            </div>
            <div className="self-stretch relative w-10">
              <FormControlLabel
                label=''
                className="absolute h-[86.96%] w-full top-[6.52%] right-[0%] bottom-[6.52%] left-[0%]"
                control={<Switch
                  color="primary"
                  checked={!!STATES.editingProduct()?.isDiscountable}
                  onChange={(_, checked) => VIEW__PRODUCT.onChanged('isDiscountable', checked)}
                />}
              />
            </div>
          </div>
          <FormControl
            className="self-stretch font-mulish-button-name-bold italic font-medium text-mini text-silver" variant="outlined" disabled={!STATES.editingProduct()?.isDiscountable}>
            <InputLabel color="primary" />
            <Select color="primary" size="small" className="bg-white" value="" >
              <MenuItem value="" disabled>{LL0().editOnlineMenu.pleaseSelect()}</MenuItem>
              <MenuItem value="Lunch Time - 5%">Lunch Time - 5%</MenuItem>
              <MenuItem value="10%">10%</MenuItem>
              <MenuItem value="$5">$5</MenuItem>
              <MenuItem value="5%">5%</MenuItem>
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.changeCategory()}:
          </div>
          <FormControl
            className="self-stretch font-mulish-button-name-bold italic font-medium text-mini text-silver"
            variant="outlined"
          >
            <InputLabel color="primary" />
            <Select color="primary" size="small" multiple value={STATES.editingProduct()?.categories ?? []} onChange={e => VIEW__PRODUCT.onChanged('categories', e.target.value)} className="bg-white">
              {STATES.categories()?.map(c => (
              <MenuItem key={c._id} value={c._id}>{c.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText />
          </FormControl>
        </div>
        <ImagePickerFieldset />
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] sm:gap-[10px] mq768:gap-[8px] height_414:gap-[6px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().onlineOrder.status()}
          </div>
          <div className="relative w-[218px] h-5">
            <FormControlLabel
              className="absolute top-[calc(50%_-_10px)] left-[calc(50%_-_109px)]"
              label={LL0().editOnlineMenu.disableEnable()}
              control={<Switch color="primary"
                               checked={!!STATES.editingProduct()?.isEnabled}
                               onChange={(_, checked) => VIEW__PRODUCT.onChanged('isEnabled', checked)}
              />}
            />
          </div>
        </div>
        <div className="pb-8 self-stretch flex flex-col items-start justify-start gap-[8px] mq768:gap-[4px] mq600:gap-[2px] height_414:gap-[2px]">
          <div className="relative font-semibold md:text-base sm:text-sm height_414:text-smi">
            {LL0().editOnlineMenu.stockQuantity()}
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[20px] text-green-solid-green-300-3cbc81 sm:flex-col sm:gap-[16px] mq768:gap-[12px]">
            <div className="flex-1 relative h-[46px] sm:flex-[unset] sm:self-stretch">
              <TextField
                className="absolute w-full top-[calc(50%_-_23px)] right-[0px] left-[0px] bg-white"
                color="primary"
                size="small"
                fullWidth={true}
                variant="outlined"
                type="number"
                value={numberToString(STATES.editingProduct()?.stockQuantity)}
                onChange={e => VIEW__PRODUCT.onChanged('stockQuantity', numberFromString(e.target.value))}
              />
            </div>
            <div className="flex-1 relative h-[37px] sm:flex-[unset] sm:self-stretch">
              <div className="absolute w-full top-[calc(50%_-_23px)] right-[0px] left-[0px] rounded-3xs bg-green-opacity-green-40-c5f2c7 shadow-[0px_2px_10px_rgba(0,_0,_0,_0.1)] box-border h-[46px] overflow-hidden flex flex-row items-center justify-center py-0 px-2 border-[1px] border-solid border-blue-solid-blue-100-e6f2fe sm:w-auto sm:[align-self:unset] sm:h-10 sm:pl-3 sm:pr-3 sm:box-border mq768:h-9 mq600:h-8 height_414:h-[34px]">
                <button disabled className="opacity-30 relative font-semibold sm:text-base mq768:text-mini mq600:text-sm height_414:text-sm">
                  {LL0().editOnlineMenu.manageStock()}
                </button>
              </div>
            </div>
          </div>
          <div className="self-stretch relative text-sm height_414:text-xs">{LL0().editOnlineMenu.totalStockAllLocations()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductEditingForm;
