import type React from "react";
import { type FunctionComponent, useState } from "react";
import { LL0 } from "@/react/core/I18nService.tsx";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { currentYear, lastFiveYears, selectedUserName } from "@/react/StaffReportView/StaffReportView.tsx";
import { srmReportLogic } from "@/srm/report.logic"
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

export type FilterPrintStaffReportSaleSummaryPopUp = {
  onClose?: () => void;
  onComplete?: () => void;
};
enum RadioValue {
  All = 'all',
  One = 'one',
}


const FilterPrintStaffReportSaleSummaryPopUp: FunctionComponent<
  FilterPrintStaffReportSaleSummaryPopUp
> = ({ onClose, onComplete }) => {
  const [year, setYear] = useState(currentYear);
  const [selectedRadioValue, setSelectedRadioValue] = useState<RadioValue>(RadioValue.All);

  const handleSelectYear = (event: React.ChangeEvent<{ value: number }>) => {
    userFLow(`handle select year ${event.target.value}`, {
      username: loginUser()?.name
    });
    setYear(event.target.value);
  }
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    userFLow(`handle select year ${event.target.value}`, {
      username: loginUser()?.name
    });
    setSelectedRadioValue(event.target.value as RadioValue);
  };

  return (
    <div
      className="w-full relative rounded bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-center justify-center p-4 box-border gap-[15px] min-w-[360px] h-full max-w-full max-h-full text-center text-[20px]">
      <div className={"w-full text-left"}>
        <b>{LL0().orderHistory.OptionalSaleSummary()}</b>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div
          className="w-full rounded-sm flex items-start flex-col pb-3 gap-[4px]">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={RadioValue.All}
            name="radio-buttons-group"
            value={selectedRadioValue}
            onChange={handleChangeCheckbox}
          >
            <FormControlLabel value={RadioValue.All} control={<Radio />} label={LL0().orderHistory.allUsers()} />
            <FormControlLabel value={RadioValue.One} control={<Radio />}
                              label={LL0().orderHistory.ReportFor() + " " + selectedUserName()} />
          </RadioGroup>
        </div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{LL0().orderHistory.Year()}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={year}
            value={year}
            size={"small"}
            label={LL0().orderHistory.Year()}
            onChange={handleSelectYear}
          >
            {lastFiveYears.map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="w-full flex flex-row items-start gap-[12px] text-sm text-royalblue">
        <div
          className="flex-1 rounded-md bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 cursor-pointer border-[1px] border-solid border-royalblue"
          onClick={() => {
            onClose?.()
            userFLow(`close filter printer staff report sale summary`, {
              username: loginUser()?.name
            });
          }}
        >
          <b className="relative">{LL0().ui.cancel()}</b>
        </div>
        <div
          className="flex-1 rounded-md bg-royalblue overflow-hidden flex flex-row items-center justify-center py-[9px] px-0 text-white-solid-white-100-ffffff"
          onClick={() => {
            userFLow(`filter printer staff report sale summary`, {
              username: loginUser()?.name
            });
            srmReportLogic.printYearlyUserReport(year, selectedRadioValue === RadioValue.One ? selectedUserName() : undefined)
            onClose?.()
          }}
        >
          <b className="relative">{LL0().ui.ok()}</b>
        </div>
      </div>
    </div>
  );
};

export default FilterPrintStaffReportSaleSummaryPopUp;