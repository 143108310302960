import { FunctionComponent } from "react";
import CategoryItem from "../CategoryItem";
import { categories1, isActiveCategory, setActiveCategory } from "@/react/OrderView/OrderView.tsx";
import { LL0 } from "@/react/core/I18nService.tsx";

export type OrderScreenAllCategoryPoType = {
  className?: string;
  onClose?: () => void;
};

const OrderAllCategoryPopup: FunctionComponent<
  OrderScreenAllCategoryPoType
> = ({ className = "", onClose }) => {
  return (
    <div
      className={`w-[400px] h-[381px] relative bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-start justify-start py-[18px] px-0 box-border max-w-full max-h-full text-left text-base text-black-solid-black-1000-000000 font-mulish ${className}`}
    >
      <div className="self-stretch overflow-hidden flex flex-row items-center justify-between py-0.5 px-4">
        <b className="flex-1 relative mq432:text-mini">{LL0().inventory.allCategory()}</b>
        <img
          className="w-[21px] relative h-[21px] object-cover cursor-pointer"
          alt=""
          src="/iconclose-popup-icon@2x.png"
          onClick={onClose}
        />
      </div>
      <div className="no-scrollbar self-stretch h-full flex-1 overflow-y-auto flex flex-col items-start justify-start pt-4 px-0 pb-2 box-border">
        <div className="self-stretch grid flex-row flex-wrap items-start justify-start py-0.5 px-4 gap-[10px] grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))] sm:grid sm:grid-cols-[repeat(auto-fill,_minmax(128px,_1fr))]">
          {categories1().map((category, index) => (
            <CategoryItem
              onClick={() => setActiveCategory(category._id)}
              key={index}
              backgroundColor={category?.layout?.color || '#FFFFFF'}
              isSelected={isActiveCategory(category._id)}
              categoryName={category?.name}
              orderScreenCategoryItemV1Flex="1"
              orderScreenCategoryItemV1Height="40px"
              orderScreenCategoryItemV1MinWidth="150px"
            />
          ))}
        </div>
      </div>
      {/*<div className="self-stretch bg-white-solid-white-100-ffffff overflow-hidden flex flex-col items-end justify-start pt-2 px-4 pb-4 text-center text-sm text-white-solid-white-100-ffffff">*/}
      {/*  <div onClick={onClose} className="self-stretch rounded-md bg-blue-solid-blue-420-2979ff h-9 overflow-hidden shrink-0 flex flex-row items-center justify-center py-[9px] px-8 box-border">*/}
      {/*    <b className="relative">{LL0().editOnlineMenu.select()}</b>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default OrderAllCategoryPopup;
