import { signal, useSignal } from '@/react/core/reactive.ts'
import PortalPopup from "@/react/core/PortalPopup.tsx";
import { FormControlLabel, Slider, Switch, TextField } from '@mui/material'
import { makeRipple } from '@/react/core/ripple-utils.ts'
import { tseConfig0 } from "@/data/TseConfigHub.ts";
import _ from "lodash";
import DeveloperOnlyVerifyPopu from "@dashboard/DeveloperOnlyVerifyPopu.tsx";

export const [openMockTSEPopup, setOpenMockTSEPopup] = signal<boolean>(false)
export const [openVerifyTSEPopup, setOpenVerifyTSEPopup] = signal<boolean>(false)

const MockTSEPopup = () => {

  const [mockTSE, setMockTSE] = useSignal<boolean>(false);
  const [QRDelay, setQRDelay] = useSignal<number | ''>('');
  const password = tseConfig0()?.tsePassword

  return (
    <>
      {openMockTSEPopup() &&
        <PortalPopup
          overlayColor="rgba(0, 0, 0, 0.2)"
          placement="Centered"
          onOutsideClick={() => setOpenMockTSEPopup(false)}
        >
          <div
            className="relative rounded-10xs bg-white flex flex-col pt-[19px] px-[32px] pb-[26px] box-border gap-[16px] w-full max-w-full min-w-[400px] max-h-full">
            <div className="flex flex-col">
              <p className="relative font-semibold text-md">Passthrough enable:</p>
              <FormControlLabel
                className="self-stretch"
                label={''}
                control={<Switch color="primary" size="medium"
                                 checked={tseConfig0()?.passthroughEnable || false}
                                 onChange={(e, checked: boolean) => _.assign(tseConfig0(), { passthroughEnable: checked })} />}
              />
            </div>
            <div className="flex flex-col">
              <p className="relative font-semibold text-md">Percent: {tseConfig0()?.percent}</p>
              <Slider color="primary"
                      valueLabelDisplay="auto"
                      value={tseConfig0()?.percent || 0}
                      max={100}
                      onChange={(_e, number) => {
                        return _.assign(tseConfig0(), { percent: number });
                      }}
              />
            </div>
            <div className="flex flex-col">
              <TextField
                label="Assign Qr delay: (seconds)"
                variant="standard"
                className="font-semibold"
                type="number"
                value={tseConfig0()?.delayAssignQr?.toString() || '0'}
                onChange={e => {
                  _.assign(tseConfig0(), { delayAssignQr: Number(e.target.value) });
                }}
              />
            </div>
            <div className="flex flex-col">
              <p className="relative font-semibold text-md">Mock TSE (only for testing purpose)</p>
              <FormControlLabel
                className="self-stretch"
                label={''}
                value={mockTSE()}
                control={<Switch
                  checked={tseConfig0()?.mockTSE || false}
                  color="primary" size="medium"
                  onChange={(e, checked: boolean) => {
                    _.assign(tseConfig0(), { mockTSE: checked });
                  }} />}
              />
            </div>
            <div className="flex justify-end flex-1 gap-x-4">
              <button
                className="bg-green-600 text-white font-semibold rounded-10xs px-4 py-2"
                ref={makeRipple}
                onClick={() => setOpenMockTSEPopup(false)}
              >
                OK
              </button>
              <button
                className="bg-red-600 text-white font-semibold rounded-10xs px-4 py-2"
                ref={makeRipple}
                onClick={() => setOpenMockTSEPopup(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </PortalPopup>
      }
      {
        openVerifyTSEPopup() &&
        <PortalPopup overlayColor="rgba(0, 0, 0, 0.2)" placement="Centered" onOutsideClick={() => setOpenVerifyTSEPopup(false)}>
          <DeveloperOnlyVerifyPopu
            onClose={() => setOpenVerifyTSEPopup(false)}
            password={password}
            onComplete={() => {
              setOpenVerifyTSEPopup(false)
              setOpenMockTSEPopup(true)
            }}
          />
        </PortalPopup>
      }
    </>

  )
}

export default MockTSEPopup;