import {type FunctionComponent, useState} from 'react';
import { makeRipple } from '@/react/core/ripple-utils.ts';
import { LL0 } from '@/react/core/I18nService.tsx';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { userFLow } from "@/shared/logger.ts";
import { loginUser } from "@/data/UserSignal.ts";

type DeveloperOnlyVerifyPopuType = {
  onClose?: () => void;
  password: string;
  onComplete: () => void;
};

const DeveloperOnlyVerifyPopu: FunctionComponent<
  DeveloperOnlyVerifyPopuType
> = ({ onClose, password, onComplete}) => {
  const [inputPassword, setInputPassword] = useState<string>("")
  const [showPassword , setShowPassword] = useState<boolean>()

  const handleInputPassword = (input: string) => {
    userFLow(`input password`, {
      username: loginUser()?.name
    });
      if (input === '<-') {
        if (inputPassword.slice(0, inputPassword.length - 1).length === 0) {
          setInputPassword('');
        } else {
          setInputPassword(inputPassword.slice(0, inputPassword.length - 1))
        }
      } else if (input === '<>') {
        setInputPassword('')
      } else {
        if (inputPassword.length < 10){
          setInputPassword(prev => prev + input);
        }
      }}

  const handleClickConfirm = () => {
    userFLow(`confirm password`, {
      username: loginUser()?.name
    });
    if (inputPassword !== password) return;
    onClose?.();
    onComplete();
  }

  return (
    <div className="relative Tablet_600:min-w-[250px] min-w-[414px] rounded-md [background:linear-gradient(180deg,_#fbfbfb,_#c1c1c1)] [backdrop-filter:blur(100px)] w-full flex flex-col items-center justify-start pt-12 px-8 pb-8 box-border gap-[24px] h-full max-w-full max-h-full overflow-auto text-center text-xl text-black-solid-black-860-1c1c1c font-mulish Tablet_600:pl-6 Tablet_600:pr-6 Tablet_600:box-border Mobile_480:pl-4 Mobile_480:pr-4 Mobile_480:box-border">
      <div className="w-full min-w-[250px] h-[248px] flex flex-col items-start justify-start gap-[4px] max-w-[350px] z-[0]">
        {/*<OutlinedInput*/}
        {/*  id="outlined-adornment-password"*/}
        {/*  type={showPassword ? 'text' : 'password'}*/}
        {/*  className={"w-full"}*/}
        {/*  size={"small"}*/}
        {/*  endAdornment={*/}
        {/*    <InputAdornment position="end">*/}
        {/*      <IconButton*/}
        {/*        aria-label="toggle password visibility"*/}
        {/*        onClick={() => setShowPassword(prev => !prev)}*/}
        {/*        onMouseDown={e => e.preventDefault()}*/}
        {/*        edge="end"*/}
        {/*      >*/}
        {/*        {showPassword ? <VisibilityOff /> : <Visibility />}*/}
        {/*      </IconButton>*/}
        {/*    </InputAdornment>*/}
        {/*  }*/}
        {/*  value={inputPassword}*/}
        {/*  onChange={e => setInputPassword(e.target.value)}*/}
        {/*/>*/}
        <div
          className='w-full min-w-[250px] max-w-[350px] relative cursor-text h-[37px] pl-[14px] py-[1px] pr-[1px] inline-flex items-center rounded-[4px] border border-[#C2C2C2]'>
          <div className='flex flex-row flex-grow'>
            <div className='text-sm text-black'>{showPassword ? inputPassword : "*".repeat(inputPassword.length)}</div>
            <div className='flex-grow'/>
          </div>
          <div className='cursor-pointer flex items-center h-full pr-1' onClick={() => setShowPassword(prev => !prev)}
               onMouseDown={e => e.preventDefault()}>
            {showPassword ? <Visibility/>: <VisibilityOff/> }
          </div>
        </div>

        <div
          className="w-full min-w-[250px] max-w-[350px] flex-1 self-stretch relative h-[164px] grid grid-rows-[repeat(4,1fr)] grid-cols-[repeat(3,1fr)] [grid-row-gap:4px] [grid-column-gap:4px] text-lg text-black-solid-black-900-1e1e23">
          {[7, 8, 9, 4, 5, 6, 1, 2, 3].map((numberKey, index) =>
            <div className="rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative border-[1px] border-solid border-gray-solid-gray-600-979797"
                 key={index}
                 onClick={() => handleInputPassword(numberKey.toString())}
            >
              <b className="flex-1 relative leading-[17px]">{numberKey}</b>
            </div>
          )}
          <div className="rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative col-[1/1] row-[4/4] border-[1px] border-solid border-gray-solid-gray-600-979797"
               onClick={() => handleInputPassword("<>")}
               ref={makeRipple}
          >
            <img
              className="relative w-6 h-6 object-cover"
              alt=""
              src="/iconreload-icon-black@2x.png"
            />
          </div>
          <div className="rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative col-[2/2] row-[4/4] border-[1px] border-solid border-gray-solid-gray-600-979797"
               onClick={() => handleInputPassword("0")}
               ref={makeRipple}
          >
            <b className="flex-1 relative leading-[17px]">0</b>
          </div>
          <div className="rounded-10xs bg-white-solid-white-100-ffffff overflow-hidden flex flex-row items-center justify-center relative col-[3/3] row-[4/4] border-[1px] border-solid border-gray-solid-gray-600-979797"
               onClick={() => handleInputPassword("<-")}
               ref={makeRipple}
          >
            <div className="relative w-[30px] h-[30px]">
            <img
              className="relative w-[30px] h-[30px] object-cover"
              alt=""
              src="/delete-icon@2x.png"
            />
          </div>
        </div>
        <div className="self-stretch col-span-3 rounded-md bg-blue-solid-blue-420-2979ff h-[38px] overflow-hidden shrink-0 flex flex-row items-center justify-center text-mini text-white-solid-white-100-ffffff"
             onClick={handleClickConfirm}
             ref={makeRipple}
        >
          <div className="relative font-semibold">{LL0().ui.confirm()}</div>
        </div>
      </div>
      <img
        className="absolute my-0 mx-[!important] top-[7px] right-[7px] w-[25px] h-[25.4px] cursor-pointer z-[1]"
        alt=""
        src="/close-tab.svg"
        onClick={onClose}
      />
    </div>
    </div>
  );
};

export default DeveloperOnlyVerifyPopu;
